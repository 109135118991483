import PropTypes from "prop-types";
import React from "react";

const ExternalLink = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="2.1---legalsite-settings" transform="translate(-360.000000, -1702.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Small-button-color" transform="translate(333.000000, 1692.000000)">
                    <g id="Button-color">
                        <g id="Group-3" transform="translate(27.000000, 5.000000)">
                            <g id="external-link-outline" transform="translate(0.000000, 5.000000)">
                                <path d="M15.1111111,7.11111111 C14.6201913,7.11111111 14.2222222,7.50908022 14.2222222,8 L14.2222222,13.3333333 C14.2222222,13.8242531 13.8242531,14.2222222 13.3333333,14.2222222 L2.66666667,14.2222222 C2.17574689,14.2222222 1.77777778,13.8242531 1.77777778,13.3333333 L1.77777778,2.66666667 C1.77777778,2.17574689 2.17574689,1.77777778 2.66666667,1.77777778 L8,1.77777778 C8.49091978,1.77777778 8.88888889,1.37980867 8.88888889,0.888888889 C8.88888889,0.397969111 8.49091978,0 8,0 L2.66666667,0 C1.19390733,0 0,1.19390733 0,2.66666667 L0,13.3333333 C0,14.8060927 1.19390733,16 2.66666667,16 L13.3333333,16 C14.8060927,16 16,14.8060927 16,13.3333333 L16,8 C16,7.50908022 15.6020309,7.11111111 15.1111111,7.11111111 Z" id="Path"></path>
                                <path d="M11.5555556,1.77777778 L12.96,1.77777778 L7.36888889,7.36 C7.20061098,7.52690364 7.10595705,7.75409997 7.10595705,7.99111111 C7.10595705,8.22812225 7.20061098,8.45531858 7.36888889,8.62222222 C7.53579253,8.79050013 7.76298886,8.88515407 8,8.88515407 C8.23701114,8.88515407 8.46420747,8.79050013 8.63111111,8.62222222 L14.2222222,3.04 L14.2222222,4.44444444 C14.2222222,4.93536422 14.6201913,5.33333333 15.1111111,5.33333333 C15.6020309,5.33333333 16,4.93536422 16,4.44444444 L16,0.888888889 C16,0.397969111 15.6020309,0 15.1111111,0 L11.5555556,0 C11.0646358,0 10.6666667,0.397969111 10.6666667,0.888888889 C10.6666667,1.37980867 11.0646358,1.77777778 11.5555556,1.77777778 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ExternalLink.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default ExternalLink;