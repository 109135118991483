import React from "react";
import PropTypes from "prop-types";
import Flag from "../../images/Inbox/Flag.svg";
import Moment from "react-moment";

function RequestStats(props) {
  const { selectedRequest, showStatus } = props;
  const date1 = new Date(
      selectedRequest && selectedRequest.dueDate
        ? selectedRequest.dueDate.toString()
        : ""
    ),
    date2 = new Date(),
    Difference_In_Time = date1.getTime() - date2.getTime(),
    Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

  return (
    <div className="inbox__data-stats">
      <div className="d-flex flex-column">
        <span className="inbox__data-stats-label">Status</span>
        <span className="inbox__data-stats-value">
          {showStatus(
            selectedRequest && selectedRequest.status
              ? selectedRequest.status
              : null
          )}
        </span>
      </div>

      <div className="d-flex flex-column">
        <span className="inbox__data-stats-label">Date</span>
        <span className="inbox__data-stats-value">
          <Moment format="D MMMM YYYY">
            {selectedRequest && selectedRequest.receivedDate
              ? selectedRequest.receivedDate
              : null}
          </Moment>
        </span>
      </div>

      <div className="d-flex flex-column">
        <span className="inbox__data-stats-label">
          Due Date{" "}
          {Difference_In_Days < 7 ||
          (selectedRequest && selectedRequest.urgent) ? (
            <img className="ml-2" src={Flag} alt="due-date" />
          ) : null}
        </span>
        <span className="inbox__data-stats-value">
          <Moment format="D MMMM YYYY">
            {selectedRequest && selectedRequest.dueDate
              ? selectedRequest.dueDate
              : null}
          </Moment>
        </span>
      </div>

      <div className="d-flex flex-column">
        <span className="inbox__data-stats-label">Request</span>
        <span className="inbox__data-stats-value">
          {selectedRequest && selectedRequest.code
            ? selectedRequest.code
            : null}
        </span>
      </div>
    </div>
  );
}

export default RequestStats;
RequestStats.propTypes = {
  selectedRequest: PropTypes.object,
  diff: PropTypes.number,
  showStatus: PropTypes.func,
};
