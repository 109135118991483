import React, { Fragment } from "react";
import { Button } from "reactstrap";
import Site from "../../components/Icons/Site";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import Request from "../../images/Settings/email1.svg";
import FaCaret from "react-icons/lib/fa/angle-down";
import MdClose from "react-icons/lib/md/close";
import Logo from "../../images/Settings/Oval.svg";
import PropTypes from "prop-types";
import "./sample.scss";

function SampleEmails(props) {
  return (
    <Fragment>
      <div className="users__banner">
        <p className="users__banner-title">Sample data</p>
        <div className="users__banner-exit" onClick={props.closeSample}>
          <span>Exit</span>
          <MdClose className="ml-2" size={20} />
        </div>
      </div>
      <div className="customization -is-sample">
        <div className="customization__navigation">
          <h4 className="customization__navigation-title">
            <Site color="#333" className="mr-2" /> Customization
          </h4>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">LegalSite</p>
              <p className="settings__captcha-item-text">
                Customize your LegalSite
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">Agreement Pop-up</p>
              <p className="settings__captcha-item-text">
                Change your Pop-up style.
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="-is-active settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">E-mails</p>
              <p className="settings__captcha-item-text">
                Customize your e-mails
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>
        </div>

        <div className="customization__content">
          <Fragment>
            <div className="justify-content-between d-flex w-100">
              <div className="customization-email customization-email__type">
                <h3 className="customization-email__subjects-title">
                  Request received
                </h3>

                <p className="customization-email__subjects-subtitle text-left">
                  This is the e-mail we send to your Data Subject when they make
                  a request on your LegalSite page.
                </p>

                <div className="customization-email__email-template mt-4">
                  <div className="customization-email__header">
                    <p className="customization-email__header-logo">Logo</p>

                    <span className="customization-email__header-link">
                      Open in browser
                    </span>
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <img src={Request} alt="banner" className="mt-4" />

                    <h4 className="mt-5 customization-email__email-title border-0">
                      Request received
                    </h4>

                    <p className="text-center mt-4 customization-email__subtitle border-0">
                      We have received your request and one of our team members
                      should get back to you in the next 24 hours. Thank you!
                    </p>

                    <p className="customization-email__email-text border-0 mb-0">
                      Best regards
                      <br />
                      LegalSite team.
                    </p>
                  </div>

                  <div className="customization-email__footer">
                    <p className="customization-email__footer-text border-0">
                      If at any time you have questions or remarks, please let
                      us know by email (contact@legalsite.co), by using our help
                      section in the app or by sending a message through our
                      contact page.
                    </p>

                    <span className="customization-email__footer-divider" />

                    <p className="customization-email__footer-info">
                      www.legalsite.com | LegalSite | All rights reserved{" "}
                      {new Date().getFullYear()} | Terms & Conditions | Privacy
                      Policy
                    </p>

                    <p className="customization-email__footer-unsubscribe">
                      Unsubscribe
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="customization-cookie__right-menu -is-sample">
              <Button className="customization-cookie__publish btn--primary mb-2">
                Publish Pop-up
              </Button>
              <Button className="customization-cookie__preview btn--secondary mb-2">
                Back to templates
              </Button>

              <div className="customization-cookie__right-menu-content">
                <div className="d-flex mr-auto flex-column">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <div className="d-flex sample-scheme mb-0 mt-4">
                        <span className="sample-scheme__color -white mr-4" />
                        <span className="sample-scheme__color -dark-gray mr-4" />
                        <span className="sample-scheme__color -purple mr-4" />
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="sample-text__label">Logo</p>

                    <div className="sample-text__logo">
                      <img src={Logo} alt="logo" />
                      <p>CHANGE LOGO</p>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Title</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -black" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Body</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -gray" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Button text</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -gray" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Footer text</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -gray" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

export default SampleEmails;
SampleEmails.propTypes = {
  closeSample: PropTypes.func.isRequired,
};
