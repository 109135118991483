import React from "react";
import MdArrow from "react-icons/lib/ti/arrow-right";
import PropTypes from "prop-types";

function BreachIncidents(props) {
  const { title, update, incident } = props;

  return (
    <div className="compliance-breach__item d-flex flex-column align-items-start justify-content-between mt-5">
      <p className="compliance-breach__item-subtitle">{title}</p>
      <p className="compliance-breach__item-update">Last update {update}</p>
      <p className="compliance-breach__item-update">
        Type:{" "}
        {incident && incident.where
          ? incident.where === "your organisation"
            ? "Inside your organisation"
            : "External"
          : "External"}
      </p>
      <p
        className="compliance-breach__item-link"
        onClick={() => props.showIncidents("update", incident)}
      >
        Update <MdArrow size={25} className="ml-3" />
      </p>
    </div>
  );
}

export default BreachIncidents;
BreachIncidents.propTypes = {
  title: PropTypes.string.isRequired,
  update: PropTypes.string.isRequired,
  showNameForm: PropTypes.func,
  incident: PropTypes.object,
  showIncidents: PropTypes.func,
};
