import React, { Component } from "react";
import Empty from "../../images/Inbox/empty.svg";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

export default class NoRequests extends Component {
  static propTypes = {
    account: PropTypes.object,
    createRequests: PropTypes.func,
  };
  state = {};

  render() {
    return (
      <div
        className={`${
          this.props.account &&
          this.props.account.users &&
          this.props.account.users.length > 1000
            ? "-has-banner"
            : ""
        } inbox container`}
      >
        <div className="inbox__empty">
          <div className="inbox__empty-img">
            <img src={Empty} alt="empty" />
          </div>

          <h4 className="inbox__empty-title">
            You don't have any requests yet
          </h4>

          <p className="inbox__empty-text px-2 text-center">
            When a user sends a request on your LegalSite, it will appear here
            so you can take action.
          </p>

          <Button
            className="btn--primary mt-4"
            onClick={this.props.createRequests}
          >
            Create request
          </Button>
        </div>
      </div>
    );
  }
}
