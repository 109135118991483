import gql from "graphql-tag";

export const DATA_PROCESSOR_QUERY = gql`
  query($integrationId: ID!) {
    integration(dataProcessorId: $integrationId) {
      id
      companyName
      contactName
      email
      dpaFile
      apiKey
      dataProcessorConsent {
        consentName
        id
        purpose
      }
      dataProcessor {
        companyName
        dateAdded
        description
        email
        id
        logo
        phoneNumber
        profile {
          id
        }
        published
        website
      }
    }
    account {
      id
      apiKey
    }
  }
`;
