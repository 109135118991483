import React from "react";
import PropTypes from "prop-types";
import Image5 from "../../images/Settings/Illustration.svg";
import { Button, Form, Input, FormFeedback, FormGroup } from "reactstrap";

function AddDPStepTwo(props) {
  const {
    handleStep2,
    companyErrors,
    contactErrors,
    emailErrors,
    addDataProcessor,
  } = props;
  return (
    <div className="data-processors__add-content">
      <p className="data-processors__add-title">Your company details</p>
      <p className="data-processors__add-subtitle">
        Please fill out the information under which you are known or registered
        at the Data Processor. They will need this information to process your
        privacy requests.
      </p>

      <img
        className="data-processors__add-img"
        src={Image5}
        alt="Data Processor"
      />

      <Form onSubmit={handleStep2} className="data-processors__add-form">
        <FormGroup>
          <Input
            type="text"
            name="companyName"
            placeholder="Company name"
            invalid={companyErrors}
          />
          <FormFeedback>Please provide your company name</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            type="text"
            name="contact"
            placeholder="Contact name"
            invalid={contactErrors}
          />
          <FormFeedback>Please provide a contact name</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            type="email"
            name="companyEmail"
            placeholder="E-mail"
            invalid={emailErrors}
          />
          <FormFeedback>Please provide company email</FormFeedback>
        </FormGroup>

        <div className="d-flex justify-content-center mt-5">
          <Button
            className="btn--secondary data-processors__add-cancel"
            onClick={addDataProcessor}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn--primary data-processors__add-next"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddDPStepTwo;
AddDPStepTwo.propTypes = {
  handleStep2: PropTypes.func.isRequired,
  companyErrors: PropTypes.bool,
  contactErrors: PropTypes.bool,
  emailErrors: PropTypes.bool,
  addDataProcessor: PropTypes.func.isRequired,
};
