import gql from "graphql-tag";

export const ADD_PAGE = gql`
  mutation($title: String!, $description: String!) {
    createPage(input: { title: $title, description: $description }) {
      errors
      page {
        id
        title
        applicable
        description
        updatedAt
        pageTemplate {
          description
          id
          sections {
            applicable
            id
            text
            title
          }
          title
          updatedAt
        }
        publicationDownloadUrl
        publications {
          id
          createdAt
          description
          sections {
            applicable
            id
            text
            title
          }
          title
        }
        sections {
          id
          applicable
          text
          title
        }
      }
    }
  }
`;
