import gql from "graphql-tag";

export const COMPLIANCE_DATA_OFFICER_QUERY = gql`
  {
    legalsite {
      bodyBg
      bodyBtnAlign
      bodyBtnBold
      bodyBtnColor
      bodyBtnFont
      bodyBtnBg
      bodyBtnItalic
      bodyBtnSize
      bodyIconsColor
      bodySubtitleAlign
      bodySubtitleBold
      bodySubtitleColor
      bodySubtitleFont
      bodySubtitleItalic
      bodySubtitleSize
      bodyTextAlign
      bodyTextBold
      bodyTextColor
      bodyTextFont
      bodyTextItalic
      bodyTextSize
      bodyTitleAlign
      bodyTitleBold
      bodyTitleColor
      bodyTitleFont
      bodyTitleItalic
      bodyTitleSize
      cardsBg
      cardsBodyAlign
      cardsBodyBold
      cardsBodyColor
      cardsBodyFont
      cardsBodyItalic
      cardsBodySize
      cardsBtnAlign
      cardsBtnBg
      cardsBtnBold
      cardsBtnColor
      cardsBtnFont
      cardsBtnItalic
      cardsBtnSize
      cardsIconsColor
      cardsTitleAlign
      cardsTitleBold
      cardsTitleColor
      cardsTitleFont
      cardsTitleItalic
      cardsTitleSize
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      headerBg
      headerBodyAlign
      headerBodyBold
      headerBodyColor
      headerBodyFont
      headerBodyItalic
      headerBodySize
      headerTitleAlign
      headerTitleBold
      headerTitleColor
      headerTitleFont
      headerTitleItalic
      homepageTitle
      homepageDescription
      headerTitleSize
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    pages {
      id
      title
    }
    dataProtectionOfficer {
      avatar
      company
      description
      email
      id
      name
      phoneNumber
      show
    }
    legalsiteSettings {
      id
      subdomain
      companyLogo
    }
    dontSellMyDataButton {
      buttonType
      color
      id
      snippet
      targetAudience
      text
      textColor
      textFont
      textSize
    }
    account {
      companyRegistration
      companyAddress
      companyCity
      companyZipCode
      country
      dateFormat
      needToUpgrade
      companyWebsite
      email
      fullName
      phoneNumber
      companyName
      showLegalInformation
      id
    }
  }
`;
