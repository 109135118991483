import PropTypes from "prop-types";
import React from "react";

const FileCheck = props => (
    <svg
        height={28}
        viewBox="0 0 22 28"
        width={22}
        fill="currentColor"
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}
    >
        <g id="Journey-11---LegalSite" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--LegalSite---home" transform="translate(-642.000000, -1444.000000)" fill={props.color} fillRule="nonzero">
                <g id="dpa" transform="translate(222.000000, 1412.000000)">
                    <g id="Group" transform="translate(420.000000, 32.000000)">
                        <g id="file-check">
                            <path d="M22,9.716 C21.9856776,9.58738885 21.9580235,9.46068281 21.9175,9.338 L21.9175,9.212 C21.8513885,9.06805165 21.7632027,8.93573077 21.65625,8.82 L13.40625,0.42 C13.2925859,0.311102687 13.1626278,0.221313513 13.02125,0.154 C12.980206,0.148064258 12.938544,0.148064258 12.8975,0.154 C12.7578203,0.0724378964 12.6035571,0.0200818853 12.44375,0 L4.125,0 C1.84682541,-3.10862447e-16 0,1.88040405 0,4.2 L0,23.8 C0,26.1195959 1.84682541,28 4.125,28 L17.875,28 C20.1531746,28 22,26.1195959 22,23.8 L22,9.8 C22,9.8 22,9.8 22,9.716 Z M13.75,4.774 L17.31125,8.4 L15.125,8.4 C14.3656085,8.4 13.75,7.77319865 13.75,7 L13.75,4.774 Z M19.25,23.8 C19.25,24.5731986 18.6343915,25.2 17.875,25.2 L4.125,25.2 C3.36560847,25.2 2.75,24.5731986 2.75,23.8 L2.75,4.2 C2.75,3.42680135 3.36560847,2.8 4.125,2.8 L11,2.8 L11,7 C11,9.31959595 12.8468254,11.2 15.125,11.2 L19.25,11.2 L19.25,23.8 Z M14.14875,14.406 L9.625,19.026 L7.85125,17.206 C7.31208201,16.657029 6.43791799,16.657029 5.89875,17.206 C5.35958201,17.754971 5.35958201,18.645029 5.89875,19.194 L8.64875,21.994 C8.90692907,22.2590377 9.25837339,22.4081177 9.625,22.4081177 C9.99162661,22.4081177 10.3430709,22.2590377 10.60125,21.994 L16.10125,16.394 C16.640418,15.845029 16.640418,14.9549711 16.10125,14.406 C15.562082,13.857029 14.687918,13.857029 14.14875,14.406 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

FileCheck.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default FileCheck;