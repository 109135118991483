import React, { Component } from "react";
import { TabContent, Nav, NavItem, NavLink } from "reactstrap";
import HistoryRequests from "./HistoryRequests";
import HistoryActivity from "./HistoryActivity";
import HistoryAgreements from "./HistoryAgreements";
import { REMOVE_REQUEST } from "../../graphql/removeRequest";
import { REMOVE_INTERACTION } from "../../graphql/removeInteraction";
import { HISTORY_QUERY } from "../../graphql/history";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import axios from "axios";
import SearchInput from "../../components/SearchInput/SearchInput";
import PropTypes from "prop-types";
import { GENERATE_REPORT } from "../../graphql/generateReport";

class ActivityTabs extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired,
    day: PropTypes.object.isRequired,
    month: PropTypes.object.isRequired,
    wholeMonth: PropTypes.object.isRequired,
    year: PropTypes.object.isRequired,
    notify: PropTypes.func.isRequired,
    notifyMsg: PropTypes.func.isRequired,
    removeRequest: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    removeInteraction: PropTypes.func.isRequired,
    historyActivity: PropTypes.array,
    historyRequests: PropTypes.array,
    historyAgreements: PropTypes.array,
  };

  state = {
    activeTab: "1",
    filteredRequests: null,
    filteredActivity: null,
    filteredLog: null,
    query: "",
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    date = dd + "/" + mm + "/" + yyyy;
    return date;
  };

  deleteRequest = (id) => {
    this.props
      .removeRequest({
        variables: {
          id: [id],
        },
      })
      .then(() => {
        this.props.notifyMsg("Request deleted");
        this.props.data.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  downloadReport = (url, id) => {
    const name = `report_${id}`;
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.props.notify();
  };

  removeActivity = (id) => {
    this.props
      .removeInteraction({
        variables: {
          ids: [id],
        },
      })
      .then(() => {
        this.props.notifyMsg("Activity deleted");
        this.props.data.refetch();
      })
      .catch((error) => {
        this.props.notifyMsg("Activity was not removed!");
        console.log(error);
      });
  };

  parseDate = (input) => {
    const parts = input.match(/(\d+)/g);
    return new Date(parts[0], parts[1] - 1, parts[2]);
  };

  checkDate = (date) => {
    return (
      this.formatDate(this.props.date) ===
      this.formatDate(this.parseDate(date.createdAt))
    );
  };

  checkRequests = (date) => {
    return (
      this.formatDate(this.props.date) ===
      this.formatDate(this.parseDate(date.receivedDate))
    );
  };

  search = (query) => {
    if (this.state.activeTab === "1") {
      this.searchUser(query);
    }

    if (this.state.activeTab === "2") {
      this.searchActivity(query);
    }

    if (this.state.activeTab === "3") {
      this.searchLog(query);
    }
  };

  formatRequestDate = (date) => {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
  };

  searchActivity = (query) => {
    this.setState({ query });
    let currentList = [],
      newList = [];

    if (query !== "") {
      currentList = this.props.historyActivity;

      newList = currentList.filter((item) => {
        const userName = item.userName ? item.userName.toLowerCase() : "",
          filter = query.toLowerCase();

        return userName.includes(filter);
      });
    } else {
      newList = this.props.historyActivity;
    }

    this.setState({
      filteredActivity: newList,
    });
  };

  searchLog = (query) => {
    this.setState({ query });
    let currentList = [],
      newList = [];

    if (query !== "") {
      currentList = this.props.historyAgreements;

      newList = currentList.filter((item) => {
        const userName = item.dataSubject.name
            ? item.dataSubject.name.toLowerCase()
            : item.dataSubject.email
            ? item.dataSubject.email.toLowerCase()
            : "",
          filter = query.toLowerCase();

        return userName.includes(filter);
      });
    } else {
      newList = this.props.historyAgreements;
    }

    this.setState({
      filteredLog: newList,
    });
  };

  searchUser = (query) => {
    this.setState({ query });
    let currentList = [],
      newList = [];

    if (query !== "") {
      currentList = this.props.historyRequests;

      newList = currentList.filter((item) => {
        const userName = item.dataSubject.name
            ? item.dataSubject.name.toLowerCase()
            : "",
          filter = query.toLowerCase();

        return userName.includes(filter);
      });
    } else {
      newList = this.props.historyRequests;
    }

    this.setState({
      filteredRequests: newList,
    });
  };

  generateReport = (id, type) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: type,
        },
      })
      .then(({ data }) => {
        this.downloadReport(data.generateReport.downloadUrl, id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  viewReport = (id, type) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: type,
        },
      })
      .then(({ data }) => {
        window.open(data.generateReport.downloadUrl, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { historyRequests, historyActivity, historyAgreements } =
      this.props.data;

    const { filteredRequests, filteredActivity, filteredLog } = this.state;

    const requests = historyRequests
      ? historyRequests.filter(
          (item) =>
            this.formatDate(this.props.day.props.children) ===
            this.formatDate(this.parseDate(item.receivedDate))
        )
      : null;

    const activity = historyActivity
      ? historyActivity.filter(
          (item) =>
            this.formatDate(this.props.day.props.children) ===
            this.formatDate(this.parseDate(item.createdAt))
        )
      : null;

    const agreements = historyAgreements
      ? historyAgreements.filter(
          (item) =>
            this.formatDate(this.props.day.props.children) ===
            this.formatDate(this.parseDate(item.createdAt))
        )
      : null;

    return (requests && requests.length) ||
      (activity && activity.length) ||
      (agreements && agreements.length) ? (
      <div className="history__activity-container container">
        <div className="history__activity-date d-flex flex-column align-items-center">
          <span>{this.props.day}</span>

          <br />
          <span className="-month">{this.props.month}</span>
        </div>
        <div className="history__activity">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={this.state.activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this.toggle("1");
                  this.setState({
                    filteredRequests: null,
                    filteredActivity: null,
                    filteredLog: null,
                    query: "",
                  });
                }}
              >
                Requests
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={this.state.activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this.toggle("2");
                  this.setState({
                    filteredRequests: null,
                    filteredActivity: null,
                    filteredLog: null,
                    query: "",
                  });
                }}
              >
                Account Activity
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={this.state.activeTab === "3" ? "active" : ""}
                onClick={() => {
                  this.toggle("3");
                  this.setState({
                    filteredRequests: null,
                    filteredActivity: null,
                    filteredLog: null,
                    query: "",
                  });
                }}
              >
                Agreement log
              </NavLink>
            </NavItem>

            <SearchInput
              placeholder="Type user name and press enter"
              isHistory={true}
              // value={this.state.query}
              search={this.search}
            />
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <HistoryRequests
              historyRequests={
                filteredRequests ? filteredRequests : historyRequests
              }
              checkRequests={this.checkRequests}
              setFilter={(statusFilter) =>
                this.setState({ statusFilter }, () =>
                  this.props.data.refetch({ status: statusFilter })
                )
              }
              day={this.props.day}
              wholeMonth={this.props.wholeMonth}
              formatDate={this.formatDate}
              formatRequestDate={this.formatRequestDate}
              year={this.props.year}
              date={this.props.date}
              statusFilter={this.state.statusFilter}
              parseDate={this.parseDate}
              downloadReport={this.downloadReport}
              deleteRequest={this.deleteRequest}
              generateReport={this.generateReport}
              viewReport={this.viewReport}
            />

            <HistoryActivity
              historyActivity={
                filteredActivity ? filteredActivity : historyActivity
              }
              checkDate={this.checkDate}
              formatDate={this.formatDate}
              day={this.props.day}
              wholeMonth={this.props.wholeMonth}
              year={this.props.year}
              date={this.props.date}
              parseDate={this.parseDate}
              removeActivity={this.removeActivity}
            />

            <HistoryAgreements
              historyAgreements={filteredLog ? filteredLog : historyAgreements}
              checkDate={this.checkDate}
              formatDate={this.formatDate}
              day={this.props.day}
              wholeMonth={this.props.wholeMonth}
              year={this.props.year}
              date={this.props.date}
              parseDate={this.parseDate}
              generateReport={this.generateReport}
            />
          </TabContent>
        </div>
      </div>
    ) : null;
  }
}

export default compose(
  graphql(REMOVE_REQUEST, {
    name: "removeRequest",
  }),
  graphql(HISTORY_QUERY, {
    options: () => ({
      variables: {
        status: "ALL",
        daysAgo: 5,
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(GENERATE_REPORT, {
    name: "generateReport",
  }),
  graphql(REMOVE_INTERACTION, {
    name: "removeInteraction",
  })
)(ActivityTabs);
