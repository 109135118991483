import React from "react";
import {
  TabPane,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DoneIcon from "../../components/Icons/DoneIcon";
import ViewIcon from "../../components/Icons/ViewIcon";
import TrashIcon from "../../components/Icons/TrashIcon";
import ProgressIcon from "../../components/Icons/ProgressIcon";
import ToDoIcon from "../../components/Icons/ToDoIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";

function HistoryRequests(props) {
  const {
    historyRequests,
    setFilter,
    formatDate,
    day,
    wholeMonth,
    year,
    date,
    parseDate,
    generateReport,
    viewReport,
    statusFilter,
    deleteRequest,
  } = props;
  const requests = historyRequests
    ? historyRequests.filter(
        (item) => formatDate(date) === formatDate(parseDate(item.receivedDate))
      )
    : null;

  const columns = [
    {
      Header: "User",
      accessor: "dataSubject",
      Cell: (props) => (
        <span>{`${props.value ? props.value.name : ""} (${
          props.value ? props.value.email : ""
        })`}</span>
      ),
    },
    {
      Header: "Type",
      accessor: "category",
      Cell: (props) =>
        props.value === "DATA_ACCESS"
          ? "Access"
          : props.value === "QUESTION"
          ? "Question"
          : props.value === "COMPLAINT"
          ? "Complaint"
          : props.value === "DATA_TRANSFER"
          ? "Transfer"
          : props.value === "DONT_SELL_DATA"
          ? "Do no sell"
          : "Removal",
      width: 100,
    },
    {
      Header: (
        <UncontrolledDropdown className="position-static">
          <DropdownToggle caret className="bg-transparent history__toggle">
            {statusFilter
              ? statusFilter === "SOLVED"
                ? "Solved"
                : statusFilter === "IN_PROGRESS"
                ? "In Progress"
                : statusFilter === "URGENT"
                ? "Urgent"
                : statusFilter === "ACTION_REQUIRED"
                ? "Action required"
                : statusFilter === "DELAYED"
                ? "Delayed"
                : statusFilter === "ARCHIVED"
                ? "Archived"
                : statusFilter === "SPAM"
                ? "Spam"
                : "Status"
              : "Status"}
          </DropdownToggle>
          <DropdownMenu className="history__dropdown-menu">
            <DropdownItem onClick={() => setFilter("ALL")}>All</DropdownItem>
            <DropdownItem onClick={() => setFilter("SOLVED")}>
              Solved
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("IN_PROGRESS")}>
              In progress
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("URGENT")}>
              Urgent
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("ACTION_REQUIRED")}>
              Action Required
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("DELAYED")}>
              Delayed
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("ARCHIVED")}>
              Archived
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("SPAM")}>Spam</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      accessor: "status",
      width: 160,
      Cell: (props) =>
        props.value === "IN_PROGRESS" ? (
          <span>
            <ProgressIcon />
            &nbsp; In progress
          </span>
        ) : props.value === "DELAYED" ? (
          <span>
            <ProgressIcon />
            &nbsp; Delayed
          </span>
        ) : props.value === "ARCHIVED" ? (
          <span>
            <ProgressIcon />
            &nbsp; Archived
          </span>
        ) : props.value === "SPAM" ? (
          <span>
            <ProgressIcon />
            &nbsp; Spam
          </span>
        ) : props.value === "ACTION_REQUIRED" ? (
          <span>
            <ToDoIcon />
            &nbsp; To-do
          </span>
        ) : (
          <span>
            <DoneIcon />
            &nbsp; Done
          </span>
        ),
    },
    {
      Header: "Time",
      accessor: "receivedDate",
      Cell: (props) => (
        <span>
          {day} {wholeMonth} {year} {props.value.slice(11, 16)}
        </span>
      ),
      width: 160,
    },
    {
      Header: "Code",
      accessor: "code",
      width: 100,
    },
    {
      Header: "Report",
      accessor: "",
      Cell: (props) => (
        <span className="d-flex align-items-center">
          <ViewIcon
            color={"#969696"}
            className="mr-2 history__icon"
            onClick={() => viewReport(props.value.id, "REQUEST")}
          />
          &nbsp;
          <DownloadIcon
            color={"#969696"}
            className="mr-2 history__icon"
            onClick={() => generateReport(props.value.id, "REQUEST")}
          />
          &nbsp;&nbsp;
          <TrashIcon
            className="history__icon"
            color={"#969696"}
            onClick={() => deleteRequest(props.value.id)}
          />
        </span>
      ),
      width: 160,
    },
  ];

  return (
    <TabPane tabId="1" className="history__table-container">
      <ReactTable
        data={requests}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={20}
        minRows={0}
        showPagination={requests && requests.length > 20 ? true : false}
        noDataText="No Requests"
      />
    </TabPane>
  );
}

export default HistoryRequests;
HistoryRequests.propTypes = {
  historyRequests: PropTypes.array,
  setFilter: PropTypes.func,
  formatDate: PropTypes.func,
  day: PropTypes.string,
  wholeMonth: PropTypes.string,
  year: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  parseDate: PropTypes.func,
  generateReport: PropTypes.func,
  viewReport: PropTypes.func,
  statusFilter: PropTypes.string,
  deleteRequest: PropTypes.func,
  value: PropTypes.array,
};
