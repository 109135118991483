import gql from "graphql-tag";

export const SOLVE_DONT_SELL = gql`
  mutation($requestId: ID!, $message: String, $signature: String, $clientMutationId: String) {
    solveDontSellDataRequest(input: { requestId: $requestId, message: $message, signature: $signature, clientMutationId: $clientMutationId }) {
      errors
      request {
        id
        status
      }
    }
  }
`;
