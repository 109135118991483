import gql from "graphql-tag";

export const IMPORT_QUERY = gql`
  query($id: ID!, $importId: ID!, $pageNumber: Int!) {
    import(id: $id) {
      id
      status
    }
    importRows(importId: $importId, pageNumber: $pageNumber) {
      numberOfPages
      rows {
        company
        email
        id
        isDataController
        name
        phoneNumber
      }
      totalCount
    }
  }
`;
