import gql from "graphql-tag";

export const CUSTOMIZE_COOKIE = gql`
  mutation(
    $titleColor: String
    $titleSize: Int
    $titleFont: String
    $bodyColor: String
    $bodySize: Int
    $bodyFont: String
    $template: String
    $buttonShapes: String
    $popupSide: String
    $title: String
    $body: String
    $backgroundColor: String
    $buttonColor: String!
    $buttonSize: Int!
    $buttonFont: String!
    $secondaryButtonColor: String!
    $secondaryButtonSize: Int!
    $secondaryButtonFont: String!
    $secondaryButtonText: String!
    $overlay: String
    $popupPosition: String
    $category: String!
    $buttonText: String!
    $titleBold: Boolean
    $titleItalic: Boolean
    $titleAlign: String
    $bodyAlign: String
    $bodyBold: Boolean
    $bodyItalic: Boolean
    $destinationPage: String!
    $catchEmail: Boolean!
    $catchSignature: Boolean!
    $verifyUser: Boolean!
    $buttonBgColor: String!
    $buttonBold: Boolean
    $buttonItalic: Boolean
    $secondaryButtonBold: Boolean!
    $secondaryButtonItalic: Boolean!
    $clientMutationId: String
  ) {
    configurePopup(
      input: {
        category: $category
        titleColor: $titleColor
        titleBold: $titleBold
        titleItalic: $titleItalic
        titleAlign: $titleAlign
        titleSize: $titleSize
        titleFont: $titleFont
        bodyAlign: $bodyAlign
        bodyBold: $bodyBold
        bodyItalic: $bodyItalic
        bodyColor: $bodyColor
        secondaryButtonBold: $secondaryButtonBold
        secondaryButtonItalic: $secondaryButtonItalic
        bodySize: $bodySize
        bodyFont: $bodyFont
        template: $template
        buttonShapes: $buttonShapes
        popupSide: $popupSide
        title: $title
        body: $body
        backgroundColor: $backgroundColor
        buttonColor: $buttonColor
        buttonBold: $buttonBold
        buttonItalic: $buttonItalic
        buttonSize: $buttonSize
        buttonFont: $buttonFont
        secondaryButtonColor: $secondaryButtonColor
        secondaryButtonSize: $secondaryButtonSize
        secondaryButtonFont: $secondaryButtonFont
        secondaryButtonText: $secondaryButtonText
        overlay: $overlay
        popupPosition: $popupPosition
        buttonText: $buttonText
        destinationPage: $destinationPage
        catchEmail: $catchEmail
        catchSignature: $catchSignature
        buttonBgColor: $buttonBgColor
        verifyUser: $verifyUser
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      popup {
        backgroundColor
        body
        bodyColor
        bodyFont
        bodySize
        buttonBgColor
        buttonColor
        buttonFont
        buttonShapes
        buttonSize
        buttonText
        catchEmail
        catchSignature
        category
        destinationPage
        overlay
        popupPosition
        popupSide
        secondaryButtonColor
        secondaryButtonFont
        secondaryButtonSize
        secondaryButtonText
        showLegalsiteBranding
        snippet
        subdomain
        template
        title
        titleColor
        titleFont
        titleSize
        verifyUser
      }
    }
  }
`;
