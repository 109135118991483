import PropTypes from "prop-types";
import React from "react";

const InboxIcon = props => (
    <svg
        height={16}
        viewBox='0 0 20 16'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Expanded-side-navigation-bar" transform="translate(-29.000000, -150.000000)" fill={props.color} fillRule="nonzero">
                <g id="Side-navigation-bar">
                    <g id="Inbox" transform="translate(29.000000, 150.000000)">
                        <g id="envelope">
                            <path d="M17,0 L3,0 C1.34314575,0 0,1.34314575 0,3 L0,13 C0,14.6568542 1.34314575,16 3,16 L17,16 C18.6568542,16 20,14.6568542 20,13 L20,3 C20,1.34314575 18.6568542,0 17,0 Z M16.59,2 L10.71,7.88 C10.5222334,8.06931265 10.2666375,8.17579832 10,8.17579832 C9.73336246,8.17579832 9.4777666,8.06931265 9.29,7.88 L3.41,2 L16.59,2 Z M18,13 C18,13.5522847 17.5522847,14 17,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3.41 L7.88,9.29 C9.05127019,10.4598135 10.9487298,10.4598135 12.12,9.29 L18,3.41 L18,13 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

InboxIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default InboxIcon;