import React, { Component, Fragment } from "react";
import SyncLoader from "react-spinners/SyncLoader";
import { Alert, Button } from "reactstrap";
import { EMAILS_QUERY } from "../../graphql/emails";
import { CUSTOMIZE_EMAILS } from "../../graphql/editEmails";
import { SEND_CUSTOM_MAIL } from "../../graphql/sendCustomInboxMail";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import Step2Email from "../Settings/Step2Email";
import MdClose from "react-icons/lib/md/close";
import Request from "../../images/Settings/Request.svg";
import PropTypes from "prop-types";

class MailPreview extends Component {
  static propTypes = {
    email: PropTypes.object,
    requestId: PropTypes.string,
    showSolved: PropTypes.func,
    answer: PropTypes.string,
    mailType: PropTypes.string,
    isEdit: PropTypes.bool,
    closeEdit: PropTypes.func,
    sendCustomInboxMail: PropTypes.func,
  };

  state = {
    updatedBody: null,
    emailText: null,
    updatedTitle: null,
    updatedButtonText: null,
    updatedFooterText: null,
  };

  saveEmail = () => {
    const { email } = this.props.email;

    this.props
      .sendCustomInboxMail({
        variables: {
          requestId: this.props.requestId,
          mailType:
            this.props.mailType === "QUESTION_COMPLAINT_SOLVED"
              ? "QUESTION_COMPLAINT_SOLVED"
              : "DATA_REQUEST_SOLVED",
          signature: this.state.emailText
            ? this.state.emailText
            : email.signature
            ? email.signature
            : "",
          message: this.state.updatedBody
            ? this.state.updatedBody
            : email.body
            ? `${email.body} ${this.props.answer}`
            : "",
        },
      })
      .then(() => {
        this.props.showSolved();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getBannerImage = () => {
    return Request;
  };

  render() {
    const { email, loading, error, legalsiteSettings } = this.props.email;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return email ? (
      <Fragment>
        {this.props.isEdit ? (
          <p className="inbox__data-edit-close text-right">
            <MdClose size={20} onClick={this.props.closeEdit} />
          </p>
        ) : null}
        {this.props.isEdit ? (
          <p className="inbox__data-edit-title">Edit e-mail</p>
        ) : null}
        {this.props.isEdit ? (
          <p className="inbox__data-edit-subtitle">
            This is the e-mail we will send to your user. Click on section to
            edit or delete it.
          </p>
        ) : null}
        {this.props.isEdit ? (
          <Button
            className="btn--primary mx-auto d-block"
            onClick={this.saveEmail}
          >
            Send email
          </Button>
        ) : null}
        <Step2Email
          bgColor={email.bgColor}
          email={email}
          avatar={null}
          emailTitle={
            this.props.mailType &&
            this.props.mailType === "QUESTION_COMPLAINT_SOLVED"
              ? "question"
              : this.props.mailType &&
                this.props.mailType === "DONT_SELL_DATA_REQUEST_DATA_SUBJECT"
              ? "Do not sell data request"
              : "Data access request"
          }
          mailType={this.props.mailType}
          files={null}
          getBannerImage={this.getBannerImage()}
          updatedTitle={this.state.updatedTitle}
          updatedBody={this.state.updatedBody}
          updatedButtonText={this.state.updatedButtonText}
          updatedFooterText={this.state.updatedFooterText}
          emailText={this.state.emailText}
          index={1}
          updateTitle={(text) => this.setState({ updatedTitle: text })}
          updateBody={(text) => this.setState({ updatedBody: text })}
          updateButtonText={(text) =>
            this.setState({ updatedButtonText: text })
          }
          updateEmailText={(text) => this.setState({ emailText: text })}
          updateFooterText={(text) =>
            this.setState({ updatedFooterText: text })
          }
          isForInbox={true}
          answer={this.props.answer}
          logo={
            legalsiteSettings && legalsiteSettings.companyLogo
              ? legalsiteSettings.companyLogo
              : null
          }
        />
      </Fragment>
    ) : null;
  }
}

export default compose(
  graphql(EMAILS_QUERY, {
    options: (props) => ({
      variables: {
        emailType: props.mailType,
      },
      fetchPolicy: "no-cache",
    }),
    name: "email",
  }),
  graphql(CUSTOMIZE_EMAILS, {
    name: "customizeEmails",
  }),
  graphql(SEND_CUSTOM_MAIL, {
    name: "sendCustomInboxMail",
  })
)(MailPreview);
