import gql from "graphql-tag";

export const TOGGLE_LEGALSITE_BRANDING = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleShowLegalsiteBrandingLegalsite(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
      legalsite {
        showLegalsiteBranding
      }
    }
  }
`;
