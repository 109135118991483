import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

export default class UsersBreach extends Component {
  static propTypes = {
    affectedRecordsKnown: PropTypes.string,
    vulnerableIndividuals: PropTypes.string,
    affectedRecords: PropTypes.func.isRequired,
    vulnerable: PropTypes.func.isRequired,
    numberOfAffectedRecords: PropTypes.func.isRequired,
    number: PropTypes.string,
  };

  state = {
    isAffectedRecords: "FALSE",
    isInitialAffectedRecords: true,
    isInitialVulnerable: true,
    isVulnerable: "FALSE",
    number: "",
  };

  render() {
    const { affectedRecordsKnown, vulnerableIndividuals } = this.props;
    const {
      isInitialAffectedRecords,
      isAffectedRecords,
      isInitialVulnerable,
      isVulnerable,
    } = this.state;

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title mb-3">
          Do you know the number of affected records?
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`${
              (affectedRecordsKnown === "TRUE" && isInitialAffectedRecords) ||
              (!isInitialAffectedRecords && isAffectedRecords === "TRUE")
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isAffectedRecords: "TRUE",
                isInitialAffectedRecords: false,
              });
              this.props.affectedRecords("TRUE");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Actual Number known
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`${
              (affectedRecordsKnown === "UNKNOWN" &&
                isInitialAffectedRecords) ||
              (!isInitialAffectedRecords && isAffectedRecords === "UNKNOWN")
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isAffectedRecords: "UNKNOWN",
                isInitialAffectedRecords: false,
              });
              this.props.affectedRecords("UNKNOWN");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Approximate Number known
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`${
              (affectedRecordsKnown === "FALSE" && isInitialAffectedRecords) ||
              (!isInitialAffectedRecords && isAffectedRecords === "FALSE")
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isAffectedRecords: "FALSE",
                isInitialAffectedRecords: false,
              });
              this.props.affectedRecords("FALSE");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Number Unknown
            </span>
          </div>
        </div>

        <Input
          type="text"
          defaultValue={this.props.number}
          className="compliance-breaches__where-input"
          placeholder="Write number of affected records"
          onChange={(e) => {
            this.setState({ number: e.target.value });
            this.props.numberOfAffectedRecords(e.target.value);
          }}
        />

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Were vulnerable individuals affected?
          </h5>

          <div className="d-flex mb-3">
            <span
              className={`${
                (vulnerableIndividuals === "TRUE" && isInitialVulnerable) ||
                (!isInitialVulnerable && isVulnerable === "TRUE")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isVulnerable: "TRUE",
                  isInitialVulnerable: false,
                });
                this.props.vulnerable("TRUE");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">Yes</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (vulnerableIndividuals === "FALSE" && isInitialVulnerable) ||
                (!isInitialVulnerable && isVulnerable === "FALSE")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isVulnerable: "FALSE",
                  isInitialVulnerable: false,
                });
                this.props.vulnerable("FALSE");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">No</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (vulnerableIndividuals === "UNKNOWN" && isInitialVulnerable) ||
                (!isInitialVulnerable && isVulnerable === "UNKNOWN")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isVulnerable: "UNKNOWN",
                  isInitialVulnerable: false,
                });
                this.props.vulnerable("UNKNOWN");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Unknown
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
