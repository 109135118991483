import gql from "graphql-tag";

export const REMOVE_INTEGRATION = gql`
  mutation($id: ID!, $clientMutationId: String) {
    removeIntegration(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
