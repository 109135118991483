import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Image from "../../images/Users/Document.svg";
import {
  Input,
  Form,
  FormGroup,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import PageLeftMenu from "./PageLeftMenu";
import PageRightMenu from "./PageRightMenu";
import PageContent from "./PageContent";

export default class AddPage extends Component {
  static propTypes = {
    isAdminNewPage: PropTypes.bool.isRequired,
    showPages: PropTypes.func.isRequired,
    addNewPageTemplate: PropTypes.func.isRequired,
    addNewPage: PropTypes.func.isRequired,
    selectedPage: PropTypes.string,
    getItem: PropTypes.func,
    updateTitle: PropTypes.func,
    item: PropTypes.object,
    menuItems: PropTypes.array,
    showMenuItems: PropTypes.func,
    dragSection: PropTypes.func,
    deleteSection: PropTypes.func,
    actionNeeded: PropTypes.bool,
    showPreview: PropTypes.func,
    isPreview: PropTypes.bool,
  };

  state = {
    isNewPage: false,
    pageName: null,
    pageDescription: null,
    formErrors: {},
  };

  showNewPage = (e) => {
    e.preventDefault();

    this.setState({
      isNewPage: !this.state.isNewPage,
    });
  };

  getFormValues = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      description = formData.get("description");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!description) {
      hasFormErrors = true;
      formErrors.description = true;
    }

    const formatPageName = format.test(name);

    if (formatPageName) {
      hasFormErrors = true;
      formErrors.format = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.isAdminNewPage) {
      this.props.addNewPageTemplate(name, description);
    } else {
      this.props.addNewPage(name, description);

      this.setState({
        isNewPage: !this.state.isNewPage,
      });

      this.props.showPages();
    }
  };

  redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };

  render() {
    const { isNewPage, pageName, pageDescription } = this.state;
    const { isAdminNewPage } = this.props;

    return (
      <Fragment>
        {!isNewPage ? (
          <div className="add-page container">
            <p className="add-page__title mt-5">
              {isAdminNewPage ? "Add page" : "Add another page"}
            </p>
            <p className="add-page__text">
              {isAdminNewPage
                ? "Add pages to LegalSite such as Terms of Service, Terms of Use, Disclaimer, Cookie Policy, Copyright Notice & Policies, Return & Refund Policy or EULA. Give a name to your page, choose to make them visible or not for users and place content."
                : "You can add other legal pages to your LegalSite such as Terms of Service, Terms of Use, Disclaimer, Cookie Policy, Copyright Notice & Policies, Return & Refund Policy or EULA. Give a name to your page and choose an import method."}
            </p>

            <img src={Image} alt="new page" />

            <Form onSubmit={this.handleSubmit} className="add-page__form">
              <FormGroup className="position-relative">
                <Label className="add-page__label">Page name</Label>
                <Input
                  className="add-page__input"
                  type="text"
                  name="name"
                  invalid={
                    this.state.formErrors.name || this.state.formErrors.format
                  }
                  placeholder="Write page name"
                  onChange={(e) =>
                    this.getFormValues("pageName", e.target.value)
                  }
                />
                <FormFeedback>
                  {this.state.formErrors.name
                    ? "Please provide a page name"
                    : "Please don't use special characters"}
                </FormFeedback>
                <p className="add-page__obs">
                  Obs: This page name and description will be show on your
                  LegalSite page.
                </p>
              </FormGroup>

              <FormGroup>
                <Label className="add-page__label">Add small description</Label>
                <Input
                  className="add-page__input"
                  type="text"
                  invalid={this.state.formErrors.description}
                  name="description"
                  onChange={(e) =>
                    this.getFormValues("pageDescription", e.target.value)
                  }
                  placeholder="Write page's description"
                />
                <FormFeedback>Please provide a page description</FormFeedback>
              </FormGroup>

              <div className="d-flex justify-content-center mb-5">
                <Button
                  onClick={
                    isAdminNewPage
                      ? this.redirectToDashboard
                      : this.props.showPages
                  }
                  className="btn--secondary add-page__btn mr-2"
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="btn--primary add-page__btn ml-2"
                >
                  Continue
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <PageLeftMenu
              selectedPage={this.props.selectedPage}
              getItem={this.props.getItem}
              updateTitle={this.props.updateTitle}
              isNewPage={true}
              pageName={pageName}
              showMenuItems={this.props.showMenuItems}
              dragSection={this.props.dragSection}
              deleteSection={this.props.deleteSection}
            />

            <PageContent
              updateTitle={this.props.updateTitle}
              item={this.props.item}
              menuItems={this.props.menuItems}
              isNewPage={true}
              actionNeeded={this.props.actionNeeded}
              showPreview={this.props.showPreview}
              isPreview={this.props.isPreview}
              selectedPage={this.props.selectedPage}
            />

            <PageRightMenu
              showPage={this.props.showPages}
              pageName={pageName}
              pageDescription={pageDescription}
              getFormValues={this.getFormValues}
              actionNeeded={this.props.actionNeeded}
              showPreview={this.props.showPreview}
              isPreview={this.props.isPreview}
            />
          </div>
        )}
      </Fragment>
    );
  }
}
