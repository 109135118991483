import React from "react";
import PropTypes from "prop-types";

function DataKeeping(props) {
  const {
    account,
    isInitialLifespan,
    is6Months,
    is1Year,
    is3Years,
    is5Years,
    isInitialInboxLifespan,
    is60Days,
    is120Days,
    isInbox1Year,
    setLifespan,
    setInboxLifespan,
  } = props;

  return (
    <div className="d-flex flex-wrap">
      <div className="data-processing__keep">
        <p className="settings__text mb-4 mt-4">
          I want LegalSite to keep <strong>my data</strong> for:
        </p>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanData === "6_MONTHS" &&
                isInitialLifespan) ||
              (!isInitialLifespan && is6Months)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setLifespan(true, false, false, false, false)}
          />
          6 months
        </div>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanData === "1_YEAR" &&
                isInitialLifespan) ||
              (!isInitialLifespan && is1Year)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setLifespan(false, false, true, false, false)}
          />
          1 year
        </div>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanData === "3_YEARS" &&
                isInitialLifespan) ||
              (!isInitialLifespan && is3Years)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setLifespan(false, false, false, true, false)}
          />
          3 years
        </div>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanData === "5_YEARS" &&
                isInitialLifespan) ||
              (!isInitialLifespan && is5Years)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setLifespan(false, false, false, false, true)}
          />
          5 years
        </div>
      </div>

      <div>
        <p className="settings__text mb-4 mt-4">
          I want LegalSite to keep <strong>inbox requests data</strong> for:
        </p>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanInbox === "60_DAYS" &&
                isInitialInboxLifespan) ||
              (!isInitialInboxLifespan && is60Days)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setInboxLifespan(true, false, false, false)}
          />
          60 days
        </div>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanInbox === "120_DAYS" &&
                isInitialInboxLifespan) ||
              (!isInitialInboxLifespan && is120Days)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setInboxLifespan(false, false, true, false)}
          />
          120 days
        </div>

        <div className="d-flex align-items-center settings__user-keep ml-0 mb-3">
          <span
            className={`${
              (account &&
                account.lifespanInbox === "1_YEAR" &&
                isInitialInboxLifespan) ||
              (!isInitialInboxLifespan && isInbox1Year)
                ? "-is-active"
                : ""
            } settings__user-check mr-2`}
            onClick={() => setInboxLifespan(false, false, false, true)}
          />
          1 year
        </div>
      </div>
    </div>
  );
}

export default DataKeeping;
DataKeeping.propTypes = {
  account: PropTypes.object,
  isInitialLifespan: PropTypes.bool,
  is6Months: PropTypes.bool,
  is1Year: PropTypes.bool,
  is3Years: PropTypes.bool,
  is5Years: PropTypes.bool,
  isInitialInboxLifespan: PropTypes.bool,
  is60Days: PropTypes.bool,
  is120Days: PropTypes.bool,
  isInbox1Year: PropTypes.bool,
  setLifespan: PropTypes.func.isRequired,
  setInboxLifespan: PropTypes.func.isRequired,
};
