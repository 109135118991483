import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class DeleteUserModal extends Component {
  static propTypes = {
    openDeleteModal: PropTypes.func.isRequired,
    isDeleteModalVisible: PropTypes.bool.isRequired,
    removeUser: PropTypes.func.isRequired,
    isUsers: PropTypes.bool,
    isDP: PropTypes.bool,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isDeleteModalVisible}
          toggle={this.props.openDeleteModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openDeleteModal}
            />
            <p className="modal-assign__title mb-3">
              Are you sure you want to remove{" "}
              {this.props.isUsers
                ? this.props.isDP
                  ? "these data processors"
                  : "these users"
                : this.props.isDP
                ? "this data processor"
                : "this user"}
            </p>
            <p className="modal-assign__text mb-0 mt-0">
              This step can not be undone.
            </p>

            <div className="discard-changes__btns">
              <Button
                className="btn--secondary"
                onClick={this.props.openDeleteModal}
              >
                Cancel
              </Button>
              <Button className="btn--primary" onClick={this.props.removeUser}>
                Remove
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
