import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import Image from "../../images/Inbox/Illustration2.svg";
import SearchInput from "../SearchInput/SearchInput";
import { SEARCH_DS } from "../../graphql/searchDataSubject";
import { graphql } from "@apollo/client/react/hoc";
import "./modals.scss";

class SelectUser extends Component {
  static propTypes = {
    showSelectUsers: PropTypes.func.isRequired,
    isSelectUsersVisible: PropTypes.bool.isRequired,
    getSelectedUsers: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  state = {
    filteredUsers: null,
    serchingQuery: null,
    selectedUser: null,
  };

  searchUsers = (user) => {
    this.props.data.refetch({ name: user });
  };

  selectUser = (user) => {
    this.setState({
      selectedUser: user,
      filteredUsers: null,
    });
  };

  render() {
    const { selectedUser } = this.state;
    const { searchDataSubject } = this.props.data;

    return (
      <div>
        <Modal
          isOpen={this.props.isSelectUsersVisible}
          toggle={this.props.showSelectUsers}
          className="select-users"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showSelectUsers}
            />

            <p className="select-users__title">Select user</p>
            <p className="select-users__text">
              Select the user that made the request.
            </p>
            <div className="publish-notification__image">
              <img src={Image} alt="email" />
            </div>

            <SearchInput
              search={this.searchUsers}
              placeholder={"Type user name and press enter"}
            />

            {searchDataSubject && searchDataSubject.length ? (
              <div className="select-users__suggestions">
                {searchDataSubject.map((item) => (
                  <p key={item.id} onClick={() => this.selectUser(item)}>
                    {item.name}
                  </p>
                ))}
              </div>
            ) : (
              ""
            )}

            {selectedUser ? (
              <div className="select-users__selected">
                {selectedUser.name}({selectedUser.email})
                <MdClose
                  size={20}
                  className="select-users__remove-selected"
                  onClick={() => this.setState({ selectedUser: null })}
                />
              </div>
            ) : null}

            <div className="d-flex select-users__btns">
              <Button
                className="btn--secondary select-users__cancel"
                onClick={this.props.showSelectUsers}
              >
                Cancel
              </Button>
              <Button
                className="btn--primary select-users__save"
                onClick={() => {
                  this.props.getSelectedUsers("is-from-selected", selectedUser);
                  this.props.showSelectUsers();
                }}
              >
                Save
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(SEARCH_DS, {
  options: () => ({
    variables: {
      name: "",
    },
    fetchPolicy: "no-cache",
  }),
})(SelectUser);
