import gql from "graphql-tag";

export const CREATE_BUSINESS_SUBSCRIPTION = gql`
  mutation($quantity: Int!, $clientMutationId: String) {
    createBusinessSubscription(
      input: { quantity: $quantity, clientMutationId: $clientMutationId }
    ) {
      errors
      chargebeeUrl
    }
  }
`;
