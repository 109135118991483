import gql from "graphql-tag";

export const EDIT_PAGE_TEMPLATE_SECTION = gql`
  mutation($id: ID!, $title: String, $text: String, $clientMutationId: String) {
    editPageTemplateSection(
      input: {
        id: $id
        title: $title
        text: $text
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;