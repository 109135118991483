import React, { Fragment } from "react";
import CustomiseColor from "./CustomiseColor";
import CustomiseText from "./CustomiseText";
import PropTypes from "prop-types";

function CustomizeCards(props) {
  const {
    capitalize,
    changeBgColor,
    changeTextColor,
    getGoogleFont,
    changeFontSize,
    cardsTitleColor,
    cardsTitleSize,
    cardsTitleFont,
    cardsBodyFont,
    cardsBodyColor,
    cardsBodySize,
    cardsBtnFont,
    cardsBtnColor,
    cardsBtnSize,
    cardsIconsColor,
    cardsBg,
    cardsBtnBg,
  } = props;

  return (
    <Fragment>
      <div>
        <CustomiseText
          title="Title Text"
          color={cardsTitleColor ? cardsTitleColor : "#7450C8"}
          size={cardsTitleSize ? cardsTitleSize : 18}
          isLegalsite={true}
          font={cardsTitleFont ? capitalize(cardsTitleFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="cardsTitleColor"
          fontSizeName="cardsTitleSize"
          fontFamilyName="cardsTitleFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={55}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="cardsTitleAlign"
          boldName="cardsTitleBold"
          italicName="cardsTitleItalic"
          boldValue={props.cardsTitleBold}
          italicValue={props.cardsTitleItalic}
          alignValue={props.cardsTitleAlign}
        />
      </div>

      <div>
        <CustomiseText
          title="Body Text"
          color={cardsBodyColor ? cardsBodyColor : "#969696"}
          size={cardsBodySize ? cardsBodySize : 14}
          isLegalsite={true}
          font={cardsBodyFont ? capitalize(cardsBodyFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="cardsBodyColor"
          fontSizeName="cardsBodySize"
          fontFamilyName="cardsBodyFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={56}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="cardsBodyAlign"
          boldName="cardsBodyBold"
          italicName="cardsBodyItalic"
          boldValue={props.cardsBodyBold}
          italicValue={props.cardsBodyItalic}
          alignValue={props.cardsBodyAlign}
        />
      </div>

      <div className="d-flex justify-content-between customize-menu__logo-container pb-4">
        <div>
          <p className="customise-text__label">Background color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="cardsBg"
            color={cardsBg ? cardsBg : "#fff"}
            index={57}
          />
        </div>

        <div>
          <p className="customise-text__label">Icons color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="cardsIconsColor"
            color={cardsIconsColor ? cardsIconsColor : "#7450C8"}
            index={58}
          />
        </div>

        <div>
          <p className="customise-text__label">Button color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="cardsBtnBg"
            color={cardsBtnBg ? cardsBtnBg : "#7450C8"}
            index={59}
          />
        </div>
      </div>

      <div>
        <CustomiseText
          title="Button Text"
          color={cardsBtnColor ? cardsBtnColor : "#fff"}
          size={cardsBtnSize ? cardsBtnSize : 16}
          isLegalsite={true}
          font={cardsBtnFont ? capitalize(cardsBtnFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="cardsBtnColor"
          fontSizeName="cardsBtnSize"
          fontFamilyName="cardsBtnFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={60}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="cardsBtnAlign"
          boldName="cardsBtnBold"
          italicName="cardsBtnItalic"
          boldValue={props.cardsBtnBold}
          italicValue={props.cardsBtnItalic}
          alignValue={props.cardsBtnAlign}
        />
      </div>
    </Fragment>
  );
}

export default CustomizeCards;
CustomizeCards.propTypes = {
  capitalize: PropTypes.func.isRequired,
  changeBgColor: PropTypes.func.isRequired,
  changeTextColor: PropTypes.func.isRequired,
  getGoogleFont: PropTypes.func.isRequired,
  changeFontSize: PropTypes.func.isRequired,
  cardsTitleColor: PropTypes.string,
  cardsTitleSize: PropTypes.number,
  cardsTitleFont: PropTypes.string,
  cardsBodyFont: PropTypes.string,
  cardsBodyColor: PropTypes.string,
  cardsBodySize: PropTypes.number,
  cardsBtnFont: PropTypes.string,
  cardsBtnColor: PropTypes.string,
  cardsBtnSize: PropTypes.number,
  cardsIconsColor: PropTypes.string,
  cardsBg: PropTypes.string,
  cardsBtnBg: PropTypes.string,
  getAligned: PropTypes.func.isRequired,
  getBold: PropTypes.func.isRequired,
  getItalic: PropTypes.func.isRequired,
  cardsTitleBold: PropTypes.bool,
  cardsTitleItalic: PropTypes.bool,
  cardsTitleAlign: PropTypes.string,
  cardsBodyBold: PropTypes.bool,
  cardsBodyItalic: PropTypes.bool,
  cardsBodyAlign: PropTypes.string,
  cardsBtnBold: PropTypes.bool,
  cardsBtnItalic: PropTypes.bool,
  cardsBtnAlign: PropTypes.string,
};
