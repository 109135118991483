import PropTypes from "prop-types";
import React from "react";

const ShareIcon = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1289.000000, -1061.000000)" fill={props.color} fillRule="nonzero">
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-4" transform="translate(0.000000, 52.000000)">
                            <g id="share-alt" transform="translate(1108.000000, 5.000000)">
                                <path d="M12.952381,9.9047619 C12.0408891,9.91078961 11.1799468,10.3244892 10.6057143,11.032381 L6.72,9.24190476 C6.90269566,8.68247464 6.90269566,8.07943012 6.72,7.52 L10.6057143,5.72952381 C11.5520417,6.87144548 13.1830074,7.1663484 14.4692945,6.42811671 C15.7555817,5.68988502 16.3236914,4.13287882 15.8150846,2.73973856 C15.3064779,1.3465983 13.8688386,0.521849679 12.4094737,0.785998876 C10.9501089,1.05014807 9.89277372,2.32649418 9.9047619,3.80952381 C9.90705352,3.99103376 9.92489659,4.17201341 9.95809524,4.35047619 L5.9352381,6.20190476 C4.73814704,5.03139339 2.82096966,5.04574174 1.64153217,6.23403929 C0.462094677,7.42233685 0.462094677,9.33956791 1.64153217,10.5278655 C2.82096966,11.716163 4.73814704,11.7305114 5.9352381,10.56 L9.95809524,12.4114286 C9.92489659,12.5898913 9.90705352,12.770871 9.9047619,12.952381 C9.9047619,14.6355345 11.2692274,16 12.952381,16 C14.6355345,16 16,14.6355345 16,12.952381 C16,11.2692274 14.6355345,9.9047619 12.952381,9.9047619 Z M12.952381,2.28571429 C13.7939577,2.28571429 14.4761905,2.96794705 14.4761905,3.80952381 C14.4761905,4.65110057 13.7939577,5.33333333 12.952381,5.33333333 C12.1108042,5.33333333 11.4285714,4.65110057 11.4285714,3.80952381 C11.4285714,2.96794705 12.1108042,2.28571429 12.952381,2.28571429 Z M3.80952381,9.9047619 C2.96794705,9.9047619 2.28571429,9.22252914 2.28571429,8.38095238 C2.28571429,7.53937562 2.96794705,6.85714286 3.80952381,6.85714286 C4.65110057,6.85714286 5.33333333,7.53937562 5.33333333,8.38095238 C5.33333333,9.22252914 4.65110057,9.9047619 3.80952381,9.9047619 Z M12.952381,14.4761905 C12.1108042,14.4761905 11.4285714,13.7939577 11.4285714,12.952381 C11.4285714,12.1108042 12.1108042,11.4285714 12.952381,11.4285714 C13.7939577,11.4285714 14.4761905,12.1108042 14.4761905,12.952381 C14.4761905,13.7939577 13.7939577,14.4761905 12.952381,14.4761905 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ShareIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default ShareIcon;