import React, { Component } from "react";
import { ADMIN_PAGE_QUERY } from "../../graphql/adminPageQuery";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import SideMenu from "../../components/SideMenu/SideMenu";
import { ADD_TEMPLATE_SECTION } from "../../graphql/addSectionToTemplate";
import { EDIT_PAGE_SECTION } from "../../graphql/editPageSection";
import { TOGGLE_SECTION } from "../../graphql/toggleSection";
import { PUBLISH_PAGE_TEMPLATE } from "../../graphql/createTemplatePublication";
import { UPDATE_PAGE_TEMPLATE } from "../../graphql/editPageTemplate";
import { REMOVE_SECTION } from "../../graphql/removeSection";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import axios from "axios";
import { REVERT_TEMPLATE } from "../../graphql/revertTemplateToPublication";
import { ToastContainer, toast } from "react-toastify";
import { REMOVE_PAGE_TEMPLATE } from "../../graphql/removePageTemplate";
import { EDIT_HELP } from "../../graphql/editHelp";
import Navbar from "../../components/Navbar/Navbar";
import { EDIT_POSITION_SECTION } from "../../graphql/editPositionSection";
import PageLeftMenu from "./PageLeftMenu";
import PageContent from "./PageContent";
import PageRightMenu from "./PageRightMenu";
import PropTypes from "prop-types";
import AddPage from "./AddPage";

class AdminPage extends Component {
  static propTypes = {
    pagesQuery: PropTypes.object,
    addSection: PropTypes.func,
    editPageSection: PropTypes.func,
    updatePageTemplate: PropTypes.func,
    editPositionSection: PropTypes.func,
    toggleSection: PropTypes.func,
    publishPage: PropTypes.func,
    editHelp: PropTypes.func,
    deletePageTemplate: PropTypes.func,
    revertTemplate: PropTypes.func,
    removeSection: PropTypes.func,
    location: PropTypes.object,
  };

  state = {
    page: null,
    activeItemIndex: 1,
    resetTitle: false,
    resetText: false,
    item: null,
    activeItem: null,
    itemText: null,
    isPreview: false,
    isAddingPage: false,
    isNotCurrentVersion: false,
    otherVersionId: null,
    activePublicationId: null,
    isDeleting: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.page) {
      this.setState({
        page: this.props.location.state.page,
      });
    }

    if (this.props.location.state && this.props.location.state.newPage) {
      this.setState({
        isAddingPage: !this.state.isAddingPage,
      });
    }
  }

  getItem = (item, activeItem, index) => {
    this.setState({
      item,
      activeItem,
      itemText: activeItem.text,
      activeItemIndex: index,
    });

    this.resetTitle(true);
    this.resetText(true);
  };

  addSection = () => {
    this.props
      .addSection({
        variables: {
          templateId: this.state.page.id,
          title: "Write a title",
          text: "Customize",
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editSection = (title, text, id, readOnly) => {
    if (!title || !text) {
      return;
    }

    this.props
      .editPageSection({
        variables: {
          id,
          title,
          text,
          readOnly: readOnly ? readOnly : false,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateTitle = (title) => {
    this.setState({
      item: title,
    });
  };

  updateText = (text) => {
    this.setState({
      itemText: text,
    });
  };

  resetTitle = (value) => {
    this.setState({
      resetTitle: value,
    });
  };

  resetText = (value) => {
    this.setState({
      resetText: value,
    });
  };

  makeSectionApplicable = (id) => {
    this.props
      .toggleSection({
        variables: {
          id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  showPreview = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });
  };

  publishPage = () => {
    this.props
      .publishPage({
        variables: {
          templateId: this.state.page.id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deletePage = () => {
    this.props
      .deletePageTemplate({
        variables: {
          id: this.state.page.id,
        },
      })
      .then(() => {
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  submitPageTemplateDetails = (title, description, id) => {
    this.props
      .updatePageTemplate({
        variables: {
          title,
          description,
          id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  editHelpText = (text, id) => {
    console.log(text, id);
    this.props
      .editHelp({
        variables: {
          sectionId: id,
          help: text,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  notify = (text) => toast(text);

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  showOtherVersion = (id) => {
    this.setState({
      isNotCurrentVersion: true,
      otherVersionId: id,
      activeItemIndex: 1,
    });
  };

  showCurrentVersion = () => {
    this.setState({
      isNotCurrentVersion: false,
      otherVersionId: null,
      activeItemIndex: 1,
    });
  };

  getActivePublicationId = (id) => {
    this.setState({
      activePublicationId: id,
    });
  };

  useVersion = (id, pageId) => {
    this.props
      .revertTemplate({
        variables: {
          pageTemplateId: pageId,
          templatePublicationId: id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  dragSection = (item, newIndex) => {
    this.props
      .editPositionSection({
        variables: {
          id: item.id,
          position: newIndex + 1,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteSection = (id) => {
    this.setState({
      isDeleting: true,
    });
    this.props
      .removeSection({
        variables: {
          id,
        },
      })
      .then(() => {
        this.setState({
          activeItemIndex: this.state.activeItemIndex - 1,
        });
        this.props.pagesQuery.refetch();

        setTimeout(() => {
          this.setState({ isDeleting: false });
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { page, isAddingPage } = this.state;
    const { pageTemplates } = this.props.pagesQuery;
    const pageId = page ? page.id : null;

    const actualPage = pageTemplates
      ? pageTemplates.filter((item) => item.id === pageId)
      : null;

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          <Navbar
            title="Pages"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <div className="pages">
            {!isAddingPage ? (
              <div className="pages__content d-flex justify-content-between">
                <PageLeftMenu
                  isAdmin={true}
                  deleteSection={this.deleteSection}
                  pageTemplate={actualPage ? actualPage[0] : null}
                  contentIndex={this.state.activeItemIndex}
                  isNotCurrentVersion={this.state.isNotCurrentVersion}
                  getItem={this.getItem}
                  dragSection={this.dragSection}
                  addSection={this.addSection}
                  isPreview={this.state.isPreview}
                  activePublicationId={this.state.activePublicationId}
                  isDeleting={this.state.isDeleting}
                />

                <PageContent
                  isAdmin={true}
                  pageTemplate={actualPage ? actualPage[0] : null}
                  activeItemIndex={this.state.activeItemIndex}
                  activePublicationId={this.state.activePublicationId}
                  shouldTitleReset={this.state.resetTitle}
                  shouldTextReset={this.state.resetText}
                  updateText={this.updateText}
                  isNotCurrentVersion={this.state.isNotCurrentVersion}
                  resetTitle={this.resetTitle}
                  resetText={this.resetText}
                  updateTitle={this.updateTitle}
                  isPreview={this.state.isPreview}
                  showPreview={this.showPreview}
                  otherVersionId={this.state.otherVersionId}
                  editSection={this.editSection}
                  makeSectionApplicable={this.makeSectionApplicable}
                />

                <PageRightMenu
                  isAdminPage={true}
                  pageTemplate={actualPage ? actualPage[0] : null}
                  activeItemIndex={this.state.activeItemIndex}
                  showPreview={this.showPreview}
                  useVersion={this.useVersion}
                  activePublicationId={this.state.activePublicationId}
                  isPreview={this.state.isPreview}
                  getActivePublicationId={this.getActivePublicationId}
                  publishPage={this.publishPage}
                  deletePage={this.deletePage}
                  showCurrentVersion={this.showCurrentVersion}
                  showOtherVersion={this.showOtherVersion}
                  submitPageTemplateDetails={this.submitPageTemplateDetails}
                  editHelpText={this.editHelpText}
                  download={this.download}
                />
              </div>
            ) : (
              <AddPage
                isAdminNewPage={true}
                showAdminPage={() =>
                  this.setState({ isAddingPage: !this.state.isAddingPage })
                }
                dragSection={this.dragSection}
              />
            )}
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(ADMIN_PAGE_QUERY, {
    name: "pagesQuery",
  }),
  graphql(ADD_TEMPLATE_SECTION, {
    name: "addSection",
  }),
  graphql(EDIT_PAGE_SECTION, {
    name: "editPageSection",
  }),
  graphql(UPDATE_PAGE_TEMPLATE, {
    name: "updatePageTemplate",
  }),
  graphql(EDIT_POSITION_SECTION, {
    name: "editPositionSection",
  }),
  graphql(TOGGLE_SECTION, {
    name: "toggleSection",
  }),
  graphql(PUBLISH_PAGE_TEMPLATE, {
    name: "publishPage",
  }),
  graphql(EDIT_HELP, {
    name: "editHelp",
  }),
  graphql(REMOVE_PAGE_TEMPLATE, {
    name: "deletePageTemplate",
  }),
  graphql(REVERT_TEMPLATE, {
    name: "revertTemplate",
  }),
  graphql(REMOVE_SECTION, {
    name: "removeSection",
  })
)(AdminPage);
