import React from "react";
import PropTypes from "prop-types";
import CookiesIcon from "../../components/Icons/Cookies";
import Site from "../../components/Icons/Site";
import Mail from "../../components/Icons/Mail";
import Sell from "../../components/Icons/Sell";
import MdArrow from "react-icons/lib/ti/arrow-right";

function CustomizationItem(props) {
  const { title, text } = props;

  return (
    <div
      className="settings__customization"
      onClick={() => props.showCustomization(title)}
    >
      {props.title === "LegalSite" ? (
        <CookiesIcon className="settings__customization-icon" />
      ) : props.title === "E-mail templates" ? (
        <Mail className="settings__customization-icon" />
      ) : props.title === "Don’t sell data Button" ? (
        <Sell className="settings__customization-icon" />
      ) : (
        <Site color="#7450C8" className="settings__customization-icon" />
      )}
      <div>
        <h4 className="settings__customization-title">{title}</h4>
        <p className="settings__customization-text">{text}</p>
      </div>

      <MdArrow className="settings__customization-arrow" color="#7450C8" />
    </div>
  );
}

export default CustomizationItem;
CustomizationItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showCustomization: PropTypes.func.isRequired,
};
