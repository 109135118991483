import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import "./modals.scss";
import SignaturePad from "react-signature-canvas";
import ReactFilestack from "filestack-react";
import * as filestack from "filestack-js";
import TrashIcon from "../Icons/TrashIcon";
import Webcam from "react-webcam";
import { ADD_SIGNATURE_TO_PAGES } from "../../graphql/addSignatureToPages";
import { graphql } from "@apollo/client/react/hoc";

const client = filestack.init("AJNM9qOpGRljTn17sgxrfz");

class AddSignature extends Component {
  static propTypes = {
    showSignatureModal: PropTypes.func.isRequired,
    isShowSignatureModalVisible: PropTypes.bool.isRequired,
    addSignature: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
  };

  state = {
    btnActive: "draw",
    showBtns: false,
    trimmedDataURL: null,
    files: [],
    screenshot: null,
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  clear = () => {
    this.sigPad.clear();

    this.setState({
      showBtns: false,
    });
  };

  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
    });

    const file = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
    const name = "signature";

    const token = {};
    const onRetry = (obj) => {
      console.log(
        `Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`
      );
    };

    client
      .upload({ file, name }, { onRetry }, { filename: "signature.jpg" }, token)
      .then((res) => this.saveSignature(res.url));
  };

  saveSignature = (img) => {
    this.props
      .addSignature({
        variables: {
          signatureUrl: img,
        },
      })
      .then(() => {
        this.setState({
          btnActive: "draw",
          showBtns: false,
          trimmedDataURL: null,
          files: [],
          screenshot: null,
        });
        this.props.notify();
        this.props.showSignatureModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  capture = () => {
    // eslint-disable-next-line
    const screenshot = this.refs.webcam.getScreenshot();
    this.setState({ screenshot: screenshot });

    const file = screenshot;
    const name = "signature";

    const token = {};
    const onRetry = (obj) => {
      console.log(
        `Retrying ${obj.location} for ${obj.filename}. Attempt ${obj.attempt} of 10.`
      );
    };

    client
      .upload({ file, name }, { onRetry }, { filename: "signature.jpg" }, token)
      .then((res) => this.saveSignature(res.url));
  };

  render() {
    const { btnActive, showBtns, files } = this.state;
    return (
      <div>
        <Modal
          isOpen={this.props.isShowSignatureModalVisible}
          toggle={this.props.showSignatureModal}
          className="modal-signature"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showSignatureModal}
            />
            <p className="modal-assign__title mt-5 mb-4">Add Signature</p>

            <div className="d-flex modal-signature__btns">
              <Button
                className={`btn--secondary modal-signature__btn ${
                  btnActive !== "draw" ? "-is-disabled" : ""
                }`}
                onClick={() =>
                  this.setState({
                    btnActive: "draw",
                    showBtns: false,
                  })
                }
              >
                Draw
              </Button>
              <Button
                className={`btn--secondary modal-signature__btn ${
                  btnActive !== "upload" ? "-is-disabled" : ""
                }`}
                onClick={() =>
                  this.setState({
                    btnActive: "upload",
                    showBtns: false,
                  })
                }
              >
                Upload
              </Button>
              <Button
                className={`btn--secondary modal-signature__btn ${
                  btnActive !== "camera" ? "-is-disabled" : ""
                }`}
                onClick={() =>
                  this.setState({
                    btnActive: "camera",
                    showBtns: false,
                  })
                }
              >
                Camera
              </Button>
            </div>

            <p className="text-center modal-signature__title">
              {btnActive === "draw"
                ? "Sign below using your trackpad or mouse."
                : btnActive === "camera"
                ? "Sign your name on white paper and hold it in front of the camera."
                : "Upload an image from your library."}
            </p>
            {btnActive === "draw" ? (
              <div onClick={() => this.setState({ showBtns: true })}>
                <SignaturePad
                  canvasProps={{
                    width: 500,
                    height: 121,
                    className: "sigCanvas border-bottom",
                  }}
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                />
              </div>
            ) : btnActive === "upload" ? (
              <Fragment>
                {files.length ? (
                  <div className="modal-signature__file mb-4">
                    <div className="modal-signature__file-type">JPG</div>
                    <p className="modal-signature__file-name">
                      {files[0].filename}
                    </p>
                  </div>
                ) : (
                  <ReactFilestack
                    apikey={"AJNM9qOpGRljTn17sgxrfz"}
                    componentDisplayMode={{
                      type: "link",
                      customText: (
                        <Button className="btn--primary mt-5 mx-auto d-flex align-items-center justify-content-center">
                          Upload Signature
                        </Button>
                      ),
                      customClass: "show-upload",
                    }}
                    onSuccess={this.onFiles}
                    actionOptions={{
                      accept: ["image/*"],
                      maxFiles: 1,
                    }}
                  />
                )}

                {files.length ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Button
                      className="btn--primary modal-signature__btn mr-4 mb-5"
                      onClick={() =>
                        this.saveSignature(this.state.files[0].url)
                      }
                    >
                      Save
                    </Button>
                    <div
                      className="modal-signature__clear mb-5"
                      onClick={() => this.setState({ files: [] })}
                    >
                      <TrashIcon color="#7450c8" />
                      &nbsp; Clear
                    </div>
                  </div>
                ) : null}
              </Fragment>
            ) : (
              <Fragment>
                <Webcam
                  audio={false}
                  height={220}
                  // eslint-disable-next-line
                  ref="webcam"
                  className="mx-auto d-flex"
                  screenshotFormat="image/jpeg"
                  width={500}
                  videoConstraints={{
                    width: 500,
                    height: 220,
                    facingMode: "user",
                  }}
                />

                <Button
                  className="btn--primary modal-signature__btn mr-4 mb-5 mt-4 mx-auto d-flex align-items-center justify-content-center"
                  onClick={this.capture}
                >
                  Take photo
                </Button>
              </Fragment>
            )}

            {showBtns ? (
              <div className="d-flex align-items-center justify-content-center">
                <Button
                  className="btn--primary modal-signature__btn mr-4 mb-5"
                  onClick={this.trim}
                >
                  Save
                </Button>
                <div
                  className="modal-signature__clear mb-5"
                  onClick={this.clear}
                >
                  <TrashIcon color="#7450c8" />
                  &nbsp; Clear
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(ADD_SIGNATURE_TO_PAGES, {
  name: "addSignature",
})(AddSignature);
