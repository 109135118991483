import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Alert, Row, Col } from "reactstrap";
import UsersIcon from "./../../components/Icons/UsersIcon";
import OverviewCard from "./../../components/OverviewCard/OverviewCard";
import StatusCard from "./../../components/StatusCard/StatusCard";
import EmptyDashboard from "../../components/EmptyDashboard/EmptyDashboard";
import SyncLoader from "react-spinners/SyncLoader";
import Building from "../../components/Icons/Building";
import BookPurple from "../../components/Icons/BookPurple";
import { Redirect } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { DASHBOARD_QUERY } from "../../graphql/dashboard";
import "react-toastify/dist/ReactToastify.css";
import "./dashboard.scss";
import PagesCard from "./PagesCard";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import AdminActions from "./AdminActions";
import PropTypes from "prop-types";

class AdminDashboard extends Component {
  render() {
    const { loading, error, pageTemplates, dashboardStatsAdmin } =
      this.props.dashboardStats;
    const { viewer } = this.props.viewer;
    const isAdminEmpty =
      dashboardStatsAdmin &&
      dashboardStatsAdmin.pendingRequests === 0 &&
      dashboardStatsAdmin.requestsInProgress === 0 &&
      dashboardStatsAdmin.solvedRequests === 0 &&
      dashboardStatsAdmin.totalUsers === 0 &&
      dashboardStatsAdmin.totalClients === 0 &&
      dashboardStatsAdmin.totalLawyers === 0;

    if (localStorage.getItem("auth-token") === null) {
      return <Redirect to={{ pathname: "/signin" }} />;
    }

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div>
        <div className="">
          <div className="info">
            {!isAdminEmpty ? (
              <div className="d-flex container info__container pr-0 align-items-end">
                <div className="info__overview">
                  <Row className="">
                    <Col md={6} lg={4} className="p-0">
                      <div className="overview-card d-flex">
                        <div className="overview-card__icon">
                          <Building />
                        </div>

                        <div className="overview-card__stats">
                          <h3 className="mb-2 overview-card__numbers text-black">
                            {dashboardStatsAdmin &&
                            dashboardStatsAdmin.totalClients
                              ? dashboardStatsAdmin.totalClients
                              : 0}
                          </h3>
                          <p className="mb-2 overview-card__text">Clients</p>

                          <div>
                            <Link to="/clients" className="overview-card__more">
                              See more{" "}
                              <MdArrow size={20} className="ml-2 my-0" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} lg={4} className="p-0">
                      <div className="overview-card d-flex">
                        <div className="overview-card__icon">
                          <UsersIcon color={"#7450C8"} />
                        </div>

                        <div className="overview-card__stats">
                          <h3 className="overview-card__numbers mb-2 text-black">
                            {dashboardStatsAdmin &&
                            dashboardStatsAdmin.totalUsers
                              ? dashboardStatsAdmin.totalUsers
                              : 0}
                          </h3>
                          <p className="mb-2 overview-card__text">Users</p>
                          <div>
                            <Link
                              to="users-admin"
                              className="overview-card__more"
                            >
                              See more{" "}
                              <MdArrow size={20} className="ml-2 my-0" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} lg={4} className="p-0">
                      <div className="overview-card d-flex">
                        <div className="overview-card__icon">
                          <BookPurple color={"#7450C8"} />
                        </div>

                        <div className="overview-card__stats">
                          <h3 className="overview-card__numbers mb-2 text-black">
                            {dashboardStatsAdmin &&
                            dashboardStatsAdmin.totalLawyers
                              ? dashboardStatsAdmin.totalLawyers
                              : 0}
                          </h3>
                          <p className="mb-2 overview-card__text">Lawyers</p>
                          <div>
                            <Link to="/lawyers" className="overview-card__more">
                              See more{" "}
                              <MdArrow size={20} className="ml-2 my-0" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} lg={4} className="p-0">
                      <OverviewCard
                        stats={dashboardStatsAdmin.solvedRequests}
                        text="Solved requests"
                        color="aqua"
                        percentage={
                          (100 * dashboardStatsAdmin.solvedRequests) /
                          dashboardStatsAdmin.totalRequests
                        }
                      />
                    </Col>

                    <Col md={6} lg={4} className="p-0">
                      <OverviewCard
                        stats={dashboardStatsAdmin.requestsInProgress}
                        text="Requests in progress"
                        color="purple"
                        percentage={
                          (100 * dashboardStatsAdmin.requestsInProgress) /
                          dashboardStatsAdmin.totalRequests
                        }
                      />
                    </Col>

                    <Col md={6} lg={4} className="p-0">
                      <OverviewCard
                        stats={dashboardStatsAdmin.pendingRequests}
                        text="Pending requests"
                        color="coral"
                        percentage={
                          (100 * dashboardStatsAdmin.pendingRequests) /
                          dashboardStatsAdmin.totalRequests
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div className="info__legal">
                  <StatusCard
                    showPreviewModal={this.showPreviewModal}
                    showNotify={this.showNotify}
                    isAdmin={true}
                    dashboardStatsAdmin={dashboardStatsAdmin}
                  />
                </div>
              </div>
            ) : (
              <EmptyDashboard
                name={viewer && viewer.name ? viewer.name : ""}
                isAdmin={true}
              />
            )}

            <PagesCard pages={pageTemplates} />

            <AdminActions />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(DASHBOARD_QUERY, {
    name: "dashboardStats",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(AdminDashboard);

AdminDashboard.propTypes = {
  dashboardStats: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    pageTemplates: PropTypes.array,
    dashboardStatsAdmin: PropTypes.shape({
      pendingRequests: PropTypes.number.isRequired,
      requestsInProgress: PropTypes.number.isRequired,
      solvedRequests: PropTypes.number.isRequired,
      totalUsers: PropTypes.number.isRequired,
      totalClients: PropTypes.number.isRequired,
      totalLawyers: PropTypes.number.isRequired,
      totalRequests: PropTypes.number.isRequired,
    }),
  }).isRequired,
  viewer: PropTypes.shape({
    viewer: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};
