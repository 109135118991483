import gql from "graphql-tag";

export const STEP2EMAIL_QUERY = gql`
  {
    account {
      companyName
      companyLogo
      id
    }
  }
`;
