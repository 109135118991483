import PropTypes from "prop-types";
import React from "react";

const Sell = props => (
    <svg
        height={24}
        viewBox='0 0 20 24'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="App-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="9.2--Dont-sell---settings" transform="translate(-1106.000000, -1260.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Group-10" transform="translate(181.000000, 904.000000)">
                    <g id="customization" transform="translate(0.000000, 270.000000)">
                        <g id="emails" transform="translate(825.000000, 52.000000)">
                            <path d="M118.761881,35.9786458 C118.479424,35.7501569 118.108682,35.6618851 117.753565,35.7385706 C115.161048,36.2818956 112.459241,35.7302405 110.287227,34.2140934 C109.875745,33.9286355 109.330281,33.9286355 108.918799,34.2140934 C106.746785,35.7302405 104.044979,36.2818956 101.452462,35.7385706 C101.097344,35.6618851 100.726603,35.7501569 100.444146,35.9786458 C100.162068,36.2074735 99.9987719,36.5517191 100.000007,36.9149389 L100.000007,45.8577385 C99.9978417,49.3496624 101.68361,52.6272976 104.525424,54.6564931 L108.906795,57.7774701 C109.323523,58.0741766 109.882504,58.0741766 110.299231,57.7774701 L114.680603,54.6564931 C117.522416,52.6272976 119.208185,49.3496624 119.20602,45.8577385 L119.20602,36.9149389 C119.207255,36.5517191 119.043959,36.2074735 118.761881,35.9786458 Z M116.805268,45.8577385 C116.807136,48.5727241 115.497073,51.1213106 113.288167,52.6998805 L109.603013,55.3287035 L105.91786,52.6998805 C103.708953,51.1213106 102.39889,48.5727241 102.400759,45.8577385 L102.400759,38.2953711 C104.91726,38.5107581 107.437584,37.9268832 109.603013,36.6268487 C111.768442,37.9268832 114.288766,38.5107581 116.805268,38.2953711 L116.805268,45.8577385 Z M111.451592,43.108878 L108.222581,46.3498926 L107.154247,45.2695544 C106.683553,44.7988604 105.920407,44.7988605 105.449713,45.2695544 C104.979019,45.7402484 104.979019,46.5033941 105.449713,46.974088 L107.370314,48.8946893 C107.595705,49.1219356 107.902516,49.2497584 108.222581,49.2497584 C108.542646,49.2497584 108.849457,49.1219356 109.074848,48.8946893 L113.204141,44.8014078 C113.674835,44.3307139 113.674835,43.5675682 113.204141,43.0968742 C112.733447,42.6261803 111.970301,42.6261803 111.499607,43.0968742 L111.451592,43.108878 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Sell.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Sell;