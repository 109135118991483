import gql from "graphql-tag";

export const ADD_USER = gql`
  mutation(
    $fullName: String!
    $email: String!
    $jobTitle: String!
    $role: UserRoleEnum!
    $phone: String
    $avatar: String!
  ) {
    addUserToAccount(
      input: {
        user: {
          fullName: $fullName
          email: $email
          jobTitle: $jobTitle
          role: $role
          phone: $phone
          avatar: $avatar
        }
      }
    ) {
      account {
        id
        fullName
        email
        users {
          name
          id
          email
          phoneNumber
          avatar
          jobTitle
        }
      }
    }
  }
`;
