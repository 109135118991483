import gql from "graphql-tag";

export const REVIEW_CHANGES = gql`
  mutation(
    $templatePublicationId: ID!
    $alertId: ID!
    $approve: Boolean!
    $clientMutationId: String
  ) {
    reviewNewTemplatePublication(
      input: {
        templatePublicationId: $templatePublicationId
        alertId: $alertId
        approve: $approve
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      page {
        id
        sections {
          id
          applicable
        }
        applicable
        
      }
    }
  }
`;
