import gql from "graphql-tag";

export const REMOVE_DATA_CATEGORY = gql`
  mutation($id: ID!, $clientMutationId: String) {
    removeDataCategory(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
