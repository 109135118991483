import gql from "graphql-tag";

export const FORGOT_PASSWORD = gql`
  mutation($email: String!, $clientMutationId: String) {
    forgotPassword(
      input: { email: $email, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
