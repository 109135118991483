import gql from "graphql-tag";

export const PUBLISH_PAGE_TEMPLATE = gql`
  mutation($templateId: ID!, $clientMutationId: String) {
    createTemplatePublication(
      input: { templateId: $templateId, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
