import PropTypes from "prop-types";
import React from "react";

const Upload = props => (
    <svg
        height={30}
        viewBox='0 0 55 30'
        width={55}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <defs>
            <filter x="-6.2%" y="-6.9%" width="114.1%" height="115.7%" filterUnits="objectBoundingBox" id="filter-1">
                <feOffset dx="-7" dy="8" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.2172589   0 0 0 0 0.276448458   0 0 0 0 0.374003917  0 0 0 0.0701709692 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"></feColorMatrix>
                <feMerge>
                    <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                    <feMergeNode in="SourceGraphic"></feMergeNode>
                </feMerge>
            </filter>
        </defs>
        <g id="Journey-4---Inbox" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.4--data-access---data-uploaded" transform="translate(-845.000000, -552.000000)" fill={props.color} fillRule="nonzero">
                <g id="Inbox" transform="translate(489.000000, 98.000000)">
                    <g id="Mail" filter="url(#filter-1)" transform="translate(118.000000, 162.000000)">
                        <g id="Group" transform="translate(82.000000, 241.000000)">
                            <g id="Group-5" transform="translate(177.000000, 58.000000)">
                                <g id="cloud-upload">
                                    <path d="M17.42,4.22 C16.1641825,1.33434404 13.1370166,-0.358508981 10.0209667,0.0823094551 C6.90491678,0.523127891 4.46610694,2.98923647 4.06,6.11 C2.08648649,6.58261454 0.779592496,8.45815024 1.01966358,10.473215 C1.25973467,12.4882797 2.97068933,14.0042745 5,14 C5.55228475,14 6,13.5522847 6,13 C6,12.4477153 5.55228475,12 5,12 C3.8954305,12 3,11.1045695 3,10 C3,8.8954305 3.8954305,8 5,8 C5.55228475,8 6,7.55228475 6,7 C6.00527496,4.55768714 7.77419383,2.47657703 10.1837489,2.07787367 C12.5933039,1.67917032 14.9382985,3.07956095 15.73,5.39 C15.8464705,5.74013872 16.1462981,5.99768288 16.51,6.06 C17.8815165,6.31918525 18.8930855,7.49002661 18.9503984,8.88464116 C19.0077114,10.2792557 18.0956247,11.5291514 16.75,11.9 C16.4034524,11.9893164 16.1308789,12.2567291 16.0349554,12.6015064 C15.9390318,12.9462836 16.0343313,13.3160455 16.2849554,13.5715064 C16.5355794,13.8269672 16.9034524,13.9293164 17.25,13.84 C19.4124221,13.2685883 20.9323148,11.3305641 20.9718336,9.09426837 C21.0113523,6.85797259 19.5608828,4.86745767 17.42,4.22 Z M11.71,6.29 C11.6148963,6.19895937 11.5027513,6.12759439 11.38,6.08 C11.1365386,5.97998198 10.8634614,5.97998198 10.62,6.08 C10.4972487,6.12759439 10.3851037,6.19895937 10.29,6.29 L7.29,9.29 C6.89787783,9.68212217 6.89787783,10.3178778 7.29,10.71 C7.68212217,11.1021222 8.31787783,11.1021222 8.71,10.71 L10,9.41 L10,15 C10,15.5522847 10.4477153,16 11,16 C11.5522847,16 12,15.5522847 12,15 L12,9.41 L13.29,10.71 C13.4777666,10.8993127 13.7333625,11.0057983 14,11.0057983 C14.2666375,11.0057983 14.5222334,10.8993127 14.71,10.71 C14.8993127,10.5222334 15.0057983,10.2666375 15.0057983,10 C15.0057983,9.73336246 14.8993127,9.4777666 14.71,9.29 L11.71,6.29 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Upload.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Upload;