import gql from "graphql-tag";

export const ADD_DATA_CATEGORY_TO_PIA = gql`
  mutation($name: String!, $clientMutationId: String) {
    addDataCategoryToPia(
      input: { clientMutationId: $clientMutationId, name: $name }
    ) {
      errors
    }
  }
`;
