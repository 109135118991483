import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import Image from "../../images/Inbox/Illustration.svg";
import { Link } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";
import "./modals.scss";

export default class PublishNotification extends Component {
  static propTypes = {
    showPublishNotification: PropTypes.func.isRequired,
    isPublishNotificationVisible: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isPublishNotificationVisible}
          toggle={this.props.showPublishNotification}
          className="publish-notification"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showPublishNotification}
            />
            <div className="publish-notification__image">
              <img src={Image} alt="email" />
            </div>
            <p className="modal-assign__title mt-5">E-mail sent!</p>

            <p className="modal-assign__text">
              You have successfully published the changes on your LegalSite and
              notified your Data Subjects about updating your page.
            </p>

            <Link to="/dashboard" className="modal-assign__link">
              Dashboard <MdArrow size={23} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
