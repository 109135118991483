import gql from "graphql-tag";

export const EDIT_DATA_CATEGORY = gql`
  mutation(
    $id: ID!
    $sources: [String!]
    $sourceSuppliers: [String!]
    $purposes: [String!]
    $legalBasis: [String!]
    $retentionPeriod: String
    $actionRequired: [String!]
    $elements: [String!]
    $clientMutationId: String
  ) {
    editDataCategory(
      input: {
        id: $id
        sources: $sources
        sourceSuppliers: $sourceSuppliers
        purposes: $purposes
        legalBasis: $legalBasis
        retentionPeriod: $retentionPeriod
        actionRequired: $actionRequired
        elements: $elements
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      dataCategory {
        id
        sourceSuppliers
        purposes
        legalBasis
        retentionPeriod
        actionRequired
        elements
      }
    }
  }
`;
