import React, { Component, Fragment } from "react";
import {
  Button,
  Form,
  Input,
  FormGroup,
  FormFeedback,
  Alert,
} from "reactstrap";
import DP from "../../images/Settings/Illustration.svg";
import Cloud from "../../images/Dashboard/cloud.svg";
import Check from "../../images/Dashboard/check.svg";
import { ToastContainer, toast } from "react-toastify";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import SyncLoader from "react-spinners/SyncLoader";
import MdClose from "react-icons/lib/md/close";
import { REMOVE_INTEGRATION } from "../../graphql/removeIntegration";
import { CREATE_INTEGRATION } from "../../graphql/createIntegration";
import { DATA_PROCESSOR_QUERY } from "../../graphql/dataProcessorQuery";
import { CONNECT_TO_INTEGRATION } from "../../graphql/connectToIntegration";
import { CREATE_DP_CONSENT } from "../../graphql/createDataProcessorConsent";
import { EDIT_DP_CONSENT } from "../../graphql/editDataProcessorConsent";
import { EDIT_API_KEY } from "../../graphql/editApiKey";
import { REMOVE_DP_CONSENT } from "../../graphql/removeDataProcessorConsent";
import "./data-processors.scss";
import CompanyDetails from "./CompanyDetails";
import Image from "../../images/Users/Illustration.svg";
import ApiKey from "./ApiKey";
import PropTypes from "prop-types";
import AddCookie from "./AddCookie";
import DPA from "./DPA";

class DataProcessor extends Component {
  static propTypes = {
    dataProcessor: PropTypes.object.isRequired,
    isCurrent: PropTypes.bool,
    isIntegrations: PropTypes.bool,
    isAdmin: PropTypes.bool,
    showDP: PropTypes.func,
    getDP: PropTypes.func,
    editDP: PropTypes.func,
    showDeleteModal: PropTypes.func,
    getId: PropTypes.func,
    refetchData: PropTypes.func,
    removeIntegration: PropTypes.func,
    removeDPConsent: PropTypes.func,
    editApiKey: PropTypes.func,
    editDPConsent: PropTypes.func,
    createDPConsent: PropTypes.func,
    createIntegration: PropTypes.func,
    integration: PropTypes.object,
    connectToIntegration: PropTypes.func,
  };

  state = {
    dataProcessor:
      this.props.isCurrent && this.props.isIntegrations
        ? this.props.dataProcessor.dataProcessor
        : this.props.isCurrent && this.props.isIntegrations
        ? this.props.dataProcessor
        : this.props.dataProcessor,
    isCurrent: this.props.isCurrent,
    isRighMenuOpen: false,
    addDP: false,
    isDPAdded: false,
    isCookie: false,
    formErrors: {},
    id: null,
  };

  removeDataProcessor = () => {
    this.props
      .removeIntegration({
        variables: {
          id: this.props.dataProcessor.id,
        },
      })
      .then(() => {
        window.location.href = !this.props.isIntegrations
          ? "/data-processors"
          : "/integrations";
      })
      .catch((error) => {
        console.log(error);
        this.notify("Data Processor couldn't be removed!");
      });
  };

  showNewDP = () => {
    this.setState({
      addDP: !this.state.addDP,
    });
  };

  showSuccessPage = () => {
    this.setState({
      isDPAdded: !this.state.isDPAdded,
    });
  };

  showCookiePage = () => {
    this.setState({
      isCookie: !this.state.isCookie,
    });
  };

  notify = (text) => toast(text);

  handleCookie = () => {
    this.showSuccessPage();
  };

  removeDPConsent = (id) => {
    this.props
      .removeDPConsent({
        variables: {
          id,
        },
      })
      .then(() => {
        this.notify("Changes saved");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes couldn't be saved");
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const companyName = formData.get("companyName"),
      contactName = formData.get("contactName"),
      email = formData.get("email");

    let hasFormErrors = false;
    let formErrors = {};

    if (!companyName) {
      hasFormErrors = true;
      formErrors.companyName = true;
    }

    if (!contactName) {
      hasFormErrors = true;
      formErrors.contactName = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createIntegration({
        variables: {
          dataProcessorId: this.state.dataProcessor.id,
          companyName,
          email,
          contactName,
        },
      })
      .then((data) => {
        this.setState({
          id: data.data.createIntegration.integration.id,
        });
        this.props.refetchData();
        this.showCookiePage();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Data Processor couldn't be added!");
      });
  };

  refetchData = () => {
    this.props.integration.refetch();
  };

  handleConnect = (e) => {
    e.preventDefault();

    this.setState({
      addDP: !this.state.addDP,
    });

    this.props
      .connectToIntegration({
        variables: {
          integrationId: this.props.dataProcessor.id,
        },
      })
      .then(() => {
        this.showSuccessPage();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Integration couldn't be added!");
      });
  };

  copy = (text) => {
    navigator.clipboard.writeText(text);
    this.notify("Copied to clipboard!");
  };

  submitForm = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const apiKey = formData.get("apiKey"),
      apiSecret = formData.get("apiSecret");

    this.props
      .editApiKey({
        variables: {
          integrationId: this.props.dataProcessor.id,
          apiKey,
          apiSecret,
        },
      })
      .then(() => {
        this.notify("Integration edited!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Integration couldn't be edited!");
      });
  };

  editCookie = (consentId, purpose, id) => {
    this.props
      .editDPConsent({
        variables: {
          dataProcessorConsentId: id,
          consentId,
          purpose,
        },
      })
      .then(() => {
        this.notify("Changes saved");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes couldn't be saved");
      });
  };

  submitCookie = (consentId, purpose) => {
    this.props
      .createDPConsent({
        variables: {
          integrationId: this.state.isCurrent
            ? this.props.integration.integration.id
            : this.state.id,
          consentId,
          purpose,
        },
      })
      .then(() => {
        this.state.isCurrent
          ? this.notify("Changes saved")
          : this.showSuccessPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { isCurrent, dataProcessor, addDP, isDPAdded, isCookie } = this.state;
    const { integration, account, loading, error } = this.props.integration;
    const { isIntegrations, isAdmin } = this.props;

    if (loading) {
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );
    }

    if (error) {
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );
    }

    return (
      <div className="d-flex position-relative">
        <div className="w-100">
          {!addDP ? (
            <div className="info">
              <div className="data-processor">
                <MdClose
                  size={20}
                  className="data-processor__close"
                  onClick={this.props.showDP}
                />
                {isIntegrations || !isCurrent ? (
                  isIntegrations && !isCurrent ? (
                    <div className="data-processor__header-container">
                      <p className="data-processor__header-title">API Key</p>
                      <p className="data-processor__header-text">
                        To add the integration copy this API Key and paste it in
                        your services page.
                      </p>
                      <img src={Image} alt="api" />

                      <div>
                        <p className="data-processor__header-label">API Key</p>
                        <div className="d-flex data-processor__header-api">
                          <Input
                            type="text"
                            readOnly={true}
                            value={
                              account && account.apiKey ? account.apiKey : ""
                            }
                          />
                          <Button
                            className="btn--secondary ml-3"
                            onClick={() =>
                              this.copy(
                                account && account.apiKey ? account.apiKey : ""
                              )
                            }
                          >
                            Copy to clipboard
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : isIntegrations || isCurrent ? (
                    <div className="data-processor__logo">
                      {(dataProcessor && dataProcessor.logo) ||
                      (dataProcessor &&
                        dataProcessor.profile &&
                        dataProcessor.profile.logo) ? (
                        <img
                          src={
                            dataProcessor.logo
                              ? dataProcessor.logo
                              : dataProcessor.profile.logo
                          }
                          alt="logo"
                        />
                      ) : (
                        <span className="data-processors__placeholder" />
                      )}
                      <span className="data-processor__title">
                        {dataProcessor && dataProcessor.companyName
                          ? dataProcessor.companyName
                          : dataProcessor &&
                            dataProcessor.profile &&
                            dataProcessor.profile.companyName
                          ? dataProcessor.profile.companyName
                          : ""}
                      </span>
                    </div>
                  ) : !isIntegrations && !isCurrent ? (
                    <div className="data-processor__logo">
                      {(dataProcessor && dataProcessor.logo) ||
                      (dataProcessor &&
                        dataProcessor.profile &&
                        dataProcessor.profile.logo) ? (
                        <img
                          src={
                            dataProcessor &&
                            dataProcessor.profile &&
                            dataProcessor.profile.logo
                              ? dataProcessor.profile.logo
                              : dataProcessor.logo
                          }
                          alt="logo"
                        />
                      ) : (
                        <span className="data-processors__placeholder" />
                      )}
                      <span className="data-processor__title">
                        {dataProcessor &&
                        dataProcessor.profile &&
                        dataProcessor.profile.companyName
                          ? dataProcessor.profile.companyName
                          : dataProcessor && dataProcessor.companyName
                          ? dataProcessor.companyName
                          : ""}
                      </span>
                    </div>
                  ) : null
                ) : (
                  <div className="data-processor__logo">
                    {(dataProcessor &&
                      dataProcessor.dataProcessor &&
                      dataProcessor.dataProcessor.logo) ||
                    (dataProcessor &&
                      dataProcessor.dataProcessor &&
                      dataProcessor.dataProcessor.profile &&
                      dataProcessor.dataProcessor.profile.logo) ? (
                      <img
                        src={
                          dataProcessor.dataProcessor.logo
                            ? dataProcessor.dataProcessor.logo
                            : dataProcessor.dataProcessor.profile.logo
                        }
                        alt="logo"
                      />
                    ) : (
                      <span className="data-processors__placeholder" />
                    )}
                    <span className="data-processor__title">
                      {dataProcessor &&
                      dataProcessor.dataProcessor &&
                      dataProcessor.companyName
                        ? dataProcessor.dataProcessor.companyName
                        : dataProcessor &&
                          dataProcessor.dataProcessor &&
                          dataProcessor.dataProcessor.profile &&
                          dataProcessor.dataProcessor.profile.companyName
                        ? dataProcessor.dataProcessor.profile.companyName
                        : ""}
                    </span>
                  </div>
                )}

                {isIntegrations ? (
                  isCurrent ? (
                    dataProcessor &&
                    dataProcessor.profile &&
                    dataProcessor.profile.smallDescription ? (
                      <p className="data-processor__description">
                        {dataProcessor.profile.smallDescription}
                      </p>
                    ) : dataProcessor && dataProcessor.description ? (
                      <p className="data-processor__description">
                        {dataProcessor.description}
                      </p>
                    ) : (
                      <p className="data-processor__description">
                        {dataProcessor &&
                        dataProcessor.dataProcessor &&
                        dataProcessor.dataProcessor.description
                          ? dataProcessor.dataProcessor.description
                          : dataProcessor &&
                            dataProcessor.dataProcessor &&
                            dataProcessor.dataProcessor.profile &&
                            dataProcessor.dataProcessor.profile.smallDescription
                          ? dataProcessor.dataProcessor.profile.smallDescription
                          : ""}
                      </p>
                    )
                  ) : null
                ) : dataProcessor &&
                  dataProcessor.profile &&
                  dataProcessor.profile.smallDescription ? (
                  <p className="data-processor__description">
                    {dataProcessor.profile.smallDescription}
                  </p>
                ) : dataProcessor && dataProcessor.description ? (
                  <p className="data-processor__description">
                    {dataProcessor.description}
                  </p>
                ) : (
                  <p className="data-processor__description">
                    {dataProcessor &&
                    dataProcessor.dataProcessor &&
                    dataProcessor.dataProcessor.description
                      ? dataProcessor.dataProcessor.description
                      : dataProcessor &&
                        dataProcessor.dataProcessor &&
                        dataProcessor.dataProcessor.profile &&
                        dataProcessor.dataProcessor.profile.smallDescription
                      ? dataProcessor.dataProcessor.profile.smallDescription
                      : ""}
                  </p>
                )}

                {!isAdmin ? (
                  !isCurrent ? (
                    <Button
                      className="data-processor__connect"
                      onClick={
                        this.props.isIntegrations
                          ? this.handleConnect
                          : this.showNewDP
                      }
                    >
                      {this.props.isIntegrations
                        ? "Connect"
                        : "Add Data Processor"}
                    </Button>
                  ) : (
                    <div className="data-processor__btns">
                      <Button
                        onClick={this.removeDataProcessor}
                        className="btn--secondary data-processor__remove-btn"
                      >
                        {this.props.isIntegrations
                          ? "Remove integration"
                          : "Remove Data Processor"}
                      </Button>
                    </div>
                  )
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button
                      className="btn--primary mr-4"
                      onClick={() => {
                        this.props.getDP(dataProcessor);
                        this.props.editDP();
                      }}
                    >
                      Edit Data Processor
                    </Button>
                    <Button
                      className="btn--secondary"
                      onClick={() => {
                        this.props.showDeleteModal();
                        this.props.getId(dataProcessor.id);
                      }}
                    >
                      Delete Data Processor
                    </Button>
                  </div>
                )}

                {!isCurrent && !isIntegrations ? (
                  <Fragment>
                    <div className="data-processor__info d-flex">
                      <div className="data-processor__info-img">
                        {dataProcessor.profile &&
                        dataProcessor.profile.photo1 ? (
                          <img
                            src={
                              dataProcessor.profile &&
                              dataProcessor.profile.photo1
                                ? dataProcessor.profile.photo1
                                : null
                            }
                            alt="info"
                          />
                        ) : (
                          <span />
                        )}
                      </div>

                      <div className="data-processor__info-content">
                        <p className="data-processor__info-title">
                          {dataProcessor.profile && dataProcessor.profile.title1
                            ? dataProcessor.profile.title1
                            : null}
                        </p>
                        <p className="data-processor__info-text">
                          {dataProcessor.profile && dataProcessor.profile.text1
                            ? dataProcessor.profile.text1
                            : null}
                        </p>
                      </div>
                    </div>

                    <div className="data-processor__info d-flex">
                      <div className="data-processor__info-content">
                        <p className="data-processor__info-title">
                          {dataProcessor.profile && dataProcessor.profile.title2
                            ? dataProcessor.profile.title2
                            : null}
                        </p>
                        <p className="data-processor__info-text">
                          {dataProcessor.profile && dataProcessor.profile.text2
                            ? dataProcessor.profile.text2
                            : null}
                        </p>
                      </div>

                      <div className="data-processor__info-img">
                        {dataProcessor.profile &&
                        dataProcessor.profile.photo1 ? (
                          <img
                            src={
                              dataProcessor.profile &&
                              dataProcessor.profile.photo2
                                ? dataProcessor.profile.photo2
                                : null
                            }
                            alt="info"
                          />
                        ) : (
                          <span />
                        )}
                      </div>
                    </div>
                  </Fragment>
                ) : !this.props.isIntegrations ? (
                  <Fragment>
                    <CompanyDetails
                      dataProcessor={integration ? integration : null}
                    />
                    <DPA
                      integration={integration ? integration : null}
                      refetchData={this.refetchData}
                    />

                    <AddCookie
                      isCurrent={true}
                      editCookie={this.editCookie}
                      integration={integration ? integration : null}
                      removeConsent={this.removeDPConsent}
                      showCookie={this.submitCookie}
                    />
                  </Fragment>
                ) : isIntegrations && isCurrent ? (
                  <ApiKey account={account} copy={this.copy} />
                ) : null}
              </div>
            </div>
          ) : (
            <div className="info">
              <div
                className={`data-processor__add ${isDPAdded ? "-small" : ""}`}
              >
                {!isDPAdded ? (
                  !isIntegrations ? (
                    !isCookie ? (
                      <Fragment>
                        <p className="data-processor__add-title">
                          Your company details
                        </p>
                        <p className="data-processor__add-text">
                          Please fill your company details within this Data
                          Processor.
                        </p>
                        <img src={DP} alt="data-processor" />
                        <Form onSubmit={this.handleSubmit}>
                          <FormGroup className="mb-4">
                            <Input
                              type="text"
                              placeholder="Company name"
                              name="companyName"
                              invalid={this.state.formErrors.companyName}
                            />
                            <FormFeedback>
                              Please add the company name
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup className="mb-4">
                            <Input
                              type="text"
                              placeholder="Contact name"
                              name="contactName"
                              invalid={this.state.formErrors.contactName}
                            />
                            <FormFeedback>
                              Please add the contact name
                            </FormFeedback>
                          </FormGroup>

                          <FormGroup className="mb-4">
                            <Input
                              type="email"
                              placeholder="E-mail"
                              name="email"
                              invalid={this.state.formErrors.email}
                            />
                            <FormFeedback>Please add your email</FormFeedback>
                          </FormGroup>

                          <div className="d-flex justify-content-around">
                            <Button
                              className="btn--secondary data-processor__add-btn"
                              onClick={this.showNewDP}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              className="btn--primary data-processor__add-btn"
                            >
                              Next
                            </Button>
                          </div>
                        </Form>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <AddCookie
                          showCookie={this.submitCookie}
                          hideCookie={this.handleCookie}
                        />
                      </Fragment>
                      // <p onClick={this.handleCookie}>ssss</p>
                    )
                  ) : (
                    <Fragment>
                      <div className="position-relative data-processor__add-logo-container">
                        <img
                          className="data-processor__add-cloud"
                          src={Cloud}
                          alt="cloud"
                        />
                        <img
                          className="data-processor__add-check"
                          src={Check}
                          alt="check"
                        />
                        <img
                          className="data-processor__add-check2"
                          src={Check}
                          alt="check"
                        />
                        <div className="data-processor__add-logo">
                          <img
                            src={
                              dataProcessor.logo
                                ? dataProcessor.logo
                                : dataProcessor.profile &&
                                  dataProcessor.profile.logo
                                ? dataProcessor.profile.logo
                                : ""
                            }
                            alt="logo"
                          />
                          <p className="data-processor__add-name">
                            {dataProcessor.companyName}
                          </p>
                        </div>
                      </div>
                      <p className="data-processor__add-title">
                        {dataProcessor.companyName} was added as a Data
                        Processor!
                      </p>
                      <p className="data-processor__add-text">
                        {dataProcessor.companyName} was successfully added as a
                        Data Processor. Now you can save even more time with
                        privacy related procedures.{" "}
                      </p>

                      <div
                        className="data-processor__add-link"
                        onClick={() => {
                          this.setState({ addDP: false, isDPAdded: false });
                          this.props.showDP();
                        }}
                      >
                        Back to Data Processors <MdArrow size={20} />
                      </div>
                    </Fragment>
                  )
                ) : (
                  <Fragment>
                    <div className="position-relative data-processor__add-logo-container">
                      <img
                        className="data-processor__add-cloud"
                        src={Cloud}
                        alt="cloud"
                      />
                      <img
                        className="data-processor__add-check"
                        src={Check}
                        alt="check"
                      />
                      <img
                        className="data-processor__add-check2"
                        src={Check}
                        alt="check"
                      />
                      <div className="data-processor__add-logo">
                        <img
                          src={
                            dataProcessor.logo
                              ? dataProcessor.logo
                              : dataProcessor.profile &&
                                dataProcessor.profile.logo
                              ? dataProcessor.profile.logo
                              : ""
                          }
                          alt="logo"
                        />
                        <p className="data-processor__add-name">
                          {dataProcessor.companyName}
                        </p>
                      </div>
                    </div>
                    <p className="data-processor__add-title">
                      {dataProcessor.companyName} was added as a Data Processor!
                    </p>
                    <p className="data-processor__add-text">
                      {dataProcessor.companyName} was successfully added as a
                      Data Processor. Now you can save even more time with
                      privacy related procedures.{" "}
                    </p>

                    <div
                      className="data-processor__add-link"
                      onClick={() => {
                        this.setState({ addDP: false, isDPAdded: false });
                        this.props.showDP();
                      }}
                    >
                      Back to Data Processors <MdArrow size={20} />
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          )}
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(REMOVE_INTEGRATION, {
    name: "removeIntegration",
  }),
  graphql(CREATE_INTEGRATION, {
    name: "createIntegration",
  }),
  graphql(EDIT_API_KEY, {
    name: "editApiKey",
  }),
  graphql(REMOVE_DP_CONSENT, {
    name: "removeDPConsent",
  }),
  graphql(EDIT_DP_CONSENT, {
    name: "editDPConsent",
  }),
  graphql(CREATE_DP_CONSENT, {
    name: "createDPConsent",
  }),
  graphql(DATA_PROCESSOR_QUERY, {
    name: "integration",
    options: (props) => ({
      variables: {
        integrationId: props.isCurrent
          ? props.dataProcessor.dataProcessor.id
          : props.dataProcessor.id,
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(CONNECT_TO_INTEGRATION, {
    name: "connectToIntegration",
  })
)(DataProcessor);
