import PropTypes from "prop-types";
import React from "react";

const Robot = props => (
    <svg
        height={30}
        viewBox='0 0 33 30'
        width={33}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-7---Help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Help" transform="translate(-391.000000, -312.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="cards" transform="translate(298.000000, 282.000000)">
                    <g id="help-card">
                        <g id="robot" transform="translate(93.000000, 30.000000)">
                            <path d="M12,19.5 C11.1715729,19.5 10.5,20.1715729 10.5,21 C10.5,21.8284271 11.1715729,22.5 12,22.5 C12.8284271,22.5 13.5,21.8284271 13.5,21 C13.5,20.1715729 12.8284271,19.5 12,19.5 Z M1.5,18 C0.671572875,18 0,18.6715729 0,19.5 L0,22.5 C0,23.3284271 0.671572875,24 1.5,24 C2.32842712,24 3,23.3284271 3,22.5 L3,19.5 C3,18.6715729 2.32842712,18 1.5,18 Z M31.5,18 C30.6715729,18 30,18.6715729 30,19.5 L30,22.5 C30,23.3284271 30.6715729,24 31.5,24 C32.3284271,24 33,23.3284271 33,22.5 L33,19.5 C33,18.6715729 32.3284271,18 31.5,18 Z M24,7.5 L18,7.5 L18,5.58 C18.9226336,5.04734311 19.4935696,4.06533318 19.5,3 C19.5,1.34314575 18.1568542,0 16.5,0 C14.8431458,0 13.5,1.34314575 13.5,3 C13.5064304,4.06533318 14.0773664,5.04734311 15,5.58 L15,7.5 L9,7.5 C6.51471863,7.5 4.5,9.51471863 4.5,12 L4.5,25.5 C4.5,27.9852814 6.51471863,30 9,30 L24,30 C26.4852814,30 28.5,27.9852814 28.5,25.5 L28.5,12 C28.5,9.51471863 26.4852814,7.5 24,7.5 Z M19.08,10.5 L18.33,13.5 L14.67,13.5 L13.92,10.5 L19.08,10.5 Z M25.5,25.5 C25.5,26.3284271 24.8284271,27 24,27 L9,27 C8.17157288,27 7.5,26.3284271 7.5,25.5 L7.5,12 C7.5,11.1715729 8.17157288,10.5 9,10.5 L10.83,10.5 L12,15.36 C12.1692233,16.0457749 12.7939542,16.5205704 13.5,16.5 L19.5,16.5 C20.2060458,16.5205704 20.8307767,16.0457749 21,15.36 L22.17,10.5 L24,10.5 C24.8284271,10.5 25.5,11.1715729 25.5,12 L25.5,25.5 Z M21,19.5 C20.1715729,19.5 19.5,20.1715729 19.5,21 C19.5,21.8284271 20.1715729,22.5 21,22.5 C21.8284271,22.5 22.5,21.8284271 22.5,21 C22.5,20.1715729 21.8284271,19.5 21,19.5 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Robot.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Robot;