import gql from "graphql-tag";

export const VIEW_REQUESTS = gql`
  mutation(
    $clientMutationId: String
  ) {
    viewRequests(
      input: {
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;