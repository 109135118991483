import gql from "graphql-tag";

export const SEARCH_QUERY = gql`
  query($name: String!, $filter: SearchFilterEnum) {
    account {
      id
      access
    }
    searchPage(name: $name, filter: $filter) {
      id
      name
      type
    }
    viewer {
      id
      recentSearches
    }
  }
`;
