import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Email from "../../images/Settings/email.svg";
import "./modals.scss";

export default class AssignRequestModal extends Component {
  static propTypes = {
    showBreachSentModal: PropTypes.func.isRequired,
    isBreachSentModalVisible: PropTypes.bool.isRequired,
    emailType: PropTypes.string,
    sectionActive: PropTypes.string.isRequired,
  };

  state = {};

  getModalText = () => {
    const { emailType, sectionActive } = this.props;

    if ((emailType && emailType === "users") || sectionActive === "your-users")
      return "All your users were notified about the Data Breach Incident.";
    else if (
      (emailType && emailType === "companies") ||
      sectionActive === "interested"
    )
      return "Interested companies were notified about the Data Breach Incident.";
    else if (
      (emailType && emailType === "company") ||
      sectionActive === "own-company"
    )
      return "Your own company was notified about the Data Breach Incident";
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isBreachSentModalVisible}
          toggle={this.props.showBreachSentModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showBreachSentModal}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p className="modal-assign__title">E-mails sent!</p>
            <p className="modal-assign__message">{this.getModalText()}</p>
            <Link to="/dashboard" className="modal-assign__link">
              Dashboard <MdArrow size={23} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
