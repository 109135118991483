import React, { Component } from "react";
import PropTypes from "prop-types";
import BreachDtepicker from "./BreachDatePicker";

export default class WhenBreach extends Component {
  static propTypes = {
    breachedDatetime: PropTypes.string,
    awenessedDatetime: PropTypes.string,
    endedDatetime: PropTypes.string,
    breachOngoing: PropTypes.bool,
    getWhen: PropTypes.func.isRequired,
    getAwareness: PropTypes.func.isRequired,
    getPeriod: PropTypes.func.isRequired,
    getBreachEnd: PropTypes.func.isRequired,
    removeBeginning: PropTypes.func.isRequired,
    removeAwareness: PropTypes.func.isRequired,
    removeEnding: PropTypes.func.isRequired,
  };

  state = {
    isInitialOngoing: true,
    isBreachOngoing: false,
  };

  render() {
    const {
      breachedDatetime,
      awenessedDatetime,
      endedDatetime,
      breachOngoing,
    } = this.props;
    const { isBreachOngoing, isInitialOngoing } = this.state;

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title">When</h5>

        <div>
          <p className="compliance-breaches__where-text mb-3">
            When did the breach began:
          </p>
          <BreachDtepicker
            date={breachedDatetime}
            getDate={this.props.getWhen}
            removeDate={this.props.removeBeginning}
          />
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title">Awareness</h5>

          <div>
            <p className="compliance-breaches__where-text mb-3">
              When did you become aware of the breach?
            </p>
            <BreachDtepicker
              date={awenessedDatetime}
              getDate={this.props.getAwareness}
              removeDate={this.props.removeAwareness}
            />
          </div>
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title">Period</h5>

          <div>
            <p className="compliance-breaches__where-text mb-3">
              Is the breach ongoing?
            </p>

            <div className="d-flex">
              <div className="d-flex mb-0 align-items-center compliance-breaches__where-radio-label mt-2">
                <span
                  className={`${
                    (breachOngoing && isInitialOngoing) ||
                    (!isInitialOngoing && isBreachOngoing)
                      ? "-is-active"
                      : ""
                  } compliance-breaches__where-radio mr-2 mt-1`}
                  onClick={() => {
                    this.setState(
                      {
                        isBreachOngoing: true,
                        isInitialOngoing: false,
                      },
                      () => this.props.getPeriod(true)
                    );
                  }}
                />
                Yes
              </div>
            </div>

            <div className="d-flex">
              <div className="d-flex align-items-center compliance-breaches__where-radio-label mt-2">
                <span
                  className={`${
                    (!breachOngoing && isInitialOngoing) ||
                    (!isInitialOngoing && !isBreachOngoing)
                      ? "-is-active"
                      : ""
                  } compliance-breaches__where-radio mr-2 mt-1`}
                  onClick={() => {
                    this.setState(
                      {
                        isBreachOngoing: false,
                        isInitialOngoing: false,
                      },
                      () => this.props.getPeriod(false)
                    );
                  }}
                />
                No
              </div>
            </div>

            {(breachOngoing && isInitialOngoing) ||
            (!isInitialOngoing && isBreachOngoing) ? null : (
              <div className="mt-5">
                <p className="compliance-breaches__where-text mb-3">
                  Please enter when did the breach ended:
                </p>
                <BreachDtepicker
                  date={endedDatetime}
                  getDate={this.props.getBreachEnd}
                  removeDate={this.props.removeEnding}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
