import PropTypes from "prop-types";
import React, { Component } from "react";
import { Progress } from "reactstrap";
import "./overview-card.scss";

export default class OverviewCard extends Component {
  static propTypes = {
    percentage: PropTypes.number,
    color: PropTypes.string,
    stats: PropTypes.number,
    text: PropTypes.string,
  };

  render() {
    return (
      <div className="overview-card d-flex">
        <div className="position-relative overview-card__percentage-container">
          <div className="overview-card__percentage">
            <Progress
              value={this.props.percentage}
              className={`${this.props.color}`}
            />
          </div>
        </div>

        <div className="overview-card__stats">
          <h3 className={`overview-card__numbers ${this.props.color}`}>
            {this.props.stats}
          </h3>
          <p className="overview-card__text">{this.props.text}</p>
        </div>
      </div>
    );
  }
}
