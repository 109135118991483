import PropTypes from "prop-types";
import React from "react";

const SendIcon = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-7---Help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--contact-2" transform="translate(-1354.000000, -945.000000)" fill="#969696" fillRule="nonzero">
                <g id="Chatbot" transform="translate(1018.000000, 396.000000)">
                    <g id="write-bar" transform="translate(0.000000, 530.000000)">
                        <g id="icons" transform="translate(275.000000, 19.000000)">
                            <g id="message" transform="translate(61.000000, 0.000000)">
                                <path d="M14.6706199,5.85312531 L3.47123301,0.251538331 C2.56571121,-0.19930416 1.47481841,-0.0338016794 0.743647329,0.665348192 C0.0124762442,1.36449806 -0.201990204,2.44718738 0.207411674,3.3724225 L2.12730658,7.66963994 C2.21530517,7.87951172 2.21530517,8.11595406 2.12730658,8.32582584 L0.207411674,12.6230433 C-0.123011446,13.3655897 -0.055403661,14.2249558 0.387067367,14.9066562 C0.829538395,15.5883566 1.5867759,15.9998023 2.39929169,16 C2.77385597,15.9962609 3.14284234,15.9087679 3.47923257,15.7439275 L14.6786195,10.1423405 C15.4887783,9.73464928 16,8.9049337 16,7.99773289 C16,7.09053209 15.4887783,6.2608165 14.6786195,5.85312531 L14.6706199,5.85312531 Z M13.9586589,8.70993466 L2.75927198,14.3115216 C2.45810236,14.456179 2.09878184,14.3983779 1.85813154,14.1665623 C1.61748125,13.9347466 1.5461864,13.5777408 1.6793311,13.2712269 L3.59122644,8.97400948 C3.61597644,8.91662645 3.6373452,8.85784249 3.65522294,8.7979596 L9.16692122,8.7979596 C9.60872484,8.7979596 9.96687743,8.4396859 9.96687743,7.99773289 C9.96687743,7.55577988 9.60872484,7.19750618 9.16692122,7.19750618 L3.65522294,7.19750618 C3.6373452,7.1376233 3.61597644,7.07883934 3.59122644,7.0214563 L1.6793311,2.72423887 C1.5461864,2.41772501 1.61748125,2.06071921 1.85813154,1.82890353 C2.09878184,1.59708785 2.45810236,1.53928678 2.75927198,1.68394414 L13.9586589,7.28553112 C14.2258173,7.42244668 14.3938667,7.69745347 14.3938667,7.99773289 C14.3938667,8.29801232 14.2258173,8.5730191 13.9586589,8.70993466 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

SendIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default SendIcon;