import PropTypes from "prop-types";
import React from "react";

const UsersIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-173.000000, -224.000000)" fill={props.color} fillRule="nonzero">
                <g id="Overview" transform="translate(149.000000, 140.000000)">
                    <g id="Visitors" transform="translate(0.000000, 60.000000)">
                        <g id="user-circle" transform="translate(24.000000, 24.000000)">
                            <path d="M10,0 C6.03710176,0.00752938505 2.45252847,2.3545171 0.860941269,5.98376899 C-0.730645929,9.61302089 -0.028931196,13.8397352 2.65,16.76 L2.65,16.76 C4.54315584,18.8121118 7.20801109,19.9793289 10,19.9793289 C12.7919889,19.9793289 15.4568442,18.8121118 17.35,16.76 L17.35,16.76 C20.0289312,13.8397352 20.7306459,9.61302089 19.1390587,5.98376899 C17.5474715,2.3545171 13.9628982,0.00752938505 10,0 Z M10,18 C7.92846506,17.9968517 5.93891507,17.1902774 4.45,15.75 C5.37453306,13.4992971 7.56680763,12.0298026 10,12.0298026 C12.4331924,12.0298026 14.6254669,13.4992971 15.55,15.75 C14.0610849,17.1902774 12.0715349,17.9968517 10,18 Z M8,8 C8,6.8954305 8.8954305,6 10,6 C11.1045695,6 12,6.8954305 12,8 C12,9.1045695 11.1045695,10 10,10 C8.8954305,10 8,9.1045695 8,8 Z M16.91,14 C16.0165313,12.471759 14.6414019,11.2830283 13,10.62 C14.4182283,9.01188026 14.3162204,6.57151486 12.7687343,5.08736276 C11.2212482,3.60321066 8.77875178,3.60321066 7.23126569,5.08736276 C5.68377961,6.57151486 5.58177169,9.01188026 7,10.62 C5.35859806,11.2830283 3.98346872,12.471759 3.09,14 C2.37794377,12.787102 2.00172006,11.4064646 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C17.9982799,11.4064646 17.6220562,12.787102 16.91,14 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

UsersIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default UsersIcon;