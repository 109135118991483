import React, { Component, Fragment } from "react";
import { Button, Progress } from "reactstrap";
import MenuChecklist from "./MenuChecklist";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import PropTypes from "prop-types";

class MenuContent extends Component {
  static propTypes = {
    showIntroductionModal: PropTypes.func,
    isDashboard: PropTypes.bool,
    location: PropTypes.string,
    restartOnboarding: PropTypes.func,
    viewer: PropTypes.object,
  };

  state = {};

  redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };

  render() {
    const { isDashboard } = this.props;
    const { viewer } = this.props.viewer;

    const completedOnboarding = viewer && viewer.onboarded;
    const completedDpOnboarding = viewer && viewer.onboardedAsDp;
    const completedCompanyInfo = viewer && viewer.editedCompanyInfo;
    const completedPages = viewer && viewer.publishedPage;
    const editedPages = viewer && viewer.editedPage;
    const customizedPopup = viewer && viewer.customizedPopup;
    const customizedLegalsite = viewer && viewer.customizedLegalsite;
    const customizedEmails = viewer && viewer.customizedEmails;
    const isDP = viewer && viewer.invitedAsDp;
    const checklist = [
      completedOnboarding,
      completedCompanyInfo,
      completedPages,
      editedPages,
      customizedPopup,
      customizedLegalsite,
      customizedEmails,
    ].filter((v) => v).length;
    const dpChecklist = [completedCompanyInfo, completedDpOnboarding].filter(
      (v) => v
    ).length;
    const dpProgressValue =
      dpChecklist === 2 ? 100 : dpChecklist === 1 ? 50 : 0;
    const progressBarValue =
      checklist === 7
        ? 100
        : checklist === 6
        ? 85.71
        : checklist === 5
        ? 71.42
        : checklist === 4
        ? 57.13
        : checklist === 3
        ? 42.84
        : checklist === 2
        ? 28.55
        : checklist === 1
        ? 14.26
        : 0;
    const dpStepValue = dpChecklist === 2 ? 2 : dpChecklist === 1 ? 1 : 0;
    const stepValue =
      checklist === 7
        ? 7
        : checklist === 6
        ? 6
        : checklist === 5
        ? 5
        : checklist === 4
        ? 4
        : checklist === 3
        ? 3
        : checklist === 2
        ? 2
        : checklist === 1
        ? 1
        : 0;

    return (
      <Fragment>
        <div className="right-menu__container">
          <h4 className="right-menu__title">Getting started</h4>

          <Button
            className="right-menu__restart"
            onClick={
              !isDashboard
                ? () => {
                    this.redirectToDashboard();
                    this.props.showIntroductionModal();
                    this.props.restartOnboarding();
                  }
                : () => {
                    this.props.showIntroductionModal();
                    this.props.restartOnboarding();
                  }
            }
          >
            {completedOnboarding ? "Restart onboarding" : "Complete onboarding"}
          </Button>

          <div className="right-menu__progress">
            <Progress
              className="right-menu__progress-bar"
              value={isDP ? dpProgressValue : progressBarValue}
            />
            <span className="right-menu__progress-stats">
              Step {isDP ? dpStepValue : stepValue} of {isDP ? "2" : "7"}
            </span>
          </div>
        </div>

        <div className="right-menu__checklist">
          <MenuChecklist
            hasOnboarded={isDP ? completedDpOnboarding : completedOnboarding}
            completedCompanyInfo={completedCompanyInfo}
            completedPages={completedPages}
            countSteps={this.countSteps}
            location={this.props.location}
            showIntroductionModal={this.props.showIntroductionModal}
            restartOnboarding={this.props.restartOnboarding}
            isDashboard={isDashboard}
            redirectToDashboard={this.redirectToDashboard}
            editedPages={editedPages}
            customizedPopup={customizedPopup}
            customizedLegalsite={customizedLegalsite}
            customizedEmails={customizedEmails}
            isDP={isDP}
          />
        </div>
      </Fragment>
    );
  }
}

export default compose(
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(MenuContent);
