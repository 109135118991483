import React, { Component, Fragment } from "react";
import SearchInput from "../../components/SearchInput/SearchInput";
import { Button } from "reactstrap";
import DPA from "../DataProcessors/DPA";
import PropTypes from "prop-types";

class UploadDpa extends Component {
  state = {
    filteredDps: null,
    selectedDP: null,
    step: 1,
  };

  searchDP = (dp) => {
    let currentList = [],
      newList = [];

    if (dp !== "") {
      currentList = this.props.currentDataProcessors;

      newList = currentList.filter((item) => {
        const dpName = item.companyName.toLowerCase(),
          filter = dp.toLowerCase();

        return dpName.includes(filter);
      });
    } else {
      newList = this.props.currentDataProcessors;
    }

    this.setState({
      filteredDps: newList,
    });
  };

  render() {
    const { currentDataProcessors } = this.props;
    const { step } = this.state;

    const dps = this.state.filteredDps
      ? this.state.filteredDps
      : currentDataProcessors;

    return (
      <div className="container import__upload-container">
        {step === 1 ? (
          <Fragment>
            <h2 className="import__upload-title">
              Upload Data Processing Agreement
            </h2>

            <p className="import__upload-text mb-5">
              Select a Data Processor to upload a signed DPA:
            </p>

            <SearchInput
              placeholder="Type to search for a data processor..."
              search={this.searchDP}
            />

            <div className="d-flex justify-content-around w-100 flex-wrap mt-5">
              {currentDataProcessors && currentDataProcessors.length ? (
                dps.map((item) => (
                  <div
                    className={`${
                      this.state.selectedDP &&
                      this.state.selectedDP.id === item.id
                        ? "-is-active"
                        : ""
                    } import__upload-content`}
                    key={item.id}
                    onClick={() => this.setState({ selectedDP: item })}
                  >
                    <div className="import__upload-avatar">
                      {item.dataProcessor.logo ? (
                        <img src={item.dataProcessor.logo} alt="logo" />
                      ) : item.dataProcessor &&
                        item.dataProcessor.profile &&
                        item.dataProcessor.profile.logo ? (
                        <img src={item.dataProcessor.profile.logo} alt="logo" />
                      ) : (
                        <span className="import__upload-placeholder" />
                      )}
                    </div>
                    <p className="import__upload-name">
                      {item.dataProcessor.companyName}
                    </p>
                    <p className="import__upload-description">
                      {item.dataProcessor && item.dataProcessor.description
                        ? item.dataProcessor.description
                        : item.dataProcessor &&
                          item.dataProcessor.profile &&
                          item.dataProcessor.profile.smallDescription
                        ? item.dataProcessor.profile.smallDescription
                        : ""}
                    </p>
                  </div>
                ))
              ) : (
                <p className="w-100 text-center">
                  Currently there's no Data Processor
                </p>
              )}
            </div>

            <div className="import__upload-btns">
              <Button
                className="btn--secondary import__upload-cancel mx-2"
                onClick={this.props.showUpload}
              >
                Cancel
              </Button>
              <Button
                disabled={!this.state.selectedDP}
                onClick={() => this.setState({ step: 2 })}
                className="btn--primary import__upload-cancel mx-2"
              >
                Next
              </Button>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <DPA
              integration={this.state.selectedDP}
              refetchData={this.props.refetchData}
              isCompliance={true}
              showUpload={this.props.showUpload}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default UploadDpa;

UploadDpa.propTypes = {
  currentDataProcessors: PropTypes.array.isRequired,
  showUpload: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
};
