import React, { Component } from "react";
import { Alert } from "reactstrap";
import StatusCard from "./../../components/StatusCard/StatusCard";
import Graph from "./../../components/Graph/Graph";
import InfoTable from "./../../components/InfoTable/InfoTable";
import EmptyDashboard from "../../components/EmptyDashboard/EmptyDashboard";
import SideMenu from "../../components/SideMenu/SideMenu";
import SyncLoader from "react-spinners/SyncLoader";
import { slide as Menu } from "react-burger-menu";
import { Redirect } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { USER_DASHBOARD_QUERY } from "../../graphql/userDashboard";
import { MENU_CONTENT_QUERY } from "../../graphql/menuContent";
import { COMPLETE_ONBOARDING } from "../../graphql/completeOnboarding";
import { COMPLETE_ONBOARDING_AS_DP } from "../../graphql/completedOnboardingAsDp";
import { PUBLISH_ALL_PAGES } from "../../graphql/publishAllPages";
import AlertBanner from "./Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntroductionModal from "../../components/Modals/IntroductionModal";
import PreviewModal from "../../components/Modals/PreviewModal";
import Check from "react-icons/lib/fa/check";
import "./dashboard.scss";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import { TOGGLE_SIDEBAR } from "../../graphql/toggleSidebar";
import { HIDE_FEEDBACK } from "../../graphql/hideFeedbackModal";
import { HIDE_ONBOARDING } from "../../graphql/hideOnboardingModal";
import OnboardSteps from "./OnboardSteps";
import AdminDashboard from "./AdminDashboard";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import Stats from "./Stats";
import PropTypes from "prop-types";
import Onboarding from "../../components/Modals/Onboarding";

class Dashboard extends Component {
  state = {
    graphType: "VISITORS",
    graphInterval: "WEEK",
    isIntroductionModalVisible: false,
    isPreviewModalVisible: false,
    step: null,
    dropdownOpen: false,
    restart: false,
    contactBg: null,
    btnBg: null,
    welcomeBg: null,
    bg: null,
    titleColor: null,
    titleSize: null,
    bodyColor: null,
    bodySize: null,
    subtitleColor: null,
    subtitleSize: null,
    btnColor: null,
    siteBtnSize: null,
    titleFont: null,
    bodyFont: null,
    footerFont: null,
    buttonFont: null,
    isThankYou: false,
    isOnboardingModalOpen: false,
  };

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.showIntroduction
    ) {
      this.setState({
        isIntroductionModalVisible: true,
        isOnboardingModalOpen: true,
      });
    }

    if (
      this.props.location.state &&
      this.props.location.state.restartOnboarding
    ) {
      this.setState({
        restart: true,
        isIntroductionModalVisible: true,
      });
    }

    if (this.props.location.state && this.props.location.state.isThankYou) {
      this.setState({
        isThankYou: true,
      });
    }
  }

  static getDerivedStateFromProps(props) {
    if (
      props.viewer.viewer &&
      !props.viewer.viewer.onboarded &&
      props.viewer.viewer.onboardedAsDp &&
      !props.viewer.viewer.appAdmin &&
      props.viewer.viewer.showOnboardingModal
    ) {
      return { isIntroductionModalVisible: true };
    }

    if (
      props.viewer.viewer &&
      props.viewer.viewer.onboarded &&
      !props.viewer.viewer.onboardedAsDp &&
      !props.viewer.viewer.appAdmin &&
      props.viewer.viewer.showOnboardingModal
    ) {
      return { isIntroductionModalVisible: true };
    }

    if (props.viewer.viewer && props.viewer.viewer.showFeedbackModal) {
      return { isOnboardingModalOpen: true };
    }

    if (props.location.state && props.location.state.isThankYou) {
      props.dashboardStats.refetch();
    }

    return null;
  }

  showIntroductionModal = () => {
    this.setState({
      isIntroductionModalVisible: !this.state.isIntroductionModalVisible,
    });

    if (this.state.isIntroductionModalVisible) {
      this.props
        .hideOnboarding()
        .then()
        .catch((error) => {
          console.log(error);
        });
    }
  };

  showNextStep = (step) => {
    this.setState({
      step,
      restart: false,
    });
  };

  getGraphType = (type) => {
    this.setState(
      {
        graphType: type,
      },
      () => {
        this.props.dashboardStats.refetch({ graphType: this.state.graphType });
      }
    );
  };

  getGraphInterval = (interval) => {
    this.setState(
      {
        graphInterval: interval,
      },
      () => {
        this.props.dashboardStats.refetch({
          graphInterval: this.state.graphInterval,
        });
      }
    );
  };

  notify = (text) => toast(text);

  showNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        LegalSite published! &nbsp;
        <Check size={12} />
      </span>
    );
  };

  showNotify = () => {
    this.notify(this.showNotifyText());
  };

  closeStep = () => {
    this.setState({
      step: null,
      restart: false,
    });
  };

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  completeOnboarding = () => {
    const { viewer } = this.props.viewer;
    const isDP = viewer && viewer.invitedAsDp;

    if (isDP) {
      this.props
        .completeOnboardingAsDp({
          variables: {
            clientMutationId: this.props.viewer.viewer.id,
          },
        })
        .then()
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props
        .completeOnboarding({
          variables: {
            clientMutationId: this.props.viewer.viewer.id,
          },
        })
        .then()
        .catch((error) => {
          console.log(error);
        });
    }
  };

  setTableTab = (status) => {
    this.props.dashboardStats.refetch({ status });
  };

  restartOnboarding = () => {
    this.setState({
      restart: true,
    });
  };

  showPreviewModal = () => {
    this.setState({
      isPreviewModalVisible: !this.state.isPreviewModalVisible,
    });
  };

  openLegalsite = () => {
    this.props
      .publishAllPages()
      .then(() => {
        this.showNotify();
        window.open(
          `https://${this.props.dashboardStats.legalsiteSettings.subdomain}.legalsites.app`,
          "_blank"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleMenu = () => {
    this.props
      .toggleSidebar()
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  getTableInterval = (interval) => {
    this.props.dashboardStats.refetch({ interval });
  };

  openOnboardingModal = () => {
    this.props
      .hideFeedback()
      .then()
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      isOnboardingModalOpen: !this.state.isOnboardingModalOpen,
    });
  };

  render() {
    const {
      dashboardStats,
      loading,
      error,
      graphDataDashboard,
      account,
      legalsite,
      pages,
      alerts,
      dataProtectionOfficer,
      dashboardRequestsTable,
      usersStats,
    } = this.props.dashboardStats;
    const { viewer } = this.props.viewer;
    const {
      step,
      isPreviewModalVisible,
      contactBg,
      btnBg,
      welcomeBg,
      bg,
      titleColor,
      titleSize,
      bodyColor,
      bodySize,
      subtitleColor,
      subtitleSize,
      btnColor,
      siteBtnSize,
      titleFont,
      bodyFont,
      footerFont,
      buttonFont,
    } = this.state;
    const completedOnboarding = viewer && viewer.onboarded;
    const completedDpOnboarding = viewer && viewer.onboardedAsDp;
    const completedCompanyInfo = viewer && viewer.editedCompanyInfo;
    const completedPages = viewer && viewer.publishedPage;
    const editedPages = viewer && viewer.editedPage;
    const customizedPopup = viewer && viewer.customizedPopup;
    const customizedLegalsite = viewer && viewer.customizedLegalsite;
    const customizedEmails = viewer && viewer.customizedEmails;
    const checklist = [
      completedOnboarding,
      completedCompanyInfo,
      completedPages,
      editedPages,
      customizedPopup,
      customizedLegalsite,
      customizedEmails,
    ].filter((v) => v).length;
    const dpChecklist = [completedCompanyInfo, completedDpOnboarding].filter(
      (v) => v
    ).length;
    const dpStepValue = dpChecklist === 2 ? 2 : dpChecklist === 1 ? 1 : 0;

    const stepValue =
      checklist === 7
        ? 7
        : checklist === 6
        ? 6
        : checklist === 5
        ? 5
        : checklist === 4
        ? 4
        : checklist === 3
        ? 3
        : checklist === 2
        ? 2
        : checklist === 1
        ? 1
        : 0;

    if (
      localStorage.getItem("auth-token") === null ||
      (error &&
        error.graphQLErrors &&
        error.graphQLErrors[0].message === "User not signed in")
    ) {
      return <Redirect to={{ pathname: "/signin" }} />;
    }

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger">
          {" "}
          {error && error.graphQLErrors && error.graphQLErrors[0].message
            ? error.graphQLErrors[0].message
            : "There's an error loading the data"}
        </Alert>
      );

    return (
      <div className="d-flex position-relative">
        <SideMenu />

        <OnboardSteps
          closeStep={this.closeStep}
          showNextStep={this.showNextStep}
          viewer={viewer}
          completeOnboarding={this.completeOnboarding}
          step={step}
          stepValue={stepValue}
          dpStepValue={dpStepValue}
          isDP={viewer && viewer.invitedAsDp}
        />

        <div className="dashboard w-100">
          {account && account.needToUpgrade && viewer && !viewer.appAdmin ? (
            <UpgradeBar />
          ) : null}

          <Navbar title="Dashboard" showSideBarMenu={this.toggleMenu} />

          <Menu
            right
            isOpen={viewer && viewer.showSidebar}
            noOverlay
            className="right-menu"
          >
            <span className="right-menu__close" onClick={this.toggleMenu}>
              <MdClose size={20} />
            </span>

            <MenuContent
              showIntroductionModal={this.showIntroductionModal}
              isDashboard={true}
              restartOnboarding={this.restartOnboarding}
            />
          </Menu>
          {alerts.length > 0 && viewer && !viewer.appAdmin ? (
            <AlertBanner alerts={alerts} />
          ) : null}
          {viewer && !viewer.appAdmin ? (
            completedPages ||
            (dashboardStats && dashboardStats.totalRequests > 0) ||
            (viewer && viewer.customizedLegalsite) ? (
              <div
                className={`${
                  account && account.needToUpgrade ? "-has-banner" : ""
                } info`}
              >
                <div className="d-flex container info__container-dashboard pr-0">
                  <Stats
                    account={account}
                    dashboardStats={dashboardStats}
                    usersStats={usersStats}
                  />

                  <div className="info__legal">
                    <StatusCard
                      status={pages.map((item) => item)}
                      showPreviewModal={this.showPreviewModal}
                      alerts={alerts}
                      openLegalsite={this.openLegalsite}
                      subdomain={
                        this.props.dashboardStats &&
                        this.props.dashboardStats.legalsiteSettings &&
                        this.props.dashboardStats.legalsiteSettings.subdomain
                          ? this.props.dashboardStats.legalsiteSettings
                              .subdomain
                          : ""
                      }
                    />
                  </div>
                </div>

                <Graph
                  data={graphDataDashboard}
                  getGraphType={this.getGraphType}
                  getGraphInterval={this.getGraphInterval}
                />

                <InfoTable
                  requests={dashboardRequestsTable}
                  setTableTab={this.setTableTab}
                  getTableInterval={this.getTableInterval}
                  refetchData={() => this.props.dashboardStats.refetch()}
                  notify={(msg) => this.notify(msg)}
                />
              </div>
            ) : (
              <EmptyDashboard
                name={viewer && viewer.name ? viewer.name : ""}
                step={this.state.step}
                closeStep={this.closeStep}
                showNextStep={this.showNextStep}
                openMenu={this.toggleMenu}
              />
            )
          ) : (
            <AdminDashboard />
          )}
        </div>

        {viewer && viewer.showFeedbackModal ? (
          <Onboarding
            openOnboardingModal={this.openOnboardingModal}
            isOnboardingModalOpen={viewer && viewer.showFeedbackModal}
          />
        ) : null}

        {(viewer &&
          !viewer.onboarded &&
          viewer.onboardedAsDp &&
          !viewer.appAdmin &&
          !viewer.showFeedbackModal &&
          viewer.showOnboardingModal) ||
        this.state.restart === true ? (
          <IntroductionModal
            showIntroductionModal={this.showIntroductionModal}
            isIntroductionModalVisible={this.state.isIntroductionModalVisible}
            showNextStep={this.showNextStep}
            onboarded={viewer.onboarded}
            hasData={dashboardStats ? true : false}
            viewer={viewer}
            account={account}
          />
        ) : (viewer &&
            viewer.onboarded &&
            !viewer.onboardedAsDp &&
            !viewer.appAdmin &&
            !viewer.showFeedbackModal &&
            viewer.showOnboardingModal) ||
          this.state.restart === true ? (
          <IntroductionModal
            showIntroductionModal={this.showIntroductionModal}
            isIntroductionModalVisible={this.state.isIntroductionModalVisible}
            showNextStep={this.showNextStep}
            onboarded={viewer.onboarded}
            hasData={dashboardStats ? true : false}
            viewer={viewer}
            account={account}
          />
        ) : null}

        <PreviewModal
          isSiteCustomization={true}
          showPreviewModal={this.showPreviewModal}
          isPreviewModalVisible={isPreviewModalVisible}
          contactBg={contactBg ? contactBg : legalsite?.backgroundColor}
          btnBg={btnBg ? btnBg : legalsite?.backgroundButtonsColor}
          welcomeBg={welcomeBg ? welcomeBg : legalsite?.background1}
          bg={bg ? bg : legalsite?.background2}
          titleSiteColor={titleColor ? titleColor : legalsite?.titleColor}
          titleSiteSize={titleSize ? titleSize : legalsite?.titleSize}
          bodySiteColor={bodyColor ? bodyColor : legalsite?.bodyColor}
          bodySiteSize={bodySize ? bodySize : legalsite?.bodySize}
          subtitleColor={
            subtitleColor ? subtitleColor : legalsite?.subtitleColor
          }
          subtitleSize={subtitleSize ? subtitleSize : legalsite?.subtitleSize}
          siteBtnColor={btnColor ? btnColor : legalsite?.buttonColor}
          siteBtnSize={siteBtnSize ? siteBtnSize : legalsite?.buttonSize}
          siteTitleFont={titleFont ? titleFont : legalsite?.titleFont}
          siteBodyFont={bodyFont ? bodyFont : legalsite?.bodyFont}
          siteButtonFont={footerFont ? footerFont : legalsite?.buttonFont}
          siteSubtitleFont={buttonFont ? buttonFont : legalsite?.subtitleFont}
          dataProtectionOfficer={dataProtectionOfficer}
          legalsiteSettings={
            this.props.dashboardStats
              ? this.props.dashboardStats.legalsiteSettings
              : null
          }
          isCDO={true}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

Dashboard.propTypes = {
  dashboardStats: PropTypes.object.isRequired,
  menuContent: PropTypes.object.isRequired,
  publishAllPages: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  completeOnboarding: PropTypes.object.isRequired,
  hideOnboarding: PropTypes.object.isRequired,
  hideFeedback: PropTypes.object.isRequired,
  completeOnboardingAsDp: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default compose(
  graphql(USER_DASHBOARD_QUERY, {
    options: () => ({
      variables: {
        graphType: "VISITORS",
        graphInterval: "WEEK",
        status: "ALL",
        requestStatus: "MOST_RECENT",
        interval: "PAST_WEEK",
      },
      fetchPolicy: "no-cache",
    }),
    name: "dashboardStats",
  }),
  graphql(MENU_CONTENT_QUERY, {
    name: "menuContent",
  }),
  graphql(PUBLISH_ALL_PAGES, {
    name: "publishAllPages",
  }),
  graphql(TOGGLE_SIDEBAR, {
    name: "toggleSidebar",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  }),
  graphql(COMPLETE_ONBOARDING, {
    name: "completeOnboarding",
  }),
  graphql(HIDE_ONBOARDING, {
    name: "hideOnboarding",
  }),
  graphql(HIDE_FEEDBACK, {
    name: "hideFeedback",
  }),
  graphql(COMPLETE_ONBOARDING_AS_DP, {
    name: "completeOnboardingAsDp",
  })
)(Dashboard);
