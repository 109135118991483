import React from "react";
import { Link } from "react-router-dom";
import WarningIcon from "./../Icons/WarningIcon";
import CheckIcon from "./../Icons/CheckIcon";
import PropTypes from "prop-types";

// Rest of the code...

// Rest of the code...

function StatusItem(props) {
  const { item, index, alerts } = props;

  const currentAlert = alerts.filter((a) =>
    a.category === "REVIEW_NEW_TEMPLATE_PUBLICATION" ||
    a.category === "REVIEW_TEMPLATE_CHANGES"
      ? a.page.id === item.id
      : null
  );

  return (
    <Link
      to={{
        pathname: "/pages",
        state: { isNotAdmin: true, page: item, index },
      }}
      className="status-card__notice d-flex justify-content-between"
      key={index}
    >
      <div className="d-flex flex-column justify-content-center">
        <span className="status-card__notice-label">{item.title}</span>
        <span className="status-card__notice-info">
          {currentAlert.length
            ? `${
                currentAlert[0].category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
                  ? "Review new template publication"
                  : "Review page details"
              }`
            : "Done and up-to-date"}
        </span>
      </div>

      <div className="d-flex align-items-center">
        {currentAlert.length ? <WarningIcon /> : <CheckIcon />}
      </div>
    </Link>
  );
}

export default StatusItem;
StatusItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  alerts: PropTypes.array.isRequired,
};
