import gql from "graphql-tag";

export const USERS_DETAILS_QUERY = gql`
  query($id: ID!, $entityType: EntityTypeEnum!) {
    dataEntity(id: $id, entityType: $entityType) {
      ... on DataController {
        addedByAccount
        addedByName
        addedByCompany
      }
      addedMethod
      avatar
      company
      addedAt
      description
      internalId
      ipAddress
      keyValue
      purpose
      lastUpdated
      userAgent
      notes
      dpa {
        id
        file
      }
      email
      id
      interactions {
        id
        activity
      }
      name
      phoneNumber
    }
    account {
      id
      needToUpgrade
      users {
        id
      }
    }
  }
`;
