import React from "react";
import { Form, FormGroup, Input, FormFeedback, Button } from "reactstrap";

import PropTypes from "prop-types";

function UserInfo(props) {
  const { user, name, email, formErrors } = props;

  return (
    <div className="user-details__info mr-5">
      <p className="user-details__info-title">User Info</p>
      <Form onSubmit={props.handleSubmit}>
        <div className="user-details__info-avatar">
          {user.avatar ? (
            <img src={user.avatar} alt="avatar" />
          ) : (
            <span className="user-details__info-avatar-placeholder">
              {name
                ? name.charAt(0).toUpperCase()
                : email
                ? email.charAt(0).toUpperCase()
                : ""}
            </span>
          )}
        </div>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="name"
            placeholder="Name"
            defaultValue={user.name}
            invalid={formErrors.name}
          />
          <FormFeedback>Please add the user's name</FormFeedback>
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            defaultValue={user.email}
            invalid={formErrors.email}
          />
          <FormFeedback>Please add the user's name</FormFeedback>
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="tel"
            name="phone"
            placeholder="Phone number"
            defaultValue={user.phoneNumber}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="company"
            placeholder="Company"
            defaultValue={user.company}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="internal"
            placeholder="Internal ID code"
            defaultValue={user.internalId}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="ip"
            placeholder="IP Address"
            defaultValue={user.ipAddress}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="key"
            placeholder="Key value"
            defaultValue={user.keyValue}
          />
        </FormGroup>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="agent"
            placeholder="User Agent"
            defaultValue={user.userAgent}
          />
        </FormGroup>

        <Button className="btn--primary user-details__info-submit">
          Save changes
        </Button>
      </Form>
    </div>
  );
}

export default UserInfo;
UserInfo.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    company: PropTypes.string,
    internalId: PropTypes.string,
    ipAddress: PropTypes.string,
    keyValue: PropTypes.string,
    userAgent: PropTypes.string,
  }),
  name: PropTypes.string,
  email: PropTypes.string,
  formErrors: PropTypes.shape({
    name: PropTypes.bool,
    email: PropTypes.bool,
  }),
  handleSubmit: PropTypes.func.isRequired,
};
