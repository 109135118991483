import gql from "graphql-tag";

export const ADMIN_PAGE_QUERY = gql`
  {
    pageTemplates {
      description
      id
      publicationDownloadUrl
      sections {
        id
        applicable
        help
        text
        title
        readOnly
      }
      templatePublications {
        createdAt
        id
        sections {
          id
          applicable
          readOnly
          help
          text
          title
        }
      }
      title
      updatedAt
    }
  }
`;
