import PropTypes from "prop-types";
import React from "react";

const Lock = props => (
    <svg
        height={24}
        viewBox='0 0 19 24'
        width={19}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--basic-settings---account" transform="translate(-181.000000, -1066.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Privacy" transform="translate(149.000000, 1032.000000)">
                    <g id="padlock" transform="translate(32.000000, 34.000000)">
                        <path d="M9.5,13.2 C8.75953651,13.1912535 8.09200726,13.6493918 7.82706611,14.3481672 C7.56212495,15.0469425 7.75582138,15.8385257 8.3125,16.332 L8.3125,18 C8.3125,18.6627417 8.84416186,19.2 9.5,19.2 C10.1558381,19.2 10.6875,18.6627417 10.6875,18 L10.6875,16.332 C11.2441786,15.8385257 11.437875,15.0469425 11.1729339,14.3481672 C10.9079927,13.6493918 10.2404635,13.1912535 9.5,13.2 Z M15.4375,8.4 L15.4375,6 C15.4375,2.6862915 12.7791907,0 9.5,0 C6.2208093,-5.32907052e-16 3.5625,2.6862915 3.5625,6 L3.5625,8.4 C1.59498558,8.4 0,10.0117749 0,12 L0,20.4 C0,22.3882251 1.59498558,24 3.5625,24 L15.4375,24 C17.4050144,24 19,22.3882251 19,20.4 L19,12 C19,10.0117749 17.4050144,8.4 15.4375,8.4 Z M5.9375,6 C5.9375,4.0117749 7.53248558,2.4 9.5,2.4 C11.4675144,2.4 13.0625,4.0117749 13.0625,6 L13.0625,8.4 L5.9375,8.4 L5.9375,6 Z M16.625,20.4 C16.625,21.0627417 16.0933381,21.6 15.4375,21.6 L3.5625,21.6 C2.90666186,21.6 2.375,21.0627417 2.375,20.4 L2.375,12 C2.375,11.3372583 2.90666186,10.8 3.5625,10.8 L15.4375,10.8 C16.0933381,10.8 16.625,11.3372583 16.625,12 L16.625,20.4 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Lock.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Lock;