import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import { Link } from "react-router-dom";
import ViewIcon from "../../components/Icons/ViewIcon";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import Edit from "react-icons/lib/ti/edit";
import TrashIcon from "../../components/Icons/TrashIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { getCountries } from "../../api/CountriesApi";
import { REMOVE_USER } from "../../graphql/removeUser";
import { REMOVE_CLIENT } from "../../graphql/removeClient";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { capitalize } from "../../utils/helpers";
import Moment from "react-moment";
import AddUserModal from "../../components/Modals/AddUserModal";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import DeleteClient from "../../components/Modals/DeleteClient";

class ClientInfo extends Component {
  static propTypes = {
    client: PropTypes.array,
    editClient: PropTypes.func.isRequired,
    account: PropTypes.object,
    refetchClients: PropTypes.func.isRequired,
    isFromUsers: PropTypes.bool,
    removeUser: PropTypes.func,
    generateReport: PropTypes.func,
    removeClient: PropTypes.func,
    showClient: PropTypes.func,
  };

  state = {
    name: this.props.isFromUsers
      ? this.props.client.companyName
        ? this.props.client.companyName
        : ""
      : this.props.client[0].companyName
      ? this.props.client[0].companyName
      : "",
    email: this.props.isFromUsers
      ? this.props.client.email
      : this.props.client[0].email,
    plan: this.props.client[0].plan,
    teamSize: this.props.isFromUsers
      ? this.props.client.teamSize
        ? this.props.client.teamSize
        : 0
      : this.props.client[0].teamSize
      ? this.props.client[0].teamSize
      : 0,
    phone: this.props.isFromUsers
      ? this.props.client.phoneNumber
        ? this.props.client.phoneNumber
        : ""
      : this.props.client[0].phoneNumber
      ? this.props.client[0].phoneNumber
      : "",
    countries: [],
    country: "",
    formErrors: {},
    isAddModalVisible: false,
    user: null,
    isDeleteModalVisible: false,
    isDeleteClientModalOpen: false,
    deleteAccountId: null,
  };

  componentDidMount() {
    this.showData();
  }

  showAddModal = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
    });
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  showData = async () => {
    try {
      const countries = await getCountries();

      this.setState({
        countries,
      });
    } catch (err) {
      console.log(err);
    }
  };

  selectCountry = (e) => {
    this.setState({
      country: e.target.value,
    });
  };

  notify = (text) => toast(text);

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      email = formData.get("email"),
      country = formData.get("country");

    let hasFormErrors = false;
    let formErrors = {};

    if (!country) {
      hasFormErrors = true;
      formErrors.country = true;
    }

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props.editClient(
      this.props.isFromUsers ? this.props.client.id : this.props.client[0].id,
      name,
      email,
      country
    );
  };

  removeUser = () => {
    this.props
      .removeUser({
        variables: {
          ids: this.state.deleteAccountId,
        },
      })
      .then(() => {
        this.props.refetchClients();
        this.notify("User removed");
      })
      .catch((error) => {
        console.log(error);
        this.notify("User was not removed");
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  generateReport = (id) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: "ACCOUNT",
        },
      })
      .then(({ data }) => {
        this.download(data.generateReport.downloadUrl, "report");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteClient = () => {
    this.props
      .removeClient({
        variables: {
          accountIds: this.props.client[0].id,
        },
      })
      .then(() => {
        this.props.refetchClients();
        this.props.showClient();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Client was not removed");
      });
  };

  openDeleteClientModal = () => {
    this.setState({
      isDeleteClientModalOpen: !this.state.isDeleteClientModalOpen,
    });
  };

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${
          this.props.isFromUsers
            ? this.props.client.companyName
            : this.props.client[0].companyName
        }.pdf`
      );
      document.body.appendChild(link);
      link.click();
      this.notify(this.showDownloadNotifyText());
    });
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  render() {
    const { client, isFromUsers } = this.props;
    const { country, countries, isAddModalVisible } = this.state;
    const clients = isFromUsers && client ? client.users : client[0].users;

    return (
      <div className="client">
        <Form onSubmit={this.handleSubmit}>
          <div>
            <div className="client__container">
              <div className="d-flex flex-column client__content">
                <p className="client__title">Client Info</p>
                <div className="d-flex flex-wrap">
                  {(
                    isFromUsers ? client.companyLogo : client[0].companyLogo
                  ) ? (
                    <img
                      className="client__avatar"
                      src={
                        isFromUsers ? client.companyLogo : client[0].companyLogo
                      }
                      alt="avatar"
                    />
                  ) : (
                    <span className="client__avatar-placeholder">
                      {(
                        isFromUsers ? client.companyName : client[0].companyName
                      )
                        ? isFromUsers
                          ? client.companyName.charAt(0).toUpperCase()
                          : client[0].companyName.charAt(0).toUpperCase()
                        : ""}
                    </span>
                  )}

                  <div className="form">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="mb-4 mr-4 client__form-group">
                        <Input
                          type="text"
                          className="mb-0"
                          placeholder="Client name"
                          name="name"
                          invalid={this.state.formErrors.name}
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                        />
                        <FormFeedback>Please add client name</FormFeedback>
                      </div>
                      <div className="mb-4 client__form-group">
                        <Input
                          type="email"
                          className="mb-0"
                          placeholder="Client email"
                          name="email"
                          invalid={this.state.formErrors.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                          value={this.state.email}
                        />
                        <FormFeedback>Please add client email</FormFeedback>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between flex-wrap">
                      <Input
                        type="tel"
                        readOnly={true}
                        placeholder="Telephone"
                        onChange={(e) =>
                          this.setState({ phone: e.target.value })
                        }
                        value={this.state.phone}
                      />
                      <Input
                        type="text"
                        defaultValue={
                          this.state.plan === "NON"
                            ? "No Plan"
                            : this.state.plan === "PAID"
                            ? "Paid"
                            : "Free"
                        }
                        readOnly={true}
                      />
                    </div>

                    <Input
                      type="text"
                      placeholder="users"
                      onChange={(e) =>
                        this.setState({ teamSize: e.target.value })
                      }
                      readOnly={true}
                      value={`${this.state.teamSize} ${
                        this.state.teamSize === 1 ? " user" : "users"
                      }`}
                    />

                    <FormGroup className="position-relative mb-4 client__select">
                      <Input
                        type="select"
                        name="country"
                        className="mb-0"
                        onChange={this.selectCountry}
                        invalid={this.state.formErrors.country}
                        value={
                          country
                            ? country
                            : !isFromUsers && client[0].country
                            ? client[0].country
                            : isFromUsers && client && client.country
                            ? client.country
                            : "Country"
                        }
                      >
                        <option value="Country" disabled>
                          {client[0] && client[0].country && !isFromUsers
                            ? client[0].country
                            : isFromUsers && client && client.country
                            ? client.country
                            : "Country"}
                        </option>
                        {countries.map((item, index) => (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        ))}
                      </Input>
                      <FormFeedback>Please provide client country</FormFeedback>
                      <FaCaret className="position-absolute settings__input-icon" />
                    </FormGroup>
                  </div>
                </div>
              </div>

              <div>
                <p className="client__added">Added</p>
                <p className="client__date">
                  <Moment format="DD/MM/YYYY">
                    {isFromUsers ? client.createdAt : client[0].createdAt}
                  </Moment>
                </p>
              </div>

              <div>
                <p
                  className="text-right client__download"
                  onClick={() =>
                    this.generateReport(
                      isFromUsers ? client.id : client[0].id,
                      "ACCOUNT"
                    )
                  }
                >
                  <DownloadIcon className="mr-2" color="#7450c8" /> Download
                </p>
                <p
                  className="text-right client__delete"
                  onClick={() => this.openDeleteClientModal()}
                >
                  <TrashIcon className="mr-2" color="#7450c8" /> Delete
                </p>
              </div>
            </div>

            <div className="client__user-table">
              <Table>
                <thead>
                  <tr>
                    <th>Users</th>
                    <th>Job title</th>
                    <th>Access</th>
                    <th>Actions</th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  {clients
                    .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                    .map((item) => (
                      <tr key={item.id}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="settings__user-avatar">
                              {item.avatar ? (
                                <img src={item.avatar} alt="avatar" />
                              ) : (
                                <span className="navigationBar__default-avatar">
                                  {item.name
                                    ? item.name.charAt(0).toUpperCase()
                                    : ""}
                                </span>
                              )}
                            </div>
                            <div className="d-flex flex-column">
                              <span className="settings__user-name">
                                {item.name}
                              </span>
                              <span className="settings__user-email">
                                {item.email}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{item.jobTitle}</td>
                        <td>
                          {item.role === "DATA_MANAGER"
                            ? "Data Manager"
                            : capitalize(item.role.toLowerCase())}
                        </td>
                        <td>
                          <Edit
                            onClick={() => {
                              this.setState({ user: item });
                              this.showAddModal();
                            }}
                            className="mr-4 settings__user-actions"
                            color={"#969696"}
                            size={"23"}
                          />
                          <TrashIcon
                            color={"#969696"}
                            className="settings__user-delete"
                            onClick={() => {
                              this.openDeleteModal();
                              this.setState({ deleteAccountId: item.id });
                            }}
                          />
                        </td>
                        <td>
                          <Link
                            to={{
                              pathname: "/users-admin",
                              state: { isUserView: true, user: item },
                            }}
                            className="settings__user-view"
                          >
                            <ViewIcon color={"#7450c8"} /> &nbsp;View
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button type="submit" className="btn--primary client__btn">
              Save and close
            </Button>
          </div>
        </Form>

        {this.state.user ? (
          <AddUserModal
            isAddModalVisible={isAddModalVisible}
            showAddModal={this.showAddModal}
            isEdit={true}
            account={this.props.account}
            refetchAccount={this.props.refetchClients}
            notify={this.notify}
            isAdmin={true}
            user={this.state.user}
          />
        ) : null}

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          removeUser={this.removeUser}
        />

        <DeleteClient
          isDeleteClientModalOpen={this.state.isDeleteClientModalOpen}
          openDeleteClientModal={this.openDeleteClientModal}
          deleteClient={this.deleteClient}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(REMOVE_USER, {
    name: "removeUser",
  }),
  graphql(REMOVE_CLIENT, {
    name: "removeClient",
  }),
  graphql(GENERATE_REPORT, {
    name: "generateReport",
  })
)(ClientInfo);
