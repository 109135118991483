import gql from "graphql-tag";

export const EDIT_DP = gql`
  mutation(
    $companyName: String!
    $email: String!
    $website: String!
    $show: Boolean!
    $category: DataProcessorCategoryEnum!
    $clientMutationId: String
  ) {
    editDataProcessor(
      input: {
        companyName: $companyName
        email: $email
        clientMutationId: $clientMutationId
        website: $website
        show: $show
        category: $category
      }
    ) {
      errors
      dataProcessor {
        id
        companyName
        email
        website
        show
        category
      }
    }
  }
`;
