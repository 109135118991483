import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import ReactFilestack from "filestack-react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { DP_ACCOUNT_QUERY } from "../../graphql/dpAccount";
import { PUBLISH_DP_PROFILE } from "../../graphql/publishProfileDataProcessor";
import SyncLoader from "react-spinners/SyncLoader";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";

class DpProfile extends Component {
  static propTypes = {
    dpId: PropTypes.string.isRequired,
    dpAccount: PropTypes.object.isRequired,
    publishProfile: PropTypes.func.isRequired,
    showDpProfile: PropTypes.func.isRequired,
  };

  state = {
    companyName: "",
    description: "",
    title1: "",
    text1: "",
    title2: "",
    text2: "",
    files: [],
    photo1: [],
    photo2: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  onAddPicture1 = ({ filesUploaded }) => {
    this.setState({ photo1: filesUploaded });
  };

  onAddPicture2 = ({ filesUploaded }) => {
    this.setState({ photo2: filesUploaded });
  };

  publishProfile = () => {
    const { dataProcessor } = this.props.dpAccount;
    const logo = this.state.files.length
      ? this.state.files[0].url
      : !this.state.files.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.logo
      ? dataProcessor.profile.logo
      : null;

    const companyName = this.state.companyName
      ? this.state.companyName
      : dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.companyName
      ? dataProcessor.profile.companyName
      : "";

    const description = this.state.description
      ? this.state.description
      : dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.smallDescription
      ? dataProcessor.profile.smallDescription
      : "";

    const photo1 = this.state.photo1.length
      ? this.state.photo1[0].url
      : !this.state.photo1.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.photo1
      ? dataProcessor.profile.photo1
      : null;

    const title1 = this.state.title1
      ? this.state.title1
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.title1
      ? dataProcessor.profile.title1
      : "";

    const text1 = this.state.text1
      ? this.state.text1
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.text1
      ? dataProcessor.profile.text1
      : "";

    const photo2 = this.state.photo2.length
      ? this.state.photo2[0].url
      : !this.state.photo2.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.photo2
      ? dataProcessor.profile.photo2
      : null;

    const title2 = this.state.title2
      ? this.state.title2
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.title2
      ? dataProcessor.profile.title2
      : "";

    const text2 = this.state.text2
      ? this.state.text2
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.text2
      ? dataProcessor.profile.text2
      : "";

    this.props
      .publishProfile({
        variables: {
          logo,
          smallDescription: description,
          companyName,
          photo1,
          title1,
          text1,
          photo2,
          title2,
          text2,
        },
      })
      .then(() => {
        this.props.dpAccount.refetch();
        this.props.showDpProfile(null, true);
      })
      .catch((error) => {
        console.log(error);
        this.notify("Profile was not published!");
      });
  };

  notify = (text) => toast(text);

  render() {
    const { files, photo1, photo2 } = this.state;

    const { dataProcessor, loading, error } = this.props.dpAccount;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="dp-profile container">
        <p className="dp-profile__title">Set up your Data Processor Profile</p>
        <p className="dp-profile__text">
          Adding your profile to the Data Processor index allows your users to
          easily add you as one of their data processors. Please fill out all
          fields to make sure your profile will look good. See some example
          profiles <a href="https://legalsite.co/data-processors.html">here</a>.
        </p>

        <div className="d-flex">
          <Button className="btn--primary mr-4" onClick={this.publishProfile}>
            Publish Profile
          </Button>
          <Button
            className="btn--secondary"
            onClick={() => this.props.showDpProfile(null, true)}
          >
            Cancel
          </Button>
        </div>

        <div className="dp-profile__container">
          <div className="dp-profile__banner">Preview Mode</div>
          <div className="dp-profile__header">
            <div className="position-relative">
              {files.length > 0 || (files.length > 0 && !dataProcessor) ? (
                files.map((file, i) => (
                  <div key={i}>
                    <img src={file.url} alt="avatar" />
                  </div>
                ))
              ) : !files.length &&
                dataProcessor &&
                dataProcessor.profile &&
                dataProcessor.profile.logo ? (
                <div>
                  <img src={dataProcessor.profile.logo} alt="avatar" />
                </div>
              ) : (
                <div className="dp-profile__add-logo">ADD LOGO</div>
              )}

              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: <span></span>,
                  customClass: "dp-profile__add-link",
                }}
                onSuccess={this.onFiles}
              />
            </div>

            <TextareaAutosize
              value={
                this.state.companyName
                  ? this.state.companyName
                  : dataProcessor &&
                    dataProcessor.profile &&
                    dataProcessor.profile.companyName
                  ? dataProcessor.profile.companyName
                  : ""
              }
              minRows={1}
              autoFocus={true}
              className="dp-profile__company-name"
              onChange={(e) => this.setState({ companyName: e.target.value })}
              placeholder="Company name"
            />
          </div>

          <div className="dp-profile__description">
            <TextareaAutosize
              value={
                this.state.description
                  ? this.state.description
                  : dataProcessor &&
                    dataProcessor.profile &&
                    dataProcessor.profile.smallDescription
                  ? dataProcessor.profile.smallDescription
                  : ""
              }
              minRows={1}
              className="dp-profile__description-text"
              onChange={(e) => this.setState({ description: e.target.value })}
              placeholder="Enter a small discription about your company product or service."
            />
          </div>

          <Button className="btn--primary mx-auto d-block">
            Add Data Processor
          </Button>

          <div className="dp-profile__item">
            <div className="dp-profile__item-img">
              <div className="position-relative">
                {photo1.length > 0 || (photo1.length > 0 && !dataProcessor) ? (
                  photo1.map((file, i) => (
                    <div key={i}>
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : !photo1.length &&
                  dataProcessor &&
                  dataProcessor.profile &&
                  dataProcessor.profile.photo1 ? (
                  <div>
                    <img src={dataProcessor.profile.photo1} alt="avatar" />
                  </div>
                ) : (
                  <div className="dp-profile__add-photo">ADD PHOTO</div>
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span></span>,
                    customClass: "dp-profile__add-photo-link",
                  }}
                  onSuccess={this.onAddPicture1}
                />
              </div>
            </div>

            <div className="dp-profile__item-content">
              <TextareaAutosize
                value={
                  this.state.title1
                    ? this.state.title1
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.title1
                    ? dataProcessor.profile.title1
                    : ""
                }
                minRows={1}
                className="dp-profile__item-title"
                onChange={(e) => this.setState({ title1: e.target.value })}
                placeholder="Write Title 1"
              />

              <TextareaAutosize
                value={
                  this.state.text1
                    ? this.state.text1
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.text1
                    ? dataProcessor.profile.text1
                    : ""
                }
                minRows={1}
                className="dp-profile__item-text"
                onChange={(e) => this.setState({ text1: e.target.value })}
                placeholder="Write a section about your company product or service."
              />
            </div>
          </div>

          <div className="dp-profile__item mb-0">
            <div className="dp-profile__item-content">
              <TextareaAutosize
                value={
                  this.state.title2
                    ? this.state.title2
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.title2
                    ? dataProcessor.profile.title2
                    : ""
                }
                minRows={1}
                className="dp-profile__item-title"
                onChange={(e) => this.setState({ title2: e.target.value })}
                placeholder="Write Title 2"
              />

              <TextareaAutosize
                value={
                  this.state.text2
                    ? this.state.text2
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.text2
                    ? dataProcessor.profile.text2
                    : ""
                }
                minRows={1}
                className="dp-profile__item-text"
                onChange={(e) => this.setState({ text2: e.target.value })}
                placeholder="Write a section about your company product or service."
              />
            </div>

            <div className="dp-profile__item-img">
              <div className="position-relative">
                {photo2.length > 0 || (photo2.length > 0 && !dataProcessor) ? (
                  photo2.map((file, i) => (
                    <div key={i}>
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : !photo2.length &&
                  dataProcessor &&
                  dataProcessor.profile &&
                  dataProcessor.profile.photo2 ? (
                  <div>
                    <img src={dataProcessor.profile.photo2} alt="avatar" />
                  </div>
                ) : (
                  <div className="dp-profile__add-photo">ADD PHOTO</div>
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span></span>,
                    customClass: "dp-profile__add-photo-link",
                  }}
                  onSuccess={this.onAddPicture2}
                />
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(DP_ACCOUNT_QUERY, {
    name: "dpAccount",
    options: (props) => ({
      variables: {
        dpId: props.dpId,
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(PUBLISH_DP_PROFILE, {
    name: "publishProfile",
  })
)(DpProfile);
