import gql from "graphql-tag";

export const SELECT_PLAN = gql`
  {
    account {
      id
      email
      subscription {
        addons
        downgradeToFreeUrl
        id
        planName
        status
        upgradeToBusinessUrl
      }
    }
  }
`;
