import gql from "graphql-tag";

export const CONTINUE_IMPORT = gql`
  mutation($clientMutationId: String, $id: ID!) {
    continueImport(
      input: {
        clientMutationId: $clientMutationId
        id: $id
      }
    ) {
      errors
      import {
        id
        status
      }
    }
  }
`;
