import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  FormGroup,
  FormFeedback,
  Alert,
} from "reactstrap";
import logo from "../../images/Login/logo purple and white.svg";
import { SIGN_IN } from "../../graphql/signIn";
import { Redirect } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { AUTH_TOKEN } from "../../constants";
import "./sign-in.scss";
import { FORGOT_PASSWORD } from "../../graphql/forgotPassword";
import { RESEND_CONFIRMATION_EMAIL } from "../../graphql/resendConfirmationMail";
import PropTypes from "prop-types";

class SignIn extends Component {
  static propTypes = {
    signin: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    resendEmail: PropTypes.func.isRequired,
  };
  state = {
    isForgot: false,
    formErrors: {},
    logFormErrors: {},
    isCheck: false,
    showIntroduction: null,
    hasError: false,
    notConfirmed: false,
    hasResetError: false,
    showDashboard: false,
    showPlans: false,
    isVerify: false,
    isCheckVerify: false,
  };

  showForgot = () => {
    this.setState({
      isForgot: !this.state.isForgot,
    });
  };

  showCreate = () => {
    window.location.href = "/signup";
  };

  showVerify = () => {
    this.setState({
      isVerify: !this.state.isVerify,
    });
  };

  logIn = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const logEmail = formData.get("email"),
      logPassword = formData.get("password");

    let hasLogFormErrors = false;
    let logFormErrors = {};

    if (!logEmail) {
      hasLogFormErrors = true;
      logFormErrors.logEmail = true;
    }

    if (!logPassword) {
      hasLogFormErrors = true;
      logFormErrors.email = true;
    }

    this.setState({
      logFormErrors,
    });

    if (hasLogFormErrors) {
      return;
    }

    this.props
      .signin({
        variables: {
          email: logEmail,
          password: logPassword,
        },
      })
      .then(({ data }) => {
        const token = data.signIn.authenticationToken;
        this.setUserToken(token);

        this.setState({
          showDashboard: true,
          showIntroduction: true,
          hasError: false,
          notConfirmed: false,
          showPlans: false,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error && error.graphQLErrors && error.graphQLErrors.length) {
          if (error.graphQLErrors[0].message === "User is not confirmed") {
            this.setState({
              notConfirmed: true,
              hasError: true,
            });
          }
        } else {
          this.setState({
            hasError: true,
            notConfirmed: false,
          });
        }
      });
  };

  setUserToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  sendResetEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const confirmEmail = formData.get("confirmEmail");

    let hasFormErrors = false;
    let formErrors = {};

    if (!confirmEmail) {
      hasFormErrors = true;
      formErrors.confirmEmail = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .resendEmail({
        variables: {
          email: confirmEmail,
        },
      })
      .then(() => {
        this.setState({
          isVerify: false,
          isCheckVerify: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sendForgotPasswordEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const email = formData.get("email");

    let hasFormErrors = false;
    let formErrors = {};

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .forgotPassword({
        variables: {
          email,
        },
      })
      .then(() => {
        this.setState({
          isCheck: true,
          hasResetError: false,
        });
      })
      .catch((error) => {
        console.log(error);

        this.setState({
          hasResetError: true,
        });
      });
  };

  render() {
    const {
      isForgot,
      isCheck,
      hasError,
      hasResetError,
      notConfirmed,
      isVerify,
      isCheckVerify,
    } = this.state;

    if (this.state.showPlans === true) {
      return (
        <Redirect
          to={{ pathname: "/select-plan", state: { showIntroduction: true } }}
        />
      );
    }

    if (this.state.showDashboard === true) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    return (
      <div className="login">
        <div className="login__banner">
          <div className="login__banner-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="login__form">
          {!isForgot && !isCheck && !isVerify && !isCheckVerify ? (
            <div className="login__form-container">
              {hasError ? (
                <Alert className="login__error" color="danger">
                  {notConfirmed
                    ? "Verify your email to confirm your account"
                    : "You've entered an incorrect email address and/or password"}
                </Alert>
              ) : null}
              <h2 className="login__form-title">Login</h2>

              <p className="login__form-create">
                or{" "}
                <span className="create" onClick={this.showCreate}>
                  Create an account
                </span>
              </p>

              <Form onSubmit={this.logIn}>
                <Input type="email" placeholder="E-mail" name="email" />
                <Input type="password" placeholder="Password" name="password" />

                <div className="d-flex justify-content-between">
                  <div>
                    <p className="login__form-forgot" onClick={this.showForgot}>
                      Forgot your password?
                    </p>
                    {notConfirmed ? (
                      <p
                        className="login__form-forgot"
                        onClick={this.showVerify}
                      >
                        Verify e-mail
                      </p>
                    ) : null}
                  </div>
                  <Button
                    className="btn--primary login__form-log"
                    type="submit"
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </div>
          ) : isCheck ? (
            <div className="login__form-container -check">
              <h2 className="login__form-title">Check your e-mail!</h2>

              <p className="login__form-place">
                Click in the link we just sent in your e-mail to set a new
                password and log in.
              </p>
            </div>
          ) : isVerify ? (
            <div className="login__form-container">
              <h2 className="login__form-title">Verify e-mail</h2>

              <p className="login__form-place">
                Place your e-mail in the field below so we can resend the
                verification e-mail.
              </p>

              <Form onSubmit={this.sendResetEmail}>
                <FormGroup className="mb-4">
                  <Input
                    className="mb-0"
                    type="email"
                    name="confirmEmail"
                    invalid={this.state.formErrors.confirmEmail}
                    placeholder="E-mail"
                  />
                  <FormFeedback>Please provide your email</FormFeedback>
                </FormGroup>

                <div className="d-flex justify-content-between">
                  <p className="login__form-return" onClick={this.showVerify}>
                    Return to login
                  </p>
                  <Button
                    type="submit"
                    className="btn--primary login__form-log"
                  >
                    Resend email
                  </Button>
                </div>
              </Form>
            </div>
          ) : isCheckVerify ? (
            <div className="login__form-container">
              <h2 className="login__form-title">Check your e-mail!</h2>

              <p className="login__form-place">
                Click in the link we just sent you to verify your e-mail.
              </p>

              <p
                className="login__form-return"
                onClick={() => this.setState({ isCheckVerify: false })}
              >
                Return to login
              </p>
            </div>
          ) : isForgot ? (
            <div className="login__form-container">
              {hasResetError ? (
                <Alert className="login__error" color="danger">
                  You've entered an incorrect email address
                </Alert>
              ) : null}
              <h2 className="login__form-title">Forgot Password</h2>

              <p className="login__form-place">
                Place your e-mail in the field below. We will send you a link
                with the instructions to follow.
              </p>

              <Form onSubmit={this.sendForgotPasswordEmail}>
                <FormGroup className="mb-4">
                  <Input
                    className="mb-0"
                    type="email"
                    name="email"
                    invalid={this.state.formErrors.email}
                    placeholder="E-mail"
                  />
                  <FormFeedback>Please provide your email</FormFeedback>
                </FormGroup>

                <div className="d-flex justify-content-between">
                  <p className="login__form-return" onClick={this.showForgot}>
                    Return to login
                  </p>
                  <Button
                    type="submit"
                    className="btn--primary login__form-log"
                  >
                    Send
                  </Button>
                </div>
              </Form>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(SIGN_IN, {
    name: "signin",
  }),
  graphql(FORGOT_PASSWORD, {
    name: "forgotPassword",
  }),
  graphql(RESEND_CONFIRMATION_EMAIL, {
    name: "resendEmail",
  })
)(SignIn);
