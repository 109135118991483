import PropTypes from "prop-types";
import React from "react";

const GreenCheck = props => (
    <svg
        height={40}
        viewBox='0 0 40 40'
        width={40}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <defs>
            <circle id="path-1" cx="20" cy="20" r="20"></circle>
            <circle id="path-3" cx="20" cy="20" r="20"></circle>
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="previous" transform="translate(-32.000000, -341.000000)">
                <g id="Group">
                    <g transform="translate(0.000000, 316.000000)">
                        <g id="Group-9">
                            <g id="Previous">
                                <g id="Avatar" transform="translate(32.000000, 25.000000)">
                                    <mask id="mask-2" fill="white">
                                        <use xlinkHref="#path-1"></use>
                                    </mask>
                                    <use id="Photo" fill="#FFFFFF" xlinkHref="#path-1"></use>
                                    <mask id="mask-4" fill="white">
                                        <use xlinkHref="#path-3"></use>
                                    </mask>
                                    <use id="Photo" fillOpacity="0.3" fill="#1DDCC0" xlinkHref="#path-3"></use>
                                    <g id="check-circle" mask="url(#mask-4)" fill="#1DDCC0" fillRule="nonzero">
                                        <g transform="translate(11.000000, 13.000000)" id="Path">
                                            <path d="M6.52524385,9.18052382 L3.36747459,5.88090536 C2.89976135,5.31021904 2.16538189,5.06163621 1.46626981,5.23735951 C0.767157727,5.41308281 0.221306161,5.98345441 0.0531371321,6.71397109 C-0.115031897,7.44448777 0.122864458,8.21185607 0.669017224,8.70057932 L5.16644617,13.4200336 C5.52761619,13.7943633 6.01655022,14.0030397 6.52524385,13.9999665 C7.02730979,13.9977575 7.50843801,13.7894792 7.86490354,13.4200336 L17.4339013,3.42118979 C17.7962078,3.04570001 18,2.53456738 18,2.00135397 C18,1.46814055 17.7962078,0.95700793 17.4339013,0.581518149 C16.6874478,-0.193839383 15.4818974,-0.193839383 14.7354439,0.581518149 L6.52524385,9.18052382 Z"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

GreenCheck.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default GreenCheck;