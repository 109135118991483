import PropTypes from "prop-types";
import React from "react";

const Smile = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-7---Help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--contact-2" transform="translate(-1293.000000, -945.000000)" fill="#969696" fillRule="nonzero">
                <g id="Chatbot" transform="translate(1018.000000, 396.000000)">
                    <g id="write-bar" transform="translate(0.000000, 530.000000)">
                        <g id="icons" transform="translate(275.000000, 19.000000)">
                            <g id="smile">
                                <path d="M9.888,9.784 C8.78610126,10.6724111 7.21389874,10.6724111 6.112,9.784 C5.77179259,9.50123021 5.26676979,9.54779259 4.984,9.888 C4.70123021,10.2282074 4.74779259,10.7332302 5.088,11.016 C6.77417855,12.4236086 9.22582145,12.4236086 10.912,11.016 C11.1320756,10.83308 11.2378472,10.546646 11.1894716,10.264595 C11.1410961,9.98254394 10.9459228,9.74772607 10.6774716,9.64859499 C10.4090205,9.54946392 10.1080756,9.60108002 9.888,9.784 Z M5.6,7.2 C6.0418278,7.2 6.4,6.8418278 6.4,6.4 C6.4,5.9581722 6.0418278,5.6 5.6,5.6 C5.1581722,5.6 4.8,5.9581722 4.8,6.4 C4.8,6.8418278 5.1581722,7.2 5.6,7.2 Z M10.4,5.6 C9.9581722,5.6 9.6,5.9581722 9.6,6.4 C9.6,6.8418278 9.9581722,7.2 10.4,7.2 C10.8418278,7.2 11.2,6.8418278 11.2,6.4 C11.2,5.9581722 10.8418278,5.6 10.4,5.6 Z M8,3.55271368e-16 C3.581722,-1.77635684e-16 3.55271368e-16,3.581722 0,8 C-3.55271368e-16,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,5.87826808 15.1571453,3.84343678 13.6568542,2.34314575 C12.1565632,0.842854723 10.1217319,1.77635684e-16 8,3.55271368e-16 Z M8,14.4 C4.4653776,14.4 1.6,11.5346224 1.6,8 C1.6,4.4653776 4.4653776,1.6 8,1.6 C11.5346224,1.6 14.4,4.4653776 14.4,8 C14.4,9.69738553 13.7257162,11.3252506 12.5254834,12.5254834 C11.3252506,13.7257162 9.69738553,14.4 8,14.4 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Smile.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Smile;