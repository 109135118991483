import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Moment from "react-moment";

export default class DatePickerCustomInput extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.string,
    date: PropTypes.string,
  };

  render() {
    return (
      <Button
        className="compliance-breaches__where-date"
        onClick={this.props.onClick}
      >
        {this.props.value ? (
          this.props.value
        ) : !this.props.value && this.props.date ? (
          <Moment format="MMMM DD, YYYY HH:MM A">{this.props.date}</Moment>
        ) : (
          "Select a date"
        )}
      </Button>
    );
  }
}
