import gql from "graphql-tag";

export const EDIT_USER = gql`
  mutation(
    $fullName: String
    $email: String
    $jobTitle: String
    $role: String
    $phone: String
    $avatar: String
    $id: ID!
    $clientMutationId: String
  ) {
    editUser(
      input: {
        fullName: $fullName
        email: $email
        jobTitle: $jobTitle
        role: $role
        phone: $phone
        avatar: $avatar
        id: $id
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      user {
        id
        name
        email
        jobTitle
        role
        phoneNumber
        avatar
      }
    }
  }
`;
