import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Popover, PopoverBody } from "reactstrap";
import { ChromePicker } from "react-color";

export default class CustomiseColor extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    changeColor: PropTypes.func.isRequired,
    isDocs: PropTypes.bool,
    name: PropTypes.string,
  };

  state = {
    popoverOpen: false,
    background: this.props.color,
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  handleColorChange = (color) => {
    this.setState({ background: color.hex });

    this.props.changeColor(this.props.name, this.state.background);
  };

  render() {
    const { background, popoverOpen } = this.state;
    const { isDocs } = this.props;

    return (
      <div className="d-flex mr-3 customise-text-color">
        <Button
          style={{
            backgroundColor: this.props.color ? this.props.color : "#7450C8",
          }}
          className={`${
            isDocs
              ? "settings__legal-chosen-color"
              : "customization-cookie__right-menu-bg"
          }`}
          id={`colorPicker${this.props.index}`}
          type="button"
        />
        <Popover
          placement="bottom"
          trigger="legacy"
          isOpen={popoverOpen}
          target={`colorPicker${this.props.index}`}
          toggle={this.toggle}
        >
          <PopoverBody>
            <ChromePicker
              color={this.props.color ? this.props.color : background}
              onChangeComplete={this.handleColorChange}
            />
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}
