import gql from "graphql-tag";

export const SEND_CUSTOM_MAIL = gql`
  mutation(
    $requestId: ID!
    $clientMutationId: String
    $mailType: InboxEmailEnum!
    $message: String!
    $signature: String!
  ) {
    sendCustomInboxMail(
      input: {
        requestId: $requestId
        clientMutationId: $clientMutationId
        mailType: $mailType
        message: $message
        signature: $signature
      }
    ) {
      errors
      request {
        id
        status
      }
    }
  }
`;
