import PropTypes from "prop-types";
import React from "react";

const Plan = props => (
    <svg
        height={21}
        viewBox='0 0 24 21'
        width={24}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--basic-settings---account" transform="translate(-181.000000, -1565.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Plan" transform="translate(149.000000, 1530.000000)">
                    <g id="layers" transform="translate(32.000000, 35.000000)">
                        <path d="M0.649350649,9.03272727 L11.4025974,15.2457143 C11.7722714,15.4591457 12.2277286,15.4591457 12.5974026,15.2457143 L23.3506494,9.03272727 C23.7191299,8.81897796 23.9459349,8.42520982 23.9459349,7.99922078 C23.9459349,7.57323174 23.7191299,7.1794636 23.3506494,6.96571429 L12.5974026,0.752727273 C12.2277286,0.539295881 11.7722714,0.539295881 11.4025974,0.752727273 L0.649350649,6.96571429 C0.280870132,7.1794636 0.0540650801,7.57323174 0.0540650801,7.99922078 C0.0540650801,8.42520982 0.280870132,8.81897796 0.649350649,9.03272727 Z M12,3.16623377 L20.3636364,7.94545455 L12,12.7844156 L3.63636364,7.99324675 L12,3.16623377 Z M22.1558442,12.4737662 L12,18.3402597 L1.84415584,12.4737662 C1.57017668,12.3132155 1.24355495,12.268337 0.936426683,12.3490422 C0.629298417,12.4297475 0.366932134,12.6293966 0.207272727,12.9038961 C0.0477124521,13.1793271 0.00465322397,13.5070413 0.087646857,13.814342 C0.17064049,14.1216428 0.372825283,14.3831232 0.649350649,14.5407792 L11.4025974,20.7537662 C11.7722714,20.9671976 12.2277286,20.9671976 12.5974026,20.7537662 L23.3506494,14.5407792 C23.6271747,14.3831232 23.8293595,14.1216428 23.9123531,13.814342 C23.9953468,13.5070413 23.9522875,13.1793271 23.7927273,12.9038961 C23.6330679,12.6293966 23.3707016,12.4297475 23.0635733,12.3490422 C22.7564451,12.268337 22.4298233,12.3132155 22.1558442,12.4737662 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Plan.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Plan;