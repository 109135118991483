import React from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, FormGroup, FormFeedback } from "reactstrap";
import Image4 from "../../images/Settings/Illustration2.svg";

function AddDPStepOne(props) {
  const {
    handleStep1,
    companyErrors,
    emailErrors,
    websiteErrors,
    addDataProcessor,
  } = props;

  return (
    <div className="data-processors__add-content">
      <p className="data-processors__add-title">Add a new Data Processor</p>
      <p className="data-processors__add-subtitle">
        Fill the form below to add the Data Processor to your account. We’ll get
        in touch with the company and inform them how to process your privacy
        request.
      </p>

      <img
        className="data-processors__add-img"
        src={Image4}
        alt="Data Processor"
      />

      <Form onSubmit={handleStep1} className="data-processors__add-form">
        <FormGroup>
          <Input
            type="text"
            name="company"
            placeholder="Company name"
            invalid={companyErrors}
          />
          <FormFeedback>Please provide the company name</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            invalid={emailErrors}
          />
          <FormFeedback>Please provide an email</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Input
            type="text"
            name="website"
            placeholder="Website"
            invalid={websiteErrors}
          />
          <FormFeedback>Please provide the website</FormFeedback>
        </FormGroup>

        <div className="d-flex justify-content-center mt-5">
          <Button
            className="btn--secondary data-processors__add-cancel"
            onClick={addDataProcessor}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn--primary data-processors__add-next"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddDPStepOne;
AddDPStepOne.propTypes = {
  handleStep1: PropTypes.func.isRequired,
  companyErrors: PropTypes.bool,
  websiteErrors: PropTypes.bool,
  emailErrors: PropTypes.bool,
  addDataProcessor: PropTypes.func.isRequired,
};
