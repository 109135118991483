import gql from "graphql-tag";

export const VIEWER_QUERY = gql`
  {
    viewer {
      id
      editedPage
      avatar
      appAdmin
      email
      jobTitle
      name
      role
      onboarded
      onboardedAsDp
      publishedPage
      editedCompanyInfo
      setEmailNotification
      emailNotifications
      customizedPopup
      customizedLegalsite
      customizedEmails
      emailNotificationsInterval
      showSidebar
      confirmed
      invitedAsDp
      newNotifications
      showOnboardingModal
      showFeedbackModal
    }
  }
`;
