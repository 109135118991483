import gql from "graphql-tag";

export const EDIT_DATA_SUBJECTS_QUANTITY = gql`
  mutation($quantity: Int!, $clientMutationId: String) {
    editDataSubjectsQuantity(
      input: { quantity: $quantity, clientMutationId: $clientMutationId }
    ) {
      errors
      chargebeeUrl
    }
  }
`;
