/* eslint-disable react/prop-types */

import React, { Fragment, Component } from "react";
import CustomiseColor from "./CustomiseColor";
import CustomiseText from "./CustomiseText";
import ReactFilestack from "filestack-react";
import { CUSTOMIZE_LEGALSITE } from "../../graphql/customizeLegalsite";
import { graphql } from "@apollo/client/react/hoc";

class CustomizeFooter extends Component {
  state = {
    files: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });

    const avatar = this.state.files.length
      ? this.state.files[0].url
      : this.props.footerLogo
      ? this.props.footerLogo
      : null;

    this.props
      .customizeLegalsite({
        variables: {
          bodyBg: this.props.legalsite.bodyBg,
          bodyBtnAlign: this.props.legalsite.bodyBtnAlign,
          bodyBtnBold: this.props.legalsite.bodyBtnBold,
          bodyBtnColor: this.props.legalsite.bodyBtnColor,
          bodyBtnFont: this.props.legalsite.bodyBtnFont,
          bodyBtnItalic: this.props.legalsite.bodyBtnItalic,
          bodyBtnSize: this.props.legalsite.bodyBtnSize,
          bodyIconsColor: this.props.legalsite.bodyIconsColor,
          bodySubtitleAlign: this.props.legalsite.bodySubtitleAlign,
          bodySubtitleBold: this.props.legalsite.bodySubtitleBold,
          bodySubtitleColor: this.props.legalsite.bodySubtitleColor,
          bodySubtitleFont: this.props.legalsite.bodySubtitleFont,
          bodySubtitleItalic: this.props.legalsite.bodySubtitleItalic,
          bodySubtitleSize: this.props.legalsite.bodySubtitleSize,
          bodyTextAlign: this.props.legalsite.bodyTextAlign,
          bodyTextBold: this.props.legalsite.bodyTextBold,
          bodyTextColor: this.props.legalsite.bodyTextColor,
          bodyTextFont: this.props.legalsite.bodyTextFont,
          bodyTextItalic: this.props.legalsite.bodyTextItalic,
          bodyTextSize: this.props.legalsite.bodyTextSize,
          bodyTitleAlign: this.props.legalsite.bodyTitleAlign,
          bodyTitleBold: this.props.legalsite.bodyTitleBold,
          bodyTitleColor: this.props.legalsite.bodyTitleColor,
          bodyTitleFont: this.props.legalsite.bodyTitleFont,
          bodyTitleItalic: this.props.legalsite.bodyTitleItalic,
          bodyTitleSize: this.props.legalsite.bodyTitleSize,
          cardsBg: this.props.legalsite.cardsBg,
          cardsBodyAlign: this.props.legalsite.cardsBodyAlign,
          cardsBodyBold: this.props.legalsite.cardsBodyBold,
          cardsBodyColor: this.props.legalsite.cardsBodyColor,
          cardsBodyFont: this.props.legalsite.cardsBodyFont,
          cardsBodyItalic: this.props.legalsite.cardsBodyItalic,
          cardsBodySize: this.props.legalsite.cardsBodySize,
          cardsBtnAlign: this.props.legalsite.cardsBtnAlign,
          cardsBtnBg: this.props.legalsite.cardsBtnBg,
          cardsBtnBold: this.props.legalsite.cardsBtnBold,
          cardsBtnColor: this.props.legalsite.cardsBtnColor,
          cardsBtnFont: this.props.legalsite.cardsBtnFont,
          cardsBtnItalic: this.props.legalsite.cardsBtnItalic,
          cardsBtnSize: this.props.legalsite.cardsBtnSize,
          cardsIconsColor: this.props.legalsite.cardsIconsColor,
          cardsTitleAlign: this.props.legalsite.cardsTitleAlign,
          cardsTitleBold: this.props.legalsite.cardsTitleBold,
          cardsTitleColor: this.props.legalsite.cardsTitleColor,
          cardsTitleFont: this.props.legalsite.cardsTitleFont,
          cardsTitleItalic: this.props.legalsite.cardsTitleItalic,
          cardsTitleSize: this.props.legalsite.cardsTitleSize,
          footerBg: this.props.legalsite.footerBg,
          footerLinksAlign: this.props.legalsite.footerLinksAlign,
          footerLinksBold: this.props.legalsite.footerLinksBold,
          footerLinksColor: this.props.legalsite.footerLinksColor,
          footerLinksFont: this.props.legalsite.footerLinksFont,
          footerLinksItalic: this.props.legalsite.footerLinksItalic,
          footerLinksSize: this.props.legalsite.footerLinksSize,
          footerTextAlign: this.props.legalsite.footerTextAlign,
          footerTextBold: this.props.legalsite.footerTextBold,
          footerTextColor: this.props.legalsite.footerTextColor,
          footerTextFont: this.props.legalsite.footerTextFont,
          footerTextItalic: this.props.legalsite.footerTextItalic,
          footerTextSize: this.props.legalsite.footerTextSize,
          headerBg: this.props.legalsite.headerBg,
          headerBodyAlign: this.props.legalsite.headerBodyAlign,
          headerBodyBold: this.props.legalsite.headerBodyBold,
          headerBodyColor: this.props.legalsite.headerBodyColor,
          headerBodyFont: this.props.legalsite.headerBodyFont,
          headerBodyItalic: this.props.legalsite.headerBodyItalic,
          headerBodySize: this.props.legalsite.headerBodySize,
          headerTitleAlign: this.props.legalsite.headerTitleAlign,
          headerTitleBold: this.props.legalsite.headerTitleBold,
          headerTitleColor: this.props.legalsite.headerTitleColor,
          headerTitleFont: this.props.legalsite.headerTitleFont,
          headerTitleItalic: this.props.legalsite.headerTitleItalic,
          headerTitleSize: this.props.legalsite.headerTitleSize,
          navigationBg: this.props.legalsite.navigationBg,
          navigationLinksAlign: this.props.legalsite.navigationLinksAlign,
          navigationLinksBold: this.props.legalsite.navigationLinksBold,
          navigationLinksColor: this.props.legalsite.navigationLinksColor,
          navigationLinksFont: this.props.legalsite.navigationLinksFont,
          navigationLinksItalic: this.props.legalsite.navigationLinksItalic,
          navigationLinksSize: this.props.legalsite.navigationLinksSize,
          footerLogo: avatar,
        },
      })
      .then(() => {
        this.props.refetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      capitalize,
      changeBgColor,
      changeTextColor,
      getGoogleFont,
      changeFontSize,
      footerLinkColor,
      footerLinkSize,
      footerLinkFont,
      footerTextColor,
      footerTextSize,
      footerTextFont,
      footerBg,
      footerLogo,
    } = this.props;
    const { files } = this.state;

    return (
      <Fragment>
        <div className="customize-menu__logo-container">
          <p className="customize-menu__logo">Logo</p>
          <div className="d-flex align-items-center settings__legal-logo-container">
            <div className="position-relative">
              {files.length || (files.length && !footerLogo) ? (
                files.map((file, i) => (
                  <div key={i} className="settings__avatar-container">
                    <img src={file.url} alt="avatar" />
                  </div>
                ))
              ) : !files.length && footerLogo ? (
                <div className="settings__avatar-container">
                  <img src={footerLogo} alt="avatar" />
                </div>
              ) : (
                <span className="settings__image-placeholder" />
              )}

              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: <span />,
                  customClass: "show-upload",
                }}
                onSuccess={this.onFiles}
                actionOptions={{
                  accept: ["image/*"],
                  maxFiles: 1,
                }}
              />

              <p className="settings__legal-color mt-2">CHANGE LOGO</p>
            </div>
          </div>
        </div>
        <div>
          <CustomiseText
            title="Links"
            color={footerLinkColor ? footerLinkColor : "#333333"}
            size={footerLinkSize ? footerLinkSize : 14}
            isLegalsite={true}
            font={footerLinkFont ? capitalize(footerLinkFont) : "Roboto"}
            changeColor={changeTextColor}
            colorName="footerLinkColor"
            fontSizeName="footerLinkSize"
            fontFamilyName="footerLinkFont"
            getGoogleFont={getGoogleFont}
            changeFontSize={changeFontSize}
            index={71}
            getAligned={this.props.getAligned}
            getBold={this.props.getBold}
            getItalic={this.props.getItalic}
            alignName="footerLinkAlign"
            boldName="footerLinkBold"
            italicName="footerLinkItalic"
            boldValue={this.props.footerLinkBold}
            italicValue={this.props.footerLinkItalic}
            alignValue={this.props.footerLinkAlign}
          />
        </div>

        <div className="d-flex justify-content-between customize-menu__logo-container pb-4">
          <div>
            <p className="customise-text__label">Background color</p>
            <CustomiseColor
              isLegalsite={true}
              changeColor={changeBgColor}
              name="footerBg"
              color={footerBg ? footerBg : "#dfe4ee"}
              index={72}
            />
          </div>
        </div>

        <div>
          <CustomiseText
            title="Body Text"
            color={footerTextColor ? footerTextColor : "#333333"}
            size={footerTextSize ? footerTextSize : 14}
            isLegalsite={true}
            font={footerTextFont ? capitalize(footerTextFont) : "Roboto"}
            changeColor={changeTextColor}
            colorName="footerTextColor"
            fontSizeName="footerTextSize"
            fontFamilyName="footerTextFont"
            getGoogleFont={getGoogleFont}
            changeFontSize={changeFontSize}
            index={73}
            getAligned={this.props.getAligned}
            getBold={this.props.getBold}
            getItalic={this.props.getItalic}
            alignName="footerTextAlign"
            boldName="footerTextBold"
            italicName="footerTextItalic"
            boldValue={this.props.footerTextBold}
            italicValue={this.props.footerTextItalic}
            alignValue={this.props.footerTextAlign}
          />
        </div>
      </Fragment>
    );
  }
}

export default graphql(CUSTOMIZE_LEGALSITE, {
  name: "customizeLegalsite",
})(CustomizeFooter);
