import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputGroup, InputGroupAddon, Button, Input } from "reactstrap";
import IoSearch from "react-icons/lib/io/search";
import "./search-input.scss";

export default class SearchInput extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  state = {
    val: "",
  };

  handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.props.search(this.state.val);
    }
  };

  render() {
    return (
      <InputGroup className="search-input">
        <Input
          placeholder={this.props.placeholder}
          onChange={(e) => this.setState({ val: e.target.value })}
          onKeyDown={this.handleKeyDown}
          value={this.props.value}
        />
        <InputGroupAddon addonType="append">
          <Button
            className="search-input__addon"
            onClick={() => this.props.search(this.state.val)}
          >
            <IoSearch />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  }
}
