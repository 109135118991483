import React, { Component } from "react";
import { Button, Form, Input, FormFeedback, FormGroup } from "reactstrap";
import logo from "../../images/Login/logo purple and white.svg";
import { Redirect } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { AUTH_TOKEN } from "../../constants";
import { ACCEPT_INVITATION } from "../../graphql/acceptInvitation";
import PropTypes from "prop-types";
import "./sign-in.scss";

class Invite extends Component {
  static propTypes = {
    acceptInvitation: PropTypes.func.isRequired,
  };
  state = {
    token: null,
    formErrors: {},
    isAgreed: false,
    showIntroduction: false,
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");

    this.setState({
      token,
    });
  }

  showConfirmation = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const password1 = formData.get("password1"),
      password2 = formData.get("password2");

    let hasFormErrors = false;
    let formErrors = {};

    if (!password1) {
      hasFormErrors = true;
      formErrors.password1 = true;
    }

    if (!password2) {
      hasFormErrors = true;
      formErrors.password2 = true;
    }

    if (password1 !== password2) {
      hasFormErrors = true;
      formErrors.confirm = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .acceptInvitation({
        variables: {
          password1,
          password2,
          token: this.state.token,
        },
      })
      .then(({ data }) => {
        const token = data.acceptInvite.authenticationToken;
        this.setUserToken(token);

        this.setState({
          showIntroduction: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setUserToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  redirectToLogin = () => {
    window.location.href = "/signin";
  };

  render() {
    const { isAgreed } = this.state;

    if (this.state.showIntroduction === true) {
      return (
        <Redirect
          to={{ pathname: "/dashboard", state: { showIntroduction: true } }}
        />
      );
    }

    return (
      <div className="login">
        <div className="login__banner">
          <div className="login__banner-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="login__form">
          <div className="login__form-container">
            <h2 className="login__form-title">Signup</h2>

            <p className="login__form-create mb-5">
              Already have an account?{" "}
              <span className="create" onClick={this.redirectToLogin}>
                Log in
              </span>
            </p>

            <Form onSubmit={this.showConfirmation}>
              <FormGroup className="mb-4">
                <Input
                  type="password"
                  placeholder="Create a password"
                  className="mb-0"
                  invalid={this.state.formErrors.password1}
                  name="password1"
                />
                <FormFeedback>Please provide a password</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  type="password"
                  placeholder="Confirm password"
                  className="mb-0"
                  invalid={
                    this.state.formErrors.password2 ||
                    this.state.formErrors.confirm
                  }
                  name="password2"
                />
                <FormFeedback>
                  {this.state.formErrors.password2
                    ? "Please confirm your password"
                    : this.state.formErrors.confirm
                    ? "It doesn't match your password!"
                    : ""}
                </FormFeedback>
              </FormGroup>

              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-baseline login__form-check-label">
                  <span
                    className={`${
                      isAgreed ? "-is-active" : ""
                    } login__form-check mr-2`}
                    onClick={() =>
                      this.setState({ isAgreed: !this.state.isAgreed })
                    }
                  />
                  <p>
                    I agree to the LegalSite{" "}
                    <span className="login__form-link">Terms & Conditions</span>{" "}
                    and{" "}
                    <span className="login__form-link">Privacy Policy.</span>
                  </p>
                </div>
                <Button
                  disabled={!this.state.isAgreed}
                  className="btn--primary login__form-log"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(ACCEPT_INVITATION, {
  name: "acceptInvitation",
})(Invite);
