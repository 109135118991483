import gql from "graphql-tag";

export const COMPLETE_ONBOARDING = gql`
  mutation($clientMutationId: String) {
    completedOnboarding(input: { clientMutationId: $clientMutationId }) {
      user {
        id
        onboarded
      }
    }
  }
`;
