import gql from "graphql-tag";

export const COMPLIANCE_QUERY = gql`
  {
    account {
      id
      needToUpgrade
    }
    privacyImpactAssessment {
      id
      lastUpdated
      status
    }
    dataProtectionOfficer {
      id
      lastUpdated
      status
    }
  }
`;
