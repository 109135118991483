import PropTypes from "prop-types";
import React from "react";

const Bell = (props) => (
  <svg
    height={24}
    viewBox="0 0 20 24"
    width={20}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="1.1--basic-settings---account"
        transform="translate(-181.000000, -1730.000000)"
        fill="#7450C8"
        fillRule="nonzero"
      >
        <g id="Notifications" transform="translate(149.000000, 1696.000000)">
          <g id="bell" transform="translate(32.000000, 34.000000)">
            <path
              d="M16.8,13.416 L16.8,9.6 C16.7964424,6.08937514 14.2615249,3.09310264 10.8,2.508 L10.8,1.2 C10.8,0.5372583 10.2627417,0 9.6,0 C8.9372583,0 8.4,0.5372583 8.4,1.2 L8.4,2.508 C4.93847513,3.09310264 2.40355761,6.08937514 2.4,9.6 L2.4,13.416 C0.965125563,13.923298 0.00427723885,15.2780941 0,16.8 L0,19.2 C0,19.8627417 0.5372583,20.4 1.2,20.4 L4.968,20.4 C5.53607738,22.4903524 7.43383169,23.9412006 9.6,23.9412006 C11.7661683,23.9412006 13.6639226,22.4903524 14.232,20.4 L18,20.4 C18.6627417,20.4 19.2,19.8627417 19.2,19.2 L19.2,16.8 C19.1957228,15.2780941 18.2348744,13.923298 16.8,13.416 Z M4.8,9.6 C4.8,6.9490332 6.9490332,4.8 9.6,4.8 C12.2509668,4.8 14.4,6.9490332 14.4,9.6 L14.4,13.2 L4.8,13.2 L4.8,9.6 Z M9.6,21.6 C8.74773346,21.5948557 7.96212551,21.1381069 7.536,20.4 L11.664,20.4 C11.2378745,21.1381069 10.4522665,21.5948557 9.6,21.6 Z M16.8,18 L2.4,18 L2.4,16.8 C2.4,16.1372583 2.9372583,15.6 3.6,15.6 L15.6,15.6 C16.2627417,15.6 16.8,16.1372583 16.8,16.8 L16.8,18 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Bell.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Bell;
