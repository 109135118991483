import gql from "graphql-tag";

export const DATA_PROCESSORS_QUERY = gql`
  query(
    $dataProcessorsCategory: DataProcessorCategoryFilterEnum!
    $companyName: String!
  ) {
    searchPublicDataProcessor(companyName: $companyName) {
      id
      logo
      companyName
      description
      profile {
        id
        companyName
        logo
        photo1
        photo2
        smallDescription
        text1
        text2
        title1
        title2
      }
    }
    allPublicDataProcessors {
      companyName
      description
      id
      logo
      profile {
        id
        companyName
        logo
        photo1
        photo2
        smallDescription
        text1
        text2
        title1
        title2
      }
    }
    publicDataProcessors(category: $dataProcessorsCategory) {
      id
      logo
      companyName
      description
      profile {
        id
        companyName
        logo
        photo1
        photo2
        smallDescription
        text1
        text2
        title1
        title2
      }
    }
    searchCurrentDataProcessors(companyName: $companyName) {
      id
      companyName
      email
      dpaFile
      dataProcessor {
        id
        logo
        description
        email
        companyName
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
    account {
      id
      needToUpgrade
      users {
        id
      }
    }
    viewer {
      id
      appAdmin
    }
    currentDataProcessors(category: $dataProcessorsCategory) {
      id
      companyName
      email
      dpaFile
      dataProcessor {
        id
        logo
        description
        email
        companyName
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
  }
`;
