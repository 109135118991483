import gql from "graphql-tag";

export const PUBLISH_DP_PROFILE = gql`
  mutation(
    $logo: String!
    $clientMutationId: String
    $smallDescription: String!
    $companyName: String!
    $photo1: String!
    $title1: String!
    $text1: String!
    $photo2: String!
    $title2: String!
    $text2: String!) {
    publishProfileDataProcessor(
      input: {
        logo: $logo
        clientMutationId: $clientMutationId
        smallDescription: $smallDescription
        companyName: $companyName
        photo1: $photo1
        title1: $title1
        text1: $text1
        photo2: $photo2
        title2: $title2
        text2: $text2
      }
    ) {
      errors
    }
  }
`;
