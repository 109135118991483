import PropTypes from "prop-types";
import React from "react";

const ArrowCircleRight = (props) => (
  <svg
    height={20}
    viewBox="0 0 20 20"
    width={20}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="1.9--settings---consent-management"
        transform="translate(-566.000000, -808.000000)"
        fill={props.color}
        fillRule="nonzero"
      >
        <g id="Group-4" transform="translate(181.000000, 790.000000)">
          <g id="Group-3">
            <g
              id="arrow-circle-down"
              transform="translate(395.000000, 28.000000) rotate(-90.000000) translate(-395.000000, -28.000000) translate(385.000000, 18.000000)"
            >
              <path
                d="M9.29,13.71 C9.38510371,13.8010406 9.49724868,13.8724056 9.62,13.92 C9.86346136,14.020018 10.1365386,14.020018 10.38,13.92 C10.5027513,13.8724056 10.6148963,13.8010406 10.71,13.71 L13.71,10.71 C14.1021222,10.3178778 14.1021222,9.68212217 13.71,9.29 C13.3178778,8.89787783 12.6821222,8.89787783 12.29,9.29 L11,10.59 L11,7 C11,6.44771525 10.5522847,6 10,6 C9.44771525,6 9,6.44771525 9,7 L9,10.59 L7.71,9.29 C7.5222334,9.10068735 7.26663754,8.99420168 7,8.99420168 C6.73336246,8.99420168 6.4777666,9.10068735 6.29,9.29 C6.10068735,9.4777666 5.99420168,9.73336246 5.99420168,10 C5.99420168,10.2666375 6.10068735,10.5222334 6.29,10.71 L9.29,13.71 Z M10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,8.8817842e-16 10,1.77635684e-15 C4.4771525,2.66453526e-15 0,4.4771525 -4.4408921e-16,10 C-2.22044605e-16,12.6521649 1.0535684,15.195704 2.92893219,17.0710678 C4.80429597,18.9464316 7.3478351,20 10,20 Z M10,2 C14.418278,2 18,5.581722 18,10 C18,14.418278 14.418278,18 10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowCircleRight.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default ArrowCircleRight;
