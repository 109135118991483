import React, { Component } from "react";
import Image from "../../images/Users/Document.svg";
import ReactFilestack from "filestack-react";
import MdClose from "react-icons/lib/md/close";
import { Button } from "reactstrap";
import ViewIcon from "../../components/Icons/ViewIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { ADD_DPA_TO_INTEGRATION } from "../../graphql/addDpaToIntegration";
import axios from "axios";
import { graphql } from "@apollo/client/react/hoc";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

class DPA extends Component {
  static propTypes = {
    addDPAToIntegration: PropTypes.func.isRequired,
    integration: PropTypes.object,
    refetchData: PropTypes.func,
    isCompliance: PropTypes.bool,
    showUpload: PropTypes.func,
  };
  state = {
    files: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });

    const dpa = this.state.files.length ? this.state.files[0].url : "";

    this.props
      .addDPAToIntegration({
        variables: {
          id: this.props.integration.id,
          dpa,
        },
      })
      .then(() => {
        this.notify("DPA uploaded successfully!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("DPA couldn't be uploaded");
      });
  };

  removeFile = () => {
    this.props
      .addDPAToIntegration({
        variables: {
          id: this.props.integration.id,
          dpa: "",
        },
      })
      .then(() => {
        this.notify("DPA removed!");
        this.props.refetchData();
      })
      .catch((error) => {
        console.log(error);
        this.notify("DPA couldn't be removed");
      });
  };

  download = (url) => {
    console.log(url);

    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${this.props.integration ? this.props.integration.id : "DP"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      this.notify(this.showDownloadNotifyText());
    });
  };

  notify = (text) => toast(text);

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  render() {
    const { files } = this.state;
    const { integration } = this.props;

    return (
      <div
        className={`data-processor__company-details ${
          this.props.isCompliance ? "border-0" : ""
        }`}
      >
        <h4 className="data-processor__company-details-title mb-4">
          Data Processing Agreement
        </h4>

        {!this.state.files.length && !integration.dpaFile ? (
          <img className="mt-2 mb-4" src={Image} alt="api" />
        ) : null}

        {!this.state.files.length && !integration.dpaFile ? (
          <p className="data-processor__company-details-text-secondary">
            Upload this Data Processor’s signed Data Processing Agreement.
          </p>
        ) : null}

        {files.length || (files.length && !integration) ? (
          <div className="d-flex">
            <div className="data-processor__file mb-4">
              <div className="data-processor__file-type">DOC</div>
              <p className="data-processor__file-name">{files[0].filename}</p>
              <MdClose
                onClick={() => this.setState({ files: [] })}
                className="data-processor__file-remove"
                size={20}
                color={"#969696"}
              />
            </div>
            {!this.props.isCompliance ? (
              <div className="d-flex align-items-center mb-4">
                <a
                  href={files[0].url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <ViewIcon color={"#7450c8"} />
                </a>
                <DownloadIcon
                  className="-has-pointer"
                  onClick={() => this.download(files[0].url)}
                  color={"#7450c8"}
                />
              </div>
            ) : null}
          </div>
        ) : !files.length && integration && integration.dpaFile ? (
          <div className="d-flex">
            <div className="data-processor__file mb-4">
              <div className="data-processor__file-type">DOC</div>
              <p className="data-processor__file-name">{integration.dpaFile}</p>
              <MdClose
                onClick={this.removeFile}
                className="data-processor__file-remove"
                size={20}
                color={"#969696"}
              />
            </div>
            {!this.props.isCompliance ? (
              <div className="d-flex align-items-center mb-4">
                <a
                  href={integration.dpaFile}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-3"
                >
                  <ViewIcon color={"#7450c8"} />
                </a>
                <DownloadIcon
                  className="-has-pointer"
                  onClick={() => this.download(integration.dpaFile)}
                  color={"#7450c8"}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <div className={`${this.props.isCompliance ? "d-flex" : ""}`}>
          <div>
            {this.props.isCompliance ? (
              <Button
                className="btn--secondary mr-3"
                onClick={this.props.showUpload}
              >
                Cancel
              </Button>
            ) : null}
            <ReactFilestack
              apikey={"AJNM9qOpGRljTn17sgxrfz"}
              componentDisplayMode={{
                type: "link",
                customText: (
                  <Button className="btn--primary">Upload DPA</Button>
                ),
                customClass: "show-upload",
              }}
              onSuccess={this.onFiles}
              actionOptions={{
                fromSources: [
                  "local_file_system",
                  "googledrive",
                  "dropbox",
                  "onedrive",
                ],
                accept: [
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ],
                maxFiles: 1,
              }}
            />
          </div>

          {this.props.isCompliance &&
          (this.state.files.length || integration.dpaFile) ? (
            <Button
              className="btn--secondary ml-3"
              onClick={this.props.showUpload}
            >
              Finish
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

export default graphql(ADD_DPA_TO_INTEGRATION, {
  name: "addDPAToIntegration",
})(DPA);
