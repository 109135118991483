import React, { Fragment } from "react";
import CustomiseColor from "./CustomiseColor";
import CustomiseText from "./CustomiseText";
import { Input } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import PropTypes from "prop-types";

function CustomizeHeader(props) {
  const {
    capitalize,
    titleSiteColor,
    titleSiteSize,
    siteTitleFont,
    titleSiteColor2,
    titleSiteSize2,
    siteTitleFont2,
    changeBgColor,
    bg,
    changeTextColor,
    getGoogleFont,
    changeFontSize,
  } = props;

  return (
    <Fragment>
      <div>
        <CustomiseText
          title="Title Text"
          color={titleSiteColor ? titleSiteColor : "#333333"}
          size={titleSiteSize ? titleSiteSize : 40}
          isLegalsite={true}
          font={siteTitleFont ? capitalize(siteTitleFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="headerTitleColor"
          fontSizeName="headerTitleSize"
          fontFamilyName="headerTitleFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="headerTitleAlign"
          boldName="headerTitleBold"
          italicName="headerTitleItalic"
          boldValue={props.headerTitleBold}
          italicValue={props.headerTitleItalic}
          alignValue={props.headerTitleAlign}
          index={33}
        />
      </div>

      <div>
        <CustomiseText
          title="Body Text"
          color={titleSiteColor2 ? titleSiteColor2 : "#969696"}
          size={titleSiteSize2 ? titleSiteSize2 : 14}
          isLegalsite={true}
          font={siteTitleFont2 ? capitalize(siteTitleFont2) : "Roboto"}
          changeColor={changeTextColor}
          colorName="headerBodyColor"
          fontSizeName="headerBodySize"
          fontFamilyName="headerBodyFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={34}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="headerBodyAlign"
          boldName="headerBodyBold"
          italicName="headerBodyItalic"
          boldValue={props.headerBodyBold}
          italicValue={props.headerBodyItalic}
          alignValue={props.headerBodyAlign}
        />
      </div>

      <div className="customize-menu__logo-container pb-4">
        <p className="customise-text__label">Background color</p>
        <CustomiseColor
          isLegalsite={true}
          changeColor={changeBgColor}
          name="headerBg"
          color={bg ? bg : "#DFE4EE"}
          index={11}
        />
      </div>

      <div className="d-flex justify-content-between customize-menu__logo-container pb-4 mt-4">
        <div className="w-100">
          <p className="customise-text__label">Title Home page</p>
          <Input
            type="text"
            defaultValue={props.homepageTitle}
            onChange={(e) => props.changeText("homepageTitle", e.target.value)}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between customize-menu__logo-container pb-4 mt-4">
        <div className="w-100">
          <p className="customise-text__label">Description Home page</p>
          <TextareaAutosize
            className="w-100 p-2 customise-text__description"
            defaultValue={props.homepageDescription}
            onChange={(e) =>
              props.changeText("homepageDescription", e.target.value)
            }
          />
        </div>
      </div>
    </Fragment>
  );
}

export default CustomizeHeader;

CustomizeHeader.propTypes = {
  capitalize: PropTypes.func.isRequired,
  titleSiteColor: PropTypes.string,
  titleSiteSize: PropTypes.number,
  siteTitleFont: PropTypes.string,
  titleSiteColor2: PropTypes.string,
  titleSiteSize2: PropTypes.number,
  siteTitleFont2: PropTypes.string,
  changeBgColor: PropTypes.func.isRequired,
  bg: PropTypes.string,
  changeTextColor: PropTypes.func.isRequired,
  getGoogleFont: PropTypes.func.isRequired,
  changeFontSize: PropTypes.func.isRequired,
  homepageTitle: PropTypes.string,
  homepageDescription: PropTypes.string,
  changeText: PropTypes.func.isRequired,
  getAligned: PropTypes.func.isRequired,
  getBold: PropTypes.func.isRequired,
  getItalic: PropTypes.func.isRequired,
  headerTitleBold: PropTypes.bool,
  headerTitleItalic: PropTypes.bool,
  headerTitleAlign: PropTypes.string,
  headerBodyBold: PropTypes.bool,
  headerBodyItalic: PropTypes.bool,
  headerBodyAlign: PropTypes.string,
};
