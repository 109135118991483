import gql from "graphql-tag";

export const NAVIGATION_BAR_QUERY = gql`
  {
    notifications {
      category
      id
      receivedDate
      request {
        id
      }
    }
    account {
      id
      subscription {
        planName
        id
      }
      email
    }
  }
`;
