import React from "react";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import DataIcon from "../Icons/DataIcon";
import Book from "../Icons/Book";
import Moment from "react-moment";
import NotificationsIcon from "./../Icons/NotificationsIcon";
import Image from "../../images/Navbar/Illustration 3.svg";

import PropTypes from "prop-types";

function NotificationsMenu(props) {
  const { viewer, datas, viewNotifications, redirectToPath } = props;

  return (
    <UncontrolledDropdown nav inNavbar>
      <DropdownToggle
        nav
        className="navigationBar__notifications position-relative"
        onClick={viewNotifications}
      >
        <NotificationsIcon />
        {viewer && viewer.newNotifications ? (
          <span className="navigationBar__notifications-alert" />
        ) : null}
      </DropdownToggle>
      <DropdownMenu right className="navigationBar__notifications-dropdown">
        <DropdownItem header className="d-flex px-2 navigationBar__header">
          Notifications
        </DropdownItem>
        <DropdownItem divider />

        <div className={!datas ? "d-none" : "navigationBar__content-menu"}>
          {datas
            ? datas
                .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                .map((alert, index) => (
                  <DropdownItem
                    key={index}
                    className="navigationBar__dropdown-item d-flex -notifications"
                  >
                    <Link
                      className="d-flex"
                      to={
                        alert.category !== "DATA_ACCESS" &&
                        alert.category !== "QUESTION" &&
                        alert.category !== "DATA_ERASURE" &&
                        alert.category !== "COMPLAINT" &&
                        alert.category !== "NEW_ASSIGNED_REQUEST" &&
                        alert.category !== "DATA_TRANSFER" &&
                        alert.category !== "DONT_SELL_DATA"
                          ? { pathname: "/pages" }
                          : {
                              pathname: "/inbox",
                              // state: { id: alert.request.id },
                            }
                      }
                    >
                      {alert.category === "DATA_ACCESS" ||
                      alert.category === "QUESTION" ||
                      alert.category === "DATA_TRANSFER" ||
                      alert.category === "DATA_ERASURE" ||
                      alert.category === "NEW_ASSIGNED_REQUEST" ||
                      alert.category === "DONT_SELL_DATA" ||
                      alert.category === "COMPLAINT" ? (
                        <DataIcon className="navigationBar__dropdown-icon" />
                      ) : (
                        <Book className="navigationBar__dropdown-icon" />
                      )}
                      <div className="d-flex flex-column">
                        <span className="navigationBar__notification">
                          {alert.category === "DATA_ACCESS"
                            ? "New user request - data access"
                            : alert.category === "NEW_ASSIGNED_REQUEST"
                            ? "New assigned request"
                            : alert.category === "QUESTION"
                            ? "New user request - question"
                            : alert.category === "DATA_TRANSFER"
                            ? "New user request - data transfer"
                            : alert.category === "DONT_SELL_DATA"
                            ? "New user request - do not sell data"
                            : alert.category === "DATA_ERASURE"
                            ? "New user request - data erasure"
                            : alert.category === "COMPLAINT"
                            ? "New user request - complaint"
                            : alert.category ===
                              "REVIEW_NEW_TEMPLATE_PUBLICATION"
                            ? `We revised ${
                                alert && alert.templatePublication
                                  ? alert.templatePublication.title
                                  : ""
                              }`
                            : `We changed ${
                                alert && alert.pageTemplate
                                  ? alert.pageTemplate.title
                                  : ""
                              } page details`}
                        </span>

                        <span className="navigationBar__time-ago">
                          <Moment fromNow>
                            {alert.receivedAt
                              ? alert.receivedAt
                              : alert.receivedDate}
                          </Moment>
                        </span>
                      </div>
                    </Link>
                  </DropdownItem>
                ))
            : null}
        </div>

        {datas && datas.length > 0 ? (
          <DropdownItem
            className="navigationBar__dropdown-item -see-all"
            onClick={() => redirectToPath("/notifications")}
          >
            See all notifications
          </DropdownItem>
        ) : (
          <div className="navigationBar__dropdown-item d-flex -no-notifications">
            <img src={Image} alt="no notification" />
            <span>You don't have any notifications yet</span>
          </div>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default NotificationsMenu;
NotificationsMenu.propTypes = {
  viewer: PropTypes.object,
  datas: PropTypes.array,
  viewNotifications: PropTypes.func,
  redirectToPath: PropTypes.func,
};
