import gql from "graphql-tag";

export const USERS_QUERY = gql`
  query($filter: DataEntityFilterEnum!, $pageNumber: Int!, $name: String!) {
    searchDataEntity(name: $name) {
      company
      addedAt
      email
      id
      name
    }
    dataEntities(filter: $filter, pageNumber: $pageNumber) {
      numberOfPages
      dataEntities {
        ... on DataSubject {
          id
          UID
        }
        company
        addedAt
        email
        id
        name
      }
    }
    account {
      id
      needToUpgrade
      users {
        id
      }
    }
    usersStats {
      dataControllers
      dataSubjects
      haveDpa
      totalUsers
    }
  }
`;
