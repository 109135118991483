import PropTypes from "prop-types";
import React, { Component } from "react";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import ViewIcon from "../../components/Icons/ViewIcon";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import { graphql } from "@apollo/client/react/hoc";
import ReactTable from "react-table-6";
import Moment from "react-moment";
import "react-table-6/react-table.css";
import { Link } from "react-router-dom";

class DPADPTable extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool.isRequired,
    showUser: PropTypes.func,
    user: PropTypes.object,
    checkItem: PropTypes.func,
    download: PropTypes.func,
    userName: PropTypes.array,
    userId: PropTypes.array,
    generateReport: PropTypes.func,
    users: PropTypes.array,
  };

  state = {
    isChecked: false,
    report: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === false
    ) {
      this.setState({
        isChecked: false,
      });
    }

    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === true
    ) {
      this.setState({
        isChecked: true,
      });
    }
  }

  handleCheck = (user) => {
    if (
      this.props.userName.includes(user.companyName) &&
      this.props.userId.includes(user.dataProcessor.id)
    ) {
      return (
        <CheckActive
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.dataProcessor.id,
              user.downloadUrl,
              user.companyName,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    } else {
      return (
        <CheckDisabled
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.dataProcessor.id,
              user.downloadUrl,
              user.companyName,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    }
  };

  generateReport = (id, type, name) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType:
            type === "DataSubject"
              ? "DATA_SUBJECT"
              : type === "DataController"
              ? "DATA_CONTROLLER"
              : "USER",
        },
      })
      .then(({ data }) => {
        this.props.download(data.generateReport.downloadUrl, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { users } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span className="rt-check">{this.handleCheck(props.value)}</span>
        ),
      },
      {
        id: "dataProcessor",
        Header: "Company",
        accessor: (d) => d.dataProcessor.companyName,
      },
      {
        id: "email",
        Header: "Email",
        accessor: (d) => d.dataProcessor.email,
      },
      // {
      //   id: 'Telephone',
      //   Header: 'Telephone',
      //   accessor: d => d.dataProcessor.phoneNumber,
      // },
      {
        Header: "Date added",
        accessor: "dateAdded",
        Cell: (props) => <Moment format="DD/MM/YYYY">{props.value}</Moment>,
      },
      {
        Header: "DPA signed",
        accessor: "dpaStatus",
        Cell: (props) => <span>{props.value === "YES" ? "Yes" : "No"}</span>,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => (
          <Link
            className="users__view"
            id="view-dp"
            to={{ pathname: "/data-processors", state: { dp: props.value } }}
          >
            <ViewIcon className="mr-2" color={"#7450c8"} /> View
          </Link>
        ),
      },
    ];

    return (
      <ReactTable
        data={users}
        columns={columns}
        resizable={false}
        minRows={0}
        showPagination={users && users.length > 20 ? true : false}
        noDataText="No Data Processors"
      />
    );
  }
}

export default graphql(GENERATE_REPORT, {
  name: "generateReport",
})(DPADPTable);
