import React, { Fragment } from "react";
import { Button, Input } from "reactstrap";
import Site from "../../components/Icons/Site";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import FaCaret from "react-icons/lib/fa/angle-down";
import MdClose from "react-icons/lib/md/close";
import Tip from "./Tip";
import PropTypes from "prop-types";
import "./sample.scss";

function SampleCookie(props) {
  return (
    <Fragment>
      <div className="users__banner">
        <p className="users__banner-title">Sample data</p>
        <div className="users__banner-exit" onClick={props.closeSample}>
          <span>Exit</span>
          <MdClose className="ml-2" size={20} />
        </div>
      </div>
      <div className="customization -is-sample">
        <div className="customization__navigation">
          <h4 className="customization__navigation-title">
            <Site color="#333" className="mr-2" /> Customization
          </h4>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">LegalSite</p>
              <p className="settings__captcha-item-text">
                Customize your LegalSite
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="-is-active settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">Agreement Pop-up</p>
              <p className="settings__captcha-item-text">
                Change your Pop-up style.
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">E-mails</p>
              <p className="settings__captcha-item-text">
                Customize your e-mails
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>
        </div>

        <div className="customization__content sample-customization">
          <Fragment>
            <div className="justify-content-between d-flex w-100">
              <div className="customization-cookie__container -is-sample">
                <h3 className="customization-cookie__title mb-4">
                  3. Customize
                </h3>
                <Tip />
                <div>
                  <div className="squared popup customization-cookie__small">
                    <p className="customization-cookie__popup-title">
                      Cookies preferences
                    </p>

                    <p className="customization-cookie__popup-text">
                      This website uses cookies. Click on the button bellow to
                      consent to all cookies in accordance with our Privacy
                      Policy or customize your preferences at any time on our
                      legal page.'
                    </p>

                    <div className="d-flex flex-wrap">
                      <Button
                        className="customization-cookie__popup-accept"
                        style={{ backgroundColor: "#7450C8" }}
                      >
                        ACCEPT
                      </Button>
                      <Button
                        className="customization-cookie__popup-options"
                        style={{
                          color: "#7450C8",
                        }}
                      >
                        OPTIONS
                      </Button>
                    </div>

                    <span className="customization-cookie__get">
                      Get your LegalSite
                    </span>
                  </div>
                </div>

                <div className="d-flex customization-cookie__check-container">
                  <span className="customization-cookie__label">
                    Button's shape:
                  </span>
                  <span className="customization-cookie__check-item d-flex align-items-center">
                    <span className="-is-active check mr-2" /> Squared
                  </span>
                  <span className="customization-cookie__check-item d-flex align-items-center">
                    <span className="check mr-2" /> Rounded
                  </span>
                </div>

                <div className="d-flex customization-cookie__check-container">
                  <span className="customization-cookie__label">
                    Popup side:
                  </span>
                  <span className="customization-cookie__check-item d-flex align-items-center">
                    <span className="check mr-2" /> Right
                  </span>
                  <span className="customization-cookie__check-item d-flex align-items-center">
                    <span className="-is-active check mr-2" /> Left
                  </span>
                </div>

                <div>
                  <span className="customization-cookie__label">
                    Title text:
                  </span>
                  <Input
                    className="customization-cookie__input"
                    type="text"
                    value={"Cookies preferences"}
                    onChange={() => {}}
                  />
                </div>

                <div>
                  <span className="customization-cookie__label">
                    Body text:
                  </span>
                  <Input
                    className="customization-cookie__textarea"
                    type="textarea"
                    onChange={() => {}}
                    value={
                      "This website uses cookies. Click on the button bellow to consent to all cookies in accordance with our Privacy Policy or customize your preferences at any time on our legal page."
                    }
                  />
                </div>

                <div className="d-flex flex-wrap">
                  <div className="mr-5">
                    <span className="customization-cookie__label">
                      Text main button:
                    </span>
                    <Input
                      className="customization-cookie__input"
                      type="text"
                      value={"ACCEPT"}
                      onChange={() => {}}
                    />
                  </div>
                  <div>
                    <span className="customization-cookie__label">
                      Text secondary button:
                    </span>
                    <Input
                      className="customization-cookie__input"
                      type="text"
                      value={"OPTIONS"}
                      onChange={() => {}}
                    />
                  </div>
                </div>

                <div>
                  <span className="customization-cookie__label">
                    Destination page when user clicks ‘Accept’:
                  </span>
                  <Input
                    className="customization-cookie__input-large"
                    type="text"
                    placeholder="Paste the URL"
                    onChange={() => {}}
                  />
                </div>

                <div className="d-flex flex-column customization-cookie__check-container">
                  <span className="customization-cookie__label w-100">
                    Catch your user’s e-mail on the pop-up?
                  </span>
                  <div className="d-flex mt-2">
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"-is-active check mr-2 ml-0"} /> Yes
                    </span>
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"check mr-2"} /> No
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-column customization-cookie__check-container">
                  <span className="customization-cookie__label w-100">
                    Catch your user’s signature on the pop-up?
                  </span>
                  <div className="d-flex mt-2">
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"-is-active check mr-2 ml-0"} /> Yes
                    </span>
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"check mr-2"} /> No
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-column customization-cookie__check-container">
                  <span className="customization-cookie__label w-100">
                    Verify user when accepting?
                  </span>
                  <span className="customization-cookie__label-secondary w-100">
                    The verification step would be an e-mail sent to the user
                    with a link where he can click to give consent.
                  </span>
                  <div className="d-flex mt-2">
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"-is-active check mr-2 ml-0"} /> Yes
                    </span>
                    <span className="customization-cookie__check-item d-flex align-items-center">
                      <span className={"check mr-2"} /> No
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="customization-cookie__right-menu -is-sample">
              <Button className="customization-cookie__publish btn--primary mb-2">
                Publish Pop-up
              </Button>
              <Button className="customization-cookie__preview btn--secondary mb-2">
                Preview
              </Button>
              <Button className="customization-cookie__preview btn--gray">
                Back to templates
              </Button>

              <div className="customization-cookie__right-menu-content">
                <div className="d-flex mr-auto flex-column">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <p className="customization-cookie__right-menu-label">
                        Background
                      </p>
                      <div className="d-flex sample-scheme mb-0">
                        <span className="sample-scheme__color -white" />
                      </div>
                    </div>

                    <div className="d-flex flex-column">
                      <p className="customization-cookie__right-menu-label">
                        Main button
                      </p>
                      <div className="d-flex sample-scheme mb-0">
                        <span className="sample-scheme__color -purple" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Title</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -black" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Body</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -gray" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Main button text</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -white" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>

                  <div className="sample-text d-flex customise-text">
                    <p className="sample-text__label">Text button and links</p>

                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <span className="sample-text__color -purple" />
                        <div className="sample-text__size">
                          40
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>

                      <div className="sample-text__font">
                        Font
                        <FaCaret className="settings__input-icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

export default SampleCookie;
SampleCookie.propTypes = {
  closeSample: PropTypes.func.isRequired,
};
