import PropTypes from "prop-types";
import React from "react";

const HelpIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Expanded-side-navigation-bar" transform="translate(-29.000000, -772.000000)" fill={props.color} fillRule="nonzero">
                <g id="question-circle" transform="translate(29.000000, 772.000000)">
                    <path d="M9.29,13.29 C9.24699475,13.3375199 9.20692071,13.3876125 9.17,13.44 C9.13215359,13.4957452 9.10188832,13.5562757 9.08,13.62 C9.05116778,13.6766856 9.03094561,13.7373522 9.02,13.8 C9.0150914,13.8665763 9.0150914,13.9334237 9.02,14 C9.01661989,14.1311765 9.04401865,14.2613206 9.1,14.38 C9.1909238,14.6312303 9.38876974,14.8290762 9.64,14.92 C9.88203988,15.0269768 10.1579601,15.0269768 10.4,14.92 C10.6512303,14.8290762 10.8490762,14.6312303 10.94,14.38 C10.984406,14.2584105 11.004783,14.1293561 11,14 C11.0015368,13.734197 10.8971865,13.4787188 10.71,13.29 C10.5222334,13.1006873 10.2666375,12.9942017 10,12.9942017 C9.73336246,12.9942017 9.4777666,13.1006873 9.29,13.29 Z M10,-4.4408921e-16 C4.4771525,-4.4408921e-16 1.77635684e-15,4.4771525 1.77635684e-15,10 C1.77635684e-15,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,-4.4408921e-16 10,-4.4408921e-16 Z M10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,12.1217319 17.1571453,14.1565632 15.6568542,15.6568542 C14.1565632,17.1571453 12.1217319,18 10,18 Z M10,4.99999938 C8.92751599,4.99931238 7.93624189,5.57120128 7.4,6.49999938 C7.20294063,6.80998907 7.19169228,7.20306198 7.37070212,7.52381282 C7.54971195,7.84456366 7.89016149,8.04135529 8.25745008,8.03638389 C8.62473866,8.03141249 8.95973714,7.82547839 9.13,7.49999938 C9.30934097,7.18936581 9.64131524,6.99857599 10,6.99999938 C10.5522847,6.99999938 11,7.44771525 11,7.99999938 C11,8.55228475 10.5522847,8.99999938 10,8.99999938 C9.44771525,8.99999938 9,9.44771525 9,9.99999938 L9,10.9999994 C9,11.5522847 9.44771525,11.9999994 10,11.9999994 C10.5522847,11.9999994 11,11.5522847 11,10.9999994 L11,10.82 C12.3649694,10.3247186 13.1792883,8.92296796 12.9333986,7.49189027 C12.687509,6.06081258 11.4520049,5.01126525 10,4.99999938 L10,4.99999938 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
);

HelpIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default HelpIcon;