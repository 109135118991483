import React, { Component } from "react";
import { Button, Alert } from "reactstrap";
import SortIcon from "../../components/Icons/SortIcon";
import DataProcessorsItem from "./DataProcessorItem";
import FaCaret from "react-icons/lib/fa/angle-down";
import FaCaretUp from "react-icons/lib/fa/angle-up";
import "./data-processors.scss";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { DATA_PROCESSORS_QUERY } from "../../graphql/dataProcessorsQuery";
import { REMOVE_DATA_PROCESSOR_ADMIN } from "../../graphql/removeDataProcessorAdmin";
import SyncLoader from "react-spinners/SyncLoader";
import SearchInput from "../../components/SearchInput/SearchInput";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import AddDataProcessor from "./AddDataProcessor";
import { ToastContainer, toast } from "react-toastify";
import { getMobileDevice } from "../../utils/helpers";
import DataProcessor from "./DataProcessor";
import Filter from "./Filter";
import { Redirect } from "react-router-dom";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import AddAdminDP from "./AddAdminDP";
import DeleteDP from "../../components/Modals/DeleteDP";
import DpProfile from "./DpProfile";
import PropTypes from "prop-types";

const filterOptions = [
  "See all",
  "Strategy & Organization",
  "Business Operations",
  "Marketing",
  "Sales",
  "Human Resources",
  "Finance",
  "Legal",
  "Facilities",
  "Government / Authority",
  "Other",
];

class DataProcessors extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    removeDP: PropTypes.func,
    location: PropTypes.object,
  };

  state = {
    activeFilter: "See all",
    isRighMenuOpen: false,
    isAddingDataProcessor: false,
    showFiler: false,
    isMobileDevice: false,
    filteredCurrentDataProcessors: null,
    filteredPublicDataProcessors: null,
    currentDPVisible: false,
    dp: null,
    isCurrent: false,
    query: "",
    hasSearch: false,
    isAddingAdminDataProcessor: false,
    isDeleteModalVisible: false,
    dpId: null,
    dpData: null,
    isEditDP: false,
  };

  componentDidMount() {
    const isMobileDevice = getMobileDevice();

    this.setState({
      isMobileDevice,
    });

    if (this.props.location.state && this.props.location.state.isAdmin) {
      this.setState({
        isAddingAdminDataProcessor: true,
      });
    }

    if (this.props.location.state && this.props.location.state.dp) {
      this.showDP(this.props.location.state.dp, true);
    }
  }

  notify = (text) => toast(text);

  getInitialPage = () => {
    this.setState({
      currentDPVisible: false,
      dp: null,
      isCurrent: false,
    });
  };

  addDataProcessor = () => {
    this.setState({
      isAddingDataProcessor: !this.state.isAddingDataProcessor,
    });
  };

  addAdminDataProcessor = () => {
    this.setState({
      isAddingAdminDataProcessor: !this.state.isAddingAdminDataProcessor,
    });
  };

  searchDataProcessor = (dataProcessor) => {
    this.props.data.refetch({ companyName: dataProcessor });

    if (dataProcessor === "") {
      this.setState({
        hasSearch: false,
      });
    } else {
      this.setState({
        hasSearch: true,
      });
    }
  };

  showDP = (dp, isCurrent) => {
    this.setState({
      currentDPVisible: !this.state.currentDPVisible,
      dp,
      isCurrent,
      hasSearch: false,
    });

    this.props.data.refetch({ companyName: "" });
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  filterDesktop = (item) => {
    const filterEnum =
      item === "See all"
        ? "ALL"
        : item === "Strategy & Organization"
        ? "STRATEGY_AND_ORGANIZATION"
        : item === "Business Operations"
        ? "BUSINESS_OPERATIONS"
        : item === "Sales"
        ? "SALES"
        : item === "Marketing"
        ? "MARKETING"
        : item === "Human Resources"
        ? "HUMAN_RESOURCES"
        : item === "Finance"
        ? "FINANCE"
        : item === "Legal"
        ? "LEGAL"
        : item === "Facilities"
        ? "FACILITIES"
        : item === "Government / Authority"
        ? "GOVERMENT_OR_AUTHORITY"
        : "OTHERS";

    this.setState(
      {
        activeFilter: item,
        filteredCurrentDataProcessors: null,
        filteredPublicDataProcessors: null,
        query: "",
      },
      () => this.props.data.refetch({ dataProcessorsCategory: filterEnum })
    );
  };

  removeAdminDP = (id) => {
    this.props
      .removeDP({
        variables: {
          id: id,
        },
      })
      .then(() => {
        this.notify("DP removed");

        this.setState({
          currentDPVisible: false,
          dp: null,
          isCurrent: false,
          hasSearch: false,
        });

        this.props.data.refetch();
      })
      .catch((error) => {
        console.log(error);
        this.notify("DP couldn't be removed");
      });
  };

  showDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  editDP = () => {
    this.setState({
      isEditDP: !this.state.isEditDP,
      currentDPVisible: false,
    });
  };

  render() {
    const {
      isAddingDataProcessor,
      showFilter,
      isMobileDevice,
      currentDPVisible,
      hasSearch,
      isAddingAdminDataProcessor,
      isEditDP,
    } = this.state;
    const {
      publicDataProcessors,
      currentDataProcessors,
      account,
      loading,
      error,
      viewer,
      searchPublicDataProcessor,
      searchCurrentDataProcessors,
      allPublicDataProcessors,
    } = this.props.data;

    let publicDP =
      searchPublicDataProcessor && hasSearch
        ? searchPublicDataProcessor
        : publicDataProcessors;
    const currentDP =
      searchCurrentDataProcessors && hasSearch
        ? searchCurrentDataProcessors
        : currentDataProcessors;

    const ids = currentDP
      ? new Set(currentDP.map((item) => item.dataProcessor.id))
      : null;

    publicDP =
      viewer && !viewer.appAdmin
        ? publicDP
          ? publicDP.filter(({ id }) => !ids.has(id))
          : null
        : allPublicDataProcessors;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Data Processors"
            showSideBarMenu={() =>
              this.setState({ isRightMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          {!isAddingDataProcessor &&
          !currentDPVisible &&
          !isEditDP &&
          !isAddingAdminDataProcessor ? (
            <div
              className={`${
                account && account.needToUpgrade ? "-has-banner" : ""
              } data-processors d-flex`}
            >
              <div className="data-processors__filter">
                <div
                  className="data-processors__filter-title"
                  onClick={() =>
                    isMobileDevice
                      ? this.setState({ showFilter: !showFilter })
                      : {}
                  }
                >
                  <SortIcon className="mr-2" /> Filter
                  {!showFilter ? (
                    <FaCaret className="-show-on-mobile" />
                  ) : (
                    <FaCaretUp className="-show-on-mobile" />
                  )}
                </div>

                <Filter
                  isMobileDevice={isMobileDevice}
                  isDP={true}
                  showFilter={showFilter}
                  filterOptions={filterOptions}
                  filterMobile={(item) => {
                    this.setState({
                      showFilter: !showFilter,
                    });
                    this.filterDesktop(item);
                  }}
                  filterDesktop={this.filterDesktop}
                  activeFilter={this.state.activeFilter}
                />
              </div>

              <div
                className={`${
                  viewer && viewer.appAdmin ? "pt-5" : ""
                } data-processors__content d-flex align-items-center flex-column`}
              >
                {viewer && !viewer.appAdmin ? (
                  <h3 className="data-processors__title">
                    A Data Processor or third-party is a company or service that
                    processes privacy data on behalf of your business. Add all
                    your Data Processors here so that you can send them privacy
                    requests automatically via LegalSite and connect your Data
                    Proccesing Agreements (DPA's).
                    <br />
                    Explore the library of preconfigured Data Processors, or
                    register a new Data Processor.
                  </h3>
                ) : null}

                <Button
                  onClick={
                    viewer && !viewer.appAdmin
                      ? () => this.addDataProcessor()
                      : () => this.addAdminDataProcessor()
                  }
                  className="btn--primary data-processors__btn"
                >
                  Add new Data Processor
                </Button>

                <SearchInput
                  placeholder={"Type company name and press enter"}
                  search={this.searchDataProcessor}
                  // value={this.state.query}
                />

                {viewer && !viewer.appAdmin ? (
                  currentDataProcessors.length ? (
                    <div className="data-processors__current">
                      <h2 className="data-processors__current-title">
                        Active Data Processors
                      </h2>

                      <div className="data-processors__current-container d-flex flex-wrap">
                        {currentDP.length ? (
                          currentDP.map((item) => (
                            <DataProcessorsItem
                              key={item.dataProcessor.id}
                              dataProcessor={item}
                              isCurrent={true}
                              showDP={() => this.showDP(item, true)}
                            />
                          ))
                        ) : (
                          <p className="mb-5">No results</p>
                        )}
                      </div>
                    </div>
                  ) : null
                ) : null}

                {publicDataProcessors ? (
                  <div
                    className={`${
                      !currentDataProcessors.length ||
                      (viewer && viewer.appAdmin)
                        ? "mt-5"
                        : ""
                    } data-processors__add`}
                  >
                    <h2 className="data-processors__current-title mt-0">
                      Available Data Processors
                    </h2>

                    <div className="data-processors__current-container d-flex flex-wrap">
                      {publicDP.length ? (
                        publicDP.map((item) => (
                          <DataProcessorsItem
                            key={item.id}
                            dataProcessor={item}
                            showDP={() =>
                              viewer && viewer.appAdmin
                                ? this.showDP(item, false)
                                : this.showDP(item, false)
                            }
                            showDeleteModal={this.showDeleteModal}
                            isAdmin={viewer && viewer.appAdmin}
                            getId={(id) => this.setState({ dpId: id })}
                          />
                        ))
                      ) : (
                        <p className="mb-5">No results</p>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : isAddingDataProcessor && !currentDPVisible ? (
            <AddDataProcessor
              addDataProcessor={this.addDataProcessor}
              refetchDP={this.refetchData}
            />
          ) : isAddingAdminDataProcessor ? (
            <AddAdminDP
              addAdminDataProcessor={this.addAdminDataProcessor}
              refetchData={this.refetchData}
            />
          ) : isEditDP && !currentDPVisible ? (
            <DpProfile
              addAdminDataProcessor={this.editDP}
              refetchData={this.refetchData}
              dataProcessor={this.state.dpData}
              seeNextStep={this.editDP}
            />
          ) : currentDPVisible && !isAddingDataProcessor ? (
            <DataProcessor
              dataProcessor={this.state.dp}
              isCurrent={this.state.isCurrent}
              showDP={this.showDP}
              refetchData={this.refetchData}
              isAdmin={viewer && viewer.appAdmin}
              editDP={this.editDP}
              showDeleteModal={this.showDeleteModal}
              getId={(id) => this.setState({ dpId: id })}
              getDP={(dp) => this.setState({ dpData: dp })}
            />
          ) : null}
        </div>

        <DeleteDP
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          showDeleteModal={this.showDeleteModal}
          dpId={this.state.dpId}
          removeAdminDP={this.removeAdminDP}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(DATA_PROCESSORS_QUERY, {
    options: () => ({
      variables: {
        dataProcessorsCategory: "ALL",
        companyName: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(REMOVE_DATA_PROCESSOR_ADMIN, {
    name: "removeDP",
  })
)(DataProcessors);
