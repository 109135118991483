import React, { Component } from "react";
import "./compliance.scss";
import CompliancePrivacy from "./CompliancePrivacy";
import ComplianceDataProcessing from "./ComplianceDataProcessing";
import SyncLoader from "react-spinners/SyncLoader";
import { Alert } from "reactstrap";
import ComplianceBreach from "./ComplianceBreach";
import ComplianceDataOfficer from "./ComplianceDataOfficer";
import SideMenu from "../../components/SideMenu/SideMenu";
import { Redirect } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import { COMPLIANCE_QUERY } from "../../graphql/complianceQuery";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import { graphql } from "@apollo/client/react/hoc";
import ComplianceItem from "./ComplianceItem";
import PropTypes from "prop-types";

class Compliance extends Component {
  state = {
    isPrivacy: false,
    isDataProcessing: false,
    isBreach: false,
    isDataOfficer: false,
    isRighMenuOpen: false,
    type: "",
  };

  showPrivacy = () => {
    this.setState({
      isPrivacy: !this.state.isPrivacy,
      isDataProcessing: false,
    });
  };

  showBreach = () => {
    this.setState({
      isBreach: !this.state.isBreach,
      isPrivacy: false,
      isDataProcessing: false,
    });
  };

  showDataProcessing = () => {
    this.setState({
      isDataProcessing: !this.state.isDataProcessing,
      isPrivacy: false,
    });
  };

  showDataOfficer = () => {
    this.setState({
      isDataOfficer: !this.state.isDataOfficer,
    });
  };

  showUpgrade = (type) => {
    this.setState({
      type,
    });
  };

  getInitialPage = () => {
    this.setState({
      isPrivacy: false,
      isDataProcessing: false,
      isBreach: false,
      isDataOfficer: false,
      type: "",
    });
  };

  render() {
    const { isPrivacy, isDataProcessing, isBreach, isDataOfficer } = this.state;

    const {
      privacyImpactAssessment,
      dataProtectionOfficer,
      account,
      loading,
      error,
    } = this.props.data;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Compliance"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          <div
            className={`${
              account && account.needToUpgrade ? "-has-banner" : ""
            } compliance`}
          >
            {!isPrivacy && !isDataProcessing && !isBreach && !isDataOfficer ? (
              <div className="compliance__container">
                <p className="compliance__subtitle">
                  Set up your privacy compliance requirements. Here you can make
                  your Privacy Impact Assessment, notify users about a breach
                  incident, manage your Data Processing Agreements and your
                  suppliers.
                </p>

                <div className="d-flex flex-wrap">
                  <ComplianceItem
                    status={
                      privacyImpactAssessment && privacyImpactAssessment.status
                        ? privacyImpactAssessment.status
                        : ""
                    }
                    lastUpdated={
                      privacyImpactAssessment
                        ? privacyImpactAssessment.lastUpdated
                        : null
                    }
                    showSection={this.showPrivacy}
                    title="Privacy Impact Assessment"
                    text="Check if you need to take action through high impact risk
                    of your user’s data."
                    icon="shield"
                  />

                  <ComplianceItem
                    showSection={this.showBreach}
                    title="Breach Incident Management"
                    text="Notify your users and interested companies about a
                    possible data breach incident."
                    icon="cloud"
                  />

                  <ComplianceItem
                    showSection={this.showDataProcessing}
                    title="Data Processing Agreements"
                    text="Manage users and suppliers Data Processing Agreements."
                    icon="file"
                  />

                  <ComplianceItem
                    showSection={this.showDataOfficer}
                    title="Data Protection Officer (DPO)"
                    text="Appoint a DPO that will be responsible to ensure
                    compliance with GDPR requirements."
                    icon="dpo"
                    status={
                      dataProtectionOfficer && dataProtectionOfficer.status
                        ? dataProtectionOfficer.status
                        : ""
                    }
                    lastUpdated={
                      dataProtectionOfficer
                        ? dataProtectionOfficer.lastUpdated
                        : null
                    }
                  />
                </div>
              </div>
            ) : isPrivacy ? (
              <CompliancePrivacy getInitialPage={this.getInitialPage} />
            ) : isDataProcessing ? (
              <ComplianceDataProcessing getInitialPage={this.getInitialPage} />
            ) : isBreach ? (
              <ComplianceBreach getInitialPage={this.getInitialPage} />
            ) : (
              <ComplianceDataOfficer
                showDataOfficer={this.showDataOfficer}
                getInitialPage={this.getInitialPage}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(COMPLIANCE_QUERY)(Compliance);
Compliance.propTypes = {
  data: PropTypes.object.isRequired,
};
