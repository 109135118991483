import gql from "graphql-tag";

export const TOGGLE_ALL_BRANDING = gql`
  mutation($clientMutationId: String) {
    toggleShowLegalsiteBrandingAll(
      input: { clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
