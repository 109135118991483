import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";
import Check from "react-icons/lib/fa/check";
import MdClose from "react-icons/lib/md/close";
import SelectSuppliersModal from "./SelectSuppliersModal";

export default class Sources extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    sources: PropTypes.array,
    dp: PropTypes.array,
    getSources: PropTypes.func.isRequired,
    getDP: PropTypes.func.isRequired,
  };

  state = {
    isSuppliersModalVisible: false,
    isIndividuals: this.props.sources
      ? this.props.sources.indexOf("individuals") > -1
        ? true
        : false
      : null,
    isThirdParty: this.props.dp ? true : false,
    other: null,
    isOther: this.props.sources
      ? this.props.sources.filter((item) => item !== "individuals").length
        ? true
        : false
      : false,
    DPBreach: this.props.dp ? this.props.dp : [],
  };

  showSuppliersModal = () => {
    this.setState({
      isSuppliersModalVisible: !this.state.isSuppliersModalVisible,
    });
  };

  getSources = () => {
    const info = this.props.sources
      ? this.props.sources.filter((item) => item !== "individuals")
      : "";

    this.props.getSources([
      this.state.isIndividuals === true ? "individuals" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  getDp = (enabled, title) => {
    if (enabled) {
      this.setState(
        {
          DPBreach: this.state.DPBreach.concat(title),
        },
        () => this.props.getDP(this.state.DPBreach)
      );
    } else {
      this.setState(
        {
          DPBreach: this.state.DPBreach.filter((item) => item !== title),
        },
        () => this.props.getDP(this.state.DPBreach)
      );
    }
  };

  render() {
    let thirdParties;

    const info = this.props.sources
      ? this.props.sources.filter((item) => item !== "individuals")
      : "";

    if (this.props.dp) {
      thirdParties = this.props.dp.map((item) => item);
    }

    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title">Sources</h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isIndividuals ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isIndividuals: !this.state.isIndividuals }, () =>
                this.getSources()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Collected directly from individuals
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isThirdParty ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState(
                { isThirdParty: !this.state.isThirdParty, DPBreach: [] },
                () => this.props.getDP()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Collected from third parties
          </span>
        </div>
        <Fragment>
          <span>
            <strong>Third-parties selected:</strong>
          </span>
          {thirdParties
            ? thirdParties.map((item, i) => (
                <p
                  key={i}
                  className="modal-edit-privacy-category__content-parties-item mb-0 mt-2"
                >
                  {item}
                </p>
              ))
            : null}
        </Fragment>

        <Button
          className="btn--secondary modal-edit-privacy-category__content-btn d-flex align-items-center justify-content-center mt-3"
          onClick={this.showSuppliersModal}
          disabled={!this.state.isThirdParty}
        >
          Select data processors
        </Button>

        <div className="d-flex flex-column">
          <div className="d-flex mt-3">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getSources()
                )
              }
            >
              <Check color={"#fff"} />
            </span>

            <span className="modal-edit-privacy-category__content-text">
              Other
            </span>
          </div>

          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getSources())
            }
          />
        </div>

        <SelectSuppliersModal
          isSuppliersModalVisible={this.state.isSuppliersModalVisible}
          showSuppliersModal={this.showSuppliersModal}
          getItem={this.getDp}
          dataSuppliers={this.props.dp}
        />
      </div>
    );
  }
}
