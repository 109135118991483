import gql from "graphql-tag";

export const BRANDING_QUERY = gql`
  {
    emails {
      id
      showLegalsiteBranding
    }
    popups {
      showLegalsiteBranding
    }
  }
`;
