import gql from "graphql-tag";

export const SEND_RESPONSE = gql`
  mutation($requestId: ID!, $response: String!, $clientMutationId: String) {
    sendResponseOnRequest(
      input: {
        requestId: $requestId
        response: $response
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      request {
        id
        status
        message
      }
    }
  }
`;
