import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Form,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import { graphql } from "@apollo/client/react/hoc";
import { ADD_CONSENT } from "../../graphql/addConsent";
import "./modals.scss";

class AddOther extends Component {
  static propTypes = {
    showAddOther: PropTypes.func.isRequired,
    isAddOtherVisible: PropTypes.bool.isRequired,
    addConsent: PropTypes.func.isRequired,
    refetchConsent: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
  };

  state = {
    formErrors: {},
  };

  handleSubmission = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .addConsent({
        variables: {
          manageableByUser: true,
          categoryName: name,
        },
      })
      .then(() => {
        this.props.refetchConsent();
        this.props.showAddOther();
        this.props.notify("Cookie Consent added!");
      })
      .catch((error) => {
        console.log(error);
        this.props.notify("Cookie Consent was not added!");
      });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isAddOtherVisible}
          toggle={this.props.showAddOther}
          className="modal-add-category"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showAddOther}
            />
            <p className="modal-assign__title mt-0">Add other category</p>
            <Form onSubmit={this.handleSubmission}>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  placeholder="Category name"
                  invalid={this.state.formErrors.name}
                />
                <FormFeedback>Please add a category name</FormFeedback>
              </FormGroup>

              <div className="modal-add-category__btns">
                <Button
                  className="btn--secondary mr-2"
                  onClick={this.props.showAddOther}
                >
                  Cancel
                </Button>
                <Button className="btn--primary ml-2" type="submit">
                  Save and close
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(ADD_CONSENT, {
  name: "addConsent",
})(AddOther);
