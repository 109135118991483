import React, { Component, Fragment } from "react";
import UserInfo from "./UserInfo";
import { Link } from "react-router-dom";
import ReactFilestack from "filestack-react";
import RequestStats from "./RequestStats";
import PlusCircle from "../../components/Icons/PlusCircle";
import {
  Button,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import MdClose from "react-icons/lib/md/close";
import Question from "./Question";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { DELAY_DATA_REQUEST } from "../../graphql/delayDataRequest";
import { TOGGLE_REQUEST_SPAM } from "../../graphql/toggleRequestSpam";
import { TOGGLE_REQUEST_ARCHIVE } from "../../graphql/toggleRequestArchive";
import { ASSIGN_REQUEST_TO_USER } from "../../graphql/assignRequestToUser";
import { DECLINE_REQUEST } from "../../graphql/declineDataRequest";
import { SEND_DEFAULT_MAIL } from "../../graphql/sendDefaultInboxMail";
import { REMOVE_REQUEST } from "../../graphql/removeRequest";
import { REMOVE_ASSIGNED_USER } from "../../graphql/removeAssignedUser";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import InternalTasks from "./InternalTasks";
import axios from "axios";
import ExternalTasks from "./ExternalTasks";
import AssignedRequest from "../../components/Modals/AsignedRequest";
import ManualDelay from "../../components/Modals/ManualDelay";
import CompanyUserInfo from "./CompanyUserInfo";
import PropTypes from "prop-types";

class Request extends Component {
  static propTypes = {
    selectedRequest: PropTypes.object,
    toggleRequestSpam: PropTypes.func,
    toggleRequestArchive: PropTypes.func,
    delayRequest: PropTypes.func,
    assignUser: PropTypes.func,
    declineRequest: PropTypes.func,
    removeRequest: PropTypes.func,
    removeAssignedUser: PropTypes.func,
    diff: PropTypes.object,
    account: PropTypes.object,
    viewer: PropTypes.object,
    isSolvingSectionVisible: PropTypes.bool,
    showDelay: PropTypes.func,
    sendDefaultMail: PropTypes.func,
    showSolved: PropTypes.func,
    showInitialRequest: PropTypes.func,
    generateReport: PropTypes.func,
    onFiles: PropTypes.func,
    files: PropTypes.array,
    onExternalFiles: PropTypes.func,
    externalFiles: PropTypes.array,
    onFile: PropTypes.func,
    removeFile: PropTypes.func,
    showMailPreview: PropTypes.func,
    editEmail: PropTypes.func,
    answer: PropTypes.func,
    getItem: PropTypes.func,
    isSameRequest: PropTypes.func,
    mailType: PropTypes.string,
    showSolvingSection: PropTypes.func,
  };

  state = {
    isAssignedModalVisible: false,
    isManualDelayVisible: false,
    selectedRequest: this.props.selectedRequest,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedRequest !== prevState.selectedRequest) {
      return { selectedRequest: nextProps.selectedRequest };
    }
    return null;
  }

  showStatus = (status) => {
    if (status === "SOLVED") return "Solved";
    else if (status === "IN_PROGRESS") return "In Progress";
    else if (status === "ACTION_REQUIRED") return "Action required";
    else if (status === "DELAYED") return "Delayed";
    else return status;
  };

  notify = (text) => toast(text);

  toggleSpam = () => {
    this.props
      .toggleRequestSpam({
        variables: {
          id: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.notify(
          this.props.selectedRequest.spam === false
            ? "Request unspammed!"
            : "Request spammed!"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openManualDelay = () => {
    this.setState({
      isManualDelayVisible: !this.state.isManualDelayVisible,
    });
  };

  toggleArchive = () => {
    this.props
      .toggleRequestArchive({
        variables: {
          id: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.notify(
          this.props.selectedRequest.archived === true
            ? "Request unarchived!"
            : "Request archived!"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  delayRequest = () => {
    this.props
      .delayRequest({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.props.showDelay();
        this.notify("Request delayed!");
      })
      .catch((error) => {
        this.notify("Request couldn't be delayed!");
        console.log(error);
      });
  };

  assignToUser = (userId) => {
    this.props
      .assignUser({
        variables: {
          requestId: this.props.selectedRequest.id,
          userId,
        },
      })
      .then(() => {
        this.notify("Request assigned");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Request couldn't be assigned!");
      });
  };

  decline = (reason) => {
    this.props
      .declineRequest({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.props
          .sendDefaultMail({
            variables: {
              requestId: this.props.selectedRequest.id,
              mailType: reason,
              reasonOfUnreasonable:
                reason === "UNREASONABLE" || reason === "CANT_FIND_DATA"
                  ? ""
                  : reason,
            },
          })
          .then(() => {
            this.notify("Request was declined");
            this.props.showSolved();
          })
          .catch((error) => {
            this.notify("Request could not be declined");
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showAssignedModal = () => {
    this.setState({
      isAssignedModalVisible: !this.state.isAssignedModalVisible,
    });
  };

  redirectToDp = () => {
    window.location.href = "/data-processors";
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  downloadReport = () => {
    const url = this.props.selectedRequest.downloadUrl,
      name = `account_report_${this.props.selectedRequest.id}`;
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.notify(this.showDownloadNotifyText());
  };

  deleteRequest = () => {
    this.props
      .removeRequest({
        variables: {
          id: [this.props.selectedRequest.id],
        },
      })
      .then(() => {
        this.notify("Request deleted");
        this.props.showInitialRequest();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  unassignUser = () => {
    this.props
      .removeAssignedUser({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.notify("Removed assigned user");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { diff, account, viewer, isSolvingSectionVisible } = this.props;

    const { selectedRequest } = this.state;

    return (
      <div className="inbox__data-info bg-white">
        <UserInfo
          selectedRequest={selectedRequest}
          toggleSpam={this.toggleSpam}
          toggleArchive={this.toggleArchive}
          downloadReport={this.downloadReport}
          deleteRequest={this.deleteRequest}
          showAssignedModal={this.showAssignedModal}
          generateReport={this.props.generateReport}
        />
        <RequestStats
          selectedRequest={selectedRequest}
          diff={diff}
          showStatus={this.showStatus}
        />

        {selectedRequest && selectedRequest.dataController ? (
          <CompanyUserInfo selectedRequest={selectedRequest} />
        ) : null}

        {selectedRequest ? (
          !isSolvingSectionVisible &&
          selectedRequest &&
          selectedRequest.fileResources &&
          !selectedRequest.fileResources.length ? (
            selectedRequest &&
            (selectedRequest.category === "COMPLAINT" ||
              selectedRequest.category === "QUESTION") ? (
              <Question
                selectedRequest={selectedRequest}
                account={account}
                showMailPreview={this.props.showMailPreview}
                editEmail={this.props.editEmail}
                answer={this.props.answer}
                getItem={this.props.getItem}
                isSameRequest={this.props.isSameRequest}
                viewer={this.props.viewer}
                mailType={this.props.mailType}
                showDelay={this.props.showDelay}
                showSolved={this.props.showSolved}
              />
            ) : selectedRequest && selectedRequest.status !== "SOLVED" ? (
              <Fragment>
                <div className="inbox__data-actions">
                  <p className="inbox__data-stats-label">
                    Choose a course of action for this request:
                  </p>

                  <div className="inbox__data-actions-btns d-flex justify-content-between">
                    <Button
                      className="btn--primary inbox__data-actions-solve"
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.props.showSolvingSection()
                      }
                    >
                      Solve request
                    </Button>
                    <Button
                      className="btn--secondary inbox__data-actions-solve"
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.delayRequest()
                      }
                    >
                      Delay
                    </Button>

                    <UncontrolledDropdown>
                      <DropdownToggle className="btn--secondary inbox__data-actions-solve">
                        Decline &nbsp; <FaCaret className="ml-3" />
                      </DropdownToggle>
                      <DropdownMenu className="inbox__request-assign-menu">
                        <DropdownItem
                          onClick={() =>
                            selectedRequest.assignedTo &&
                            selectedRequest.assignedTo.id !== viewer.id
                              ? this.showAssignedModal()
                              : this.decline("UNREASONABLE")
                          }
                        >
                          Unreasonable request
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            selectedRequest.assignedTo &&
                            selectedRequest.assignedTo.id !== viewer.id
                              ? this.showAssignedModal()
                              : this.decline("CANT_FIND_DATA")
                          }
                        >
                          Can’t find data
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            selectedRequest.assignedTo &&
                            selectedRequest.assignedTo.id !== viewer.id
                              ? this.showAssignedModal()
                              : this.openManualDelay()
                          }
                        >
                          Write manual response
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <UncontrolledDropdown
                      className={`${
                        selectedRequest && selectedRequest.assignedTo
                          ? "inbox__request-users-toggle"
                          : ""
                      }`}
                    >
                      {selectedRequest && selectedRequest.assignedTo ? (
                        <DropdownToggle
                          className="d-flex align-items-center"
                          tag="div"
                        >
                          <div className="d-flex align-items-center">
                            {selectedRequest &&
                            selectedRequest.assignedTo.avatar ? (
                              <img
                                className="inbox__request-avatar-placeholder"
                                src={selectedRequest.assignedTo.avatar}
                                alt="avatar"
                              />
                            ) : (
                              <span className="inbox__request-avatar-placeholder" />
                            )}
                            <div className="ml-3 inbox__request-users">
                              <p className="mb-0">
                                <strong>
                                  {selectedRequest.assignedTo.name}
                                </strong>{" "}
                                <FaCaret className="ml-3" />
                              </p>
                            </div>
                          </div>
                        </DropdownToggle>
                      ) : (
                        <DropdownToggle className="btn--secondary inbox__request-actions">
                          Assign to <FaCaret className="ml-3" />
                        </DropdownToggle>
                      )}
                      <DropdownMenu className="inbox__request-assign-menu">
                        {account &&
                        account.users &&
                        account.users.length !== 1 ? (
                          account.users.map((user) =>
                            user.id !== viewer.id ? (
                              <DropdownItem
                                key={user.id}
                                onClick={() =>
                                  selectedRequest &&
                                  selectedRequest.assignedTo &&
                                  user.id === selectedRequest.assignedTo.id
                                    ? this.unassignUser()
                                    : this.assignToUser(user.id)
                                }
                              >
                                <div className="d-flex align-items-center">
                                  {user.avatar ? (
                                    <img src={user.avatar} alt="avatar" />
                                  ) : (
                                    <span className="inbox__request-avatar-placeholder" />
                                  )}
                                  <div className="ml-3 inbox__request-users">
                                    <p className="mb-0">
                                      <strong>{user.name}</strong>
                                    </p>
                                    <p className="mb-0">{user.jobTitle}</p>
                                  </div>
                                  {selectedRequest &&
                                  selectedRequest.assignedTo &&
                                  user.id === selectedRequest.assignedTo.id ? (
                                    <MdClose className="ml-2" size={18} />
                                  ) : null}
                                </div>
                              </DropdownItem>
                            ) : null
                          )
                        ) : account &&
                          account.users &&
                          account.users.length === 1 ? (
                          <div className="inbox__request-add-empty">
                            <Link
                              to={{
                                pathname: "/settings",
                                state: { isAddUser: true },
                              }}
                            >
                              <PlusCircle size={24} /> Add users to your account
                              to assign requests
                            </Link>
                          </div>
                        ) : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </Fragment>
            ) : null
          ) : selectedRequest && selectedRequest.status !== "SOLVED" ? (
            selectedRequest.category === "DONT_SELL_DATA" ? (
              <Fragment>
                <ExternalTasks
                  onExternalFiles={this.props.onExternalFiles}
                  assigned={
                    selectedRequest.assignedTo &&
                    selectedRequest.assignedTo.id !== viewer.id
                  }
                  showAssignedModal={this.showAssignedModal}
                  isSell={true}
                  externalFiles={this.props.externalFiles}
                  selectedRequest={selectedRequest}
                  redirectToDp={this.redirectToDp}
                  removeFile={this.props.removeFile}
                  hasNotify={(msg) => this.notify(msg)}
                  selectedRequestFile={
                    selectedRequest &&
                    selectedRequest.fileResources &&
                    selectedRequest.fileResources.length
                      ? selectedRequest.fileResources
                      : null
                  }
                />

                <Question
                  notQuestion={true}
                  isSell={true}
                  selectedRequest={selectedRequest}
                  account={account}
                  onFile={this.props.onFile}
                  mailType={this.props.mailType}
                  showMailPreview={this.props.showMailPreview}
                  editEmail={this.props.editEmail}
                  answer={this.props.answer}
                  getItem={this.props.getItem}
                  isSameRequest={this.props.isSameRequest}
                  viewer={this.props.viewer}
                  showDelay={this.props.showDelay}
                  showSolved={this.props.showSolved}
                />
              </Fragment>
            ) : (
              <Fragment>
                <InternalTasks
                  onFiles={this.props.onFiles}
                  selectedRequest={selectedRequest}
                  files={this.props.files}
                  removeFile={this.props.removeFile}
                  selectedRequestFile={
                    selectedRequest &&
                    selectedRequest.fileResources &&
                    selectedRequest.fileResources.length
                      ? selectedRequest.fileResources
                      : null
                  }
                  assigned={
                    selectedRequest.assignedTo &&
                    selectedRequest.assignedTo.id !== viewer.id
                  }
                  showAssignedModal={this.showAssignedModal}
                />
                <ExternalTasks
                  onExternalFiles={this.props.onExternalFiles}
                  assigned={
                    selectedRequest.assignedTo &&
                    selectedRequest.assignedTo.id !== viewer.id
                  }
                  showAssignedModal={this.showAssignedModal}
                  externalFiles={this.props.externalFiles}
                  selectedRequest={selectedRequest}
                  redirectToDp={this.redirectToDp}
                  removeFile={this.props.removeFile}
                  hasNotify={(msg) => this.notify(msg)}
                  selectedRequestFile={
                    selectedRequest &&
                    selectedRequest.fileResources &&
                    selectedRequest.fileResources.length
                      ? selectedRequest.fileResources
                      : null
                  }
                />
                <Question
                  notQuestion={true}
                  selectedRequest={selectedRequest}
                  account={account}
                  mailType={this.props.mailType}
                  showMailPreview={this.props.showMailPreview}
                  editEmail={this.props.editEmail}
                  answer={this.props.answer}
                  getItem={this.props.getItem}
                  isSameRequest={this.props.isSameRequest}
                  viewer={this.props.viewer}
                  showDelay={this.props.showDelay}
                  showSolved={this.props.showSolved}
                />
              </Fragment>
            )
          ) : null
        ) : selectedRequest && selectedRequest.status !== "SOLVED" ? (
          <Fragment>
            <div className="inbox__data-actions">
              <p className="inbox__data-stats-label">
                Choose a course of action for this request:
              </p>

              <div className="inbox__data-actions-btns d-flex">
                <UncontrolledDropdown>
                  <DropdownToggle className="btn--primary inbox__data-actions-solve mr-4">
                    Actions &nbsp; <FaCaret className="ml-3" />
                  </DropdownToggle>
                  <DropdownMenu className="inbox__request-assign-menu">
                    <ReactFilestack
                      apikey={"AJNM9qOpGRljTn17sgxrfz"}
                      componentDisplayMode={{
                        type: "link",
                        customText: <DropdownItem>Upload file</DropdownItem>,
                        customClass: "show-upload",
                      }}
                      onSuccess={(res) => this.props.onFile(res)}
                      actionOptions={{
                        fromSources: [
                          "local_file_system",
                          "googledrive",
                          "dropbox",
                          "onedrive",
                        ],
                        accept: [
                          "application/pdf",
                          "application/msword",
                          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                        ],
                        maxFiles: 1,
                      }}
                    />

                    <DropdownItem
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.decline("CANT_FIND_DATA")
                      }
                    >
                      Can’t find data
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.delayRequest()
                      }
                    >
                      Delay
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

                <UncontrolledDropdown
                  className={`${
                    selectedRequest && selectedRequest.assignedTo
                      ? "inbox__request-users-toggle"
                      : ""
                  }`}
                >
                  {selectedRequest && selectedRequest.assignedTo ? (
                    <DropdownToggle
                      className="d-flex align-items-center"
                      tag="div"
                    >
                      <div className="d-flex align-items-center">
                        {selectedRequest &&
                        selectedRequest.assignedTo.avatar ? (
                          <img
                            className="inbox__request-avatar-placeholder"
                            src={selectedRequest.assignedTo.avatar}
                            alt="avatar"
                          />
                        ) : (
                          <span className="inbox__request-avatar-placeholder" />
                        )}
                        <div className="ml-3 inbox__request-users">
                          <p className="mb-0">
                            <strong>{selectedRequest.assignedTo.name}</strong>{" "}
                            <FaCaret className="ml-3" />
                          </p>
                        </div>
                      </div>
                    </DropdownToggle>
                  ) : (
                    <DropdownToggle className="btn--secondary inbox__request-actions">
                      Assign to <FaCaret className="ml-3" />
                    </DropdownToggle>
                  )}
                  <DropdownMenu className="inbox__request-assign-menu">
                    {account && account.users && account.users.length !== 1 ? (
                      account.users.map((user) =>
                        user.id !== viewer.id ? (
                          <DropdownItem
                            key={user.id}
                            onClick={() =>
                              selectedRequest &&
                              selectedRequest.assignedTo &&
                              user.id === selectedRequest.assignedTo.id
                                ? this.unassignUser()
                                : this.assignToUser(user.id)
                            }
                          >
                            <div className="d-flex align-items-center">
                              {user.avatar ? (
                                <img src={user.avatar} alt="avatar" />
                              ) : (
                                <span className="inbox__request-avatar-placeholder" />
                              )}
                              <div className="ml-3 inbox__request-users">
                                <p className="mb-0">
                                  <strong>{user.name}</strong>
                                </p>
                                <p className="mb-0">{user.jobTitle}</p>
                              </div>
                              {selectedRequest &&
                              selectedRequest.assignedTo &&
                              user.id === selectedRequest.assignedTo.id ? (
                                <MdClose className="ml-2" size={18} />
                              ) : null}
                            </div>
                          </DropdownItem>
                        ) : null
                      )
                    ) : account &&
                      account.users &&
                      account.users.length === 1 ? (
                      <div className="inbox__request-add-empty">
                        <Link
                          to={{
                            pathname: "/settings",
                            state: { isAddUser: true },
                          }}
                        >
                          <PlusCircle size={24} /> Add users to your account to
                          assign requests
                        </Link>
                      </div>
                    ) : null}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Fragment>
        ) : null}

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <AssignedRequest
          showAssignedModal={this.showAssignedModal}
          isAssignedModalVisible={this.state.isAssignedModalVisible}
        />

        <ManualDelay
          openManualDelay={this.openManualDelay}
          isManualDelayVisible={this.state.isManualDelayVisible}
          id={selectedRequest ? selectedRequest.id : null}
          mailType={this.props.mailType}
          showSolved={this.props.showSolved}
        />
      </div>
    );
  }
}

export default compose(
  graphql(TOGGLE_REQUEST_SPAM, {
    name: "toggleRequestSpam",
  }),
  graphql(TOGGLE_REQUEST_ARCHIVE, {
    name: "toggleRequestArchive",
  }),
  graphql(DELAY_DATA_REQUEST, {
    name: "delayRequest",
  }),
  graphql(ASSIGN_REQUEST_TO_USER, {
    name: "assignUser",
  }),
  graphql(DECLINE_REQUEST, {
    name: "declineRequest",
  }),
  graphql(SEND_DEFAULT_MAIL, {
    name: "sendDefaultMail",
  }),
  graphql(REMOVE_REQUEST, {
    name: "removeRequest",
  }),
  graphql(REMOVE_ASSIGNED_USER, {
    name: "removeAssignedUser",
  })
)(Request);
