import PropTypes from "prop-types";
import React from "react";

const Mail = props => (
    <svg
        height={20}
        viewBox='0 0 24 20'
        width={24}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="App-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="9.2--Dont-sell---settings" transform="translate(-829.000000, -1260.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Group-10" transform="translate(181.000000, 904.000000)">
                    <g id="customization" transform="translate(0.000000, 270.000000)">
                        <g id="emails" transform="translate(550.000000, 52.000000)">
                            <g id="window" transform="translate(98.000000, 34.000000)">
                                <g id="envelope">
                                    <path d="M20.4,1.0658141e-15 L3.6,1.0658141e-15 C1.6117749,0 5.32907052e-16,1.6117749 0,3.6 L0,15.6 C5.32907052e-16,17.5882251 1.6117749,19.2 3.6,19.2 L20.4,19.2 C22.3882251,19.2 24,17.5882251 24,15.6 L24,3.6 C24,1.6117749 22.3882251,0 20.4,1.0658141e-15 Z M19.908,2.4 L12.852,9.456 C12.6266801,9.68317518 12.319965,9.81095799 12,9.81095799 C11.680035,9.81095799 11.3733199,9.68317518 11.148,9.456 L4.092,2.4 L19.908,2.4 Z M21.6,15.6 C21.6,16.2627417 21.0627417,16.8 20.4,16.8 L3.6,16.8 C2.9372583,16.8 2.4,16.2627417 2.4,15.6 L2.4,4.092 L9.456,11.148 C10.8615242,12.5517762 13.1384758,12.5517762 14.544,11.148 L21.6,4.092 L21.6,15.6 Z" id="Shape"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Mail.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Mail;