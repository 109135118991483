import PropTypes from "prop-types";
import React from "react";

const LogOutIcon = props => (
    <svg
        height={13}
        viewBox='0 0 13 13'
        width={13}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-1----Introduction" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="2.7--Onboarding-7" transform="translate(-1129.000000, -323.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="Dropdown" transform="translate(1113.000000, 78.000000)">
                    <g id="options" transform="translate(13.000000, 245.000000)">
                        <g id="sign-out-alt-(1)" transform="translate(3.000000, 0.000000)">
                            <path d="M6.88296124,7.14950552 L5.38807825,8.63788902 C5.26503466,8.75992776 5.19582439,8.92605207 5.19582439,9.0993529 C5.19582439,9.27265373 5.26503466,9.43877804 5.38807825,9.56081678 C5.51011699,9.68386037 5.6762413,9.75307064 5.84954213,9.75307064 C6.02284297,9.75307064 6.18896728,9.68386037 6.31100601,9.56081678 L8.91080252,6.96102028 C8.96997429,6.8992077 9.0163579,6.82631918 9.04729183,6.74653706 C9.11229846,6.58829957 9.11229846,6.41081322 9.04729183,6.25257573 C9.0163579,6.17279362 8.96997429,6.09990509 8.91080252,6.03809252 L6.31100601,3.43829601 C6.14614085,3.27343084 5.90584463,3.20904366 5.68063462,3.26938849 C5.45542461,3.32973333 5.27951557,3.50564237 5.21917074,3.73085238 C5.1588259,3.95606239 5.22321308,4.19635861 5.38807825,4.36122377 L6.88296124,5.84960727 L0.649949126,5.84960727 C0.290992136,5.84960727 -8.57980353e-13,6.14059941 -8.57980353e-13,6.4995564 C-8.57980353e-13,6.85851339 0.290992136,7.14950552 0.649949126,7.14950552 L6.88296124,7.14950552 Z M6.49949126,6.51366023e-05 C4.02141235,-0.0109983047 1.75268053,1.38805298 0.649949126,3.60728279 C0.48841848,3.93034408 0.619364941,4.32318346 0.942426233,4.48471411 C1.26548752,4.64624475 1.65832691,4.51529829 1.81985755,4.192237 C2.9570437,1.89574383 5.5978799,0.770226567 8.04205174,1.54035463 C10.4862236,2.31048269 12.0048362,4.7465904 11.6201239,7.2801787 C11.2354116,9.81376699 9.06210237,11.6892755 6.49949126,11.6991494 C4.52185524,11.7077136 2.7137106,10.5839366 1.84585552,8.80687579 C1.68432487,8.48381451 1.29148549,8.35286806 0.968424206,8.5143987 C0.645362922,8.67592935 0.514416458,9.06876872 0.675947091,9.39183001 C2.10213973,12.2619395 5.40760923,13.6640088 8.46240365,12.6945838 C11.5171981,11.7251587 13.4093449,8.67364969 12.9195976,5.50636355 C12.4298504,2.33907741 9.7044174,0.00156980399 6.49949126,6.51366023e-05 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

LogOutIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default LogOutIcon;