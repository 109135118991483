import React, { Component } from "react";
import Tip from "./Tip";
import Camera from "../../components/Icons/Camera";
import ReactFilestack from "filestack-react";
import TextareaAutosize from "react-textarea-autosize";
import { STEP2EMAIL_QUERY } from "../../graphql/step2EmailQuery";
import { graphql } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

class Step2Email extends Component {
  static propTypes = {
    bgColor: PropTypes.string,
    email: PropTypes.string,
    emailTitle: PropTypes.string,
    files: PropTypes.array,
    titleColor: PropTypes.string,
    titleFont: PropTypes.string,
    updatedTitle: PropTypes.string,
    titleSize: PropTypes.number,
    bodyFont: PropTypes.string,
    bodyColor: PropTypes.string,
    bodySize: PropTypes.number,
    updatedBody: PropTypes.string,
    updatedButtonText: PropTypes.string,
    updatedFooterText: PropTypes.string,
    buttonFont: PropTypes.string,
    buttonSize: PropTypes.number,
    buttonColor: PropTypes.string,
    emailText: PropTypes.string,
    buttonTextColor: PropTypes.string,
    buttonTextSize: PropTypes.number,
    footerFont: PropTypes.string,
    index: PropTypes.number,
    btnBg: PropTypes.string,
    footerBg: PropTypes.string,
    getBannerImage: PropTypes.func,
    onFiles: PropTypes.func,
    updateTitle: PropTypes.func,
    updateBody: PropTypes.func,
    updateButtonText: PropTypes.func,
    updateEmailText: PropTypes.func,
    updateFooterText: PropTypes.func,
    isForInbox: PropTypes.bool,
    logo: PropTypes.string,
    emailTitleAlign: PropTypes.string,
    emailTitleBold: PropTypes.bool,
    emailTitleItalic: PropTypes.bool,
    emailBodyAlign: PropTypes.string,
    emailBodyBold: PropTypes.bool,
    emailBodyItalic: PropTypes.bool,
    emailBtnBold: PropTypes.bool,
    emailFooterBold: PropTypes.bool,
    emailFooterItalic: PropTypes.bool,
    emailBtnItalic: PropTypes.bool,
    data: PropTypes.object,
    answer: PropTypes.string,
  };

  state = {
    showBodyTextarea: false,
    isEmailText: false,
    showTitle: false,
    isFooterText: false,
  };

  render() {
    const {
      bgColor,
      email,
      emailTitle,
      files,
      titleColor,
      titleFont,
      updatedTitle,
      titleSize,
      bodyFont,
      bodyColor,
      bodySize,
      updatedBody,
      updatedButtonText,
      updatedFooterText,
      buttonFont,
      buttonSize,
      buttonColor,
      emailText,
      buttonTextColor,
      buttonTextSize,
      footerFont,
      index,
      btnBg,
      footerBg,
      getBannerImage,
      onFiles,
      updateTitle,
      updateBody,
      updateButtonText,
      updateEmailText,
      updateFooterText,
      isForInbox,
      logo,
      emailTitleAlign,
      emailTitleBold,
      emailTitleItalic,
      emailBodyAlign,
      emailBodyBold,
      emailBodyItalic,
      emailBtnBold,
      emailFooterBold,
      emailFooterItalic,
      emailBtnItalic,
    } = this.props;

    const { account } = this.props.data;

    return (
      <div
        className={`${
          isForInbox ? "mt-5" : "customization-email customization-email__type"
        }`}
      >
        {!isForInbox ? (
          <h3 className="customization-email__subjects-title">2. Customize</h3>
        ) : null}

        {!isForInbox ? (
          <div className="mx-auto customization-email__tip">
            <Tip isEmail={true} />
          </div>
        ) : null}

        <div
          className="customization-email__email-template mt-4"
          style={{
            background: bgColor ? bgColor : email.backgroundColor,
          }}
        >
          <div className="customization-email__header">
            {logo || (account && account.companyLogo) ? (
              <div className="customization-email__logo-container d-flex align-items-center">
                <img src={logo ? logo : account.companyLogo} alt="logo" />
              </div>
            ) : account && account.companyName ? (
              <span className="navigationBar__default-avatar2">
                {account && account.companyName
                  ? account.companyName.charAt(0).toUpperCase()
                  : ""}
              </span>
            ) : (
              <p className="customization-email__header-logo">Logo</p>
            )}
            <span
              className="customization-email__header-link"
              style={{
                color: titleColor ? titleColor : email.titleColor,
              }}
            >
              Open in browser
            </span>
          </div>
          <div>
            {emailTitle !== "question" &&
            emailTitle !== "breach" &&
            emailTitle !== "breach2" &&
            emailTitle !== "breach3" &&
            emailTitle !== "Data erasure request" ? (
              <div className="customization-email__banner position-relative">
                {(files && files.length && email.bannerImage) ||
                (files && files.length && !email.bannerImage) ? (
                  files.map((file, i) => (
                    <img key={i} src={file ? file.url : null} alt="banner" />
                  ))
                ) : isForInbox && email.bannerImage ? (
                  <img src={email.bannerImage} alt="banner" />
                ) : files && !files.length && email.bannerImage ? (
                  <img src={email.bannerImage} alt="banner" />
                ) : (
                  <img src={getBannerImage} alt="getbanner" />
                )}

                {!isForInbox ? (
                  <ReactFilestack
                    apikey={"AJNM9qOpGRljTn17sgxrfz"}
                    componentDisplayMode={{
                      type: "link",
                      customText: (
                        <span className="d-flex align-items-center justify-content-center flex-column">
                          <Camera />
                          <p className="customization-email__overlay-text">
                            Replace image
                          </p>
                        </span>
                      ),
                      customClass: "customization-email__overlay",
                    }}
                    onSuccess={onFiles}
                  />
                ) : null}
              </div>
            ) : null}

            {emailTitle !== "question" &&
            emailTitle !== "breach" &&
            emailTitle !== "breach2" &&
            emailTitle !== "breach3" ? (
              !this.state.showTitle ? (
                <p
                  onClick={() => this.setState({ showTitle: true })}
                  className={`${
                    emailTitle === "question" ||
                    emailTitle === "Data erasure request"
                      ? "mt-3"
                      : ""
                  } customization-email__email-title border-0 px-5`}
                  style={{
                    color: titleColor ? titleColor : email.titleColor,
                    fontSize: titleSize ? titleSize : email.titleSize,
                    background: "transparent",
                    fontFamily: titleFont ? titleFont : email.titleFont,
                    fontStyle:
                      emailTitleItalic !== null
                        ? emailTitleItalic === true
                          ? "italic"
                          : "normal"
                        : email.titleItalic
                        ? "italic"
                        : "normal",
                    textAlign:
                      emailTitleAlign !== null
                        ? emailTitleAlign
                        : email.titleAlign,
                    fontWeight:
                      emailTitleBold !== null
                        ? emailTitleBold === true
                          ? 900
                          : 300
                        : email.titleBold
                        ? 900
                        : 300,
                  }}
                >
                  {updatedTitle ? updatedTitle : email.title}
                </p>
              ) : (
                <TextareaAutosize
                  onBlur={() => this.setState({ showTitle: false })}
                  value={updatedTitle ? updatedTitle : email.title}
                  minRows={1}
                  autoFocus={true}
                  onChange={(e) => updateTitle(e.target.value)}
                  className={`${
                    emailTitle === "question" ||
                    emailTitle === "Data erasure request"
                      ? "mt-3"
                      : ""
                  } customization-email__email-title border-0 px-5`}
                  style={{
                    color: titleColor ? titleColor : email.titleColor,
                    fontSize: titleSize ? titleSize : email.titleSize,
                    background: "transparent",
                    fontFamily: titleFont ? titleFont : email.titleFont,
                    textAlign:
                      emailTitleAlign !== null
                        ? emailTitleAlign
                        : email.titleAlign,
                    fontStyle:
                      emailTitleItalic !== null
                        ? emailTitleItalic === true
                          ? "italic"
                          : "normal"
                        : email.titleItalic
                        ? "italic"
                        : "normal",
                    fontWeight:
                      emailTitleBold !== null
                        ? emailTitleBold === true
                          ? 900
                          : 300
                        : email.titleBold
                        ? 900
                        : 300,
                  }}
                />
              )
            ) : null}

            {!this.state.showBodyTextarea ? (
              <p
                onClick={() => this.setState({ showBodyTextarea: true })}
                style={{
                  height: "auto",
                  color: bodyColor ? bodyColor : email.bodyColor,
                  fontSize: bodySize ? bodySize : email.bodySize,
                  background: "transparent",
                  fontFamily: bodyFont ? bodyFont : email.bodyFont,
                  textAlign:
                    emailBodyAlign !== null ? emailBodyAlign : email.bodyAlign,
                  fontWeight:
                    emailBodyBold !== null
                      ? emailBodyBold === true
                        ? 900
                        : 300
                      : email.bodyBold
                      ? 900
                      : 300,
                  fontStyle:
                    emailBodyItalic !== null
                      ? emailBodyItalic === true
                        ? "italic"
                        : "normal"
                      : email.bodyItalic
                      ? "italic"
                      : "normal",
                }}
                className={`${
                  emailTitle === "question" ||
                  emailTitle === "breach" ||
                  emailTitle === "breach3" ||
                  emailTitle === "breach2"
                    ? "text-left mt-4"
                    : ""
                } customization-email__subtitle border-0 px-5`}
              >
                {isForInbox
                  ? updatedBody
                    ? updatedBody
                    : `${email.body} ${
                        this.props.answer ? this.props.answer : ""
                      }`
                  : updatedBody
                  ? updatedBody
                  : email.body}
              </p>
            ) : (
              <TextareaAutosize
                value={
                  isForInbox
                    ? updatedBody
                      ? updatedBody
                      : `${email.body} ${
                          this.props.answer ? this.props.answer : ""
                        }`
                    : updatedBody
                    ? updatedBody
                    : email.body
                }
                minRows={1}
                autoFocus={this.state.showBodyTextarea}
                onBlur={() => this.setState({ showBodyTextarea: false })}
                onChange={(e) => updateBody(e.target.value)}
                style={{
                  height: "auto",
                  color: bodyColor ? bodyColor : email.bodyColor,
                  fontSize: bodySize ? bodySize : email.bodySize,
                  background: "transparent",
                  fontFamily: bodyFont ? bodyFont : email.bodyFont,
                  textAlign:
                    emailBodyAlign !== null ? emailBodyAlign : email.bodyAlign,
                  fontStyle:
                    emailBodyItalic !== null
                      ? emailBodyItalic === true
                        ? "italic"
                        : "normal"
                      : email.bodyItalic
                      ? "italic"
                      : "normal",
                  fontWeight:
                    emailBodyBold !== null
                      ? emailBodyBold === true
                        ? 900
                        : 300
                      : email.bodyBold
                      ? 900
                      : 300,
                }}
                className={`${
                  emailTitle === "question" ||
                  emailTitle === "breach" ||
                  emailTitle === "breach3" ||
                  emailTitle === "breach2"
                    ? "text-left mt-4"
                    : ""
                } customization-email__subtitle border-0 px-5`}
              />
            )}

            {(emailTitle === "Data access request" &&
              index !== 12 &&
              index !== 13 &&
              index !== 14) ||
            emailTitle !== "Do not sell data request" ||
            emailTitle === "Signed Data Processing Agreement" ||
            emailTitle === "Data Processing Agreement signature" ||
            emailTitle === "Data erasure request" ||
            emailTitle === "Data access requested" ||
            (index !== 8 && emailTitle === "Data Processing Agreement") ? (
              index !== 5 &&
              index !== 8 &&
              index !== 12 &&
              index !== 13 &&
              index !== 1 &&
              index !== 9 &&
              index !== 14 ? (
                <TextareaAutosize
                  value={
                    updatedButtonText ? updatedButtonText : email.buttonText
                  }
                  minRows={1}
                  onChange={(e) => updateButtonText(e.target.value)}
                  className="customization-email__button border-0"
                  style={{
                    background: btnBg ? btnBg : email.buttonColor,
                    color: buttonColor ? buttonColor : email.buttonTextColor,
                    fontSize: buttonSize ? buttonSize : email.buttonSize,
                    fontFamily: buttonFont ? buttonFont : email.buttonFont,
                    fontStyle:
                      emailBtnItalic !== null
                        ? emailBtnItalic === true
                          ? "italic"
                          : "normal"
                        : email.buttonItalic
                        ? "italic"
                        : "normal",
                    fontWeight:
                      emailBtnBold !== null
                        ? emailBtnBold === true
                          ? 900
                          : 300
                        : email.buttonBold
                        ? 900
                        : 300,
                  }}
                />
              ) : null
            ) : null}

            {!this.state.isEmailText ? (
              <p
                className="customization-email__email-text border-0 mb-0"
                onClick={() => this.setState({ isEmailText: true })}
                style={{
                  color: bodyColor ? bodyColor : email.bodyColor,
                  fontSize: bodySize ? bodySize : email.bodySize,
                  background: "transparent",
                  fontFamily: bodyFont ? bodyFont : email.bodyFont,
                  textAlign:
                    emailBodyAlign !== null ? emailBodyAlign : email.bodyAlign,
                  fontWeight:
                    emailBodyBold !== null
                      ? emailBodyBold === true
                        ? 900
                        : 300
                      : email.bodyBold
                      ? 900
                      : 300,
                  fontStyle:
                    emailBodyItalic !== null
                      ? emailBodyItalic === true
                        ? "italic"
                        : "normal"
                      : email.bodyItalic
                      ? "italic"
                      : "normal",
                }}
              >
                {emailText ? emailText : email.signature}
              </p>
            ) : (
              <TextareaAutosize
                value={emailText ? emailText : email.signature}
                minRows={1}
                autoFocus={this.state.isEmailText}
                onBlur={() => this.setState({ isEmailText: false })}
                onChange={(e) => updateEmailText(e.target.value)}
                className="customization-email__email-text border-0 mb-0"
                style={{
                  color: bodyColor ? bodyColor : email.bodyColor,
                  fontSize: bodySize ? bodySize : email.bodySize,
                  background: "transparent",
                  fontFamily: bodyFont ? bodyFont : email.bodyFont,
                  textAlign:
                    emailBodyAlign !== null ? emailBodyAlign : email.bodyAlign,
                  fontWeight:
                    emailBodyBold !== null
                      ? emailBodyBold === true
                        ? 900
                        : 300
                      : email.bodyBold
                      ? 900
                      : 300,
                  fontStyle:
                    emailBodyItalic !== null
                      ? emailBodyItalic === true
                        ? "italic"
                        : "normal"
                      : email.bodyItalic
                      ? "italic"
                      : "normal",
                }}
              />
            )}
          </div>

          <div
            className="customization-email__footer"
            style={{
              background: footerBg ? footerBg : email.footerBackgroundColor,
            }}
          >
            {!this.state.isFooterText ? (
              <p
                onClick={() => this.setState({ isFooterText: true })}
                className="customization-email__footer-text border-0"
                style={{
                  color: buttonTextColor ? buttonTextColor : email.footerColor,
                  fontSize: buttonTextSize ? buttonTextSize : email.footerSize,
                  background: "transparent",
                  fontFamily: footerFont ? footerFont : email.footerFont,
                  fontWeight:
                    emailFooterBold !== null
                      ? emailFooterBold === true
                        ? 900
                        : 300
                      : email.footerBold
                      ? 900
                      : 300,
                  fontStyle:
                    emailFooterItalic !== null
                      ? emailFooterItalic === true
                        ? "italic"
                        : "normal"
                      : email.footerItalic
                      ? "italic"
                      : "normal",
                }}
              >
                {updatedFooterText ? updatedFooterText : email.footer}
              </p>
            ) : (
              <TextareaAutosize
                onBlur={() => this.setState({ isFooterText: false })}
                value={updatedFooterText ? updatedFooterText : email.footer}
                minRows={1}
                autoFocus={this.state.isFooterText}
                onChange={(e) => updateFooterText(e.target.value)}
                className="customization-email__footer-text border-0"
                style={{
                  color: buttonTextColor ? buttonTextColor : email.footerColor,
                  fontSize: buttonTextSize ? buttonTextSize : email.footerSize,
                  background: "transparent",
                  fontFamily: footerFont ? footerFont : email.footerFont,
                  fontWeight:
                    emailFooterBold !== null
                      ? emailFooterBold === true
                        ? 900
                        : 300
                      : email.footerBold
                      ? 900
                      : 300,
                  fontStyle:
                    emailFooterItalic !== null
                      ? emailFooterItalic === true
                        ? "italic"
                        : "normal"
                      : email.footerItalic
                      ? "italic"
                      : "normal",
                }}
              />
            )}

            <p
              className="customization-email__footer-link"
              style={{
                color: buttonTextColor ? buttonTextColor : email.footerColor,
                fontSize: buttonTextSize ? buttonTextSize : email.footerSize,
                background: "transparent",
                fontFamily: footerFont ? footerFont : email.footerFont,
                fontWeight:
                  emailFooterBold !== null
                    ? emailFooterBold === true
                      ? 900
                      : 300
                    : email.footerBold
                    ? 900
                    : 300,
                fontStyle:
                  emailFooterItalic !== null
                    ? emailFooterItalic === true
                      ? "italic"
                      : "normal"
                    : email.footerItalic
                    ? "italic"
                    : "normal",
              }}
            >
              Get your LegalSite
            </p>

            <span className="customization-email__footer-divider" />

            <p
              className="customization-email__footer-info"
              style={{
                color: buttonTextColor ? buttonTextColor : email.footerColor,
                fontSize: buttonTextSize ? buttonTextSize : email.footerSize,
                background: "transparent",
                fontFamily: footerFont ? footerFont : email.footerFont,
                fontWeight:
                  emailFooterBold !== null
                    ? emailFooterBold === true
                      ? 900
                      : 300
                    : email.footerBold
                    ? 900
                    : 300,
                fontStyle:
                  emailFooterItalic !== null
                    ? emailFooterItalic === true
                      ? "italic"
                      : "normal"
                    : email.footerItalic
                    ? "italic"
                    : "normal",
              }}
            >
              www.legalsite.com | LegalSite | All rights reserved{" "}
              {new Date().getFullYear()} | Terms & Conditions | Privacy Policy
            </p>

            <p
              className="customization-email__footer-unsubscribe"
              style={{
                color: buttonTextColor ? buttonTextColor : email.footerColor,
                fontSize: buttonTextSize ? buttonTextSize : email.footerSize,
                background: "transparent",
                fontFamily: footerFont ? footerFont : email.footerFont,
                fontWeight:
                  emailFooterBold !== null
                    ? emailFooterBold === true
                      ? 900
                      : 300
                    : email.footerBold
                    ? 900
                    : 300,
                fontStyle:
                  emailFooterItalic !== null
                    ? emailFooterItalic === true
                      ? "italic"
                      : "normal"
                    : email.footerItalic
                    ? "italic"
                    : "normal",
              }}
            >
              Unsubscribe
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(STEP2EMAIL_QUERY)(Step2Email);
