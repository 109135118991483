import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class SaveBranding extends Component {
  static propTypes = {
    openSaveModal: PropTypes.func.isRequired,
    isSaveModalVisible: PropTypes.bool.isRequired,
    brand: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isSaveModalVisible}
          toggle={this.props.openSaveModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openSaveModal}
            />
            <p className="modal-assign__title mb-3">Confirm branding update</p>
            <p className="modal-assign__text mb-0 mt-0">
              The settings made on branding will overwrite your current styling
              on assets. Click to confirm this action.
            </p>

            <div className="discard-changes__btns">
              <Button
                className="btn--secondary"
                onClick={this.props.openSaveModal}
              >
                Cancel
              </Button>
              <Button
                className="btn--primary"
                onClick={() => {
                  this.props.brand();
                  this.props.openSaveModal();
                }}
              >
                Save and overwrite
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
