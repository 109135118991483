import React, { Component } from "react";
import { Button } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import ReactFilestack from "filestack-react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ADMIN_PUBLISH_DP_PROFILE } from "../../graphql/adminPublishProfileDataProcessor";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class DpProfile extends Component {
  static propTypes = {
    publishProfile: PropTypes.func.isRequired,
    dataProcessor: PropTypes.object,
    refetchData: PropTypes.func,
    addAdminDataProcessor: PropTypes.func,
    createdDPId: PropTypes.string,
    seeNextStep: PropTypes.func,
  };

  state = {
    companyName: null,
    description: null,
    title1: null,
    text1: null,
    title2: null,
    text2: null,
    files: [],
    photo1: [],
    photo2: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  onAddPicture1 = ({ filesUploaded }) => {
    this.setState({ photo1: filesUploaded });
  };

  onAddPicture2 = ({ filesUploaded }) => {
    this.setState({ photo2: filesUploaded });
  };

  publish = () => {
    const { dataProcessor } = this.props;

    const logo = this.state.files.length
      ? this.state.files[0].url
      : !this.state.files.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.logo
      ? dataProcessor.profile.logo
      : null;

    const companyName = this.state.companyName
      ? this.state.companyName
      : dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.companyName
      ? dataProcessor.profile.companyName
      : "";

    const description = this.state.description
      ? this.state.description
      : dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.smallDescription
      ? dataProcessor.profile.smallDescription
      : "";

    const photo1 = this.state.photo1.length
      ? this.state.photo1[0].url
      : !this.state.photo1.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.photo1
      ? dataProcessor.profile.photo1
      : null;

    const title1 = this.state.title1
      ? this.state.title1
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.title1
      ? dataProcessor.profile.title1
      : "";

    const text1 = this.state.text1
      ? this.state.text1
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.text1
      ? dataProcessor.profile.text1
      : "";

    const photo2 = this.state.photo2.length
      ? this.state.photo2[0].url
      : !this.state.photo2.length &&
        dataProcessor &&
        dataProcessor.profile &&
        dataProcessor.profile.photo2
      ? dataProcessor.profile.photo2
      : null;

    const title2 = this.state.title2
      ? this.state.title2
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.title2
      ? dataProcessor.profile.title2
      : "";

    const text2 = this.state.text2
      ? this.state.text2
      : dataProcessor && dataProcessor.profile && dataProcessor.profile.text2
      ? dataProcessor.profile.text2
      : "";

    this.props
      .publishProfile({
        variables: {
          logo,
          smallDescription: description,
          companyName,
          photo1,
          title1,
          text1,
          photo2,
          title2,
          text2,
          dataProcessorId: this.props.createdDPId
            ? this.props.createdDPId
            : dataProcessor.id,
        },
      })
      .then(() => {
        this.props.refetchData();
        this.notify("Profile was published!");
        this.props.seeNextStep();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Profile was not published!");
      });
  };

  notify = (text) => toast(text);

  render() {
    const { files, photo1, photo2 } = this.state;
    const { dataProcessor } = this.props;

    return (
      <div className="dp-profile container">
        <Link
          to="/dashboard"
          className="d-flex align-items-center dp-profile__back"
        >
          <MdArrowLeft size={25} color="#7450c8" />
          &nbsp;Back to Dashboard
        </Link>
        <p className="dp-profile__title">Data Processor index profile</p>
        <p className="dp-profile__text">
          To add a company to the Data Processors index, create their public
          profile.
        </p>

        <div className="d-flex">
          <Button className="btn--primary mr-4" onClick={this.publish}>
            Publish Profile
          </Button>
          <Button
            className="btn--secondary bg-transparent"
            onClick={() => {
              this.props.addAdminDataProcessor();
              this.props.refetchData();
            }}
          >
            Cancel
          </Button>
        </div>

        <div className="dp-profile__container">
          <div className="dp-profile__banner">Preview Mode</div>
          <div className="dp-profile__header">
            <div className="position-relative">
              {files.length > 0 || (files.length > 0 && !dataProcessor) ? (
                files.map((file, i) => (
                  <div key={i}>
                    <img src={file.url} alt="avatar" />
                  </div>
                ))
              ) : !files.length &&
                dataProcessor &&
                dataProcessor.profile &&
                dataProcessor.profile.logo ? (
                <div>
                  <img src={dataProcessor.profile.logo} alt="avatar" />
                </div>
              ) : (
                <div className="dp-profile__add-logo">ADD LOGO</div>
              )}

              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: <span></span>,
                  customClass: "dp-profile__add-link",
                }}
                onSuccess={this.onFiles}
              />
            </div>

            <TextareaAutosize
              value={
                this.state.companyName !== null
                  ? this.state.companyName
                  : dataProcessor &&
                    dataProcessor.profile &&
                    dataProcessor.profile.companyName
                  ? dataProcessor.profile.companyName
                  : ""
              }
              minRows={1}
              autoFocus={true}
              className="dp-profile__company-name"
              onChange={(e) => this.setState({ companyName: e.target.value })}
              placeholder="Company name"
            />
          </div>

          <div className="dp-profile__description">
            <TextareaAutosize
              value={
                this.state.description !== null
                  ? this.state.description
                  : dataProcessor &&
                    dataProcessor.profile &&
                    dataProcessor.profile.smallDescription
                  ? dataProcessor.profile.smallDescription
                  : ""
              }
              minRows={1}
              className="dp-profile__description-text"
              onChange={(e) => this.setState({ description: e.target.value })}
              placeholder="Enter a small discription about your company product or service."
            />
          </div>

          <Button className="btn--primary mx-auto d-block">
            Add Data Processor
          </Button>

          <div className="dp-profile__item">
            <div className="dp-profile__item-img">
              <div className="position-relative">
                {photo1.length > 0 || (photo1.length > 0 && !dataProcessor) ? (
                  photo1.map((file, i) => (
                    <div key={i}>
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : !photo1.length &&
                  dataProcessor &&
                  dataProcessor.profile &&
                  dataProcessor.profile.photo1 ? (
                  <div>
                    <img src={dataProcessor.profile.photo1} alt="avatar" />
                  </div>
                ) : (
                  <div className="dp-profile__add-photo">ADD PHOTO</div>
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span></span>,
                    customClass: "dp-profile__add-photo-link",
                  }}
                  onSuccess={this.onAddPicture1}
                />
              </div>
            </div>

            <div className="dp-profile__item-content">
              <TextareaAutosize
                value={
                  this.state.title1 !== null
                    ? this.state.title1
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.title1
                    ? dataProcessor.profile.title1
                    : ""
                }
                minRows={1}
                className="dp-profile__item-title"
                onChange={(e) => this.setState({ title1: e.target.value })}
                placeholder="Write Title 1"
              />

              <TextareaAutosize
                value={
                  this.state.text1 !== null
                    ? this.state.text1
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.text1
                    ? dataProcessor.profile.text1
                    : ""
                }
                minRows={1}
                className="dp-profile__item-text"
                onChange={(e) => this.setState({ text1: e.target.value })}
                placeholder="Write a section about your company product or service."
              />
            </div>
          </div>

          <div className="dp-profile__item mb-0">
            <div className="dp-profile__item-content">
              <TextareaAutosize
                value={
                  this.state.title2 !== null
                    ? this.state.title2
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.title2
                    ? dataProcessor.profile.title2
                    : ""
                }
                minRows={1}
                className="dp-profile__item-title"
                onChange={(e) => this.setState({ title2: e.target.value })}
                placeholder="Write Title 2"
              />

              <TextareaAutosize
                value={
                  this.state.text2 !== null
                    ? this.state.text2
                    : dataProcessor &&
                      dataProcessor.profile &&
                      dataProcessor.profile.text2
                    ? dataProcessor.profile.text2
                    : ""
                }
                minRows={1}
                className="dp-profile__item-text"
                onChange={(e) => this.setState({ text2: e.target.value })}
                placeholder="Write a section about your company product or service."
              />
            </div>

            <div className="dp-profile__item-img">
              <div className="position-relative">
                {photo2.length > 0 || (photo2.length > 0 && !dataProcessor) ? (
                  photo2.map((file, i) => (
                    <div key={i}>
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : !photo2.length &&
                  dataProcessor &&
                  dataProcessor.profile &&
                  dataProcessor.profile.photo2 ? (
                  <div>
                    <img src={dataProcessor.profile.photo2} alt="avatar" />
                  </div>
                ) : (
                  <div className="dp-profile__add-photo">ADD PHOTO</div>
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span></span>,
                    customClass: "dp-profile__add-photo-link",
                  }}
                  onSuccess={this.onAddPicture2}
                />
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(ADMIN_PUBLISH_DP_PROFILE, {
    name: "publishProfile",
  })
)(DpProfile);
