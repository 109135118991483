import gql from "graphql-tag";

export const DATA_PROCESSOR_SETTINGS_QUERY = gql`
  {
    dataProcessorSettings {
      companyName
      id
      email
      website
      published
      category
      show
    }
  }
`;
