import PropTypes from "prop-types";
import React from "react";

const FileEdit = props => (
    <svg
        height={20}
        viewBox='0 0 18 20'
        width={18}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-6---Users" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="2.1--import-data-" transform="translate(-612.000000, -328.000000)" fill={props.color} fillRule="nonzero">
                <g id="Group-4" transform="translate(149.000000, 311.000000)">
                    <g id="Group-11" transform="translate(454.500000, 8.000000)">
                        <g id="Group" transform="translate(8.000000, 6.000000)">
                            <g id="file-edit-alt" transform="translate(0.500000, 3.000000)">
                                <path d="M17.71,14.71 L15.29,12.29 C15.1022334,12.1006873 14.8466375,11.9942017 14.58,11.9942017 C14.3133625,11.9942017 14.0577666,12.1006873 13.87,12.29 L10.29,15.87 C10.1028135,16.0587188 9.99846324,16.314197 10,16.58 L10,19 C10,19.5522847 10.4477153,20 11,20 L13.42,20 C13.685803,20.0015368 13.9412812,19.8971865 14.13,19.71 L17.71,16.13 C17.8993127,15.9422334 18.0057983,15.6866375 18.0057983,15.42 C18.0057983,15.1533625 17.8993127,14.8977666 17.71,14.71 Z M13,18 L12,18 L12,17 L14.58,14.42 L15.58,15.42 L13,18 Z M7,18 L3,18 C2.44771525,18 2,17.5522847 2,17 L2,3 C2,2.44771525 2.44771525,2 3,2 L8,2 L8,5 C8,6.65685425 9.34314575,8 11,8 L14,8 L14,9 C14,9.55228475 14.4477153,10 15,10 C15.5522847,10 16,9.55228475 16,9 L16,7 C16,7 16,7 16,6.94 C15.9895837,6.8481349 15.9694716,6.75763058 15.94,6.67 L15.94,6.58 C15.8919189,6.47717975 15.8277838,6.38266483 15.75,6.3 L9.75,0.3 C9.66733517,0.222216205 9.57282025,0.158081081 9.47,0.11 C9.4401498,0.105760184 9.4098502,0.105760184 9.38,0.11 L9.06,0 L3,0 C1.34314575,-4.4408921e-16 0,1.34314575 0,3 L0,17 C0,18.6568542 1.34314575,20 3,20 L7,20 C7.55228475,20 8,19.5522847 8,19 C8,18.4477153 7.55228475,18 7,18 Z M10,3.41 L12.59,6 L11,6 C10.4477153,6 10,5.55228475 10,5 L10,3.41 Z M5,12 L11,12 C11.5522847,12 12,11.5522847 12,11 C12,10.4477153 11.5522847,10 11,10 L5,10 C4.44771525,10 4,10.4477153 4,11 C4,11.5522847 4.44771525,12 5,12 Z M5,8 L6,8 C6.55228475,8 7,7.55228475 7,7 C7,6.44771525 6.55228475,6 6,6 L5,6 C4.44771525,6 4,6.44771525 4,7 C4,7.55228475 4.44771525,8 5,8 Z M7,14 L5,14 C4.44771525,14 4,14.4477153 4,15 C4,15.5522847 4.44771525,16 5,16 L7,16 C7.55228475,16 8,15.5522847 8,15 C8,14.4477153 7.55228475,14 7,14 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

FileEdit.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default FileEdit;