import React from "react";
import { Col, Row } from "reactstrap";
import UsersIcon from "./../../components/Icons/UsersIcon";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import PropTypes from "prop-types";

function Stats(props) {
  const { account, dashboardStats, usersStats } = props;
  return (
    <div className="info__overview">
      <Row className="">
        <Col md={6} lg={4} className="p-0">
          <div className="overview-card d-flex">
            <div className="overview-card__icon">
              <UsersIcon color={"#7450C8"} />
            </div>

            <div className="overview-card__stats">
              <h3 className="overview-card__numbers text-black">
                {account && account.visitorsCount ? account.visitorsCount : 0}
              </h3>
              <p className="overview-card__text">Visitors to your LegalSite</p>
            </div>
          </div>
        </Col>

        <Col md={6} lg={4} className="p-0">
          <OverviewCard
            stats={dashboardStats.totalRequests}
            text="Total requests"
            color="purple"
            percentage={100}
          />
        </Col>

        <Col md={6} lg={4} className="p-0">
          <OverviewCard
            stats={dashboardStats.newRequests}
            text="New requests"
            color="yellow"
            percentage={
              (100 * dashboardStats.newRequests) / dashboardStats.totalRequests
            }
          />
        </Col>

        <Col md={6} lg={4} className="p-0">
          <OverviewCard
            stats={dashboardStats.solvedRequests}
            text="Solved requests"
            color="aqua"
            percentage={
              (100 * dashboardStats.solvedRequests) /
              dashboardStats.totalRequests
            }
          />
        </Col>

        <Col md={6} lg={4} className="p-0">
          <OverviewCard
            stats={dashboardStats.requestsInProgress}
            text="Requests in progress"
            color="purple"
            percentage={
              (100 * dashboardStats.requestsInProgress) /
              dashboardStats.totalRequests
            }
          />
        </Col>

        <Col md={6} lg={4} className="p-0">
          <OverviewCard
            stats={usersStats.dataSubjects}
            text="Data subjects"
            color="coral"
            percentage={(100 * usersStats.dataSubjects) / usersStats.totalUsers}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Stats;
Stats.propTypes = {
  account: PropTypes.object,
  dashboardStats: PropTypes.shape({
    totalRequests: PropTypes.number,
    newRequests: PropTypes.number,
    solvedRequests: PropTypes.number,
    requestsInProgress: PropTypes.number,
  }),
  usersStats: PropTypes.shape({
    dataSubjects: PropTypes.number,
    totalUsers: PropTypes.number,
  }),
};
