import PropTypes from "prop-types";
import React from "react";

const Palette = props => (
    <svg
        height={21}
        viewBox='0 0 23 21'
        width={23}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="App-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="10.1--Branding---settings" transform="translate(-181.000000, -510.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="color-palette-outline" transform="translate(181.000000, 510.000000)">
                    <path d="M19.467,3.234 C17.3402403,1.12222524 14.4522988,-0.0435493418 11.4555,9.74561144e-14 C5.65651013,-0.0144974747 0.943747475,4.67476013 0.92925,10.47375 C0.914752525,16.2727399 5.60401013,20.9855025 11.403,21 C12.6372131,21.0209921 13.7295505,20.2049772 14.0595,19.0155 C14.2671038,18.1676303 14.0431773,17.2719244 13.461,16.6215 C13.3256018,16.4674047 13.2923526,16.2485647 13.3758729,16.0612084 C13.4593932,15.8738521 13.6443836,15.7522996 13.8495,15.75 L15.582,15.75 C18.9645133,15.7658155 21.7860331,13.1687348 22.05,9.7965 C22.1289457,7.34672271 21.1945468,4.97274185 19.467,3.234 Z M15.624,13.65 L13.8915,13.65 C12.8577913,13.6441467 11.917691,14.2479019 11.4930146,15.1903656 C11.0683382,16.1328293 11.2388333,17.2370215 11.928,18.0075 C12.0618394,18.1425444 12.1102921,18.3403928 12.054,18.522 C12.0015,18.7425 11.76,18.879 11.4345,18.9 C9.0222263,18.8690819 6.73969025,17.8024954 5.16835097,15.9719454 C3.59701169,14.1413955 2.8885714,11.7236093 3.2235,9.3345 C3.8417158,5.23703718 7.32296408,2.18152118 11.466,2.1 L11.55,2.1 C13.9488292,2.06859296 16.2586037,3.00764882 17.955,4.704 C19.279148,6.02897986 20.0005046,7.83996458 19.95,9.7125 C19.7248563,11.9370204 17.8598314,13.6345552 15.624,13.65 Z" id="Shape"></path>
                    <circle id="Oval" cx="11.55" cy="4.725" r="1.575"></circle>
                    <path d="M14.9625,5.46 C14.2094886,5.89498739 13.9515157,6.85794791 14.3862241,7.61112032 C14.8209326,8.36429273 15.7837975,8.62262234 16.5371309,8.18819286 C17.2904642,7.75376337 17.5491504,6.79099421 17.115,6.0375 C16.9063076,5.67530181 16.562189,5.41092307 16.1584481,5.30260236 C15.7547073,5.19428165 15.3244661,5.2509053 14.9625,5.46 Z" id="Path"></path>
                    <path d="M8.1375,5.46 C7.38400579,5.02584956 6.42123663,5.28453577 5.98680714,6.03786913 C5.55237766,6.7912025 5.81070727,7.7540674 6.56387968,8.18877587 C7.31705209,8.62348433 8.28001261,8.36551136 8.715,7.6125 C8.9240947,7.25053391 8.98071835,6.82029269 8.87239764,6.41655185 C8.76407693,6.01281102 8.49969819,5.66869238 8.1375,5.46 Z" id="Path"></path>
                    <path d="M5.418,9.723 C4.88969429,10.079721 4.62850897,10.7197213 4.75637426,11.344227 C4.88423956,11.9687326 5.37593869,12.4545825 6.00192973,12.5749654 C6.62792076,12.6953483 7.26474974,12.426523 7.61512317,11.8939864 C7.96549659,11.3614497 7.96031605,10.6702252 7.602,10.143 C7.36950688,9.79592072 7.0078611,9.55623868 6.59762553,9.47734722 C6.18738997,9.39845577 5.76264142,9.48690813 5.418,9.723 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
);

Palette.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Palette;