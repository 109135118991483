import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  Input,
  Button,
  Popover,
  PopoverBody,
  ButtonGroup,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import { ChromePicker } from "react-color";
import AlignLeft from "react-icons/lib/fa/align-left";
import AlignCenter from "react-icons/lib/fa/align-center";
import AlignRight from "react-icons/lib/fa/align-right";
import Bold from "react-icons/lib/md/format-bold";
import Italic from "react-icons/lib/md/format-italic";

export default class CustomiseText extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    size: PropTypes.number,
    index: PropTypes.number.isRequired,
    colorName: PropTypes.string.isRequired,
    fontSizeName: PropTypes.string.isRequired,
    fontFamilyName: PropTypes.string.isRequired,
    changeColor: PropTypes.func.isRequired,
    changeFontSize: PropTypes.func.isRequired,
    getGoogleFont: PropTypes.func.isRequired,
    getAligned: PropTypes.func,
    getBold: PropTypes.func,
    getItalic: PropTypes.func,
    alignValue: PropTypes.string,
    boldValue: PropTypes.bool,
    italicValue: PropTypes.bool,
    alignName: PropTypes.string,
    boldName: PropTypes.string,
    italicName: PropTypes.string,
    isLegalsite: PropTypes.bool,
    isBranding: PropTypes.bool,
    isEmail: PropTypes.bool,
    hasAlign: PropTypes.bool,
    font: PropTypes.string,
    isNavigation: PropTypes.bool,
  };

  state = {
    popoverOpen: false,
    background: this.props.color,
    fontSize: this.props.size,
    fontFamily: "",
    data: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCSmOrp1cmL-37bidd_br-z8BFnfFa5vlU"
    )
      .then((response) => response.json())
      .then((data) => this.setState({ data }));
  };

  toggle = () => {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  };

  handleColorChange = (color) => {
    this.setState({ background: color.hex });

    this.props.changeColor(this.props.colorName, this.state.background);
  };

  handleChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      [name]: value,
    });

    if (name === "fontSize") {
      this.props.changeFontSize(this.props.fontSizeName, value);
    }

    if (name === "fontFamily") {
      this.props.getGoogleFont(this.props.fontFamilyName, value);
    }
  };

  render() {
    const {
      title,
      size,
      isLegalsite,
      isBranding,
      alignName,
      boldName,
      italicName,
      hasAlign,
    } = this.props;
    const { background, popoverOpen, data } = this.state;

    return (
      <div
        className={`${
          this.props.isLegalsite
            ? "-is-legalsite"
            : this.props.isBranding || this.props.isEmail
            ? "-is-branding"
            : ""
        } ${this.props.isEmail ? "w-100" : ""} d-flex mr-auto customise-text`}
      >
        <p className="customise-text__label">{title}</p>
        <div className="d-flex">
          <Button
            style={{
              backgroundColor: this.props.color ? this.props.color : background,
            }}
            className="customise-text__color"
            id={`colorPicker${this.props.index}`}
            type="button"
          />
          <Popover
            placement="bottom"
            trigger="legacy"
            isOpen={popoverOpen}
            target={`colorPicker${this.props.index}`}
            toggle={this.toggle}
          >
            <PopoverBody>
              <ChromePicker
                color={this.props.color ? this.props.color : background}
                onChangeComplete={this.handleColorChange}
              />
            </PopoverBody>
          </Popover>
          <FormGroup className="position-relative">
            <Input
              type="select"
              onChange={this.handleChange}
              name="fontSize"
              defaultValue={size}
            >
              <option value="default" disabled>
                Size
              </option>
              <option value="40">40px</option>
              <option value="36">36px</option>
              <option value="32">32px</option>
              <option value="28">28px</option>
              <option value="24">24px</option>
              <option value="22">22px</option>
              <option value="18">18px</option>
              <option value="16">16px</option>
              <option value="14">14px</option>
              <option value="12">12px</option>
              <option value="10">10px</option>
              <option value="9">9px</option>
              <option value="8">8px</option>
            </Input>
            <FaCaret className="position-absolute settings__input-icon" />
          </FormGroup>

          {isLegalsite || hasAlign ? (
            <ButtonGroup className="settings__btn-group">
              <Button
                className={`${
                  this.props.alignValue === "left" ? "-is-active" : ""
                }`}
                disabled={this.props.isNavigation ? true : false}
                onClick={() => this.props.getAligned(alignName, "left")}
              >
                <AlignLeft />
              </Button>
              <Button
                className={`${
                  this.props.alignValue === "center" || !this.props.alignValue
                    ? "-is-active"
                    : ""
                }`}
                disabled={this.props.isNavigation ? true : false}
                onClick={() => this.props.getAligned(alignName, "center")}
              >
                <AlignCenter />
              </Button>
              <Button
                className={`${
                  this.props.alignValue === "right" ? "-is-active" : ""
                }`}
                disabled={this.props.isNavigation ? true : false}
                onClick={() => this.props.getAligned(alignName, "right")}
              >
                <AlignRight />
              </Button>
            </ButtonGroup>
          ) : null}

          {isLegalsite || isBranding || this.props.isEmail ? (
            <ButtonGroup className="settings__btn-group">
              <Button
                className={`${this.props.boldValue ? "-is-active" : ""}`}
                onClick={() =>
                  this.props.getBold(boldName, !this.props.boldValue)
                }
              >
                <Bold />
              </Button>
              <Button
                className={`${this.props.italicValue ? "-is-active" : ""}`}
                onClick={() =>
                  this.props.getItalic(italicName, !this.props.italicValue)
                }
              >
                <Italic />
              </Button>
            </ButtonGroup>
          ) : null}
        </div>

        <FormGroup className="position-relative">
          <Input
            className={`customise-text__font ${
              isBranding || this.props.isEmail ? "w-100 -is-branding" : ""
            }`}
            type="select"
            onChange={this.handleChange}
            name="fontFamily"
            defaultValue={this.props.font}
          >
            <option disabled>Font</option>
            <option value={this.props.font}>{this.props.font}</option>
            {data && data.items && data.items.length
              ? data.items.map((item, index) => (
                  <option key={index} value={item.family}>
                    {item.family}
                  </option>
                ))
              : null}
          </Input>
          <FaCaret className="position-absolute settings__input-icon" />
        </FormGroup>
      </div>
    );
  }
}
