import PropTypes from "prop-types";
import React from "react";

const SearchIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Style-Guide---UI-Desktop" transform="translate(-74.000000, -4505.000000)">
                <g id="Icons" transform="translate(72.000000, 4283.000000)">
                    <g id="Group-9" transform="translate(0.000000, 80.000000)">
                        <g id="search" transform="translate(0.000000, 139.000000)">
                            <rect id="button-bg" x="0" y="0" width="24" height="24" rx="2"></rect>
                            <path d="M21.7309154,21.3109154 L18.0209154,17.6309154 C21.0111294,13.9025602 20.5656881,8.48718845 17.0064354,5.29755951 C13.4471827,2.10793057 8.01555512,2.25655928 4.6360572,5.6360572 C1.25655928,9.01555512 1.10793057,14.4471827 4.29755951,18.0064354 C7.48718845,21.5656881 12.9025602,22.0111294 16.6309154,19.0209154 L20.3109154,22.7009154 C20.498682,22.8902281 20.7542779,22.9967137 21.0209154,22.9967137 C21.2875529,22.9967137 21.5431488,22.8902281 21.7309154,22.7009154 C22.1055691,22.3133188 22.1055691,21.698512 21.7309154,21.3109154 Z M11.0209154,19.0209154 C7.15492215,19.0209154 4.0209154,15.8869087 4.0209154,12.0209154 C4.0209154,8.15492215 7.15492215,5.0209154 11.0209154,5.0209154 C14.8869087,5.0209154 18.0209154,8.15492215 18.0209154,12.0209154 C18.0209154,13.8774308 17.2834175,15.6579082 15.9706629,16.9706629 C14.6579082,18.2834175 12.8774308,19.0209154 11.0209154,19.0209154 Z" id="Shape" fill="#969696" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

SearchIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default SearchIcon;