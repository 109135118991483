import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import Img from "../../images/Settings/Avatar.svg";

function DataProcessingItem(props) {
  const { img, title, text, btnText, id, setItemActive, activeItem } = props;
  return (
    <div
      className={`${
        activeItem === id ? "-is-active" : ""
      } data-processing__item`}
      onClick={() => setItemActive(id)}
    >
      {activeItem === id ? (
        <img className="data-processing__item-check" src={Img} alt="check" />
      ) : null}
      <p className="data-processing__item-title">{title}</p>
      <img className="data-processing__item-img" src={img} alt="DP" />
      <p className="data-processing__item-text">{text}</p>
      <Button className="btn--secondary data-processing__item-btn">
        {btnText}
      </Button>
    </div>
  );
}

export default DataProcessingItem;
DataProcessingItem.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  setItemActive: PropTypes.func.isRequired,
  activeItem: PropTypes.number.isRequired,
};
