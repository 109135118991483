import gql from "graphql-tag";

export const TOGGLE_REQUEST_SPAM = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleRequestSpam(input: { id: $id, clientMutationId: $clientMutationId }) {
      errors
      request {
        id
        spam
        status
      }
    }
  }
`;
