import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import "./modals.scss";

export default class DeletePage extends Component {
  static propTypes = {
    showDeleteModal: PropTypes.func.isRequired,
    isDeleteModalVisible: PropTypes.bool.isRequired,
    removePage: PropTypes.func,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isDeleteModalVisible}
          toggle={this.props.showDeleteModal}
          className="modal-delete-page"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showDeleteModal}
            />

            <p className="modal-assign__title mb-5">
              Are you sure you want to delete this page?
            </p>

            <div className="d-flex">
              <Button
                className="btn--secondary publish-legalsite__btn mr-2"
                onClick={this.props.showDeleteModal}
              >
                Cancel
              </Button>

              <Button
                className="btn--primary publish-legalsite__btn"
                onClick={() => {
                  this.props.removePage();
                  this.props.showDeleteModal();
                }}
              >
                Delete
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
