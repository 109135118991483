import gql from "graphql-tag";

export const COMPLIANCE_DP_QUERY = gql`
  query($filter: DataEntityFilterEnum!, $pageNumber: Int!) {
    dataEntities(filter: $filter, pageNumber: $pageNumber) {
      numberOfPages
      dataEntities {
        ... on DataSubject {
          id
          UID
        }
        company
        addedAt
        dpaSigned
        email
        id
        name
      }
    }
    currentDataProcessors(category: ALL) {
      id
      companyName
      email
      dpaStatus
      dataProcessor {
        id
        companyName
        email
        logo
        description
        phoneNumber
        profile {
          id
          logo
          smallDescription
          companyName
        }
      }
    }
  }
`;
