import gql from "graphql-tag";

export const ADD_TEMPLATE_SECTION = gql`
  mutation($templateId: ID!, $title: String!, $text: String!) {
    addSectionToTemplate(
      input: { templateId: $templateId, title: $title, text: $text }
    ) {
      errors
    }
  }
`;
