import React, { Component } from "react";
import PropTypes from "prop-types";
import Plan from "../../components/Icons/Plan";
import { Row, Col, Button, Progress, UncontrolledTooltip } from "reactstrap";
import Check from "../../images/Settings/Check.svg";
import ReactSlider from "react-slider";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { EDIT_DATA_SUBJECTS_QUANTITY } from "../../graphql/editDataSubjectsQuantity";
import { CREATE_FREE_SUBSCRIBTION } from "../../graphql/createFreeSubscription";
import { PLAN_SETTINGS_QUERY } from "../../graphql/planSettings";
import { CREATE_BUSINESS_SUBSCRIPTION } from "../../graphql/createBusinessSubscription";

class PlanSettings extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    account: PropTypes.object,
    data: PropTypes.object,
    addQuantity: PropTypes.func,
    createFreeSubscription: PropTypes.func,
  };

  state = {
    isCard1: true,
    isCard2: false,
    value: 5000,
    cbInstance: window.Chargebee.init({
      site: "legalsite",
    }),
  };

  componentDidMount() {
    this.props.data.refetch();
  }

  handleChange = (value) => {
    this.setState({
      value: value,
    });
  };

  addDataSubjects = () => {
    console.log(this.state.value / 5000);
    this.props
      .addQuantity({
        variables: {
          quantity: this.state.value / 5000,
        },
      })
      .then(({ data }) => {
        window.location.replace(data.editDataSubjectsQuantity.chargebeeUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  createFreePlan = () => {
    this.props
      .createFreeSubscription()
      .then(() => {
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handlePortal = () => {
    this.state.cbInstance.createChargebeePortal().open({
      visit(visit) {
        console.log("portal visit", visit);
      },
    });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  addDataSubjectsSimple = () => {
    window.location.replace(
      `https://legalsite.chargebee.com/hosted_pages/plans/business-edition?customer[email]=${
        this.props.account && this.props.account.email
          ? this.props.account.email
          : ""
      }`
    );
  };

  addDataSubjectsMultiple = () => {
    this.props
      .addQuantity({
        variables: {
          quantity: this.state.value / 5000,
        },
      })
      .then(({ data }) => {
        window.location.replace(data.editDataSubjectsQuantity.chargebeeUrl);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { sectionVisible, showSection, account } = this.props;
    const { value } = this.state;
    const isActive = sectionVisible === "plan" ? true : false;
    const { usersStats } = this.props.data;

    return (
      <div
        className={`${isActive ? "-is-active" : ""} settings__account`}
        // eslint-disable-next-line react/no-string-refs
        ref="plan"
        onClick={
          !isActive
            ? () => {
                showSection("plan");
                this.scrollToSection("plan");
              }
            : () => showSection("plan")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <Plan />
          &nbsp; My Plan details
        </h2>
        <p className={`${isActive ? "mb-5" : ""} settings__text`}>
          Change your payment settings, get invoices or upgrade your plan.
        </p>

        {isActive ? (
          <div>
            <Row className="align-items-center">
              <Col lg={7} sm={12} className="settings__plans-business">
                <span className="settings__plans-badge">
                  <p>MOST POPULAR</p>
                </span>

                <p className="settings__plans-business-title">Business</p>

                <div className="d-flex justify-content-between align-items-center settings__plans-plans">
                  <div className="settings__plans-business-upgrade">
                    <span className="settings__plans-business-upgrade-amount">
                      <sup>$</sup>29
                    </span>
                    <p className="settings__plans-business-upgrade-month">
                      per month
                    </p>

                    <p className="settings__plans-business-upgrade-subjects">
                      Add up to 5.000 <u id="ds9">Data Subjects</u>
                      <UncontrolledTooltip placement="auto" target="ds9">
                        What is a Data Subject? Anybody that you keep personal
                        identifiable information about (e.g. email adress, name,
                        address).
                      </UncontrolledTooltip>
                    </p>

                    <p className="settings__plans-business-upgrade-subjects">
                      + $20/month per 5,000 <u id="ds10">Data Subjects</u>
                      <UncontrolledTooltip placement="auto" target="ds10">
                        What is a Data Subject? Anybody that you keep personal
                        identifiable information about (e.g. email adress, name,
                        address).
                      </UncontrolledTooltip>
                    </p>

                    <Button
                      className="btn--secondary"
                      onClick={
                        account &&
                        account.subscription &&
                        account.subscription.planName === "Free Edition"
                          ? () =>
                              window.location.replace(
                                account && account.subscription
                                  ? account.subscription.upgradeToBusinessUrl
                                  : null
                              )
                          : account && !account.subscription
                          ? () => this.addDataSubjectsSimple()
                          : () => this.handlePortal()
                      }
                    >
                      {account &&
                      account.subscription &&
                      account.subscription.planName === "Free Edition"
                        ? "Upgrade"
                        : account && !account.subscription
                        ? "Choose this plan"
                        : "Manage current plan"}
                    </Button>
                  </div>

                  <div className="settings__plans-business-legal">
                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          Legal Pages
                        </p>
                        <p className="settings__plans-business-secondary">
                          Your LegalSite with all legal pages and pop-ups.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          Privacy Compliance
                        </p>
                        <p className="settings__plans-business-secondary">
                          All compliance features such as DPA, PIA and Breach
                          Incidents.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          Privacy Operations
                        </p>
                        <p className="settings__plans-business-secondary">
                          All privacy operations features to handle user
                          requests with legal inbox.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          1,000 <u id="ds">Data Subjects</u>
                          <UncontrolledTooltip placement="auto" target="ds">
                            What is a Data Subject? Anybody that you keep
                            personal identifiable information about (e.g. email
                            adress, name, address).
                          </UncontrolledTooltip>{" "}
                          <span className="settings__plans-business-secondary">
                            included
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          Customization{" "}
                          <span className="settings__plans-business-secondary">
                            of LegalSite, Pop-ups and E-mails
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex align-items-start mb-4">
                      <img src={Check} alt="check" />
                      <div className="ml-3">
                        <p className="settings__plans-business-main">
                          VIP Support{" "}
                          <span className="settings__plans-business-secondary">
                            jump to the front of the line
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={5} sm={12}>
                <div className="settings__plans-free">
                  <p className="settings__plans-free-title">Free</p>
                  {account &&
                  account.subscription &&
                  account.subscription.planName !== "Free Edition" ? (
                    <a href={account.subscription.downgradeToFreeUrl}>
                      <Button className="btn--secondary mb-3">
                        Downgrade to Free Plan
                      </Button>
                    </a>
                  ) : (
                    <Button
                      className="btn--secondary mb-3"
                      onClick={
                        account && account.subscription
                          ? () => this.handlePortal
                          : () => this.createFreePlan()
                      }
                    >
                      {account && account.subscription
                        ? "Manage current plan"
                        : "Choose this plan"}
                    </Button>
                  )}

                  <div className="d-flex align-items-center mb-4">
                    <img src={Check} alt="check" />
                    <p className="mb-0 ml-3 settings__plans-free-text">
                      Add up to 1,000 <u>Data Subjects</u>
                    </p>
                  </div>

                  <div className="d-flex align-items-center">
                    <img src={Check} alt="check" />
                    <p className="mb-0 ml-3 settings__plans-free-text">
                      Branded LegalSite, e-mails and pop-ups
                    </p>
                  </div>
                </div>
                <div className="settings__plans-free">
                  <p className="settings__plans-free-title">Enterprise</p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://legalsite.co/contact-enterprise.html"
                    className="btn--secondary mb-5 d-flex align-items-center justify-content-center"
                  >
                    Contact us
                  </a>
                  <div className="d-flex align-items-center mb-4">
                    <p className="mb-0 ml-3 settings__plans-free-text">
                      Let’s talk!
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="settings__plans-add">
              <p className="settings__plans-add-title">Add Data Subjects</p>
              <p className="settings__plans-add-current">Current</p>
              <p className="settings__plans-add-text">
                Your current plan has a{" "}
                {account && account.access
                  ? account.access.toLocaleString()
                  : 0}{" "}
                Data Subjects limit and your company has {usersStats.totalUsers}{" "}
                Data Subjects added.
              </p>

              <div className="settings__plans-add-progress">
                <Progress
                  value={
                    (100 *
                      (account && account.users && account.users.length
                        ? account.users.length
                        : 0)) /
                    (account && account.access ? account.access : 0)
                  }
                />
              </div>

              <p className="settings__plans-add-current mt-5">
                Add Data Subjects
              </p>
              <p className="settings__plans-add-text">
                Your current plan has a 1,000 Data Subjects included, add +
                $20/month per 5,000 Data Subjects.
              </p>

              <div className="slider">
                <div className="value">
                  + {value.toLocaleString()} Data Subjects
                </div>
                <ReactSlider
                  min={5000}
                  max={150000}
                  step={5000}
                  value={value}
                  onChange={this.handleChange}
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props) => <div {...props} />}
                />
              </div>

              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <div className="d-flex align-items-center">
                  <span className="settings__plans-add-value">
                    Your total plan costs:{" "}
                  </span>
                  <span className="settings__plans-add-total-value">
                    <sup>$</sup>
                    {value === 5000
                      ? 29
                      : ((value / 5000) * 20 + 29 - 20).toLocaleString()}
                  </span>
                  <span className="settings__plans-add-monthly">per month</span>
                </div>

                <Button
                  className="btn--primary ml-5"
                  onClick={() =>
                    account && account.subscription
                      ? this.addDataSubjects()
                      : this.addDataSubjectsMultiple()
                  }
                >
                  Add Data Subjects
                </Button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(EDIT_DATA_SUBJECTS_QUANTITY, {
    name: "addQuantity",
  }),
  graphql(PLAN_SETTINGS_QUERY),
  graphql(CREATE_BUSINESS_SUBSCRIPTION, {
    name: "addQuantity",
  }),
  graphql(CREATE_FREE_SUBSCRIBTION, {
    name: "createFreeSubscription",
  })
)(PlanSettings);
