import gql from "graphql-tag";

export const BRAND_LEGALSITE = gql`
  mutation(
    $legalsiteTitleColor: String
    $legalsiteTitleSize: Int
    $legalsiteTitleFont: String
    $legalsiteTitleAlign: String
    $legalsiteTitleItalic: Boolean
    $legalsiteTitleBold: Boolean
    $manageInfoTitleColor: String
    $manageInfoTitleSize: Int
    $manageInfoTitleFont: String
    $manageInfoTitleAlign: String
    $manageInfoTitleItalic: Boolean
    $manageInfoTitleBold: Boolean
    $subtitleTitleColor: String
    $subtitleTitleSize: Int
    $subtitleTitleFont: String
    $subtitleTitleAlign: String
    $subtitleTitleItalic: Boolean
    $subtitleTitleBold: Boolean
    $bodyTextSize: Int
    $bodyTextFont: String
    $bodyTextColor: String
    $bodyTextAlign: String
    $bodyTextBold: Boolean
    $bodyTextItalic: Boolean
    $mainButtonTextSize: Int
    $mainButtonTextFont: String
    $mainButtonTextColor: String
    $mainButtonTextAlign: String
    $mainButtonTextBold: Boolean
    $mainButtonTextItalic: Boolean
    $buttonTextSize: Int
    $buttonTextFont: String
    $buttonTextColor: String
    $buttonTextAlign: String
    $buttonTextBold: Boolean
    $buttonTextItalic: Boolean
    $linksFooterColor: String
    $linksFooterSize: Int
    $linksFooterFont: String
    $linksFooterAlign: String
    $linksFooterItalic: Boolean
    $linksFooterBold: Boolean
    $buttonsAndIconsColor: String
    $popupTextButtonAndLinkColor: String
    $popupTextButtonAndLinkSize: Int
    $headerAndFooterColor: String
    $legalsiteBgColor: String
    $navbarAndCardBgColor: String
    $popupTextButtonAndLinkBold: Boolean
    $popupTextButtonAndLinkItalic: Boolean
    $popupTextButtonAndLinkFont: String
    $emailBgColor: String
    $popupBgColor: String
    $clientMutationId: String
  ) {
    brandLegalsite(
      input: {
        legalsiteTitleColor: $legalsiteTitleColor
        legalsiteTitleSize: $legalsiteTitleSize
        popupTextButtonAndLinkSize: $popupTextButtonAndLinkSize
        popupTextButtonAndLinkBold: $popupTextButtonAndLinkBold
        popupTextButtonAndLinkFont: $popupTextButtonAndLinkFont
        legalsiteTitleFont: $legalsiteTitleFont
        legalsiteTitleAlign: $legalsiteTitleAlign
        legalsiteTitleItalic: $legalsiteTitleItalic
        legalsiteTitleBold: $legalsiteTitleBold
        popupTextButtonAndLinkItalic: $popupTextButtonAndLinkItalic
        manageInfoTitleColor: $manageInfoTitleColor
        manageInfoTitleSize: $manageInfoTitleSize
        manageInfoTitleFont: $manageInfoTitleFont
        manageInfoTitleAlign: $manageInfoTitleAlign
        manageInfoTitleItalic: $manageInfoTitleItalic
        manageInfoTitleBold: $manageInfoTitleBold
        subtitleTitleColor: $subtitleTitleColor
        subtitleTitleSize: $subtitleTitleSize
        subtitleTitleFont: $subtitleTitleFont
        subtitleTitleAlign: $subtitleTitleAlign
        subtitleTitleItalic: $subtitleTitleItalic
        subtitleTitleBold: $subtitleTitleBold
        bodyTextSize: $bodyTextSize
        bodyTextFont: $bodyTextFont
        bodyTextColor: $bodyTextColor
        bodyTextAlign: $bodyTextAlign
        bodyTextBold: $bodyTextBold
        bodyTextItalic: $bodyTextItalic
        mainButtonTextSize: $mainButtonTextSize
        mainButtonTextFont: $mainButtonTextFont
        mainButtonTextColor: $mainButtonTextColor
        mainButtonTextAlign: $mainButtonTextAlign
        mainButtonTextBold: $mainButtonTextBold
        mainButtonTextItalic: $mainButtonTextItalic
        buttonTextSize: $buttonTextSize
        buttonTextFont: $buttonTextFont
        buttonTextColor: $buttonTextColor
        buttonTextAlign: $buttonTextAlign
        buttonTextBold: $buttonTextBold
        popupTextButtonAndLinkColor: $popupTextButtonAndLinkColor
        buttonTextItalic: $buttonTextItalic
        linksFooterColor: $linksFooterColor
        linksFooterSize: $linksFooterSize
        linksFooterFont: $linksFooterFont
        linksFooterAlign: $linksFooterAlign
        linksFooterItalic: $linksFooterItalic
        linksFooterBold: $linksFooterBold
        buttonsAndIconsColor: $buttonsAndIconsColor
        headerAndFooterColor: $headerAndFooterColor
        legalsiteBgColor: $legalsiteBgColor
        navbarAndCardBgColor: $navbarAndCardBgColor
        emailBgColor: $emailBgColor
        popupBgColor: $popupBgColor
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      brandStyling {
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        buttonTextAlign
        buttonTextBold
        buttonTextColor
        buttonTextFont
        buttonTextItalic
        buttonTextSize
        buttonsAndIconsColor
        emailBgColor
        headerAndFooterColor
        legalsiteBgColor
        legalsiteTitleAlign
        legalsiteTitleBold
        legalsiteTitleColor
        legalsiteTitleFont
        legalsiteTitleItalic
        legalsiteTitleSize
        linksFooterAlign
        linksFooterBold
        linksFooterColor
        linksFooterFont
        linksFooterItalic
        linksFooterSize
        mainButtonTextAlign
        mainButtonTextBold
        mainButtonTextColor
        mainButtonTextFont
        mainButtonTextItalic
        mainButtonTextSize
        manageInfoTitleAlign
        manageInfoTitleBold
        manageInfoTitleColor
        manageInfoTitleFont
        manageInfoTitleItalic
        manageInfoTitleSize
        navbarAndCardBgColor
        popupBgColor
        subtitleTitleAlign
        subtitleTitleBold
        subtitleTitleColor
        subtitleTitleFont
        subtitleTitleItalic
        subtitleTitleSize
      }
    }
  }
`;
