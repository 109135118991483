import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import EmailSubjectItem from "./EmailSubjectType";

function Step1Email(props) {
  const { showSteps, getSubjectType } = props;

  return (
    <div className="customization-email customization-email__subjects">
      <h3 className="customization-email__subjects-title ml-0">
        1. Choose your E-mail subject
      </h3>

      <p className="customization-email__subjects-text">
        Here you can see the types of e-mails we send and you can customize
        them.
      </p>

      <p className="customization-email__subjects-label">
        E-mail templates types:
      </p>

      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            title="Request received"
            emailTitle="Request received"
            index={1}
            emailType="DATA_RECEIVED"
            getSubjectType={getSubjectType}
            text="E-mail sent when a user submits a request."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            index={2}
            emailTitle="Data Processing Agreement"
            emailType="DATA_PROCESSING_AGREEMENT_SUBMITTED"
            title="Data Processing Agreement submitted"
            text="E-mail sent with a signed DPA requested by other company."
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailTitle="Data access request"
            emailType="DATA_REQUEST_SOLVED"
            index={3}
            title="Data Request solved"
            text="E-mail sent to solve an user’s request."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            index={4}
            emailType="DATA_PROCESSING_AGREEMENT_SIGNED"
            emailTitle="Signed Data Processing Agreement"
            title="Data Processing Agreement signed"
            text="E-mail sent when both parts signed the DPA."
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            index={5}
            emailType="QUESTION_COMPLAINT_SOLVED"
            getSubjectType={getSubjectType}
            emailTitle="question"
            title="Question/ Complaint solved"
            text="E-mail sent to solve an user’s question or complaint."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            index={6}
            emailType="DATA_PROCESSING_AGREEMENT_SIGNATURE"
            getSubjectType={getSubjectType}
            emailTitle="Data Processing Agreement signature"
            title="Data Processing Agreement signature"
            text="E-mail requesting another company to sign the dpa."
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            emailType="DATA_ERASURE_REQUESTED_DATA_PROCESSOR"
            getSubjectType={getSubjectType}
            index={7}
            emailTitle="Data erasure request"
            title="Data erasure requested"
            text="E-mail requesting a third-party to delete user’s data."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            emailType="DATA_PROCESSING_AGREEMENT_REQUEST"
            getSubjectType={getSubjectType}
            index={8}
            emailTitle="Data Processing Agreement"
            title="Data Processing Agreement request"
            text="E-mail requesting a supplier for their dpa."
          />
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="DATA_BREACH_USERS"
            index={9}
            emailTitle="breach"
            title="Data breach - users"
            text="E-mail sent to notify users about a data breach incident."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="REQUEST_DELAYED"
            index={12}
            emailTitle="Data access request"
            title="Request Delayed"
            text="E-mail to notify that you need to delay a request."
          />
        </Col>
      </Row>

      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="CANT_FIND_DATA"
            emailTitle="Data access request"
            index={13}
            title="Request - Can’t find data"
            text="E-mail to notify that you don’t have the data requested."
          />
        </Col>

        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="UNREASONABLE"
            index={14}
            emailTitle="Data access request"
            title="Request - Unreasonable"
            text="E-mail to a user when his requests is unreasonable."
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="DATA_ACCESS_REQUESTED_DATA_PROCESSOR"
            emailTitle="Data access requested"
            index={19}
            title="Data access request"
            text="E-mail requesting a data processor to send user’s data."
          />
        </Col>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="DONT_SELL_DATA_REQUEST_DATA_SUBJECT"
            emailTitle="Do not sell data request"
            title="Don’t sell data request - Data Subject"
            text="E-mail sent to solve an user’s request."
            index={20}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={6} className="customization-email__subjects-container">
          <EmailSubjectItem
            showEmail={props.showEmail}
            showSteps={showSteps}
            getSubjectType={getSubjectType}
            emailType="DONT_SELL_DATA_REQUEST_DATA_PROCESSOR"
            emailTitle="Do not sell data request"
            title="Don’t sell data request - Data Processor"
            text="E-mail sent to solve an user’s request."
            index={22}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Step1Email;

Step1Email.propTypes = {
  showSteps: PropTypes.func.isRequired,
  getSubjectType: PropTypes.func.isRequired,
  showEmail: PropTypes.func.isRequired,
};
