import React, { Component } from "react";
import FileEdit from "../../components/Icons/FileEdit";
import PageLeftMenuItem from "./PageLeftMenuItem";
import PlusCircle from "../../components/Icons/PlusCircle";
import { ReactSortable } from "react-sortablejs";
import DeleteSection from "../../components/Modals/DeleteSection";
import MdArrow from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

export default class PageLeftMenu extends Component {
  static propTypes = {
    isNewPage: PropTypes.bool,
    pageName: PropTypes.string,
    isNotCurrentVersion: PropTypes.bool,
    isPreview: PropTypes.bool,
    isAdmin: PropTypes.bool,
    pageTemplate: PropTypes.object,
    contentPage: PropTypes.object,
    activePublicationId: PropTypes.number,
    contentIndex: PropTypes.number,
    alerts: PropTypes.array,
    addSection: PropTypes.func,
    dragSection: PropTypes.func,
    deleteSection: PropTypes.func,
    updateTitle: PropTypes.func,
    getItem: PropTypes.func,
    showPage: PropTypes.func,
    isDeleting: PropTypes.bool,
  };

  state = {
    activeItem: 1,
    index: 0,
    otherPublicationItems: [],
    isAdding: false,
    isDeleteModalVisible: false,
    item: null,
  };

  showItem = (index, title, activeItem) => {
    this.setState(
      {
        activeItem: activeItem,
        isNewSection: false,
        index,
      },
      () => this.props.getItem(title, activeItem, index)
    );
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  changeOrder = (newState) => {
    this.setState({
      list: newState,
    });
  };

  getBack = () => {
    window.location.href = "/dashboard";
  };

  render() {
    const {
      isNewPage,
      pageName,
      isNotCurrentVersion,
      isPreview,
      isAdmin,
      pageTemplate,
    } = this.props;
    const { activeItem } = this.state;

    const publicationContent =
      isNotCurrentVersion && !isAdmin
        ? this.props.contentPage.publications.filter(
            (item) => item.id === this.props.activePublicationId
          )
        : isNotCurrentVersion && isAdmin
        ? this.props.pageTemplate.templatePublications.filter(
            (item) => item.id === this.props.activePublicationId
          )
        : null;

    let items = !isAdmin
      ? isNotCurrentVersion
        ? publicationContent[0].sections
        : this.props.contentPage.sections
      : isNotCurrentVersion
      ? publicationContent[0].sections
      : pageTemplate && pageTemplate.sections;

    const currentAlert = !isAdmin
      ? this.props.alerts.filter((item) =>
          item.category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
            ? item.page.id === this.props.contentPage.id
            : null
        )
      : 0;

    if (currentAlert.length > 0) {
      currentAlert.filter((item) =>
        item.category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
          ? (items = item.templatePublication.sections)
          : null
      );
    }

    return (
      <div className={`${isPreview ? "-has-preview" : ""} page-left-menu`}>
        <div className="page-left-menu__header">
          <p
            onClick={
              isAdmin ? () => this.getBack() : () => this.props.showPage()
            }
            className="page-left-menu__back"
          >
            <MdArrow
              size={25}
              className="customize-menu__back position-relative"
              color="#7450c8"
            />
            &nbsp;{isAdmin ? "Back to Dashboard" : "Back to Pages"}
          </p>
          <div className="d-flex align-items-baseline mb-2">
            <FileEdit className="page-left-menu__icon" color={"#333"} />
            <p className="page-left-menu__title">
              {!isAdmin
                ? isNewPage
                  ? pageName
                  : this.props.contentPage.title
                : pageTemplate
                ? pageTemplate.title
                : ""}
            </p>
          </div>
          <p className="page-left-menu__text">
            {!isAdmin
              ? isNewPage
                ? "Here you can edit your page and publish it."
                : this.props.contentPage.description
              : pageTemplate
              ? pageTemplate.description
              : ""}
          </p>
        </div>

        <div className="page-left-menu__content">
          <ReactSortable
            list={items ? items : []}
            setList={(newState) =>
              isNotCurrentVersion ? {} : this.changeOrder(newState)
            }
            onEnd={(e) =>
              isNotCurrentVersion
                ? {}
                : this.props.dragSection(items[e.oldIndex], e.newIndex)
            }
          >
            {items
              ? [...items]
                  .sort(
                    (a, b) => parseFloat(a.position) - parseFloat(b.position)
                  )
                  .map((item, index) => (
                    <PageLeftMenuItem
                      key={index}
                      length={items ? items.length : null}
                      title={item.title}
                      text={item.text}
                      isNotCurrentVersion={this.props.isNotCurrentVersion}
                      activeItem={activeItem}
                      itemIndex={this.props.contentIndex}
                      index={index}
                      item={item}
                      updateTitle={this.props.updateTitle}
                      showItem={this.showItem}
                      isDeleting={this.props.isDeleting}
                      openDeleteModal={this.openDeleteModal}
                      isAdmin={isAdmin}
                      setItem={(item) => this.setState({ item })}
                    />
                  ))
              : null}
          </ReactSortable>

          <DeleteSection
            deleteSection={this.props.deleteSection}
            item={this.state.item}
            isDeleteModalVisible={this.state.isDeleteModalVisible}
            openDeleteModal={this.openDeleteModal}
          />

          {!isNotCurrentVersion ? (
            <div
              className="page-left-menu__add"
              onClick={this.props.addSection}
            >
              <PlusCircle />
              <p className="page-left-menu__add-text">Add section</p>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
