import React from "react";
import Tip from "./Tip";
import MdClose from "react-icons/lib/md/close";
import { Button, Input, Label, FormGroup } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import FontLoader from "./FontLoader";
import PropTypes from "prop-types";

function Step3Popup(props) {
  const {
    popup,
    isInitialTemplate,
    templateActive,
    selectedPage,
    pages,
    cookieBg,
    titleColor,
    titleSize,
    cookieDescription,
    titleFont,
    cookieTitle,
    bodySize,
    bodyColor,
    bodyFont,
    isInitialShape,
    buttonShape,
    buttonColor,
    isInitialSide,
    popupSide,
    buttonSize,
    secondaryButtonColor,
    buttonTextColor,
    buttonFont,
    buttonTextSize,
    footerFont,
    isInitialPosition,
    popupPosition,
    catchEmail,
    isInitialOverlay,
    popupOverlay,
    catchSignature,
    verifyUser,
    btnText,
    secondaryBtnText,
    changeButtonsShape,
    changePopupSide,
    changePopupPosition,
    changePopupOverlay,
    changeCookieTitle,
    changeCookieDescription,
    changeCookieBtn,
    changeCookieSecondarBtn,
    destinationUrl,
    setDestinationUrl,
    setCatchEmail,
    setCatchSignature,
    setVerifyUser,
    isInitialCatch,
    isInitialCatchSignature,
    isInitialVerify,
    legalsite,
    titleBold,
    titleItalic,
    titleAlign,
    emailBodyAlign,
    emailBodyBold,
    emailBodyItalic,
    emailBtnBold,
    emailBtnItalic,
    emailFooterBold,
    emailFooterItalic,
  } = props;

  return (
    <div className="customization-cookie__container">
      <FontLoader
        font1={
          legalsite && legalsite.titleFont ? legalsite.titleFont : "Roboto"
        }
        font2={
          legalsite && legalsite.subtitleFont
            ? legalsite.subtitleFont
            : "Roboto"
        }
        font3={legalsite && legalsite.bodyFont ? legalsite.bodyFont : "Roboto"}
        font4={
          legalsite && legalsite.buttonFont ? legalsite.buttonFont : "Roboto"
        }
      />
      <h3 className="customization-cookie__title mb-4">3. Customize</h3>
      <Tip />
      <div
        className={`${
          (popup.template === "page view" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 4)
            ? "page-view d-flex flex-column"
            : ""
        }`}
      >
        {selectedPage !== "all" &&
        ((popup.template === "page view" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 4)) ? (
          <div className="page-view__container">
            <div
              className="page-view__header"
              style={{
                background: legalsite ? legalsite.background1 : null,
              }}
            >
              <p
                className="page-view__title"
                style={{
                  color: legalsite ? legalsite.titleColor : null,
                  fontFamily: legalsite ? legalsite.titleFont : null,
                }}
              >
                {selectedPage.title}
              </p>
              <p
                className="page-view__subtitle"
                style={{
                  color: legalsite ? legalsite.bodyColor : null,
                  fontFamily: legalsite ? legalsite.bodyFont : null,
                }}
              >
                {selectedPage.description}
              </p>
              <MdClose className="page-view__close" size={15} />
            </div>
            <div
              className="page-view__content"
              style={{ background: legalsite ? legalsite.background2 : null }}
            >
              <div className="page-view__left-menu">
                {selectedPage.sections.map((item) => (
                  <p
                    key={item.id}
                    className="page-view__left-menu-item"
                    style={{
                      color: legalsite ? legalsite.subtitleColor : null,
                      fontSize: legalsite ? legalsite.subtitleSize : null,
                      fontFamily: legalsite
                        ? legalsite.siteSubtitleFont
                        : "Roboto",
                    }}
                  >
                    {item.title}
                  </p>
                ))}
              </div>
              <div className="page-view__publication">
                {selectedPage.sections.map((item) => (
                  <div key={item.id}>
                    <p
                      className="page-view__publication-title"
                      style={{
                        color: legalsite ? legalsite.subtitleColor : null,
                        fontSize: legalsite ? legalsite.subtitleSize : null,
                        fontFamily: legalsite
                          ? legalsite.siteSubtitleFont
                          : "Roboto",
                      }}
                    >
                      {item.title}
                    </p>
                    <p
                      className="page-view__publication-text"
                      style={{
                        color: legalsite ? legalsite.bodyColor : null,
                        fontFamily: legalsite ? legalsite.bodyFont : null,
                      }}
                    >
                      {ReactHtmlParser(item.text)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : selectedPage === "all" &&
          ((popup.template === "page view" && isInitialTemplate) ||
            (!isInitialTemplate && templateActive === 4)) ? (
          <div className="page-view__container">
            {pages.map((page) =>
              page.title === "Cookies Policy" ? (
                <div
                  key={page.id}
                  className="page-view__container position-relative"
                >
                  <MdClose className="page-view__close" size={15} />
                  <div
                    className="page-view__pages"
                    style={{
                      backgroundColor:
                        legalsite && legalsite.navigationBg
                          ? legalsite.navigationBg
                          : null,
                    }}
                  >
                    {pages.map((item) => (
                      <p
                        key={item.id}
                        className="page-view__pages-title"
                        style={{
                          color: legalsite
                            ? legalsite.navigationLinksColor
                            : null,
                          fontSize: legalsite ? legalsite.navigationSize : null,
                          fontFamily: legalsite
                            ? legalsite.navigationFont
                            : null,
                          textAlign: legalsite
                            ? legalsite.navigationLinksAlign
                            : null,
                          fontWeight:
                            legalsite.navigationLinksBold !== null
                              ? legalsite.navigationLinksBold === true
                                ? 900
                                : 300
                              : null,
                          fontStyle:
                            legalsite.navigationLinksItalic !== null
                              ? legalsite.navigationLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        {item.title}
                      </p>
                    ))}
                  </div>
                  <div
                    className="page-view__header"
                    style={{
                      background:
                        legalsite && legalsite.headerBg
                          ? legalsite.headerBg
                          : "#dfe4ee",
                    }}
                  >
                    <p
                      className="page-view__title"
                      style={{
                        color:
                          legalsite && legalsite.headerTitleColor
                            ? legalsite.headerTitleColor
                            : "#333",
                        fontSize:
                          legalsite && legalsite.headerTitleSize
                            ? legalsite.headerTitleSize
                            : 56,
                        fontFamily:
                          legalsite && legalsite.headerTitleFont
                            ? legalsite.headerTitleFont
                            : null,
                        textAlign:
                          legalsite && legalsite.headerTitleAlign !== null
                            ? legalsite.headerTitleAlign
                            : null,
                        fontWeight:
                          legalsite && legalsite.headerTitleBold !== null
                            ? legalsite.headerTitleBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          legalsite && legalsite.headerTitleItalic !== null
                            ? legalsite.headerTitleItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      {page.title}
                    </p>
                    <p
                      className="page-view__subtitle"
                      style={{
                        color:
                          legalsite && legalsite.headerBodyColor
                            ? legalsite.headerBodyColor
                            : "#969696",
                        fontSize:
                          legalsite && legalsite.headerBodySize
                            ? legalsite.headerBodySize
                            : 16,
                        fontFamily:
                          legalsite && legalsite.headerBodyFont
                            ? legalsite.headerBodyFont
                            : null,
                        textAlign:
                          legalsite && legalsite.headerBodyAlign !== null
                            ? legalsite.headerBodyAlign
                            : null,
                        fontWeight:
                          legalsite && legalsite.headerBodyBold !== null
                            ? legalsite.headerBodyBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          legalsite && legalsite.headerBodyItalic !== null
                            ? legalsite.headerBodyItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      {page.description}
                    </p>
                  </div>
                  <div
                    className="page-view__content"
                    style={{
                      background: legalsite ? legalsite.headerBg : null,
                    }}
                  >
                    <div className="page-view__left-menu">
                      {page.sections.map((item) => (
                        <p
                          key={item.id}
                          className="page-view__left-menu-item"
                          style={{
                            color:
                              legalsite && legalsite.cardsTitleColor
                                ? legalsite.cardsTitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.cardsTitleSize
                                ? legalsite.cardsTitleSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.cardsTitleFont
                                ? legalsite.cardsTitleFont
                                : null,
                            textAlign:
                              legalsite && legalsite.cardsTitleAlign
                                ? legalsite.cardsTitleAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.cardsTitleBold
                                ? legalsite.cardsTitleBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.cardsTitleItalic
                                ? legalsite.cardsTitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {item.title}
                        </p>
                      ))}
                    </div>
                    <div className="page-view__publication">
                      {page.sections.map((item) => (
                        <div key={item.id}>
                          <p
                            className="page-view__publication-title"
                            style={{
                              color:
                                legalsite && legalsite.cardsTitleColor
                                  ? legalsite.cardsTitleColor
                                  : "#333",
                              fontSize:
                                legalsite && legalsite.cardsTitleSize
                                  ? legalsite.cardsTitleSize
                                  : null,
                              fontFamily:
                                legalsite && legalsite.cardsTitleFont
                                  ? legalsite.cardsTitleFont
                                  : null,
                              textAlign:
                                legalsite && legalsite.cardsTitleAlign
                                  ? legalsite.cardsTitleAlign
                                  : null,
                              fontWeight:
                                legalsite && legalsite.cardsTitleBold
                                  ? legalsite.cardsTitleBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                legalsite && legalsite.cardsTitleItalic
                                  ? legalsite.cardsTitleItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            {item.title}
                          </p>
                          <p
                            className="page-view__publication-text"
                            style={{
                              color:
                                legalsite && legalsite.cardsBodyColor
                                  ? legalsite.cardsBodyColor
                                  : "#969696",
                              fontSize:
                                legalsite && legalsite.cardsBodySize
                                  ? legalsite.cardsBodySize
                                  : null,
                              fontFamily:
                                legalsite && legalsite.cardsBodyFont
                                  ? legalsite.cardsBodyFont
                                  : null,
                              // textAlign:
                              //   legalsite && legalsite.cardsBodyAlign
                              //     ? legalsite.cardsBodyAlign
                              //     : null,
                              // fontWeight:
                              //   legalsite && legalsite.cardsBodyBold
                              //     ? legalsite.cardsBodyBold === true
                              //       ? 900
                              //       : 500
                              //     : 500,
                              // fontStyle:
                              //   legalsite && legalsite.cardsBodyItalic
                              //     ? legalsite.cardsBodyItalic === true
                              //       ? 'italic'
                              //       : 'normal'
                              //     : 'normal',
                            }}
                          >
                            {ReactHtmlParser(item.text)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        ) : null}
        <div
          style={{
            background: cookieBg ? cookieBg : popup.backgroundColor,
          }}
          className={`${
            (popup.template === "squared popup" && isInitialTemplate) ||
            (!isInitialTemplate && templateActive === 1)
              ? "customization-cookie__small"
              : (popup.template === "vertical popup" && isInitialTemplate) ||
                (!isInitialTemplate && templateActive === 2)
              ? "customization-cookie__vertical mb-4 mt-4"
              : (popup.template === "overlay popup" && isInitialTemplate) ||
                (!isInitialTemplate && templateActive === 3)
              ? "customization-cookie__overlay mb-4 mt-4"
              : (popup.template === "page view" && isInitialTemplate) ||
                (!isInitialTemplate && templateActive === 4)
              ? "customization-cookie__page-view pb-4"
              : null
          }`}
        >
          <p
            style={{
              color: titleColor ? titleColor : popup.titleColor,
              fontSize: titleSize ? titleSize : popup.titleSize,
              fontFamily: titleFont ? titleFont : popup.titleFont,
              textAlign: titleAlign !== null ? titleAlign : popup.titleAlign,
              fontStyle:
                titleItalic !== null
                  ? titleItalic === true
                    ? "italic"
                    : "normal"
                  : popup.titleItalic
                  ? "italic"
                  : "normal",
              fontWeight:
                titleBold !== null
                  ? titleBold === true
                    ? 900
                    : 300
                  : popup.titleBold
                  ? 900
                  : 300,
            }}
            className="customization-cookie__popup-title w-100"
          >
            {cookieTitle !== null
              ? cookieTitle
              : popup.title
              ? popup.title
              : "Cookies preferences"}
          </p>

          <p
            style={{
              color: bodyColor ? bodyColor : popup.bodyColor,
              fontSize: bodySize ? bodySize : popup.bodySize,
              fontFamily: bodyFont ? bodyFont : popup.bodyFont,
              fontStyle:
                emailBodyItalic !== null
                  ? emailBodyItalic === true
                    ? "italic"
                    : "normal"
                  : popup.bodyItalic
                  ? "italic"
                  : "normal",
              textAlign:
                emailBodyAlign !== null ? emailBodyAlign : popup.bodyAlign,
              fontWeight:
                emailBodyBold !== null
                  ? emailBodyBold === true
                    ? 900
                    : 300
                  : popup.bodyBold
                  ? 900
                  : 300,
            }}
            className="customization-cookie__popup-text"
          >
            {cookieDescription !== null
              ? cookieDescription
              : popup.body
              ? popup.body
              : "This website uses cookies. Click on the button bellow to consent to all cookies in accordance with our Privacy Policy or customize your preferences at any time on our legal page."}
          </p>

          {(popup.catchEmail === true && isInitialCatch) ||
          (!isInitialCatch && catchEmail) ? (
            <FormGroup className="w-100">
              {(popup.template === "squared popup" && isInitialTemplate) ||
              (!isInitialTemplate && templateActive === 1) ? (
                <Label className="customization-cookie__label mb-0">
                  E-mail:
                </Label>
              ) : null}
              <Input
                type="text"
                placeholder="Write your email"
                className="bg-white w-100"
                readOnly={true}
              />
            </FormGroup>
          ) : null}

          <div className="d-flex flex-wrap align-items-center">
            <Button
              className={`${
                (popup.buttonShapes === "rounded" && isInitialShape) ||
                (!isInitialShape && buttonShape === "rounded")
                  ? "btn-round"
                  : ""
              } customization-cookie__popup-accept`}
              style={{
                color: buttonColor ? buttonColor : popup.buttonColor,
                fontSize: buttonSize ? buttonSize : parseInt(popup.buttonSize),
                backgroundColor: secondaryButtonColor
                  ? secondaryButtonColor
                  : popup.buttonBgColor
                  ? popup.buttonBgColor
                  : "#7450C8",
                fontFamily: buttonFont ? buttonFont : popup.buttonFont,
                fontStyle:
                  emailBtnItalic !== null
                    ? emailBtnItalic === true
                      ? "italic"
                      : "normal"
                    : popup.buttonItalic
                    ? "italic"
                    : "normal",
                fontWeight:
                  emailBtnBold !== null
                    ? emailBtnBold === true
                      ? 900
                      : 300
                    : popup.buttonBold
                    ? 900
                    : 300,
              }}
            >
              {btnText !== null
                ? btnText
                : popup.buttonText
                ? popup.buttonText
                : "ACCEPT"}
            </Button>
            <Button
              className="customization-cookie__popup-options"
              style={{
                color: buttonTextColor
                  ? buttonTextColor
                  : popup.secondaryButtonColor
                  ? popup.secondaryButtonColor
                  : "#7450C8",
                fontSize: buttonTextSize
                  ? buttonTextSize
                  : parseInt(popup.secondaryButtonSize),
                fontFamily: footerFont ? footerFont : popup.secondaryButtonFont,
                fontStyle:
                  emailFooterItalic !== null
                    ? emailFooterItalic === true
                      ? "italic"
                      : "normal"
                    : // : popup.buttonItalic
                      // ? 'italic'
                      "normal",
                fontWeight:
                  emailFooterBold !== null
                    ? emailFooterBold === true
                      ? 900
                      : 300
                    : // : popup.secondaryButtonBold
                      // ? 900
                      300,
              }}
            >
              {secondaryBtnText !== null
                ? secondaryBtnText
                : popup.secondaryButtonText
                ? popup.secondaryButtonText
                : popup.title === "Cookies preferences"
                ? "OPTIONS"
                : "SEE LEGALSITE"}
            </Button>
          </div>

          <span
            style={{
              color: buttonTextColor
                ? buttonTextColor
                : popup.secondaryButtonColor,
              fontSize: buttonTextSize
                ? buttonTextSize
                : parseInt(popup.secondaryButtonSize),
              fontFamily: footerFont ? footerFont : popup.secondaryButtonFont,
              fontStyle:
                emailFooterItalic !== null
                  ? emailFooterItalic === true
                    ? "italic"
                    : "normal"
                  : // : popup.buttonItalic
                    // ? 'italic'
                    "normal",
              fontWeight:
                emailFooterBold !== null
                  ? emailFooterBold === true
                    ? 900
                    : 300
                  : // : popup.secondaryButtonBold
                    // ? 900
                    300,
            }}
            className="customization-cookie__get"
          >
            Get your LegalSite
          </span>
        </div>
      </div>

      <div className="d-flex customization-cookie__check-container">
        <span className="customization-cookie__label">Button's shape:</span>
        <span className="customization-cookie__check-item d-flex align-items-center">
          <span
            className={`${
              (popup.buttonShapes === "squared" && isInitialShape) ||
              (!isInitialShape && buttonShape === "squared")
                ? "-is-active"
                : ""
            } check mr-2`}
            onClick={() => changeButtonsShape("squared")}
          />{" "}
          Squared
        </span>
        <span className="customization-cookie__check-item d-flex align-items-center">
          <span
            className={`${
              (popup.buttonShapes === "rounded" && isInitialShape) ||
              (!isInitialShape && buttonShape === "rounded")
                ? "-is-active"
                : ""
            } check mr-2`}
            onClick={() => changeButtonsShape("rounded")}
          />{" "}
          Rounded
        </span>
      </div>

      {(popup.template === "squared popup" && isInitialTemplate) ||
      (!isInitialTemplate && templateActive === 1) ? (
        <div className="d-flex customization-cookie__check-container">
          <span className="customization-cookie__label">Popup side:</span>
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span className="customization-cookie__check-item d-flex align-items-center">
              <span
                className={`${
                  (popup.popupSide === "left" && isInitialSide) ||
                  (!isInitialSide && popupSide === "left")
                    ? "-is-active"
                    : ""
                } check mr-2`}
                onClick={() => changePopupSide("left")}
              />{" "}
              Left
            </span>
            <span
              className={`${
                (popup.popupSide === "right" && isInitialSide) ||
                (!isInitialSide && popupSide === "right")
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => changePopupSide("right")}
            />{" "}
            Right
          </span>
        </div>
      ) : (popup.template === "vertical popup" && isInitialTemplate) ||
        (!isInitialTemplate && templateActive === 2) ? (
        <div className="d-flex customization-cookie__check-container">
          <span className="customization-cookie__label">Popup position:</span>
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.popupPosition === "bottom" && isInitialPosition) ||
                (!isInitialPosition && popupPosition === "bottom")
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => changePopupPosition("bottom")}
            />{" "}
            Bottom
          </span>
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.popupPosition === "top" && isInitialPosition) ||
                (!isInitialPosition && popupPosition === "top")
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => changePopupPosition("top")}
            />{" "}
            Top
          </span>
        </div>
      ) : (
        <div className="d-flex customization-cookie__check-container flex-column">
          <span className="customization-cookie__label w-100 mb-3">
            Filter overlay on background screen:
          </span>
          <div className="d-flex">
            <span className="customization-cookie__check-item d-flex align-items-center">
              <span
                className={`${
                  (popup.overlay === "light" && isInitialOverlay) ||
                  (!isInitialOverlay && popupOverlay === "light")
                    ? "-is-active"
                    : ""
                } check mr-2 ml-0`}
                onClick={() => changePopupOverlay("light")}
              />{" "}
              <span className="customization-cookie__bg-screen -white" />
            </span>
            <span className="customization-cookie__check-item d-flex align-items-center">
              <span
                className={`${
                  (popup.overlay === "dark" && isInitialOverlay) ||
                  (!isInitialOverlay && popupOverlay === "dark")
                    ? "-is-active"
                    : ""
                } check mr-2 ml-0`}
                onClick={() => changePopupOverlay("dark")}
              />{" "}
              <span className="customization-cookie__bg-screen -gray" />
            </span>
          </div>
        </div>
      )}

      <div>
        <span className="customization-cookie__label">Title text:</span>
        <Input
          className="customization-cookie__input"
          type="text"
          readOnly={
            popup.title === "Cookies preferences" ||
            popup.title === "Terms & Conditions" ||
            popup.title === "Legal preferences" ||
            popup.title === "Privacy Policy" ||
            popup.title === "Data processing agreement"
          }
          value={
            cookieTitle !== null
              ? cookieTitle
              : popup.title === "Cookies preferences"
              ? "Cookies preferences"
              : popup.title
          }
          onChange={(title) => changeCookieTitle(title)}
        />
      </div>

      <div>
        <span className="customization-cookie__label">Body text:</span>
        <Input
          className="customization-cookie__textarea"
          type="textarea"
          value={
            cookieDescription !== null
              ? cookieDescription
              : popup.body
              ? popup.body
              : "This website uses cookies. Click on the button bellow to consent to all cookies in accordance with our Privacy Policy or customize your preferences at any time on our legal page."
          }
          onChange={(description) => changeCookieDescription(description)}
        />
      </div>

      <div className="d-flex flex-wrap">
        <div className="mr-5">
          <span className="customization-cookie__label">Text main button:</span>
          <Input
            className="customization-cookie__input"
            type="text"
            value={
              btnText !== null
                ? btnText
                : popup.buttonText
                ? popup.buttonText
                : "ACCEPT"
            }
            onChange={(text) => changeCookieBtn(text)}
          />
        </div>
        <div>
          <span className="customization-cookie__label">
            Text secondary button:
          </span>
          <Input
            className="customization-cookie__input"
            type="text"
            value={
              secondaryBtnText !== null
                ? secondaryBtnText
                : popup.secondaryButtonText
                ? popup.secondaryButtonText
                : popup.title === "Cookies preferences"
                ? "OPTIONS"
                : "SEE LEGALSITE"
            }
            onChange={(text) => changeCookieSecondarBtn(text)}
          />
        </div>
      </div>

      <div>
        <span className="customization-cookie__label">
          Destination page when user clicks ‘Accept’:
        </span>
        <Input
          className="customization-cookie__input-large"
          type="text"
          placeholder="Paste the URL"
          value={
            destinationUrl !== null
              ? destinationUrl
              : popup.destinationPage
              ? popup.destinationPage
              : ""
          }
          onChange={(e) => setDestinationUrl(e.target.value)}
        />
      </div>

      <div className="d-flex flex-column customization-cookie__check-container">
        <span className="customization-cookie__label w-100">
          Catch your user’s e-mail on the pop-up?
        </span>
        <div className="d-flex mt-2">
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.catchEmail === true && isInitialCatch) ||
                (!isInitialCatch && catchEmail)
                  ? "-is-active"
                  : ""
              } check mr-2 ml-0`}
              onClick={() => setCatchEmail(true)}
            />{" "}
            Yes
          </span>

          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.catchEmail === false && isInitialCatch) ||
                (!isInitialCatch && !catchEmail)
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => setCatchEmail(false)}
            />{" "}
            No
          </span>
        </div>
      </div>

      <div className="d-flex flex-column customization-cookie__check-container">
        <span className="customization-cookie__label w-100">
          Catch your user’s signature on the pop-up?
        </span>
        <div className="d-flex mt-2">
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.catchSignature === true && isInitialCatchSignature) ||
                (!isInitialCatchSignature && catchSignature)
                  ? "-is-active"
                  : ""
              } check mr-2 ml-0`}
              onClick={() => {
                setCatchSignature(true);
                setCatchEmail(true);
              }}
            />{" "}
            Yes
          </span>
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.catchSignature === false && isInitialCatchSignature) ||
                (!isInitialCatchSignature && !catchSignature)
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => setCatchSignature(false)}
            />{" "}
            No
          </span>
        </div>
      </div>

      <div className="d-flex flex-column customization-cookie__check-container">
        <span className="customization-cookie__label w-100">
          Verify user when accepting?
        </span>
        <span className="customization-cookie__label-secondary w-100">
          The verification step would be an e-mail sent to the user with a link
          where he can click to give consent.
        </span>
        <div className="d-flex mt-2">
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.verifyUser === true && isInitialVerify) ||
                (!isInitialVerify && verifyUser)
                  ? "-is-active"
                  : ""
              } check mr-2 ml-0`}
              onClick={() => {
                setVerifyUser(true);
                setCatchEmail(true);
              }}
            />{" "}
            Yes
          </span>
          <span className="customization-cookie__check-item d-flex align-items-center">
            <span
              className={`${
                (popup.verifyUser === false && isInitialVerify) ||
                (!isInitialVerify && !verifyUser)
                  ? "-is-active"
                  : ""
              } check mr-2`}
              onClick={() => setVerifyUser(false)}
            />{" "}
            No
          </span>
        </div>
      </div>
    </div>
  );
}

export default Step3Popup;
Step3Popup.propTypes = {
  popup: PropTypes.string.isRequired,
  isInitialTemplate: PropTypes.bool.isRequired,
  templateActive: PropTypes.bool.isRequired,
  selectedPage: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  cookieBg: PropTypes.string.isRequired,
  titleColor: PropTypes.string.isRequired,
  titleSize: PropTypes.number.isRequired,
  cookieDescription: PropTypes.string.isRequired,
  titleFont: PropTypes.string.isRequired,
  cookieTitle: PropTypes.string.isRequired,
  bodySize: PropTypes.number.isRequired,
  bodyColor: PropTypes.string.isRequired,
  bodyFont: PropTypes.string.isRequired,
  isInitialShape: PropTypes.bool.isRequired,
  buttonShape: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  isInitialSide: PropTypes.bool.isRequired,
  popupSide: PropTypes.string.isRequired,
  buttonSize: PropTypes.string.isRequired,
  secondaryButtonColor: PropTypes.string.isRequired,
  buttonTextColor: PropTypes.string.isRequired,
  buttonFont: PropTypes.string.isRequired,
  buttonTextSize: PropTypes.number.isRequired,
  footerFont: PropTypes.string.isRequired,
  isInitialPosition: PropTypes.bool.isRequired,
  popupPosition: PropTypes.string.isRequired,
  catchEmail: PropTypes.bool.isRequired,
  isInitialOverlay: PropTypes.bool.isRequired,
  popupOverlay: PropTypes.string.isRequired,
  catchSignature: PropTypes.bool.isRequired,
  verifyUser: PropTypes.bool.isRequired,
  btnText: PropTypes.string.isRequired,
  secondaryBtnText: PropTypes.string.isRequired,
  changeButtonsShape: PropTypes.func.isRequired,
  changePopupSide: PropTypes.func.isRequired,
  changePopupPosition: PropTypes.func.isRequired,
  changePopupOverlay: PropTypes.func.isRequired,
  changeCookieTitle: PropTypes.func.isRequired,
  changeCookieDescription: PropTypes.func.isRequired,
  changeCookieBtn: PropTypes.func.isRequired,
  changeCookieSecondarBtn: PropTypes.func.isRequired,
  destinationUrl: PropTypes.string.isRequired,
  setDestinationUrl: PropTypes.func.isRequired,
  setCatchEmail: PropTypes.func.isRequired,
  setCatchSignature: PropTypes.func.isRequired,
  setVerifyUser: PropTypes.func.isRequired,
  isInitialCatch: PropTypes.bool.isRequired,
  isInitialCatchSignature: PropTypes.bool.isRequired,
  isInitialVerify: PropTypes.bool.isRequired,
  legalsite: PropTypes.string.isRequired,
  titleBold: PropTypes.bool.isRequired,
  titleItalic: PropTypes.bool.isRequired,
  titleAlign: PropTypes.string.isRequired,
  emailBodyAlign: PropTypes.string.isRequired,
  emailBodyBold: PropTypes.bool.isRequired,
  emailBodyItalic: PropTypes.bool.isRequired,
  emailBtnBold: PropTypes.bool.isRequired,
  emailBtnItalic: PropTypes.bool.isRequired,
  emailFooterBold: PropTypes.bool.isRequired,
  emailFooterItalic: PropTypes.bool.isRequired,
};
