import React, { Component } from "react";
import {
  Button,
  Form,
  Input,
  FormGroup,
  FormFeedback,
  Alert,
} from "reactstrap";
import logo from "../../images/Login/logo purple and white.svg";
import { Redirect } from "react-router-dom";
import { SIGNUP } from "../../graphql/signup";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import SyncLoader from "react-spinners/SyncLoader";
import { AUTH_TOKEN } from "../../constants";
import PropTypes from "prop-types";
import "./sign-in.scss";

class SignUp extends Component {
  static propTypes = {
    signup: PropTypes.func.isRequired,
  };

  state = {
    formErrors: {},
    isLoading: false,
    errorMsg: null,
    showPlan: false,
  };

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  createAccount = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("fullName"),
      email = formData.get("email"),
      password1 = formData.get("password1"),
      password2 = formData.get("password2");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!password1) {
      hasFormErrors = true;
      formErrors.password1 = true;
    }

    if (!password2) {
      hasFormErrors = true;
      formErrors.password2 = true;
    }

    if (password1 !== password2) {
      hasFormErrors = true;
      formErrors.confirm = true;
    }

    if (password1.length < 6 || password2.length < 6) {
      hasFormErrors = true;
      formErrors.length = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    this.props
      .signup({
        variables: {
          email: email,
          password: password1,
          name: name,
        },
      })
      .then(({ data }) => {
        const token = data.createAccount.authenticationToken;
        this.setUserToken(token);
      })
      .then(() => {
        var xhr = new XMLHttpRequest();
        var url =
          "https://api.hsforms.com/submissions/v3/integration/submit/6250848/0fec4222-4dfa-45c3-b2aa-9c6cd5c256b3";

        var data = {
          submittedAt: new Date(),
          fields: [
            {
              name: "firstname",
              value: name,
            },
            {
              name: "email",
              value: email,
            },
            {
              name: "account_type",
              value: "Account Owner",
            },
          ],
          context: {
            hutk: this.getCookie("hubspotutk"),
            pageUri: "https://app.legalsite.co/signup",
            pageName: "Signup",
          },
        };

        var final_data = JSON.stringify(data);

        xhr.open("POST", url);

        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4 && xhr.status == 200) {
            console.log(xhr.responseText);
          } else if (xhr.readyState == 4 && xhr.status == 400) {
            console.log(xhr.responseText);
          } else if (xhr.readyState == 4 && xhr.status == 403) {
            console.log(xhr.responseText);
          } else if (xhr.readyState == 4 && xhr.status == 404) {
            console.log(xhr.responseText);
          }
        };

        xhr.send(final_data);
      })
      .then(() => {
        this.setState({
          isLoading: false,
          showPlan: true,
        });
      })
      .catch((e) => {
        console.log(e?.graphQLErrors?.[0]?.message || "Something went wrong.");
        console.log(e);
        this.setState({
          isLoading: false,
          errorMsg: e?.graphQLErrors?.[0]?.message || "Something went wrong.",
        });
      });
  };

  showLegalSite = () => {
    window.open("https://startupz.legalsites.app", "_blank");
  };

  setUserToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  showCreate = () => {
    window.location.href = "/signin";
  };

  render() {
    const { isLoading, errorMsg } = this.state;

    if (this.state.showPlan === true) {
      return <Redirect to={{ pathname: "/select-plan" }} />;
    }

    return (
      <div className="login">
        <div className="login__banner">
          <div className="login__banner-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="login__form">
          <div className="login__form-container h-100">
            {errorMsg ? (
              <Alert className="login__error" color="danger">
                {errorMsg}
              </Alert>
            ) : null}
            {isLoading ? (
              <SyncLoader
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 30px;
                  position: relative;
                `}
                sizeUnit={"px"}
                size={10}
                color={"#7450c8"}
                loading={true}
              />
            ) : (
              <div className="h-100 d-flex flex-column justify-content-around">
                <div>
                  <h2 className="login__form-title">Signup</h2>

                  <p className="login__form-create mb-5">
                    Already have an account?{" "}
                    <span className="create" onClick={this.showCreate}>
                      Log in
                    </span>
                  </p>

                  <Form onSubmit={this.createAccount}>
                    <FormGroup className="position-relative mb-3">
                      <Input
                        type="text"
                        name="fullName"
                        className="mb-0"
                        invalid={this.state.formErrors.name}
                        placeholder="Full name"
                      />
                      <FormFeedback>Please provide your full name</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type="email"
                        name="email"
                        className="mb-0"
                        invalid={this.state.formErrors.email}
                        placeholder="E-mail"
                      />
                      <FormFeedback>Please provide a valid email</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type="password"
                        name="password1"
                        className="mb-0"
                        invalid={this.state.formErrors.password1}
                        placeholder="Password"
                      />
                      <FormFeedback>Please provide a password</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type="password"
                        name="password2"
                        invalid={
                          this.state.formErrors.password2 ||
                          this.state.formErrors.confirm ||
                          this.state.formErrors.length
                        }
                        className="mb-0"
                        placeholder="Confirm password"
                      />

                      <FormFeedback>
                        {this.state.formErrors.password2
                          ? "Please confirm your password"
                          : this.state.formErrors.confirm
                          ? "Passwords don't match"
                          : this.state.formErrors.length
                          ? "Your password must be minimum 6 characters long"
                          : ""}
                      </FormFeedback>
                    </FormGroup>

                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn--primary login__form-log"
                        type="submit"
                      >
                        Next step
                      </Button>
                    </div>
                  </Form>
                </div>

                <div className="login__terms">
                  <u
                    className="login__terms-link"
                    onClick={() =>
                      window.open(
                        "//startupz.legalsites.app/page?page=TermsConditions",
                        "_blank"
                      )
                    }
                  >
                    Terms and Conditions
                  </u>{" "}
                  apply. Signing up indicates that you’ve read and agree to our
                  <u
                    className="login__terms-link"
                    onClick={() =>
                      window.open(
                        "//startupz.legalsites.app/page?page=TermsConditions",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Terms{" "}
                  </u>
                  and have read our
                  <u
                    className="login__terms-link"
                    onClick={() =>
                      window.open(
                        "//startupz.legalsites.app/page?page=CookiesPolicy",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Cookie Policy
                  </u>{" "}
                  and{" "}
                  <u
                    className="login__terms-link"
                    onClick={() =>
                      window.open(
                        "//startupz.legalsites.app/page?page=PrivacyPolicy",
                        "_blank"
                      )
                    }
                  >
                    Privacy Policy.
                  </u>{" "}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(SIGNUP, {
    name: "signup",
  })
)(SignUp);
