import React from "react";
import FileCheck from "../../components/Icons/FileCheck";
import { Button } from "reactstrap";
import UsersIcon from "../../components/Icons/UsersIcon";
import PropTypes from "prop-types";

function CustomizationSiteItem(props) {
  const {
    item,
    legalsite,
    contactBg,
    cardsIconsColor,
    cardsTitleColor,
    cardsTitleFont,
    cardsTitleSize,
    cardsBodyFont,
    cardsBodySize,
    cardsBodyColor,
    cardsBtnColor,
    cardsBtnFont,
    cardsBtnSize,
    cardsTitleAlign,
    cardsTitleBold,
    cardsTitleItalic,
    cardsBodyAlign,
    cardsBodyBold,
    cardsBodyItalic,
    cardsBtnAlign,
    cardsBtnBold,
    cardsBtnItalic,
    isDPO,
    cardsBtnBg,
  } = props;

  return (
    <div
      className="customization-site__item"
      style={{
        background: contactBg ? contactBg : legalsite.cardsBg,
      }}
    >
      <div className="d-flex align-items-center">
        {isDPO ? (
          <UsersIcon
            color={
              cardsIconsColor
                ? cardsIconsColor
                : legalsite.cardsIconsColor
                ? legalsite.cardsIconsColor
                : "#7450c8"
            }
            className="mr-1"
          />
        ) : (
          <FileCheck
            color={
              cardsIconsColor
                ? cardsIconsColor
                : legalsite.cardsIconsColor
                ? legalsite.cardsIconsColor
                : "#7450c8"
            }
            className="mr-1"
          />
        )}

        <p
          className="customization-site__item-title"
          style={{
            color: cardsTitleColor
              ? cardsTitleColor
              : legalsite.cardsTitleColor,
            fontSize: cardsTitleSize
              ? cardsTitleSize
              : legalsite.cardsTitleSize,
            fontFamily: cardsTitleFont
              ? cardsTitleFont
              : legalsite.cardsTitleFont,
            textAlign:
              cardsTitleAlign !== null
                ? cardsTitleAlign
                : legalsite.cardsTitleAlign,
            fontWeight:
              cardsTitleBold !== null
                ? cardsTitleBold === true
                  ? 900
                  : 300
                : legalsite.cardsTitleBold
                ? 900
                : 300,
            fontStyle:
              cardsTitleItalic !== null
                ? cardsTitleItalic === true
                  ? "italic"
                  : "normal"
                : legalsite.cardsTitleItalic
                ? "italic"
                : "normal",
          }}
        >
          {isDPO || props.showLegalInformation
            ? "Legal Information"
            : item.title}
        </p>
      </div>
      <p
        className="customization-site__item-text"
        style={{
          color: cardsBodyColor ? cardsBodyColor : legalsite.cardsBodyColor,
          fontSize: cardsBodySize ? cardsBodySize : legalsite.cardsBodySize,
          fontFamily: cardsBodyFont ? cardsBodyFont : legalsite.cardsBodyFont,
          textAlign:
            cardsBodyAlign !== null ? cardsBodyAlign : legalsite.cardsBodyAlign,
          fontWeight:
            cardsBodyBold !== null
              ? cardsBodyBold === true
                ? 900
                : 300
              : legalsite.cardsBodyBold
              ? 900
              : 300,
          fontStyle:
            cardsBodyItalic !== null
              ? cardsBodyItalic === true
                ? "italic"
                : "normal"
              : legalsite.cardsBodyItalic
              ? "italic"
              : "normal",
        }}
      >
        {isDPO ? "Here you can find our Legal Information" : item.description}
      </p>

      <Button
        className="customization-site__btn"
        style={{
          fontSize: cardsBtnSize ? cardsBtnSize : legalsite.cardsBtnSize,
          fontFamily: cardsBtnFont ? cardsBtnFont : legalsite.cardsBtnFont,
          color: cardsBtnColor
            ? cardsBtnColor
            : legalsite.cardsBtnColor
            ? legalsite.cardsBtnColor
            : "#fff",
          backgroundColor: cardsBtnBg
            ? cardsBtnBg
            : legalsite && legalsite.cardsBtnBg
            ? legalsite.cardsBtnBg
            : "#7450c8",
          textAlign:
            cardsBtnAlign !== null ? cardsBtnAlign : legalsite.cardsBtnAlign,
          fontWeight:
            cardsBtnBold !== null
              ? cardsBtnBold === true
                ? 900
                : 300
              : legalsite.cardsBtnBold
              ? 900
              : 300,
          fontStyle:
            cardsBtnItalic !== null
              ? cardsBtnItalic === true
                ? "italic"
                : "normal"
              : legalsite.cardsBtnItalic
              ? "italic"
              : "normal",
        }}
      >
        See more
      </Button>
    </div>
  );
}

export default CustomizationSiteItem;
CustomizationSiteItem.propTypes = {
  item: PropTypes.object.isRequired,
  legalsite: PropTypes.object.isRequired,
  contactBg: PropTypes.string,
  cardsIconsColor: PropTypes.string,
  cardsTitleColor: PropTypes.string,
  cardsTitleFont: PropTypes.string,
  cardsTitleSize: PropTypes.string,
  cardsBodyFont: PropTypes.string,
  cardsBodySize: PropTypes.string,
  cardsBodyColor: PropTypes.string,
  cardsBtnColor: PropTypes.string,
  cardsBtnFont: PropTypes.string,
  cardsBtnSize: PropTypes.string,
  cardsTitleAlign: PropTypes.string,
  cardsTitleBold: PropTypes.bool,
  cardsTitleItalic: PropTypes.bool,
  cardsBodyAlign: PropTypes.string,
  cardsBodyBold: PropTypes.bool,
  cardsBodyItalic: PropTypes.bool,
  cardsBtnAlign: PropTypes.string,
  cardsBtnBold: PropTypes.bool,
  cardsBtnItalic: PropTypes.bool,
  isDPO: PropTypes.bool,
  cardsBtnBg: PropTypes.string,
  showLegalInformation: PropTypes.bool,
};
