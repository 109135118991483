import React, { Component, Fragment } from "react";
import { Alert } from "reactstrap";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { REMOVE_DPS_FROM_COMPLIANCE } from "../../graphql/removeDpsFromCompliance";
import { COMPLIANCE_DATA_PROCESSING_QUERY } from "../../graphql/complianceDataProcessing";
import UsersActions from "../Users/UsersActions";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import DPADPTable from "./DPADPTable";
import PropTypes from "prop-types";

class DPADP extends Component {
  static propTypes = {
    showRequestSignature: PropTypes.func.isRequired,
    showUpload: PropTypes.func.isRequired,
    data: PropTypes.object,
    removeDpsFromCompliance: PropTypes.func,
  };

  state = {
    isCheckedAll: false,
    filteredDps: null,
    isUploadDpaVisible: false,
    isRequestSignature: false,
    isSendRequest: false,
    user: null,
    arr: [],
    urls: [],
    userName: [],
    userType: [],
    hasFiltered: false,
    isDeleteModalVisible: false,
    removedCheck: false,
    filter: "ALL",
  };

  showRequestSignature = () => {
    if (this.state.isRequestSignature) {
      this.setState({
        isCheckedAll: false,
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        user: null,
      });
    }
    this.setState({
      isRequestSignature: !this.state.isRequestSignature,
    });
  };

  searchDP = (dp) => {
    const { filter } = this.state;

    let currentList = [],
      newList = [];

    if (dp !== "") {
      currentList =
        this.props.data &&
        this.props.data.currentDataProcessors &&
        filter === "ALL"
          ? this.props.data.currentDataProcessors
          : this.props.data &&
            this.props.data.currentDataProcessors &&
            filter === "NO_DPA"
          ? this.props.data.currentDataProcessors.filter(
              (item) => item.dpaStatus === "NO"
            )
          : this.props.data &&
            this.props.data.currentDataProcessors &&
            filter === "HAVE_DPA"
          ? this.props.data.currentDataProcessors.filter(
              (item) => item.dpaStatus === "YES"
            )
          : [];

      newList = currentList.filter((item) => {
        const dpName = item.dataProcessor.companyName.toLowerCase(),
          filter = dp.toLowerCase();

        return dpName.includes(filter);
      });
    } else {
      newList =
        this.props.data &&
        this.props.data.currentDataProcessors &&
        filter === "ALL"
          ? this.props.data.currentDataProcessors
          : this.props.data &&
            this.props.data.currentDataProcessors &&
            filter === "NO_DPA"
          ? this.props.data.currentDataProcessors.filter(
              (item) => item.dpaStatus === "NO"
            )
          : this.props.data &&
            this.props.data.currentDataProcessors &&
            filter === "HAVE_DPA"
          ? this.props.data.currentDataProcessors.filter(
              (item) => item.dpaStatus === "YES"
            )
          : [];
    }

    this.setState({
      filteredDps: newList,
    });
  };

  uploadDpa = () => {
    this.setState({
      isUploadDpaVisible: !this.state.isUploadDpaVisible,
    });
  };

  sendRequest = () => {
    this.setState({
      isSendRequest: !this.state.isSendRequest,
      isRequestSignature: false,
    });
  };

  setFilter = (filter) => {
    this.setState({
      hasFiltered: true,
      filter,
      filteredDps: null,
    });
  };

  checkAll = (users) => {
    const userId = users.map((item) => item.dataProcessor.id);
    const userUrl = users.map((item) => item.downloadUrl);
    const userName = users.map((item) => item.companyName);
    const userType = users.map((item) => item.__typename);
    this.setState({
      isCheckedAll: true,
      arr: userId,
      urls: userUrl,
      userName,
      userType,
    });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: false,
      arr: [],
      urls: [],
      userName: [],
      userType: [],
    });
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  removeUser = () => {
    this.props
      .removeDpsFromCompliance({
        variables: {
          ids: this.state.arr,
        },
      })
      .then(() => {
        this.props.data.refetch();

        this.setState({
          arr: [],
          urls: [],
          userName: [],
          userType: [],
          isCheckedAll: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  checkItem = (id, downloadUrl, name, type) => {
    let checkedItems = [];
    let checkedUrls = [];
    let checkedNames = [];
    let checkedTypes = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
        urls: [],
        userName: [],
        userType: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);
    checkedUrls.concat(downloadUrl);
    checkedNames.concat(name);
    checkedTypes.concat(type);

    if (this.state.arr.includes(id) && this.state.urls.includes(downloadUrl)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id && this.state.urls[i] === downloadUrl) {
          this.state.arr.splice(i, 1);
          this.state.urls.splice(i, 1);
          this.state.userName.splice(i, 1);
          this.state.userType.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
      urls: this.state.urls.concat(downloadUrl),
      userName: this.state.userName.concat(name),
      userType: this.state.userType.concat(type),
    });
  };

  render() {
    const { isCheckedAll, filteredDps, filter } = this.state;
    const { loading, error } = this.props.data;

    const dps = filteredDps
      ? filteredDps
      : this.props.data &&
        this.props.data.currentDataProcessors &&
        filter === "ALL"
      ? this.props.data.currentDataProcessors
      : this.props.data &&
        this.props.data.currentDataProcessors &&
        filter === "NO_DPA"
      ? this.props.data.currentDataProcessors.filter(
          (item) => item.dpaStatus === "NO"
        )
      : this.props.data &&
        this.props.data.currentDataProcessors &&
        filter === "HAVE_DPA"
      ? this.props.data.currentDataProcessors.filter(
          (item) => item.dpaStatus === "YES"
        )
      : [];

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <Fragment>
        <UsersActions
          isCheckedAll={isCheckedAll}
          users={dps}
          searchUser={this.searchDP}
          showRequestSignature={() =>
            this.props.showRequestSignature(this.state.arr, this.state.userType)
          }
          checkAll={this.checkAll}
          unselectAll={this.unselectAll}
          setFilter={this.setFilter}
          arr={this.state.arr}
          download={this.download}
          deleteUser={this.openDeleteModal}
          isDP={true}
          showUpload={this.props.showUpload}
          filter={this.state.filter}
        />

        <div className="users__table">
          <DPADPTable
            isCheckedAll={isCheckedAll}
            users={dps}
            checkItem={this.checkItem}
            removedCheck={this.state.removedCheck}
            userName={this.state.userName}
            userId={this.state.arr}
          />
        </div>

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          isUsers={this.state.arr && this.state.arr.length > 1}
          removeUser={this.removeUser}
          isDP={true}
        />
      </Fragment>
    );
  }
}

export default compose(
  graphql(COMPLIANCE_DATA_PROCESSING_QUERY, {
    options: () => ({
      variables: {
        filter: "ALL",
        pageNumber: 1,
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(REMOVE_DPS_FROM_COMPLIANCE, {
    name: "removeDpsFromCompliance",
  })
)(DPADP);
