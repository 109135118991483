import React, { Component } from "react";
import PropTypes from "prop-types";

export default class RiskBreach extends Component {
  static propTypes = {
    getRisk: PropTypes.func.isRequired,
    risk: PropTypes.string,
  };

  state = {
    isInitialRisk: true,
    isLowRisk: false,
    isMediumRisk: false,
    isHighRisk: false,
    isSevereRisk: false,
  };

  render() {
    const { isInitialRisk, isLowRisk, isMediumRisk, isHighRisk, isSevereRisk } =
      this.state;
    const { risk } = this.props;

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title">Determine risk</h5>
        <p className="compliance-breaches__where-text mb-3">
          In determining how serious you consider the breach to be for affected
          individuals, you should take into account the impact the breach could
          potentially have on individuals whose data has been exposed.
        </p>

        <p className="compliance-breaches__where-text">
          In assessing this potential impact you should consider the nature of
          the breach, the cause of the breach, the type of data exposed,
          mitigating factors in place and whether the personal data of
          vulnerable individuals has been exposed. The levels of risk are
          further defined below:
        </p>

        <div className="d-flex mb-5 align-baseline">
          <span
            className={`${
              (risk === "low risk" && isInitialRisk) ||
              (!isInitialRisk && isLowRisk)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isLowRisk: true,
                isInitialRisk: false,
                isMediumRisk: false,
                isHighRisk: false,
                isSevereRisk: false,
              });
              this.props.getRisk("low risk");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              Low risk
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach is unlikely to have an impact on individuals, or the
              impact is likely to be minimal.
            </span>
          </div>
        </div>

        <div className="d-flex mb-5 align-baseline">
          <span
            className={`${
              (risk === "medium risk" && isInitialRisk) ||
              (!isInitialRisk && isMediumRisk)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isLowRisk: false,
                isInitialRisk: false,
                isMediumRisk: true,
                isHighRisk: false,
                isSevereRisk: false,
              });
              this.props.getRisk("medium risk");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              Medium risk
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach may have an impact on individuals, but the impact is
              unlikely to be substantial.
            </span>
          </div>
        </div>

        <div className="d-flex mb-5 align-baseline">
          <span
            className={`${
              (risk === "high risk" && isInitialRisk) ||
              (!isInitialRisk && isHighRisk)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isLowRisk: false,
                isInitialRisk: false,
                isMediumRisk: false,
                isHighRisk: true,
                isSevereRisk: false,
              });
              this.props.getRisk("high risk");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              High risk
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach may have a considerable impact on affected individuals.
            </span>
          </div>
        </div>

        <div className="d-flex mb-5 align-baseline">
          <span
            className={`${
              (risk === "severe risk" && isInitialRisk) ||
              (!isInitialRisk && isSevereRisk)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isLowRisk: false,
                isInitialRisk: false,
                isMediumRisk: false,
                isHighRisk: false,
                isSevereRisk: true,
              });
              this.props.getRisk("severe risk");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              Severe risk
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach may have a critical, extensive or dangerous impact on
              affected individuals.
            </span>
          </div>
        </div>
      </div>
    );
  }
}
