import React, { Fragment } from "react";
import DashboardIcon from "../Icons/DashboardIcon";
import PropTypes from "prop-types";
import InboxIcon from "../Icons/InboxIcon";
import LegalIcon from "../Icons/LegalIcon";
import PrivacyIcon from "../Icons/PrivacyIcon";
import HistoryIcon from "../Icons/HistoryIcon";
import DataIcon from "../Icons/DataIcon";
import { NavLink } from "react-router-dom";
import SettingsIcon from "../Icons/SettingsIcon";
import Logo from "../../images/Logo.svg";
import { SIDEMENU_QUERY } from "../../graphql/sidemenu";
import { graphql } from "@apollo/client/react/hoc";
import "./side-menu.scss";
import Integrations from "../Icons/Integrations";
import Integration from "../Icons/Integration";

function SideMenu(props) {
  const { viewer } = props.data;

  return (
    <div className="sidemenu">
      <div>
        <span className="sidemenu__logo">
          <img src={Logo} alt="logo" />
        </span>
        <NavLink
          exact
          activeClassName="-is-active"
          to="/dashboard"
          className="sidemenu__item"
        >
          <DashboardIcon className="sidemenu__item-icon" />{" "}
          <span className="sidemenu__item-label">Dashboard</span>
        </NavLink>

        {viewer && !viewer.appAdmin ? (
          <Fragment>
            <NavLink
              activeClassName="-is-active"
              to={"/inbox"}
              className="sidemenu__item"
            >
              <InboxIcon color={"#DFE4EE"} className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label">Inbox</span>
            </NavLink>

            <NavLink
              onClick={props.getInitialPage}
              activeClassName="-is-active"
              to="/pages"
              className="sidemenu__item"
            >
              <LegalIcon className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label">Pages</span>
            </NavLink>

            <NavLink
              onClick={props.getInitialPage}
              activeClassName="-is-active"
              to={"/compliance"}
              className="sidemenu__item"
            >
              <PrivacyIcon className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label">Compliance</span>
            </NavLink>

            <NavLink
              activeClassName="-is-active"
              onClick={props.getInitialPage}
              to={"/users"}
              className="sidemenu__item"
            >
              <DataIcon className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label text-center">Users</span>
            </NavLink>

            <NavLink
              onClick={props.getInitialPage}
              activeClassName="-is-active"
              to={"/data-processors"}
              className="sidemenu__item"
            >
              <Integrations className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label text-center">
                Data
                <br />
                Processors
              </span>
            </NavLink>

            <NavLink
              activeClassName="-is-active"
              className="sidemenu__item"
              to={"/history"}
            >
              <HistoryIcon className="sidemenu__item-icon" />{" "}
              <span className="sidemenu__item-label">History</span>
            </NavLink>
          </Fragment>
        ) : (
          <NavLink
            onClick={props.getInitialPage}
            activeClassName="-is-active"
            to="/settings"
            className="sidemenu__item"
          >
            <SettingsIcon className="sidemenu__item-icon" />{" "}
            <span className="sidemenu__item-label">Settings</span>
          </NavLink>
        )}
      </div>

      {viewer && !viewer.appAdmin ? (
        <div className="sidemenu__options">
          <NavLink
            onClick={props.getInitialPage}
            activeClassName="-is-active"
            to={"/integrations"}
            className="sidemenu__item"
          >
            <Integration className="sidemenu__item-icon" />{" "}
            <span className="sidemenu__item-label text-center">
              Integrations
            </span>
          </NavLink>

          <NavLink
            onClick={props.getInitialPage}
            activeClassName="-is-active"
            to="/settings"
            className="sidemenu__item"
          >
            <SettingsIcon className="sidemenu__item-icon" />{" "}
            <span className="sidemenu__item-label">Settings</span>
          </NavLink>
        </div>
      ) : null}
    </div>
  );
}

export default graphql(SIDEMENU_QUERY)(SideMenu);
SideMenu.propTypes = {
  getInitialPage: PropTypes.func,
  data: PropTypes.object,
};
