import gql from "graphql-tag";

export const REMOVE_FILE_OF_REQUEST = gql`
  mutation($fileId: String!, $clientMutationId: String) {
    removeFileOfRequest(
      input: { fileId: $fileId, clientMutationId: $clientMutationId }
    ) {
      errors
      requests{
        id  
        fileResources{
          id
        }
      }
    }
  }
`;
