import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import Illustration from "../../images/History/Illustration.svg";
import "./modals.scss";
import DataProcessingItem from "../../containers/Settings/DataProcessingItem";
import { EDIT_DP_SETTINGS } from "../../graphql/editDataProcessingSettings";
import { graphql } from "@apollo/client/react/hoc";
import Img2 from "../../images/Settings/Group_5.svg";
import Img3 from "../../images/Settings/Illustration3.svg";

class FeedbackModal extends Component {
  static propTypes = {
    showIntroductionModal: PropTypes.func.isRequired,
    isIntroductionModalVisible: PropTypes.bool.isRequired,
    showNextStep: PropTypes.func.isRequired,
    account: PropTypes.object,
    viewer: PropTypes.object,
    hasData: PropTypes.bool,
    editDPSettings: PropTypes.func,
  };

  state = {
    showType: false,
    activeItem:
      this.props.account && this.props.account.dataProcessing
        ? this.props.account.dataProcessing === "NO"
          ? 1
          : this.props.account.dataProcessing === "LEGALSITE_ACCOUNT"
          ? 2
          : 3
        : 1,
  };

  handleDataProcessing = (item) => {
    this.props
      .editDPSettings({
        variables: {
          dataProcessing:
            item === 1
              ? "NO"
              : item === 2
              ? "LEGALSITE_ACCOUNT"
              : "USER_INTERFACE",
        },
      })
      .then(() => {
        this.props.showNextStep(this.props.hasData ? "dataFirst" : "first");
        this.props.showIntroductionModal();
        this.setState({
          showType: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { viewer } = this.props;
    const isDP = viewer && viewer.invitedAsDp;
    const { showType, activeItem } = this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isIntroductionModalVisible}
          toggle={this.props.showIntroductionModal}
          className={`${!showType ? "" : "-bigger-modal"} modal-introduction`}
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showIntroductionModal}
            />
            {!showType ? (
              <Fragment>
                <img
                  className="modal-introduction__img"
                  src={Illustration}
                  alt="introduction"
                />
                <p className="modal-introduction__title">
                  Welcome to LegalSite!
                </p>
                <p className="modal-introduction__message">
                  {isDP
                    ? "Your company was successfully added as a Data Processor and can now solve data requests at scale. Let us guide you through your account and see how easy it is to get started!"
                    : "Let us guide you through your account and see how easy it is to get your LegalSite up and running!"}
                </p>

                <Button
                  className="modal-introduction__btn btn--primary"
                  onClick={
                    isDP
                      ? () => this.setState({ showType: true })
                      : () => {
                          this.props.showNextStep(
                            this.props.hasData ? "dataFirst" : "first"
                          );
                          this.props.showIntroductionModal();
                        }
                  }
                >
                  Get started
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <p className="modal-introduction__title">
                  Configure your integration with LegalSite
                </p>
                <p className="modal-introduction__message">
                  Before guiding you through our app, please configure your
                  integration so we can add your company as a Data Processor.{" "}
                </p>

                <div className="d-flex justify-content-around flex-wrap mt-2 w-100">
                  <DataProcessingItem
                    title="Data Processor LegalSite account"
                    id={2}
                    img={Img2}
                    text="Select this option to solve requests from Data Controllers inside your LegalSite Inbox."
                    btnText="DP LegalSite Account"
                    setItemActive={
                      activeItem === 2
                        ? () => {
                            this.props.showNextStep(
                              this.props.hasData ? "dataFirst" : "first"
                            );
                            this.props.showIntroductionModal();
                            this.setState({
                              showType: false,
                            });
                          }
                        : (item) => {
                            this.setState({ activeItem: item });
                            this.handleDataProcessing(2);
                          }
                    }
                    activeItem={activeItem}
                  />
                  <DataProcessingItem
                    title="Data Processor User Interface"
                    id={3}
                    img={Img3}
                    text="Select this if your users should go to your website/application to make changes to their data. Requests will still show up in our Inbox for managing tasks relating to your other Data Processors."
                    btnText="DP User Interface"
                    setItemActive={
                      activeItem === 3
                        ? () => {
                            this.props.showNextStep(
                              this.props.hasData ? "dataFirst" : "first"
                            );
                            this.props.showIntroductionModal();
                            this.setState({
                              showType: false,
                            });
                          }
                        : (item) => {
                            this.setState({ activeItem: item });
                            this.handleDataProcessing(3);
                          }
                    }
                    activeItem={activeItem}
                  />
                </div>
              </Fragment>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(EDIT_DP_SETTINGS, {
  name: "editDPSettings",
})(FeedbackModal);
