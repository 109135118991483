import gql from "graphql-tag";

export const USER_DASHBOARD_QUERY = gql`
  query(
    $graphType: GraphTypeEnum!
    $graphInterval: GraphIntervalEnum!
    $interval: IntervalEnum!
    $status: RequestStatusDashboardTableEnum!
  ) {
    usersStats {
      dataSubjects
      totalUsers
    }
    alerts {
      id
      category
      templatePublication {
        id
        title
      }
      page {
        id
      }
      pageTemplate {
        id
        title
      }
    }
    dataProtectionOfficer {
      id
      show
    }
    dashboardStats {
      newRequests
      pendingRequests
      requestsInProgress
      solvedRequests
      totalRequests
      dataSubjects
    }
    graphDataDashboard(type: $graphType, interval: $graphInterval) {
      id
      intervals
      requests
    }
    dashboardRequestsTable(status: $status, interval: $interval) {
      id
      dataSubject {
        id
        name
        email
      }
      receivedDate
      status
      category
      code
    }
    account {
      id
      dataProcessing
      visitorsCount
      needToUpgrade
    }
    legalsite {
      bodyBg
      bodyBtnAlign
      bodyBtnBold
      bodyBtnColor
      bodyBtnFont
      bodyBtnBg
      bodyBtnItalic
      bodyBtnSize
      bodyIconsColor
      bodySubtitleAlign
      bodySubtitleBold
      bodySubtitleColor
      bodySubtitleFont
      bodySubtitleItalic
      bodySubtitleSize
      bodyTextAlign
      bodyTextBold
      bodyTextColor
      bodyTextFont
      bodyTextItalic
      bodyTextSize
      bodyTitleAlign
      bodyTitleBold
      bodyTitleColor
      bodyTitleFont
      bodyTitleItalic
      bodyTitleSize
      cardsBg
      cardsBodyAlign
      cardsBodyBold
      cardsBodyColor
      cardsBodyFont
      cardsBodyItalic
      cardsBodySize
      cardsBtnAlign
      cardsBtnBg
      cardsBtnBold
      cardsBtnColor
      cardsBtnFont
      cardsBtnItalic
      cardsBtnSize
      cardsIconsColor
      cardsTitleAlign
      cardsTitleBold
      cardsTitleColor
      cardsTitleFont
      cardsTitleItalic
      cardsTitleSize
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      headerBg
      headerBodyAlign
      headerBodyBold
      headerBodyColor
      headerBodyFont
      headerBodyItalic
      headerBodySize
      headerTitleAlign
      headerTitleBold
      headerTitleColor
      headerTitleFont
      headerTitleItalic
      homepageTitle
      homepageDescription
      headerTitleSize
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    pages {
      applicable
      description
      id
      status
      title
    }
    legalsiteSettings {
      brandColor
      companyLogo
      subdomain
      id
    }
  }
`;
