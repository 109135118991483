import React, { Component, Fragment } from "react";
import BreachIncidents from "./BreachIncidents";
import { Alert } from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import { BREACHES_QUERY } from "../../graphql/breaches";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class UpdateBreach extends Component {
  static propTypes = {
    showNameForm: PropTypes.func.isRequired,
    showIncidents: PropTypes.func.isRequired,
    getInitialPage: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
  };

  state = {};

  render() {
    const { breachIncidents, loading, error } = this.props.data;

    if (loading) return <p>Loading...</p>;

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="compliance-breach__container">
        <p
          onClick={this.props.getInitialPage}
          className="compliance-breach__back"
        >
          <MdArrowLeft
            size={25}
            className="customize-menu__back position-relative"
            color="#7450c8"
          />
          &nbsp;Back to Compliance
        </p>
        <h5 className="compliance-breach__title">Update Breach Incidents</h5>
        <p className="compliance-breach__text">
          Select the breach incident you would like to update.
        </p>

        {breachIncidents && breachIncidents.length ? (
          <Fragment>
            {breachIncidents
              .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
              .map((item, i) => (
                <BreachIncidents
                  key={i}
                  title={item.name}
                  update={item.lastUpdated}
                  incident={item}
                  showNameForm={this.props.showNameForm}
                  showIncidents={this.props.showIncidents}
                />
              ))}
          </Fragment>
        ) : (
          <p>No Breach Incidents to update</p>
        )}
      </div>
    );
  }
}

export default graphql(BREACHES_QUERY)(UpdateBreach);
