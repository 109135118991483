import gql from "graphql-tag";

export const EDIT_LEGALSITE_SETTINGS = gql`
  mutation(
    $companyLogo: String
    $brandColor: String
    $clientMutationId: String
    $favicon: String
  ) {
    editLegalsiteSettings(
      input: {
        companyLogo: $companyLogo
        brandColor: $brandColor
        clientMutationId: $clientMutationId
        favicon: $favicon
      }
    ) {
      errors
      legalsiteSettings {
        companyLogo
        brandColor
        id
        favicon
      }
    }
  }
`;
