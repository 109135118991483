import gql from "graphql-tag";

export const EDIT_LEGALSITE_INFORMATION = gql`
  mutation(
    $companyName: String
    $companyAddress: String
    $companyCity: String
    $country: String
    $companyZipCode: String
    $companyWebsite: String
    $phoneNumber: String
    $vat: String
    $fullName: String
    $companyRegistration: String
    $email: String
    $showLegalInformation: Boolean!
    $clientMutationId: String
  ) {
    editLegalInformation(
      input: {
        companyName: $companyName
        companyAddress: $companyAddress
        companyCity: $companyCity
        country: $country
        companyZipCode: $companyZipCode
        companyWebsite: $companyWebsite
        phoneNumber: $phoneNumber
        vat: $vat
        fullName: $fullName
        companyRegistration: $companyRegistration
        email: $email
        clientMutationId: $clientMutationId
        showLegalInformation: $showLegalInformation
      }
    ) {
      errors
      account {
        companyRegistration
        companyAddress
        companyCity
        companyZipCode
        country
        email
        fullName
        phoneNumber
        companyName
        showLegalInformation
        id
      }
    }
  }
`;
