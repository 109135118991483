import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import ProgressIcon from "./../Icons/ProgressIcon";
import ViewIcon from "./../Icons/ViewIcon";
import { REMOVE_REQUEST } from "../../graphql/removeRequest";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import TrashIcon from "../Icons/TrashIcon";
import axios from "axios";
import DownloadIcon from "../Icons/DownloadIcon";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import DoneIcon from "../Icons/DoneIcon";
import ToDoIcon from "../Icons/ToDoIcon";
import Moment from "react-moment";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import "./info-table.scss";

class InfoTable extends Component {
  static propTypes = {
    requests: PropTypes.array,
    removeRequest: PropTypes.func,
    notify: PropTypes.func,
    refetchData: PropTypes.func,
    setTableTab: PropTypes.func,
    getTableInterval: PropTypes.func,
    generateReport: PropTypes.func,
  };

  state = {
    tabVisible: "ALL",
    tableInterval: "PAST_WEEK",
  };

  share = () => {
    alert("This should share something!");
  };

  delete = (id) => {
    this.props
      .removeRequest({
        variables: {
          id: [id],
        },
      })
      .then(() => {
        this.props.notify("Request deleted");
        this.props.refetchData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //   TODO
  //  Check if this one was used; there were 2 download functions
  //   download = (url) => {
  //     axios({
  //       url,
  //       method: "GET",
  //       responseType: "blob",
  //     }).then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "report.pdf");
  //       document.body.appendChild(link);
  //       link.click();
  //     });
  //   };

  checkType = (type) => {
    if (type === "DATA_ACCESS") return "Access";
    if (type === "QUESTION") return "Question";
    if (type === "COMPLAINT") return "Complaint";
    if (type === "DATA_ERASURE") return "Removal";
    if (type === "DATA_TRANSFER") return "Transfer";
    if (type === "DONT_SELL_DATA") return "Do not sell";
  };

  generateReport = (id, type, name) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: "REQUEST",
        },
      })
      .then(({ data }) => {
        if (type === "view") {
          window.open(data.generateReport.downloadUrl, "_blank");
        } else {
          this.download(data.generateReport.downloadUrl, name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.props.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  render() {
    const { tabVisible } = this.state;
    const { requests } = this.props;

    let requestData = requests;

    const columns = [
      {
        Header: "User",
        accessor: "dataSubject",
        Cell: (props) => (
          <span>
            {props.value && props.value.name ? props.value.name : ""} (
            {props.value && props.value.email ? props.value.email : ""})
          </span>
        ),
      },
      {
        Header: "Type",
        accessor: "category",
        Cell: (props) => <span>{this.checkType(props.value)}</span>,
        width: 100,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (props) => (
          <span>
            {props.value === "SOLVED" ? (
              <DoneIcon />
            ) : props.value === "PENDING" ? (
              <ToDoIcon />
            ) : props.value === "ACTION_REQUIRED" ? (
              <ProgressIcon />
            ) : (
              <ProgressIcon />
            )}
            &nbsp;
            {props.value === "SOLVED"
              ? " Done"
              : props.value === "PENDING"
              ? " To Do"
              : props.value === "ACTION_REQUIRED"
              ? "Action Required"
              : " In Progress"}
          </span>
        ),
        width: 140,
      },
      {
        Header: "Time",
        accessor: "receivedDate",
        Cell: (props) => <Moment format="DD MMMM YYYY">{props.value}</Moment>,
        width: 160,
      },
      {
        Header: "Code",
        accessor: "code",
        minWidth: 60,
        maxWidth: 100,
      },
      {
        Header: "Report",
        accessor: "",
        Cell: (props) => (
          <span
            className="info-table__text-dark-gray"
            style={{ cursor: "pointer" }}
          >
            <span
              onClick={() =>
                this.generateReport(
                  props.value.id,
                  "view",
                  props.value.dataSubject.name
                )
              }
            >
              <ViewIcon color={"#969696"} /> View
            </span>
          </span>
        ),
        width: 80,
      },
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span className="d-flex align-items-center justify-content-around">
            <DownloadIcon
              color={"#969696"}
              className="info-table__action-btn"
              onClick={() =>
                this.generateReport(
                  props.value.id,
                  "download",
                  props.value.dataSubject.name
                )
              }
            />

            <TrashIcon
              color={"#969696"}
              className="info-table__action-btn"
              onClick={() => this.delete(props.value.id)}
            />
          </span>
        ),
      },
    ];

    return (
      <div className="info-table container">
        <div className="info-table__tabs d-flex justify-content-between">
          <div className="d-flex">
            <div
              className={`info-table__tab ${
                tabVisible === "ALL" ? "-is-active" : null
              }`}
              onClick={() => {
                this.setState(
                  {
                    tabVisible: "ALL",
                  },
                  () => this.props.setTableTab("ALL")
                );
              }}
            >
              <p className="info-table__tab-text">User Requests</p>
            </div>

            <div
              className={`info-table__tab ${
                tabVisible === "SOLVED" ? "-is-active" : null
              }`}
              onClick={() => {
                this.setState(
                  {
                    tabVisible: "SOLVED",
                  },
                  () => this.props.setTableTab("SOLVED")
                );
              }}
            >
              <p className="info-table__tab-text">Solved requests</p>
            </div>

            <div
              className={`info-table__tab ${
                tabVisible === "IN_PROGRESS" ? "-is-active" : null
              }`}
              onClick={() => {
                this.setState(
                  {
                    tabVisible: "IN_PROGRESS",
                  },
                  () => this.props.setTableTab("IN_PROGRESS")
                );
              }}
            >
              <p className="info-table__tab-text">Requests in progress</p>
            </div>

            <div
              className={`info-table__tab ${
                tabVisible === "PENDING" ? "-is-active" : null
              }`}
              onClick={() => {
                this.setState(
                  {
                    tabVisible: "PENDING",
                  },
                  () => this.props.setTableTab("PENDING")
                );
              }}
            >
              <p className="info-table__tab-text">Pending Requests</p>
            </div>
          </div>

          <UncontrolledDropdown className="info-table__dropdown">
            <DropdownToggle className="w-100 d-flex align-items-center info-table__toggle">
              {this.state.tableInterval === "PAST_WEEK"
                ? "Past Week"
                : this.state.tableInterval === "PAST_MONTH"
                ? "Past Month"
                : "Past Year"}{" "}
              <FaCaret
                className="info-table__caret"
                size="20"
                color="#969696"
              />
            </DropdownToggle>
            <DropdownMenu>
              {this.state.tableInterval !== "PAST_WEEK" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getTableInterval("PAST_WEEK");
                    this.setState({ tableInterval: "PAST_WEEK" });
                  }}
                >
                  Past Week
                </DropdownItem>
              ) : null}

              {this.state.tableInterval !== "PAST_MONTH" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getTableInterval("PAST_MONTH");
                    this.setState({ tableInterval: "PAST_MONTH" });
                  }}
                >
                  Past Month
                </DropdownItem>
              ) : null}

              {this.state.tableInterval !== "PAST_YEAR" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getTableInterval("PAST_YEAR");
                    this.setState({ tableInterval: "PAST_YEAR" });
                  }}
                >
                  Past Year
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="info-table__container">
          <ReactTable
            data={requestData}
            columns={columns}
            showPageSizeOptions={false}
            defaultPageSize={20}
            minRows={0}
            showPagination={
              requestData && requestData.length > 20 ? true : false
            }
            noDataText="No Requests"
          />
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(REMOVE_REQUEST, {
    name: "removeRequest",
  }),
  graphql(GENERATE_REPORT, {
    name: "generateReport",
  })
)(InfoTable);
