import PropTypes from "prop-types";
import React from "react";
import MdArrow from "react-icons/lib/ti/arrow-right";
import TrashIcon from "../../components/Icons/TrashIcon";

function DataProcessorItem(props) {
  const { dataProcessor, isCurrent, isSample } = props;

  return isCurrent && !isSample ? (
    <div className="data-processors__item" onClick={props.showDP}>
      <div className="data-processors__logo">
        {dataProcessor &&
        dataProcessor.dataProcessor &&
        dataProcessor.dataProcessor.logo ? (
          <img src={dataProcessor.dataProcessor.logo} alt="logo" />
        ) : dataProcessor &&
          dataProcessor.dataProcessor &&
          dataProcessor.dataProcessor.profile &&
          dataProcessor.dataProcessor.profile.logo ? (
          <img src={dataProcessor.dataProcessor.profile.logo} alt="logo" />
        ) : (
          <span className="data-processors__placeholder" />
        )}
      </div>

      <div className="data-processors__item-content">
        <h4 className="data-processors__item-title">
          {dataProcessor &&
          dataProcessor.dataProcessor &&
          dataProcessor.dataProcessor.companyName
            ? dataProcessor.dataProcessor.companyName
            : ""}
        </h4>
        <p className="data-processors__item-info">
          {dataProcessor &&
          dataProcessor.dataProcessor &&
          dataProcessor.dataProcessor.description
            ? dataProcessor.dataProcessor.description
            : dataProcessor &&
              dataProcessor.dataProcessor &&
              dataProcessor.dataProcessor.profile &&
              dataProcessor.dataProcessor.profile.smallDescription
            ? dataProcessor.dataProcessor.profile.smallDescription
            : ""}
        </p>
      </div>

      <div className="data-processors__item-edit">
        Edit &nbsp;
        <MdArrow size={20} />
      </div>
    </div>
  ) : !isCurrent && !isSample ? (
    <div onClick={props.showDP} className="data-processors__item">
      <div className="data-processors__logo">
        {dataProcessor.profile && dataProcessor.profile.logo ? (
          <img src={dataProcessor.profile.logo} alt="logo" />
        ) : dataProcessor.logo ? (
          <img src={dataProcessor.logo} alt="logo" />
        ) : (
          <span className="data-processors__placeholder" />
        )}
      </div>

      <div className="data-processors__item-content">
        <h4 className="data-processors__item-title">
          {dataProcessor &&
          dataProcessor.profile &&
          dataProcessor.profile.companyName
            ? dataProcessor.profile.companyName
            : dataProcessor.companyName}
        </h4>
        <p className="data-processors__item-info">
          {dataProcessor &&
          dataProcessor.profile &&
          dataProcessor.profile.smallDescription
            ? dataProcessor.profile.smallDescription
            : dataProcessor.description}
        </p>
      </div>
      {props.isAdmin ? (
        <div
          className="data-processors__item-edit"
          onClick={(e) => {
            e.stopPropagation();
            props.showDeleteModal();
            props.getId(dataProcessor.id);
          }}
        >
          <TrashIcon color="#7450C8" />
          &nbsp; Delete
        </div>
      ) : null}
    </div>
  ) : (
    <div className="data-processors__item">
      <div className="data-processors__logo">
        {dataProcessor.logo ? (
          <img src={dataProcessor.logo} alt="logo" />
        ) : (
          <span className="data-processors__placeholder" />
        )}
      </div>

      <div className="data-processors__item-content">
        <h4 className="data-processors__item-title">
          {dataProcessor.companyName}
        </h4>
        <p className="data-processors__item-info">
          {dataProcessor.description}
        </p>
      </div>
    </div>
  );
}

export default DataProcessorItem;

DataProcessorItem.propTypes = {
  dataProcessor: PropTypes.object.isRequired,
  isCurrent: PropTypes.bool,
  isSample: PropTypes.bool,
  showDP: PropTypes.func,
  showDeleteModal: PropTypes.func,
  getId: PropTypes.func,
  isAdmin: PropTypes.bool,
};
