import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import Img from "../../images/Users/Document.svg";
import MdArrow from "react-icons/lib/ti/arrow-right";

export default class ConcludedBreach extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isModalOpen}
          toggle={this.props.openModal}
          className="breach-concluded-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openModal}
            />
            <img src={Img} alt="breach" />

            <p className="modal-assign__title mb-3">
              You have concluded the breach form!
            </p>

            <div className="discard-changes__btns">
              <Button className="btn--outline" onClick={this.props.openModal}>
                Close <MdArrow size={23} />
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
