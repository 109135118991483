import React, { Component } from "react";
import { Alert } from "reactstrap";
import SortIcon from "../../components/Icons/SortIcon";
import DataProcessorsItem from "../DataProcessors/DataProcessorItem";
import FaCaret from "react-icons/lib/fa/angle-down";
import FaCaretUp from "react-icons/lib/fa/angle-up";
import "../DataProcessors/data-processors.scss";
import { graphql } from "@apollo/client/react/hoc";
import { INTEGRATIONS_QUERY } from "../../graphql/integrations";
import SyncLoader from "react-spinners/SyncLoader";
import SearchInput from "../../components/SearchInput/SearchInput";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import AddDataProcessor from "../DataProcessors/AddDataProcessor";
import { getMobileDevice } from "../../utils/helpers";
import DataProcessor from "../DataProcessors/DataProcessor";
import Filter from "../DataProcessors/Filter";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

const filterOptions = [
  "See all",
  "Advertising",
  "Accounting",
  "CRM",
  "Customer Support",
  "Databases",
  "Ecommerce",
  "Email",
  "Files",
  "Forms",
  "HR Matters",
  "Online Courses",
  "Project Management",
  "Team Collaboration",
  "Other",
];

class Integrations extends Component {
  static propTypes = {
    location: PropTypes.object,
    data: PropTypes.shape({
      refetch: PropTypes.func,
      publicIntegrations: PropTypes.array,
      currentIntegrations: PropTypes.array,
      account: PropTypes.object,
      loading: PropTypes.bool,
      error: PropTypes.object,
      searchPublicIntegrations: PropTypes.array,
      searchCurrentIntegrations: PropTypes.array,
    }),
  };

  state = {
    activeFilter: "See all",
    isRighMenuOpen: false,
    isAddingDataProcessor: false,
    showFiler: false,
    isMobileDevice: false,
    filteredCurrentDataProcessors: null,
    filteredPublicDataProcessors: null,
    currentDPVisible: false,
    dp: null,
    isCurrent: false,
    query: "",
    hasSearch: false,
  };

  componentDidMount() {
    const isMobileDevice = getMobileDevice();

    this.setState({
      isMobileDevice,
    });

    if (this.props.location.state && this.props.location.state.isAdmin) {
      this.setState({
        isAddingDataProcessor: true,
      });
    }
  }

  filterDesktop = (item) => {
    const filterEnum =
      item === "See all"
        ? "ALL"
        : item === "Accounting"
        ? "ACCOUNTING"
        : item === "Advertising"
        ? "ADVERTISING"
        : item === "HR Matters"
        ? "HR_MATTERS"
        : item === "CRM"
        ? "CRM"
        : item === "Customer Support"
        ? "CUSTOMER_SUPPORT"
        : item === "Databases"
        ? "DATABASES"
        : item === "Ecommerce"
        ? "E_COMMERCE"
        : item === "Email"
        ? "E_MAILS"
        : item === "Files"
        ? "FILES"
        : item === "Forms"
        ? "FORMS"
        : item === "Online Courses"
        ? "ONLINE_COURSES"
        : item === "Project Management"
        ? "PROJECT_MANAGEMENT"
        : item === "Team Collaboration"
        ? "TEAM_COLLABORATION"
        : "OTHERS";

    this.setState(
      {
        activeFilter: item,
        filteredCurrentDataProcessors: null,
        filteredPublicDataProcessors: null,
        query: "",
      },
      () => this.props.data.refetch({ integrationCategory: filterEnum })
    );
  };

  addDataProcessor = () => {
    this.setState({
      isAddingDataProcessor: !this.state.isAddingDataProcessor,
    });
  };

  getInitialPage = () => {
    this.setState({
      currentDPVisible: false,
      dp: null,
      isCurrent: false,
    });
  };

  searchDataProcessor = (dataProcessor) => {
    this.props.data.refetch({ companyName: dataProcessor });

    if (dataProcessor === "") {
      this.setState({
        hasSearch: false,
      });
    } else {
      this.setState({
        hasSearch: true,
      });
    }
  };

  showDP = (dp, isCurrent) => {
    this.setState({
      currentDPVisible: !this.state.currentDPVisible,
      dp,
      isCurrent,
      hasSearch: false,
    });

    this.props.data.refetch({ companyName: "" });
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  render() {
    const {
      isAddingDataProcessor,
      showFilter,
      isMobileDevice,
      currentDPVisible,
      hasSearch,
    } = this.state;

    const {
      publicIntegrations,
      currentIntegrations,
      account,
      loading,
      error,
      searchPublicIntegrations,
      searchCurrentIntegrations,
    } = this.props.data;

    let publicDP =
      searchPublicIntegrations && hasSearch
        ? searchPublicIntegrations
        : publicIntegrations;
    const currentDP =
      searchCurrentIntegrations && hasSearch
        ? searchCurrentIntegrations
        : currentIntegrations;

    const ids = currentDP
      ? new Set(currentDP.map((item) => item.dataProcessor.id))
      : null;

    publicDP = publicDP ? publicDP.filter(({ id }) => !ids.has(id)) : null;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Integrations"
            showSideBarMenu={() =>
              this.setState({ isRightMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          {!isAddingDataProcessor && !currentDPVisible ? (
            <div
              className={`${
                account && account.needToUpgrade ? "-has-banner" : ""
              } data-processors d-flex`}
            >
              <div className="data-processors__filter">
                <div
                  className="data-processors__filter-title"
                  onClick={() =>
                    isMobileDevice
                      ? this.setState({ showFilter: !showFilter })
                      : {}
                  }
                >
                  <SortIcon className="mr-2" /> Filter
                  {!showFilter ? (
                    <FaCaret className="-show-on-mobile" />
                  ) : (
                    <FaCaretUp className="-show-on-mobile" />
                  )}
                </div>

                <Filter
                  isMobileDevice={isMobileDevice}
                  isIntegrations={true}
                  showFilter={showFilter}
                  filterOptions={filterOptions}
                  filterMobile={(item) => {
                    this.setState({
                      showFilter: !showFilter,
                    });
                    this.filterDesktop(item);
                  }}
                  filterDesktop={this.filterDesktop}
                  activeFilter={this.state.activeFilter}
                />
              </div>

              <div className="data-processors__content d-flex align-items-center flex-column">
                <h3 className="data-processors__title">
                  Use integrations to connect LegalSite to your applications
                  where you collect privacy data, like your CRM, email marketing
                  software and databases.
                </h3>

                <SearchInput
                  placeholder={"Type to search for a company..."}
                  search={this.searchDataProcessor}
                  // value={this.state.query}
                />

                {currentIntegrations.length ? (
                  <div className="data-processors__current">
                    <h2 className="data-processors__current-title">
                      Active Integrations
                    </h2>

                    <div className="data-processors__current-container d-flex flex-wrap">
                      {currentDP.length ? (
                        currentDP.map((item) => (
                          <DataProcessorsItem
                            key={item.id}
                            dataProcessor={item}
                            isCurrent={true}
                            showDP={() => this.showDP(item, true)}
                            isIntegration={true}
                          />
                        ))
                      ) : (
                        <p className="mb-5">No results</p>
                      )}
                    </div>
                  </div>
                ) : null}

                {publicIntegrations ? (
                  <div
                    className={`${
                      !currentIntegrations.length ? "mt-5" : ""
                    } data-processors__add`}
                  >
                    <h2 className="data-processors__current-title mt-0">
                      Available Integrations
                    </h2>

                    <div className="data-processors__current-container d-flex flex-wrap">
                      {publicDP.length ? (
                        publicDP.map((item) => (
                          <DataProcessorsItem
                            key={item.id}
                            dataProcessor={item}
                            showDP={() => this.showDP(item, false)}
                          />
                        ))
                      ) : (
                        <p className="mb-5">No results</p>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : isAddingDataProcessor && !currentDPVisible ? (
            <AddDataProcessor addDataProcessor={this.addDataProcessor} />
          ) : currentDPVisible && !isAddingDataProcessor ? (
            <DataProcessor
              dataProcessor={this.state.dp}
              isCurrent={this.state.isCurrent}
              isIntegrations={true}
              showDP={this.showDP}
              refetchData={this.refetchData}
              isIntegration={true}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default graphql(INTEGRATIONS_QUERY, {
  options: () => ({
    variables: {
      integrationCategory: "ALL",
      companyName: "",
    },
    fetchPolicy: "no-cache",
  }),
})(Integrations);
