import gql from "graphql-tag";

export const HISTORY_QUERY = gql`
  query(
    $status: RequestStatusEnum!
    $daysAgo: Int!
  ) {
    account {
      id
      needToUpgrade
      users {
        id
      }
    }
    historyRequests(status: $status, daysAgo: $daysAgo) {
      archived
      assignedTo {
        id
      }
      category
      code
      dataSubject {
        id
        name
        email
      }
      dueDate
      fileResources {
        id
      }
      id
      message
      receivedDate
      response
      spam
      status
      tasks {
        id
      }
      urgent
    }
    historyActivity(daysAgo: $daysAgo) {
      activity
      createdAt
      id
      requestNumber
      userName
    }
    historyAgreements(daysAgo: $daysAgo) {
      createdAt
      dataSubject {
        id
        name
        email
      }
      id
      ipNumber
      originUrl
      publicationVersion
      type
      userAgent
    }
  }
`;
