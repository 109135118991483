import React, { Component } from "react";
import SelectSuppliers from "../../components/Modals/SelectSuppliersModal";
import PropTypes from "prop-types";
import "../../components/Check/check.scss";

export default class WhereBreach extends Component {
  static propTypes = {
    showWhere: PropTypes.func.isRequired,
    where: PropTypes.string,
  };

  state = {
    isOrganisation:
      this.props.where && this.props.where === "your organisation"
        ? true
        : null,
    isInitialOption: true,
    isSuppliersModalVisible: false,
    DPBreach: [],
  };

  getItem = (enabled, title) => {
    if (enabled) {
      this.setState(
        {
          DPBreach: this.state.DPBreach.concat(title),
        },
        () => this.props.showWhere(this.state.DPBreach.toString())
      );
    } else {
      this.setState(
        {
          DPBreach: this.state.DPBreach.filter((item) => item !== title),
        },
        () => this.props.showWhere(this.state.DPBreach.toString())
      );
    }
  };

  showSuppliersModal = () => {
    this.setState({
      isSuppliersModalVisible: !this.state.isSuppliersModalVisible,
    });
  };

  render() {
    const { isOrganisation, isInitialOption } = this.state;

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title">Where</h5>
        <p className="compliance-breaches__where-text">
          First we need to know where this incident happened.
          <br />
          Please choose one of the following options.
        </p>

        <div className="d-flex mb-5">
          <span
            className={`${
              (isOrganisation && isInitialOption) ||
              (!isInitialOption && isOrganisation)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({
                isOrganisation: true,
                isInitialOption: false,
                DPBreach: [],
              });
              this.props.showWhere("your organisation");
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              Your organization
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach has occurred (or may have occurred) within your
              organisation.
            </span>
          </div>
        </div>

        <div className="d-flex mb-5">
          <span
            className={`${
              (!isOrganisation && isInitialOption) ||
              (!isInitialOption && !isOrganisation)
                ? "-is-active"
                : ""
            } compliance-breaches__where-radio mr-2 mt-1`}
            onClick={() => {
              this.setState({ isOrganisation: false, isInitialOption: false });
              this.showSuppliersModal();
            }}
          />
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-label">
              Third-party organization
            </span>
            <span className="compliance-breaches__where-check-text">
              The breach has occurred (or may have occurred) within a
              third-party company data processor.
            </span>
          </div>
        </div>

        <SelectSuppliers
          showSuppliersModal={this.showSuppliersModal}
          isSuppliersModalVisible={this.state.isSuppliersModalVisible}
          isBreach={true}
          getItem={this.getItem}
          dataSuppliers={this.props.where}
        />
      </div>
    );
  }
}
