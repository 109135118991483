import React, { Component, Fragment } from "react";
import {
  Alert,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import RightSideMenu from "./RightSideMenu";
import SyncLoader from "react-spinners/SyncLoader";
import FontLoader from "./FontLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { CUSTOMIZE_SELL_QUERY } from "../../graphql/customiseSellQuery";
import { CONFIGURE_DATA_BUTTON } from "../../graphql/configureDataButton";
import FaCaret from "react-icons/lib/fa/angle-down";
import ViewIcon from "../../components/Icons/ViewIcon";
import CustomizeSellFooter from "./CustomizeSellFooter";
import PropTypes from "prop-types";

class CustomizeSell extends Component {
  static propTypes = {
    legalsite: PropTypes.object.isRequired,
    configureDataButton: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool,
    showCustomization: PropTypes.func,
    accountData: PropTypes.object,
    notifyMsg: PropTypes.func,
    getInitialPage: PropTypes.func,
  };

  state = {
    type: null,
    audience: null,
    sellBg: null,
    titleColor: null,
    titleSize: null,
    titleFont: null,
    isInitialShape: true,
    isInitialAudience: true,
  };

  notify = (text) => toast(text);

  changeTextColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  publishButton = () => {
    const { dontSellMyDataButton } = this.props.legalsite;
    const { sellBg, type, audience, titleSize, titleColor, titleFont } =
      this.state;

    this.props
      .configureDataButton({
        variables: {
          buttonType: type
            ? type
            : dontSellMyDataButton && dontSellMyDataButton.buttonType
            ? dontSellMyDataButton.buttonType
            : "contained",
          targetAudience: audience
            ? audience
            : dontSellMyDataButton && dontSellMyDataButton.targetAudience
            ? dontSellMyDataButton.targetAudience
            : "ALL",
          color: sellBg
            ? sellBg
            : dontSellMyDataButton && dontSellMyDataButton.color
            ? dontSellMyDataButton.color
            : "#7450c8",
          textColor: titleColor
            ? titleColor
            : dontSellMyDataButton && dontSellMyDataButton.textColor
            ? dontSellMyDataButton.textColor
            : "#fff",
          textFont: titleFont
            ? titleFont
            : dontSellMyDataButton && dontSellMyDataButton.textFont
            ? dontSellMyDataButton.textFont
            : "Roboto",
          textSize: titleSize
            ? titleSize
            : dontSellMyDataButton && dontSellMyDataButton.textSize
            ? parseInt(dontSellMyDataButton.textSize)
            : 14,
        },
      })
      .then(() => {
        this.notify("Data Button Published");
        this.props.legalsite.refetch();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Data Button couldn't be published");
      });
  };

  changeBgColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeFontSize = (name, size) => {
    this.setState({
      [name]: parseInt(size),
    });
  };

  getGoogleFont = (name, font) => {
    this.setState({
      [name]: font,
    });
  };

  selectAudience = (audience) => {
    this.setState({
      audience,
      isInitialAudience: false,
    });
  };

  render() {
    const { type, audience, isInitialShape, isInitialAudience } = this.state;
    const {
      loading,
      error,
      account,
      legalsite,
      pages,
      dontSellMyDataButton,
      legalsiteSettings,
    } = this.props.legalsite;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <Fragment>
        <div className="justify-content-between d-flex w-100">
          <FontLoader
            font1={this.state.titleFont ? this.state.titleFont : "Roboto"}
            font2={
              dontSellMyDataButton && dontSellMyDataButton.textFont
                ? dontSellMyDataButton.textFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.footerLinksFont
                ? legalsite.footerLinksFont
                : "Roboto"
            }
            font4={
              legalsite && legalsite.footerTextFont
                ? legalsite.footerTextFont
                : "Roboto"
            }
          />
          <Fragment>
            <div className="customise-sell container">
              <p className="customise-sell__title">Don’t sell data Button</p>

              <p className="customise-sell__subtitle">
                The CCPA requires websites to display a “Do Not Sell My Info”
                button to Californian visitors. Use the snippet code on the
                right sidebar to add the button at the bottom of any page where
                you collect user data. It links to your company’s Request page,
                on your LegalSite, where the user will fill in a form to request
                that you stop further selling their information.{" "}
              </p>

              <div className="customise-sell__preview">
                <ViewIcon color="#7450c8" />
                &nbsp; Preview button on footer
              </div>

              <CustomizeSellFooter
                legalsite={legalsite}
                legalsiteSettings={legalsiteSettings}
                pages={pages}
                type={
                  type
                    ? type
                    : dontSellMyDataButton && dontSellMyDataButton.buttonType
                    ? dontSellMyDataButton.buttonType
                    : "contained"
                }
                titleColor={
                  this.state.titleColor
                    ? this.state.titleColor
                    : dontSellMyDataButton && dontSellMyDataButton.textColor
                    ? dontSellMyDataButton.textColor
                    : "#fff"
                }
                titleFont={
                  this.state.titleFont
                    ? this.state.titleFont
                    : dontSellMyDataButton && dontSellMyDataButton.textFont
                    ? dontSellMyDataButton.textFont
                    : "Roboto"
                }
                titleSize={
                  this.state.titleSize
                    ? this.state.titleSize
                    : dontSellMyDataButton && dontSellMyDataButton.textSize
                    ? parseInt(dontSellMyDataButton.textSize)
                    : 14
                }
                sellBg={
                  this.state.sellBg
                    ? this.state.sellBg
                    : dontSellMyDataButton && dontSellMyDataButton.color
                    ? dontSellMyDataButton.color
                    : "#7450c8"
                }
              />

              <p className="customise-sell__label">Button Type</p>

              <div className="d-flex flex-wrap mt-3 mb-5">
                <div className="d-flex align-items-center settings__user-keep ml-0">
                  <span
                    className={`${
                      (dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        isInitialShape) ||
                      (dontSellMyDataButton &&
                        !dontSellMyDataButton.buttonType &&
                        isInitialShape) ||
                      (!isInitialShape && type === "contained")
                        ? "-is-active"
                        : ""
                    } settings__user-check mr-2`}
                    onClick={() =>
                      this.setState({
                        type: "contained",
                        isInitialShape: false,
                      })
                    }
                  />
                  Contained
                </div>

                <div className="d-flex align-items-center settings__user-keep">
                  <span
                    className={`${
                      (dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType &&
                        dontSellMyDataButton.buttonType === "text_only" &&
                        isInitialShape) ||
                      (!isInitialShape && type === "text_only")
                        ? "-is-active"
                        : ""
                    } settings__user-check mr-2`}
                    onClick={() =>
                      this.setState({
                        type: "text_only",
                        isInitialShape: false,
                      })
                    }
                  />
                  Text
                </div>
              </div>

              <p className="customise-sell__label">Target audiences</p>

              <p className="customise-sell__subtitle">
                Select who will see the ‘Do Not Sell My Info’ button and card on
                the requests page, on your LegalSite. This way you can limit who
                will be allowed to send you this type of request.
              </p>

              <UncontrolledDropdown className="chart__dropdown w-100">
                <DropdownToggle className="d-flex align-items-center justify-content-between text-left settings__cookies-toggle">
                  {(dontSellMyDataButton &&
                    dontSellMyDataButton.targetAudience &&
                    dontSellMyDataButton.targetAudience === "ALL" &&
                    isInitialAudience) ||
                  (!isInitialAudience && audience === "ALL") ||
                  (dontSellMyDataButton && !dontSellMyDataButton.targetAudience)
                    ? "All website visitors"
                    : "Only for Californian visitors - comply to CCPA"}
                  <FaCaret className="chart__caret" size="20" color="#969696" />
                </DropdownToggle>
                <DropdownMenu>
                  {(dontSellMyDataButton &&
                    dontSellMyDataButton.targetAudience &&
                    dontSellMyDataButton.targetAudience ===
                      "CALIFORNIAN_VISITORS" &&
                    isInitialAudience) ||
                  (!isInitialAudience &&
                    audience === "CALIFORNIAN_VISITORS") ? (
                    <DropdownItem
                      className="settings__cookies-menu-item"
                      onClick={() => this.selectAudience("ALL")}
                    >
                      All website visitors
                    </DropdownItem>
                  ) : null}

                  {(dontSellMyDataButton &&
                    dontSellMyDataButton.targetAudience &&
                    dontSellMyDataButton.targetAudience === "ALL" &&
                    isInitialAudience) ||
                  (!isInitialAudience && audience === "ALL") ||
                  (dontSellMyDataButton &&
                    !dontSellMyDataButton.targetAudience) ? (
                    <DropdownItem
                      className="settings__cookies-menu-item"
                      onClick={() =>
                        this.selectAudience("CALIFORNIAN_VISITORS")
                      }
                    >
                      Only for Californian visitors - comply to CCPA
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            <RightSideMenu
              isAdmin={this.props.isAdmin}
              showCustomization={this.props.showCustomization}
              isEmailCustomization={false}
              btnType={
                type
                  ? type
                  : dontSellMyDataButton && dontSellMyDataButton.buttonType
                  ? dontSellMyDataButton.buttonType
                  : "contained"
              }
              account={account}
              isCookieCustomization={false}
              isSiteCustomizatio={false}
              isSell={true}
              index={198}
              sellBtnColor={
                this.state.titleColor !== null
                  ? this.state.titleColor
                  : dontSellMyDataButton && dontSellMyDataButton.textColor
                  ? dontSellMyDataButton.textColor
                  : "#fff"
              }
              sellBtnSize={
                this.state.titleSize !== null
                  ? this.state.titleSize
                  : dontSellMyDataButton && dontSellMyDataButton.textSize
                  ? parseInt(dontSellMyDataButton.textSize)
                  : 14
              }
              sellBtnFont={
                this.state.titleFont !== null
                  ? this.state.titleFont
                  : dontSellMyDataButton && dontSellMyDataButton.textFont
                  ? dontSellMyDataButton.textFont
                  : "Roboto"
              }
              sellSnippet={
                dontSellMyDataButton && dontSellMyDataButton.snippet
                  ? dontSellMyDataButton.snippet
                  : ""
              }
              sellBg={
                this.state.sellBg !== null
                  ? this.state.sellBg
                  : dontSellMyDataButton && dontSellMyDataButton.color
                  ? dontSellMyDataButton.color
                  : "#7450c8"
              }
              accountData={this.props.accountData}
              changeBgColor={this.changeBgColor}
              notifyMsg={this.props.notifyMsg}
              changeTextColor={this.changeTextColor}
              publishButton={this.publishButton}
              changeEmailBg={this.changeEmailBg}
              changeFontSize={this.changeFontSize}
              saveCustomizedEmails={this.saveCustomizedEmails}
              showSteps={this.showSteps}
              getGoogleFont={this.getGoogleFont}
              getInitialPage={this.props.getInitialPage}
            />
          </Fragment>

          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            newestOnTop={false}
            closeButton={false}
            closeOnClick
            rtl={false}
            draggable
            hideProgressBar
          />
        </div>
      </Fragment>
    );
  }
}

export default compose(
  graphql(CUSTOMIZE_SELL_QUERY, {
    name: "legalsite",
  }),
  graphql(CONFIGURE_DATA_BUTTON, {
    name: "configureDataButton",
  })
)(CustomizeSell);
