import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import laugh from "../../images/Dashboard/laugh.svg";
import smile from "../../images/Dashboard/smile.svg";
import speechless from "../../images/Dashboard/speechless.svg";
import jobs from "../../config/jobs";
import industries from "../../config/industries";
import companySizes from "../../config/companySizes";

export default class Onboarding extends Component {
  static propTypes = {
    openOnboardingModal: PropTypes.func.isRequired,
    isOnboardingModalOpen: PropTypes.bool.isRequired,
  };

  state = {
    step: 1,
    job: null,
    industry: null,
    companySize: null,
    isSelected: null,
    peopleType: "",
    mainBenefit: "",
    improve: "",
  };

  render() {
    const { step, job, industry, companySize, isSelected } = this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isOnboardingModalOpen}
          toggle={this.props.openOnboardingModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openOnboardingModal}
            />

            <Form
              onSubmit={this.props.openOnboardingModal}
              action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSe00anjq3UuKM7qmaxDQJ5e65MOVFwh6GeJY0ER8j7bnyUdHg/formResponse"
              target="formSubmitFrame"
            >
              <iframe
                name="formSubmitFrame"
                title="Holds Submitted form data"
                rel="nofollow"
                className="d-none"
              ></iframe>

              <Input
                name="entry.1223100986"
                type="text"
                className="d-none"
                defaultValue={
                  isSelected && isSelected === 1
                    ? "Excited"
                    : isSelected && isSelected === 2
                    ? "Good"
                    : isSelected && isSelected === 3
                    ? "Ok"
                    : ""
                }
              />

              <Input
                type="text"
                name="entry.408988245"
                className="d-none"
                defaultValue={this.state.peopleType}
              />
              <Input
                type="text"
                name="entry.1803616572"
                className="d-none"
                defaultValue={this.state.mainBenefit}
              />
              <Input
                type="text"
                name="entry.1762705547"
                className="d-none"
                defaultValue={this.state.improve}
              />
              <Input
                type="text"
                name="entry.1839158210"
                className="d-none"
                defaultValue={this.state.job ? this.state.job : ""}
              />
              <Input
                type="text"
                name="entry.221880469"
                className="d-none"
                defaultValue={this.state.industry ? this.state.industry : ""}
              />
              <Input
                type="text"
                name="entry.231270270"
                className="d-none"
                defaultValue={
                  this.state.companySize ? this.state.companySize : ""
                }
              />

              <p className="modal-assign__title mb-3">Tell us about yourself</p>
              <p className="modal-assign__text mb-5 mt-0">
                To optimize your experience using our app, please answer these
                questions below.
              </p>

              {step === 1 ? (
                <Fragment>
                  <p className="modal-assign__text mb-0 mt-0">
                    How do you feel about finding LegalSite?
                  </p>

                  <div className="d-flex modal-assign__feedback-faces justify-content-between w-100">
                    <div
                      className={`${
                        isSelected && isSelected === 1
                          ? "-is-selected"
                          : isSelected
                          ? "-is-grayed-out"
                          : ""
                      } "d-flex align-items-center justify-content-center flex-column modal-assign__feedback-face`}
                      onClick={() => this.setState({ isSelected: 1 })}
                    >
                      <img src={laugh} alt="laugh" />
                      <span className="mt-2">Excited</span>
                    </div>
                    <div
                      className={`${
                        isSelected && isSelected === 2
                          ? "-is-selected"
                          : isSelected
                          ? "-is-grayed-out"
                          : ""
                      } "d-flex align-items-center justify-content-center flex-column modal-assign__feedback-face`}
                      onClick={() => this.setState({ isSelected: 2 })}
                    >
                      <img src={smile} alt="smile" />
                      <span className="mt-2">Good</span>
                    </div>
                    <div
                      className={`${
                        isSelected && isSelected === 3
                          ? "-is-selected"
                          : isSelected
                          ? "-is-grayed-out"
                          : ""
                      } "d-flex align-items-center justify-content-center flex-column modal-assign__feedback-face`}
                      onClick={() => this.setState({ isSelected: 3 })}
                    >
                      <img src={speechless} alt="speechless" />
                      <span className="mt-2">Ok</span>
                    </div>
                  </div>

                  <div className="discard-changes__btns mb-4">
                    <Button className="btn--secondary border-0" type="submit">
                      Skip
                    </Button>
                    <Button
                      className="btn--primary"
                      onClick={() => this.setState({ step: 2 })}
                    >
                      Next
                    </Button>
                  </div>
                </Fragment>
              ) : step === 2 ? (
                <div className="modal-assign__feedback-form mx-auto">
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      What type of people do you think would benefit from
                      LegalSite?
                    </Label>
                    <Input
                      type="text"
                      placeholder="Type of people..."
                      name="peopleType"
                      onChange={(e) =>
                        this.setState({ peopleType: e.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      What is the main benefit you expect to receive from
                      LegalSite?
                    </Label>
                    <Input
                      type="text"
                      placeholder="Main benefit..."
                      name="mainBenefit"
                      onChange={(e) =>
                        this.setState({ mainBenefit: e.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      When you were researching our product, did you find a way
                      we could improve LegalSite for you?
                    </Label>
                    <Input
                      type="text"
                      placeholder="Improve..."
                      name="improve"
                      onChange={(e) =>
                        this.setState({ improve: e.target.value })
                      }
                    />
                  </FormGroup>

                  <span className="discard-changes__btns mb-4">
                    <Button className="btn--secondary border-0" type="submit">
                      Skip
                    </Button>

                    <Button
                      className="btn--primary"
                      onClick={() => this.setState({ step: 3 })}
                    >
                      Next
                    </Button>
                  </span>
                </div>
              ) : (
                <div className="modal-assign__feedback-form mx-auto">
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      What is your job title?
                    </Label>
                    <Input
                      type="select"
                      onChange={(e) => this.setState({ job: e.target.value })}
                      name="selectedJob"
                      value={job ? job : "select"}
                    >
                      <option value="select" disabled>
                        {job ? job : "Select"}
                      </option>
                      {jobs.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      In which industry do you work at?
                    </Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        this.setState({ industry: e.target.value })
                      }
                      name="selectedIndustry"
                      value={industry ? industry : "select"}
                    >
                      <option value="select" disabled>
                        {industry ? industry : "Select"}
                      </option>
                      {industries.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label className="modal-assign__feedback-label">
                      What is the size of your company?
                    </Label>
                    <Input
                      type="select"
                      onChange={(e) =>
                        this.setState({ companySize: e.target.value })
                      }
                      name="companySize"
                      value={companySize ? companySize : "select"}
                    >
                      <option value="select" disabled>
                        {companySize ? companySize : "Select"}{" "}
                        {companySize ? "employees" : null}
                      </option>
                      {companySizes.map((item, index) => (
                        <option value={item} key={index}>
                          {item} employees
                        </option>
                      ))}
                    </Input>
                  </FormGroup>

                  <div className="discard-changes__btns mb-4">
                    <Button className="btn--secondary border-0" type="submit">
                      Skip
                    </Button>
                    <Button className="btn--primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
