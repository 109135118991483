import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Alert } from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import { LEGALSITE_SETTINGS_QUERY } from "../../graphql/legalSiteSettings";
import SubmittedModal from "../../components/Modals/SubmittedModal";
import PropTypes from "prop-types";
import "./dpa.scss";

class Dpa extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
  };

  state = {
    isForm: false,
    isSubmittedModal: false,
  };

  showSignForm = () => {
    this.setState({
      isForm: !this.state.isForm,
    });
  };

  submitForm = (e) => {
    e.preventDefault();

    this.openSubmittedModal();
  };

  openSubmittedModal = () => {
    this.setState({
      isSubmittedModal: !this.state.isSubmittedModal,
    });
  };

  closeSubmittedModal = () => {
    window.location.href = "/legalSites";
  };

  render() {
    const { legalsiteSettings, loading, error } = this.props.data;

    if (loading) return <p>Loading...</p>;

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );
    const { isForm } = this.state;

    return (
      <div className="dpa">
        <div className="legalsite__nav">
          <Link
            to="/legalSites"
            className="d-flex align-items-center text-decoration-none"
          >
            {legalsiteSettings?.companyLogo ? (
              <div className="legalsite__logo-container">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </div>
            ) : (
              <span className="legalsite__logo" />
            )}
            <span className="legalsite__brand">
              {legalsiteSettings.companyName}
            </span>
          </Link>

          <span className="legalsite__link">Get your LegalSite</span>
        </div>

        <div className="dpa__term">
          <h1 className="dpa__term-title">
            {!isForm
              ? "Data Processing Agreement"
              : "Signed Data Processing Agreement"}
          </h1>
          <p className="dpa__term-text">
            {!isForm
              ? "Last Modified: November 1, 2018"
              : "If you want to receive a signed copy, complete this form to download a copy of our Data Processing Agreement already signed by us and ready for your signature."}
          </p>

          {!isForm ? (
            <div className="d-flex dpa__btns">
              <Button className="btn--primary mr-4" onClick={this.showSignForm}>
                Request signed copy
              </Button>
              <Button className="btn--secondary bg-transparent">
                Download as PDF
              </Button>
            </div>
          ) : null}
        </div>

        <div className="dpa__content">
          {!isForm ? (
            <div className="container d-flex justify-content-between">
              <div className="dpa__menu">
                <a href="#introduction" className="dpa__menu-title">
                  1.Introduction
                </a>
                <a href="#rights" className="dpa__menu-title">
                  2.Rights we grant you
                </a>
                <span className="dpa__menu-title">3.Introduction</span>
                <span className="dpa__menu-title">4.Rights we grant you</span>
                <span className="dpa__menu-title">5.Introduction</span>
                <span className="dpa__menu-title">6.Rights we grant you</span>
                <span className="dpa__menu-title">7.Introduction</span>
              </div>
              <div className="dpa__info">
                <h2 className="dpa__info-title" id="introduction">
                  1.Introduction
                </h2>
                <p className="dpa__info-text">
                  This website uses cookies to improve user experience. By using
                  our website you consent to all cookies in accordance with our
                  Cookie Policy. This website uses cookies to improve user
                  experience. By using our website you consent to all cookies in
                  accordance with our Cookie Policy.
                </p>

                <h2 className="dpa__info-title" id="rights">
                  2.Rights we grant you
                </h2>
                <h5 className="dpa__info-subtitle">2.1. Service options</h5>
                <p className="dpa__info-text">
                  You can find a description of our service options on our
                  website and we will explain to you which service options are
                  available to you when you create an account. Certain options
                  are offered to you for free. The Spotify service for which no
                  payment is required is referred to as the 'free service'.
                  Other options require payment before you can use them (the
                  'paid subscriptions').
                  <br />
                  <br />
                  If you have purchased or received a code, gift voucher,
                  prepaid offer or other offers that were supplied or sold by or
                  on behalf of Spotify, for access to a paid subscription (the
                  'code'), separate conditions submitted with the code may also
                  apply. apply to your access to the service and you fully agree
                  to such terms. You can also purchase access to a paid
                  subscription through a third party. In such cases, separate
                  terms and conditions applied by such third party may apply to
                  your access to the service, in addition to the agreements.
                </p>

                <h5 className="dpa__info-subtitle">
                  2.2. Processing personal data
                </h5>
                <p className="dpa__info-text">
                  You can find a description of our service options on our
                  website and we will explain to you which service options are
                  available to you when you create an account. Certain options
                  are offered to you for free. The Spotify service for which no
                  payment is required is referred to as the 'free service'.
                  Other options require payment before you can use them (the
                  'paid subscriptions').
                  <br />
                  <br />
                  If you have purchased or received a code, gift voucher,
                  prepaid offer or other offers that were supplied or sold by or
                  on behalf of Spotify, for access to a paid subscription (the
                  'code'), separate conditions submitted with the code may also
                  apply. apply to your access to the service and you fully agree
                  to such terms. You can also purchase access to a paid
                  subscription through a third party. In such cases, separate
                  terms and conditions applied by such third party may apply to
                  your access to the service, in addition to the agreements.
                </p>
              </div>
            </div>
          ) : (
            <Fragment>
              <h3 className="dpa__content-title">Personal information</h3>
              <p className="dpa__content-text">
                Fill in the fields with your data.
              </p>

              <Form className="dpa__content-form">
                <Input className="mb-4" type="text" placeholder="Full name" />
                <div className="d-flex mb-4">
                  <Input className="mr-4" type="email" placeholder="E-mail" />
                  <Input type="tel" placeholder="Phone number" />
                </div>
                <div className="d-flex mb-4">
                  <Input className="mr-4" type="text" placeholder="Company" />
                  <Input type="text" placeholder="Job title" />
                </div>

                <Button
                  className="btn--primary dpa__content-btn"
                  onClick={this.submitForm}
                >
                  Submit
                </Button>
              </Form>
            </Fragment>
          )}
        </div>

        <SubmittedModal
          openSubmittedModal={this.openSubmittedModal}
          closeSubmittedModal={this.closeSubmittedModal}
          isSubmittedModal={this.state.isSubmittedModal}
        />
      </div>
    );
  }
}

export default graphql(LEGALSITE_SETTINGS_QUERY)(Dpa);
