import React, { Component } from "react";
import logo from "../../images/Login/logo purple and white.svg";
import "./sign-in.scss";

export default class CheckEmail extends Component {
  state = {};

  render() {
    return (
      <div className="login">
        <div className="login__banner">
          <div className="login__banner-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="login__form">
          <div className="login__form-container h-100">
            <div className="h-100 d-flex flex-column justify-content-around">
              <div>
                <h2 className="login__form-title">Check your e-mail!</h2>

                <p className="login__form-create mb-5">
                  Click on the verification link we just sent in your e-mail to
                  log in.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
