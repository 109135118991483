import gql from "graphql-tag";

export const PAGES_DATA_QUERY = gql`
  {
    pages {
      applicable
      description
      id
      status
      title
    }
  }
`;
