import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Sorry from "../../images/Inbox/Illustration.png";
import "./modals.scss";

export default class AssignedRequest extends Component {
  static propTypes = {
    showAssignedModal: PropTypes.func.isRequired,
    isAssignedModalVisible: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isAssignedModalVisible}
          toggle={this.props.showAssignedModal}
          className="modal-assign"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showAssignedModal}
            />
            <div className="modal-assign__image">
              <img src={Sorry} alt="sorry" />
            </div>
            <p className="modal-assign__title">
              Sorry, you can't perform an action while another user is assigned
            </p>
            <p className="modal-assign__message">
              Another user was assigned to upload data on this request. If you
              want solve this request, please undo to assignment.
            </p>
            <Link to="/dashboard" className="modal-assign__link">
              Home page <MdArrow size={23} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
