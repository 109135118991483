import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Input, FormFeedback } from "reactstrap";
import categories from "../../config/DPCategories";
import FaCaret from "react-icons/lib/fa/angle-down";

export default class DPAccountInfo extends Component {
  static propTypes = {
    isIndex: PropTypes.bool,
    checkIndex: PropTypes.func.isRequired,
    showDpProfile: PropTypes.func.isRequired,
    dataProcessorSettings: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    formErrors: PropTypes.object,
    isVisible: PropTypes.bool,
  };

  state = {
    category: null,
    categories: [],
    isPublished:
      this.props.isVisible !== null
        ? this.props.isVisible
        : this.props.dataProcessorSettings &&
          this.props.dataProcessorSettings.show
        ? true
        : false,
  };

  componentDidMount() {
    this.showData();
  }

  showData = async () => {
    try {
      this.setState({
        categories,
      });
    } catch (err) {
      console.log(err);
    }
  };

  selectCategory = (e) => {
    this.setState({
      category: e.target.value,
    });
  };

  render() {
    const {
      isIndex,
      checkIndex,
      showDpProfile,
      dataProcessorSettings,
      handleSubmit,
      formErrors,
    } = this.props;

    const { isPublished, category, categories } = this.state;

    return (
      <Form className="data-processing__form" onSubmit={handleSubmit}>
        <FormGroup className="mb-4">
          <Input
            type="text"
            name="companyName"
            placeholder="Company name"
            invalid={formErrors.companyName}
            defaultValue={
              dataProcessorSettings ? dataProcessorSettings.companyName : ""
            }
          />
          <FormFeedback>Please add the company name</FormFeedback>
        </FormGroup>

        <FormGroup className="mb-4">
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            invalid={formErrors.email}
            defaultValue={
              dataProcessorSettings ? dataProcessorSettings.email : ""
            }
          />
          <FormFeedback>Please add the email</FormFeedback>
        </FormGroup>

        <FormGroup className="mb-4">
          <Input
            type="text"
            name="website"
            placeholder="Website"
            invalid={formErrors.website}
            defaultValue={
              dataProcessorSettings ? dataProcessorSettings.website : ""
            }
          />
          <FormFeedback>Please add the website</FormFeedback>
        </FormGroup>

        <FormGroup className="position-relative">
          <Input
            type="select"
            name="category"
            className="mb-0 w-100"
            onChange={this.selectCategory}
            invalid={formErrors.category}
            value={
              category
                ? category
                : dataProcessorSettings && dataProcessorSettings.category
                ? dataProcessorSettings.category
                : "Category"
            }
          >
            <option value="Category" disabled>
              Category
            </option>
            {categories.map((item, index) => (
              <option value={item} key={index}>
                {item === "OTHERS"
                  ? "Others"
                  : item === "STRATEGY_AND_ORGANIZATION"
                  ? "Strategy & Organization"
                  : item === "BUSINESS_OPERATIONS"
                  ? "Business Operations"
                  : item === "MARKETING"
                  ? "Marketing"
                  : item === "SALES"
                  ? "Sales"
                  : item === "HUMAN_RESOURCES"
                  ? "Human Resources"
                  : item === "FINANCE"
                  ? "Finance"
                  : item === "LEGAL"
                  ? "Legal"
                  : item === "FACILITIES"
                  ? "Facilities"
                  : item === "GOVERMENT_OR_AUTHORITY"
                  ? "Government / Authority"
                  : "System Integrity"}
              </option>
            ))}
          </Input>
          <FormFeedback>Please choose a category</FormFeedback>
          <FaCaret className="position-absolute settings__input-icon" />
        </FormGroup>

        <p className="settings__text">
          Do you want your company to appear on the Data Processors index? By
          selecting yes all LegalSite users will be able to see your company
          profile and add you as their Data Processor.
        </p>

        <div className="d-flex flex-wrap mt-4">
          <div className="d-flex align-items-center settings__user-keep ml-0">
            <span
              className={`${
                isPublished ? "-is-active" : ""
              } settings__user-check mr-2`}
              onClick={() => {
                this.setState({ isPublished: true });
                checkIndex(true);
              }}
            />
            Yes
          </div>

          <div className="d-flex align-items-center settings__user-keep">
            <span
              className={`${
                !isPublished ? "-is-active" : ""
              } settings__user-check mr-2`}
              onClick={() => {
                this.setState({ isPublished: false });
                checkIndex(false);
              }}
            />
            No
          </div>
        </div>

        <div className="d-flex flex-column">
          {isPublished ? (
            <Button
              className="btn--secondary data-processing__manage mt-4 mb-4"
              onClick={() =>
                showDpProfile(
                  dataProcessorSettings && dataProcessorSettings.id
                    ? dataProcessorSettings.id
                    : "",
                  true
                )
              }
            >
              Manage Profile
            </Button>
          ) : null}

          <Button
            type="submit"
            className={`${
              !isIndex ? "mt-4" : ""
            } btn--primary data-processing__save`}
          >
            Save changes
          </Button>
        </div>
      </Form>
    );
  }
}
