import gql from "graphql-tag";

export const TOGGLE_EMAIL_NOTIFICATIONS = gql`
  mutation($clientMutationId: String) {
    toggleEmailNotifications(input: { clientMutationId: $clientMutationId }) {
      errors
      viewer {
        id
        emailNotifications
      }
    }
  }
`;
