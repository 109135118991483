import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import FaCaretUp from "react-icons/lib/fa/angle-up";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import HelpIcon from "../../components/Icons/HelpIcon";
import ShareIcon from "../../components/Icons/ShareIcon";
import EditPencilSquare from "../../components/Icons/EditPencilSquare";
import PublishLegalSite from "../../components/Modals/PublishLegalSite";
import UserNotification from "../../components/Modals/UserNotification";
import PageRightMenuItem from "./PageRightMenuItem";
import PublishNotification from "../../components/Modals/PublishNotification";
import EditPageDetails from "../../components/Modals/EditPageDetails";
import DiscardChanges from "../../components/Modals/DiscardChanges";
import Moment from "react-moment";
import TrashIcon from "../../components/Icons/TrashIcon";
import PlusCircle from "../../components/Icons/PlusCircle";
import HelpTextForm from "./HelpTextForm";
import DeletePage from "../../components/Modals/DeletePage";

let content;

export default class PageRightMenu extends Component {
  static propTypes = {
    download: PropTypes.func,
    share: PropTypes.func,
    isAdmin: PropTypes.bool,
    subdomain: PropTypes.string,
    approveChange: PropTypes.func,
    isAdminNewPage: PropTypes.bool,
    publishPage: PropTypes.func,
    showPreview: PropTypes.func,
    getActivePublicationId: PropTypes.func,
    activePublicationId: PropTypes.number,
    showPage: PropTypes.func,
    showCurrentVersion: PropTypes.func,
    showOtherVersion: PropTypes.func,
    useVersion: PropTypes.func,
    editHelpText: PropTypes.func,
    deletePage: PropTypes.func,
    submitPageTemplateDetails: PropTypes.func,
    savePageDetails: PropTypes.func,
    getFormValues: PropTypes.func,
    submitPageDetails: PropTypes.func,
    accountId: PropTypes.number,
    isAdminPage: PropTypes.bool,
    alerts: PropTypes.array,
    pageTemplate: PropTypes.object,
    contentPage: PropTypes.object,
    selectedItem: PropTypes.object,
    viewer: PropTypes.object,
    isPreview: PropTypes.bool,
    activeItemIndex: PropTypes.number,
  };

  state = {
    isPublishLegalSiteVisible: false,
    hasChanges: false,
    isEditPageDetailsVisible: false,
    isDiscardModalVisible: false,
    isPreview: false,
    publicationId: null,
    isUserNotificationVisible: false,
    isPublishNotificationVisible: false,
    isHelp: false,
    isEdit: false,
    isDeleteModalVisible: false,
  };

  showPublishLegalSite = () => {
    this.setState({
      isPublishLegalSiteVisible: !this.state.isPublishLegalSiteVisible,
    });
  };

  showChanges = () => {
    this.setState({
      hasChanges: !this.state.hasChanges,
    });
  };

  showEditPageDetails = () => {
    this.setState({
      isEditPageDetailsVisible: !this.state.isEditPageDetailsVisible,
    });
  };

  approveChange = () => {
    this.showPublishLegalSite();
  };

  discardChanges = () => {
    this.setState({
      isDiscardModalVisible: !this.state.isDiscardModalVisible,
    });
  };

  preview = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });

    this.props.showPreview();
  };

  getActivePublicationId = (id) => {
    this.setState({
      publicationId: id,
    });

    this.props.getActivePublicationId(id);
  };

  showPublishNotification = () => {
    this.setState({
      isPublishNotificationVisible: !this.state.isPublishNotificationVisible,
    });
  };

  showUserNotification = () => {
    this.setState({
      isUserNotificationVisible: !this.state.isUserNotificationVisible,
    });
  };

  resetActivePublicationId = () => {
    this.setState({
      publicationId: null,
    });

    this.props.getActivePublicationId(null);
  };

  redirectToDashboard = () => {
    window.location.href = "/dashboard";
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const helpText = formData.get("help-text");

    this.props.editHelpText(helpText, content.id);
    this.setState({ isEdit: false });
  };

  deleteHelpText = () => {
    this.props.editHelpText("", content.id);
    this.setState({ isEdit: false });
  };

  showDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  render() {
    const { hasChanges, isEditPageDetailsVisible, isEdit } = this.state;
    const {
      isPreview,
      selectedItem,
      contentPage,
      isAdmin,
      isAdminNewPage,
      subdomain,
      alerts,
      isAdminPage,
      pageTemplate,
      viewer,
      activeItemIndex,
    } = this.props;

    const currentAlert = !isAdminPage
      ? alerts.filter((item) =>
          item.category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
            ? item.page.id === contentPage.id
            : null
        )
      : null;

    const detailsAlert = !isAdminPage
      ? alerts.filter((item) =>
          item.category === "REVIEW_TEMPLATE_CHANGES"
            ? item.page.id === contentPage.id
            : null
        )
      : null;

    content = isAdminPage
      ? pageTemplate && pageTemplate.sections && pageTemplate.sections.length
        ? pageTemplate.sections[activeItemIndex - 1]
        : null
      : contentPage && contentPage.sections && contentPage.sections.length
      ? contentPage.sections[activeItemIndex - 1]
      : null;

    return !isPreview ? (
      <div className="page-right-menu">
        {currentAlert && currentAlert.length > 0 ? (
          <span
            className="text-center"
            style={{ position: "relative", top: -20 }}
          >
            {currentAlert.length} new page{" "}
            {currentAlert.length === 1 ? "template" : "templates"}
          </span>
        ) : null}
        <div className="page-right-menu__btns">
          <Button
            className="btn--primary mb-2"
            onClick={
              currentAlert && currentAlert.length > 0
                ? () => {
                    this.props.approveChange(
                      true,
                      currentAlert[0].id,
                      currentAlert[0].templatePublication.id
                    );
                    this.approveChange();
                  }
                : isAdminPage ||
                  (viewer && viewer.viewer && viewer.viewer.publishedPage)
                ? this.showUserNotification
                : () => {
                    this.props.publishPage();
                  }
            }
          >
            {currentAlert && currentAlert.length > 0
              ? "Approve changes"
              : "Publish page"}
          </Button>

          <Button
            className="btn--secondary mb-2"
            onClick={
              currentAlert && currentAlert.length > 0
                ? this.discardChanges
                : this.props.showPreview
            }
          >
            {currentAlert && currentAlert.length > 0
              ? "Discard changes"
              : "Preview"}
          </Button>
          <Button
            className="btn--gray"
            onClick={
              isAdmin || isAdminNewPage || isAdminPage
                ? this.redirectToDashboard
                : currentAlert && currentAlert.length > 0
                ? this.preview
                : this.props.showPage
            }
          >
            {currentAlert && currentAlert.length > 0
              ? "Preview"
              : "Save as draft"}
          </Button>
        </div>

        <div className="relative">
          <p
            onClick={this.showChanges}
            className={`${
              currentAlert && currentAlert.length > 0 ? "-is-disabled" : ""
            } page-right-menu__last-edit`}
          >
            Last edit was made{" "}
            <Moment fromNow>
              {currentAlert && currentAlert.length > 0
                ? contentPage
                  ? contentPage.pageTemplate.createdAt
                  : null
                : !isAdminPage
                ? contentPage.updatedAt
                : pageTemplate
                ? pageTemplate.updatedAt
                : null}
            </Moment>{" "}
            {hasChanges ? (
              <FaCaretUp className="" size="20" color="#7450c8" />
            ) : (
              <FaCaret className="" size="20" color="#7450c8" />
            )}
          </p>
          {hasChanges ? (
            <div>
              <PageRightMenuItem
                isCurrent={true}
                contentPageId={
                  contentPage
                    ? contentPage.id
                    : pageTemplate
                    ? pageTemplate.id
                    : null
                }
                date={
                  !isAdminPage
                    ? selectedItem
                      ? selectedItem.updatedAt
                      : ""
                    : pageTemplate
                    ? pageTemplate.updatedAt
                    : ""
                }
                useVersion={this.props.useVersion}
                showCurrentVersion={this.props.showCurrentVersion}
                resetActivePublicationId={this.resetActivePublicationId}
                activePublicationId={this.props.activePublicationId}
              />

              {!isAdminPage
                ? contentPage &&
                  contentPage.publications
                    .filter((item) => item.sections.length)
                    .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                    .map((item, i) => (
                      <PageRightMenuItem
                        contentPageId={
                          contentPage
                            ? contentPage.id
                            : pageTemplate
                            ? pageTemplate.id
                            : null
                        }
                        key={i}
                        item={item}
                        useVersion={this.props.useVersion}
                        activePublicationId={this.props.activePublicationId}
                        showOtherVersion={this.props.showOtherVersion}
                        id={item.id}
                        index={i}
                        publicationId={this.state.publicationId}
                        getActivePublicationId={this.getActivePublicationId}
                        date={item.createdAt}
                      />
                    ))
                : pageTemplate &&
                  pageTemplate.templatePublications.map((item, i) => (
                    <PageRightMenuItem
                      contentPageId={
                        contentPage
                          ? contentPage.id
                          : pageTemplate
                          ? pageTemplate.id
                          : null
                      }
                      useVersion={this.props.useVersion}
                      activePublicationId={this.props.activePublicationId}
                      key={i}
                      showOtherVersion={this.props.showOtherVersion}
                      id={item.id}
                      index={i}
                      publicationId={this.state.publicationId}
                      getActivePublicationId={this.getActivePublicationId}
                      date={item.createdAt}
                    />
                  ))}
            </div>
          ) : null}
        </div>

        {!hasChanges ? (
          <div className="w-100 page-right-menu__items pl-2">
            <p
              className="page-right-menu__download"
              onClick={this.showEditPageDetails}
            >
              <EditPencilSquare
                className="page-right-menu__icons"
                color="#7450c8"
              />{" "}
              Edit page details
            </p>
            {(pageTemplate && pageTemplate.publicationDownloadUrl) ||
            (contentPage && contentPage.publicationDownloadUrl) ? (
              <p
                className="page-right-menu__download"
                onClick={() =>
                  this.props.download(
                    !isAdminPage
                      ? contentPage.publicationDownloadUrl
                      : pageTemplate
                      ? pageTemplate.publicationDownloadUrl
                      : null,
                    !isAdminPage
                      ? contentPage
                        ? contentPage.title
                        : "file"
                      : pageTemplate
                      ? pageTemplate.title
                      : "file"
                  )
                }
              >
                <DownloadIcon
                  className="page-right-menu__icons"
                  color="#7450c8"
                />{" "}
                Download
              </p>
            ) : null}
            {!isAdminPage ? (
              <p
                className={`${
                  !contentPage.applicable ? "-is-disabled" : ""
                } page-right-menu__download`}
                onClick={() =>
                  this.props.share(
                    subdomain,
                    contentPage.title.replace("&", "").replace(/\s/g, "")
                  )
                }
              >
                <ShareIcon className="page-right-menu__icons" color="#7450c8" />{" "}
                Share
              </p>
            ) : null}
            {isAdminPage &&
            pageTemplate &&
            pageTemplate.title.trim() !== "Cookies Policy" &&
            pageTemplate.title !== "Terms & Conditions" &&
            pageTemplate.title !== "Privacy Policy" &&
            pageTemplate.title !== "Data Processing Agreement" &&
            pageTemplate.title !== "Privacy policy" ? (
              <p
                className="page-right-menu__download"
                onClick={this.showDeleteModal}
              >
                <TrashIcon className="page-right-menu__icons" color="#7450c8" />{" "}
                Delete page
              </p>
            ) : null}
            <p
              className={`page-right-menu__download ${
                content && !content.help && !isAdminPage ? "d-none" : ""
              }`}
              onClick={() => this.setState({ isHelp: !this.state.isHelp })}
            >
              {!isAdminPage ? (
                <HelpIcon className="page-right-menu__icons" color="#7450c8" />
              ) : (
                <PlusCircle
                  className="page-right-menu__icons"
                  color="#7450c8"
                />
              )}{" "}
              {content && content.help && !isAdminPage
                ? "Help"
                : isAdminPage
                ? "Add help text in this section"
                : null}{" "}
              {(content && content.help && !isAdminPage) || isAdminPage ? (
                this.state.isHelp ? (
                  <FaCaretUp
                    className={`${!isAdminPage ? "ml-5" : "ml-0"}`}
                    size="20"
                    color="#7450c8"
                  />
                ) : (
                  <FaCaret
                    className={`${!isAdminPage ? "ml-5" : "ml-0"}`}
                    size="20"
                    color="#7450c8"
                  />
                )
              ) : null}
            </p>

            {this.state.isHelp && !isAdminPage ? (
              content && !content.help ? null : (
                <span className="page-right-menu__help-text">
                  {content.help}
                </span>
              )
            ) : this.state.isHelp && isAdminPage ? (
              <div className="page-right-menu__help-section">
                {content && !content.help ? (
                  <p className="page-right-menu__help-section-title">Help</p>
                ) : null}

                {content && content.help ? (
                  <div>
                    {!isEdit ? (
                      <p className="mb-0">{content ? content.help : ""}</p>
                    ) : (
                      <HelpTextForm
                        handleSubmit={this.handleSubmit}
                        cancel={() =>
                          this.setState({
                            isEdit: false,
                          })
                        }
                        value={content.help}
                      />
                    )}
                    <div>
                      <EditPencilSquare
                        onClick={() => this.setState({ isEdit: true })}
                        className="page-right-menu__icons mr-3"
                      />
                      <TrashIcon
                        onClick={this.deleteHelpText}
                        className="page-right-menu__icons"
                        color="#7450c8"
                      />
                    </div>
                  </div>
                ) : null}

                {content && !content.help ? (
                  <HelpTextForm
                    handleSubmit={this.handleSubmit}
                    cancel={() =>
                      this.setState({
                        isHelp: !this.state.isHelp,
                      })
                    }
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}

        <DeletePage
          showDeleteModal={this.showDeleteModal}
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          removePage={this.props.deletePage}
        />

        <PublishLegalSite
          isPages={true}
          goToPages={this.props.showPage}
          isRightMenu={true}
          showPublishLegalSite={this.showPublishLegalSite}
          isPublishLegalSiteVisible={this.state.isPublishLegalSiteVisible}
          accountId={this.props.accountId}
          isAdmin={isAdmin}
          isAdminPage={this.props.isAdminPage}
          subdomain={subdomain}
        />

        <UserNotification
          showUserNotification={this.showUserNotification}
          isUserNotificationVisible={this.state.isUserNotificationVisible}
          name={
            !isAdminPage
              ? contentPage.title
              : pageTemplate
              ? pageTemplate.title
              : ""
          }
          showPublishLegalSite={
            isAdminPage ||
            (viewer && viewer.viewer && viewer.viewer.publishedPage)
              ? this.showPublishLegalSite
              : this.showPublishNotification
          }
          publishPage={this.props.publishPage}
        />

        <PublishNotification
          showPublishNotification={this.showPublishNotification}
          isPublishNotificationVisible={this.state.isPublishNotificationVisible}
        />

        <EditPageDetails
          isEditPageDetailsVisible={isEditPageDetailsVisible}
          showEditPageDetails={this.showEditPageDetails}
          submitPageTemplateDetails={this.props.submitPageTemplateDetails}
          pageName={
            !isAdminPage
              ? detailsAlert && detailsAlert.length
                ? contentPage &&
                  contentPage.pageTemplate &&
                  contentPage.pageTemplate.title
                  ? contentPage.pageTemplate.title
                  : contentPage.title
                : contentPage.title
              : pageTemplate
              ? pageTemplate.title
              : ""
          }
          savePageDetails={this.props.savePageDetails}
          detailsAlert={detailsAlert}
          isAdminNewPage={this.props.isAdminNewPage}
          isAdmin={this.props.isAdminPage}
          pageTemplate={isAdminPage ? pageTemplate : null}
          pageDescription={
            !isAdminPage
              ? detailsAlert && detailsAlert.length !== 0
                ? contentPage &&
                  contentPage.pageTemplate &&
                  contentPage.pageTemplate.description
                  ? contentPage.pageTemplate.description
                  : contentPage.description
                : contentPage.description
              : pageTemplate
              ? pageTemplate.description
              : ""
          }
          pageId={
            !isAdminPage ? contentPage.id : pageTemplate ? pageTemplate.id : ""
          }
          getFormValues={this.props.getFormValues}
          submitPageDetails={this.props.submitPageDetails}
        />

        <DiscardChanges
          discardChanges={this.discardChanges}
          isDiscardModalVisible={this.state.isDiscardModalVisible}
          removeChanges={() => {
            this.props.approveChange(
              false,
              currentAlert[0].id,
              currentAlert[0].templatePublication.id
            );
          }}
        />
      </div>
    ) : null;
  }
}
