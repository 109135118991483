import gql from "graphql-tag";

export const EDIT_DATA_KEEPING_SETTINGS = gql`
  mutation(
    $lifespanData: String
    $lifespanInbox: String
    $clientMutationId: String
  ) {
    editDataKeepingSettings(
      input: {
        lifespanData: $lifespanData
        clientMutationId: $clientMutationId
        lifespanInbox: $lifespanInbox
      }
    ) {
      errors
      account {
        id
        lifespanData
        lifespanInbox
      }
    }
  }
`;
