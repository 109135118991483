import gql from "graphql-tag";

export const EDIT_DPO = gql`
  mutation(
    $name: String!
    $company: String!
    $email: String!
    $description: String!
    $phoneNumber: String!
    $avatar: String!
    $show: Boolean!
    $clientMutationId: String
  ) {
    editDataProtectionOfficer(
      input: {
        name: $name
        company: $company
        email: $email
        description: $description
        phoneNumber: $phoneNumber
        avatar: $avatar
        show: $show
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      dataProtectionOfficer {
        avatar
        company
        description
        email
        id
        lastUpdated
        name
        phoneNumber
        show
        status
      }
    }
  }
`;
