import gql from "graphql-tag";

export const ADD_COOKIE_QUERY = gql`
  {
    consents {
      id
      categoryName
    }
  }
`;
