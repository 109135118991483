import React, { Component } from "react";
import PropTypes from "prop-types";

class DPSupplier extends Component {
  static propTypes = {
    dataSuppliers: PropTypes.array,
    item: PropTypes.object,
    getItem: PropTypes.func,
  };

  state = {
    isSelected: this.props.dataSuppliers
      ? this.props.dataSuppliers
          .toString()
          .includes(this.props.item.dataProcessor.companyName)
      : false,
  };

  render() {
    const { item } = this.props;
    const { isSelected } = this.state;

    return (
      <div
        className={`modal-suppliers__supplies ${
          isSelected ? "-is-selected" : ""
        }`}
        onClick={() => {
          this.setState({
            isSelected: !this.state.isSelected,
          });

          this.props.getItem(!isSelected, item.dataProcessor.companyName);
        }}
      >
        <div className="modal-suppliers__supply">
          {item && item.dataProcessor && item.dataProcessor.logo ? (
            <img src={item.dataProcessor.logo} alt="DP" />
          ) : item.dataProcessor &&
            item.dataProcessor.profile &&
            item.dataProcessor.profile.logo ? (
            <img src={item.dataProcessor.profile.logo} alt="logo" />
          ) : (
            <span className="modal-suppliers__supply-placeholder" />
          )}
        </div>

        <p className="modal-suppliers__label">
          {item && item.dataProcessor && item.dataProcessor.companyName
            ? item.dataProcessor.companyName
            : ""}
        </p>
        <p className="modal-suppliers__text">
          <p className="import__upload-description">
            {item.dataProcessor && item.dataProcessor.description
              ? item.dataProcessor.description
              : item.dataProcessor &&
                item.dataProcessor.profile &&
                item.dataProcessor.profile.smallDescription
              ? item.dataProcessor.profile.smallDescription
              : ""}
          </p>
        </p>
      </div>
    );
  }
}

export default DPSupplier;
