import React, { Component } from "react";
import Image from "../../images/Illustration.svg";
import { Button } from "reactstrap";
import ImportData from "./ImportData";
import ManualData from "./ManualData";
import PropTypes from "prop-types";

export default class EmptyUsers extends Component {
  static propTypes = {
    refetchData: PropTypes.func.isRequired,
    getImportStatus: PropTypes.func.isRequired,
    showImport: PropTypes.func.isRequired,
  };

  state = {
    isPaste: false,
    isImport: false,
  };

  render() {
    const { isImport, isPaste } = this.state;

    return !isImport && !isPaste ? (
      <div className="users users-empty">
        <div className="container users-empty__container">
          <div className="users-empty__img">
            <img src={Image} alt="empty" />
          </div>

          <h2 className="users-empty__title">
            You don't have any user data yet
          </h2>
          <p className="users-empty__text">
            Here you can manage your user index. You can import user data from a
            file or add it manually.
          </p>

          <div className="users-empty__btns">
            <Button
              className="users-empty__import"
              onClick={() => this.setState({ isImport: true })}
            >
              Import
            </Button>
            <Button
              className="users-empty__add"
              onClick={() => this.setState({ isPaste: true })}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    ) : isImport ? (
      <ImportData
        isEmpty={true}
        refetchData={this.props.refetchData}
        getImportStatus={this.props.getImportStatus}
        showImport={this.props.showImport}
      />
    ) : (
      <ManualData
        refetchData={this.props.refetchData}
        isEmpty={true}
        showManualData={() => this.setState({ isPaste: false })}
        showNotify={() => {}}
      />
    );
  }
}
