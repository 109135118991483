import React from "react";
import PropTypes from "prop-types";
import LightBulb from "../../components/Icons/Lightbulb";

function Tip(props) {
  const { isEmail } = props;

  return (
    <div className="customization__tip">
      <LightBulb className="mr-3" color={"#7450c8"} /> TIP:
      <span className="customization__tip-text">
        {isEmail
          ? "Choose your e-mail colors, then edit the text."
          : "Choose your pop-up colors and position, then edit the text."}
      </span>
    </div>
  );
}

export default Tip;

Tip.propTypes = {
  isEmail: PropTypes.bool,
};
