import PropTypes from "prop-types";
import React, { Component } from "react";
import Lightbulb from "../../components/Icons/Lightbulb";

export default class SuggestedAction extends Component {
  static propTypes = {
    showSuggestedActionModal: PropTypes.func,
    isQuestionShowing: PropTypes.bool.isRequired,
    isDataAccessShowing: PropTypes.bool.isRequired,
    isDataErasureShowing: PropTypes.bool.isRequired,
    isSellShowing: PropTypes.bool.isRequired,
    isDataTransfer: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    const {
      isQuestionShowing,
      isDataAccessShowing,
      isDataErasureShowing,
      isDataTransfer,
      isSellShowing,
    } = this.props;
    return (
      <div
        className={`inbox__data-suggestion ${
          isDataErasureShowing
            ? "-is-yellow"
            : isQuestionShowing
            ? "-is-aqua"
            : isDataAccessShowing
            ? "-is-purple"
            : isDataTransfer
            ? "-is-black"
            : isSellShowing
            ? "-is-blue"
            : "-is-coral"
        }`}
      >
        <div>
          <Lightbulb
            color={
              isDataErasureShowing
                ? "#EBA601"
                : isQuestionShowing
                ? "#1DDCC0"
                : isDataAccessShowing
                ? "#7450C8"
                : isSellShowing
                ? "#3a73b8"
                : isDataTransfer
                ? "#292e31"
                : "#F86B67"
            }
          />
          <span
            className={`inbox__data-suggestion-text ${
              isDataErasureShowing
                ? "-is-yellow"
                : isQuestionShowing
                ? "-is-aqua"
                : isDataAccessShowing
                ? "-is-purple"
                : isSellShowing
                ? "-is-blue"
                : isDataTransfer
                ? "-is-black"
                : "-is-coral"
            }`}
          >
            SUGGESTED ACTION:
          </span>
          <span className="inbox__data-suggestion-action">
            {isDataErasureShowing
              ? "Erase the user’s data and upload the confirmation log."
              : isQuestionShowing
              ? "Write an answer to the question."
              : isDataAccessShowing || isDataTransfer
              ? "Upload the user’s data."
              : isSellShowing
              ? "Stop selling the user’s data and notify all data processors."
              : "Write an answer to the complaint."}
          </span>
        </div>

        <span
          onClick={this.props.showSuggestedActionModal}
          className={`inbox__data-suggestion-read mb-0 ${
            isDataErasureShowing
              ? "-is-yellow"
              : isQuestionShowing
              ? "-is-aqua"
              : isDataAccessShowing
              ? "-is-purple"
              : isSellShowing
              ? "-is-blue"
              : isDataTransfer
              ? "-is-black"
              : "-is-coral"
          }`}
        >
          Read more
        </span>
      </div>
    );
  }
}
