import PropTypes from "prop-types";
import React from "react";

const InfoIcon = props => (
    <svg
        height={27}
        viewBox='0 0 27 27'
        width={27}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-0---Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--Dashboard---empty-state" transform="translate(-590.000000, -662.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="help-card" transform="translate(494.000000, 631.000000)">
                    <g id="comment-info" transform="translate(96.000000, 31.000000)">
                        <path d="M13.5190175,12.1494591 C12.7744833,12.1494591 12.1709192,12.7538478 12.1709192,13.499399 L12.1709192,17.5492187 C12.1709192,18.29477 12.7744833,18.8991586 13.5190175,18.8991586 C14.2635516,18.8991586 14.8671157,18.29477 14.8671157,17.5492187 L14.8671157,13.499399 C14.8671157,12.7538478 14.2635516,12.1494591 13.5190175,12.1494591 Z M13.5190175,8.09963942 C12.7744833,8.09963942 12.1709192,8.7040281 12.1709192,9.44957932 C12.1709192,10.1951305 12.7744833,10.7995192 13.5190175,10.7995192 C14.2635516,10.7995192 14.8671157,10.1951305 14.8671157,9.44957932 C14.8671157,8.7040281 14.2635516,8.09963942 13.5190175,8.09963942 Z M13.5190175,1.49873436e-15 C6.07367628,-1.49873436e-15 0.0380347147,6.04388681 0.0380347147,13.499399 C0.0262494086,16.6165916 1.10410032,19.6396522 3.08473681,22.0445186 L0.388540266,24.7443984 C0.00602471146,25.1325881 -0.105668153,25.7131192 0.105439628,26.2158329 C0.334438793,26.7125942 0.84022897,27.0218142 1.38613299,26.9987981 L13.5190175,26.9987981 C20.9643586,26.9987979 27,20.9549112 27,13.499399 C27,6.04388689 20.9643586,1.11095789e-07 13.5190175,1.49873436e-15 Z M13.5190175,24.2989183 L4.63504983,24.2989183 L5.88878123,23.0434741 C6.14607339,22.7909389 6.29171509,22.4457827 6.29321071,22.0850168 C6.28814584,21.7289442 6.14278217,21.3892989 5.88878123,21.1400589 C2.2487586,17.4991133 1.68390658,11.7904563 4.53964098,7.50502051 C7.39537537,3.21958476 12.8764106,1.55080934 17.6310084,3.51917641 C22.3856062,5.48754347 25.0896717,10.5448879 24.0896268,15.598565 C23.089582,20.652242 18.6639264,24.294867 13.5190175,24.2989183 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

InfoIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default InfoIcon;