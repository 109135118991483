import gql from "graphql-tag";

export const EDIT_CONSENT_MESSAGE = gql`
  mutation($consentMessage: String!, $clientMutationId: String) {
    editConsentMessage(
      input: {
        consentMessage: $consentMessage
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      legalsiteSettings {
        id
        consentMessage
      }
    }
  }
`;
