import PropTypes from "prop-types";
import React from "react";

const LockIcon = props => (
    <svg
        height={28}
        viewBox='0 0 28 28'
        width={28}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="3.3--Cookies-preview-1---tooltip" transform="translate(-316.000000, -390.000000)" fill={props.color} fillRule="nonzero">
                <g id="Group-2">
                    <g id="cards" transform="translate(220.000000, 358.000000)">
                        <g id="privacy">
                            <g id="lock-access" transform="translate(96.000000, 32.000000)">
                                <path d="M26.6,0 L18.2,0 C17.4268014,0 16.8,0.62680135 16.8,1.4 C16.8,2.17319865 17.4268014,2.8 18.2,2.8 L25.2,2.8 L25.2,9.8 C25.2,10.5731986 25.8268014,11.2 26.6,11.2 C27.3731986,11.2 28,10.5731986 28,9.8 L28,1.4 C28,0.62680135 27.3731986,0 26.6,0 Z M26.6,16.8 C25.8268014,16.8 25.2,17.4268014 25.2,18.2 L25.2,25.2 L18.2,25.2 C17.4268014,25.2 16.8,25.8268014 16.8,26.6 C16.8,27.3731986 17.4268014,28 18.2,28 L26.6,28 C27.3731986,28 28,27.3731986 28,26.6 L28,18.2 C28,17.4268014 27.3731986,16.8 26.6,16.8 Z M14,5.6 C11.6804041,5.6 9.8,7.48040405 9.8,9.8 L9.8,11.2 C8.2536027,11.2 7,12.4536027 7,14 L7,19.6 C7,21.1463973 8.2536027,22.4 9.8,22.4 L18.2,22.4 C19.7463973,22.4 21,21.1463973 21,19.6 L21,14 C21,12.4536027 19.7463973,11.2 18.2,11.2 L18.2,9.8 C18.2,7.48040405 16.3195959,5.6 14,5.6 Z M12.6,9.8 C12.6,9.02680135 13.2268014,8.4 14,8.4 C14.7731986,8.4 15.4,9.02680135 15.4,9.8 L15.4,11.2 L12.6,11.2 L12.6,9.8 Z M18.2,19.6 L9.8,19.6 L9.8,14 L18.2,14 L18.2,19.6 Z M1.4,11.2 C2.17319865,11.2 2.8,10.5731986 2.8,9.8 L2.8,2.8 L9.8,2.8 C10.5731986,2.8 11.2,2.17319865 11.2,1.4 C11.2,0.62680135 10.5731986,0 9.8,0 L1.4,0 C0.62680135,0 0,0.62680135 0,1.4 L0,9.8 C0,10.5731986 0.62680135,11.2 1.4,11.2 Z M9.8,25.2 L2.8,25.2 L2.8,18.2 C2.8,17.4268014 2.17319865,16.8 1.4,16.8 C0.62680135,16.8 0,17.4268014 0,18.2 L0,26.6 C0,27.3731986 0.62680135,28 1.4,28 L9.8,28 C10.5731986,28 11.2,27.3731986 11.2,26.6 C11.2,25.8268014 10.5731986,25.2 9.8,25.2 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

LockIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default LockIcon;