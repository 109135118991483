import PropTypes from "prop-types";
import React from "react";

const NotificationsIcon = props => (
    <svg
        height={25}
        viewBox='0 0 28 25'
        width={28}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <defs>
            <circle id="path-1" cx="18" cy="5" r="5"></circle>
            <filter x="-45.0%" y="-45.0%" width="250.0%" height="250.0%" filterUnits="objectBoundingBox" id="filter-2">
                <feOffset dx="3" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0.97254902   0 0 0 0 0.419607843   0 0 0 0 0.403921569  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Style-Guide---UI-Desktop" transform="translate(-170.000000, -4501.000000)">
                <g id="Icons" transform="translate(72.000000, 4283.000000)">
                    <g id="Group-9" transform="translate(0.000000, 80.000000)">
                        <g id="Notifications" transform="translate(96.000000, 139.000000)">
                            <rect id="button-bg" x="0" y="0" width="24" height="24" rx="2"></rect>
                            <g id="bell" transform="translate(2.000000, 0.000000)" fill="#969696" fillRule="nonzero">
                                <path d="M16.625,13.4489496 L16.625,9.62357754 C16.6214794,6.10433061 14.1129673,3.10069928 10.6875,2.51415963 L10.6875,1.20294719 C10.6875,0.538577803 10.1558381,0 9.5,0 C8.84416186,0 8.3125,0.538577803 8.3125,1.20294719 L8.3125,2.51415963 C4.88703268,3.10069928 2.37852055,6.10433061 2.375,9.62357754 L2.375,13.4489496 C0.955072172,13.9574935 0.00423268428,15.315617 0,16.8412607 L0,19.2471551 C0,19.9115245 0.53166186,20.4501023 1.1875,20.4501023 L4.91625,20.4501023 C5.47840991,22.5455886 7.35639594,24 9.5,24 C11.6436041,24 13.5215901,22.5455886 14.08375,20.4501023 L17.8125,20.4501023 C18.4683381,20.4501023 19,19.9115245 19,19.2471551 L19,16.8412607 C18.9957673,15.315617 18.0449278,13.9574935 16.625,13.4489496 Z M4.75,9.62357754 C4.75,6.96609998 6.87664744,4.81178877 9.5,4.81178877 C12.1233526,4.81178877 14.25,6.96609998 14.25,9.62357754 L14.25,13.2324191 L4.75,13.2324191 L4.75,9.62357754 Z M9.5,21.6530495 C8.65661123,21.6478925 7.8791867,21.1900219 7.4575,20.4501023 L11.5425,20.4501023 C11.1208133,21.1900219 10.3433888,21.6478925 9.5,21.6530495 Z M16.625,18.0442079 L2.375,18.0442079 L2.375,16.8412607 C2.375,16.1768913 2.90666186,15.6383135 3.5625,15.6383135 L15.4375,15.6383135 C16.0933381,15.6383135 16.625,16.1768913 16.625,16.8412607 L16.625,18.0442079 Z" id="Shape"></path>
                            </g>
                            <g id="Oval">
                                <use fill="black" fillOpacity="1" filter="url(#filter-2)"></use>
                                <use fill="#F86B67" fillRule="evenodd"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

NotificationsIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default NotificationsIcon;