import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import Check from "react-icons/lib/fa/check";
import MdClose from "react-icons/lib/md/close";

export default class Purposes extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    purposes: PropTypes.array,
    getPurposes: PropTypes.func.isRequired,
  };

  state = {
    isLegitimate: this.props.purposes
      ? this.props.purposes.indexOf("Legitimate interest") > -1
        ? true
        : false
      : null,
    isHR: this.props.purposes
      ? this.props.purposes.indexOf("HR matters") > -1
        ? true
        : false
      : null,
    isIntegrity: this.props.purposes
      ? this.props.purposes.indexOf("Systems integrity") > -1
        ? true
        : false
      : null,
    isProduct: this.props.purposes
      ? this.props.purposes.indexOf("Product development") > -1
        ? true
        : false
      : null,
    isAdvertising: this.props.purposes
      ? this.props.purposes.indexOf("Advertising") > -1
        ? true
        : false
      : null,
    isService: this.props.purposes
      ? this.props.purposes.indexOf("Service enhancement") > -1
        ? true
        : false
      : null,
    isMarketing: this.props.purposes
      ? this.props.purposes.indexOf("Marketing") > -1
        ? true
        : false
      : null,
    isCommunication: this.props.purposes
      ? this.props.purposes.indexOf("Communication") > -1
        ? true
        : false
      : null,
    isProcessing: this.props.purposes
      ? this.props.purposes.indexOf("Processing transactions") > -1
        ? true
        : false
      : null,
    isCompliance: this.props.purposes
      ? this.props.purposes.indexOf("Compliance") > -1
        ? true
        : false
      : null,
    isResearch: this.props.purposes
      ? this.props.purposes.indexOf("Research") > -1
        ? true
        : false
      : null,
    isSecurity: this.props.purposes
      ? this.props.purposes.indexOf("Security") > -1
        ? true
        : false
      : null,
    isPersonalization: this.props.purposes
      ? this.props.purposes.indexOf("Personalization") > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.purposes
      ? this.props.purposes.filter(
          (item) =>
            item !== "Legitimate interest" &&
            item !== "HR matters" &&
            item !== "Systems integrity" &&
            item !== "Product development" &&
            item !== "Advertising" &&
            item !== "Service enhancement" &&
            item !== "Marketing" &&
            item !== "Communication" &&
            item !== "Processing transactions" &&
            item !== "Compliance" &&
            item !== "Research" &&
            item !== "Security" &&
            item !== "Personalization"
        ).length
        ? true
        : false
      : false,
  };

  getPurposes = () => {
    const info = this.props.purposes
      ? this.props.purposes.filter(
          (item) =>
            item !== "Legitimate interest" &&
            item !== "HR matters" &&
            item !== "Systems integrity" &&
            item !== "Product development" &&
            item !== "Advertising" &&
            item !== "Service enhancement" &&
            item !== "Marketing" &&
            item !== "Communication" &&
            item !== "Processing transactions" &&
            item !== "Compliance" &&
            item !== "Research" &&
            item !== "Security" &&
            item !== "Personalization"
        )
      : "";

    this.props.getPurposes([
      this.state.isLegitimate === true ? "Legitimate interest" : null,
      this.state.isHR === true ? "HR matters" : null,
      this.state.isIntegrity === true ? "Systems integrity" : null,
      this.state.isProduct === true ? "Product development" : null,
      this.state.isAdvertising === true ? "Advertising" : null,
      this.state.isService === true ? "Service enhancement" : null,
      this.state.isMarketing === true ? "Marketing" : null,
      this.state.isCommunication === true ? "Communication" : null,
      this.state.isProcessing === true ? "Processing transactions" : null,
      this.state.isCompliance === true ? "Compliance" : null,
      this.state.isResearch === true ? "Research" : null,
      this.state.isSecurity === true ? "Security" : null,
      this.state.isPersonalization === true ? "Personalization" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  render() {
    const info = this.props.purposes
      ? this.props.purposes.filter(
          (item) =>
            item !== "Legitimate interest" &&
            item !== "HR matters" &&
            item !== "Systems integrity" &&
            item !== "Product development" &&
            item !== "Advertising" &&
            item !== "Service enhancement" &&
            item !== "Marketing" &&
            item !== "Communication" &&
            item !== "Processing transactions" &&
            item !== "Compliance" &&
            item !== "Research" &&
            item !== "Security" &&
            item !== "Personalization"
        )
      : "";
    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title">Purpose</h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isMarketing ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isMarketing: !this.state.isMarketing }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Marketing
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isService ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isService: !this.state.isService }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Service enhancement
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isAdvertising ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isAdvertising: !this.state.isAdvertising }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Advertising
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isProduct ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isProduct: !this.state.isProduct }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Product development
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isIntegrity ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isIntegrity: !this.state.isIntegrity }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Systems integrity
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${this.state.isHR ? "-checked" : "-unchecked"}`}
            onClick={() =>
              this.setState({ isHR: !this.state.isHR }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            HR matters
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isLegitimate ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isLegitimate: !this.state.isLegitimate }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Legitimate interest
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isCommunication ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState(
                { isCommunication: !this.state.isCommunication },
                () => this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Communication
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isProcessing ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isProcessing: !this.state.isProcessing }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Processing transactions
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isCompliance ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isCompliance: !this.state.isCompliance }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Compliance
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isResearch ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isResearch: !this.state.isResearch }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Research
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isSecurity ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isSecurity: !this.state.isSecurity }, () =>
                this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Security
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isPersonalization ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState(
                { isPersonalization: !this.state.isPersonalization },
                () => this.getPurposes()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Personalization
          </span>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getPurposes()
                )
              }
            >
              <Check color={"#fff"} />
            </span>
            <span className="modal-edit-privacy-category__content-text">
              Other
            </span>
          </div>
          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75 mb-3"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getPurposes())
            }
          />
        </div>
      </div>
    );
  }
}
