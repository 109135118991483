import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// import ReactFilestack from "filestack-react";
import {
  Input,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Button,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import { ASSIGN_REQUEST_TO_USER } from "../../graphql/assignRequestToUser";
import { EMAILS_QUERY } from "../../graphql/emails";
import { DELAY_DATA_REQUEST } from "../../graphql/delayDataRequest";
import { SOLVE_DONT_SELL } from "../../graphql/solveDontSellDataRequest";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import MdClose from "react-icons/lib/md/close";
import { SEND_RESPONSE } from "../../graphql/sendResponseOnRequest";
import PlusCircle from "../../components/Icons/PlusCircle";
import { REMOVE_ASSIGNED_USER } from "../../graphql/removeAssignedUser";
import AssignedRequest from "../../components/Modals/AsignedRequest";
import { SEND_DEFAULT_MAIL } from "../../graphql/sendDefaultInboxMail";

class Question extends Component {
  static propTypes = {
    account: PropTypes.object,
    selectedRequest: PropTypes.object,
    viewer: PropTypes.object,
    showMailPreview: PropTypes.func,
    editEmail: PropTypes.func,
    showSolved: PropTypes.func,
    getItem: PropTypes.func,
    isSell: PropTypes.bool,
    email: PropTypes.object,
    assignUser: PropTypes.func,
    delayRequest: PropTypes.func,
    sendResponse: PropTypes.func,
    solveSell: PropTypes.func,
    sendDefaultMail: PropTypes.func,
    removeAssignedUser: PropTypes.func,
    showDelay: PropTypes.func,
    mailType: PropTypes.string,
    answer: PropTypes.string,
    notQuestion: PropTypes.bool,
  };

  state = {
    answer: this.props.answer,
    formErrors: {},
    selectedRequest: this.props.selectedRequest,
    isAssignedModalVisible: false,
    assignedTo: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedRequest !== prevState.selectedRequest) {
      return { answer: "", selectedRequest: nextProps.selectedRequest };
    }
    return null;
  }

  assignToUser = (userId) => {
    this.props
      .assignUser({
        variables: {
          requestId: this.props.selectedRequest.id,
          userId,
        },
      })
      .then(() => {
        this.notify("Request assigned");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  unassignUser = () => {
    this.props
      .removeAssignedUser({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.notify("Removed assigned user");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  notify = (text) => toast(text);

  delay = () => {
    this.props
      .delayRequest({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.props.showDelay();
      })
      .catch((error) => {
        this.notify("Request couldn't be delayed!");
        console.log(error);
      });
  };

  sendAnswer = () => {
    const { email } = this.props.email;

    let hasFormErrors = false;
    let formErrors = {};

    if (!this.state.answer) {
      hasFormErrors = true;
      formErrors.answer = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .sendResponse({
        variables: {
          requestId: this.props.selectedRequest.id,
          response: `${email.body} ${this.state.answer}`,
        },
      })
      .then(() => {
        this.props.showSolved();
      })
      .catch((error) => {
        this.notify("Response couldn't be sent!");
        console.log(error);
      });
  };

  showAssignedModal = () => {
    this.setState({
      isAssignedModalVisible: !this.state.isAssignedModalVisible,
    });
  };

  send = () => {
    if (this.props.isSell) {
      this.props
        .solveSell({
          variables: {
            requestId: this.props.selectedRequest.id,
          },
        })
        .then(() => {
          this.props.showSolved();
        })
        .catch((error) => {
          this.notify("Request couldn't be solved!");
          console.log(error);
        });
    } else {
      this.props
        .sendDefaultMail({
          variables: {
            requestId: this.props.selectedRequest.id,
            mailType: "DATA_REQUEST_SOLVED",
            reasonOfUnreasonable: null,
          },
        })
        .then(() => {
          this.props.showSolved();
        })
        .catch((error) => {
          this.notify("Response couldn't be sent!");
          console.log(error);
        });
    }
  };

  markAsSolved = () => {
    this.props
      .solveSell({
        variables: {
          requestId: this.props.selectedRequest.id,
        },
      })
      .then(() => {
        this.props.showSolved();
      })
      .catch((error) => {
        this.notify("Request couldn't be solved!");
        console.log(error);
      });
  };

  render() {
    const { account, viewer, notQuestion, isSell } = this.props;
    const { selectedRequest } = this.state;

    return (
      <div className={`${notQuestion ? "inbox__internal" : ""}`}>
        {notQuestion ? (
          <Fragment>
            <p className="inbox__internal-title">
              {isSell ? "Step 2. Notify the user" : "Step 3. Send the data"}
            </p>

            <p className="inbox__internal-text">
              {isSell
                ? "Now that you have stop selling the data and notified all data processors, notify the data subject. The user will receive an e-mail confirming that you have solved the request."
                : "Now that all the data files are uploaded, send them to the user. An e-mail will be sent with a link to download the files on your LegalSite."}
            </p>
          </Fragment>
        ) : null}

        {!notQuestion ? (
          <div className="inbox__data-stats flex-column">
            <div
              className={`${
                selectedRequest && selectedRequest.status !== "SOLVED"
                  ? !selectedRequest.assignedTo ||
                    (selectedRequest.assignedTo &&
                      selectedRequest.assignedTo.id === viewer.id)
                    ? "mb-5"
                    : "mb-0"
                  : "mb-5"
              } d-flex flex-column`}
            >
              <span className="inbox__data-stats-label">Question</span>
              <span className="inbox__data-stats-value">
                {selectedRequest && selectedRequest.message
                  ? selectedRequest.message
                  : ""}
              </span>
            </div>

            {selectedRequest && selectedRequest.status !== "SOLVED" ? (
              !selectedRequest.assignedTo ||
              (selectedRequest.assignedTo &&
                selectedRequest.assignedTo.id === viewer.id) ? (
                <div>
                  <p className="inbox__data-stats-label mb-2">
                    Write an answer:
                  </p>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="question"
                      placeholder="Write your message"
                      className="inbox__request-question"
                      onChange={(e) =>
                        this.setState({ answer: e.target.value }, () =>
                          this.props.getItem()
                        )
                      }
                      invalid={this.state.formErrors.answer}
                      value={this.state.answer}
                    />
                    <FormFeedback>Please write an answer</FormFeedback>
                  </FormGroup>
                </div>
              ) : null
            ) : (
              <div className="d-flex flex-column">
                <span className="inbox__data-stats-label">Answer</span>
                <span className="inbox__data-stats-value">
                  {selectedRequest && selectedRequest.response
                    ? selectedRequest.response
                    : ""}
                </span>
              </div>
            )}
          </div>
        ) : null}

        {selectedRequest && selectedRequest.status !== "SOLVED" ? (
          <div
            className={`inbox__data-stats justify-content-start ${
              notQuestion ? "p-0 border-0" : ""
            }`}
          >
            {isSell ? (
              (selectedRequest && !selectedRequest.tasks) ||
              (selectedRequest &&
                selectedRequest.tasks &&
                !selectedRequest.tasks.length) ? (
                <Button
                  className="btn--primary inbox__request-actions mr-4"
                  onClick={() => this.markAsSolved()}
                >
                  Mark as solved
                </Button>
              ) : (
                <UncontrolledDropdown className="mr-3">
                  <DropdownToggle className="btn--primary inbox__request-actions">
                    Actions <FaCaret className="ml-3" />
                  </DropdownToggle>
                  <DropdownMenu className="inbox__request-action-menu">
                    <DropdownItem
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.markAsSolved()
                      }
                    >
                      Mark as solved
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.props.showMailPreview(this.state.answer)
                      }
                    >
                      Preview request e-mail
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        selectedRequest.assignedTo &&
                        selectedRequest.assignedTo.id !== viewer.id
                          ? this.showAssignedModal()
                          : this.props.editEmail(this.state.answer)
                      }
                    >
                      Edit request e-mail
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            ) : null}

            {!isSell ? (
              <UncontrolledDropdown className="mr-3">
                <DropdownToggle className="btn--primary inbox__request-actions">
                  Actions <FaCaret className="ml-3" />
                </DropdownToggle>
                <DropdownMenu className="inbox__request-action-menu">
                  <DropdownItem
                    onClick={() =>
                      selectedRequest.assignedTo &&
                      selectedRequest.assignedTo.id !== viewer.id
                        ? this.showAssignedModal()
                        : notQuestion
                        ? this.send()
                        : this.sendAnswer()
                    }
                  >
                    Send
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      selectedRequest.assignedTo &&
                      selectedRequest.assignedTo.id !== viewer.id
                        ? this.showAssignedModal()
                        : this.props.showMailPreview(this.state.answer)
                    }
                  >
                    Preview e-mail
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      selectedRequest.assignedTo &&
                      selectedRequest.assignedTo.id !== viewer.id
                        ? this.showAssignedModal()
                        : this.props.editEmail(this.state.answer)
                    }
                  >
                    Edit e-mail
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      selectedRequest.assignedTo &&
                      selectedRequest.assignedTo.id !== viewer.id
                        ? this.showAssignedModal()
                        : this.delay()
                    }
                  >
                    Delay
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : null}

            <UncontrolledDropdown
              className={`${
                selectedRequest && selectedRequest.assignedTo
                  ? "inbox__request-users-toggle"
                  : ""
              }`}
            >
              {selectedRequest && selectedRequest.assignedTo ? (
                <DropdownToggle className="d-flex align-items-center" tag="div">
                  <div className="d-flex align-items-center">
                    {selectedRequest && selectedRequest.assignedTo.avatar ? (
                      <img
                        className="inbox__request-avatar-placeholder"
                        src={selectedRequest.assignedTo.avatar}
                        alt="avatar"
                      />
                    ) : (
                      <span className="inbox__request-avatar-placeholder" />
                    )}
                    <div className="ml-3 inbox__request-users">
                      <p className="mb-0">
                        <strong>{selectedRequest.assignedTo.name}</strong>{" "}
                        <FaCaret className="ml-3" />
                      </p>
                    </div>
                  </div>
                </DropdownToggle>
              ) : (
                <DropdownToggle className="btn--secondary inbox__request-actions">
                  Assign to <FaCaret className="ml-3" />
                </DropdownToggle>
              )}
              <DropdownMenu className="inbox__request-assign-menu">
                {account && account.users && account.users.length !== 1 ? (
                  account.users.map((user) =>
                    user.id !== viewer.id ? (
                      <DropdownItem
                        key={user.id}
                        onClick={() =>
                          selectedRequest &&
                          selectedRequest.assignedTo &&
                          user.id === selectedRequest.assignedTo.id
                            ? this.unassignUser()
                            : this.assignToUser(user.id)
                        }
                      >
                        <div className="d-flex align-items-center">
                          {user.avatar ? (
                            <img src={user.avatar} alt="avatar" />
                          ) : (
                            <span className="inbox__request-avatar-placeholder" />
                          )}
                          <div className="ml-3 inbox__request-users">
                            <p className="mb-0">
                              <strong>{user.name}</strong>
                            </p>
                            <p className="mb-0">{user.jobTitle}</p>
                          </div>
                          {selectedRequest &&
                          selectedRequest.assignedTo &&
                          user.id === selectedRequest.assignedTo.id ? (
                            <MdClose className="ml-2" size={18} />
                          ) : null}
                        </div>
                      </DropdownItem>
                    ) : null
                  )
                ) : account && account.users && account.users.length === 1 ? (
                  <div className="inbox__request-add-empty">
                    <Link
                      to={{ pathname: "/settings", state: { isAddUser: true } }}
                    >
                      <PlusCircle size={24} /> Add users to your account to
                      assign requests
                    </Link>
                  </div>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>

            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              newestOnTop={false}
              closeButton={false}
              closeOnClick
              rtl={false}
              draggable
              hideProgressBar
            />

            <AssignedRequest
              showAssignedModal={this.showAssignedModal}
              isAssignedModalVisible={this.state.isAssignedModalVisible}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(ASSIGN_REQUEST_TO_USER, {
    name: "assignUser",
  }),
  graphql(EMAILS_QUERY, {
    options: (props) => ({
      variables: {
        emailType: props.mailType,
      },
      fetchPolicy: "no-cache",
    }),
    name: "email",
  }),
  graphql(DELAY_DATA_REQUEST, {
    name: "delayRequest",
  }),
  graphql(SEND_RESPONSE, {
    name: "sendResponse",
  }),
  graphql(SOLVE_DONT_SELL, {
    name: "solveSell",
  }),
  graphql(SEND_DEFAULT_MAIL, {
    name: "sendDefaultMail",
  }),
  graphql(REMOVE_ASSIGNED_USER, {
    name: "removeAssignedUser",
  })
)(Question);
