import gql from "graphql-tag";

export const CREATE_FREE_SUBSCRIBTION = gql`
  mutation(
    $clientMutationId: String
  ) {
    createFreeSubscription(
      input: {
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      subscriptionId
    }
  }
`;
