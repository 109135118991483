import PropTypes from "prop-types";
import React from "react";

const ArrowCircleUpIcon = (props) => (
  <svg
    height={13}
    viewBox="0 0 13 13"
    width={13}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-1----Introduction"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2.7--Onboarding-7"
        transform="translate(-1129.000000, -229.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <g id="Dropdown" transform="translate(1113.000000, 78.000000)">
          <g id="options" transform="translate(13.000000, 151.000000)">
            <g
              id="arrow-circle-down"
              transform="translate(9.500000, 6.500000) scale(1, -1) translate(-9.500000, -6.500000) translate(3.000000, 0.000000)"
            >
              <path
                d="M6.0385,8.9115 C6.10031741,8.97067641 6.17321164,9.01706365 6.253,9.048 C6.41124988,9.11301171 6.58875012,9.11301171 6.747,9.048 C6.82678836,9.01706365 6.89968259,8.97067641 6.9615,8.9115 L8.9115,6.9615 C9.16637941,6.70662059 9.16637941,6.29337941 8.9115,6.0385 C8.65662059,5.78362059 8.24337941,5.78362059 7.9885,6.0385 L7.15,6.8835 L7.15,4.55 C7.15,4.19101491 6.85898509,3.9 6.5,3.9 C6.14101491,3.9 5.85,4.19101491 5.85,4.55 L5.85,6.8835 L5.0115,6.0385 C4.88945171,5.91544678 4.7233144,5.84623109 4.55,5.84623109 C4.3766856,5.84623109 4.21054829,5.91544678 4.0885,6.0385 C3.96544678,6.16054829 3.89623109,6.3266856 3.89623109,6.5 C3.89623109,6.6733144 3.96544678,6.83945171 4.0885,6.9615 L6.0385,8.9115 Z M6.5,13 C10.0898509,13 13,10.0898509 13,6.5 C13,2.91014913 10.0898509,-5.77315973e-16 6.5,0 C2.91014913,5.77315973e-16 2.88657986e-16,2.91014913 0,6.5 C1.44328993e-16,8.22390718 0.684819462,9.87720762 1.90380592,11.0961941 C3.12279238,12.3151805 4.77609282,13 6.5,13 Z M6.5,1.3 C9.3718807,1.3 11.7,3.6281193 11.7,6.5 C11.7,9.3718807 9.3718807,11.7 6.5,11.7 C3.6281193,11.7 1.3,9.3718807 1.3,6.5 C1.3,3.6281193 3.6281193,1.3 6.5,1.3 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowCircleUpIcon.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default ArrowCircleUpIcon;
