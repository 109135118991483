import gql from "graphql-tag";

export const HIDE_FEEDBACK = gql`
  mutation($clientMutationId: String) {
    hideFeedbackModal(
      input: {clientMutationId: $clientMutationId }
    ) {
      errors
      user {
        id
        showFeedbackModal
      }
    }
  }
`;
