import React, { Component } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import Image from "../../images/History/Illustration.svg";

export default class EmptyHistory extends Component {
  static propTypes = {
    openMenu: PropTypes.func.isRequired,
  };
  state = {};

  render() {
    return (
      <div className="container history__empty-container">
        <img className="history__empty-img" src={Image} alt="no-data" />

        <h2 className="history__empty-title">
          You don’t have any activity yet
        </h2>

        <p className="history__empty-text">
          When you or an user performes an action, it will appear here. Start by
          setting up your LegalSite!
        </p>

        <Button
          className="history__empty-btn btn--primary"
          onClick={this.props.openMenu}
        >
          See Getting Started checklist
        </Button>
      </div>
    );
  }
}
