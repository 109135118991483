import gql from "graphql-tag";

export const CHANGE_PASSWORD = gql`
  mutation(
    $currentPassword: String!
    $password1: String!
    $password2: String!
    $clientMutationId: String
  ) {
    changePassword(
      input: {
        currentPassword: $currentPassword
        password1: $password1
        password2: $password2
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
