import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
  mutation(
    $requestId: String!
    $type: FileTypeEnum!
    $url: String!
    $clientMutationId: String
  ) {
    uploadFile(
      input: {
        requestId: $requestId
        type: $type
        url: $url
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      request {
        id
        fileResources {
          category
          id
          url
        }
        status
      }
    }
  }
`;
