import React from "react";
import { Col } from "reactstrap";
import ShieldCheck from "../../components/Icons/ShieldCheck";
import LockIcon from "../../components/Icons/LockIcon";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Comments from "../../components/Icons/Comments";
import PropTypes from "prop-types";

function CustomizationSitePage(props) {
  const {
    page,
    contactBg,
    legalsite,
    isContact,
    cardsIconsColor,
    cardsTitleColor,
    cardsTitleFont,
    cardsTitleSize,
    cardsBodyFont,
    cardsBodySize,
    cardsBodyColor,
    cardsBtnBg,
    cardsBtnFont,
    cardsBtnSize,
    cardsTitleAlign,
    cardsTitleBold,
    cardsTitleItalic,
    cardsBodyAlign,
    cardsBodyBold,
    cardsBodyItalic,
    cardsBtnAlign,
    cardsBtnBold,
    cardsBtnItalic,
  } = props;

  return (
    <Col
      md={12}
      lg={4}
      className="customization-site__info-privacy justify-content-between"
      style={{
        background: contactBg ? contactBg : legalsite.cardsBg,
      }}
    >
      <div className="w-100">
        {!isContact ? (
          page.title === "Terms & Conditions" ? (
            <ShieldCheck
              color={
                cardsIconsColor
                  ? cardsIconsColor
                  : legalsite.cardsIconsColor
                  ? legalsite.cardsIconsColor
                  : "#7450c8"
              }
            />
          ) : (
            <LockIcon
              color={
                cardsIconsColor
                  ? cardsIconsColor
                  : legalsite.cardsIconsColor
                  ? legalsite.cardsIconsColor
                  : "#7450c8"
              }
            />
          )
        ) : (
          <Comments
            color={
              cardsIconsColor
                ? cardsIconsColor
                : legalsite.cardsIconsColor
                ? legalsite.cardsIconsColor
                : "#7450c8"
            }
          />
        )}
        <h4
          className="customization-site__info-title"
          style={{
            color: cardsTitleColor
              ? cardsTitleColor
              : legalsite.cardsTitleColor,
            fontSize: cardsTitleSize
              ? cardsTitleSize
              : legalsite.cardsTitleSize,
            fontFamily: cardsTitleFont
              ? cardsTitleFont
              : legalsite.cardsTitleFont,
            textAlign:
              cardsTitleAlign !== null
                ? cardsTitleAlign
                : legalsite.cardsTitleAlign,
            fontWeight:
              cardsTitleBold !== null
                ? cardsTitleBold === true
                  ? 900
                  : 300
                : legalsite.cardsTitleBold
                ? 900
                : 300,
            fontStyle:
              cardsTitleItalic !== null
                ? cardsTitleItalic === true
                  ? "italic"
                  : "normal"
                : legalsite.cardsTitleItalic
                ? "italic"
                : "normal",
          }}
        >
          {isContact ? "Contact us" : page.title}
        </h4>
        <p
          className="customization-site__info-text"
          style={{
            color: cardsBodyColor ? cardsBodyColor : legalsite.cardsBodyColor,
            fontSize: cardsBodySize ? cardsBodySize : legalsite.cardsBodySize,
            fontFamily: cardsBodyFont ? cardsBodyFont : legalsite.cardsBodyFont,
            textAlign:
              cardsBodyAlign !== null
                ? cardsBodyAlign
                : legalsite.cardsBodyAlign,
            fontWeight:
              cardsBodyBold !== null
                ? cardsBodyBold === true
                  ? 900
                  : 300
                : legalsite.cardsBodyBold
                ? 900
                : 300,
            fontStyle:
              cardsBodyItalic !== null
                ? cardsBodyItalic === true
                  ? "italic"
                  : "normal"
                : legalsite.cardsBodyItalic
                ? "italic"
                : "normal",
          }}
        >
          {isContact
            ? "Click to send a message and we’ll get in touch soon."
            : page.description}
        </p>
      </div>
      <div className="w-100">
        <p
          className="customization-site__info-more"
          style={{
            fontSize: cardsBtnSize ? cardsBtnSize : legalsite.cardsBtnSize,
            fontFamily: cardsBtnFont ? cardsBtnFont : legalsite.cardsBtnFont,
            color: cardsBtnBg
              ? cardsBtnBg
              : legalsite.cardsBtnBg
              ? legalsite.cardsBtnBg
              : "#7450c8",
            textAlign:
              cardsBtnAlign !== null ? cardsBtnAlign : legalsite.cardsBtnAlign,
            fontWeight:
              cardsBtnBold !== null
                ? cardsBtnBold === true
                  ? 900
                  : 300
                : legalsite.cardsBtnBold
                ? 900
                : 300,
            fontStyle:
              cardsBtnItalic !== null
                ? cardsBtnItalic === true
                  ? "italic"
                  : "normal"
                : legalsite.cardsBtnItalic
                ? "italic"
                : "normal",
          }}
        >
          See more <MdArrow size={20} className="ml-2 my-0" />
        </p>
      </div>
    </Col>
  );
}

export default CustomizationSitePage;
CustomizationSitePage.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  contactBg: PropTypes.string,
  legalsite: PropTypes.shape({
    cardsBg: PropTypes.string,
    cardsIconsColor: PropTypes.string,
    cardsTitleColor: PropTypes.string,
    cardsTitleSize: PropTypes.string,
    cardsTitleFont: PropTypes.string,
    cardsTitleAlign: PropTypes.string,
    cardsTitleBold: PropTypes.bool,
    cardsTitleItalic: PropTypes.bool,
    cardsBodyColor: PropTypes.string,
    cardsBodySize: PropTypes.string,
    cardsBodyFont: PropTypes.string,
    cardsBodyAlign: PropTypes.string,
    cardsBodyBold: PropTypes.bool,
    cardsBodyItalic: PropTypes.bool,
    cardsBtnBg: PropTypes.string,
    cardsBtnSize: PropTypes.string,
    cardsBtnFont: PropTypes.string,
    cardsBtnAlign: PropTypes.string,
    cardsBtnBold: PropTypes.bool,
    cardsBtnItalic: PropTypes.bool,
  }),
  isContact: PropTypes.bool,
  cardsIconsColor: PropTypes.string,
  cardsTitleColor: PropTypes.string,
  cardsTitleSize: PropTypes.string,
  cardsTitleFont: PropTypes.string,
  cardsTitleAlign: PropTypes.string,
  cardsTitleBold: PropTypes.bool,
  cardsTitleItalic: PropTypes.bool,
  cardsBodyColor: PropTypes.string,
  cardsBodySize: PropTypes.string,
  cardsBodyFont: PropTypes.string,
  cardsBodyAlign: PropTypes.string,
  cardsBodyBold: PropTypes.bool,
  cardsBodyItalic: PropTypes.bool,
  cardsBtnBg: PropTypes.string,
  cardsBtnSize: PropTypes.string,
  cardsBtnFont: PropTypes.string,
  cardsBtnAlign: PropTypes.string,
  cardsBtnBold: PropTypes.bool,
  cardsBtnItalic: PropTypes.bool,
};
