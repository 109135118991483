import gql from "graphql-tag";

export const ADD_PAGE_TEMPLATE = gql`
  mutation($title: String!, $description: String!, $clientMutationId: String) {
    createPageTemplate(
      input: {
        title: $title
        description: $description
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      pageTemplate {
        description
        id
        title
        updatedAt
        sections {
          applicable
          id
          text
          title
        }
      }
    }
  }
`;
