import gql from "graphql-tag";

export const EDIT_CONSENT = gql`
  mutation(
    $id: ID!
    $enabled: Boolean
    $manageableByUser: Boolean
    $categoryName: String
    $smallDescription: String
    $completeDescription: String
    $clientMutationId: String
  ) {
    editConsent(
      input: {
        id: $id
        enabled: $enabled
        clientMutationId: $clientMutationId
        manageableByUser: $manageableByUser
        categoryName: $categoryName
        smallDescription: $smallDescription
        completeDescription: $completeDescription
      }
    ) {
      errors
      consent {
        categoryName
        completeDescription
        enabled
        id
        manageableByVisitor
        smallDescription
        status
      }
    }
  }
`;
