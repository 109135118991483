/* eslint-disable react/prop-types */

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";
import CustomiseText from "./CustomiseText";
import ShowConsentModal from "../../components/Modals/ShowConsentModal";
import PreviewModal from "../../components/Modals/PreviewModal";
import Upgrade from "../../components/Modals/Upgrade";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { TOGGLE_POPUP_BRANDING } from "../../graphql/toggleShowLegalsiteBrandingPopup";
import { TOGGLE_EMAIL_BRANDING } from "../../graphql/toggleShowLegalsiteBrandingEmail";
import { TOGGLE_LEGALSITE_BRANDING } from "../../graphql/toggleShowLegalsiteBrandingLegalsite";
import { TOGGLE_ALL_BRANDING } from "../../graphql/toggleShowLegalsiteBrandingAll";
import { EDIT_LEGALSITE_SETTINGS } from "../../graphql/editLegalsiteSettings";
import ReactFilestack from "filestack-react";
import CustomiseColor from "./CustomiseColor";
import Browser from "../../components/Icons/Browser";
import FaRight from "react-icons/lib/fa/angle-right";
import navigation from "../../images/Settings/navigation.svg";
import header from "../../images/Settings/header.svg";
import cards from "../../images/Settings/cards.svg";
import body from "../../images/Settings/body.svg";
import footer from "../../images/Settings/footer.svg";
import MdArrow from "react-icons/lib/ti/arrow-left";
import CustomizeNavigation from "./CustomizeNavigation";
import CustomizeHeader from "./CustomizeHeader";
import CustomizeCards from "./CustomizeCards";
import CustomizeBody from "./CustomizeBody";
import CustomizeFooter from "./CustomizeFooter";

import SaveBranding from "../../components/Modals/SaveBranding";
import CollectModal from "../../components/Modals/CollectModal";

class RightSideMenu extends Component {
  static propTypes = {
    changeColor: PropTypes.func,
    changeBodyColor: PropTypes.func,
    changeButtonColor: PropTypes.func,
    changeTextButtonColor: PropTypes.func,
    changeFontSize: PropTypes.func,
    changeBodyFontSize: PropTypes.func,
    changeButtonFontSize: PropTypes.func,
    changeTextButtonFontSize: PropTypes.func,
    showCustomization: PropTypes.func,
    templateActive: PropTypes.number,
    popupPosition: PropTypes.string,
    changeCookieBg: PropTypes.func,
    changeCookieBtnBg: PropTypes.func,
    accountData: PropTypes.object,
    showAvatar: PropTypes.func,
    notifyMsg: PropTypes.func,
    toggleEmailBranding: PropTypes.func,
    toggleLegalsiteBranding: PropTypes.func,
    togglePopupBranding: PropTypes.func,
    toggleAllBranding: PropTypes.func,
    isEmailCustomization: PropTypes.bool,
    isSiteCustomization: PropTypes.bool,
    isCookieCustomization: PropTypes.bool,
    isBranding: PropTypes.bool,
    isSell: PropTypes.bool,
    email: PropTypes.object,
    legalsite: PropTypes.object,
    popup: PropTypes.object,
    legalsiteSettings: PropTypes.object,
    isPopup: PropTypes.bool,
    isEmail: PropTypes.bool,
  };

  state = {
    isConsentModalVisible: false,
    isPreviewModalVisible: false,
    files: [],
    isUpgradeVisible: false,
    activeItem: null,
    logo: [],
    isSaveModalVisible: false,
    isModalOpen: false,
  };

  openModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  openSaveModal = () => {
    this.setState({
      isSaveModalVisible: !this.state.isSaveModalVisible,
    });
  };

  showConsentModal = () => {
    this.setState({
      isConsentModalVisible: !this.state.isConsentModalVisible,
    });
  };

  showPreviewModal = () => {
    this.setState({
      isPreviewModalVisible: !this.state.isPreviewModalVisible,
    });
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });

    const url = this.state.files.map((file) => file.url);

    this.props.showAvatar(url);
  };

  removeBranding = () => {
    if (
      (this.props.accountData &&
        this.props.accountData.subscription &&
        this.props.accountData.subscription.planName === "Free Edition") ||
      (this.props.accountData && !this.props.accountData.subscription)
    ) {
      this.showUpgrade();
    } else {
      if (this.props.isEmailCustomization) {
        this.props
          .toggleEmailBranding({
            variables: {
              id: this.props.accountData ? this.props.accountData.id : "",
            },
          })
          .then(() => {
            this.props.notifyMsg("Branding removed!");
          })
          .catch((error) => {
            console.log(error);
            this.props.notifyMsg("Branding couldn't be removed!");
          });
      } else if (this.props.isSiteCustomization) {
        this.props
          .toggleLegalsiteBranding({
            variables: {
              id: this.props.accountData ? this.props.accountData.id : "",
            },
          })
          .then(() => {
            this.props.notifyMsg("Branding removed!");
          })
          .catch((error) => {
            console.log(error);
            this.props.notifyMsg("Branding couldn't be removed!");
          });
      } else {
        this.props
          .togglePopupBranding({
            variables: {
              id: this.props.accountData ? this.props.accountData.id : "",
            },
          })
          .then(() => {
            this.props.notifyMsg("Branding removed!");
          })
          .catch((error) => {
            console.log(error);
            this.props.notifyMsg("Branding couldn't be removed!");
          });
      }
    }
  };

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  getImage = (item) => {
    if (item === "navigation") return navigation;
    else if (item === "header") return header;
    else if (item === "body") return body;
    else if (item === "footer") return footer;
    else return cards;
  };

  //   TODO duplicate
  //   onFiles = ({ filesUploaded }) => {
  //     this.setState({ files: filesUploaded });
  //   };

  onLogo = ({ filesUploaded }) => {
    this.setState({ logo: filesUploaded });

    const avatar = this.state.logo.length
      ? this.state.logo[0].url
      : this.props.legalsiteSettings.companyLogo
      ? this.props.legalsiteSettings.companyLogo
      : null;

    this.props
      .editLegalsiteSettings({
        variables: {
          companyLogo: avatar,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  brandingRemove = () => {
    if (
      (this.props.accountData &&
        this.props.accountData.subscription &&
        this.props.accountData.subscription.planName === "Free Edition") ||
      (this.props.accountData && !this.props.accountData.subscription)
    ) {
      this.showUpgrade();
    } else {
      this.props
        .toggleAllBranding()
        .then(() => {
          this.props.notifyMsg("Branding removed!");
          this.props.refetchData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  render() {
    const {
      isEmailCustomization,
      isSiteCustomization,
      index,
      legalsite,
      email,
      isCookieCustomization,
      popup,
      legalsiteSettings,
      isSell,
      isBranding,
    } = this.props;

    const { activeItem, files, logo } = this.state;

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    return (
      <div
        className={`customization-cookie__right-menu ${
          isSiteCustomization ||
          isBranding ||
          isEmailCustomization ||
          isCookieCustomization
            ? "-is-site"
            : ""
        }`}
      >
        {isSiteCustomization || isSell || isBranding || isEmailCustomization ? (
          <p
            onClick={this.props.getInitialPage}
            className="customization-cookie__back"
          >
            <MdArrow
              size={25}
              className="customize-menu__back position-relative"
              color="#7450c8"
            />
            &nbsp;Back to Settings
          </p>
        ) : null}

        {isCookieCustomization ? (
          <p onClick={this.props.goBack} className="customization-cookie__back">
            <MdArrow
              size={25}
              className="customize-menu__back position-relative"
              color="#7450c8"
            />
            &nbsp;Back to all Pop-ups
          </p>
        ) : null}

        {!isEmailCustomization &&
        !isSiteCustomization &&
        !isSell &&
        !isBranding ? (
          <Fragment>
            <div className="d-flex justify-content-between customization-cookie__publish-btns">
              <Button
                className="customization-cookie__publish btn--primary mb-2 -is-site-btn mr-4"
                disabled={
                  (this.props.templateActive === 1 &&
                    this.props.buttonShape &&
                    this.props.popupSide) ||
                  (this.props.templateActive === 2 &&
                    this.props.buttonShape &&
                    this.props.popupPosition) ||
                  (this.props.templateActive === 3 &&
                    this.props.buttonShape &&
                    this.props.popupOverlay) ||
                  this.props.templateActive === 4
                    ? false
                    : true
                }
                onClick={() => {
                  this.props.submitCookieSettings();
                  this.showConsentModal();
                }}
              >
                Publish Pop-up
              </Button>

              <Button
                disabled={
                  (this.props.templateActive === 1 &&
                    this.props.buttonShape &&
                    this.props.popupSide) ||
                  (this.props.templateActive === 2 &&
                    this.props.buttonShape &&
                    this.props.popupPosition) ||
                  (this.props.templateActive === 3 &&
                    this.props.buttonShape &&
                    this.props.popupOverlay) ||
                  this.props.templateActive === 4
                    ? false
                    : true
                }
                className="customization-cookie__publish btn--secondary mb-2 -is-site-btn"
                onClick={this.showPreviewModal}
              >
                Preview
              </Button>
            </div>
          </Fragment>
        ) : !isEmailCustomization &&
          isSiteCustomization &&
          !isSell &&
          !isBranding ? (
          <div className="d-flex justify-content-between customization-cookie__publish-btns">
            <Button
              className={`customization-cookie__publish btn--primary mb-2 ${
                isSiteCustomization ? "-is-site-btn mr-4" : ""
              }`}
              onClick={() => {
                this.props.submitLegalsite();
                this.showConsentModal();
              }}
            >
              Publish
            </Button>
            <Button
              className={`customization-cookie__preview btn--secondary ${
                isSiteCustomization ? "-is-site-btn" : ""
              }`}
              onClick={this.showPreviewModal}
            >
              Preview
            </Button>
          </div>
        ) : !isEmailCustomization &&
          !isSiteCustomization &&
          isSell &&
          !isBranding ? (
          <div className="d-flex flex-column customization-cookie__publish-btns">
            <Button
              className="customization-cookie__publish btn--primary mb-2"
              onClick={this.props.publishButton}
            >
              Publish Button
            </Button>
            <Button
              className={`customization-cookie__preview btn--secondary ${
                isSiteCustomization ? "-is-site-btn" : ""
              }`}
              onClick={this.showPreviewModal}
            >
              Preview
            </Button>
          </div>
        ) : !isEmailCustomization &&
          !isSiteCustomization &&
          !isSell &&
          isBranding ? (
          <div className="customization-cookie__save">
            <Button className="btn--primary w-100" onClick={this.openSaveModal}>
              Save
            </Button>
          </div>
        ) : (
          <Fragment>
            <div className="d-flex w-100 px-4">
              <Button
                className="btn--primary mb-2 mr-2"
                onClick={this.props.saveCustomizedEmails}
              >
                Save & Close
              </Button>
              <Button
                className={`btn--secondary ${
                  isSiteCustomization ? "-is-site-btn" : ""
                }`}
                onClick={this.props.back}
              >
                Back to templates
              </Button>
            </div>
          </Fragment>
        )}

        {/* {isBranding ? (
          <div
            className="customization-cookie__collect"
            onClick={this.openModal}
          >
            <p>
              <Palette />
              &nbsp;Collect styling from my website
            </p>
          </div>
        ) : null} */}

        {/* {!this.props.isAdmin ? (
          isCookieCustomization && popup && popup.showLegalsiteBranding ? (
            <div
              className="settings__remove"
              onClick={() => this.removeBranding()}
            >
              <Browser className="mr-2" />
              Remove LegalSite Branding
            </div>
          ) : (
            false
          )
        ) : null} */}

        <div
          className={`${
            isSiteCustomization ? "w-100 -is-site-content" : ""
          } customization-cookie__right-menu-content`}
        >
          {!isEmailCustomization &&
          !isSiteCustomization &&
          !isSell &&
          !isCookieCustomization &&
          !isBranding ? (
            <div className="d-flex mr-auto flex-column">
              <p className="customization-cookie__right-menu-label">
                Color scheme
              </p>
              <div className="d-flex">
                <CustomiseColor
                  changeColor={this.props.changeCookieColor}
                  color={this.props.cookieBg ? this.props.cookieBg : "#fff"}
                  name="cookieBg"
                  index={8}
                />

                <CustomiseColor
                  changeColor={this.props.changeCookieColor}
                  name="secondaryButtonColor"
                  color={this.props.buttonBg ? this.props.buttonBg : "#7450c8"}
                  index={9}
                />
              </div>
            </div>
          ) : !isEmailCustomization &&
            !isSiteCustomization &&
            isSell &&
            !isCookieCustomization &&
            !isBranding ? (
            <div className="d-flex mr-auto flex-column mb-5 mt-4">
              <p className="customization-cookie__right-menu-label">
                Button Color
              </p>
              <CustomiseColor
                changeColor={this.props.changeBgColor}
                color={this.props.sellBg ? this.props.sellBg : "#7450c8"}
                name="sellBg"
                index={318}
              />
            </div>
          ) : isSiteCustomization ? null : isBranding ? (
            <div className="customize-menu__logo-container">
              <p className="customize-menu__logo">Logo</p>
              <div className="d-flex align-items-center settings__legal-logo-container">
                <div className="position-relative">
                  {logo.length || (logo.length && !legalsiteSettings) ? (
                    logo.map((file, i) => (
                      <div key={i} className="settings__avatar-container">
                        <img src={file.url} alt="avatar" />
                      </div>
                    ))
                  ) : !logo.length &&
                    legalsiteSettings &&
                    legalsiteSettings.companyLogo ? (
                    <div className="settings__avatar-container">
                      <img src={legalsiteSettings.companyLogo} alt="avatar" />
                    </div>
                  ) : (
                    <span className="settings__image-placeholder" />
                  )}

                  <ReactFilestack
                    apikey={"AJNM9qOpGRljTn17sgxrfz"}
                    componentDisplayMode={{
                      type: "link",
                      customText: <span />,
                      customClass: "show-upload",
                    }}
                    actionOptions={{
                      accept: ["image/*"],
                      maxFiles: 1,
                    }}
                    onSuccess={this.onLogo}
                  />

                  <p className="settings__legal-color mt-2">CHANGE LOGO</p>
                </div>
              </div>
            </div>
          ) : !isEmailCustomization && !isCookieCustomization ? (
            <div className="d-flex mr-auto border-0 flex-column">
              <p className="customization-cookie__right-menu-label">
                Color scheme
              </p>
              <div className="d-flex">
                <CustomiseColor
                  changeColor={this.props.changeEmailBg}
                  name="bgColor"
                  color={
                    this.props.emailBgColor ? this.props.emailBgColor : "#fff"
                  }
                  index={5}
                />

                <CustomiseColor
                  changeColor={this.props.changeEmailBg}
                  name="footerBg"
                  color={
                    this.props.emailFooterBgColor
                      ? this.props.emailFooterBgColor
                      : "#f4f6fc"
                  }
                  index={6}
                />

                {index !== 5 &&
                index !== 8 &&
                index !== 9 &&
                index !== 12 &&
                index !== 13 &&
                index !== 14 &&
                index !== 22 &&
                index !== 20 &&
                index !== 1 &&
                index !== 10 ? (
                  <CustomiseColor
                    changeColor={this.props.changeEmailBg}
                    name="btnBg"
                    color={
                      this.props.emailButtonBg
                        ? this.props.emailButtonBg
                        : "#7450c8"
                    }
                    index={7}
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          {isSell ? (
            <CustomiseText
              title="Button text"
              color={this.props.sellBtnColor ? this.props.sellBtnColor : "#fff"}
              size={this.props.sellBtnSize ? this.props.sellBtnSize : 14}
              font={this.props.sellBtnFont ? this.props.sellBtnFont : "Roboto"}
              changeColor={this.props.changeTextColor}
              colorName="titleColor"
              fontSizeName="titleSize"
              fontFamilyName="titleFont"
              getGoogleFont={this.props.getGoogleFont}
              changeFontSize={this.props.changeFontSize}
              index={2311}
            />
          ) : null}

          {isSiteCustomization ? (
            <div className="customize-menu">
              <div className="customize-menu__container">
                {!activeItem ? (
                  <Fragment>
                    <div
                      className="d-flex justify-content-between customize-menu__item"
                      onClick={() =>
                        this.setState({
                          activeItem: "navigation",
                        })
                      }
                    >
                      <span className="d-flex">
                        <img
                          src={navigation}
                          alt="navigation"
                          className="mr-3"
                        />
                        <p className="mb-0 customize-menu__label">Navigation</p>
                      </span>
                      <FaRight />
                    </div>
                    <div
                      className="d-flex justify-content-between customize-menu__item"
                      onClick={() =>
                        this.setState({
                          activeItem: "header",
                        })
                      }
                    >
                      <span className="d-flex">
                        <img src={header} alt="navigation" className="mr-3" />
                        <p className="mb-0 customize-menu__label">Header</p>
                      </span>
                      <FaRight />
                    </div>
                    <div
                      className="d-flex justify-content-between customize-menu__item"
                      onClick={() =>
                        this.setState({
                          activeItem: "cards",
                        })
                      }
                    >
                      <span className="d-flex">
                        <img src={cards} alt="navigation" className="mr-3" />
                        <p className="mb-0 customize-menu__label">Cards</p>
                      </span>
                      <FaRight />
                    </div>
                    <div
                      className="d-flex justify-content-between customize-menu__item"
                      onClick={() =>
                        this.setState({
                          activeItem: "body",
                        })
                      }
                    >
                      <span className="d-flex">
                        <img src={body} alt="navigation" className="mr-3" />
                        <p className="mb-0 customize-menu__label">Body</p>
                      </span>
                      <FaRight />
                    </div>
                    <div
                      className="d-flex justify-content-between customize-menu__item"
                      onClick={() =>
                        this.setState({
                          activeItem: "footer",
                        })
                      }
                    >
                      <span className="d-flex">
                        <img src={footer} alt="navigation" className="mr-3" />
                        <p className="mb-0 customize-menu__label">Footer</p>
                      </span>
                      <FaRight />
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="customize-menu__header justify-content-center text-center position-relative">
                      <MdArrow
                        size={25}
                        className="customize-menu__back"
                        onClick={() => this.setState({ activeItem: null })}
                      />
                      <span className="d-flex text-center justify-content-center">
                        <img
                          src={this.getImage(activeItem)}
                          className="mr-2"
                          alt="item"
                        />
                        <p className="customize-menu__title mb-0">
                          {capitalize(activeItem)}
                        </p>
                      </span>
                    </div>

                    {activeItem === "navigation" ? (
                      <CustomizeNavigation
                        files={files}
                        legalsiteSettings={legalsiteSettings}
                        capitalize={capitalize}
                        titleSiteColor={this.props.linkColor}
                        navigationLinksBold={this.props.navigationLinksBold}
                        navigationLinksItalic={this.props.navigationLinksItalic}
                        getAligned={this.props.getAligned}
                        getBold={this.props.getBold}
                        getItalic={this.props.getItalic}
                        titleSiteSize={this.props.navigationSize}
                        siteTitleFont={this.props.navigationFont}
                        changeBgColor={this.props.changeBgColor}
                        bg={this.props.bg}
                        changeTextColor={this.props.changeTextColor}
                        getGoogleFont={this.props.getGoogleFont}
                        changeFontSize={this.props.changeFontSize}
                        onFiles={this.onFiles}
                        navigationBg={this.props.navigationBg}
                      />
                    ) : activeItem === "header" ? (
                      <CustomizeHeader
                        legalsiteSettings={legalsiteSettings}
                        homepageDescription={this.props.homepageDescription}
                        changeText={this.props.changeText}
                        homepageTitle={this.props.homepageTitle}
                        capitalize={capitalize}
                        titleSiteColor={this.props.headerTitleColor}
                        titleSiteSize={this.props.headerTitleSize}
                        siteTitleFont={this.props.headerTitleFont}
                        changeBgColor={this.props.changeBgColor}
                        bg={this.props.headerBg}
                        changeTextColor={this.props.changeTextColor}
                        getGoogleFont={this.props.getGoogleFont}
                        changeFontSize={this.props.changeFontSize}
                        getAligned={this.props.getAligned}
                        getBold={this.props.getBold}
                        getItalic={this.props.getItalic}
                        headerTitleBold={this.props.headerTitleBold}
                        headerTitleItalic={this.props.headerTitleItalic}
                        headerTitleAlign={this.props.headerTitleAlign}
                        headerBodyBold={this.props.headerBodyBold}
                        headerBodyItalic={this.props.headerBodyItalic}
                        headerBodyAlign={this.props.headerBodyAlign}
                        titleSiteColor2={this.props.headerBodyColor}
                        titleSiteSize2={this.props.headerBodySize}
                        siteTitleFont2={this.props.headerBodyFont}
                      />
                    ) : activeItem === "cards" ? (
                      <CustomizeCards
                        legalsiteSettings={legalsiteSettings}
                        capitalize={capitalize}
                        titleSiteColor={this.props.titleSiteColor}
                        titleSiteSize={this.props.titleSiteSize}
                        siteTitleFont={this.props.siteTitleFont}
                        changeBgColor={this.props.changeBgColor}
                        bg={this.props.bg}
                        changeTextColor={this.props.changeTextColor}
                        getGoogleFont={this.props.getGoogleFont}
                        changeFontSize={this.props.changeFontSize}
                        cardsBg={this.props.cardsBg}
                        cardsBodySize={this.props.cardsBodySize}
                        cardsIconsColor={this.props.cardsIconsColor}
                        cardsTitleColor={this.props.cardsTitleColor}
                        cardsTitleFont={this.props.cardsTitleFont}
                        cardsTitleSize={this.props.cardsTitleSize}
                        cardsBodyFont={this.props.cardsBodyFont}
                        cardsBodyColor={this.props.cardsBodyColor}
                        cardsBtnColor={this.props.cardsBtnColor}
                        cardsBtnFont={this.props.cardsBtnFont}
                        cardsBtnSize={this.props.cardsBtnSize}
                        cardsBodyAlign={this.props.cardsBodyAlign}
                        cardsBodyBold={this.props.cardsBodyBold}
                        cardsBodyItalic={this.props.cardsBodyItalic}
                        cardsBtnAlign={this.props.cardsBtnAlign}
                        cardsBtnBold={this.props.cardsBtnBold}
                        cardsBtnItalic={this.props.cardsBtnItalic}
                        cardsTitleAlign={this.props.cardsTitleAlign}
                        cardsTitleBold={this.props.cardsTitleBold}
                        cardsTitleItalic={this.props.cardsTitleItalic}
                        getAligned={this.props.getAligned}
                        getBold={this.props.getBold}
                        getItalic={this.props.getItalic}
                        cardsBtnBg={this.props.cardsBtnBg}
                      />
                    ) : activeItem === "body" ? (
                      <CustomizeBody
                        legalsiteSettings={legalsiteSettings}
                        capitalize={capitalize}
                        titleSiteColor={this.props.titleSiteColor}
                        titleSiteSize={this.props.titleSiteSize}
                        siteTitleFont={this.props.siteTitleFont}
                        changeBgColor={this.props.changeBgColor}
                        bg={this.props.bg}
                        bodyBtnBg={this.props.bodyBtnBg}
                        changeTextColor={this.props.changeTextColor}
                        getGoogleFont={this.props.getGoogleFont}
                        changeFontSize={this.props.changeFontSize}
                        bodyTitleColor={this.props.bodyTitleColor}
                        bodyTitleFont={this.props.bodyTitleFont}
                        bodyTitleSize={this.props.bodyTitleSize}
                        bodyTitleAlign={this.props.bodyTitleAlign}
                        bodyTitleBold={this.props.bodyTitleBold}
                        bodyTitleItalic={this.props.bodyTitleItalic}
                        bodySubtitleColor={this.props.bodySubtitleColor}
                        bodySubtitleSize={this.props.bodySubtitleSize}
                        bodySubtitleFont={this.props.bodySubtitleFont}
                        bodySubtitleAlign={this.props.bodySubtitleAlign}
                        bodySubtitleBold={this.props.bodySubtitleBold}
                        bodySubtitleItalic={this.props.bodySubtitleItalic}
                        bodyTextColor={this.props.bodyTextColor}
                        bodyTextSize={this.props.bodyTextSize}
                        bodyTextFont={this.props.bodyTextFont}
                        bodyTextAlign={this.props.bodyTextAlign}
                        bodyTextBold={this.props.bodyTextBold}
                        bodyTextItalic={this.props.bodyTextItalic}
                        bodyBtnColor={this.props.bodyBtnColor}
                        bodyBtnSize={this.props.bodyBtnSize}
                        bodyBtnFont={this.props.bodyBtnFont}
                        bodyBtnAlign={this.props.bodyBtnAlign}
                        bodyBtnBold={this.props.bodyBtnBold}
                        bodyBtnItalic={this.props.bodyBtnItalic}
                        bodyBg={this.props.bodyBg}
                        getAligned={this.props.getAligned}
                        getBold={this.props.getBold}
                        getItalic={this.props.getItalic}
                        bodyIconsColor={this.props.bodyIconsColor}
                      />
                    ) : activeItem === "footer" ? (
                      <CustomizeFooter
                        legalsiteSettings={legalsiteSettings}
                        capitalize={capitalize}
                        refetchData={this.props.refetchData}
                        footerLogo={this.props.footerLogo}
                        titleSiteColor={this.props.titleSiteColor}
                        titleSiteSize={this.props.titleSiteSize}
                        siteTitleFont={this.props.siteTitleFont}
                        changeBgColor={this.props.changeBgColor}
                        bg={this.props.bg}
                        changeTextColor={this.props.changeTextColor}
                        getGoogleFont={this.props.getGoogleFont}
                        changeFontSize={this.props.changeFontSize}
                        footerLinkColor={this.props.footerLinkColor}
                        footerLinkSize={this.props.footerLinkSize}
                        footerLinkFont={this.props.footerLinkFont}
                        footerLinkAlign={this.props.footerLinkAlign}
                        footerLinkBold={this.props.footerLinkBold}
                        footerLinkItalic={this.props.footerLinkItalic}
                        footerTextColor={this.props.footerTextColor}
                        footerTextSize={this.props.footerTextSize}
                        footerTextFont={this.props.footerTextFont}
                        footerTextAlign={this.props.footerTextAlign}
                        footerTextBold={this.props.footerTextBold}
                        footerTextItalic={this.props.footerTextItalic}
                        footerBg={this.props.footerBg}
                        getAligned={this.props.getAligned}
                        getBold={this.props.getBold}
                        getItalic={this.props.getItalic}
                        legalsite={this.props.legalsite}
                      />
                    ) : null}
                  </Fragment>
                )}
              </div>
              {legalsite && legalsite.showLegalsiteBranding ? (
                <div
                  className="settings__remove pl-0 text-center justify-content-center"
                  onClick={() => this.removeBranding()}
                >
                  <Browser className="mr-2" />
                  Remove LegalSite Branding
                </div>
              ) : null}
            </div>
          ) : null}

          {index !== 5 &&
          index !== 9 &&
          index !== 8 &&
          index !== 10 &&
          !isSiteCustomization &&
          !isBranding &&
          !isSell &&
          !isCookieCustomization ? (
            <CustomiseText
              title="Title Text"
              isEmail={true}
              hasAlign={true}
              color={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.titleColor
                    ? this.props.titleColor
                    : "#7450c8"
                  : isSiteCustomization
                  ? this.props.titleSiteColor
                    ? this.props.titleSiteColor
                    : "#333333"
                  : this.props.emailTitleColor
                  ? this.props.emailTitleColor
                  : "#333"
              }
              size={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.titleSize
                    ? this.props.titleSize
                    : 22
                  : isSiteCustomization
                  ? this.props.titleSiteSize
                    ? this.props.titleSiteSize
                    : 40
                  : this.props.emailTitleSize
                  ? this.props.emailTitleSize
                  : 22
              }
              font={
                isSiteCustomization
                  ? this.props.siteTitleFont
                    ? capitalize(this.props.siteTitleFont)
                    : "Roboto"
                  : isEmailCustomization
                  ? capitalize(this.props.emailTitleFont)
                  : this.props.cookieTitleFont
                  ? capitalize(this.props.cookieTitleFont)
                  : "Roboto"
              }
              changeColor={this.props.changeTextColor}
              colorName="titleColor"
              fontSizeName="titleSize"
              fontFamilyName="titleFont"
              getGoogleFont={this.props.getGoogleFont}
              changeFontSize={this.props.changeFontSize}
              index={1}
              getAligned={this.props.getAligned}
              getBold={this.props.getBold}
              getItalic={this.props.getItalic}
              alignName="emailTitleAlign"
              boldName="emailTitleBold"
              italicName="emailTitleItalic"
              boldValue={this.props.emailTitleBold}
              italicValue={this.props.emailTitleItalic}
              alignValue={this.props.emailTitleAlign}
            />
          ) : null}

          {isCookieCustomization ? (
            <CustomiseText
              title="Title Text"
              isEmail={true}
              hasAlign={true}
              color={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.titleColor
                    ? this.props.titleColor
                    : "#7450c8"
                  : isSiteCustomization
                  ? this.props.titleSiteColor
                    ? this.props.titleSiteColor
                    : "#333333"
                  : this.props.emailTitleColor
                  ? this.props.emailTitleColor
                  : "#333"
              }
              size={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.titleSize
                    ? this.props.titleSize
                    : 22
                  : isSiteCustomization
                  ? this.props.titleSiteSize
                    ? this.props.titleSiteSize
                    : 40
                  : this.props.emailTitleSize
                  ? this.props.emailTitleSize
                  : 22
              }
              font={
                isSiteCustomization
                  ? this.props.siteTitleFont
                    ? capitalize(this.props.siteTitleFont)
                    : "Roboto"
                  : isEmailCustomization
                  ? capitalize(this.props.emailTitleFont)
                  : this.props.cookieTitleFont
                  ? capitalize(this.props.cookieTitleFont)
                  : "Roboto"
              }
              changeColor={this.props.changeTextColor}
              colorName="titleColor"
              fontSizeName="titleSize"
              fontFamilyName="titleFont"
              getGoogleFont={this.props.getGoogleFont}
              changeFontSize={this.props.changeFontSize}
              index={1}
              getAligned={this.props.getAligned}
              getBold={this.props.getBold}
              getItalic={this.props.getItalic}
              alignName="titleAlign"
              boldName="titleBold"
              italicName="titleItalic"
              boldValue={this.props.titleBold}
              italicValue={this.props.titleItalic}
              alignValue={this.props.titleAlign}
            />
          ) : null}

          {!isSiteCustomization && !isSell && !isBranding ? (
            <CustomiseText
              title="Body Text"
              isEmail={true}
              hasAlign={true}
              color={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.bodyColor
                    ? this.props.bodyColor
                    : "#969696"
                  : isSiteCustomization
                  ? this.props.bodySiteColor
                    ? this.props.bodySiteColor
                    : "#969696"
                  : this.props.emailBodyColor
              }
              size={
                isSiteCustomization
                  ? this.props.bodySiteSize
                    ? this.props.bodySiteSize
                    : 14
                  : isEmailCustomization
                  ? this.props.emailBodySize
                    ? this.props.emailBodySize
                    : 14
                  : this.props.bodySize
                  ? this.props.bodySize
                  : 16
              }
              colorName="bodyColor"
              fontFamilyName="bodyFont"
              font={
                isSiteCustomization
                  ? this.props.siteBodyFont
                    ? capitalize(this.props.siteBodyFont)
                    : "Roboto"
                  : isEmailCustomization
                  ? capitalize(this.props.emailBodyFont)
                  : this.props.cookieBodyFont
                  ? capitalize(this.props.cookieBodyFont)
                  : "Roboto"
              }
              fontSizeName="bodySize"
              getGoogleFont={this.props.getGoogleFont}
              changeColor={this.props.changeTextColor}
              changeFontSize={this.props.changeFontSize}
              index={2}
              getAligned={this.props.getAligned}
              getBold={this.props.getBold}
              getItalic={this.props.getItalic}
              alignName="emailBodyAlign"
              boldName="emailBodyBold"
              italicName="emailBodyItalic"
              boldValue={this.props.emailBodyBold}
              italicValue={this.props.emailBodyItalic}
              alignValue={this.props.emailBodyAlign}
            />
          ) : null}

          {isCookieCustomization ? (
            <div className="d-flex justify-content-between">
              <div className="d-flex mr-auto flex-column mb-5 mt-4">
                <p className="customization-cookie__right-menu-label">
                  Background color
                </p>
                <CustomiseColor
                  changeColor={this.props.changeCookieColor}
                  color={this.props.cookieBg ? this.props.cookieBg : "#fff"}
                  name="cookieBg"
                  index={8}
                />
              </div>

              <div className="d-flex mr-auto flex-column mb-5 mt-4">
                <p className="customization-cookie__right-menu-label">
                  Color button and links
                </p>
                <CustomiseColor
                  changeColor={this.props.changeCookieColor}
                  name="secondaryButtonColor"
                  color={this.props.buttonBg ? this.props.buttonBg : "#7450c8"}
                  index={9}
                />
              </div>
            </div>
          ) : null}

          {isEmailCustomization ? (
            <div className="d-flex mr-auto border-0 flex-column">
              <div className="d-flex justify-content-between">
                <div className="d-flex mr-auto flex-column mb-5 mt-4">
                  <p className="customization-cookie__right-menu-label">
                    Background
                  </p>
                  <CustomiseColor
                    changeColor={this.props.changeEmailBg}
                    name="bgColor"
                    color={
                      this.props.emailBgColor ? this.props.emailBgColor : "#fff"
                    }
                    index={5}
                  />
                </div>

                <div className="d-flex mr-auto flex-column mb-5 mt-4">
                  <p className="customization-cookie__right-menu-label">
                    Footer
                  </p>
                  <CustomiseColor
                    changeColor={this.props.changeEmailBg}
                    name="footerBg"
                    color={
                      this.props.emailFooterBgColor
                        ? this.props.emailFooterBgColor
                        : "#f4f6fc"
                    }
                    index={6}
                  />
                </div>

                {index !== 5 &&
                index !== 8 &&
                index !== 9 &&
                index !== 12 &&
                index !== 13 &&
                index !== 14 &&
                index !== 22 &&
                index !== 20 &&
                index !== 1 &&
                index !== 10 ? (
                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      Button
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeEmailBg}
                      name="btnBg"
                      color={
                        this.props.emailButtonBg
                          ? this.props.emailButtonBg
                          : "#7450c8"
                      }
                      index={7}
                    />
                  </div>
                ) : null}
              </div>

              <div className="customization-cookie__divider" />
            </div>
          ) : null}

          {isCookieCustomization ? (
            <div className="customization-cookie__divider" />
          ) : null}

          {index !== 5 &&
          index !== 8 &&
          index !== 9 &&
          index !== 12 &&
          index !== 13 &&
          index !== 14 &&
          index !== 22 &&
          index !== 20 &&
          index !== 1 &&
          index !== 10 &&
          !isSiteCustomization &&
          !isSell &&
          !isBranding ? (
            <CustomiseText
              title={
                !isEmailCustomization && !isSiteCustomization
                  ? "Main button text"
                  : isSiteCustomization
                  ? "Subtitle"
                  : "Button Text"
              }
              isEmail={
                isEmailCustomization || isCookieCustomization ? true : false
              }
              fontFamilyName="buttonFont"
              color={
                isSiteCustomization
                  ? this.props.subtitleColor
                    ? this.props.subtitleColor
                    : "#7450c8"
                  : isEmailCustomization
                  ? this.props.emailButtonColor
                    ? this.props.emailButtonColor
                    : "#fff"
                  : this.props.buttonColor
                  ? this.props.buttonColor
                  : "#fff"
              }
              font={
                isSiteCustomization
                  ? this.props.siteSubtitleFont
                    ? capitalize(this.props.siteSubtitleFont)
                    : "Roboto"
                  : isEmailCustomization
                  ? capitalize(this.props.emailButtonFont)
                  : this.props.cookieButtonFont
                  ? capitalize(this.props.cookieButtonFont)
                  : "Roboto"
              }
              colorName={
                isEmailCustomization
                  ? "buttonColor"
                  : isSiteCustomization
                  ? "subtitleColor"
                  : "buttonColor"
              }
              fontSizeName={
                isEmailCustomization
                  ? "buttonSize"
                  : isSiteCustomization
                  ? "subtitleSize"
                  : "buttonSize"
              }
              getGoogleFont={this.props.getGoogleFont}
              size={
                isSiteCustomization
                  ? this.props.subtitleSize
                    ? this.props.subtitleSize
                    : 18
                  : isEmailCustomization
                  ? this.props.emailButtonSize
                  : this.props.buttonSize
                  ? parseInt(this.props.buttonSize)
                  : 14
              }
              changeColor={this.props.changeTextColor}
              changeFontSize={this.props.changeFontSize}
              index={3}
              getAligned={this.props.getAligned}
              getBold={this.props.getBold}
              getItalic={this.props.getItalic}
              alignName="emailBtnAlign"
              boldName="emailBtnBold"
              italicName="emailBtnItalic"
              boldValue={this.props.emailBtnBold}
              italicValue={this.props.emailBtnItalic}
              alignValue={this.props.emailBtnAlign}
            />
          ) : null}

          {isBranding ? (
            <Fragment>
              <CustomiseText
                title="Legalsite Title"
                color={
                  this.props.brandingTextColor
                    ? this.props.brandingTextColor
                    : "#333"
                }
                fontFamilyName="brandingTextFont"
                font={
                  this.props.brandingTextFont
                    ? capitalize(this.props.brandingTextFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingTextColor"
                fontSizeName="brandingTextSize"
                size={
                  this.props.brandingTextSize
                    ? parseInt(this.props.brandingTextSize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={42}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingTitleAlign"
                boldName="brandingTitleBold"
                italicName="brandingTitleItalic"
                boldValue={this.props.brandingTitleBold}
                italicValue={this.props.brandingTitleItalic}
                alignValue={this.props.brandingTitleAlign}
              />

              <CustomiseText
                title="LegalSite ‘manage info’ section title"
                color={
                  this.props.manageTextColor
                    ? this.props.manageTextColor
                    : "#333"
                }
                fontFamilyName="manageTextFont"
                font={
                  this.props.manageTextFont
                    ? capitalize(this.props.manageTextFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="manageTextColor"
                fontSizeName="manageTextSize"
                size={
                  this.props.manageTextSize
                    ? parseInt(this.props.manageTextSize)
                    : 28
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={981}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="manageTitleAlign"
                boldName="manageTitleBold"
                italicName="manageTitleItalic"
                boldValue={this.props.manageTitleBold}
                italicValue={this.props.manageTitleItalic}
                alignValue={this.props.manageTitleAlign}
              />

              <CustomiseText
                title="Subtitle - applies to LegalSite, e-mail and pop-up"
                color={
                  this.props.brandingBodyColor
                    ? this.props.brandingBodyColor
                    : "#7450c8"
                }
                fontFamilyName="brandingBodyFont"
                font={
                  this.props.brandingBodyFont
                    ? capitalize(this.props.brandingBodyFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingBodyColor"
                fontSizeName="brandingBodySize"
                size={
                  this.props.brandingBodySize
                    ? parseInt(this.props.brandingBodySize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={45}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingBodyAlign"
                boldName="brandingBodyBold"
                italicName="brandingBodyItalic"
                boldValue={this.props.brandingBodyBold}
                italicValue={this.props.brandingBodyItalic}
                alignValue={this.props.brandingBodyAlign}
              />

              <CustomiseText
                title="Body text"
                color={
                  this.props.brandingSubtitleColor
                    ? this.props.brandingSubtitleColor
                    : "#333"
                }
                fontFamilyName="brandingSubtitleFont"
                font={
                  this.props.brandingSubtitleFont
                    ? capitalize(this.props.brandingSubtitleFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingSubtitleColor"
                fontSizeName="brandingSubtitleSize"
                size={
                  this.props.brandingSubtitleSize
                    ? parseInt(this.props.brandingSubtitleSize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={43}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingSubtitleAlign"
                boldName="brandingSubtitleBold"
                italicName="brandingSubtitleItalic"
                boldValue={this.props.brandingSubtitleBold}
                italicValue={this.props.brandingSubtitleItalic}
                alignValue={this.props.brandingSubtitleAlign}
              />

              <CustomiseText
                title="Main button text"
                color={
                  this.props.brandingButtonColor
                    ? this.props.brandingButtonColor
                    : "#333"
                }
                fontFamilyName="brandingButtonFont"
                font={
                  this.props.brandingButtonFont
                    ? capitalize(this.props.brandingButtonFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingButtonColor"
                fontSizeName="brandingButtonSize"
                size={
                  this.props.brandingButtonSize
                    ? parseInt(this.props.brandingButtonSize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={44}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingButtonAlign"
                boldName="brandingButtonBold"
                italicName="brandingButtonItalic"
                boldValue={this.props.brandingButtonBold}
                italicValue={this.props.brandingButtonItalic}
                alignValue={this.props.brandingButtonAlign}
              />

              <CustomiseText
                title="Pop-up text button and links"
                color={
                  this.props.brandingTextButtonColor
                    ? this.props.brandingTextButtonColor
                    : "#333"
                }
                fontFamilyName="brandingTextButtonFont"
                font={
                  this.props.brandingTextButtonFont
                    ? capitalize(this.props.brandingTextButtonFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingTextButtonColor"
                fontSizeName="brandingTextButtonSize"
                size={
                  this.props.brandingTextButtonSize
                    ? parseInt(this.props.brandingTextButtonSize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={414}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingTextButtonAlign"
                boldName="brandingTextButtonBold"
                italicName="brandingTextButtonItalic"
                boldValue={this.props.brandingTextButtonBold}
                italicValue={this.props.brandingTextButtonItalic}
                alignValue={this.props.brandingTextButtonAlign}
              />

              <CustomiseText
                title="Links and footer text"
                color={
                  this.props.brandingLinksColor
                    ? this.props.brandingLinksColor
                    : "#969696"
                }
                fontFamilyName="brandingLinksFont"
                font={
                  this.props.brandingLinksFont
                    ? capitalize(this.props.brandingLinksFont)
                    : "Roboto"
                }
                getGoogleFont={this.props.getGoogleFont}
                colorName="brandingLinksColor"
                fontSizeName="brandingLinksSize"
                size={
                  this.props.brandingLinksSize
                    ? parseInt(this.props.brandingLinksSize)
                    : 14
                }
                changeColor={this.props.changeTextColor}
                changeFontSize={this.props.changeFontSize}
                index={49}
                isBranding={true}
                getAligned={this.props.getAligned}
                getBold={this.props.getBold}
                getItalic={this.props.getItalic}
                alignName="brandingLinksAlign"
                boldName="brandingLinksBold"
                italicName="brandingLinksItalic"
                boldValue={this.props.brandingLinksBold}
                italicValue={this.props.brandingLinksItalic}
                alignValue={this.props.brandingLinksAlign}
              />

              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between">
                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      Buttons
                      <br />
                      and Icons
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="iconsColor"
                      color={
                        this.props.iconsColor
                          ? this.props.iconsColor
                          : "#7450c8"
                      }
                      index={392}
                    />
                  </div>

                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      Header
                      <br />
                      and Footer
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="btnBg"
                      color={this.props.btnBg ? this.props.btnBg : "#7450c8"}
                      index={31}
                    />
                  </div>

                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      Legalsite
                      <br />
                      Background
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="legalsiteBg"
                      color={
                        this.props.legalsiteBg
                          ? this.props.legalsiteBg
                          : "#7450c8"
                      }
                      index={35}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      LegalSite
                      <br />
                      Navbar/ cards
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="cardsColor"
                      color={
                        this.props.cardsColor
                          ? this.props.cardsColor
                          : "#7450c8"
                      }
                      index={393}
                    />
                  </div>

                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      E-mails
                      <br />
                      Background
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="emailBg"
                      color={
                        this.props.emailBg ? this.props.emailBg : "#7450c8"
                      }
                      index={386}
                    />
                  </div>

                  <div className="d-flex mr-auto flex-column mb-5 mt-4">
                    <p className="customization-cookie__right-menu-label">
                      Pop-ups
                      <br />
                      Background
                    </p>
                    <CustomiseColor
                      changeColor={this.props.changeBg}
                      name="popupBg"
                      color={
                        this.props.popupBg ? this.props.popupBg : "#7450c8"
                      }
                      index={359}
                    />
                  </div>
                </div>

                {/* <div className="d-flex mr-auto flex-column mb-5 mt-4">
                  <p className="customization-cookie__right-menu-label">
                    Background Color
                  </p>
                  <CustomiseColor
                    changeColor={this.props.changeBg}
                    name="bgColor"
                    color={this.props.bgColor ? this.props.bgColor : '#f4f6fc'}
                    index={30}
                  />
                </div> */}
              </div>
            </Fragment>
          ) : null}

          {isBranding ? (
            (legalsite && !legalsite.showLegalsiteBranding) ||
            this.props.emailBranding ||
            this.props.popupBranding ? (
              <div className="settings__remove" onClick={this.brandingRemove}>
                <Browser className="mr-2" />
                Remove LegalSite Branding
              </div>
            ) : null
          ) : null}

          {!isSiteCustomization && !isSell && !isBranding ? (
            <CustomiseText
              title={
                !isEmailCustomization && !isSiteCustomization
                  ? "Text button and links"
                  : isSiteCustomization
                  ? "Button Text"
                  : "Footer text"
              }
              isEmail={
                isEmailCustomization || isCookieCustomization ? true : false
              }
              color={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.buttonTextColor
                    ? this.props.buttonTextColor
                    : "#7450c8"
                  : isSiteCustomization
                  ? this.props.siteBtnColor
                    ? this.props.siteBtnColor
                    : "#ffffff"
                  : this.props.emailFooterColor
              }
              fontFamilyName="footerFont"
              font={
                isSiteCustomization
                  ? this.props.siteButtonFont
                    ? capitalize(this.props.siteButtonFont)
                    : "Roboto"
                  : isEmailCustomization
                  ? capitalize(this.props.emailFooterFont)
                  : this.props.cookieButtonTextFont
                  ? capitalize(this.props.cookieButtonTextFont)
                  : "Roboto"
              }
              getGoogleFont={this.props.getGoogleFont}
              colorName={
                isEmailCustomization
                  ? "buttonTextColor"
                  : isSiteCustomization
                  ? "btnColor"
                  : "buttonTextColor"
              }
              fontSizeName={
                isEmailCustomization
                  ? "buttonTextSize"
                  : isSiteCustomization
                  ? "siteBtnSize"
                  : "buttonTextSize"
              }
              size={
                !isEmailCustomization && !isSiteCustomization
                  ? this.props.buttonTextSize
                    ? parseInt(this.props.buttonTextSize)
                    : 14
                  : isSiteCustomization
                  ? this.props.siteBtnSize
                    ? this.props.siteBtnSize
                    : 16
                  : this.props.emailFooterSize
              }
              changeColor={this.props.changeTextColor}
              changeFontSize={this.props.changeFontSize}
              index={4}
              getAligned={this.props.getAligned}
              getBold={this.props.getBold}
              getItalic={this.props.getItalic}
              alignName="emailFooterAlign"
              boldName="emailFooterBold"
              italicName="emailFooterItalic"
              boldValue={this.props.emailFooterBold}
              italicValue={this.props.emailFooterItalic}
              alignValue={this.props.emailFooterAlign}
            />
          ) : null}
        </div>

        {!isEmailCustomization &&
        !isSiteCustomization &&
        !isSell &&
        !isBranding ? (
          <div className="customization-cookie__right-menu-snippet">
            <p className="customise-text__label">Pop-up snippet</p>

            <p className="customise-text__label-secondary">
              Copy this snippet to use the pop-up on your website.
            </p>

            <Input
              className="customise-text__textarea"
              type="textarea"
              spellCheck="false"
              placeholder="Publish your pop-up in order to get the snippet"
              value={this.props.snippet ? this.props.snippet : ""}
              onChange={() => {}}
            />
          </div>
        ) : isSell ? (
          <div className="customization-cookie__right-menu-snippet">
            <p className="customise-text__label">Button HTML code</p>

            <p className="customise-text__label-secondary">
              Copy this html code to use the button on your website.
            </p>

            <Input
              className="customise-text__textarea"
              type="textarea"
              spellCheck="false"
              placeholder="No snippet available"
              value={this.props.sellSnippet ? this.props.sellSnippet : ""}
              onChange={() => {}}
            />
          </div>
        ) : null}

        {!this.props.isAdmin ? (
          (isEmailCustomization && email && email.showLegalsiteBranding) ||
          (isCookieCustomization && popup && popup.showLegalsiteBranding) ? (
            <div
              className="settings__remove p-0 justify-content-center"
              onClick={() => this.removeBranding()}
            >
              <Browser className="mr-2" />
              Remove LegalSite Branding
            </div>
          ) : (
            false
          )
        ) : null}

        <ShowConsentModal
          showConsentModal={this.showConsentModal}
          isForEmail={isEmailCustomization}
          isForLegalSite={isSiteCustomization}
          isConsentModalVisible={this.state.isConsentModalVisible}
        />

        <PreviewModal
          footerLinkColor={this.props.footerLinkColor}
          btnType={this.props.btnType}
          sellBg={this.props.sellBg}
          sellBtnColor={this.props.sellBtnColor}
          sellBtnSize={this.props.sellBtnSize}
          sellBtnFont={this.props.sellBtnFont}
          footerLogo={this.props.footerLogo}
          footerLinkSize={this.props.footerLinkSize}
          footerLinkFont={this.props.footerLinkFont}
          footerLinkAlign={this.props.footerLinkAlign}
          footerLinkBold={this.props.footerLinkBold}
          footerLinkItalic={this.props.footerLinkItalic}
          footerTextColor={this.props.footerTextColor}
          footerTextSize={this.props.footerTextSize}
          homepageTitle={this.props.homepageTitle}
          homepageDescription={this.props.homepageDescription}
          footerTextFont={this.props.footerTextFont}
          footerTextAlign={this.props.footerTextAlign}
          footerTextBold={this.props.footerTextBold}
          footerTextItalic={this.props.footerTextItalic}
          footerBg={this.props.footerBg}
          bodyIconsColor={this.props.bodyIconsColor}
          bodyTitleColor={this.props.bodyTitleColor}
          bodyTitleFont={this.props.bodyTitleFont}
          bodyTitleSize={this.props.bodyTitleSize}
          bodyTitleAlign={this.props.bodyTitleAlign}
          bodyTitleBold={this.props.bodyTitleBold}
          bodyTitleItalic={this.props.bodyTitleItalic}
          bodySubtitleColor={this.props.bodySubtitleColor}
          bodySubtitleSize={this.props.bodySubtitleSize}
          bodySubtitleFont={this.props.bodySubtitleFont}
          bodySubtitleAlign={this.props.bodySubtitleAlign}
          bodySubtitleBold={this.props.bodySubtitleBold}
          bodySubtitleItalic={this.props.bodySubtitleItalic}
          bodyTextColor={this.props.bodyTextColor}
          bodyTextSize={this.props.bodyTextSize}
          bodyTextFont={this.props.bodyTextFont}
          bodyTextAlign={this.props.bodyTextAlign}
          bodyTextBold={this.props.bodyTextBold}
          bodyTextItalic={this.props.bodyTextItalic}
          bodyBtnColor={this.props.bodyBtnColor}
          bodyBtnSize={this.props.bodyBtnSize}
          bodyBtnFont={this.props.bodyBtnFont}
          bodyBtnAlign={this.props.bodyBtnAlign}
          bodyBtnBold={this.props.bodyBtnBold}
          bodyBtnItalic={this.props.bodyBtnItalic}
          bodyBg={this.props.bodyBg}
          cardsBodyAlign={this.props.cardsBodyAlign}
          cardsBodyBold={this.props.cardsBodyBold}
          cardsBodyItalic={this.props.cardsBodyItalic}
          cardsBtnAlign={this.props.cardsBtnAlign}
          cardsBtnBold={this.props.cardsBtnBold}
          cardsBtnItalic={this.props.cardsBtnItalic}
          cardsTitleAlign={this.props.cardsTitleAlign}
          cardsTitleBold={this.props.cardsTitleBold}
          cardsTitleItalic={this.props.cardsTitleItalic}
          cardsBg={this.props.cardsBg}
          bodyBtnBg={this.props.bodyBtnBg}
          cardsBodySize={this.props.cardsBodySize}
          cardsIconsColor={this.props.cardsIconsColor}
          cardsTitleColor={this.props.cardsTitleColor}
          cardsTitleFont={this.props.cardsTitleFont}
          cardsTitleSize={this.props.cardsTitleSize}
          cardsBodyFont={this.props.cardsBodyFont}
          cardsBodyColor={this.props.cardsBodyColor}
          cardsBtnColor={this.props.cardsBtnColor}
          cardsBtnFont={this.props.cardsBtnFont}
          cardsBtnSize={this.props.cardsBtnSize}
          cardsBtnBg={this.props.cardsBtnBg}
          headerBg={this.props.headerBg}
          titleBold={this.props.titleBold}
          titleItalic={this.props.titleItalic}
          titleAlign={this.props.titleAlign}
          emailBodyAlign={this.props.emailBodyAlign}
          emailBodyBold={this.props.emailBodyBold}
          emailBtnBold={this.props.emailBtnBold}
          emailBtnItalic={this.props.emailBtnItalic}
          emailBodyItalic={this.props.emailBodyItalic}
          headerBodySize={this.props.headerBodySize}
          headerBodyColor={this.props.headerBodyColor}
          headerBodyFont={this.props.headerBodyFont}
          headerBodyAlign={this.props.headerBodyAlign}
          headerBodyBold={this.props.headerBodyBold}
          headerBodyItalic={this.props.headerBodyItalic}
          headerTitleSize={this.props.headerTitleSize}
          headerTitleColor={this.props.headerTitleColor}
          headerTitleFont={this.props.headerTitleFont}
          headerTitleAlign={this.props.headerTitleAlign}
          headerTitleBold={this.props.headerTitleBold}
          headerTitleItalic={this.props.headerTitleItalic}
          navigationLinksAlign={this.props.navigationLinksAlign}
          navigationLinksBold={this.props.navigationLinksBold}
          navigationLinksItalic={this.props.navigationLinksItalic}
          navigationBg={this.props.navigationBg}
          linkColor={this.props.linkColor}
          navigationFont={this.props.navigationFont}
          navigationSize={this.props.navigationSize}
          isSiteCustomization={isSiteCustomization}
          isSell={isSell}
          legalsiteSettings={this.props.legalsiteSettings}
          hasEmail={this.props.hasEmail}
          isCookieCustomization={this.props.isCookieCustomization}
          showPreviewModal={this.showPreviewModal}
          isPreviewModalVisible={this.state.isPreviewModalVisible}
          templateActive={this.props.templateActive}
          titleColor={this.props.titleColor}
          titleSize={this.props.titleSize}
          bodyColor={this.props.bodyColor}
          bodySize={this.props.bodySize}
          buttonColor={this.props.buttonColor}
          selectedPage={this.props.selectedPage}
          buttonText={this.props.buttonText}
          secondaryText={this.props.secondaryText}
          cookieBg={this.props.cookieBg}
          cookieBtnColor={this.props.cookieBtnColor}
          buttonSize={this.props.buttonSize}
          buttonTextColor={this.props.buttonTextColor}
          buttonTextSize={this.props.buttonTextSize}
          secondaryButtonColor={this.props.secondaryButtonColor}
          cookieDescription={this.props.cookieDescription}
          cookieTitle={this.props.cookieTitle}
          buttonShape={this.props.buttonShape}
          popupOverlay={this.props.popupOverlay}
          popupSide={this.props.popupSide}
          popupPosition={this.props.popupPosition}
          contactBg={this.props.contactBg}
          btnBg={this.props.btnBg}
          welcomeBg={this.props.welcomeBg}
          account={this.props.account}
          bg={this.props.bg}
          legalsite={legalsite}
          titleSiteColor={this.props.titleSiteColor}
          titleSiteSize={this.props.titleSiteSize}
          bodySiteColor={this.props.bodySiteColor}
          bodySiteSize={this.props.bodySiteSize}
          subtitleColor={this.props.subtitleColor}
          subtitleSize={this.props.subtitleSize}
          siteBtnColor={this.props.siteBtnColor}
          siteBtnSize={this.props.siteBtnSize}
          siteTitleFont={this.props.siteTitleFont}
          siteBodyFont={this.props.siteBodyFont}
          siteButtonFont={this.props.siteButtonFont}
          siteSubtitleFont={this.props.siteSubtitleFont}
          cookieTitleFont={this.props.cookieTitleFont}
          cookieBodyFont={this.props.cookieBodyFont}
          cookieButtonTextFont={this.props.cookieButtonTextFont}
          cookieButtonFont={this.props.cookieButtonFont}
          buttonBg={this.props.buttonBg}
          dataProtectionOfficer={this.props.dataProtectionOfficer}
          emailFooterBold={this.props.emailFooterBold}
          emailFooterItalic={this.props.emailFooterItalic}
        />

        <Upgrade
          showUpgrade={this.showUpgrade}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type="legalsite"
          account={this.props.accountData}
        />

        <SaveBranding
          openSaveModal={this.openSaveModal}
          isSaveModalVisible={this.state.isSaveModalVisible}
          brand={this.props.brand}
        />

        <CollectModal
          openModal={this.openModal}
          isModalOpen={this.state.isModalOpen}
        />
      </div>
    );
  }
}

export default compose(
  graphql(TOGGLE_POPUP_BRANDING, {
    name: "togglePopupBranding",
  }),
  graphql(EDIT_LEGALSITE_SETTINGS, {
    name: "editLegalsiteSettings",
  }),
  graphql(TOGGLE_EMAIL_BRANDING, {
    name: "toggleEmailBranding",
  }),
  graphql(TOGGLE_LEGALSITE_BRANDING, {
    name: "toggleLegalsiteBranding",
  }),
  graphql(TOGGLE_ALL_BRANDING, {
    name: "toggleAllBranding",
  })
)(RightSideMenu);
