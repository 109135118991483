import React from "react";
import {
  UncontrolledDropdown,
  Button,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Form,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import FaCaret from "react-icons/lib/fa/angle-down";
import InteractionTimelineItem from "../Search/InteractionTimelineItem";
import ReactFilestack from "filestack-react";
import Moment from "react-moment";
import PropTypes from "prop-types";

function UserData(props) {
  const {
    userType,
    getUserType,
    user,
    isInitialShow,
    purpose,
    setPurpose,
    toggleType,
    submitPurpose,
    files,
    removeFile,
    fileRemoved,
    onFiles,
    notes,
    setNotes,
  } = props;

  return (
    <div className="user-details__content">
      <div
        className="user-details__content-exit"
        onClick={() => (window.location.href = "/users")}
      >
        Exit &nbsp;&nbsp;
        <MdClose size={20} />
      </div>
      <p className="user-details__content-title">Source</p>

      <Row className="d-flex justify-content-between mb-4">
        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">Date added</p>
          <p className="user-details__content-data">
            <Moment format="DD/MM/YY">
              {user && user.addedAt ? user.addedAt : null}
            </Moment>
          </p>
        </Col>

        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">Last updated</p>
          <p className="user-details__content-data">
            <Moment format="DD/MM/YY">
              {user && user.lastUpdated ? user.lastUpdated : null}
            </Moment>
          </p>
        </Col>

        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">How it was added</p>
          <p className="user-details__content-data">
            {user && user.addedMethod ? user.addedMethod : null}
          </p>
        </Col>
      </Row>

      <Row className="d-flex justify-content-between mt-5">
        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">Added by - Account</p>
          <p className="user-details__content-data">
            {user && user.addedByAccount ? user.addedByAccount : ""}
          </p>
        </Col>

        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">Added by - Name</p>
          <p className="user-details__content-data">
            {user && user.addedByName ? user.addedByName : ""}
          </p>
        </Col>

        <Col sm={12} md={4}>
          <p className="user-details__content-subtitle">Added by - Company</p>
          <p className="user-details__content-data">
            {user && user.addedByCompany ? user.addedByCompany : ""}
          </p>
        </Col>
      </Row>

      <div>
        <p className="user-details__content-subtitle mb-3 mt-5">User type</p>

        <div className="d-flex align-items-center user-details__content-keep ml-0">
          <span
            className={`${
              (user && user.__typename === "DataController" && isInitialShow) ||
              (!isInitialShow && userType === "Data Controller")
                ? "-is-active"
                : ""
            } user-details__content-check mr-2`}
            onClick={() => {
              getUserType("Data Controller", false);
              toggleType(user.id, user.__typename);
            }}
          />
          Data Controller
        </div>

        <div className="d-flex align-items-center user-details__content-keep">
          <span
            className={`${
              (user && user.__typename === "DataSubject" && isInitialShow) ||
              (!isInitialShow && userType === "Data Subject")
                ? "-is-active"
                : ""
            } user-details__content-check mr-2`}
            onClick={() => {
              getUserType("Data Subject", false);
              toggleType(user.id, user.__typename);
            }}
          />
          Data Subject
        </div>
      </div>

      <div className="mb-3">
        <p className="user-details__content-subtitle mb-3 mt-5">
          Purpose for processing data
        </p>

        <div>
          <UncontrolledDropdown className=" w-100">
            <DropdownToggle className="w-100 d-flex align-items-center pl-0 pt-0 border-0 text-left user-details__content-dropdown">
              {purpose && purpose === "MARKETING"
                ? "Marketing"
                : purpose && purpose === "SERVICE_ENCHANCEMENT"
                ? "Service enhancement"
                : purpose && purpose === "ADVERTISING"
                ? "Advertising"
                : purpose && purpose === "PRODUCT_DEVELOPMENT"
                ? "Product development"
                : purpose && purpose === "SYSTEM_INTEGRITY"
                ? "Systems integrity"
                : purpose && purpose === "HR_MATTERS"
                ? "HR matters"
                : purpose && purpose === "LEGITMATE_INTEREST"
                ? "Legitimate interest"
                : purpose && purpose === "COMMUNICATION"
                ? "Communication"
                : purpose && purpose === "PROCESSING_TRANSACTIONS"
                ? "Processing transactions"
                : purpose && purpose === "COMPLIANCE"
                ? "Compliance"
                : purpose && purpose === "RESEARCH"
                ? "Research"
                : purpose && purpose === "SECURITY"
                ? "Security"
                : purpose && purpose === "PERSONALIZATION"
                ? "Personalization"
                : purpose && purpose === "OTHER"
                ? "Other"
                : "Unknown"}{" "}
              <FaCaret className="chart__caret" size="20" color="#969696" />
            </DropdownToggle>
            <DropdownMenu>
              {purpose && purpose !== "MARKETING" ? (
                <DropdownItem onClick={() => setPurpose("MARKETING")}>
                  Marketing
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "SERVICE_ENCHANCEMENT" ? (
                <DropdownItem
                  onClick={() => setPurpose("SERVICE_ENCHANCEMENT")}
                >
                  Service enhancement
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "ADVERTISING" ? (
                <DropdownItem onClick={() => setPurpose("ADVERTISING")}>
                  Advertising
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "PRODUCT_DEVELOPMENT" ? (
                <DropdownItem onClick={() => setPurpose("PRODUCT_DEVELOPMENT")}>
                  Product development
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "SYSTEM_INTEGRITY" ? (
                <DropdownItem onClick={() => setPurpose("SYSTEM_INTEGRITY")}>
                  Systems integrity
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "HR_MATTERS" ? (
                <DropdownItem onClick={() => setPurpose("HR_MATTERS")}>
                  HR matters
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "LEGITMATE_INTEREST" ? (
                <DropdownItem onClick={() => setPurpose("LEGITMATE_INTEREST")}>
                  Legitimate interest
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "COMMUNICATION" ? (
                <DropdownItem onClick={() => setPurpose("COMMUNICATION")}>
                  Communication
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "PROCESSING_TRANSACTIONS" ? (
                <DropdownItem
                  onClick={() => setPurpose("PROCESSING_TRANSACTIONS")}
                >
                  Processing transactions
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "COMPLIANCE" ? (
                <DropdownItem onClick={() => setPurpose("COMPLIANCE")}>
                  Compliance
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "RESEARCH" ? (
                <DropdownItem onClick={() => setPurpose("RESEARCH")}>
                  Research
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "SECURITY" ? (
                <DropdownItem onClick={() => setPurpose("SECURITY")}>
                  Security
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "PERSONALIZATION" ? (
                <DropdownItem onClick={() => setPurpose("PERSONALIZATION")}>
                  Personalization
                </DropdownItem>
              ) : null}

              {purpose && purpose !== "OTHER" ? (
                <DropdownItem onClick={() => setPurpose("OTHER")}>
                  Other
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      <Button
        className="btn--primary user-details__info-submit"
        onClick={() => submitPurpose(user.__typename, user.id)}
      >
        Save changes
      </Button>

      <div className="user-details__content-divider" />

      <p className="user-details__content-title">
        Data Processing Agreement (DPA)
      </p>

      {(files && !files.length && !user.dpa) ||
      (files && !files.length && user.dpa && !user.dpa.file) ||
      fileRemoved ? (
        <ReactFilestack
          apikey={"AJNM9qOpGRljTn17sgxrfz"}
          componentDisplayMode={{
            type: "link",
            customText: (
              <Button className="btn--secondary user-details__info-submit">
                Upload DPA
              </Button>
            ),
            customClass: "show-upload",
          }}
          onSuccess={(res) => onFiles(res)}
          actionOptions={{
            fromSources: [
              "local_file_system",
              "googledrive",
              "dropbox",
              "onedrive",
            ],
            accept: [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ],
            maxFiles: 1,
          }}
        />
      ) : (
        <div className="d-flex flex-wrap justify-content-between">
          {files && files.length ? (
            <div className="inbox__internal-file mb-4">
              <div className="inbox__internal-file-type">DOC</div>
              <p className="inbox__internal-file-name">{files[0].key}</p>
              <MdClose
                onClick={removeFile}
                className="inbox__internal-file-remove"
                size={20}
                color={"#969696"}
              />
            </div>
          ) : user && user.dpa && user.dpa.file ? (
            <div className="inbox__internal-file mb-4">
              <div className="inbox__internal-file-type">DOC</div>
              <p className="inbox__internal-file-name">
                {user && user.dpa && user.dpa.file ? user.dpa.file : ""}
              </p>
              <MdClose
                onClick={removeFile}
                className="inbox__internal-file-remove"
                size={20}
                color={"#969696"}
              />
            </div>
          ) : null}
        </div>
      )}

      <div className="user-details__content-divider" />

      <p className="user-details__content-title">History</p>

      <span className="search-user__timeline-action">
        {user && user.interactions.length ? user.interactions.length : 0}{" "}
        interactions
      </span>

      <div className="search-user__interaction-content">
        {user && user.interactions.length
          ? user.interactions
              .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
              .map((item) => (
                <InteractionTimelineItem
                  key={item.id}
                  text={item.activity}
                  item={item}
                  type={
                    item.activity === "Requested data access"
                      ? "request"
                      : item.activity === "solved"
                      ? "solved"
                      : "sent"
                  }
                />
              ))
          : null}
      </div>

      <div className="user-details__content-divider" />

      <p className="user-details__content-title">Notes</p>

      <Form onSubmit={props.editNotes}>
        <Input
          type="textarea"
          placeholder="Notes"
          className="user-details__content-textarea"
          rows="9"
          name="notes"
          defaultValue={notes ? notes : user.notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <Button
          className="btn--primary user-details__info-submit"
          type="submit"
        >
          Save changes
        </Button>
      </Form>
    </div>
  );
}

export default UserData;
UserData.propTypes = {
  userType: PropTypes.string,
  getUserType: PropTypes.func,
  user: PropTypes.object,
  isInitialShow: PropTypes.bool,
  purpose: PropTypes.string,
  setPurpose: PropTypes.func,
  toggleType: PropTypes.func,
  submitPurpose: PropTypes.func,
  files: PropTypes.array,
  removeFile: PropTypes.func,
  fileRemoved: PropTypes.bool,
  onFiles: PropTypes.func,
  notes: PropTypes.string,
  setNotes: PropTypes.func,
  editNotes: PropTypes.func,
};
