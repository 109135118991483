import PropTypes from "prop-types";
import React from "react";

const ViewIcon = props => (
    <svg
        height={14}
        viewBox='0 0 20 14'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1132.000000, -1062.000000)" fill={props.color}>
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-4" transform="translate(0.000000, 52.000000)">
                            <g id="Group" transform="translate(951.000000, 0.000000)">
                                <path d="M9.67692308,10.9717182 C8.48988718,10.9717182 7.52649573,11.86265 7.52649573,12.9604054 C7.52649573,14.0591551 8.48988718,14.9490927 9.67692308,14.9490927 C10.8650342,14.9490927 11.8273504,14.0591551 11.8273504,12.9604054 C11.8273504,11.86265 10.8650342,10.9717182 9.67692308,10.9717182 M9.67692308,17.9321236 C7.12651624,17.9321236 4.53847692,16.1343503 2.46976581,12.9604054 C4.53847692,9.78646055 7.12651624,7.98868726 9.67692308,7.98868726 C12.2273299,7.98868726 14.8153692,9.78646055 16.8840803,12.9604054 C14.8153692,16.1343503 12.2273299,17.9321236 9.67692308,17.9321236 M9.67692308,6 C6.16957607,6 2.66115385,8.31980369 1.65201186e-13,12.9604054 L1.65201186e-13,12.9604054 C2.66115385,17.6010071 6.16957607,19.9208108 9.67692308,19.9208108 C13.1853453,19.9208108 16.6926923,17.6010071 19.3538462,12.9604054 L19.3538462,12.9604054 C16.6926923,8.31980369 13.1853453,6 9.67692308,6" id="Views-Icon"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ViewIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default ViewIcon;