import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import { Button } from "reactstrap";
import ActionsRequired from "./ActionsRequired";
import Actions from "./Actions";
import RetentionPeriod from "./RetentionPeriod";
import LegalBasis from "./LegalBasis";
import Purposes from "./Purposes";
import { graphql } from "@apollo/client/react/hoc";
import Sources from "./Sources";
import { EDIT_DATA_CATEGORY } from "../../graphql/editDataCategory";
import "./modals.scss";

class CompliancePrivacyEditModal extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    isEditModalVisible: PropTypes.bool.isRequired,
    checkedItem: PropTypes.object,
    title: PropTypes.string,
    id: PropTypes.string,
    editDataCategory: PropTypes.func.isRequired,
  };

  state = {
    isSources: true,
    isPurposes: false,
    isLegal: false,
    isRetention: false,
    isActions: false,
    isElements: false,
    sources: this.props.checkedItem ? this.props.checkedItem.sources : [],
    dp: this.props.checkedItem ? this.props.checkedItem.sourceSuppliers : [],
    purposes: this.props.checkedItem ? this.props.checkedItem.purposes : [],
    legal: this.props.checkedItem ? this.props.checkedItem.legalBasis : [],
    period: this.props.checkedItem
      ? this.props.checkedItem.retentionPeriod
      : "",
    actions: this.props.checkedItem
      ? this.props.checkedItem.actionRequired
      : "",
    elements: this.props.checkedItem ? this.props.checkedItem.elements : [],
  };

  saveData = () => {
    const { sources, purposes, legal, period, actions, elements, dp } =
      this.state;

    this.props
      .editDataCategory({
        variables: {
          id: this.props.id,
          sources,
          purposes,
          legalBasis: legal,
          retentionPeriod: period,
          actionRequired: actions,
          elements,
          sourceSuppliers: dp,
        },
      })
      .then(() => {
        this.props.showEditModal();

        this.setState({
          isSources: true,
          isPurposes: false,
          isLegal: false,
          isRetention: false,
          isActions: false,
          isElements: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { title, checkedItem } = this.props;

    const {
      isSources,
      isPurposes,
      isLegal,
      isRetention,
      isActions,
      isElements,
    } = this.state;

    const { sources, purposes, legal, period, actions, elements, dp } =
      this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isEditModalVisible}
          toggle={this.props.showEditModal}
          className="modals modal-edit-privacy-category"
        >
          <ModalBody>
            <div className="modal-edit-privacy-category__data">
              <h5 className="modal-edit-privacy-category__data-title">
                {title}
              </h5>
              <p className="modal-edit-privacy-category__data-text">
                Fill the information about the category of data collected.
              </p>

              <div>
                <div
                  className={`${
                    isSources ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: true,
                      isPurposes: false,
                      isLegal: false,
                      isRetention: false,
                      isActions: false,
                      isElements: false,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Sources
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>

                <div
                  className={`${
                    isPurposes ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: false,
                      isPurposes: true,
                      isLegal: false,
                      isRetention: false,
                      isActions: false,
                      isElements: false,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Purposes
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>

                <div
                  className={`${
                    isLegal ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: false,
                      isPurposes: false,
                      isLegal: true,
                      isRetention: false,
                      isActions: false,
                      isElements: false,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Legal basis
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>

                <div
                  className={`${
                    isRetention ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: false,
                      isPurposes: false,
                      isLegal: false,
                      isRetention: true,
                      isActions: false,
                      isElements: false,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Retention period
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>

                <div
                  className={`${
                    isActions ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: false,
                      isPurposes: false,
                      isLegal: false,
                      isRetention: false,
                      isActions: true,
                      isElements: false,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Actions required
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>

                <div
                  className={`${
                    isElements ? "-is-active" : ""
                  } modal-edit-privacy-category__data-item`}
                  onClick={() =>
                    this.setState({
                      isSources: false,
                      isPurposes: false,
                      isLegal: false,
                      isRetention: false,
                      isActions: false,
                      isElements: true,
                    })
                  }
                >
                  <span className="modal-edit-privacy-category__data-item-title">
                    Elements
                  </span>
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="compliance-privacy__category-icon"
                  />
                </div>
              </div>
            </div>
            <div className="modal-edit-privacy-category__content">
              {isSources ? (
                <Sources
                  showEditModal={this.props.showEditModal}
                  sources={sources && sources.length ? sources : null}
                  dp={dp && dp.length ? dp : null}
                  getSources={(sources) =>
                    this.setState({
                      sources: sources.filter((item) => item !== null),
                    })
                  }
                  getDP={(dp) =>
                    this.setState({
                      dp: dp ? dp.filter((item) => item !== null) : null,
                    })
                  }
                />
              ) : isPurposes ? (
                <Purposes
                  showEditModal={this.props.showEditModal}
                  purposes={
                    purposes && purposes.length > 0
                      ? purposes
                      : checkedItem
                      ? checkedItem.purposes
                      : null
                  }
                  getPurposes={(purposes) =>
                    this.setState({
                      purposes: purposes.filter((item) => item !== null),
                    })
                  }
                />
              ) : isLegal ? (
                <LegalBasis
                  showEditModal={this.props.showEditModal}
                  legalBase={
                    legal && legal.length > 0
                      ? legal
                      : checkedItem
                      ? checkedItem.legalBasis
                      : null
                  }
                  getLegalBasis={(legal) =>
                    this.setState({
                      legal: legal.filter((item) => item !== null),
                    })
                  }
                />
              ) : isRetention ? (
                <RetentionPeriod
                  showEditModal={this.props.showEditModal}
                  period={
                    period
                      ? period
                      : checkedItem
                      ? checkedItem.retentionPeriod
                      : null
                  }
                  getPeriod={(period) => this.setState({ period })}
                />
              ) : isActions ? (
                <Actions
                  showEditModal={this.props.showEditModal}
                  action={
                    actions
                      ? actions
                      : checkedItem
                      ? checkedItem.actionRequired
                      : null
                  }
                  getActions={(actions) =>
                    this.setState({
                      actions,
                    })
                  }
                />
              ) : (
                <ActionsRequired
                  showEditModal={this.props.showEditModal}
                  elements={
                    elements && elements.length > 0
                      ? elements
                      : checkedItem
                      ? checkedItem.elements
                      : null
                  }
                  getElements={(elements) =>
                    this.setState({
                      elements: elements.filter((item) => item !== null),
                    })
                  }
                />
              )}

              <div className="d-flex modal-edit-privacy-category__content-btns">
                {!isElements ? (
                  <Button onClick={this.saveData} className="btn--secondary">
                    Save and Close
                  </Button>
                ) : null}
                <Button
                  className="btn--primary"
                  onClick={
                    isSources
                      ? () =>
                          this.setState({
                            isSources: false,
                            isPurposes: true,
                            isLegal: false,
                            isRetention: false,
                            isActions: false,
                            isElements: false,
                          })
                      : isPurposes
                      ? () =>
                          this.setState({
                            isSources: false,
                            isPurposes: false,
                            isLegal: true,
                            isRetention: false,
                            isActions: false,
                            isElements: false,
                          })
                      : isLegal
                      ? () =>
                          this.setState({
                            isSources: false,
                            isPurposes: false,
                            isLegal: false,
                            isRetention: true,
                            isActions: false,
                            isElements: false,
                          })
                      : isRetention
                      ? () =>
                          this.setState({
                            isSources: false,
                            isPurposes: false,
                            isLegal: false,
                            isRetention: false,
                            isActions: true,
                            isElements: false,
                          })
                      : isActions
                      ? () =>
                          this.setState({
                            isSources: false,
                            isPurposes: false,
                            isLegal: false,
                            isRetention: false,
                            isActions: false,
                            isElements: true,
                          })
                      : this.saveData
                  }
                >
                  {isElements ? "Save and Close" : "Next"}
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(EDIT_DATA_CATEGORY, {
  name: "editDataCategory",
})(CompliancePrivacyEditModal);
