import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

export default class MeasuresBreach extends Component {
  static propTypes = {
    takenMeasures: PropTypes.string,
    mitigatingActionsImplemented: PropTypes.bool,
    securedBreachedData: PropTypes.bool,
    takenMeasuresData: PropTypes.func,
    mitigatingActions: PropTypes.func,
    securedBreached: PropTypes.func,
  };

  state = {
    isInitialOption: true,
    isMitigating: false,
    isInitialSecured: true,
    isSecured: false,
    description: "",
  };

  render() {
    const { takenMeasures, mitigatingActionsImplemented, securedBreachedData } =
      this.props;
    const { isInitialOption, isMitigating, isInitialSecured, isSecured } =
      this.state;

    return (
      <div className="compliance-breaches__where">
        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            What measures have you taken / do you propose to take in response to
            the breach?
          </h5>
          <Input
            type="textarea"
            defaultValue={takenMeasures}
            placeholder="Write a message..."
            onChange={(e) => {
              this.setState({ description: e.target.value });
              this.props.takenMeasuresData(e.target.value);
            }}
          />
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Are the mitigating actions fully implemented?
          </h5>

          <div className="d-flex mb-3">
            <span
              className={`${
                (mitigatingActionsImplemented && isInitialOption) ||
                (!isInitialOption && isMitigating)
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({ isMitigating: true, isInitialOption: false });
                this.props.mitigatingActions(true);
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">Yes</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (!mitigatingActionsImplemented && isInitialOption) ||
                (!isInitialOption && !isMitigating)
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({ isMitigating: false, isInitialOption: false });
                this.props.mitigatingActions(false);
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">No</span>
            </div>
          </div>
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Have you secured/retrieved the breached data?
          </h5>

          <div className="d-flex mb-3">
            <span
              className={`${
                (securedBreachedData && isInitialSecured) ||
                (!isInitialSecured && isSecured)
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({ isSecured: true, isInitialSecured: false });
                this.props.securedBreached(true);
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">Yes</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (!securedBreachedData && isInitialSecured) ||
                (!isInitialSecured && !isSecured)
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({ isSecured: false, isInitialSecured: false });
                this.props.securedBreached(false);
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">No</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
