import gql from "graphql-tag";

export const UPGRADE_QUERY = gql`
  {
    account {
      email
      id
    }
  }
`;
