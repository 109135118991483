import PropTypes from "prop-types";
import React from "react";

const YellowUser = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="previous" transform="translate(-42.000000, -463.000000)">
                <g id="Group">
                    <g transform="translate(0.000000, 316.000000)">
                        <g id="Group-9" transform="translate(0.000000, 114.000000)">
                            <g id="Previous">
                                <g id="user-circle" transform="translate(40.000000, 31.000000)">
                                    <rect id="button-bg" x="0" y="0" width="24" height="24" rx="2"></rect>
                                    <path d="M11.9812037,2 C8.01830551,2.00752939 4.43373222,4.3545171 2.84214502,7.98376899 C1.25055782,11.6130209 1.95227255,15.8397352 4.63120375,18.76 C6.52435959,20.8121118 9.18921484,21.9793289 11.9812037,21.9793289 C14.7731927,21.9793289 17.4380479,20.8121118 19.3312037,18.76 C22.0101349,15.8397352 22.7118497,11.6130209 21.1202625,7.98376899 C19.5286753,4.3545171 15.944102,2.00752939 11.9812037,2 Z M11.9812037,20 C9.90966881,19.9968517 7.92011882,19.1902774 6.43120375,17.75 C7.35573681,15.4992971 9.54801138,14.0298026 11.9812037,14.0298026 C14.4143961,14.0298026 16.6066707,15.4992971 17.5312037,17.75 C16.0422887,19.1902774 14.0527387,19.9968517 11.9812037,20 Z M9.98120375,10 C9.98120375,8.8954305 10.8766342,8 11.9812037,8 C13.0857732,8 13.9812037,8.8954305 13.9812037,10 C13.9812037,11.1045695 13.0857732,12 11.9812037,12 C10.8766342,12 9.98120375,11.1045695 9.98120375,10 Z M18.8912037,16 C17.997735,14.471759 16.6226057,13.2830283 14.9812037,12.62 C16.3994321,11.0118803 16.2974241,8.57151486 14.7499381,7.08736276 C13.202452,5.60321066 10.7599555,5.60321066 9.21246944,7.08736276 C7.66498336,8.57151486 7.56297544,11.0118803 8.98120375,12.62 C7.33980181,13.2830283 5.96467247,14.471759 5.07120375,16 C4.35914752,14.787102 3.98292381,13.4064646 3.98120375,12 C3.98120375,7.581722 7.56292575,4 11.9812037,4 C16.3994817,4 19.9812037,7.581722 19.9812037,12 C19.9794837,13.4064646 19.60326,14.787102 18.8912037,16 Z" id="Shape" fill="#EBA601" fillRule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

YellowUser.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default YellowUser;