import gql from "graphql-tag";

export const ADD_SIGNATURE_TO_PAGES = gql`
  mutation($signatureUrl: String!, $clientMutationId: String) {
    addSignatureToPages(
      input: {
        signatureUrl: $signatureUrl
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      account {
        id
        pagesSignature
      }
    }
  }
`;
