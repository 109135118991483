import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ChatBubble from "../../components/Icons/ChatBubble";
import Consent from "../../components/Icons/Consent";

function ManageItem(props) {
  const {
    legalsite,
    isManage,
    bodySubtitleColor,
    bodySubtitleSize,
    bodySubtitleFont,
    bodySubtitleAlign,
    bodySubtitleBold,
    bodySubtitleItalic,
    bodyBtnColor,
    bodyBtnSize,
    bodyBtnFont,
    bodyBtnAlign,
    bodyBtnBold,
    bodyBtnItalic,
    bodyTextColor,
    bodyTextSize,
    bodyTextFont,
    bodyTextAlign,
    bodyTextBold,
    bodyTextItalic,
    bodyIconsColor,
    bodyBtnBg,
  } = props;

  return (
    <div className="d-flex flex-column align-items-center justify-content-center customization-site__info-details-item">
      {isManage ? (
        <Consent
          color={
            bodyIconsColor
              ? bodyIconsColor
              : legalsite.bodyIconsColor
              ? legalsite.bodyIconsColor
              : "#7450c8"
          }
        />
      ) : (
        <ChatBubble
          color={
            bodyIconsColor
              ? bodyIconsColor
              : legalsite.bodyIconsColor
              ? legalsite.bodyIconsColor
              : "#7450c8"
          }
        />
      )}
      <p
        className="customization-site__info-title mt-4"
        style={{
          color: bodySubtitleColor
            ? bodySubtitleColor
            : legalsite.bodySubtitleColor,
          fontSize: bodySubtitleSize
            ? bodySubtitleSize
            : legalsite.bodySubtitleSize,
          fontFamily: bodySubtitleFont
            ? bodySubtitleFont
            : legalsite.bodySubtitleFont,
          textAlign:
            bodySubtitleAlign !== null
              ? bodySubtitleAlign
              : legalsite.bodySubtitleAlign,
          fontWeight:
            bodySubtitleBold !== null
              ? bodySubtitleBold === true
                ? 900
                : 300
              : legalsite.bodySubtitleBold
              ? 900
              : 300,

          fontStyle:
            bodySubtitleItalic !== null
              ? bodySubtitleItalic === true
                ? "italic"
                : "normal"
              : legalsite.bodySubtitleItalic
              ? "italic"
              : "normal",
        }}
      >
        {isManage ? "Manage Consent" : "Requests"}
      </p>
      <p
        className="customization-site__info-text"
        style={{
          color: bodyTextColor ? bodyTextColor : legalsite.bodyTextColor,
          fontSize: bodyTextSize ? bodyTextSize : legalsite.bodyTextSize,
          fontFamily: bodyTextFont ? bodyTextFont : legalsite.bodyTextFont,
          textAlign:
            bodyTextAlign !== null ? bodyTextAlign : legalsite.bodyTextAlign,
          fontWeight:
            bodyTextBold !== null
              ? bodyTextBold === true
                ? 900
                : 300
              : legalsite.bodyTextBold
              ? 900
              : 300,

          fontStyle:
            bodyTextItalic !== null
              ? bodyTextItalic === true
                ? "italic"
                : "normal"
              : legalsite.bodyTextItalic
              ? "italic"
              : "normal",
        }}
      >
        {isManage
          ? "Review your consent settings here"
          : "Send data requests, questions or complaints"}
      </p>
      <Button
        className="customization-site__btn"
        style={{
          color: bodyBtnColor ? bodyBtnColor : legalsite.bodyBtnColor,
          fontSize: bodyBtnSize ? bodyBtnSize : legalsite.bodyBtnSize,
          fontFamily: bodyBtnFont ? bodyBtnFont : legalsite.bodyBtnFont,
          textAlign:
            bodyBtnAlign !== null ? bodyBtnAlign : legalsite.bodyBtnAlign,
          backgroundColor: bodyBtnBg
            ? bodyBtnBg
            : legalsite.bodyBtnBg
            ? legalsite.bodyBtnBg
            : "#7450C8",
          fontWeight:
            bodyBtnBold !== null
              ? bodyBtnBold === true
                ? 900
                : 300
              : legalsite.bodyBtnBold
              ? 900
              : 300,
          fontStyle:
            bodyBtnItalic !== null
              ? bodyBtnItalic === true
                ? "italic"
                : "normal"
              : legalsite.bodyBtnItalic
              ? "italic"
              : "normal",
        }}
      >
        {isManage ? "Review" : "Request"}
      </Button>
    </div>
  );
}

export default ManageItem;
ManageItem.propTypes = {
  btnBg: PropTypes.string,
  legalsite: PropTypes.object.isRequired,
  subtitleColor: PropTypes.string,
  subtitleSize: PropTypes.string,
  buttonFont: PropTypes.string,
  bodyColor: PropTypes.string,
  bodySize: PropTypes.string,
  bodyFont: PropTypes.string,
  btnColor: PropTypes.string,
  siteBtnSize: PropTypes.string,
  footerFont: PropTypes.string,
  isManage: PropTypes.bool,
  bodySubtitleColor: PropTypes.string,
  bodySubtitleSize: PropTypes.string,
  bodySubtitleFont: PropTypes.string,
  bodySubtitleAlign: PropTypes.string,
  bodySubtitleBold: PropTypes.bool,
  bodySubtitleItalic: PropTypes.bool,
  bodyBtnColor: PropTypes.string,
  bodyBtnSize: PropTypes.string,
  bodyBtnFont: PropTypes.string,
  bodyBtnAlign: PropTypes.string,
  bodyBtnBold: PropTypes.bool,
  bodyBtnItalic: PropTypes.bool,
  bodyTextColor: PropTypes.string,
  bodyTextSize: PropTypes.string,
  bodyTextFont: PropTypes.string,
  bodyTextAlign: PropTypes.string,
  bodyTextBold: PropTypes.bool,
  bodyTextItalic: PropTypes.bool,
  bodyIconsColor: PropTypes.string,
  bodyBtnBg: PropTypes.string,
};
