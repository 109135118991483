import gql from "graphql-tag";

export const ALL_USERS_QUERY = gql`
  query($usersFilter: UsersFilterEnum!, $name: String!) {
    searchUser(name: $name) {
      id
      avatar
      name
      account {
        id
        companyName
        teamSize
        companyLogo
        access
        email
        plan
        teamSize
        country
        downloadUrl
        users {
          id
          avatar
          name
          email
          phoneNumber
          jobTitle
          role
          account {
            companyName
            companyLogo
            country
            teamSize
            plan
            access
            downloadUrl
            id
          }
        }
      }
      email
      phoneNumber
      downloadUrl
      jobTitle
      role
    }
    allUsers(filter: $usersFilter) {
      id
      avatar
      name
      account {
        id
        companyName
        teamSize
        companyLogo
        access
        email
        plan
        teamSize
        country
        downloadUrl
        users {
          id
          avatar
          name
          email
          phoneNumber
          jobTitle
          role
          account {
            companyName
            companyLogo
            country
            teamSize
            plan
            access
            downloadUrl
            id
          }
        }
      }
      email
      phoneNumber
      downloadUrl
      jobTitle
      role
    }
  }
`;
