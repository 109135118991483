import gql from "graphql-tag";

export const ASSIGN_REQUEST_TO_USER = gql`
  mutation($requestId: ID!, $userId: ID!, $clientMutationId: String) {
    assignRequestToUser(
      input: {
        requestId: $requestId
        userId: $userId
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      request {
        id
        assignedTo {
          id
        }
      }
    }
  }
`;
