import gql from "graphql-tag";

export const CONNECT_TO_INTEGRATION = gql`
  mutation($integrationId: ID!, $clientMutationId: String) {
    connectToIntegration(
      input: {
        integrationId: $integrationId
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      integration {
        id
      }
    }
  }
`;
