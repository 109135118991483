import React, { Component } from "react";
import CookiesIcon from "../../components/Icons/Cookies";
import { Input } from "reactstrap";
import CookieItem from "./CookieItem";
import {
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { TOGGLE_CONSENTS } from "../../graphql/toggleConsents";
import { LEGALSITE_QUERY } from "../../graphql/legalSite";
import { REMOVE_CONSENT } from "../../graphql/removeConsent";
import { EDIT_CONSENT_MESSAGE } from "../../graphql/editConsentMessage";
import { EDIT_AUDIENCE } from "../../graphql/editTargetAudienceCookiePopup";
import FaCaret from "react-icons/lib/fa/angle-down";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import PreviewConsent from "../../components/Modals/PreviewConsent";
import PropTypes from "prop-types";

class Cookies extends Component {
  state = {
    consents: this.props.consents,
    selectedItems: null,
    selectedItemsArray: null,
    isPreviewModalVisible: false,
    descr:
      this.props.legalsiteSettings &&
      this.props.legalsiteSettings.consentMessage
        ? this.props.legalsiteSettings.consentMessage
        : "Here you can manage what data you agree to share with us. Don't worry, you can change them anytime you like.",
    audience:
      this.props.popup && this.props.popup.targetAudience
        ? this.props.popup.targetAudience
        : null,
  };

  getCookie = (
    enabled,
    id,
    categoryName,
    smallDescription,
    completeDescription
  ) => {
    var arr1 = this.state.consents;
    var arr2 = [
      { id, enabled, categoryName, smallDescription, completeDescription },
    ];

    var res = arr1.map((obj) => arr2.find((o) => o.id === obj.id) || obj);

    this.setState({
      consents: res,
    });

    const selectedItems = res.map((item) => {
      return {
        id: item.id,
        enabled: item.enabled,
        categoryName: item.categoryName,
        smallDescription: item.smallDescription,
        completeDescription: item.completeDescription,
      };
    });

    const selectedItemsArray = res.map((item) => {
      return {
        id: item.id,
        enabled: item.enabled,
      };
    });

    this.setState({
      selectedItems,
      selectedItemsArray,
    });
  };

  saveChanges = (enabled, id) => {
    this.props
      .toggleConsents({
        variables: {
          consents: {
            id,
            enabled,
          },
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  showPreviewModal = () => {
    this.setState({
      isPreviewModalVisible: !this.state.isPreviewModalVisible,
    });
  };

  selectAudience = (audience) => {
    this.setState({
      audience,
    });

    this.props
      .editAudience({
        variables: {
          targetAudience: audience,
        },
      })
      .then(() => {
        this.props.notify("Changes saved!");
      })
      .catch((error) => {
        console.log(error);
        this.props.notify("Changes were not saved!");
      });
  };

  saveConsentMessage = () => {
    const msg = this.state.descr;

    this.props
      .editConsentMsg({
        variables: {
          consentMessage: msg,
        },
      })
      .then(() => {
        this.props.notify("Cookie Consent description saved!");
      })
      .catch((error) => {
        console.log(error);
        this.props.notify("Cookie Consent description was not saved!");
      });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  deleteCookie = (id) => {
    this.props
      .removeConsent({
        variables: {
          id,
        },
      })
      .then(() => {
        this.props.notify("Cookie Consent deleted!");
        this.props.refetchConsent();
      })
      .catch((error) => {
        console.log(error);
        this.props.notify("Cookie Consent was not deleted!");
      });
  };

  render() {
    const { sectionVisible, showSection, consents } = this.props;
    const isActive = sectionVisible === "cookies" ? true : false;
    const { audience } = this.state;

    const { legalsite, pages, legalsiteSettings, dontSellMyDataButton } =
      this.props.legalsite;

    return (
      <div
        //   eslint-disable-next-line
        ref="cookies"
        className={`${isActive ? "-is-active" : ""} settings__account`}
        onClick={
          !isActive
            ? () => {
                showSection("cookies");
                this.scrollToSection("cookies");
              }
            : () => showSection("cookies")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <CookiesIcon /> &nbsp; Cookie Consent Management
        </h2>
        <p className={`${isActive ? "-is-active" : ""} settings__text`}>
          {!isActive
            ? "Set up your cookie policies and manage constent settings for your visitors."
            : "Set up your cookie policies and manage constent settings for your visitors. You can set different levels of consent that your users can modify when visiting your website via your LegalSite."}
        </p>

        {isActive && consents ? (
          <div>
            <p className="settings__cookies-title">Target audiences</p>
            <p className="settings__text">
              Select who will see the cookies pop-up and the cookies consent
              management page.
            </p>

            <UncontrolledDropdown className="chart__dropdown w-100">
              <DropdownToggle className="d-flex align-items-center justify-content-between text-left settings__cookies-toggle">
                {audience === "ALL"
                  ? "All website visitors"
                  : audience === "EU_VISITORS"
                  ? "Only for EU visitors - comply to GDPR"
                  : audience === "CALIFORNIAN_VISITORS"
                  ? "Only for Californian visitors - comply to CCPA"
                  : "Only for EU and Californian visitors - comply to GDPR and CCPA"}
                <FaCaret className="chart__caret" size="20" color="#969696" />
              </DropdownToggle>
              <DropdownMenu>
                {audience !== "ALL" ? (
                  <DropdownItem
                    className="settings__cookies-menu-item"
                    onClick={() => this.selectAudience("ALL")}
                  >
                    All website visitors
                  </DropdownItem>
                ) : null}

                {audience !== "EU_VISITORS" ? (
                  <DropdownItem
                    className="settings__cookies-menu-item"
                    onClick={() => this.selectAudience("EU_VISITORS")}
                  >
                    Only for EU visitors - comply to GDPR
                  </DropdownItem>
                ) : null}

                {audience !== "CALIFORNIAN_VISITORS" ? (
                  <DropdownItem
                    className="settings__cookies-menu-item"
                    onClick={() => this.selectAudience("CALIFORNIAN_VISITORS")}
                  >
                    Only for Californian visitors - comply to CCPA
                  </DropdownItem>
                ) : null}

                {audience !== "EU_AND_CALIFORNIAN_VISITORS" ? (
                  <DropdownItem
                    className="settings__cookies-menu-item"
                    onClick={() =>
                      this.selectAudience("EU_AND_CALIFORNIAN_VISITORS")
                    }
                  >
                    Only for EU and Californian visitors - comply to GDPR and
                    CCPA
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>

            <p className="settings__cookies-title">Description</p>
            <p className="settings__text">
              This description will be shown on your LegalSite page.
            </p>

            <Input
              type="textarea"
              name="descr"
              className="settings__cookies-textarea"
              defaultValue={this.state.descr}
              onChange={(e) => this.setState({ descr: e.target.value })}
            />

            <Button
              className="settings__cookie-save btn--primary mb-5"
              onClick={this.saveConsentMessage}
            >
              Save
            </Button>

            <p className="settings__cookies-title">Cookies</p>
            {this.props.consents
              .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
              .map((item, index) => (
                <CookieItem
                  key={index}
                  enabled={item.enabled}
                  id={item.id}
                  dp={item.dataProcessorConsents}
                  title={item.categoryName}
                  manageable={item.manageableByVisitor}
                  smallDescription={item.smallDescription}
                  completeDescription={item.completeDescription}
                  text={item.status}
                  refetchConsent={this.props.refetchConsent}
                  notify={this.props.notify}
                  getCookie={this.getCookie}
                  saveChanges={this.saveChanges}
                  deleteCookie={this.deleteCookie}
                />
              ))}

            <CookieItem
              title="Other"
              isOther={true}
              getCookie={this.getCookie}
              refetchConsent={this.props.refetchConsent}
              notify={this.props.notify}
              deleteCookie={this.deleteCookie}
            />

            <div className="settings__cookies-btns">
              <Button
                className="settings__cookie-save btn--secondary"
                onClick={this.showPreviewModal}
              >
                Preview
              </Button>
            </div>
          </div>
        ) : null}

        <PreviewConsent
          showPreviewModal={this.showPreviewModal}
          isPreviewModalVisible={this.state.isPreviewModalVisible}
          consents={this.props.consents}
          selectedConsents={this.state.selectedItems}
          legalsite={legalsite}
          pages={pages}
          legalsiteSettings={legalsiteSettings}
          descr={this.state.descr}
          dontSellMyDataButton={dontSellMyDataButton}
        />
      </div>
    );
  }
}

export default compose(
  graphql(TOGGLE_CONSENTS, {
    name: "toggleConsents",
  }),
  graphql(LEGALSITE_QUERY, {
    name: "legalsite",
  }),
  graphql(EDIT_CONSENT_MESSAGE, {
    name: "editConsentMsg",
  }),
  graphql(EDIT_AUDIENCE, {
    name: "editAudience",
  }),
  graphql(REMOVE_CONSENT, {
    name: "removeConsent",
  })
)(Cookies);
Cookies.propTypes = {
  consents: PropTypes.array,
  popup: PropTypes.object,
  legalsiteSettings: PropTypes.object,
  toggleConsents: PropTypes.func,
  editAudience: PropTypes.func,
  notify: PropTypes.func,
  editConsentMsg: PropTypes.func,
  removeConsent: PropTypes.func,
  refetchConsent: PropTypes.func,
  sectionVisible: PropTypes.string,
  showSection: PropTypes.func,
  legalsite: PropTypes.object,
  pages: PropTypes.array,
  dontSellMyDataButton: PropTypes.bool,
};
