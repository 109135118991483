import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import axios from "axios";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
} from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import MenuContent from "../../components/MenuContent/MenuContent";
import SyncLoader from "react-spinners/SyncLoader";
import SearchInput from "../../components/SearchInput/SearchInput";
import MdClose from "react-icons/lib/md/close";
import Check from "react-icons/lib/fa/check";
import TrashIcon from "../../components/Icons/TrashIcon";
import FaCaret from "react-icons/lib/fa/angle-down";
import { ToastContainer, toast } from "react-toastify";
import { CLIENTS_QUERY } from "../../graphql/clientsQuery";
import { REMOVE_CLIENT } from "../../graphql/removeClient";
import { EDIT_CLIENT } from "../../graphql/editClient";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import "./clients.scss";
import ClientInfo from "./ClientInfo";
import DeleteClient from "../../components/Modals/DeleteClient";
import ClientsTable from "./ClientsTable";
import PropTypes from "prop-types";

class Clients extends Component {
  static propTypes = {
    clients: PropTypes.object,
    editClient: PropTypes.func,
    removeClient: PropTypes.func,
    location: PropTypes.object,
  };

  state = {
    isRighMenuOpen: false,
    isCheckedAll: false,
    isClientVisible: false,
    selectedClientId: null,
    filteredClients: null,
    selectedClientName: "",
    isFromUsers: false,
    client: null,
    arr: [],
    isDeleteClientModalOpen: false,
    urls: [],
    userName: [],
    filter: "",
    hasSearch: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.isClientView) {
      this.setState({
        isClientVisible: !this.state.isUserVisible,
        client: this.props.location.state.client,
        isFromUsers: true,
      });
    }
  }

  openDeleteClientModal = () => {
    this.setState({
      isDeleteClientModalOpen: !this.state.isDeleteClientModalOpen,
    });
  };

  notify = (text) => toast(text);

  searchUser = (client) => {
    this.props.clients.refetch({ name: client });

    if (client === "") {
      this.setState({
        hasSearch: false,
      });
    } else {
      this.setState({
        hasSearch: true,
      });
    }
  };

  checkItem = (id, downloadUrl, name) => {
    let checkedItems = [];
    let checkedUrls = [];
    let checkedNames = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
        urls: [],
        userName: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);
    checkedUrls.concat(downloadUrl);
    checkedNames.concat(name);

    if (this.state.arr.includes(id) && this.state.urls.includes(downloadUrl)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id && this.state.urls[i] === downloadUrl) {
          this.state.arr.splice(i, 1);
          this.state.urls.splice(i, 1);
          this.state.userName.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
      urls: this.state.urls.concat(downloadUrl),
      userName: this.state.userName.concat(name),
    });
  };

  showClient = (id, name) => {
    this.setState({
      isClientVisible: !this.state.isClientVisible,
      selectedClientId: id,
      selectedClientName: name,
      hasSearch: false,
    });

    this.props.clients.refetch({ name: "" });
  };

  editClient = (accountId, companyName, email, country, access) => {
    this.props
      .editClient({
        variables: {
          accountId,
          companyName,
          email,
          country,
          access,
        },
      })
      .then(() => {
        this.showClient();
        this.notify("Client info updated!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Client info couldn't be updated");
      });
  };

  checkAll = (users) => {
    const userId = users.map((item) => item.id);
    const userUrl = users.map((item) => item.downloadUrl);
    const userName = users.map((item) => item.companyName);
    this.setState({
      isCheckedAll: true,
      arr: userId,
      urls: userUrl,
      userName,
    });
  };

  deleteClient = () => {
    this.props
      .removeClient({
        variables: {
          accountIds: this.state.arr,
        },
      })
      .then(() => {
        this.props.clients.refetch();
        this.openDeleteClientModal();

        this.setState({
          arr: [],
        });

        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Clients were removed!"
            : "Client was removed!"
        );
      })
      .catch((error) => {
        console.log(error);
        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Clients were not removed!"
            : "Client was not removed"
        );
      });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: false,
      arr: [],
      urls: [],
      userName: [],
    });
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name ? name : "report"}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  setFilter = (filter) => {
    this.setState(
      {
        filter,
        filteredUsers: null,
      },
      () => {
        this.props.clients.refetch({
          clientsFilter: this.state.filter,
        });
      }
    );
  };

  render() {
    const { isCheckedAll, isClientVisible, hasSearch } = this.state;
    const { allClients, loading, error, searchClient } = this.props.clients;
    const clients = searchClient && hasSearch ? searchClient : allClients;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="d-flex position-relative">
        <SideMenu />

        <div className="dashboard w-100">
          <Navbar
            title="Dashboard"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          <div className="clients container position-relative">
            {isClientVisible ? (
              <div className="clients__close" onClick={() => this.showClient()}>
                Exit &nbsp;&nbsp;
                <MdClose size={20} />
              </div>
            ) : null}
            <h5 className="clients__header">
              {isClientVisible
                ? `Client: ${
                    this.state.selectedClientName
                      ? this.state.selectedClientName
                      : ""
                  }`
                : "Clients"}
            </h5>
            {!isClientVisible ? (
              <p className="clients__text">
                Here you can manage and see your clients.
              </p>
            ) : null}
            {!isClientVisible ? (
              <div className="clients__table">
                <div className="clients__table-content">
                  <div className="users__actions">
                    <div className="d-flex align-items-center">
                      <span
                        className={`check ${
                          isCheckedAll ? "-checked" : "-unchecked"
                        }`}
                        onClick={() =>
                          !isCheckedAll
                            ? this.checkAll(clients)
                            : this.unselectAll()
                        }
                      >
                        <Check color={"#fff"} />
                      </span>
                      <div
                        className={`${
                          this.state.arr && this.state.arr.length
                            ? ""
                            : "-is-disabled"
                        } users__action`}
                        onClick={() => this.openDeleteClientModal()}
                      >
                        <TrashIcon className="mr-2" color={"#7450c8"} /> Delete
                      </div>

                      <div className="users__divider" />

                      <UncontrolledDropdown>
                        <DropdownToggle className="users__action -filter">
                          {this.state.filter
                            ? this.state.filter === "LAST_ADDED"
                              ? "Last added"
                              : "All"
                            : "Filter"}{" "}
                          <FaCaret className="ml-4" color="#333" size="20" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.setFilter("LAST_ADDED")}
                          >
                            Last added
                          </DropdownItem>
                          <DropdownItem onClick={() => this.setFilter("ALL")}>
                            All
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <span className="">
                      <SearchInput
                        placeholder="Type to search for a client..."
                        search={this.searchUser}
                      />
                    </span>
                  </div>

                  <div className="users__table">
                    <ClientsTable
                      isCheckedAll={isCheckedAll}
                      checkItem={this.checkItem}
                      showClient={(id, name) => this.showClient(id, name)}
                      users={clients}
                      userName={this.state.userName}
                      userId={this.state.arr}
                      download={this.download}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <ClientInfo
                client={
                  this.state.isFromUsers
                    ? this.state.client
                    : allClients.filter(
                        (item) => item.id === this.state.selectedClientId
                      )
                }
                editClient={this.editClient}
                showClient={this.showClient}
                refetchClients={() => this.props.clients.refetch()}
                isFromUsers={this.state.isFromUsers}
              />
            )}
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <DeleteClient
          isDeleteClientModalOpen={this.state.isDeleteClientModalOpen}
          openDeleteClientModal={this.openDeleteClientModal}
          deleteClient={this.deleteClient}
          isClients={this.state.arr && this.state.arr.length > 1}
        />
      </div>
    );
  }
}

export default compose(
  graphql(CLIENTS_QUERY, {
    name: "clients",
    options: () => ({
      variables: {
        clientsFilter: "ALL",
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(EDIT_CLIENT, {
    name: "editClient",
  }),
  graphql(REMOVE_CLIENT, {
    name: "removeClient",
  })
)(Clients);
