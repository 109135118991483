import React, { Component } from "react";
import PropTypes from "prop-types";

export default class AnnouncementItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  };

  render() {
    const { title, text, date } = this.props;

    return (
      <div className="announcements__item">
        <div>
          <h4 className="announcements__item-title">{title}</h4>
          <p className="announcements__item-text">{text}</p>
        </div>
        <p className="announcements__item-date">{date}</p>
      </div>
    );
  }
}
