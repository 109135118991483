import gql from "graphql-tag";

export const EMAILS_QUERY = gql`
  query($emailType: EmailEnum!) {
    email(type: $emailType) {
      backgroundColor
      bodyColor
      bodyFont
      bodySize
      bannerImage
      buttonColor
      buttonFont
      buttonSize
      buttonTextColor
      footerBackgroundColor
      footerColor
      footerFont
      footerSize
      logo
      titleColor
      signature
      titleFont
      titleSize
      title
      body
      id
      buttonText
      footer
      showLegalsiteBranding
      showLegalsiteBranding
      titleAlign
      titleBold
      titleItalic
      bodyAlign
      bodyBold
      bodyItalic
      buttonBold
      buttonItalic
      footerBold
      footerItalic
    }
    legalsite {
      bodyBtnFont
      bodyBtnSize
    }
    legalsiteSettings {
      companyLogo
      id
    }
    account {
      id
      needToUpgrade
    }
  }
`;
