import gql from "graphql-tag";

export const COMPLETE_ONBOARDING_AS_DP = gql`
  mutation($clientMutationId: String) {
    completedOnboardingAsDp(input: { clientMutationId: $clientMutationId }) {
      user {
        id
        onboardedAsDp
      }
    }
  }
`;
