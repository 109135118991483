import PropTypes from "prop-types";
import React from "react";

const ToDoIcon = props => (
    <svg
        height={12}
        viewBox='0 0 12 12'
        width={12}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-630.000000, -1237.000000)" stroke="#F86B67" strokeWidth="2">
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-15" transform="translate(0.000000, 226.000000)">
                            <g id="Group-10" transform="translate(449.000000, 3.000000)">
                                <circle id="Colour" cx="6" cy="10" r="5"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ToDoIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default ToDoIcon;