import PropTypes from "prop-types";
import React from "react";

const Learn = props => (
    <svg
        height={26}
        viewBox='0 0 34 26'
        width={34}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-7---Help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Help" transform="translate(-742.000000, -596.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="cards" transform="translate(298.000000, 282.000000)">
                    <g id="help-card" transform="translate(351.000000, 282.000000)">
                        <path d="M126.174166,41.1620603 L124.554883,40.2638191 L109.981336,32.0979899 L109.803215,32.0979899 C109.703939,32.0556563 109.600929,32.0228481 109.495551,32 L109.187888,32 L108.896417,32 C108.785785,32.0228631 108.677401,32.055657 108.57256,32.0979899 L108.394439,32.0979899 L93.8208927,40.2638191 C93.3135544,40.553806 93,41.0965263 93,41.6846734 C93,42.2728204 93.3135544,42.8155407 93.8208927,43.1055276 L97.8529072,45.3592965 L97.8529072,53.1005025 C97.8529072,55.8064203 100.02784,58 102.710756,58 L115.665019,58 C118.347935,58 120.522868,55.8064203 120.522868,53.1005025 L120.522868,45.3592965 L123.761434,43.5301508 L123.761434,48.201005 C123.761434,49.1029776 124.486412,49.8341709 125.380717,49.8341709 C126.275022,49.8341709 127,49.1029776 127,48.201005 L127,42.5829146 C126.998993,41.993727 126.683409,41.4507618 126.174166,41.1620603 Z M117,53.2721382 C117,54.2264099 116.283656,55 115.4,55 L102.6,55 C101.716344,55 101,54.2264099 101,53.2721382 L101,47 L108.216,51.3196544 L108.456,51.4233261 L108.6,51.4233261 C108.732808,51.4413956 108.867192,51.4413956 109,51.4233261 C109.132808,51.4413956 109.267192,51.4413956 109.4,51.4233261 L109.544,51.4233261 C109.628941,51.4039873 109.710141,51.368912 109.784,51.3196544 L117,47 L117,53.2721382 Z M109,48 L98,41.5 L109,35 L120,41.5 L109,48 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Learn.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Learn;