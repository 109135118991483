import gql from "graphql-tag";

export const PAGES_PAGE_QUERY = gql`
  {
    consents {
      id
      enabled
    }
    dontSellMyDataButton {
      buttonType
      color
      id
      snippet
      targetAudience
      text
      textColor
      textFont
      textSize
    }
    pages {
      applicable
      description
      id
      pageTemplate {
        description
        id
        sections {
          applicable
          id
          text
          help
          title
          position
          readOnly
        }
        title
        updatedAt
      }
      publicationDownloadUrl
      publications {
        id
        createdAt
        description
        sections {
          applicable
          readOnly
          id
          help
          text
          title
          position
        }
        title
      }
      sections {
        id
        applicable
        readOnly
        text
        help
        title
        position
      }
      status
      title
      updatedAt
    }
    dataProtectionOfficer {
      id
      show
    }
    alerts {
      category
      page {
        id
      }
      id
      templatePublication {
        id
        title
        description
        sections {
          id
          applicable
          readOnly
          help
          text
          title
        }
      }
      pageTemplate {
        id
        title
        description
      }
    }
    legalsiteSettings {
      subdomain
      companyLogo
      id
    }
    legalsite {
      bodyBg
      bodyBtnAlign
      bodyBtnBold
      bodyBtnColor
      bodyBtnFont
      bodyBtnBg
      bodyBtnItalic
      bodyBtnSize
      bodyIconsColor
      bodySubtitleAlign
      bodySubtitleBold
      bodySubtitleColor
      bodySubtitleFont
      bodySubtitleItalic
      bodySubtitleSize
      bodyTextAlign
      bodyTextBold
      bodyTextColor
      bodyTextFont
      bodyTextItalic
      bodyTextSize
      bodyTitleAlign
      bodyTitleBold
      bodyTitleColor
      bodyTitleFont
      bodyTitleItalic
      bodyTitleSize
      cardsBg
      cardsBodyAlign
      cardsBodyBold
      cardsBodyColor
      cardsBodyFont
      cardsBodyItalic
      cardsBodySize
      cardsBtnAlign
      cardsBtnBg
      cardsBtnBold
      cardsBtnColor
      cardsBtnFont
      cardsBtnItalic
      cardsBtnSize
      cardsIconsColor
      cardsTitleAlign
      cardsTitleBold
      cardsTitleColor
      cardsTitleFont
      cardsTitleItalic
      cardsTitleSize
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      headerBg
      headerBodyAlign
      headerBodyBold
      headerBodyColor
      headerBodyFont
      headerBodyItalic
      headerBodySize
      headerTitleAlign
      headerTitleBold
      headerTitleColor
      headerTitleFont
      headerTitleItalic
      homepageTitle
      homepageDescription
      headerTitleSize
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    account {
      id
      needToUpgrade
      showLegalInformation
      users {
        id
      }
      pagesSignature
    }
  }
`;
