import gql from "graphql-tag";

export const CREATE_DP = gql`
  mutation(
    $companyName: String!
    $email: String!
    $website: String!
    $clientMutationId: String
  ) {
    createDataProcessor(
      input: {
        companyName: $companyName
        email: $email
        clientMutationId: $clientMutationId
        website: $website
      }
    ) {
      errors
      dataProcessor {
        id
        companyName
      }
    }
  }
`;
