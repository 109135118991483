import gql from "graphql-tag";

export const ADD_CONSENT = gql`
mutation(
  $manageableByUser: Boolean!
  $categoryName: String!
  $smallDescription: String
  $completeDescription: String
  $clientMutationId: String
) {
  addConsent(
    input: {
      clientMutationId: $clientMutationId
      manageableByUser: $manageableByUser
      categoryName: $categoryName
      smallDescription: $smallDescription
      completeDescription: $completeDescription
    }
  ) {
    errors
    consent {
      categoryName
      completeDescription
      enabled
      id
      manageableByVisitor
      smallDescription
      status
    }
  }
}
`;
