import React from "react";
import PropTypes from "prop-types";
import ComplianceMenu from "./ComplianceMenu";
import CompliancePrivacyCategory from "./CompliancePrivacyCategory";
import PlusCircle from "../../components/Icons/PlusCircle";
import CompliancePrivacyEditModal from "../../components/Modals/CompliancePrivacyEditModal";
import AddDataCategory from "../../components/Modals/AddDataCategory";

function PIASurvey(props) {
  const { privacyImpactAssessment, showSurvey, showPreview } = props;

  return (
    <div className="d-flex justify-content-between compliance-privacy__container">
      <div className="w-100 px-4 compliance-privacy__categ">
        <p className="compliance-privacy__text w-100 -select">
          Select the categories of personal data collected and retained, then
          click on each selected category and fill the information about them.
        </p>

        <div className="compliance-privacy__categories mx-auto">
          <h4 className="compliance-privacy__categories-title">Categories</h4>

          {privacyImpactAssessment && privacyImpactAssessment.dataCategories
            ? privacyImpactAssessment.dataCategories
                .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                .map((item) => (
                  <CompliancePrivacyCategory
                    key={item.id}
                    title={item.name}
                    text={item.status}
                    item={item}
                    categories={privacyImpactAssessment.dataCategories}
                    deleteCategory={props.deleteCategory}
                  />
                ))
            : null}

          <div
            className="d-flex align-items-center mb-5 mt-4 compliance-privacy__add"
            onClick={props.showAddModal}
          >
            <PlusCircle />
            <p className="compliance-privacy__add-text">Add other</p>
          </div>
        </div>
      </div>

      <ComplianceMenu
        showSurvey={showSurvey}
        showPreviewCompliance={showPreview}
        privacyImpactAssessment={privacyImpactAssessment}
        downloadBreach={props.downloadBreach}
        refetchData={props.refetchData}
      />

      <CompliancePrivacyEditModal
        title={"Other"}
        checkedItem={null}
        isEditModalVisible={props.isEditModalVisible}
        showEditModal={props.showEditModal}
      />

      <AddDataCategory
        showAddModal={props.showAddModal}
        isAddModalVisible={props.isAddModalVisible}
        refetchData={props.refetchData}
      />
    </div>
  );
}

export default PIASurvey;
PIASurvey.propTypes = {
  privacyImpactAssessment: PropTypes.object.isRequired,
  showSurvey: PropTypes.func.isRequired,
  showPreview: PropTypes.func.isRequired,
  downloadBreach: PropTypes.func.isRequired,
  refetchData: PropTypes.func.isRequired,
  deleteCategory: PropTypes.func.isRequired,
  showAddModal: PropTypes.func.isRequired,
  isEditModalVisible: PropTypes.bool.isRequired,
  showEditModal: PropTypes.func.isRequired,
  isAddModalVisible: PropTypes.bool.isRequired,
};
