import gql from "graphql-tag";

export const GENERATE_REPORT = gql`
  mutation($id: ID!, $clientMutationId: String, $reportType: ReportEnum!) {
    generateReport(
      input: {
        id: $id
        clientMutationId: $clientMutationId
        reportType: $reportType
      }
    ) {
      errors
      downloadUrl
    }
  }
`;
