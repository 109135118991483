import gql from "graphql-tag";

export const CUSTOMIZE_EMAILS = gql`
  mutation(
    $id: ID!
    $titleColor: String
    $titleSize: Int
    $titleFont: String
    $bodyColor: String
    $bodySize: Int
    $bodyFont: String
    $logo: String
    $backgroundColor: String
    $footerBackgroundColor: String
    $buttonColor: String
    $buttonTextColor: String
    $buttonSize: Int
    $buttonFont: String
    $footerColor: String
    $footerSize: Int
    $footerFont: String
    $title: String
    $body: String
    $titleAlign: String
    $titleBold: Boolean
    $titleItalic: Boolean
    $bodyBold: Boolean
    $bodyItalic: Boolean
    $bodyAlign: String
    $footer: String
    $buttonBold: Boolean
    $buttonItalic: Boolean
    $buttonText: String
    $footerBold: Boolean
    $footerItalic: Boolean
    $clientMutationId: String
    $signature: String
    $bannerImage: String
  ) {
    configureEmail(
      input: {
        id: $id
        titleColor: $titleColor
        footerBold: $footerBold
        footerItalic: $footerItalic
        titleSize: $titleSize
        titleFont: $titleFont
        bodyColor: $bodyColor
        titleAlign: $titleAlign
        titleBold: $titleBold
        titleItalic: $titleItalic
        buttonBold: $buttonBold
        buttonItalic: $buttonItalic
        bodyAlign: $bodyAlign
        bodyBold: $bodyBold
        bodyItalic: $bodyItalic
        bodySize: $bodySize
        bodyFont: $bodyFont
        logo: $logo
        backgroundColor: $backgroundColor
        footerBackgroundColor: $footerBackgroundColor
        buttonColor: $buttonColor
        buttonTextColor: $buttonTextColor
        buttonSize: $buttonSize
        buttonFont: $buttonFont
        footerColor: $footerColor
        footerSize: $footerSize
        footerFont: $footerFont
        title: $title
        body: $body
        footer: $footer
        buttonText: $buttonText
        clientMutationId: $clientMutationId
        signature: $signature
        bannerImage: $bannerImage
      }
    ) {
      errors
      email {
        id
      }
    }
  }
`;
