import PropTypes from "prop-types";
import React from "react";

const SortIcon = props => (
    <svg
        height={12}
        viewBox='0 0 16 12'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-10--Integrations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Integrations-empty-page" transform="translate(-149.000000, -155.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Filter-sidebar" transform="translate(76.000000, 98.000000)">
                    <g id="Group-4" transform="translate(73.000000, 49.000000)">
                        <g id="align-center-alt" transform="translate(0.000000, 8.000000)">
                            <path d="M1,2 L15,2 C15.5522847,2 16,1.55228475 16,1 C16,0.44771525 15.5522847,0 15,0 L1,0 C0.44771525,0 0,0.44771525 0,1 C0,1.55228475 0.44771525,2 1,2 Z M14.3,5 L1.7,5 C1.31340068,5 1,5.44771525 1,6 C1,6.55228475 1.31340068,7 1.7,7 L14.3,7 C14.6865993,7 15,6.55228475 15,6 C15,5.44771525 14.6865993,5 14.3,5 Z M11.5625,10 L5.4375,10 C5.19587542,10 5,10.4477153 5,11 C5,11.5522847 5.19587542,12 5.4375,12 L11.5625,12 C11.8041246,12 12,11.5522847 12,11 C12,10.4477153 11.8041246,10 11.5625,10 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

SortIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default SortIcon;