import gql from "graphql-tag";

export const EDIT_DATA_ENTITY = gql`
  mutation(
    $entityType: EntityTypeEnum!
    $id: ID!
    $name: String!
    $email: String!
    $phoneNumber: String
    $avatar: String
    $company: String
    $internalId: String
    $ipAddress: String
    $keyValue: String
    $userAgent: String
    $clientMutationId: String
  ) {
    editDataEntity(
      input: {
        entityType: $entityType
        id: $id
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        avatar: $avatar
        keyValue: $keyValue
        ipAddress: $ipAddress
        company: $company
        userAgent: $userAgent
        internalId: $internalId
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
