import React from "react";
import MdArrow from "react-icons/lib/ti/arrow-right";
import PropTypes from "prop-types";

function AgreementItem(props) {
  const { page } = props;

  return (
    <div
      className="agreement-item"
      onClick={() => {
        props.showNextSection();
        props.getSelectedPage(
          page ? page : "all",
          page ? page.title : "All pages in one pop-up"
        );
      }}
    >
      <div className="d-flex flex-column">
        <p className="agreement-item__title">
          {page ? page.title : "All pages in one pop-up"}
        </p>
        <p className="agreement-item__text">
          Customize the consent Pop-up and copy snippet.
        </p>
      </div>

      <MdArrow color="#7450c8" size={20} />
    </div>
  );
}

export default AgreementItem;
AgreementItem.propTypes = {
  page: PropTypes.object,
  showNextSection: PropTypes.func.isRequired,
  getSelectedPage: PropTypes.func.isRequired,
};
