import gql from "graphql-tag";

export const EDIT_HELP = gql`
  mutation($sectionId: ID!, $help: String!, $clientMutationId: String) {
    editHelp(
      input: {
        sectionId: $sectionId
        help: $help
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
