import React, { Component, Fragment } from "react";
import Site from "../../components/Icons/Site";
import {
  Form,
  Button,
  Input,
  FormGroup,
  FormFeedback,
  Label,
} from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { EDIT_SUBDOMAIN } from "../../graphql/editSubdomain";
import { CREATE_CUSTOM_DOMAIN } from "../../graphql/createCustomDomain";
import { ToastContainer, toast } from "react-toastify";
import Upgrade from "../../components/Modals/Upgrade";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import ExternalLink from "../../components/Icons/ExternalLink";
import PropTypes from "prop-types";

class LegalSiteSettings extends Component {
  static propTypes = {
    legalsiteSettings: PropTypes.object.isRequired,
    editSubdomain: PropTypes.func.isRequired,
    createCustomDomain: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    sectionVisible: PropTypes.string,
    showSection: PropTypes.func.isRequired,
    getSample: PropTypes.func.isRequired,
  };
  state = {
    formErrors: {},
    isUpgradeVisible: false,
    type: null,
    legalsiteDomain: null,
    customDomain: null,
    hasError: null,
  };

  notify = (text) => toast(text);

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  showType = (type) => {
    this.setState({
      type,
    });
  };

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(`${text}.legalsites.app`);
    this.notify("Copied to clipboard!");
  };

  copy = (text) => {
    navigator.clipboard.writeText(text);
    this.notify("Copied to clipboard!");
  };

  updateDomain = (text) => {
    this.setState({
      legalsiteDomain: text,
    });
  };

  editSubdomain = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const subdomain = formData.get("subdomain");

    let hasFormErrors = false;
    let formErrors = {};

    if (!subdomain) {
      hasFormErrors = true;
      formErrors.subdomain = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editSubdomain({
        variables: {
          subdomain,
        },
      })
      .then(() => {
        this.props.viewer.refetch();
        this.notify("Your LegalSite subdomain was updated!");
      })
      .catch((error) => {
        console.log(error);
        this.notify(error.graphQLErrors[0].message);
      });
  };

  editCustomDomain = (e) => {
    e.preventDefault();
    this.setState({
      hasError: null,
    });

    const formData = new FormData(e.target);
    const customDomain = formData.get("customDomain");

    let hasFormErrors = false;
    let formErrors = {};

    if (!customDomain) {
      hasFormErrors = true;
      formErrors.customDomain = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createCustomDomain({
        variables: {
          customDomain,
        },
      })
      .then(() => {
        this.setState({
          hasError: null,
        });
        this.notify("Your Website domain was updated!");
        this.props.viewer.refetch();
      })
      .catch((error) => {
        console.log(error.graphQLErrors);

        this.setState({
          hasError: error.graphQLErrors[0].message,
        });
        this.notify("Your Website domain was not updated!");
      });
  };

  showLegalSite = () => {
    window.open(
      `https://${this.props.legalsiteSettings.subdomain}.legalsites.app`,
      "_blank"
    );
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  render() {
    const { sectionVisible, showSection } = this.props;
    const isActive = sectionVisible === "legal" ? true : false;
    const { legalsiteSettings } = this.props;

    return (
      <Fragment>
        <div
          className={`${isActive ? "-is-active" : ""} settings__account`}
          // eslint-disable-next-line react/no-string-refs
          ref="legal"
          onClick={
            !isActive
              ? () => {
                  showSection("legal");
                  this.scrollToSection("legal");
                }
              : () => showSection("legal")
          }
        >
          <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
            <Site color="#7450C8" />
            &nbsp; LegalSite Settings
          </h2>

          <p className="settings__text">
            Customize your LegalSite URL and set up your custom domain.
          </p>

          {isActive ? (
            <div className="settings__legal">
              <p className="settings__legal-title mb-4">LegalSite URL</p>

              <p className="settings__text">
                Customise a subdomain for your LegalSite. For instance, use your
                company name. This is the url where your LegalSite will be
                published.
              </p>

              <Form onSubmit={this.editSubdomain}>
                <div className="d-flex flex-wrap">
                  <FormGroup className="mt-4 mr-md-4">
                    <Label className="settings__label">
                      Your LegalSite subdomain:
                    </Label>
                    <div className="d-flex align-items-center">
                      <div className="mr-2">
                        <Input
                          className="settings__disabled-input"
                          value={
                            this.state.legalsiteDomain !== null
                              ? this.state.legalsiteDomain.toLowerCase()
                              : legalsiteSettings.subdomain.toLowerCase()
                          }
                          name="subdomain"
                          onChange={(e) => this.updateDomain(e.target.value)}
                          type="text"
                          invalid={this.state.formErrors.subdomain}
                        />

                        <FormFeedback>
                          Please provide your preferred LegalSite subdomain
                        </FormFeedback>
                      </div>
                      <span className="settings__input-label">
                        .legalsites.app
                      </span>
                    </div>
                  </FormGroup>
                </div>

                <Button
                  className="btn--primary settings__copy mr-3"
                  type="submit"
                >
                  Save
                </Button>

                <Button
                  onClick={this.showLegalSite}
                  className="btn--secondary settings__copy"
                >
                  <ExternalLink />
                  &nbsp; Visit URL
                </Button>
              </Form>

              <p className="settings__legal-title mb-2 mt-5 mb-4">
                Custom LegalSite Domain
              </p>

              <p className="settings__text">
                Set up a custom domain for your LegalSite here. The DNS settings
                to enter at your domain hoster will be generated for you. Note:
                we do not support root domains, and advise using a subdomain
                like 'legal', so that your site is published on a URL like
                https://legal.legalsite.co. That way you can link to it from
                your main website/app where needed.
              </p>

              <Form onSubmit={this.editCustomDomain} className="mb-4">
                <FormGroup className="mt-4">
                  <Label className="settings__label">Custom Domain:</Label>

                  <Input
                    onBlur={() => {}}
                    className="settings__disabled-input -domain mr-2"
                    value={
                      this.state.customDomain !== null
                        ? this.state.customDomain.toLowerCase()
                        : legalsiteSettings && legalsiteSettings.customDomain
                        ? legalsiteSettings.customDomain.toLowerCase()
                        : ""
                    }
                    onChange={(e) =>
                      this.setState({ customDomain: e.target.value })
                    }
                    name="customDomain"
                    placeholder="Enter domain"
                    type="text"
                    invalid={
                      this.state.formErrors.customDomain ||
                      this.state.hasError !== null
                    }
                  />
                  <FormFeedback>
                    {this.state.hasError
                      ? "Domain or domain name: full name used to access an app (in words, not with an IP address). For example, www.yourcustomdomain.com"
                      : "Please provide your Website domain"}
                  </FormFeedback>
                </FormGroup>

                <Button
                  className="btn--primary settings__copy mr-3"
                  type="submit"
                >
                  Save
                </Button>
              </Form>

              <p className="settings__text mt-5">
                Copy the CNAM record destination below and add it to your DNS
                settings at your domain hoster. It might take a few hours for
                the domain to propagate and start showing your LegalSite on your
                custom domain.
              </p>

              <div className="d-flex settings__group-form">
                <FormGroup className="mt-4">
                  <Label className="settings__label">Type:</Label>
                  <div className="d-flex align-items-center">
                    <Input
                      onBlur={() => {}}
                      className="settings__disabled-input -domain mr-2"
                      value={"CNAME"}
                      readOnly={true}
                      type="text"
                    />
                  </div>
                </FormGroup>

                <div className="">
                  <FormGroup className="mt-4">
                    <Label className="settings__label">Host (name):</Label>
                    <div className="d-flex align-items-center">
                      <Input
                        onBlur={() => {}}
                        className="settings__disabled-input -domain mr-2"
                        value={
                          legalsiteSettings.customDomain
                            ? legalsiteSettings.customDomain.toLowerCase()
                            : ""
                        }
                        readOnly={true}
                        type="text"
                      />
                    </div>
                  </FormGroup>

                  <Button
                    className="settings__copy btn--secondary"
                    disabled={legalsiteSettings.customDomain ? false : true}
                    onClick={() =>
                      this.copy(
                        legalsiteSettings.customDomain
                          ? legalsiteSettings.customDomain.toLowerCase()
                          : ""
                      )
                    }
                  >
                    Copy to clipboard
                  </Button>
                </div>

                <div className="">
                  <FormGroup className="mt-4">
                    <Label className="settings__label">Value:</Label>
                    <div className="d-flex align-items-center">
                      <Input
                        onBlur={() => {}}
                        className="settings__disabled-input -domain mr-2"
                        value={
                          legalsiteSettings.dnsTarget
                            ? legalsiteSettings.dnsTarget.toLowerCase()
                            : ""
                        }
                        readOnly={true}
                        type="text"
                      />
                    </div>
                  </FormGroup>

                  <Button
                    className="settings__copy btn--secondary"
                    disabled={legalsiteSettings.dnsTarget ? false : true}
                    onClick={() =>
                      this.copy(
                        legalsiteSettings.dnsTarget
                          ? legalsiteSettings.dnsTarget.toLowerCase()
                          : ""
                      )
                    }
                  >
                    Copy to clipboard
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <Upgrade
          showUpgrade={this.showUpgrade}
          isCustomization={true}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type={this.state.type}
          getSample={this.props.getSample}
        />
      </Fragment>
    );
  }
}

export default compose(
  graphql(EDIT_SUBDOMAIN, {
    name: "editSubdomain",
  }),
  graphql(CREATE_CUSTOM_DOMAIN, {
    name: "createCustomDomain",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(LegalSiteSettings);
