import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Form,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import { ADD_DATA_CATEGORY_TO_PIA } from "../../graphql/addDataCategoryToPia";
import { graphql } from "@apollo/client/react/hoc";
import "./modals.scss";

class AddDataCategory extends Component {
  static propTypes = {
    showAddModal: PropTypes.func.isRequired,
    isAddModalVisible: PropTypes.bool.isRequired,
    addDataCategoryToPia: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
  };

  state = {
    formErrors: {},
  };

  handleSubmission = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .addDataCategoryToPia({
        variables: {
          name,
        },
      })
      .then(() => {
        this.props.refetchData();
        this.props.showAddModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isAddModalVisible}
          toggle={this.props.showAddModal}
          className="modal-add-category"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showAddModal}
            />
            <p className="modal-assign__title mt-0">Add data category</p>
            <Form onSubmit={this.handleSubmission}>
              <FormGroup>
                <Input
                  type="text"
                  name="name"
                  placeholder="Category name"
                  invalid={this.state.formErrors.name}
                />
                <FormFeedback>Please add a category name</FormFeedback>
              </FormGroup>

              <div className="modal-add-category__btns">
                <Button
                  className="btn--secondary mr-2"
                  onClick={this.props.showAddModal}
                >
                  Cancel
                </Button>
                <Button className="btn--primary ml-2" type="submit">
                  Save and close
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(ADD_DATA_CATEGORY_TO_PIA, {
  name: "addDataCategoryToPia",
})(AddDataCategory);
