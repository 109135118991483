import React, { Component, Fragment } from "react";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Table,
  Alert,
  FormFeedback,
} from "reactstrap";
import UsersIcon from "../../components/Icons/UsersIcon";
import FaCaret from "react-icons/lib/fa/angle-down";
import TrashIcon from "../../components/Icons/TrashIcon";
import Edit from "react-icons/lib/ti/edit";
import AddUserModal from "../../components/Modals/AddUserModal";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { EDIT_ACCOUNT } from "../../graphql/editAccount";
import { REMOVE_ACCOUNT } from "../../graphql/removeAccount";
import { REMOVE_USER } from "../../graphql/removeUser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import timezones from "../../config/timezone";
import countries from "../../config/countries";
import Upgrade from "../../components/Modals/Upgrade";
import DeleteAccount from "../../components/Modals/DeleteAccount";
import { RESEND_CONFIRMATION_EMAIL } from "../../graphql/resendConfirmationMail";
import PropTypes from "prop-types";

class AccountSettings extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string,
    showSection: PropTypes.func,
    account: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.object,
    viewer: PropTypes.object,
    editAccount: PropTypes.func,
    removeUser: PropTypes.func,
    removeAccount: PropTypes.func,
    resendEmail: PropTypes.func,
    refetchAccount: PropTypes.func,
    showUser: PropTypes.func,
    editAccountResult: PropTypes.object,
  };
  state = {
    isAddModalVisible: false,
    isEdit: false,
    countries: [],
    country: "",
    timezone: [],
    selectedTimezone: null,
    dateFormat: "",
    companyRegistration: "",
    fullName: "",
    email: "",
    vat: "",
    deleteAccountId: null,
    isDeleteModalVisible: false,
    formErrors: {},
    user: null,
    isUpgradeVisible: false,
    type: "add users",
    isUserView: false,
    phoneNumber: "",
    companyName: "",
    isDeleteModalOpen: false,
  };

  componentDidMount() {
    this.showData();
  }

  showData = async () => {
    try {
      this.setState({
        countries: countries,
        timezone: timezones,
      });
    } catch (err) {
      console.log(err);
    }
  };

  openDeleteAccountModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  };

  showUser = (id) => {
    this.props.showUser(id);
  };

  showAddModal = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
    });
  };

  notify = (text) => toast(text);

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  removeUser = () => {
    this.props
      .removeUser({
        variables: {
          ids: this.state.deleteAccountId,
        },
      })
      .then(() => {
        this.notify("User removed");
      })
      .catch((error) => {
        console.log(error);
        this.notify("User was not removed");
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const selectedTimezone = formData.get("selectedTimezone"),
      date = formData.get("date");

    let hasFormErrors = false;
    let formErrors = {};

    if (!selectedTimezone) {
      hasFormErrors = true;
      formErrors.selectedTimezone = true;
    }

    if (!date) {
      hasFormErrors = true;
      formErrors.date = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editAccount({
        variables: {
          dateFormat: date,
          timezone: selectedTimezone,
        },
      })
      .then(() => {
        this.notify("Changes saved!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes were not saved!");
      });
  };

  selectCountry = (e) => {
    this.setState({
      country: e.target.value,
    });
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  deleteAccount = () => {
    this.props
      .removeAccount()
      .then(() => {
        this.openDeleteAccountModal();
        window.location.href = "/signin";
      })
      .catch((error) => {
        console.log(error);
        this.notify("Account couldn't be deleted");
      });
  };

  resendVerification = (email) => {
    this.props
      .resendEmail({
        variables: {
          email,
        },
      })
      .then(() => {
        this.notify("Verification e-mail sent! Check your e-mail");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { sectionVisible, showSection } = this.props;
    const { isAddModalVisible, isEdit, timezone, selectedTimezone } =
      this.state;
    const isActive = sectionVisible === "account" ? true : false;
    const { account, loading, error, viewer } = this.props;

    if (isActive) {
      if (loading)
        return (
          <SyncLoader
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              top: 30px;
              position: relative;
            `}
            sizeUnit={"px"}
            size={10}
            color={"#7450c8"}
            loading={true}
          />
        );

      if (error)
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
    }

    return (
      <div
        className={`${isActive ? "-is-active" : ""} settings__account`}
        // eslint-disable-next-line
        ref="account"
        onClick={
          !isActive
            ? () => {
                showSection("account");
                this.scrollToSection("account");
              }
            : () => showSection("account")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <UsersIcon color={"#7450C8"} />
          &nbsp; Account Settings
        </h2>
        <p className="settings__text">Manage your main account information.</p>

        {isActive ? (
          <Fragment>
            <Form onSubmit={this.handleSubmit} className="settings__user-form">
              <div className="d-flex">
                <div>
                  <div className="d-flex flex-wrap">
                    <FormGroup className="position-relative mr-4 mb-4">
                      <Input
                        type="select"
                        className="mb-0"
                        onChange={(e) =>
                          this.setState({ selectedTimezone: e.target.value })
                        }
                        name="selectedTimezone"
                        invalid={this.state.formErrors.selectedTimezone}
                        value={
                          selectedTimezone
                            ? selectedTimezone
                            : account && account.timezone
                            ? account.timezone
                            : "time-default"
                        }
                      >
                        <option value="time-default" disabled>
                          {account && account.timezone
                            ? account.timezone
                            : "Timezone"}
                        </option>
                        {timezone.map((item, index) => (
                          <option value={item} key={index}>
                            {item}
                          </option>
                        ))}
                      </Input>
                      <FaCaret className="position-absolute settings__input-icon" />
                      <FormFeedback>Please provide your timezone</FormFeedback>
                    </FormGroup>
                  </div>

                  <div className="d-flex flex-wrap">
                    <FormGroup className="position-relative mb-4">
                      <Input
                        type="select"
                        name="date"
                        className="mb-0"
                        invalid={this.state.formErrors.date}
                        onChange={(e) =>
                          this.setState({ dateFormat: e.target.value })
                        }
                        defaultValue={
                          account && account.dateFormat
                            ? account.dateFormat
                            : "date-format"
                        }
                      >
                        <option value="date-format" disabled>
                          Date format
                        </option>
                        <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                        <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                        <option value="DD/MM/YY">DD/MM/YY</option>
                      </Input>
                      <FaCaret className="position-absolute settings__input-icon" />
                      <FormFeedback>
                        Please provide your preferred date format
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                className="settings__user-save btn--primary"
              >
                {this.props.editAccountResult.loading
                  ? "Saving"
                  : "Save changes"}
              </Button>
            </Form>

            {account && account.users ? (
              <div className="settings__user-table">
                <Table>
                  <thead>
                    <tr>
                      <th>Users</th>
                      <th>Job title</th>
                      <th>Access</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {account.users.map((user, index) => (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="settings__user-avatar align-items-center justify-content-center d-flex">
                              {user && user.avatar ? (
                                <img
                                  src={
                                    user
                                      ? user.avatar
                                      : "https://images-na.ssl-images-amazon.com/images/I/61s4T4fkRBL._SL1500_.jpg"
                                  }
                                  alt="avatar"
                                />
                              ) : (
                                <span className="navigationBar__default-avatar">
                                  {user && user.name
                                    ? user.name.charAt(0).toUpperCase()
                                    : ""}
                                </span>
                              )}
                            </div>
                            <div className="d-flex flex-column">
                              <span className="settings__user-name">
                                {user.name}
                              </span>
                              <span className="settings__user-email">
                                {user.email}
                              </span>
                              <span
                                className="settings__user-send"
                                onClick={() =>
                                  this.resendVerification(user.email)
                                }
                              >
                                Resend verification e-mail
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{user.jobTitle}</td>
                        <td>
                          {user.role === "ADMIN" || (viewer && viewer.appAdmin)
                            ? "Admin"
                            : user.role === "MANAGER"
                            ? "Manager"
                            : "Data Manager"}
                        </td>
                        <td>
                          <Edit
                            onClick={() => {
                              this.showAddModal();
                              this.setState({ isEdit: true, user });
                            }}
                            className="mr-4 settings__user-actions"
                            color={"#969696"}
                            size={"23"}
                          />
                          {viewer.role === "ADMIN" ||
                          (viewer && viewer.appAdmin) ? (
                            <TrashIcon
                              color={"#969696"}
                              className="settings__user-delete"
                              onClick={() => {
                                viewer && viewer.id === user.id
                                  ? this.openDeleteAccountModal()
                                  : this.openDeleteModal();
                                this.setState({ deleteAccountId: user.id });
                              }}
                            />
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : null}
            {viewer.appAdmin ? (
              <div>
                <Button
                  className="settings__user-save btn--primary mr-4"
                  onClick={() => {
                    this.showAddModal();
                    this.setState({ isEdit: false });
                  }}
                >
                  Add user
                </Button>
              </div>
            ) : null}

            {!viewer.appAdmin && viewer.role === "ADMIN" ? (
              <Button
                className="settings__user-save btn--primary"
                onClick={
                  account.needToUpgrade
                    ? this.showUpgrade
                    : () => {
                        this.showAddModal();
                        this.setState({ isEdit: false });
                      }
                }
              >
                Add user
              </Button>
            ) : null}

            <div
              className="d-flex settings__delete"
              onClick={this.openDeleteAccountModal}
            >
              <TrashIcon
                color={"#969696"}
                className="settings__user-delete mr-2"
              />
              Delete account
            </div>
          </Fragment>
        ) : null}

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <AddUserModal
          isAddModalVisible={isAddModalVisible}
          showAddModal={this.showAddModal}
          isEdit={isEdit}
          account={this.props.account}
          viewer={this.props.viewer}
          refetchAccount={this.props.refetchAccount}
          notify={this.notify}
          user={this.state.user}
        />

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          removeUser={this.removeUser}
        />

        <Upgrade
          showUpgrade={this.showUpgrade}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type={this.state.type}
        />

        <DeleteAccount
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          openDeleteModal={this.openDeleteAccountModal}
          deleteAccount={this.deleteAccount}
        />
      </div>
    );
  }
}

export default compose(
  graphql(EDIT_ACCOUNT, {
    name: "editAccount",
  }),
  graphql(REMOVE_USER, {
    name: "removeUser",
  }),
  graphql(REMOVE_ACCOUNT, {
    name: "removeAccount",
  }),
  graphql(RESEND_CONFIRMATION_EMAIL, {
    name: "resendEmail",
  })
)(AccountSettings);
