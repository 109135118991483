import React, { Fragment } from "react";
import CustomiseColor from "./CustomiseColor";
import CustomiseText from "./CustomiseText";
import PropTypes from "prop-types";

function CustomizeBody(props) {
  const {
    capitalize,
    changeBgColor,
    changeTextColor,
    getGoogleFont,
    changeFontSize,
    bodyTitleColor,
    bodyTitleFont,
    bodyTitleSize,
    bodySubtitleColor,
    bodySubtitleSize,
    bodySubtitleFont,
    bodyTextColor,
    bodyTextSize,
    bodyTextFont,
    bodyBtnFont,
    bodyBtnSize,
    bodyBtnColor,
  } = props;

  return (
    <Fragment>
      <div>
        <CustomiseText
          title="Title Text"
          color={bodyTitleColor ? bodyTitleColor : "#333333"}
          size={bodyTitleSize ? bodyTitleSize : 28}
          isLegalsite={true}
          font={bodyTitleFont ? capitalize(bodyTitleFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="bodyTitleColor"
          fontSizeName="bodyTitleSize"
          fontFamilyName="bodyTitleFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={61}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="bodyTitleAlign"
          boldName="bodyTitleBold"
          italicName="bodyTitleItalic"
          boldValue={props.bodyTitleBold}
          italicValue={props.bodyTitleItalic}
          alignValue={props.bodyTitleAlign}
        />
      </div>

      <div>
        <CustomiseText
          title="Subtitle Text"
          color={bodySubtitleColor ? bodySubtitleColor : "#7450C8"}
          size={bodySubtitleSize ? bodySubtitleSize : 18}
          isLegalsite={true}
          font={bodySubtitleFont ? capitalize(bodySubtitleFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="bodySubtitleColor"
          fontSizeName="bodySubtitleSize"
          fontFamilyName="bodySubtitleFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={62}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="bodySubtitleAlign"
          boldName="bodySubtitleBold"
          italicName="bodySubtitleItalic"
          boldValue={props.bodySubtitleBold}
          italicValue={props.bodySubtitleItalic}
          alignValue={props.bodySubtitleAlign}
        />
      </div>

      <div>
        <CustomiseText
          title="Body Text"
          color={bodyTextColor ? bodyTextColor : "#969696"}
          size={bodyTextSize ? bodyTextSize : 18}
          isLegalsite={true}
          font={bodyTextFont ? capitalize(bodyTextFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="bodyTextColor"
          fontSizeName="bodyTextSize"
          fontFamilyName="bodyTextFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={63}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="bodyTextAlign"
          boldName="bodyTextBold"
          italicName="bodyTextItalic"
          boldValue={props.bodyTextBold}
          italicValue={props.bodyTextItalic}
          alignValue={props.bodyTextAlign}
        />
      </div>

      <div className="d-flex justify-content-between customize-menu__logo-container pb-4">
        <div>
          <p className="customise-text__label">Background color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="bodyBg"
            color={props.bodyBg ? props.bodyBg : "#f4f6fc"}
            index={64}
          />
        </div>

        <div>
          <p className="customise-text__label">Icons color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="bodyIconsColor"
            color={props.bodyIconsColor ? props.bodyIconsColor : "#7450C8"}
            index={65}
          />
        </div>

        <div>
          <p className="customise-text__label">Button color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="bodyBtnBg"
            color={props.bodyBtnBg ? props.bodyBtnBg : "#7450C8"}
            index={66}
          />
        </div>
      </div>

      <div>
        <CustomiseText
          title="Button Text"
          color={bodyBtnColor ? bodyBtnColor : "#fff"}
          size={bodyBtnSize ? bodyBtnSize : 16}
          isLegalsite={true}
          font={bodyBtnFont ? capitalize(bodyBtnFont) : "Roboto"}
          changeColor={changeTextColor}
          colorName="bodyBtnColor"
          fontSizeName="bodyBtnSize"
          fontFamilyName="bodyBtnFont"
          getGoogleFont={getGoogleFont}
          changeFontSize={changeFontSize}
          index={67}
          getAligned={props.getAligned}
          getBold={props.getBold}
          getItalic={props.getItalic}
          alignName="bodyBtnAlign"
          boldName="bodyBtnBold"
          italicName="bodyBtnItalic"
          boldValue={props.bodyBtnBold}
          italicValue={props.bodyBtnItalic}
          alignValue={props.bodyBtnAlign}
        />
      </div>
    </Fragment>
  );
}

export default CustomizeBody;
CustomizeBody.propTypes = {
  capitalize: PropTypes.func.isRequired,
  changeBgColor: PropTypes.func.isRequired,
  changeTextColor: PropTypes.func.isRequired,
  getGoogleFont: PropTypes.func.isRequired,
  changeFontSize: PropTypes.func.isRequired,
  bodyTitleColor: PropTypes.string,
  bodyTitleFont: PropTypes.string,
  bodyTitleSize: PropTypes.number,
  bodySubtitleColor: PropTypes.string,
  bodySubtitleSize: PropTypes.number,
  bodySubtitleFont: PropTypes.string,
  bodyTextColor: PropTypes.string,
  bodyTextSize: PropTypes.number,
  bodyTextFont: PropTypes.string,
  bodyBtnFont: PropTypes.string,
  bodyBtnSize: PropTypes.number,
  bodyBtnColor: PropTypes.string,
  getAligned: PropTypes.func.isRequired,
  getBold: PropTypes.func.isRequired,
  getItalic: PropTypes.func.isRequired,
  bodyTitleBold: PropTypes.bool,
  bodyTitleItalic: PropTypes.bool,
  bodyTitleAlign: PropTypes.string,
  bodySubtitleBold: PropTypes.bool,
  bodySubtitleItalic: PropTypes.bool,
  bodySubtitleAlign: PropTypes.string,
  bodyTextBold: PropTypes.bool,
  bodyTextItalic: PropTypes.bool,
  bodyTextAlign: PropTypes.string,
  bodyBtnBold: PropTypes.bool,
  bodyBtnItalic: PropTypes.bool,
  bodyBtnAlign: PropTypes.string,
  bodyBg: PropTypes.string,
  bodyIconsColor: PropTypes.string,
  bodyBtnBg: PropTypes.string,
};
