import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MenuContent from "../../components/MenuContent/MenuContent";
import SearchInput from "../../components/SearchInput/SearchInput";
import { ALL_USERS_QUERY } from "../../graphql/usersAdmin";
import axios from "axios";
import { EDIT_USER } from "../../graphql/editUser";
import { EDIT_CLIENT } from "../../graphql/editClient";
import { REMOVE_USER } from "../../graphql/removeUser";
import { ToastContainer, toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import MdClose from "react-icons/lib/md/close";
import Check from "react-icons/lib/fa/check";
import FaCaret from "react-icons/lib/fa/angle-down";
import TrashIcon from "../../components/Icons/TrashIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import "./users-admin.scss";
import UserView from "../Settings/UserView";
import UserTable from "./UserTable";
import PropTypes from "prop-types";

class UsersAdmin extends Component {
  static propTypes = {
    usersAdmin: PropTypes.object,
    editUser: PropTypes.func,
    editClient: PropTypes.func,
    removeUser: PropTypes.func,
    location: PropTypes.object,
  };

  state = {
    isRighMenuOpen: false,
    isCheckedAll: false,
    isUserVisible: false,
    user: null,
    filteredUsers: null,
    isFromClient: false,
    isDeleteModalVisible: false,
    arr: [],
    urls: [],
    userName: [],
    filter: "",
    userReports: [],
    hasSearch: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.isUserView) {
      this.setState({
        isUserVisible: !this.state.isUserVisible,
        user: this.props.location.state.user,
        isFromClient: true,
      });
    }
  }

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  searchUser = (user) => {
    this.props.usersAdmin.refetch({ name: user });

    if (user === "") {
      this.setState({
        hasSearch: false,
      });
    } else {
      this.setState({
        hasSearch: true,
      });
    }
  };

  showUser = (user) => {
    this.setState({
      isUserVisible: !this.state.isUserVisible,
      user,
      hasSearch: false,
    });

    this.props.usersAdmin.refetch({ name: "" });
  };

  notify = (text) => toast(text);

  editUser = (
    fullName,
    email,
    jobTitle,
    role,
    avatar,
    phone,
    id,
    accountId,
    companyName,
    companyEmail,
    country
  ) => {
    this.props
      .editUser({
        variables: {
          fullName,
          email,
          jobTitle,
          role,
          avatar,
          phone,
          id,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });

    this.props
      .editClient({
        variables: {
          accountId,
          companyName,
          email: companyEmail,
          country,
        },
      })
      .then(() => {
        if (this.state.isFromClient) {
          window.location.href = "/clients";
        } else {
          this.showUser();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkItem = (id, downloadUrl, name, report) => {
    let checkedItems = [];
    let checkedUrls = [];
    let checkedNames = [];
    let checkedReports = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
        urls: [],
        userName: [],
        userReports: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);
    checkedUrls.concat(downloadUrl);
    checkedNames.concat(name);
    checkedReports.concat(report);

    if (this.state.arr.includes(id) && this.state.urls.includes(downloadUrl)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id && this.state.urls[i] === downloadUrl) {
          this.state.arr.splice(i, 1);
          this.state.urls.splice(i, 1);
          this.state.userName.splice(i, 1);
          this.state.userReports.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
      urls: this.state.urls.concat(downloadUrl),
      userName: this.state.userName.concat(name),
      userReports: this.state.userReports.concat(report),
    });
  };

  checkAll = (users) => {
    this.setState({
      arr: [],
      urls: [],
      userName: [],
      userReports: [],
      isCheckedAll: !this.state.isCheckedAll,
    });

    this.checkEverything(this.state.userReports, users);
  };

  checkEverything = (reports, users) => {
    const userId = users.map((item) => item.id);
    const userUrl = users.map((item) => item.downloadUrl);
    const userName = users.map((item) => item.name);

    this.setState({
      isCheckedAll: true,
      arr: userId,
      urls: userUrl,
      userName,
      userReports: reports,
    });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: false,
      arr: [],
      urls: [],
      userName: [],
      userReports: [],
    });
  };

  removeUser = () => {
    this.props
      .removeUser({
        variables: {
          ids: this.state.arr,
        },
      })
      .then(() => {
        this.props.usersAdmin.refetch();

        this.setState({
          arr: [],
          urls: [],
          userName: [],
        });

        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were removed!"
            : "User was removed!"
        );
      })
      .catch((error) => {
        console.log(error);
        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were not removed!"
            : "User was not removed"
        );
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  setFilter = (filter) => {
    this.setState(
      {
        filter,
        filteredUsers: null,
      },
      () => {
        this.props.usersAdmin.refetch({
          usersFilter: this.state.filter,
        });
      }
    );
  };

  render() {
    const { isCheckedAll, isUserVisible, user, hasSearch } = this.state;
    const { allUsers, searchUser } = this.props.usersAdmin;

    const users = searchUser && hasSearch ? searchUser : allUsers;

    return (
      <div className="d-flex position-relative">
        <SideMenu />

        <div className="dashboard w-100">
          <Navbar
            title="Dashboard"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          {!isUserVisible ? (
            <div className="users-admin container position-relative">
              <h5 className="users-admin__header">
                {isUserVisible ? "User: Tim Company" : "Users"}
              </h5>
              <p className="users-admin__text">
                Here you can manage and see your users.
              </p>
              <div className="users-admin__table">
                <div className="users-admin__table-content">
                  <div className="users__actions">
                    <div className="d-flex align-items-center">
                      <span
                        className={`check ${
                          isCheckedAll ? "-checked" : "-unchecked"
                        }`}
                        onClick={() =>
                          !isCheckedAll
                            ? this.checkAll(users)
                            : this.unselectAll()
                        }
                      >
                        <Check color={"#fff"} />
                      </span>
                      <div
                        className={`${
                          this.state.arr && this.state.arr.length
                            ? ""
                            : "-is-disabled"
                        } users__action`}
                        onClick={this.openDeleteModal}
                      >
                        <TrashIcon className="mr-2" color={"#7450c8"} /> Delete
                      </div>

                      <div className="users__divider" />

                      <UncontrolledDropdown>
                        <DropdownToggle className="users__action -filter">
                          {this.state.filter
                            ? this.state.filter === "LAST_ADDED"
                              ? "Last added"
                              : this.state.filter === "ADMIN_ACCESS"
                              ? "Admin access"
                              : this.state.filter === "DATA_MANAGER_ACCESS"
                              ? "Data Manager access"
                              : this.state.filter === "MANAGER_ACCESS"
                              ? "Manager access"
                              : "All"
                            : "Filter"}{" "}
                          <FaCaret className="ml-4" color="#333" size="20" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => this.setFilter("LAST_ADDED")}
                          >
                            Last added
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => this.setFilter("ADMIN_ACCESS")}
                          >
                            Admin access
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => this.setFilter("MANAGER_ACCESS")}
                          >
                            Manager access
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              this.setFilter("DATA_MANAGER_ACCESS")
                            }
                          >
                            Data manager access
                          </DropdownItem>
                          <DropdownItem onClick={() => this.setFilter("ALL")}>
                            All
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <span className="">
                      <SearchInput
                        placeholder="Type to search for a user..."
                        search={this.searchUser}
                      />
                    </span>
                  </div>
                  <div className="users__table">
                    <UserTable
                      users={users}
                      isCheckedAll={isCheckedAll}
                      showUser={this.showUser}
                      checkItem={this.checkItem}
                      userName={this.state.userName}
                      userId={this.state.arr}
                      download={this.download}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <UserView
              user={user}
              isUsers={true}
              showUser={this.showUser}
              isFromClient={this.state.isFromClient}
              editUser={this.editUser}
              refetchUsers={() => this.props.usersAdmin.refetch()}
            />
          )}
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          isUsers={this.state.arr && this.state.arr.length > 1}
          removeUser={this.removeUser}
        />
      </div>
    );
  }
}

export default compose(
  graphql(ALL_USERS_QUERY, {
    name: "usersAdmin",
    options: () => ({
      variables: {
        usersFilter: "ALL",
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(EDIT_USER, {
    name: "editUser",
  }),
  graphql(EDIT_CLIENT, {
    name: "editClient",
  }),
  graphql(REMOVE_USER, {
    name: "removeUser",
  })
)(UsersAdmin);
