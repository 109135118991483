import PropTypes from "prop-types";
import React from "react";

const ReadOnly = (props) => (
    <svg
        height={17}
        viewBox="0 0 17 17"
        width={17}
        fill="currentColor"
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style,
        }}
    >
        <g
            id="App-15---Admin-"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="1.5.2--edit-bar"
                transform="translate(-429.000000, -154.000000)"
                fill="#969696"
                fillRule="nonzero"
            >
                <g id="Group-3" transform="translate(404.000000, 140.000000)">
                    <g id="Group-9" transform="translate(24.000000, 13.000000)">
                        <path
                            d="M2.0000331,11.762678 L2.0000331,16.0014492 C2.0000331,16.5535738 2.44761859,17.0011593 2.99974327,17.0011593 L7.23851438,17.0011593 C7.50424033,17.0026956 7.75964447,16.8983756 7.9483086,16.7112434 L14.866303,9.78325192 L17.7054798,7.00405765 C17.8947376,6.81634547 18.0011924,6.56082369 18.0011924,6.29426343 C18.0011924,6.02770318 17.8947376,5.77218139 17.7054798,5.58446921 L13.4667087,1.29571259 C13.2789965,1.10645481 13.0234748,1 12.7569145,1 C12.4903543,1 12.2348325,1.10645481 12.0471203,1.29571259 L9.22793762,4.12489237 L2.28994905,11.0528838 C2.10281679,11.241548 1.99849679,11.4969521 2.0000331,11.762678 Z M12.7569145,3.41509815 L15.5860943,6.24427792 L14.1665058,7.66386636 L11.3373261,4.83468659 L12.7569145,3.41509815 Z M3.99945344,12.1725592 L9.92773473,6.24427792 L12.7569145,9.0734577 L6.82863321,15.001739 L3.99945344,15.001739 L3.99945344,12.1725592 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M-0.650182261,10.3284271 L19.7212501,10.3284271 C20.4470987,10.3284271 21.0355339,9.88070406 21.0355339,9.32842712 C21.0355339,8.77615018 20.4470987,8.32842712 19.7212501,8.32842712 L-0.650182261,8.32842712 C-1.37603091,8.32842712 -1.96446609,8.77615018 -1.96446609,9.32842712 C-1.96446609,9.88075416 -1.37603091,10.3284271 -0.650182261,10.3284271 Z"
                            id="Path"
                            transform="translate(9.535534, 9.328427) rotate(-315.000000) translate(-9.535534, -9.328427) "
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

ReadOnly.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
};

export default ReadOnly;
