import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Alert } from "reactstrap";
import "./pages.scss";
import PagesItem from "./PagesItem";
import PageLeftMenu from "./PageLeftMenu";
import PageRightMenu from "./PageRightMenu";
import PageContent from "./PageContent";
import AddPage from "./AddPage";
import PublishLegalSite from "../../components/Modals/PublishLegalSite";
import PreviewModal from "../../components/Modals/PreviewModal";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import SyncLoader from "react-spinners/SyncLoader";
import { EDIT_PAGE_SECTION } from "../../graphql/editPageSection";
import { ADD_SECTION } from "../../graphql/addSection";
import { Redirect } from "react-router-dom";
import { ADD_PAGE } from "../../graphql/addNewPage";
import { PAGES_PAGE_QUERY } from "../../graphql/pagesQuery";
import { TOGGLE_PAGE } from "../../graphql/togglePage";
import { TOGGLE_SECTION } from "../../graphql/toggleSection";
import { PUBLISH_PAGE } from "../../graphql/publishPage";
import { EDIT_PAGE_TEMPLATE_SECTION } from "../../graphql/editPageTemplateSection";
import { ADD_PAGE_TEMPLATE } from "../../graphql/createPageTemplate";
import { REVIEW_TEMPLATE_CHANGES } from "../../graphql/reviewTemplateChanges";
import { REVERT_PAGE } from "../../graphql/revertPageToPublication";
import SideMenu from "../../components/SideMenu/SideMenu";
import { EDIT_POSITION_SECTION } from "../../graphql/editPositionSection";
import { slide as Menu } from "react-burger-menu";
import { ToastContainer, toast } from "react-toastify";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import axios from "axios";
import ShareIcon from "../../components/Icons/ShareIcon";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import Upgrade from "../../components/Modals/Upgrade";
import { UPDATE_PAGE } from "../../graphql/updatePage";
import { REMOVE_PAGE } from "../../graphql/removePage";
import { REVIEW_CHANGES } from "../../graphql/reviewPageChanges";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import { PUBLISH_ALL_PAGES } from "../../graphql/publishAllPages";
import { REMOVE_SECTION } from "../../graphql/removeSection";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import AddSignature from "../../components/Modals/AddSignature";
import AlertBanner from "./AlertBanner";

class Pages extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    editSection: PropTypes.func.isRequired,
    addSection: PropTypes.func.isRequired,
    publishPage: PropTypes.func.isRequired,
    togglePage: PropTypes.func.isRequired,
    updatePage: PropTypes.func.isRequired,
    addNewPage: PropTypes.func.isRequired,
    toggleSection: PropTypes.func.isRequired,
    reviewChanges: PropTypes.func.isRequired,
    removePage: PropTypes.func.isRequired,
    addNewPageTemplate: PropTypes.func.isRequired,
    publishAllPages: PropTypes.func.isRequired,
    revertPage: PropTypes.func.isRequired,
    reviewTemplateChanges: PropTypes.func.isRequired,
    pagesQuery: PropTypes.shape({
      legalsite: PropTypes.object,
      loading: PropTypes.bool,
      error: PropTypes.object,
      pages: PropTypes.array,
      account: PropTypes.object,
      legalsiteSettings: PropTypes.object,
      alerts: PropTypes.array,
      dataProtectionOfficer: PropTypes.object,
      dontSellMyDataButton: PropTypes.bool,
      refetch: PropTypes.func,
    }).isRequired,
    viewer: PropTypes.object.isRequired,
    editPositionSection: PropTypes.func.isRequired,
    removeSection: PropTypes.func.isRequired,
  };

  state = {
    selectedPage: null,
    item: null,
    itemText: null,
    isAddingPage: false,
    isPreviewModalVisible: false,
    isPreview: false,
    actionNeeded: false,
    menuItems: [],
    isPublishLegalSiteVisible: false,
    isRighMenuOpen: false,
    selectedItem: null,
    resetTitle: false,
    resetText: false,
    activeItem: null,
    type: "add pages",
    isUpgradeVisible: false,
    isNotCurrentVersion: false,
    otherVersionId: null,
    activeItemIndex: 1,
    selectedPageIndex: null,
    activePublicationId: null,
    isAdmin: false,
    isAdminNewPage: false,
    addedPage: false,
    isRedirecting: false,
    isShowSignatureModalVisible: false,
    isDeleting: false,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.isAdmin) {
      if (this.props.location.state.newPage) {
        this.setState({
          isAddingPage: !this.state.isAddingPage,
          isAdminNewPage: true,
        });
      } else {
        this.setState({
          isAdmin: true,
          selectedPage: this.props.location.state.page.title,
          selectedItem: this.props.location.state.page,
          selectedPageIndex: this.props.location.state.index,
        });
      }
    }

    if (this.props.location.state && this.props.location.state.isNotAdmin) {
      this.setState({
        isAdmin: false,
        selectedPage: this.props.location.state.page.title,
        selectedItem: this.props.location.state.page,
        selectedPageIndex: this.props.location.state.index,
      });
    }

    if (this.props.location.state && this.props.location.state.isDPA) {
      this.setState({
        isAdmin: false,
        selectedPage: this.props.location.state.page.title,
        selectedItem: this.props.location.state.page,
        selectedPageIndex: this.props.location.state.index,
      });
    }
  }

  showPage = (page, actionNeeded, selectedItem, index) => {
    this.setState({
      selectedPage: page,
      actionNeeded,
      selectedItem,
      selectedPageIndex: index,
    });
  };

  showSignatureModal = () => {
    this.setState({
      isShowSignatureModalVisible: !this.state.isShowSignatureModalVisible,
    });
  };

  getItem = (item, activeItem, index) => {
    this.setState({
      item,
      activeItem,
      itemText: activeItem.text,
      activeItemIndex: index,
    });

    this.resetTitle(true);
    this.resetText(true);
  };

  getInitialPage = () => {
    this.setState({
      selectedPage: null,
      isNotCurrentVersion: false,
      selectedPageIndex: null,
      activeItemIndex: 1,
      isPreview: false,
      addedPage: false,
    });
  };

  resetTitle = (value) => {
    this.setState({
      resetTitle: value,
    });
  };

  resetText = (value) => {
    this.setState({
      resetText: value,
    });
  };

  updateTitle = (title) => {
    this.setState({
      item: title,
    });
  };

  updateText = (text) => {
    this.setState({
      itemText: text,
    });
  };

  showPages = () => {
    this.setState({
      selectedPage: null,
      isNotCurrentVersion: false,
      activeItemIndex: 1,
      addedPage: false,
    });
  };

  addPage = () => {
    this.setState({
      isAddingPage: !this.state.isAddingPage,
    });
  };

  showPreviewModal = () => {
    this.setState({
      isPreviewModalVisible: !this.state.isPreviewModalVisible,
    });
  };

  showPreview = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });
  };

  showMenuItems = (items) => {
    this.setState({
      menuItems: items,
    });
  };

  showPublishLegalSite = () => {
    this.setState({
      isPublishLegalSiteVisible: !this.state.isPublishLegalSiteVisible,
    });
  };

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  showOtherVersion = (id) => {
    this.setState({
      isNotCurrentVersion: true,
      otherVersionId: id,
      activeItemIndex: 1,
    });
  };

  showCurrentVersion = () => {
    this.setState({
      isNotCurrentVersion: false,
      otherVersionId: null,
      activeItemIndex: 1,
    });
  };

  editSection = (title, text, id) => {
    if (!title || !text) {
      return;
    }

    this.props
      .editSection({
        variables: {
          id,
          title,
          text,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
        this.props.viewer.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getActivePublicationId = (id) => {
    this.setState({
      activePublicationId: id,
    });
  };

  addSection = () => {
    this.props
      .addSection({
        variables: {
          pageId: this.state.selectedItem.id,
          title: "Write a title",
          text: "Customize",
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  publishPage = () => {
    this.props
      .publishPage({
        variables: {
          pageId: this.state.selectedItem.id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
        this.props.viewer.refetch();
        if (
          this.props.viewer &&
          this.props.viewer.viewer &&
          !this.props.viewer.viewer.publishedPage
        ) {
          this.showPublishLegalSite();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  makePageApplicable = (id) => {
    this.props
      .togglePage({
        variables: {
          id,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  submitPageDetails = (title, description, id) => {
    this.props
      .updatePage({
        variables: {
          id,
          title,
          description,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  notify = (text) => toast(text);

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  showShareNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <ShareIcon color="#fff" />
        &nbsp; Link copied to clipboard
      </span>
    );
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.notify(this.showDownloadNotifyText());
  };

  share = (url, page) => {
    if (url && page) {
      navigator.clipboard.writeText(`${url}.legalsites.app/page?page=${page}`);
      this.notify(
        <span className="d-flex align-items-center">
          <ShareIcon color="#fff" />
          &nbsp; Link copied to clipboard
        </span>
      );
    }
  };

  addNewPage = (title, description) => {
    this.props
      .addNewPage({
        variables: {
          title,
          description,
        },
      })
      .then(({ data }) => {
        this.props.pagesQuery.refetch();

        this.setState({
          selectedPage: data.createPage.page.title,
          selectedItem: data.createPage.page,
          addedPage: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  makeSectionApplicable = (id) => {
    this.props
      .toggleSection({
        variables: {
          id,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  approveChange = (approve, id, templatePublicationId) => {
    this.props
      .reviewChanges({
        variables: {
          templatePublicationId,
          alertId: id,
          approve,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
        this.props.viewer.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  removePage = (id) => {
    this.props
      .removePage({
        variables: {
          id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
        this.notify("Page was removed!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Page couldn't be removed!");
      });
  };

  addNewPageTemplate = (title, description) => {
    this.props
      .addNewPageTemplate({
        variables: {
          title,
          description,
        },
      })
      .then(({ data }) => {
        this.setState({
          selectedItem: data.createPageTemplate.pageTemplate,
          isRedirecting: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  publishPages = () => {
    this.props
      .publishAllPages()
      .then(() => {
        this.props.viewer.refetch();
        this.showPublishLegalSite();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useVersion = (id, pageId) => {
    this.props
      .revertPage({
        variables: {
          pageId,
          publicationId: id,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  savePageDetails = (alertId, pageTemplateId, approve) => {
    this.props
      .reviewTemplateChanges({
        variables: {
          alertId,
          pageTemplateId,
          approve,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  seeLegalsite = () => {
    window.open(
      `https://${this.props.pagesQuery.legalsiteSettings.subdomain}.legalsites.app`,
      "_blank"
    );
  };

  dragSection = (item, newIndex) => {
    this.props
      .editPositionSection({
        variables: {
          id: item.id,
          position: newIndex + 1,
        },
      })
      .then(() => {
        this.props.pagesQuery.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  deleteSection = (id) => {
    this.setState({
      isDeleting: true,
    });
    this.props
      .removeSection({
        variables: {
          id,
        },
      })
      .then(() => {
        this.setState({
          activeItemIndex: 1,
        });
        this.props.pagesQuery.refetch();

        setTimeout(() => {
          this.setState({ isDeleting: false });
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      selectedPage,
      item,
      isAddingPage,
      isPreviewModalVisible,
      addedPage,
    } = this.state;

    const {
      legalsite,
      loading,
      error,
      pages,
      account,
      legalsiteSettings,
      alerts,
      dataProtectionOfficer,
      dontSellMyDataButton,
    } = this.props.pagesQuery;

    const mutatePages = pages && [...pages];

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    if (this.state.isRedirecting) {
      return (
        <Redirect
          to={{
            pathname: "/admin-page",
            state: { page: this.state.selectedItem },
          }}
        />
      );
    }

    const PrivacyUpdate = pages
      ? pages.filter((item) => item.title === "Privacy Policy")
      : null;

    const date1 = new Date(
        PrivacyUpdate && PrivacyUpdate.length && PrivacyUpdate[0].updatedAt
          ? PrivacyUpdate[0].updatedAt.toString()
          : ""
      ),
      date2 = new Date(),
      Difference_In_Time = date1.getTime() - date2.getTime(),
      Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

    let showAlert;

    if (Difference_In_Days > 364) {
      showAlert = true;
    }

    return pages ? (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Pages"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          <div
            className={`pages ${
              account && account.needToUpgrade ? "-has-banner" : ""
            } ${isAddingPage ? "-has-scroll" : ""} `}
          >
            {!selectedPage && !isAddingPage ? (
              <div className="pages__container container">
                {showAlert ? (
                  <AlertBanner
                    PrivacyUpdate={PrivacyUpdate}
                    showPage={this.showPage}
                  />
                ) : null}
                <p className="pages__text">
                  Get all your pages ready for your LegalSite page.
                  <br />
                  Tip: You can choose which pages you want to customize and
                  publish.
                </p>

                <div className="pages__contents">
                  <div className="pages__items">
                    {mutatePages
                      .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                      .map((item, i) => (
                        <PagesItem
                          key={i}
                          title={item.title}
                          text={item.status}
                          viewer={this.props.viewer}
                          page={item}
                          index={i}
                          alerts={alerts}
                          update={item.updatedAt}
                          isAdminNewPage={this.state.isAdminNewPage}
                          applicable={item.applicable}
                          showPage={this.showPage}
                          makePageApplicable={this.makePageApplicable}
                          removePage={this.removePage}
                        />
                      ))}
                  </div>

                  <div className="d-flex flex-column">
                    <div className="pages__btns">
                      <Button
                        className="btn--primary pages__btn mb-4"
                        onClick={this.publishPages}
                      >
                        Publish LegalSite
                      </Button>
                      <Button
                        className="btn--secondary pages__btn mb-4"
                        onClick={this.seeLegalsite}
                      >
                        See LegalSite
                      </Button>
                      <Button
                        className="btn--secondary pages__btn mb-4"
                        onClick={this.showPreviewModal}
                      >
                        Preview LegalSite
                      </Button>
                      <Button
                        className="btn--gray pages__btn"
                        onClick={this.addPage}
                      >
                        Add another page
                      </Button>
                    </div>

                    <div className="pages__signature">
                      <p className="pages__signature-title">
                        Company Signature
                      </p>
                      <p className="pages__signature-text">
                        Add your signature so you can auto-sign agreements (like
                        Data Processing Agreements) with your users, by inviting
                        them to the legal pages to sign.
                      </p>
                      {account && account.pagesSignature ? (
                        <img
                          className="pages__signature-img"
                          src={account.pagesSignature}
                          alt="signature"
                        />
                      ) : null}
                      <Button
                        className="btn--primary"
                        onClick={this.showSignatureModal}
                      >
                        {account && account.pagesSignature
                          ? "Change Signature"
                          : "Add Signature"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ) : selectedPage ? (
              <div className="pages__content d-flex justify-content-between">
                <PageLeftMenu
                  contentPage={
                    addedPage
                      ? mutatePages.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )[pages.length - 1]
                      : pages &&
                        mutatePages.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )
                      ? pages[this.state.selectedPageIndex]
                      : null
                  }
                  getItem={this.getItem}
                  deleteSection={this.deleteSection}
                  dragSection={this.dragSection}
                  isNotCurrentVersion={this.state.isNotCurrentVersion}
                  activePublicationId={this.state.activePublicationId}
                  updateTitle={this.updateTitle}
                  alerts={alerts}
                  isPreview={this.state.isPreview}
                  addSection={this.addSection}
                  contentIndex={this.state.activeItemIndex}
                  isDeleting={this.state.isDeleting}
                  showPage={this.showPages}
                />

                <PageContent
                  contentPage={
                    addedPage
                      ? mutatePages.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )[pages.length - 1]
                      : pages &&
                        mutatePages.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )
                      ? pages[this.state.selectedPageIndex]
                      : null
                  }
                  editSection={this.editSection}
                  pages={mutatePages}
                  activePublicationId={this.state.activePublicationId}
                  updateTitle={this.updateTitle}
                  isPreview={this.state.isPreview}
                  selectedPage={selectedPage}
                  updateText={this.updateText}
                  resetTitle={this.resetTitle}
                  legalsite={legalsite}
                  dontSellMyDataButton={dontSellMyDataButton}
                  alerts={alerts}
                  account={account}
                  resetText={this.resetText}
                  showPreview={this.showPreview}
                  shouldTitleReset={this.state.resetTitle}
                  shouldTextReset={this.state.resetText}
                  isNotCurrentVersion={this.state.isNotCurrentVersion}
                  otherVersionId={this.state.otherVersionId}
                  activeItemIndex={this.state.activeItemIndex}
                  activeItem={this.state.activeItem}
                  makeSectionApplicable={this.makeSectionApplicable}
                  legalsiteSettings={legalsiteSettings}
                />

                <PageRightMenu
                  getActivePublicationId={this.getActivePublicationId}
                  useVersion={this.useVersion}
                  contentPage={
                    addedPage
                      ? pages[pages.length - 1]
                      : pages &&
                        mutatePages.sort(
                          (a, b) => parseFloat(a.id) - parseFloat(b.id)
                        )
                      ? pages[this.state.selectedPageIndex]
                      : null
                  }
                  activeItemIndex={this.state.activeItemIndex}
                  activePublicationId={this.state.activePublicationId}
                  showPage={this.showPages}
                  selectedItem={this.state.selectedItem}
                  viewer={this.props.viewer}
                  showPreview={this.showPreview}
                  showOtherVersion={this.showOtherVersion}
                  showCurrentVersion={this.showCurrentVersion}
                  isPreview={this.state.isPreview}
                  publishPage={this.publishPage}
                  accountId={account ? account.id : null}
                  submitPageDetails={this.submitPageDetails}
                  download={this.download}
                  share={this.share}
                  alerts={alerts}
                  isAdmin={this.state.isAdmin}
                  subdomain={legalsiteSettings?.subdomain}
                  approveChange={this.approveChange}
                  isAdminNewPage={this.state.isAdminNewPage}
                  savePageDetails={this.savePageDetails}
                />
              </div>
            ) : (
              <AddPage
                selectedPage={selectedPage}
                getItem={this.getItem}
                updateTitle={this.updateTitle}
                showPages={this.addPage}
                showPreview={this.showPreview}
                isPreview={this.state.isPreview}
                showMenuItems={this.showMenuItems}
                actionNeeded={this.state.actionNeeded}
                menuItems={this.state.menuItems}
                item={item}
                addNewPage={this.addNewPage}
                isAdminNewPage={this.state.isAdminNewPage}
                addNewPageTemplate={this.addNewPageTemplate}
                deleteSection={this.deleteSection}
              />
            )}

            {legalsite && (
              <PreviewModal
                isSiteCustomization={true}
                showPreviewModal={this.showPreviewModal}
                isPreviewModalVisible={isPreviewModalVisible}
                dataProtectionOfficer={dataProtectionOfficer}
                contactBg={
                  legalsite.backgroundColor ? legalsite.backgroundColor : null
                }
                btnBg={
                  legalsite.backgroundButtonsColor
                    ? legalsite.backgroundButtonsColor
                    : null
                }
                welcomeBg={legalsite.background1 ? legalsite.background1 : null}
                bg={legalsite.background2 ? legalsite.background2 : null}
                titleSiteColor={
                  legalsite.titleColor ? legalsite.titleColor : null
                }
                titleSiteSize={legalsite.titleSize ? legalsite.titleSize : null}
                bodySiteColor={legalsite.bodyColor ? legalsite.bodyColor : null}
                bodySiteSize={legalsite.bodySize ? legalsite.bodySize : null}
                subtitleColor={
                  legalsite.subtitleColor ? legalsite.subtitleColor : null
                }
                subtitleSize={
                  legalsite.subtitleSize ? legalsite.subtitleSize : null
                }
                siteBtnColor={
                  legalsite.buttonColor ? legalsite.buttonColor : null
                }
                siteBtnSize={legalsite.buttonSize ? legalsite.buttonSize : null}
                siteTitleFont={legalsite.titleFont ? legalsite.titleFont : null}
                siteBodyFont={legalsite.bodyFont ? legalsite.bodyFont : null}
                siteButtonFont={
                  legalsite.buttonFont ? legalsite.buttonFont : null
                }
                siteSubtitleFont={
                  legalsite.subtitleFont ? legalsite.subtitleFont : null
                }
                isCDO={true}
                legalsiteSettings={legalsiteSettings}
              />
            )}

            <PublishLegalSite
              isPages={true}
              goToPages={this.showPages}
              showPublishLegalSite={this.showPublishLegalSite}
              isPublishLegalSiteVisible={this.state.isPublishLegalSiteVisible}
              accountId={account ? account.id : null}
              subdomain={legalsiteSettings?.subdomain}
            />
          </div>
        </div>

        <AddSignature
          isShowSignatureModalVisible={this.state.isShowSignatureModalVisible}
          showSignatureModal={this.showSignatureModal}
          notify={() => this.notify("Signature added!")}
        />

        <Upgrade
          showUpgrade={this.showUpgrade}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type={this.state.type}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    ) : null;
  }
}

export default compose(
  graphql(PAGES_PAGE_QUERY, {
    name: "pagesQuery",
  }),
  graphql(ADD_SECTION, {
    name: "addSection",
  }),
  graphql(PUBLISH_PAGE, {
    name: "publishPage",
  }),
  graphql(REMOVE_SECTION, {
    name: "removeSection",
  }),
  graphql(PUBLISH_ALL_PAGES, {
    name: "publishAllPages",
  }),
  graphql(REVERT_PAGE, {
    name: "revertPage",
  }),
  graphql(EDIT_PAGE_SECTION, {
    name: "editSection",
  }),
  graphql(EDIT_PAGE_TEMPLATE_SECTION, {
    name: "editPageTemplateSection",
  }),
  graphql(EDIT_POSITION_SECTION, {
    name: "editPositionSection",
  }),
  graphql(ADD_PAGE, {
    name: "addNewPage",
  }),
  graphql(ADD_PAGE_TEMPLATE, {
    name: "addNewPageTemplate",
  }),
  graphql(TOGGLE_PAGE, {
    name: "togglePage",
  }),
  graphql(TOGGLE_SECTION, {
    name: "toggleSection",
  }),
  graphql(REVIEW_CHANGES, {
    name: "reviewChanges",
  }),
  graphql(REVIEW_TEMPLATE_CHANGES, {
    name: "reviewTemplateChanges",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  }),
  graphql(UPDATE_PAGE, {
    name: "updatePage",
  }),
  graphql(REMOVE_PAGE, {
    name: "removePage",
  })
)(Pages);
