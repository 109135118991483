import React from "react";
import PropTypes from "prop-types";

function Filter(props) {
  const { isMobileDevice, showFilter, filterOptions, activeFilter } = props;
  return (
    <div
      className={`${
        isMobileDevice ? (showFilter ? "d-block" : "d-none") : ""
      } data-processors__filter-container`}
    >
      {filterOptions.map((item, index) => (
        <div
          key={index}
          onClick={() =>
            isMobileDevice
              ? props.filterMobile(item)
              : props.filterDesktop(item)
          }
          className={`data-processors__filter-item ${
            item === activeFilter ? "-is-active" : ""
          }`}
        >
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
}

export default Filter;
Filter.propTypes = {
  isMobileDevice: PropTypes.bool.isRequired,
  showFilter: PropTypes.bool,
  filterOptions: PropTypes.array.isRequired,
  activeFilter: PropTypes.string.isRequired,
  filterMobile: PropTypes.func,
  filterDesktop: PropTypes.func,
};
