import gql from "graphql-tag";

export const INBOX_QUERY = gql`
  query($requestsStatus: InboxRequestFilterEnum!, $requestsVerified: Boolean!, $limit: Int) {
    requestPager(status: $requestsStatus, verifiedOnly: $requestsVerified, limit: $limit) {
      totalCount
      inProgressCount
      solvedCount
      actionRequiredCount
      requests {
        assignedTo {
          id
          email
          name
          avatar
        }
        spam
        urgent
        archived
        message
        response
        category
        code
        dueDate
        fileResources {
          category
          id
          url
        }
        id
        receivedDate
        status
        tasks {
          id
          status
          dataProcessor {
            id
            category
            companyName
            processingType
            email
            logo
          }
        }
        dataController {
          avatar
          company
          addedAt
          email
          id
          interactions {
            createdAt
            id
            requestNumber
            activity
            requestId
            request {
              id
            }
          }
          name
        }
        dataSubject {
          avatar
          company
          addedAt
          dpaSigned
          email
          id
          interactions {
            createdAt
            requestId
            id
            request {
              id
            }
            requestNumber
            activity
          }
          name
          verified
        }
      }
    }
    viewer {
      id
    }
    account {
      id
      email
      needToUpgrade
      users {
        id
        name
        avatar
        jobTitle
        email
      }
    }
  }
`;
