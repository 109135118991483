import gql from "graphql-tag";

export const TOGGLE_DATA_CATEGORY = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleDataCategory(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
      dataCategory {
        id
        applicable
      }
    }
  }
`;
