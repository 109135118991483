import gql from "graphql-tag";

export const CREATE_REQUEST_DC = gql`
  mutation(
    $type: UserRequestCategoryEnum!
    $status: CreateRequestStatusEnum!
    $createdAt: String!
    $dataSubjectId: ID!
    $dataControllerId: ID!
    $message: String
    $dataSubjectVerified: Boolean!
    $clientMutationId: String
  ) {
    createRequestDataController(
      input: {
        type: $type
        status: $status
        createdAt: $createdAt
        message: $message
        dataSubjectId: $dataSubjectId
        dataSubjectVerified: $dataSubjectVerified
        clientMutationId: $clientMutationId
        dataControllerId: $dataControllerId
      }
    ) {
      errors
      request {
        id
        status
      }
    }
  }
`;
