import gql from "graphql-tag";

export const EDIT_API_KEY = gql`
  mutation(
    $integrationId: ID
    $clientMutationId: String
    $apiKey: String
    $apiSecret: String
  ) {
    editApiKey(
      input: {
        integrationId: $integrationId
        clientMutationId: $clientMutationId
        apiKey: $apiKey
        apiSecret: $apiSecret
      }
    ) {
      errors
    }
  }
`;
