import gql from "graphql-tag";

export const EDIT_EMAIL_INTERVAL = gql`
  mutation(
    $interval: EmailNotificationsIntervalEnum
    $clientMutationId: String
  ) {
    editEmailNotificationsInterval(
      input: { interval: $interval, clientMutationId: $clientMutationId }
    ) {
      errors
      user {
        id
        emailNotificationsInterval
      }
    }
  }
`;
