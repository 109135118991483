import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Col, Row } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import FontLoader from "../../containers/Settings/FontLoader";
import PreviewHeader from "../PreviewHeader/PreviewHeader";
import "./modals.scss";

export default class DpoModal extends Component {
  static propTypes = {
    isDpoModalVisible: PropTypes.bool.isRequired,
    showDpoModal: PropTypes.func.isRequired,
    dataProtectionOfficer: PropTypes.object,
    name: PropTypes.string,
    email: PropTypes.string,
    description: PropTypes.string,
    avatar: PropTypes.string,
    legalsite: PropTypes.string,
    pages: PropTypes.array,
    account: PropTypes.object,
    dontSellMyDataButton: PropTypes.bool,
    legalsiteSettings: PropTypes.object,
    footerLinkAlign: PropTypes.string,
    footerLinkItalic: PropTypes.bool,
    footerLinkBold: PropTypes.bool,
  };

  state = {};

  render() {
    const {
      dataProtectionOfficer,
      name,
      email,
      description,
      avatar,
      legalsite,
      pages,
      account,
      dontSellMyDataButton,
    } = this.props;

    return (
      <div>
        <Modal
          fade={false}
          isOpen={this.props.isDpoModalVisible}
          toggle={this.props.showDpoModal}
          className="modal-preview"
        >
          <FontLoader
            font1={
              legalsite && legalsite.bodyTextFont
                ? legalsite.bodyTextFont
                : "Roboto"
            }
            font2={
              legalsite && legalsite.bodyBtnFont
                ? legalsite.bodyBtnFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.footerLinksFont
                ? legalsite.footerLinksFont
                : "Roboto"
            }
            font4={
              legalsite && legalsite.footerTextFont
                ? legalsite.footerTextFont
                : "Roboto"
            }
          />

          <FontLoader
            font1={
              legalsite && legalsite.navigationLinksFont
                ? legalsite.navigationLinksFont
                : "Roboto"
            }
            font2={
              legalsite && legalsite.headerTitleFont
                ? legalsite.headerTitleFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.headerBodyFont
                ? legalsite.headerBodyFont
                : "Roboto"
            }
            font4={
              legalsite && legalsite.cardsTitleFont
                ? legalsite.cardsTitleFont
                : "Roboto"
            }
          />

          <FontLoader
            font1={
              legalsite && legalsite.cardsBodyFont
                ? legalsite.cardsBodyFont
                : "Roboto"
            }
            font2={
              legalsite && legalsite.bodyTitleFont
                ? legalsite.bodyTitleFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.cardsBtnFont
                ? legalsite.cardsBtnFont
                : "Roboto"
            }
            font4={
              legalsite && legalsite.bodySubtitleFont
                ? legalsite.bodySubtitleFont
                : "Roboto"
            }
          />

          <FontLoader
            font1={
              legalsite && legalsite.cardsBodyFont
                ? legalsite.cardsBodyFont
                : "Roboto"
            }
            font2={
              legalsite && legalsite.bodyTitleFont
                ? legalsite.bodyTitleFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.cardsBtnFont
                ? legalsite.cardsBtnFont
                : "Roboto"
            }
            font4={
              dontSellMyDataButton && dontSellMyDataButton.textFont
                ? dontSellMyDataButton.textFont
                : "Roboto"
            }
          />
          <ModalBody>
            <div className="modal-preview__header">
              <h3 className="modal-preview__title">Preview Mode</h3>
              <div
                className="modal-preview__close-btn"
                onClick={this.props.showDpoModal}
              >
                <span className="modal-preview__exit mr-2">Exit</span>
                <MdClose
                  className="modal-assign__close"
                  color={"#fff"}
                  size={25}
                />
              </div>
            </div>

            <PreviewHeader />

            <div
              className="modal-preview__container"
              style={{
                background:
                  legalsite && legalsite.bodyBg ? legalsite.bodyBg : "#f4f6fc",
              }}
            >
              <div
                className="modal-preview__banner pt-5"
                style={{
                  background:
                    legalsite && legalsite.headerBg
                      ? legalsite.headerBg
                      : "#dfe4ee",
                }}
              >
                <div className="container">
                  <h1
                    className="modal-preview__banner-title"
                    style={{
                      color:
                        legalsite && legalsite.headerTitleColor
                          ? legalsite.headerTitleColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.headerTitleSize
                          ? legalsite.headerTitleSize
                          : 56,
                      fontFamily:
                        legalsite && legalsite.headerTitleFont
                          ? legalsite.headerTitleFont
                          : null,
                      textAlign:
                        legalsite && legalsite.headerTitleAlign !== null
                          ? legalsite.headerTitleAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.headerTitleBold !== null
                          ? legalsite.headerTitleBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.headerTitleItalic !== null
                          ? legalsite.headerTitleItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Legal Information
                  </h1>
                  <p
                    className="modal-preview__banner-text"
                    style={{
                      color:
                        legalsite && legalsite.headerBodyColor
                          ? legalsite.headerBodyColor
                          : "#969696",
                      fontSize:
                        legalsite && legalsite.headerBodySize
                          ? legalsite.headerBodySize
                          : 16,
                      fontFamily:
                        legalsite && legalsite.headerBodyFont
                          ? legalsite.headerBodyFont
                          : null,
                      textAlign:
                        legalsite && legalsite.headerBodyAlign !== null
                          ? legalsite.headerBodyAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.headerBodyBold !== null
                          ? legalsite.headerBodyBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.headerBodyItalic !== null
                          ? legalsite.headerBodyItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Here you can find our Legal Information.
                  </p>
                </div>
              </div>
              <div className="modal-preview__legal-content">
                {account && account.showLegalInformation ? (
                  <div>
                    <p
                      className="modal-preview__name modal-preview__title mb-5"
                      style={{
                        color:
                          legalsite && legalsite.bodyTitleColor
                            ? legalsite.bodyTitleColor
                            : "#333",
                        fontSize:
                          legalsite && legalsite.bodyTitleSize
                            ? legalsite.bodyTitleSize
                            : 36,
                        fontFamily:
                          legalsite && legalsite.bodyTitleFont
                            ? legalsite.bodyTitleFont
                            : "Bookmania Semibold",
                        textAlign:
                          legalsite && legalsite.bodyTitleAlign !== null
                            ? legalsite.bodyTitleAlign
                            : null,
                        fontWeight:
                          legalsite && legalsite.bodyTitleBold !== null
                            ? legalsite.bodyTitleBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          legalsite && legalsite.bodyTitleItalic !== null
                            ? legalsite.bodyTitleItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      Impressum
                    </p>

                    {account && account.companyName ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Company Name:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyName}
                        </p>
                      </div>
                    ) : null}

                    {account && account.companyAddress ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Company Address:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyAddress}
                        </p>
                      </div>
                    ) : null}

                    {account && account.companyCity ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>City:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyCity}
                        </p>
                      </div>
                    ) : null}

                    {account && account.country ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Country:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.country}
                        </p>
                      </div>
                    ) : null}

                    {account && account.companyZipCode ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>ZIP Code:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyZipCode}
                        </p>
                      </div>
                    ) : null}

                    {account && account.companyWebsite ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Company website:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyWebsite}
                        </p>
                      </div>
                    ) : null}

                    {account && account.phoneNumber ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Company phone number:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.phoneNumber}
                        </p>
                      </div>
                    ) : null}

                    {account && account.vat ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Tax ID:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.vat}
                        </p>
                      </div>
                    ) : null}

                    {account && account.companyRegistration ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Company Registration Number:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.companyRegistration}
                        </p>
                      </div>
                    ) : null}

                    {account && account.fullName ? (
                      <div className="d-flex modal-preview__impressum-info mb-4">
                        <p
                          className="d-flex modal-preview__impressum-title"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Legal representative full name:</strong>
                        </p>
                        <p
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.fullName}
                        </p>
                      </div>
                    ) : null}

                    {account && account.email ? (
                      <div
                        className={`${
                          dataProtectionOfficer ? "mb-4" : "mb-0"
                        } d-flex modal-preview__impressum-info`}
                      >
                        <p
                          className={`${
                            dataProtectionOfficer ? "" : "mb-0"
                          } d-flex modal-preview__impressum-title`}
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Legal representative e-mail:</strong>
                        </p>
                        <p
                          className={`${dataProtectionOfficer ? "" : "mb-0"}`}
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                            textAlign:
                              legalsite && legalsite.bodyTextAlign !== null
                                ? legalsite.bodyTextAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {account.email}
                        </p>
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {account && account.showLegalInformation ? (
                  <div className="modal-preview__divider" />
                ) : null}

                <div className="modal-preview__dpo-section">
                  <p
                    className="modal-preview__name modal-preview__title mb-5 pl-0"
                    style={{
                      color:
                        legalsite && legalsite.bodyTitleColor
                          ? legalsite.bodyTitleColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.bodyTitleSize
                          ? legalsite.bodyTitleSize
                          : 36,
                      fontFamily:
                        legalsite && legalsite.bodyTitleFont
                          ? legalsite.bodyTitleFont
                          : "Bookmania Semibold",
                      textAlign:
                        legalsite && legalsite.bodyTitleAlign !== null
                          ? legalsite.bodyTitleAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.bodyTitleBold !== null
                          ? legalsite.bodyTitleBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.bodyTitleItalic !== null
                          ? legalsite.bodyTitleItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Data Protection Officer (DPO)
                  </p>
                  <Row className="modal-preview__dpo">
                    {avatar ||
                    (dataProtectionOfficer && dataProtectionOfficer.avatar) ? (
                      <Col lg={3}>
                        <div className="modal-preview__dpo-avatar">
                          <img
                            src={
                              avatar
                                ? avatar
                                : dataProtectionOfficer
                                ? dataProtectionOfficer.avatar
                                : null
                            }
                            alt="avatar"
                          />
                        </div>
                      </Col>
                    ) : null}
                    <Col lg={9}>
                      <div>
                        <p
                          className="modal-preview__dpo-name"
                          style={{
                            color:
                              legalsite && legalsite.bodySubtitleColor
                                ? legalsite.bodySubtitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.bodySubtitleSize
                                ? legalsite.bodySubtitleSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodySubtitleFont
                                ? legalsite.bodySubtitleFont
                                : "Roboto",
                            fontWeight:
                              legalsite && legalsite.bodySubtitleBold !== null
                                ? legalsite.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodySubtitleItalic !== null
                                ? legalsite.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {name
                            ? name
                            : dataProtectionOfficer
                            ? dataProtectionOfficer.name
                            : ""}
                        </p>
                        <p
                          className="modal-preview__dpo-description"
                          style={{
                            color:
                              legalsite && legalsite.cardsBodyColor
                                ? legalsite.cardsBodyColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.cardsBodySize
                                ? legalsite.cardsBodySize
                                : null,
                            fontFamily:
                              legalsite && legalsite.cardsBodyFont
                                ? legalsite.cardsBodyFont
                                : null,
                            textAlign:
                              legalsite && legalsite.cardsBodyAlign !== null
                                ? legalsite.cardsBodyAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.cardsBodyBold !== null
                                ? legalsite.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.cardsBodyItalic !== null
                                ? legalsite.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {description
                            ? description
                            : dataProtectionOfficer
                            ? dataProtectionOfficer.description
                            : null}
                        </p>
                      </div>

                      <div>
                        <p
                          className="modal-preview__dpo-name"
                          style={{
                            color:
                              legalsite && legalsite.bodySubtitleColor
                                ? legalsite.bodySubtitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.bodySubtitleSize
                                ? legalsite.bodySubtitleSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodySubtitleFont
                                ? legalsite.bodySubtitleFont
                                : "Roboto",
                            fontWeight:
                              legalsite && legalsite.bodySubtitleBold !== null
                                ? legalsite.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodySubtitleItalic !== null
                                ? legalsite.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Contact
                        </p>
                        <p
                          className="modal-preview__dpo-description"
                          style={{
                            color:
                              legalsite && legalsite.cardsBodyColor
                                ? legalsite.cardsBodyColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.cardsBodySize
                                ? legalsite.cardsBodySize
                                : null,
                            fontFamily:
                              legalsite && legalsite.cardsBodyFont
                                ? legalsite.cardsBodyFont
                                : null,
                            textAlign:
                              legalsite && legalsite.cardsBodyAlign !== null
                                ? legalsite.cardsBodyAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.cardsBodyBold !== null
                                ? legalsite.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.cardsBodyItalic !== null
                                ? legalsite.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          If you want to contact our Data Protection Officer,
                          please send an e-mail to{" "}
                          <a
                            style={{
                              color: legalsite
                                ? legalsite.backgroundButtonsColor
                                : null,
                            }}
                            href={`mailto:${
                              email
                                ? email
                                : dataProtectionOfficer
                                ? dataProtectionOfficer.email
                                : ""
                            }`}
                          >
                            {email
                              ? email
                              : dataProtectionOfficer
                              ? dataProtectionOfficer.email
                              : ""}
                          </a>
                          . You can also get in touch with us and send requests
                          regarding your data on our{" "}
                          <span
                            className="modal-preview__dpo-request"
                            style={{
                              color: legalsite
                                ? legalsite.backgroundButtonsColor
                                : null,
                            }}
                          >
                            Requests
                          </span>{" "}
                          section.{" "}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div
                className="footer"
                style={{
                  background:
                    legalsite && legalsite.footerBg
                      ? legalsite.footerBg
                      : "#dfe4ee",
                }}
              >
                <div
                  className="footer__container"
                  style={{
                    background:
                      legalsite && legalsite.footerBg
                        ? legalsite.footerBg
                        : "#dfe4ee",
                  }}
                >
                  <Row className="w-100">
                    <Col sm={12} md={3} className="d-flex flex-column">
                      <span className="d-flex align-items-center text-decoration-none">
                        {this.props.legalsiteSettings &&
                        this.props.legalsiteSettings.companyLogo &&
                        legalsite &&
                        !legalsite.footerLogo ? (
                          <div className="footer__logo-container">
                            <img
                              src={this.props.legalsiteSettings.companyLogo}
                              alt="logo"
                            />
                          </div>
                        ) : legalsite && legalsite.footerLogo ? (
                          <div className="footer__logo-container">
                            <img src={legalsite.footerLogo} alt="logo" />
                          </div>
                        ) : (
                          <span className="footer__logo" />
                        )}
                      </span>
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Website</strong>
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Homepage
                      </p>
                      <p
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.legalsite.co/"
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Go back to main website
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Data Protection Officer
                      </p>
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Legal Pages</strong>
                      </p>
                      {pages
                        ? pages.map((item) => (
                            <p
                              className="mb-4"
                              style={{
                                color:
                                  legalsite && legalsite.footerLinksColor
                                    ? legalsite.footerLinksColor
                                    : "#333",
                                fontSize:
                                  legalsite && legalsite.footerLinksSize
                                    ? legalsite.footerLinksSize
                                    : null,
                                fontFamily:
                                  legalsite && legalsite.footerLinksFont
                                    ? legalsite.footerLinksFont
                                    : null,
                                textAlign:
                                  legalsite &&
                                  legalsite.footerLinksAlign !== null
                                    ? legalsite.footerLinksAlign
                                    : null,
                                fontWeight:
                                  legalsite &&
                                  legalsite.footerLinksBold !== null
                                    ? legalsite.footerLinksBold === true
                                      ? 900
                                      : 500
                                    : 500,
                                fontStyle:
                                  legalsite &&
                                  legalsite.footerLinksItalic !== null
                                    ? legalsite.footerLinksItalic === true
                                      ? "italic"
                                      : "normal"
                                    : "normal",
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </p>
                          ))
                        : null}
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Manage your information</strong>
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Manage Consent
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Send request
                      </p>
                      <div
                        className={`d-flex ${
                          legalsite && legalsite.footerLinksAlign !== null
                            ? legalsite.footerLinksAlign === "center"
                              ? "justify-content-center"
                              : legalsite.footerLinksAlign === "left"
                              ? "justify-content-start"
                              : "justify-content-end"
                            : null
                        }`}
                      >
                        <p
                          className={`${
                            (dontSellMyDataButton &&
                              dontSellMyDataButton.buttonType ===
                                "contained") ||
                            (dontSellMyDataButton &&
                              !dontSellMyDataButton.buttonType)
                              ? "-is-contained"
                              : ""
                          }`}
                          style={{
                            background:
                              (dontSellMyDataButton &&
                                dontSellMyDataButton.buttonType ===
                                  "contained") ||
                              (dontSellMyDataButton &&
                                !dontSellMyDataButton.buttonType)
                                ? dontSellMyDataButton &&
                                  dontSellMyDataButton.color
                                  ? dontSellMyDataButton.color
                                  : "#7450c8"
                                : null,
                            color:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textColor
                                ? dontSellMyDataButton.textColor
                                : "#fff",
                            fontSize:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textSize
                                ? parseInt(dontSellMyDataButton.textSize)
                                : 14,
                            fontFamily:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textFont
                                ? dontSellMyDataButton.textFont
                                : "Roboto",
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Do Not Sell My Info
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  className="footer-text"
                  style={{
                    background: legalsite ? legalsite.background1 : null,
                  }}
                >
                  <span>
                    © {new Date().getFullYear()} LegalSite BV. All rights
                    reserved. Get your LegalSite!
                  </span>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
