import gql from "graphql-tag";

export const EDIT_INTEGRATION = gql`
  mutation(
    $id: ID!
    $clientMutationId: String
    $companyName: String!
    $contactName: String!
    $email: String!
  ) {
    editIntegration(
      input: {
        id: $id
        companyName: $companyName
        contactName: $contactName
        email: $email
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
