import React, { Component } from "react";
import { Button, Form, Input, FormFeedback, FormGroup } from "reactstrap";
import logo from "../../images/Login/logo purple and white.svg";
import { graphql } from "@apollo/client/react/hoc";
import { AUTH_TOKEN } from "../../constants";
import { RESET_PASSWORD } from "../../graphql/resetPassword";
import PropTypes from "prop-types";
import "./sign-in.scss";

class Reset extends Component {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
  };
  state = {
    isConfirmation: false,
    token: null,
    formErrors: {},
  };

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get("token");

    this.setState({
      token,
    });
  }

  showConfirmation = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);

    const password1 = formData.get("password1"),
      password2 = formData.get("password2");

    let hasFormErrors = false;
    let formErrors = {};

    if (!password1) {
      hasFormErrors = true;
      formErrors.password1 = true;
    }

    if (!password2) {
      hasFormErrors = true;
      formErrors.password2 = true;
    }

    if (password1 !== password2) {
      hasFormErrors = true;
      formErrors.confirm = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .resetPassword({
        variables: {
          password1,
          password2,
          token: this.state.token,
        },
      })
      .then(({ data }) => {
        const token = data.resetPassword.authenticationToken;
        this.setUserToken(token);
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setUserToken = (token) => {
    localStorage.setItem(AUTH_TOKEN, token);
  };

  render() {
    const { isConfirmation } = this.state;

    return (
      <div className="login">
        <div className="login__banner">
          <div className="login__banner-logo">
            <img src={logo} alt="logo" />
          </div>
        </div>

        <div className="login__form">
          {!isConfirmation ? (
            <div className="login__form-container">
              <h2 className="login__form-title">Reset your password</h2>

              <p className="login__form-place">
                Define and confirm a new password for your account.
              </p>

              <Form onSubmit={this.showConfirmation}>
                <FormGroup className="mb-4">
                  <Input
                    type="password"
                    placeholder="New password"
                    className="mb-0"
                    invalid={this.state.formErrors.password1}
                    name="password1"
                  />
                  <FormFeedback>Please provide a new password</FormFeedback>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Input
                    type="password"
                    placeholder="Confirm new password"
                    className="mb-0"
                    invalid={
                      this.state.formErrors.password2 ||
                      this.state.formErrors.confirm
                    }
                    name="password2"
                  />
                  <FormFeedback>
                    {this.state.formErrors.password2
                      ? "Please confirm your new password"
                      : this.state.formErrors.confirm
                      ? "It doesn't match your new password!"
                      : ""}
                  </FormFeedback>
                </FormGroup>

                <div className="d-flex justify-content-end">
                  <Button
                    type="submit"
                    className="btn--primary login__form-log"
                  >
                    Update
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            <div className="login__form-container -check">
              <h2 className="login__form-title">Check your e-mail!</h2>

              <p className="login__form-place">
                Click on the verification link we just sent in your e-mail to
                log in.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default graphql(RESET_PASSWORD, {
  name: "resetPassword",
})(Reset);
