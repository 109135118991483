import gql from "graphql-tag";

export const PARSE_USER_DATA_FILE = gql`
  mutation($clientMutationId: String, $fileName: String!) {
    parseUserDataFile(input: { clientMutationId: $clientMutationId, fileName: $fileName }) {
      errors
      userObjects {
        company
        email
        phoneNumber
        user
      }
    }
  }
`;
