import gql from "graphql-tag";

export const EDIT_NOTES_DATA_ENTITY = gql`
  mutation($id: ID!, $entityType: EntityTypeEnum!, $notes: [String!]!, $clientMutationId: String) {
    editNotesDataEntity(
      input: {
        id: $id
        notes: $notes
        entityType: $entityType
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      dataEntity {
        id
        notes
      }
    }
  }
`;
