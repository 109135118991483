import gql from "graphql-tag";

export const CUSTOMIZE_LEGALSITE = gql`
  mutation(
    $navigationLinksColor: String
    $navigationLinksSize: Int
    $navigationLinksFont: String
    $navigationLinksAlign: String
    $navigationLinksItalic: Boolean
    $navigationLinksBold: Boolean
    $navigationBg: String
    $headerTitleColor: String
    $headerTitleFont: String
    $headerTitleSize: Int
    $headerTitleAlign: String
    $headerTitleItalic: Boolean
    $headerTitleBold: Boolean
    $headerBodyFont: String
    $headerBodySize: Int
    $headerBodyColor: String
    $headerBodyAlign: String
    $headerBodyItalic: Boolean
    $homepageTitle: String
    $homepageDescription: String
    $headerBodyBold: Boolean
    $headerBg: String
    $cardsTitleColor: String
    $cardsTitleSize: Int
    $cardsTitleFont: String
    $cardsTitleAlign: String
    $cardsTitleItalic: Boolean
    $cardsTitleBold: Boolean
    $cardsBodyColor: String
    $cardsBodySize: Int
    $cardsBodyFont: String
    $cardsBodyAlign: String
    $cardsBodyItalic: Boolean
    $cardsBodyBold: Boolean
    $cardsBg: String
    $cardsIconsColor: String
    $cardsBtnBg: String
    $cardsBtnColor: String
    $cardsBtnFont: String
    $cardsBtnSize: Int
    $cardsBtnAlign: String
    $cardsBtnBold: Boolean
    $cardsBtnItalic: Boolean
    $bodyTitleFont: String
    $bodyTitleSize: Int
    $bodyTitleColor: String
    $bodyTitleAlign: String
    $bodyTitleBold: Boolean
    $bodyTitleItalic: Boolean
    $bodySubtitleSize: Int
    $bodySubtitleColor: String
    $bodySubtitleFont: String
    $bodySubtitleAlign: String
    $bodySubtitleBold: Boolean
    $bodySubtitleItalic: Boolean
    $bodyTextSize: Int
    $bodyTextFont: String
    $bodyTextColor: String
    $bodyTextAlign: String
    $bodyTextBold: Boolean
    $bodyTextItalic: Boolean
    $bodyBg: String
    $bodyIconsColor: String
    $bodyBtnColor: String
    $bodyBtnSize: Int
    $bodyBtnFont: String
    $bodyBtnBg: String
    $bodyBtnAlign: String
    $bodyBtnBold: Boolean
    $bodyBtnItalic: Boolean
    $footerLogo: String
    $footerLinksColor: String
    $footerLinksSize: Int
    $footerLinksFont: String
    $footerLinksAlign: String
    $footerLinksBold: Boolean
    $footerLinksItalic: Boolean
    $footerBg: String
    $footerTextColor: String
    $footerTextFont: String
    $footerTextSize: Int
    $footerTextAlign: String
    $footerTextBold: Boolean
    $footerTextItalic: Boolean
    $clientMutationId: String
  ) {
    configureLegalsite(
      input: {
        navigationLinksColor: $navigationLinksColor
        navigationLinksSize: $navigationLinksSize
        navigationLinksFont: $navigationLinksFont
        navigationLinksAlign: $navigationLinksAlign
        navigationLinksItalic: $navigationLinksItalic
        navigationLinksBold: $navigationLinksBold
        navigationBg: $navigationBg
        headerTitleColor: $headerTitleColor
        headerTitleFont: $headerTitleFont
        headerTitleSize: $headerTitleSize
        headerTitleAlign: $headerTitleAlign
        headerTitleItalic: $headerTitleItalic
        headerTitleBold: $headerTitleBold
        headerBodyFont: $headerBodyFont
        headerBodySize: $headerBodySize
        headerBodyColor: $headerBodyColor
        headerBodyAlign: $headerBodyAlign
        headerBodyItalic: $headerBodyItalic
        headerBodyBold: $headerBodyBold
        headerBg: $headerBg
        cardsTitleColor: $cardsTitleColor
        cardsTitleSize: $cardsTitleSize
        cardsTitleFont: $cardsTitleFont
        cardsTitleAlign: $cardsTitleAlign
        cardsTitleItalic: $cardsTitleItalic
        cardsTitleBold: $cardsTitleBold
        cardsBodyColor: $cardsBodyColor
        cardsBodySize: $cardsBodySize
        cardsBodyFont: $cardsBodyFont
        cardsBodyAlign: $cardsBodyAlign
        cardsBodyItalic: $cardsBodyItalic
        cardsBodyBold: $cardsBodyBold
        cardsBg: $cardsBg
        cardsIconsColor: $cardsIconsColor
        homepageTitle: $homepageTitle
        homepageDescription: $homepageDescription
        cardsBtnBg: $cardsBtnBg
        cardsBtnColor: $cardsBtnColor
        cardsBtnFont: $cardsBtnFont
        cardsBtnSize: $cardsBtnSize
        cardsBtnAlign: $cardsBtnAlign
        cardsBtnBold: $cardsBtnBold
        cardsBtnItalic: $cardsBtnItalic
        bodyTitleFont: $bodyTitleFont
        bodyTitleSize: $bodyTitleSize
        bodyTitleColor: $bodyTitleColor
        bodyTitleAlign: $bodyTitleAlign
        bodyTitleBold: $bodyTitleBold
        bodyTitleItalic: $bodyTitleItalic
        bodySubtitleSize: $bodySubtitleSize
        bodySubtitleColor: $bodySubtitleColor
        bodySubtitleFont: $bodySubtitleFont
        bodySubtitleAlign: $bodySubtitleAlign
        bodySubtitleBold: $bodySubtitleBold
        bodySubtitleItalic: $bodySubtitleItalic
        bodyTextSize: $bodyTextSize
        bodyTextFont: $bodyTextFont
        bodyTextColor: $bodyTextColor
        bodyTextAlign: $bodyTextAlign
        bodyTextBold: $bodyTextBold
        bodyTextItalic: $bodyTextItalic
        bodyBg: $bodyBg
        bodyIconsColor: $bodyIconsColor
        bodyBtnColor: $bodyBtnColor
        bodyBtnSize: $bodyBtnSize
        bodyBtnFont: $bodyBtnFont
        bodyBtnBg: $bodyBtnBg
        bodyBtnAlign: $bodyBtnAlign
        bodyBtnBold: $bodyBtnBold
        bodyBtnItalic: $bodyBtnItalic
        footerLogo: $footerLogo
        footerLinksColor: $footerLinksColor
        footerLinksSize: $footerLinksSize
        footerLinksFont: $footerLinksFont
        footerLinksAlign: $footerLinksAlign
        footerLinksBold: $footerLinksBold
        footerLinksItalic: $footerLinksItalic
        footerBg: $footerBg
        footerTextColor: $footerTextColor
        footerTextFont: $footerTextFont
        footerTextSize: $footerTextSize
        footerTextAlign: $footerTextAlign
        footerTextBold: $footerTextBold
        footerTextItalic: $footerTextItalic
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      legalsite {
        bodyBg
        bodyBtnAlign
        bodyBtnBg
        bodyBtnBold
        bodyBtnColor
        bodyBtnFont
        bodyBtnItalic
        bodyBtnSize
        bodyIconsColor
        bodySubtitleAlign
        bodySubtitleBold
        bodySubtitleColor
        bodySubtitleFont
        bodySubtitleItalic
        bodySubtitleSize
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        bodyTitleAlign
        bodyTitleBold
        bodyTitleColor
        bodyTitleFont
        bodyTitleItalic
        bodyTitleSize
        cardsBg
        cardsBodyAlign
        cardsBodyBold
        cardsBodyColor
        cardsBodyFont
        cardsBodyItalic
        cardsBodySize
        cardsBtnAlign
        cardsBtnBg
        cardsBtnBold
        cardsBtnColor
        cardsBtnFont
        cardsBtnItalic
        cardsBtnSize
        cardsIconsColor
        cardsTitleAlign
        cardsTitleBold
        cardsTitleColor
        cardsTitleFont
        cardsTitleItalic
        cardsTitleSize
        footerBg
        footerLinksAlign
        footerLinksBold
        footerLinksColor
        footerLinksFont
        footerLinksItalic
        footerLinksSize
        footerLogo
        footerTextAlign
        footerTextBold
        footerTextColor
        footerTextFont
        footerTextItalic
        footerTextSize
        headerBg
        headerBodyAlign
        headerBodyBold
        headerBodyColor
        headerBodyFont
        headerBodyItalic
        headerBodySize
        headerTitleAlign
        headerTitleBold
        headerTitleColor
        headerTitleFont
        headerTitleItalic
        headerTitleSize
        homepageDescription
        homepageTitle
        navigationBg
        navigationLinksAlign
        navigationLinksBold
        navigationLinksColor
        navigationLinksFont
        navigationLinksItalic
        navigationLinksSize
        showLegalsiteBranding
      }
    }
  }
`;
