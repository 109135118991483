import PropTypes from "prop-types";
import React, { Component } from "react";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import ViewIcon from "../../components/Icons/ViewIcon";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import { graphql } from "@apollo/client/react/hoc";
import ReactTable from "react-table-6";
import Moment from "react-moment";
import "react-table-6/react-table.css";
import DownloadIcon from "../../components/Icons/DownloadIcon";

class ClientsTable extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool.isRequired,
    showUser: PropTypes.func,
    user: PropTypes.object,
    checkItem: PropTypes.func,
    download: PropTypes.func,
    userName: PropTypes.array,
    userId: PropTypes.array,
    generateReport: PropTypes.func,
    users: PropTypes.array,
    showClient: PropTypes.func,
  };

  state = {
    isChecked: false,
    report: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === false
    ) {
      this.setState({
        isChecked: false,
      });
    }

    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === true
    ) {
      this.setState({
        isChecked: true,
      });
    }
  }

  handleCheck = (user) => {
    if (
      this.props.userName.includes(user.companyName) &&
      this.props.userId.includes(user.id)
    ) {
      return (
        <CheckActive
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.id,
              user.downloadUrl,
              user.companyName,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    } else {
      return (
        <CheckDisabled
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.id,
              user.downloadUrl,
              user.companyName,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    }
  };

  generateReport = (id, type, name) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType:
            type === "DataSubject"
              ? "DATA_SUBJECT"
              : type === "DataController"
              ? "DATA_CONTROLLER"
              : "USER",
        },
      })
      .then(({ data }) => {
        this.props.download(data.generateReport.downloadUrl, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { users } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span className="rt-check">{this.handleCheck(props.value)}</span>
        ),
      },

      {
        Header: "Company",
        accessor: "companyName",
      },
      {
        Header: "Plan",
        id: "plan",
        accessor: (d) => d.plan,
        Cell: (props) => (
          <span>
            {props.value && props.value === "NO PLAN"
              ? "No Plan"
              : props.value && props.value === "PAID"
              ? "Paid"
              : "Free"}
          </span>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => <span>{props.value}</span>,
      },
      {
        Header: "Telephone",
        accessor: "phoneNumber",
      },
      {
        Header: "Team",
        accessor: "teamSize",
      },
      {
        Header: "Date added",
        accessor: "createdAt",
        Cell: (props) => <Moment format="DD/MM/YYYY">{props.value}</Moment>,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => (
          <span
            className="clients__view"
            onClick={() =>
              this.props.showClient(props.value.id, props.value.companyName)
            }
          >
            <ViewIcon className="mr-2" color={"#7450c8"} /> View
          </span>
        ),
      },
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span
            className="users__view"
            onClick={() =>
              this.generateReport(
                props.value.id,
                props.value.__typename,
                props.value.companyName
              )
            }
          >
            <DownloadIcon className="mr-2" color={"#7450c8"} />
          </span>
        ),
      },
    ];

    return (
      <ReactTable
        data={users}
        columns={columns}
        minRows={0}
        showPagination={users && users.length > 20 ? true : false}
      />
    );
  }
}

export default graphql(GENERATE_REPORT, {
  name: "generateReport",
})(ClientsTable);
