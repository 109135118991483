import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";

function PreviewReport(props) {
  const { privacyImpactAssessment, legalsiteSettings } = props;

  return (
    <div className="preview-report">
      <div className="preview-report__header">
        <h3 className="preview-report__title">Preview Mode</h3>
        <div
          className="preview-report__close-btn"
          onClick={props.showPreviewCompliance}
        >
          <span className="preview-report__exit mr-2">Exit</span>
          <MdClose className="preview-report__close" color={"#fff"} size={25} />
        </div>
      </div>

      <div className="preview-report__container">
        <div className="preview-report__doc">
          <div className="preview-report__banner" />

          <div className="preview-report__info d-flex">
            <div className="d-flex align-items-center">
              {legalsiteSettings && legalsiteSettings.companyLogo ? (
                <div className="preview-report__logo">
                  <img src={legalsiteSettings.companyLogo} alt="logo" />
                </div>
              ) : (
                <span className="preview-report__placeholder" />
              )}
            </div>

            <span className="preview-report__date">24 July 2019</span>
          </div>

          <h5 className="preview-report__doc-title">
            Privacy Impact Assessment
          </h5>

          <ol>
            {privacyImpactAssessment && privacyImpactAssessment.dataCategories
              ? privacyImpactAssessment.dataCategories.map((item) =>
                  item.applicable ? (
                    <Fragment key={item.id}>
                      <li className="preview-report__subtitle">{item.name}</li>
                      {item.sources && item.sources.length ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Source:
                          </p>
                          <span>
                            {item.sourceSuppliers && item.sourceSuppliers.length
                              ? item.sourceSuppliers.map((item, i) => (
                                  <div
                                    className="d-flex preview-report__text"
                                    key={i}
                                  >
                                    <span>{item}</span>
                                  </div>
                                ))
                              : null}
                            {item.sources.map((item, i) => (
                              <div
                                className="d-flex preview-report__text"
                                key={i}
                              >
                                <span>{item}</span>
                              </div>
                            ))}
                          </span>
                        </Fragment>
                      ) : null}

                      {item.purposes && item.purposes.length ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Purpose:{" "}
                          </p>
                          <span>
                            {item.purposes.map((item, i) => (
                              <div
                                className="d-flex preview-report__text"
                                key={i}
                              >
                                <span>{item}</span>
                              </div>
                            ))}
                          </span>
                        </Fragment>
                      ) : null}

                      {item.legalBasis && item.legalBasis.length ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Legal basis:{" "}
                          </p>
                          <span>
                            {item.legalBasis.map((item, i) => (
                              <div
                                className="d-flex preview-report__text"
                                key={i}
                              >
                                <span>{item}</span>
                              </div>
                            ))}
                          </span>
                        </Fragment>
                      ) : null}

                      {item.retentionPeriod ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Retention period:
                          </p>
                          <span className="preview-report__text">
                            {item.retentionPeriod === "3_YEARS"
                              ? "3 Years"
                              : item.retentionPeriod === "6_MONTHS"
                              ? "6 Months"
                              : item.retentionPeriod === "1_YEAR"
                              ? "1 Year"
                              : item.retentionPeriod === "5_YEARS"
                              ? "5 Years"
                              : item.retentionPeriod}
                          </span>
                        </Fragment>
                      ) : null}

                      {item.actionRequired ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Actions required:
                          </p>
                          <span className="preview-report__text">
                            {item.actionRequired}
                          </span>
                        </Fragment>
                      ) : null}

                      {item.elements && item.elements.length ? (
                        <Fragment>
                          <p className="preview-report__subtitle mt-3 mb-3">
                            Elements:{" "}
                          </p>
                          <span>
                            {item.elements.map((item, i) => (
                              <div
                                className="d-flex preview-report__text"
                                key={i}
                              >
                                <span>{item}</span>
                              </div>
                            ))}
                          </span>{" "}
                        </Fragment>
                      ) : null}
                    </Fragment>
                  ) : null
                )
              : null}
          </ol>
        </div>
      </div>
    </div>
  );
}

export default PreviewReport;
PreviewReport.propTypes = {
  showPreviewCompliance: PropTypes.func.isRequired,
  privacyImpactAssessment: PropTypes.object.isRequired,
  legalsiteSettings: PropTypes.object.isRequired,
};
