import gql from "graphql-tag";

export const SETTINGS_QUERY = gql`
query($category: String!){
    account {
      companyRegistration
      companyAddress
      companyCity
      companyZipCode
      country
      dateFormat
      needToUpgrade
      companyWebsite
      email
      fullName
      phoneNumber
      companyName
      showLegalInformation
      id
      lifespanData
      needToUpgrade
      timezone
      subscription {
        id
        addons
        planName
        downgradeToFreeUrl
        upgradeToBusinessUrl
        status
      }
      lifespanInbox
      dataProcessing
      vat
      access
      users {
        avatar
        email
        id
        jobTitle
        name
        phoneNumber
        role
      }
    }
    legalsiteSettings {
      brandColor
      companyLogo
      favicon
      id
      subdomain
      customDomain
      dnsTarget
      consentMessage
    }
    dataProcessorSettings {
      id
    }
    viewer {
      id
      role
      appAdmin
    }
    consents {
      categoryName
      completeDescription
      enabled
      id
      manageableByVisitor
      smallDescription
      status
      dataProcessorConsents {
        id
        consentName
        dataProcessorCompanyName
        dataProcessorLogo
        purpose
      }
    }
    popup(category: $category) {
      targetAudience
    }
  }
`;
