import gql from "graphql-tag";

export const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      authenticationToken
      clientMutationId
      user {
        id
        appAdmin
        account {
          id
          subscription {
            id
          }
        }
      }
    }
  }
`;
