import PropTypes from "prop-types";
import React from "react";

const Site = props => (
    <svg
        height={24}
        viewBox='0 0 24 24'
        width={24}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--basic-settings---account" transform="translate(-181.000000, -1232.000000)">
                <g id="LegalSite" transform="translate(149.000000, 1198.000000)">
                    <g id="Group" transform="translate(32.000000, 34.000000)">
                        <path d="M9.81818182,4.36363636 C9.21568936,4.36363636 8.72727273,4.852053 8.72727273,5.45454545 C8.72727273,6.05703791 9.21568936,6.54545455 9.81818182,6.54545455 C10.4206743,6.54545455 10.9090909,6.05703791 10.9090909,5.45454545 C10.9090909,4.852053 10.4206743,4.36363636 9.81818182,4.36363636 Z M5.45454545,4.36363636 C4.852053,4.36363636 4.36363636,4.852053 4.36363636,5.45454545 C4.36363636,6.05703791 4.852053,6.54545455 5.45454545,6.54545455 C6.05703791,6.54545455 6.54545455,6.05703791 6.54545455,5.45454545 C6.54545455,4.852053 6.05703791,4.36363636 5.45454545,4.36363636 Z M14.1818182,4.36363636 C13.5793257,4.36363636 13.0909091,4.852053 13.0909091,5.45454545 C13.0909091,6.05703791 13.5793257,6.54545455 14.1818182,6.54545455 C14.7843106,6.54545455 15.2727273,6.05703791 15.2727273,5.45454545 C15.2727273,4.852053 14.7843106,4.36363636 14.1818182,4.36363636 Z M20.7272727,2.42230478e-16 L3.27272727,2.42230478e-16 C1.46524991,-2.42230478e-16 2.42230478e-16,1.46524991 0,3.27272727 L0,20.7272727 C2.42230478e-16,22.5347501 1.46524991,24 3.27272727,24 L20.7272727,24 C22.5347501,24 24,22.5347501 24,20.7272727 L24,3.27272727 C24,1.46524991 22.5347501,2.42230478e-16 20.7272727,2.42230478e-16 Z M21.8181818,20.7272727 C21.8181818,21.3297652 21.3297652,21.8181818 20.7272727,21.8181818 L3.27272727,21.8181818 C2.67023482,21.8181818 2.18181818,21.3297652 2.18181818,20.7272727 L2.18181818,10.9090909 L21.8181818,10.9090909 L21.8181818,20.7272727 Z M21.8181818,8.72727273 L2.18181818,8.72727273 L2.18181818,3.27272727 C2.18181818,2.67023482 2.67023482,2.18181818 3.27272727,2.18181818 L20.7272727,2.18181818 C21.3297652,2.18181818 21.8181818,2.67023482 21.8181818,3.27272727 L21.8181818,8.72727273 Z" id="Shape" fill={props.color} fillRule="nonzero"></path>
                        <g id="window"></g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Site.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Site;