import gql from "graphql-tag";

export const REMOVE_DPS_FROM_COMPLIANCE = gql`
  mutation($ids: [ID!]!, $clientMutationId: String) {
    removeDpsFromCompliance(
      input: { ids: $ids, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
