import PropTypes from "prop-types";
import React from "react";

const UserExclamationIcon = props => (
    <svg
        height={13}
        viewBox='0 0 12 13'
        width={12}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-1----Introduction" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="2.7--Onboarding-7" transform="translate(-1129.000000, -179.000000)" fill="#FFFFFF" fillRule="nonzero">
                <g id="Dropdown" transform="translate(1113.000000, 78.000000)">
                    <g id="options" transform="translate(13.000000, 101.000000)">
                        <g id="user-exclamation" transform="translate(3.000000, 0.000000)">
                            <path d="M8.4375,6.66823529 C9.2472369,5.95358051 9.7125117,4.91548641 9.7125,3.82352941 C9.7125,1.71185243 8.03356781,0 5.9625,0 C3.89143219,0 2.2125,1.71185243 2.2125,3.82352941 C2.22186966,4.92162397 2.70129957,5.96073793 3.525,6.66823529 C1.38070572,7.65825254 0.00225457508,9.83525073 0,12.2352941 C0,12.6576295 0.335786438,13 0.75,13 C1.16421356,13 1.5,12.6576295 1.5,12.2352941 C1.5,9.70128174 3.51471863,7.64705882 6,7.64705882 C8.48528137,7.64705882 10.5,9.70128174 10.5,12.2352941 C10.5,12.6576295 10.8357864,13 11.25,13 C11.6642136,13 12,12.6576295 12,12.2352941 C11.9911042,9.82628959 10.5964476,7.64688324 8.4375,6.66823529 Z M5.9625,6.11764706 C4.71985931,6.11764706 3.7125,5.0905356 3.7125,3.82352941 C3.7125,2.55652322 4.71985931,1.52941176 5.9625,1.52941176 C7.20514069,1.52941176 8.2125,2.55652322 8.2125,3.82352941 C8.2125,4.43196724 7.97544711,5.01548504 7.55349026,5.44571556 C7.13153341,5.87594607 6.5592371,6.11764706 5.9625,6.11764706 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

UserExclamationIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default UserExclamationIcon;