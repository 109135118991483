import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import CheckIcon from "../../components/Icons/WhiteCheck";
import user from "../../images/Inbox/user-exclamation.svg";
import download from "../../images/Inbox/download.svg";
import trash from "../../images/Inbox/trash.svg";
import archive from "../../images/Inbox/archive.svg";
import exclamation from "../../images/Inbox/exclamation.svg";
import Building from "../../images/Inbox/building.png";
import declined from "../../images/Inbox/declined.svg";
import Moment from "react-moment";

function UserInfo(props) {
  const { selectedRequest } = props;

  return (
    <div className="inbox__data-user d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div
          className={`${
            selectedRequest &&
            selectedRequest.dataController &&
            selectedRequest.dataController.avatar
              ? "inbox__data-user-avatar-company"
              : "inbox__data-user-avatar"
          }`}
        >
          {selectedRequest &&
          selectedRequest.dataController &&
          selectedRequest.dataController.avatar ? (
            <img src={selectedRequest.dataController.avatar} alt="avatar" />
          ) : selectedRequest &&
            !selectedRequest.dataController &&
            selectedRequest.dataSubject &&
            selectedRequest.dataSubject.avatar ? (
            <img src={selectedRequest.dataSubject.avatar} alt="avatar" />
          ) : (
            <span className="inbox__data-user-avatar-placeholder">
              {selectedRequest &&
              selectedRequest.dataController &&
              selectedRequest.dataController.name
                ? selectedRequest.dataController.name.charAt(0).toUpperCase()
                : selectedRequest &&
                  selectedRequest.dataSubject &&
                  selectedRequest.dataSubject.name
                ? selectedRequest.dataSubject.name.charAt(0).toUpperCase()
                : ""}
            </span>
          )}
        </div>

        <div className="d-flex flex-column">
          <span className="inbox__data-user-name">
            {selectedRequest &&
            selectedRequest.dataController &&
            selectedRequest.dataController.name
              ? selectedRequest.dataController.name
              : selectedRequest &&
                !selectedRequest.dataController &&
                selectedRequest.dataSubject &&
                selectedRequest.dataSubject.name
              ? selectedRequest.dataSubject.name
              : ""}
          </span>
          {selectedRequest && selectedRequest.dataController ? (
            <div className="d-flex align-items-center">
              <img
                src={Building}
                alt="request"
                className="ml-1 inbox__data-company"
              />
              <span className="inbox__data-user-name">
                {selectedRequest &&
                selectedRequest.dataController &&
                selectedRequest.dataController.company
                  ? selectedRequest.dataController.company
                  : ""}
              </span>
            </div>
          ) : null}
          <span className="inbox__data-user-email">
            {selectedRequest &&
            selectedRequest.dataController &&
            selectedRequest.dataController.email
              ? selectedRequest.dataController.email
              : selectedRequest &&
                !selectedRequest.dataController &&
                selectedRequest.dataSubject &&
                selectedRequest.dataSubject.email
              ? selectedRequest.dataSubject.email
              : ""}
          </span>
          {selectedRequest && !selectedRequest.dataController ? (
            <span className="inbox__data-user-email d-flex align-items mt-2">
              {selectedRequest &&
              selectedRequest.dataSubject &&
              selectedRequest.dataSubject.verified ? (
                <Fragment>
                  <CheckIcon className="mr-2" /> User Verified
                </Fragment>
              ) : (
                <Fragment>
                  <img src={declined} alt="declined" className="mr-2" /> User
                  Unverified
                </Fragment>
              )}
            </span>
          ) : null}
        </div>
      </div>

      <div className="text-right">
        <div className="d-flex">
          <img
            className="inbox__data-user-action mr-2 -user"
            src={archive}
            alt="user"
            id="archive"
            onClick={
              selectedRequest && selectedRequest.assignedTo
                ? props.showAssignedModal
                : props.toggleArchive
            }
          />
          <UncontrolledTooltip placement="top" target="archive">
            {selectedRequest && selectedRequest.archived
              ? "Unarchive"
              : "Archive"}
          </UncontrolledTooltip>

          <img
            className="inbox__data-user-action mr-2 -user"
            src={exclamation}
            alt="user"
            id="exclamation"
            onClick={
              selectedRequest && selectedRequest.assignedTo
                ? props.showAssignedModal
                : props.toggleSpam
            }
          />
          <UncontrolledTooltip placement="top" target="exclamation">
            {selectedRequest && selectedRequest.spam
              ? "Unspam"
              : "Mark as spam"}
          </UncontrolledTooltip>

          <Link
            to={{
              pathname: `/users/${
                selectedRequest && selectedRequest.dataSubject
                  ? selectedRequest.dataSubject.id
                  : null
              }`,
              state: {
                type:
                  selectedRequest && selectedRequest.dataSubject
                    ? selectedRequest.dataSubject.__typename
                    : "",
              },
            }}
          >
            <img
              className="inbox__data-user-action mr-2 -user"
              src={user}
              alt="user"
              id="user"
            />
          </Link>
          <UncontrolledTooltip placement="top" target="user">
            See user
          </UncontrolledTooltip>

          <img
            className="inbox__data-user-action mr-2"
            src={download}
            alt="download"
            id="download"
            onClick={() => props.generateReport(selectedRequest.id)}
          />
          <UncontrolledTooltip placement="top" target="download">
            Download report
          </UncontrolledTooltip>

          <img
            className="inbox__data-user-action"
            src={trash}
            alt="trash"
            id="trash"
            onClick={
              selectedRequest && selectedRequest.assignedTo
                ? props.showAssignedModal
                : props.deleteRequest
            }
          />
          <UncontrolledTooltip placement="top" target="trash">
            Delete
          </UncontrolledTooltip>
        </div>

        <span className="inbox__data-user-date">
          <Moment format="D MMM YYYY">
            {selectedRequest && selectedRequest.receivedDate
              ? selectedRequest.receivedDate
              : null}
          </Moment>
        </span>
      </div>
    </div>
  );
}

export default UserInfo;
UserInfo.propTypes = {
  selectedRequest: PropTypes.object,
  toggleArchive: PropTypes.func,
  toggleSpam: PropTypes.func,
  deleteRequest: PropTypes.func,
  generateReport: PropTypes.func,
  showAssignedModal: PropTypes.func,
};
