import React, { Component } from "react";
import PropTypes from "prop-types";
import Check from "react-icons/lib/fa/check";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import CompliancePrivacyEditModal from "../../components/Modals/CompliancePrivacyEditModal";
import { TOGGLE_DATA_CATEGORY } from "../../graphql/toggleDataCategory";
import TrashIcon from "../../components/Icons/TrashIcon";
import { graphql } from "@apollo/client/react/hoc";
import DeleteCategory from "../../components/Modals/DeleteCategory";

class CompliancePrivacyCategory extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    item: PropTypes.object,
    toggleDataCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
  };

  state = {
    isChecked:
      this.props.item && this.props.item.applicable
        ? this.props.item.applicable
        : false,
    isEditModalVisible: false,
    isDeleteModalOpen: false,
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  };

  checkItem = (value) => {
    this.setState({
      isChecked: value,
    });
  };

  showEditModal = () => {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
    });
  };

  toggleCategory = (id) => {
    this.props
      .toggleDataCategory({
        variables: {
          id,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  deleteCategory = () => {
    this.props.deleteCategory(this.props.item.id);
  };

  render() {
    const { title, text, item } = this.props;
    const { isChecked } = this.state;

    return (
      <div
        className={`${
          isChecked ? "-is-active" : ""
        } compliance-privacy__category d-flex justify-content-between`}
      >
        <div className="d-flex align-items-center">
          <div
            className="compliance-privacy__category-check"
            onClick={() => {
              this.setState({ isChecked: !this.state.isChecked });
              this.toggleCategory(item.id);
            }}
          >
            <span
              className={`check ${
                this.state.isChecked ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isChecked: !this.state.isChecked })
              }
            >
              <Check color={"#fff"} />
            </span>
          </div>

          <div className="d-flex flex-column">
            <span className="compliance-privacy__category-title">{title}</span>

            {isChecked ? (
              <span className="compliance-privacy__category-text">
                {text && text === "DONE" ? "Done" : text}
              </span>
            ) : null}
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-end flex-grow-1">
          <TrashIcon
            className="mr-2 delete"
            style={{ cursor: "pointer" }}
            color="#969696"
            onClick={this.openDeleteModal}
          />
          {isChecked ? (
            <div
              className="d-flex align-items-center compliance-privacy__category-edit"
              onClick={this.showEditModal}
            >
              Edit&nbsp;{" "}
              <ArrowCircleRight
                color={"#EBA601"}
                className="compliance-privacy__category-icon"
              />
            </div>
          ) : null}
        </div>

        <CompliancePrivacyEditModal
          title={title}
          checkedItem={item}
          isEditModalVisible={this.state.isEditModalVisible}
          showEditModal={this.showEditModal}
          id={item ? item.id : null}
        />

        <DeleteCategory
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          openDeleteModal={this.openDeleteModal}
          delete={this.deleteCategory}
        />
      </div>
    );
  }
}

export default graphql(TOGGLE_DATA_CATEGORY, {
  name: "toggleDataCategory",
})(CompliancePrivacyCategory);
