import gql from "graphql-tag";

export const CREATE_CUSTOM_DOMAIN = gql`
  mutation($customDomain: String!, $clientMutationId: String) {
    createCustomDomain(
      input: {
        customDomain: $customDomain
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      legalsiteSettings {
        id
        customDomain
      }
    }
  }
`;
