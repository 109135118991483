import React from "react";
import { Link } from "react-router-dom";

function AdminActions() {
  return (
    <div className="info__suppliers-container container">
      <div className="info__suppliers">
        <div>
          <p className="info__suppliers-title">Data Processors</p>
          <p className="info__suppliers-text">
            Manage Data Processors (third-parties) that will appear in the
            suppliers library. The user can send them data erasure requests and
            ask or upload their Data Processing Agreements.
          </p>
        </div>
        <div className="info__suppliers-btns">
          <Link
            to="/data-processors"
            className="btn--primary info__suppliers-manage mr-4"
          >
            Manage Data Processors
          </Link>
          <Link
            to={{ pathname: "/data-processors", state: { isAdmin: true } }}
            className="btn--secondary info__suppliers-manage"
          >
            Add Data Processors
          </Link>
        </div>
      </div>
      <div className="info__suppliers">
        <div>
          <p className="info__suppliers-title">E-mail templates</p>
          <p className="info__suppliers-text">
            Edit the e-mail templates that are available for users to send
            through the LegalSite app.
          </p>
        </div>
        <Link
          to={{
            pathname: "/settings",
            state: { isAdmin: true, showEmail: true },
          }}
          className="btn--primary info__suppliers-manage d-flex align-items-center justify-content-center"
        >
          Edit e-mails
        </Link>
      </div>
    </div>
  );
}

export default AdminActions;
