import gql from "graphql-tag";

export const SEND_BREACH_MAIL = gql`
  mutation(
    $id: ID!
    $body: String!
    $signature: String!
    $footer: String!
    $clientMutationId: String
  ) {
    sendBreachMail(
      input: {
        id: $id
        body: $body
        signature: $signature
        footer: $footer
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      breachMail {
        body
        id
        signature
        target
      }
    }
  }
`;
