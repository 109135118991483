import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Email from "../../images/Users/Document.svg";
import "./modals.scss";

export default class SubmittedModal extends Component {
  static propTypes = {
    openSubmittedModal: PropTypes.func.isRequired,
    isSubmittedModal: PropTypes.bool.isRequired,
    closeSubmittedModal: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isSubmittedModal}
          toggle={this.props.openSubmittedModal}
          className="breach-sent-email"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.closeSubmittedModal}
            />
            <div className="modal-assign__image">
              <img src={Email} alt="email" />
            </div>
            <p className="modal-assign__title">Request submitted</p>
            <p className="modal-assign__message">
              We’ll get back to you soon with a signed Data Processing
              Agreement.
            </p>
            <Link to="/legalSites" className="modal-assign__link mt-4">
              Home page <MdArrow size={23} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
