import gql from "graphql-tag";

export const CREATE_REQUEST_INBOX = gql`
  mutation(
    $type: UserRequestCategoryEnum!
    $status: CreateRequestStatusEnum!
    $createdAt: String!
    $dataSubjectId: ID!
    $dataSubjectVerified: Boolean!
    $clientMutationId: String
    $message: String
  ) {
    createRequestDataSubject(
      input: {
        type: $type
        status: $status
        createdAt: $createdAt
        dataSubjectId: $dataSubjectId
        dataSubjectVerified: $dataSubjectVerified
        clientMutationId: $clientMutationId
        message: $message
      }
    ) {
      errors
      request {
        id
        status
      }
    }
  }
`;
