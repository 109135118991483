import gql from "graphql-tag";

export const DPO_QUERY = gql`
  {
    dataProtectionOfficer {
      avatar
      company
      description
      email
      id
      name
      phoneNumber
      show
    }
  }
`;
