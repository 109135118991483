import React from "react";
import PropTypes from "prop-types";
import MdArrow from "react-icons/lib/ti/arrow-right";

function EmailSubjectItem(props) {
  const { title, text, emailType, index, emailTitle } = props;

  return (
    <div
      className="customization-email__subjects-item"
      onClick={() => {
        props.showEmail(emailType, emailTitle, index, 3);
      }}
    >
      <div>
        <h4 className="customization-email__subjects-item-title">{title}</h4>
        <p className="customization-email__subjects-item-text">{text}</p>
      </div>

      <MdArrow size={24} color={"#7450c8"} />
    </div>
  );
}

export default EmailSubjectItem;
EmailSubjectItem.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  showEmail: PropTypes.func.isRequired,
  emailType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  emailTitle: PropTypes.string.isRequired,
};
