import React from "react";
import { Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import PropTypes from "prop-types";

function BrandingPopup(props) {
  const {
    brandingSubtitleColor,
    brandingSubtitleSize,
    brandingSubtitleFont,
    brandingSubtitleAlign,
    brandingSubtitleBold,
    brandingSubtitleItalic,
    brandingBodyColor,
    brandingBodySize,
    brandingBodyFont,
    brandingBodyAlign,
    brandingBodyBold,
    brandingBodyItalic,
    brandingButtonColor,
    brandingButtonSize,
    brandingButtonFont,
    brandingButtonAlign,
    brandingButtonBold,
    brandingButtonItalic,
    brandingTextButtonColor,
    bgColor,
    btnBg,
    brandingTextButtonFont,
    brandingTextButtonSize,
    brandingTextButtonBold,
    brandingTextButtonItalic,
  } = props;
  return (
    <div>
      <p className="customization-site__branding-title">Pop-ups</p>

      <div
        className="customization-site__branding-popup"
        style={{
          background: bgColor ? bgColor : "#fff",
        }}
      >
        <MdClose
          className="customization-site__branding-close"
          size={20}
          color={"#969696"}
        />
        <div>
          <p
            className="customization-site__branding-subtitle"
            style={{
              color: brandingBodyColor ? brandingBodyColor : null,
              fontSize: brandingBodySize ? brandingBodySize : null,
              fontFamily: brandingBodyFont ? brandingBodyFont : null,
              textAlign: brandingBodyAlign !== null ? brandingBodyAlign : null,
              fontWeight:
                brandingBodyBold !== null
                  ? brandingBodyBold === true
                    ? 900
                    : 300
                  : null,
              fontStyle:
                brandingBodyItalic !== null
                  ? brandingBodyItalic === true
                    ? "italic"
                    : "normal"
                  : null,
            }}
          >
            Pop-up Title
          </p>
          <p
            className="customization-site__branding-subtext"
            style={{
              color: brandingSubtitleColor ? brandingSubtitleColor : null,
              fontSize: brandingSubtitleSize ? brandingSubtitleSize : null,
              fontFamily: brandingSubtitleFont ? brandingSubtitleFont : null,
              textAlign:
                brandingSubtitleAlign !== null ? brandingSubtitleAlign : null,
              fontWeight:
                brandingSubtitleBold !== null
                  ? brandingSubtitleBold === true
                    ? 900
                    : 300
                  : null,
              fontStyle:
                brandingSubtitleItalic !== null
                  ? brandingSubtitleItalic === true
                    ? "italic"
                    : "normal"
                  : null,
            }}
          >
            This is a placeholder text. We’ll adapt this title, text and images
            to the pop-up. This is a placeholder text.
          </p>
        </div>

        <div>
          <div className="d-flex flex-wrap">
            <Button
              className="customization-site__btn"
              style={{
                color: brandingButtonColor ? brandingButtonColor : null,
                fontSize: brandingButtonSize ? brandingButtonSize : null,
                fontFamily: brandingButtonFont ? brandingButtonFont : null,
                textAlign:
                  brandingButtonAlign !== null ? brandingButtonAlign : null,
                backgroundColor: btnBg ? btnBg : "#7450C8",
                fontWeight:
                  brandingButtonBold !== null
                    ? brandingButtonBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingButtonItalic !== null
                    ? brandingButtonItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              ACCEPT
            </Button>
            <Button
              className="border-0"
              style={{
                backgroundColor: "transparent",
                color: brandingTextButtonColor
                  ? brandingTextButtonColor
                  : "#7450C8",
                fontSize: brandingTextButtonSize
                  ? brandingTextButtonSize
                  : null,
                fontFamily: brandingTextButtonFont
                  ? brandingTextButtonFont
                  : null,
                fontWeight:
                  brandingTextButtonBold !== null
                    ? brandingTextButtonBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingTextButtonItalic !== null
                    ? brandingTextButtonItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              OPTIONS
            </Button>
          </div>

          <p
            className="customization-site__branding-get"
            style={{
              backgroundColor: "transparent",
              color: brandingTextButtonColor
                ? brandingTextButtonColor
                : "#7450C8",
              fontSize: brandingTextButtonSize ? brandingTextButtonSize : null,
              fontFamily: brandingTextButtonFont
                ? brandingTextButtonFont
                : null,
              fontWeight:
                brandingTextButtonBold !== null
                  ? brandingTextButtonBold === true
                    ? 900
                    : 300
                  : null,
              fontStyle:
                brandingTextButtonItalic !== null
                  ? brandingTextButtonItalic === true
                    ? "italic"
                    : "normal"
                  : null,
            }}
          >
            Get your Legalsite
          </p>
        </div>
      </div>
    </div>
  );
}

export default BrandingPopup;
BrandingPopup.propTypes = {
  brandingSubtitleColor: PropTypes.string,
  brandingSubtitleSize: PropTypes.string,
  brandingSubtitleFont: PropTypes.string,
  brandingSubtitleAlign: PropTypes.string,
  brandingSubtitleBold: PropTypes.bool,
  brandingSubtitleItalic: PropTypes.bool,
  brandingBodyColor: PropTypes.string,
  brandingBodySize: PropTypes.string,
  brandingBodyFont: PropTypes.string,
  brandingBodyAlign: PropTypes.string,
  brandingBodyBold: PropTypes.bool,
  brandingBodyItalic: PropTypes.bool,
  brandingButtonColor: PropTypes.string,
  brandingButtonSize: PropTypes.string,
  brandingButtonFont: PropTypes.string,
  brandingButtonAlign: PropTypes.string,
  brandingButtonBold: PropTypes.bool,
  brandingButtonItalic: PropTypes.bool,
  brandingLinksColor: PropTypes.string,
  brandingLinksSize: PropTypes.string,
  brandingLinksFont: PropTypes.string,
  brandingLinksAlign: PropTypes.string,
  brandingLinksBold: PropTypes.bool,
  brandingLinksItalic: PropTypes.bool,
  brandingTextButtonColor: PropTypes.string,
  bgColor: PropTypes.string,
  btnBg: PropTypes.string,
  brandingTextButtonFont: PropTypes.string,
  brandingTextButtonSize: PropTypes.string,
  brandingTextButtonBold: PropTypes.bool,
  brandingTextButtonItalic: PropTypes.bool,
};
