import axios from "axios";

export async function getCountries() {
	const apiUrl = window.encodeURI("https://restcountries.eu/rest/v2/all"),
		countries = await axios.get(apiUrl)
			.catch(err => {
				console.log(err);
			});

	return countries.data;
}