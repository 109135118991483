import gql from "graphql-tag";

export const DECLINE_REQUEST = gql`
  mutation($requestId: ID!, $clientMutationId: String) {
    declineDataRequest(
      input: { requestId: $requestId, clientMutationId: $clientMutationId }
    ) {
      errors
      request {
        id
        status
      }
    }
  }
`;
