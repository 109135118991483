import gql from "graphql-tag";

export const SEARCH_DC = gql`
  query($name: String!) {
    searchDataController(name: $name) {
      company
      addedAt
      email
      id
      name
    }
  }
`;
