import PropTypes from "prop-types";
import React from "react";

const Read = (props) => (
    <svg
        height={16}
        viewBox="0 0 16 16"
        width={16}
        fill="currentColor"
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style,
        }}
    >
        <g id="App-15---Admin-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5.2--edit-bar" transform="translate(-77.000000, -154.000000)" fill="#969696" fillRule="nonzero">
                <g id="Group" transform="translate(53.000000, 140.000000)">
                    <g id="Group-6" transform="translate(24.000000, 14.000000)">
                        <g id="Group-9">
                            <path d="M3.31029009e-05,10.762678 L3.31029009e-05,15.0014492 C3.31029009e-05,15.5535738 0.447618591,16.0011593 0.999743271,16.0011593 L5.23851438,16.0011593 C5.50424033,16.0026956 5.75964447,15.8983756 5.9483086,15.7112434 L12.866303,8.78325192 L15.7054798,6.00405765 C15.8947376,5.81634547 16.0011924,5.56082369 16.0011924,5.29426343 C16.0011924,5.02770318 15.8947376,4.77218139 15.7054798,4.58446921 L11.4667087,0.295712592 C11.2789965,0.106454811 11.0234748,0 10.7569145,0 C10.4903543,0 10.2348325,0.106454811 10.0471203,0.295712592 L7.22793762,3.12489237 L0.289949052,10.0528838 C0.10281679,10.241548 -0.0015032105,10.4969521 3.31029009e-05,10.762678 Z M10.7569145,2.41509815 L13.5860943,5.24427792 L12.1665058,6.66386636 L9.33732607,3.83468659 L10.7569145,2.41509815 Z M1.99945344,11.1725592 L7.92773473,5.24427792 L10.7569145,8.0734577 L4.82863321,14.001739 L1.99945344,14.001739 L1.99945344,11.1725592 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Read.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object,
};

export default Read;
