import gql from "graphql-tag";

export const LEGALSITE_QUERY = gql`
  {
    legalsite {
      bodyBg
      bodyBtnAlign
      bodyBtnBold
      bodyBtnColor
      bodyBtnFont
      bodyBtnBg
      bodyBtnItalic
      bodyBtnSize
      bodyIconsColor
      bodySubtitleAlign
      bodySubtitleBold
      bodySubtitleColor
      bodySubtitleFont
      bodySubtitleItalic
      bodySubtitleSize
      bodyTextAlign
      bodyTextBold
      bodyTextColor
      bodyTextFont
      bodyTextItalic
      bodyTextSize
      bodyTitleAlign
      bodyTitleBold
      bodyTitleColor
      bodyTitleFont
      bodyTitleItalic
      bodyTitleSize
      cardsBg
      cardsBodyAlign
      cardsBodyBold
      cardsBodyColor
      cardsBodyFont
      cardsBodyItalic
      cardsBodySize
      cardsBtnAlign
      cardsBtnBg
      cardsBtnBold
      cardsBtnColor
      cardsBtnFont
      cardsBtnItalic
      cardsBtnSize
      cardsIconsColor
      cardsTitleAlign
      cardsTitleBold
      cardsTitleColor
      cardsTitleFont
      cardsTitleItalic
      cardsTitleSize
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      headerBg
      headerBodyAlign
      headerBodyBold
      headerBodyColor
      headerBodyFont
      headerBodyItalic
      homepageDescription
      homepageTitle
      headerBodySize
      headerTitleAlign
      headerTitleBold
      headerTitleColor
      headerTitleFont
      headerTitleItalic
      homepageTitle
      homepageDescription
      headerTitleSize
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    legalsiteSettings {
      companyLogo
      id
      brandStyling {
        bodyTextAlign
        bodyTextBold
        bodyTextColor
        bodyTextFont
        bodyTextItalic
        bodyTextSize
        buttonTextAlign
        buttonTextBold
        buttonTextColor
        buttonTextFont
        buttonTextItalic
        buttonTextSize
        buttonsAndIconsColor
        emailBgColor
        headerAndFooterColor
        legalsiteBgColor
        legalsiteTitleAlign
        legalsiteTitleBold
        legalsiteTitleColor
        legalsiteTitleFont
        legalsiteTitleItalic
        legalsiteTitleSize
        linksFooterAlign
        linksFooterBold
        linksFooterColor
        linksFooterFont
        linksFooterItalic
        linksFooterSize
        mainButtonTextAlign
        mainButtonTextBold
        mainButtonTextColor
        mainButtonTextFont
        mainButtonTextItalic
        mainButtonTextSize
        manageInfoTitleAlign
        manageInfoTitleBold
        manageInfoTitleColor
        manageInfoTitleFont
        manageInfoTitleItalic
        manageInfoTitleSize
        navbarAndCardBgColor
        popupBgColor
        subtitleTitleAlign
        subtitleTitleBold
        subtitleTitleColor
        subtitleTitleFont
        subtitleTitleItalic
        subtitleTitleSize
      }
    }
    pages {
      applicable
      description
      id
      title
    }
    dontSellMyDataButton {
      buttonType
      color
      id
      snippet
      targetAudience
      text
      textColor
      textFont
      textSize
    }
    dataProtectionOfficer {
      show
      id
    }
    account {
      id
      subscription {
        id
        planName
      }
    }
  }
`;
