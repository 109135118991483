import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Input,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import { EMAILS_QUERY } from "../../graphql/emails";
import { SEND_CUSTOM_MAIL } from "../../graphql/sendCustomInboxMail";
import { DECLINE_REQUEST } from "../../graphql/declineDataRequest";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";

class ManualDelay extends Component {
  static propTypes = {
    openManualDelay: PropTypes.func.isRequired,
    isManualDelayVisible: PropTypes.bool.isRequired,
    showSolved: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    email: PropTypes.object,
    declineRequest: PropTypes.func,
    sendCustomInboxMail: PropTypes.func,
  };

  state = {
    formErrors: {},
  };

  handleSubmission = (e) => {
    e.preventDefault();
    const { email } = this.props.email;

    const formData = new FormData(e.target);
    const reason = formData.get("reason");

    let hasFormErrors = false;
    let formErrors = {};

    if (!reason) {
      hasFormErrors = true;
      formErrors.reason = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .declineRequest({
        variables: {
          requestId: this.props.id,
        },
      })
      .then(() => {
        this.props
          .sendCustomInboxMail({
            variables: {
              requestId: this.props.id,
              mailType: "UNREASONABLE",
              signature: email.signature ? email.signature : "",
              message: reason,
            },
          })
          .then(() => {
            this.props.showSolved();
            this.props.openManualDelay();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isManualDelayVisible}
          toggle={this.props.openManualDelay}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openManualDelay}
            />

            <p className="modal-assign__title mb-3">Decline this request</p>
            <p className="modal-assign__text mb-0 mt-0">
              Write the decline reason
            </p>
            <Form onSubmit={this.handleSubmission}>
              <FormGroup>
                <Input
                  type="textarea"
                  className="delete-modal__input"
                  rows="3"
                  name="reason"
                  invalid={this.state.formErrors.reason}
                  placeholder="Reason"
                />
                <FormFeedback>Please write a reason</FormFeedback>
              </FormGroup>

              <div className="discard-changes__btns">
                <Button
                  className="btn--secondary"
                  onClick={this.props.openManualDelay}
                >
                  Cancel
                </Button>
                <Button type="submit" className="btn--primary">
                  Decline
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(EMAILS_QUERY, {
    options: (props) => ({
      variables: {
        emailType: props.mailType,
      },
      fetchPolicy: "no-cache",
    }),
    name: "email",
  }),
  graphql(DECLINE_REQUEST, {
    name: "declineRequest",
  }),
  graphql(SEND_CUSTOM_MAIL, {
    name: "sendCustomInboxMail",
  })
)(ManualDelay);
