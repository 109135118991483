import React, { Component } from "react";
import { Alert } from "reactstrap";
import "./notifications.scss";
import NotificationItem from "./NotificationItem";
import { graphql } from "@apollo/client/react/hoc";
import { NOTIFICATIONS_QUERY } from "../../graphql/notifications";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import SyncLoader from "react-spinners/SyncLoader";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import PropTypes from "prop-types";

class Notifications extends Component {
  static propTypes = { data: PropTypes.object };
  state = {
    isRighMenuOpen: false,
    currentPage: 1,
    itemsPerPage: 9,
    datas: [],
  };

  handleClick = (event) => {
    this.setState({
      currentPage: Number(event.target.id),
    });
  };

  render() {
    const { notifications, loading, error } = this.props.data;
    const { currentPage, itemsPerPage } = this.state;

    let renderItems;
    let renderPageNumbers;

    const datas = this.state.datas.concat(notifications);

    if (datas) {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = datas.slice(indexOfFirstItem, indexOfLastItem);

      renderItems = currentItems
        .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        .map((item, index) => {
          return <NotificationItem key={index} notification={item} />;
        });

      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(datas.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map((number) => {
        return (number !== currentPage &&
          number > currentPage &&
          number - currentPage === 1) ||
          (number !== currentPage &&
            number < currentPage &&
            number - currentPage === -1) ||
          number === currentPage ? (
          <div
            key={number}
            className={`${
              number === currentPage
                ? "-is-current"
                : number !== currentPage && number > currentPage
                ? "-is-next"
                : "-is-previous"
            } notifications__pagination-item`}
            id={number}
            onClick={this.handleClick}
          >
            {number === currentPage
              ? number
              : number !== currentPage &&
                number > currentPage &&
                number - currentPage === 1
              ? ">"
              : number !== currentPage &&
                number < currentPage &&
                number - currentPage === -1
              ? "<"
              : null}
          </div>
        ) : null;
      });
    }

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return <Alert color="danger">There was an error loading the data!</Alert>;

    return (
      <div className="d-flex position-relative">
        <SideMenu />
        <div className="dashboard w-100">
          <Navbar
            title="Notifications"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          <div className="notifications info">
            <div className="notifications__container container">
              {renderItems}
            </div>

            <div className="notifications__pagination">
              {datas && datas.length > 9 ? renderPageNumbers : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(NOTIFICATIONS_QUERY)(Notifications);
