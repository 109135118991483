import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import { UncontrolledAlert } from "reactstrap";
import WarningIcon from "../../components/Icons/WarningIcon";

function AlertBanner(props) {
  const { alerts } = props;

  return (
    <UncontrolledAlert className="info__alert" color="danger">
      <WarningIcon />
      <span className="info__alert-action">
        {alerts.length > 1 ? "ACTIONS" : "ACTION"} REQUIRED:{" "}
      </span>
      <span className="info__alert-text">
        {alerts.length > 1
          ? "We have updated some templates. Please review and confirm to publish the changes."
          : `We have updated the ${
              alerts && alerts.length
                ? alerts[0].templatePublication
                  ? alerts[0].templatePublication.title
                  : alerts[0].pageTemplate.title
                : ""
            } template. Please review and confirm to publish the changes.`}
      </span>
      <Link to="/pages">
        <span className="info__alert-review">Review page</span>
        <ArrowCircleRight color={"#f86b67"} />
      </Link>
    </UncontrolledAlert>
  );
}

export default AlertBanner;
AlertBanner.propTypes = {
  alerts: PropTypes.array.isRequired,
};
