import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import Check from "react-icons/lib/fa/check";
import MdClose from "react-icons/lib/md/close";

export default class LegalBasis extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    legalBase: PropTypes.array,
    getLegalBasis: PropTypes.func.isRequired,
  };

  state = {
    isConsent: this.props.legalBase
      ? this.props.legalBase.indexOf("Consent") > -1
        ? true
        : false
      : null,
    isContract: this.props.legalBase
      ? this.props.legalBase.indexOf("Contract") > -1
        ? true
        : false
      : null,
    isLegal: this.props.legalBase
      ? this.props.legalBase.indexOf("Legal obligation") > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.legalBase
      ? this.props.legalBase.filter(
          (item) =>
            item !== "Consent" &&
            item !== "Contract" &&
            item !== "Legal obligation"
        ).length
        ? true
        : false
      : false,
  };

  getLegalBasis = () => {
    const info = this.props.legalBase
      ? this.props.legalBase.filter(
          (item) =>
            item !== "Consent" &&
            item !== "Contract" &&
            item !== "Legal obligation"
        )
      : "";

    this.props.getLegalBasis([
      this.state.isConsent === true ? "Consent" : null,
      this.state.isContract === true ? "Contract" : null,
      this.state.isLegal === true ? "Legal obligation" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  render() {
    const info = this.props.legalBase
      ? this.props.legalBase.filter(
          (item) =>
            item !== "Consent" &&
            item !== "Contract" &&
            item !== "Legal obligation"
        )
      : "";

    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title">
          Legal basis
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isConsent ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isConsent: !this.state.isConsent }, () =>
                this.getLegalBasis()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Consent
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isContract ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isContract: !this.state.isContract }, () =>
                this.getLegalBasis()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Contract
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isLegal ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isLegal: !this.state.isLegal }, () =>
                this.getLegalBasis()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Legal obligation
          </span>
        </div>

        <div>
          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getLegalBasis()
                )
              }
            >
              <Check color={"#fff"} />
            </span>
            <span className="modal-edit-privacy-category__content-text">
              Other
            </span>
          </div>
          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () =>
                this.getLegalBasis()
              )
            }
          />
        </div>
      </div>
    );
  }
}
