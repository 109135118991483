import gql from "graphql-tag";

export const DASHBOARD_QUERY = gql`
  {
    dashboardStatsAdmin {
      pendingRequests
      requestsInProgress
      solvedRequests
      totalUsers
      totalClients
      totalLawyers
      totalRequests
    }
    pageTemplates {
      description
      id
      sections {
        id
        applicable
        text
        title
      }
      title
      updatedAt
    }
  }
`;
