import gql from "graphql-tag";

export const SIGNUP = gql`
  mutation($email: String!, $password: String!, $name: String!) {
    createAccount(
      input: {
        authProvider: { email: $email, password: $password, name: $name }
      }
    ) {
      errors
      authenticationToken
    }
  }
`;
