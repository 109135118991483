import gql from "graphql-tag";

export const REMOVE_DPA_OF_DATA_ENTITY = gql`
  mutation($dpaId: ID!, $clientMutationId: String) {
    removeDpaOfDataEntity(
      input: { dpaId: $dpaId, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
