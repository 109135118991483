import PropTypes from "prop-types";
import React from "react";

const Sitemap = props => (
    <svg
        height={22}
        viewBox='0 0 22 22'
        width={22}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-3---Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="4.12--breach---reporting-overview" transform="translate(-573.000000, -492.000000)" fill={props.color} fillRule="nonzero">
                <g id="categories" transform="translate(149.000000, 276.000000)">
                    <g id="your-company" transform="translate(408.000000, 197.000000)">
                        <g id="sitemap" transform="translate(16.000000, 19.000000)">
                            <path d="M21,14 L19,14 L19,11 C19,10.4477153 18.5522847,10 18,10 L12,10 L12,8 L14,8 C14.5522847,8 15,7.55228475 15,7 L15,1 C15,0.44771525 14.5522847,0 14,0 L8,0 C7.44771525,0 7,0.44771525 7,1 L7,7 C7,7.55228475 7.44771525,8 8,8 L10,8 L10,10 L4,10 C3.44771525,10 3,10.4477153 3,11 L3,14 L1,14 C0.44771525,14 0,14.4477153 0,15 L0,21 C0,21.5522847 0.44771525,22 1,22 L7,22 C7.55228475,22 8,21.5522847 8,21 L8,15 C8,14.4477153 7.55228475,14 7,14 L5,14 L5,12 L17,12 L17,14 L15,14 C14.4477153,14 14,14.4477153 14,15 L14,21 C14,21.5522847 14.4477153,22 15,22 L21,22 C21.5522847,22 22,21.5522847 22,21 L22,15 C22,14.4477153 21.5522847,14 21,14 Z M6,16 L6,20 L2,20 L2,16 L6,16 Z M9,6 L9,2 L13,2 L13,6 L9,6 Z M20,20 L16,20 L16,16 L20,16 L20,20 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Sitemap.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Sitemap;