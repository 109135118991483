import PropTypes from "prop-types";
import React, { Fragment } from "react";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { DropdownItem, DropdownMenu } from "reactstrap";
import UserExclamationIcon from "../../components/Icons/UserExclamation";
import ArrowCircleUpIcon from "../../components/Icons/ArrowCircleUp";
import HelpIcon from "../../components/Icons/HelpIcon";
import LogOutIcon from "../../components/Icons/LogOutIcon";
import GetStartedIcon from "../../components/Icons/GetStartedIcon";

function OnboardSteps(props) {
  const {
    step,
    viewer,
    stepValue,
    closeStep,
    showNextStep,
    completeOnboarding,
    isDP,
    dpStepValue,
  } = props;

  return (
    <Fragment>
      {step === "dataFirst" ? (
        <div className="introduction-step1">
          <div>
            <span className="info__step1-close" onClick={closeStep}>
              <MdClose size={20} color={"#fff"} />
            </span>
            <h5 className="info__step1-title">This is your dashboard!</h5>
            <p className="info__step1-text">
              After the onboarding go through this checklist on the right. Here
              on the dashboard you’ll be able to have an overview of your
              company and see if there are any urgent tasks.
            </p>
          </div>

          <span
            className="info__step1-next"
            onClick={() =>
              isDP ? showNextStep("fourth") : showNextStep("second")
            }
          >
            Next&nbsp;
            <MdArrow size={20} color="#fff" />
          </span>
        </div>
      ) : step === "second" ? (
        <div className="introduction-step2">
          <div>
            <span className="info__step1-close" onClick={closeStep}>
              <MdClose size={20} color={"#fff"} />
            </span>
            <h5 className="info__step1-title">Pages</h5>
            <p className="info__step1-text">
              Here you can customize your pages such as Terms and Conditions and
              publish them on your LegalSite.
            </p>
          </div>

          <span
            className="info__step1-next"
            onClick={() => showNextStep("third")}
          >
            Next&nbsp;
            <MdArrow size={20} color="#fff" />
          </span>
        </div>
      ) : step === "third" ? (
        <div className="introduction-step3">
          <div>
            <span className="info__step1-close" onClick={closeStep}>
              <MdClose size={20} color={"#fff"} />
            </span>
            <h5 className="info__step1-title">Compliance</h5>
            <p className="info__step1-text">
              Set up your privacy compliance requirements. Manage your Data
              Processing Agreements, set your Privacy Impact Assessment or
              report a breach incident.
            </p>
          </div>

          <span
            className="info__step1-next"
            onClick={() => showNextStep("fourth")}
          >
            Next&nbsp;
            <MdArrow size={20} color="#fff" />
          </span>
        </div>
      ) : step === "fourth" ? (
        <div className="introduction-step4">
          <div>
            <span className="info__step1-close" onClick={closeStep}>
              <MdClose size={20} color={"#fff"} />
            </span>
            <h5 className="info__step1-title">Inbox</h5>
            <p className="info__step1-text">
              Here you can see all of your requests and take action to solve
              them.
            </p>
          </div>

          <span
            className="info__step1-next"
            onClick={() =>
              isDP ? showNextStep("sixth") : showNextStep("fifth")
            }
          >
            Next&nbsp;
            <MdArrow size={20} color="#fff" />
          </span>
        </div>
      ) : step === "fifth" ? (
        <div className="introduction-step5">
          <div>
            <span className="info__step1-close" onClick={closeStep}>
              <MdClose size={20} color={"#fff"} />
            </span>
            <h5 className="info__step1-title">Settings</h5>
            <p className="info__step1-text">
              Set up your company info, choose notifications and customize your
              LegalSite, cookies and e-mails!
            </p>
          </div>

          <span
            className="info__step1-next"
            onClick={() => showNextStep("sixth")}
          >
            Next&nbsp;
            <MdArrow size={20} color="#fff" />
          </span>
        </div>
      ) : step === "sixth" ? (
        <Fragment>
          <DropdownMenu className="introduction-navbar__dropdown">
            <DropdownItem header className="d-flex px-2">
              <div className="navigationBar__dropdown-avatar">
                {viewer && viewer.avatar ? (
                  <img src={viewer.avatar} alt="avatar" />
                ) : (
                  <span className="navigationBar__default-avatar2">
                    {viewer && viewer.name
                      ? viewer.name.charAt(0).toUpperCase()
                      : ""}
                  </span>
                )}
              </div>

              <div>
                <p className="navigationBar__dropdown-name">
                  {viewer ? viewer.name : ""}
                </p>
                <p className="navigationBar__dropdown-email">
                  {viewer ? viewer.email : ""}
                </p>
              </div>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="navigationBar__dropdown-item">
              <UserExclamationIcon className="navigationBar__dropdown-icon" />
              Account Settings
            </DropdownItem>
            <DropdownItem className="navigationBar__dropdown-item">
              <ArrowCircleUpIcon className="navigationBar__dropdown-icon" />{" "}
              Upgrade
            </DropdownItem>
            <DropdownItem className="navigationBar__dropdown-item">
              <HelpIcon
                color="#DFE4EE"
                className="navigationBar__dropdown-icon"
              />{" "}
              Help
            </DropdownItem>
            <DropdownItem className="navigationBar__dropdown-item">
              <LogOutIcon className="navigationBar__dropdown-icon" /> Log out
            </DropdownItem>
            <DropdownItem className="navigationBar__dropdown-item">
              <GetStartedIcon className="navigationBar__dropdown-icon" />{" "}
              Getting started - step {isDP ? dpStepValue : stepValue} of{" "}
              {isDP ? "2" : "7"} done
            </DropdownItem>
          </DropdownMenu>

          <div className="introduction-step6">
            <div>
              <span
                className="info__step1-close"
                onClick={() => {
                  closeStep();
                  completeOnboarding();
                }}
              >
                <MdClose size={20} color={"#fff"} />
              </span>
              <h5 className="info__step1-title">Getting started</h5>
              <p className="info__step1-text">
                Now go through the Getting started checklist. If you go to
                another page and want to see the checklist again, just click
                here to see your next steps!
              </p>
            </div>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
}

export default OnboardSteps;

OnboardSteps.propTypes = {
  step: PropTypes.string,
  viewer: PropTypes.object,
  stepValue: PropTypes.number.isRequired,
  closeStep: PropTypes.func.isRequired,
  showNextStep: PropTypes.func.isRequired,
  completeOnboarding: PropTypes.func.isRequired,
  isDP: PropTypes.bool,
  dpStepValue: PropTypes.number,
};
