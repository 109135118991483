import React from "react";
import PropTypes from "prop-types";
import EmailType from "./EmailType";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";

function NotifyBreach(props) {
  const { incident } = props;

  return (
    <div className="notify-breach w-100">
      <p onClick={props.getInitialPage} className="notify-breach__back">
        <MdArrowLeft
          size={25}
          className="customize-menu__back position-relative"
          color="#7450c8"
        />
        &nbsp;Back to Compliance
      </p>
      <h5 className="notify-breach__title">Notify breach incident</h5>

      <p className="notify-breach__text">
        Notify the interested parts about this incident with an e-mail.
      </p>

      <div className="d-flex flex-wrap">
        {incident
          ? incident.breachMails.map((item) =>
              item.target === "VISITORS" ||
              item.target === "SUPERVISORY_AUTHORITY" ? (
                <EmailType
                  key={item.id}
                  item={item}
                  showEmail={props.showEmail}
                />
              ) : null
            )
          : null}
      </div>
    </div>
  );
}

export default NotifyBreach;
NotifyBreach.propTypes = {
  showEmail: PropTypes.func.isRequired,
  getInitialPage: PropTypes.func.isRequired,
  incident: PropTypes.object.isRequired,
};
