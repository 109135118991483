import React from "react";
import { TabPane } from "reactstrap";
import TrashIcon from "../../components/Icons/TrashIcon";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";

function HistoryActivity(props) {
  const {
    historyActivity,
    formatDate,
    date,
    day,
    wholeMonth,
    year,
    parseDate,
    removeActivity,
  } = props;

  const requests = historyActivity
    ? historyActivity
        .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        .filter(
          (item) => formatDate(date) === formatDate(parseDate(item.createdAt))
        )
    : null;

  const columns = [
    {
      Header: "Time",
      accessor: "createdAt",
      Cell: (props) => (
        <span>
          {day} {wholeMonth} {year} {props.value.slice(11, 16)}
        </span>
      ),
    },
    {
      Header: "User",
      accessor: "userName",
    },
    {
      Header: "Activity",
      accessor: "activity",
    },
    {
      Header: "",
      accessor: "",
      Cell: (props) => (
        <TrashIcon
          color={"#969696"}
          className="history__icon"
          onClick={() => removeActivity(props.value.id)}
        />
      ),
    },
  ];

  return (
    <TabPane tabId="2" className="history__table-container">
      <ReactTable
        data={requests}
        showPageSizeOptions={false}
        defaultPageSize={20}
        columns={columns}
        minRows={0}
        showPagination={requests && requests.length > 20 ? true : false}
        noDataText="No Activity"
      />
    </TabPane>
  );
}

export default HistoryActivity;

HistoryActivity.propTypes = {
  historyActivity: PropTypes.array,
  formatDate: PropTypes.func,
  date: PropTypes.object,
  day: PropTypes.string,
  wholeMonth: PropTypes.string,
  year: PropTypes.string,
  parseDate: PropTypes.func,
  removeActivity: PropTypes.func,
  value: PropTypes.array,
};
