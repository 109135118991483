import React, { Component, Fragment } from "react";
import CheckActive from "../Icons/CheckActive";
import { Link } from "react-router-dom";
import "./menu-content.scss";
import PropTypes from "prop-types";
import CheckDisabled from "../Icons/CheckDisabled";

export default class MenuChecklist extends Component {
  static propTypes = {
    hasOnboarded: PropTypes.bool,
    completedCompanyInfo: PropTypes.bool,
    completedPages: PropTypes.bool,
    editedPages: PropTypes.bool,
    customizedPopup: PropTypes.bool,
    customizedLegalsite: PropTypes.bool,
    customizedEmails: PropTypes.bool,
    isDP: PropTypes.bool,
    location: PropTypes.string,
    isDashboard: PropTypes.bool,
    showIntroductionModal: PropTypes.func,
    restartOnboarding: PropTypes.func,
    redirectToDashboard: PropTypes.func,
  };

  render() {
    const onboardingSteps = this.props.isDP
      ? [
          {
            title: "Do the onboarding tour",
            text: "Learn how to navigate on the platform.",
            id: 1,
            checked: this.props.hasOnboarded,
          },
          {
            title: "Company Info",
            text: "Fill out your basic company information",
            id: 2,
            checked: this.props.completedCompanyInfo,
          },
        ]
      : [
          {
            title: "Do the onboarding tour",
            text: "Learn how to navigate on the platform.",
            id: 1,
            checked: this.props.hasOnboarded,
          },
          {
            title: "Company Info",
            text: "Fill out your basic company information",
            id: 2,
            checked: this.props.completedCompanyInfo,
          },
          {
            title: "Customize your pages",
            text: "Edit your legal pages content.",
            id: 5,
            checked: this.props.editedPages,
          },
          {
            title: "Customize Agreements Pop-up",
            text: "Choose the visuals for your Pop-ups",
            id: 6,
            checked: this.props.customizedPopup,
          },
          {
            title: "Customize e-mails",
            text: "Customize your e-mail templates",
            id: 7,
            checked: this.props.customizedEmails,
          },
          {
            title: "Customize your LegalSite",
            text: "Set colors, logo and fonts for your LegalSite.",
            id: 8,
            checked: this.props.customizedLegalsite,
          },
          {
            title: "Publish your LegalSite",
            text: "",
            id: 4,
            checked: this.props.completedPages,
          },
        ];

    const { location } = this.props;

    return (
      <Fragment>
        {onboardingSteps.map((item, index) => (
          <div className="checklist-item" key={index}>
            {item.checked ? <CheckActive /> : <CheckDisabled />}

            <Link
              to={{
                pathname:
                  item.id === 2 ||
                  item.id === 3 ||
                  item.id === 8 ||
                  item.id === 7 ||
                  item.id === 6
                    ? "/settings"
                    : item.id === 4 || item.id === 5
                    ? "/pages"
                    : "",
                state:
                  item.id === 2
                    ? { companyInfo: true }
                    : item.id === 3
                    ? { notifications: true }
                    : item.id === 6
                    ? { customizePopups: true }
                    : item.id === 7
                    ? { customizeEmails: true }
                    : item.id === 8
                    ? { customizeLegalsite: true }
                    : null,
              }}
              onClick={
                item.id !== 4 && item.id !== 5
                  ? item.id === 1
                    ? !this.props.isDashboard
                      ? () => {
                          this.props.redirectToDashboard();
                          this.props.showIntroductionModal();
                          this.props.restartOnboarding();
                        }
                      : () => {
                          this.props.showIntroductionModal();
                          this.props.restartOnboarding();
                        }
                    : location === "/settings"
                    ? () => window.location.reload()
                    : null
                  : null
              }
              className="checklist-item__container"
            >
              <p
                className={`${
                  item.checked ? "-is-checked" : ""
                } checklist-item__title`}
              >
                {item.title}
              </p>
              <span className="checklist-item__info">{item.text}</span>
            </Link>
          </div>
        ))}
      </Fragment>
    );
  }
}
