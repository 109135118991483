import gql from "graphql-tag";

export const EDIT_PURPOSE_DATA_ENTITY = gql`
  mutation($entityType: EntityTypeEnum!, $id: ID!, $purpose: UserPurposeEnum!, $clientMutationId: String) {
    editPurposeDataEntity(
      input: {
        entityType: $entityType
        id: $id
        purpose: $purpose
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      dataEntity {
        id
        purpose
      }
    }
  }
`;
