import gql from "graphql-tag";

export const EDIT_PAGE_SECTION = gql`
  mutation($id: ID!, $title: String, $text: String, $clientMutationId: String, $readOnly: Boolean) {
    editSection(
      input: {
        id: $id
        title: $title
        text: $text
        readOnly: $readOnly
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      section {
        applicable
        id
        position
        text
        title
      }
    }
  }
`;
