import React, { Component } from "react";
import MdArrow from "react-icons/lib/ti/arrow-right";
import UpdateBreach from "./UpdateBreach";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import Breaches from "./Breaches";
import CloudExclamation from "../../components/Icons/CloudExclamation";
import Image from "../../images/Settings/Illustration2.svg";
import { graphql } from "@apollo/client/react/hoc";
import { COMPLIANCE_BREACH_QUERY } from "../../graphql/complianceBreach";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class ComplianceBreach extends Component {
  static propTypes = {
    complianceBreach: PropTypes.object,
    getInitialPage: PropTypes.func,
  };

  state = {
    isUpdate: false,
    isIncidents: false,
    incidentType: null,
    incident: {},
    isNameForm: false,
    isFromSection: null,
    formErrors: {},
    breachName: "",
  };

  componentDidMount() {
    this.props.complianceBreach.refetch();
  }

  updateBreach = () => {
    this.setState({
      isUpdate: !this.state.isUpdate,
      isIncidents: false,
    });
  };

  showIncidents = (type, incident) => {
    this.setState({
      isIncidents: !this.state.isIncidents,
      isNameForm: false,
      isUpdate: false,
      incidentType: type,
      incident,
    });
  };

  showNameForm = (type, incident) => {
    this.setState({
      isNameForm: !this.state.isNameForm,
      isFromSection: type,
      incident,
    });
  };

  handleNewBreach = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.showIncidents("new");
  };

  render() {
    const { isUpdate, isIncidents, isNameForm } = this.state;

    return !isNameForm ? (
      <div className={`${isUpdate ? "-has-scroll" : ""} compliance-breach`}>
        {!isUpdate && !isIncidents && !isNameForm ? (
          <div className="compliance-breach__container">
            <p
              onClick={this.props.getInitialPage}
              className="compliance-breach__back"
            >
              <MdArrowLeft
                size={25}
                className="customize-menu__back position-relative"
                color="#7450c8"
              />
              &nbsp;Back to Compliance
            </p>

            <h5 className="compliance-breach__title">
              Breach Incident Management
            </h5>
            <p className="compliance-breach__text">
              A personal data breach occurs when the data is accessed,
              disclosed, altered, lost or destroyed in contravention of an
              organization's obligation to keep personal data in its possession
              safe and secure.
            </p>

            <p className="compliance-breach__text">
              You need to report personal data breaches to the relevant
              supervisory authority within 72 hours after becoming aware of the
              breach.
            </p>

            <div
              className="compliance-breach__item mt-5"
              onClick={() => this.showNameForm("new")}
            >
              <div>
                <p className="compliance-breach__item-title">
                  New breach incident
                </p>
                <p className="compliance-breach__item-text">
                  Start here to report a new incident and notify your users.
                </p>
              </div>

              <MdArrow size={25} color={"#7450c8"} />
            </div>

            {this.props.complianceBreach &&
            this.props.complianceBreach.breachIncidents &&
            this.props.complianceBreach.breachIncidents.length ? (
              <div
                className="compliance-breach__item"
                onClick={this.updateBreach}
              >
                <div>
                  <p className="compliance-breach__item-title">
                    Update breach incident
                  </p>
                  <p className="compliance-breach__item-text">
                    Update an ongoing breach incident.
                  </p>
                </div>

                <MdArrow size={25} color={"#7450c8"} />
              </div>
            ) : null}
          </div>
        ) : isUpdate ? (
          <UpdateBreach
            showIncidents={this.showIncidents}
            getInitialPage={this.props.getInitialPage}
          />
        ) : isIncidents ? (
          <Breaches
            incidentType={this.state.incidentType}
            showIncidents={this.showIncidents}
            breachName={this.state.breachName}
            refetchData={() => this.props.complianceBreach.refetch()}
            getInitialPage={this.props.getInitialPage}
            incident={
              this.state.incident
                ? this.state.incident
                : this.props.complianceBreach &&
                  this.props.complianceBreach.breachIncidents.length
                ? this.props.complianceBreach.breachIncidents[0]
                : null
            }
          />
        ) : null}
      </div>
    ) : (
      <div className="compliance-breach__new container">
        <div className="compliance-breach__new-title">
          <CloudExclamation color={"#333"} />
          <p>New Breach Incident</p>
        </div>

        <p className="compliance-breach__new-text">
          Give a name to this data breach incident. This name will be only for
          internal use. We’ll register all the info regarding this breach, so
          the name will make it easier to identify it.
        </p>

        <img alt="breach" src={Image} />

        <Form
          className="compliance-breach__new-form"
          onSubmit={this.handleNewBreach}
        >
          <FormGroup>
            <Label>Breach incident name:</Label>
            <Input
              type="text"
              name="name"
              placeholder="Write your breach incident name"
              invalid={this.state.formErrors.name}
              onChange={(e) => this.setState({ breachName: e.target.value })}
            />
            <FormFeedback>Please add a breach name</FormFeedback>
          </FormGroup>

          <div className="compliance-breach__new-btns">
            <Button
              className="btn--secondary compliance-breach__new-cancel"
              onClick={this.showNameForm}
            >
              Cancel
            </Button>
            <Button className="btn--primary compliance-breach__new-next">
              Next
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default graphql(COMPLIANCE_BREACH_QUERY, {
  name: "complianceBreach",
})(ComplianceBreach);
