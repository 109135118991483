import gql from "graphql-tag";

export const TOGGLE_PAGE = gql`
  mutation($id: ID!, $clientMutationId: String) {
    togglePage(input: { id: $id, clientMutationId: $clientMutationId }) {
      errors
      page {
        id
        applicable
        updatedAt
      }
    }
  }
`;
