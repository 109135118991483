import React, { Component } from "react";
import logo from "../../images/Login/PurpleLogo.svg";
import thanks from "../../images/Login/thanks.svg";
import { Link } from "react-router-dom";
import "./dashboard.scss";

class ThankYou extends Component {
  state = {};

  render() {
    return (
      <div className="thank-you">
        <div className="thank-you__logo">
          <img src={logo} alt="logo" />
        </div>

        <div className="d-flex h-100">
          <div className="thank-you__container container">
            <img src={thanks} alt="thanks" />

            <h1 className="thank-you__title">Thank you!</h1>
            <p className="thank-you__text">
              Your plan was successfully activated.
              <br />
              Click on the button below to get started.
            </p>
            <Link
              to={{ pathname: "/dashboard", state: { isThankYou: true } }}
              className="btn--primary d-flex align-items-center justify-content-center"
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
