import React from "react";
import PropTypes from "prop-types";
import GoogleFontLoader from "react-google-font-loader";

function Fonts(props) {
  const {
    font1,
    font2,
    font3,
    font4,
    font5,
    font6,
    font7,
    font8,
    font9,
    font10,
    font11,
    font12,
  } = props;

  return (
    <GoogleFontLoader
      fonts={[
        {
          font: font1,
        },
        {
          font: font2,
        },
        {
          font: font3,
        },
        {
          font: font4,
        },
        {
          font: font5,
        },
        {
          font: font6,
        },
        {
          font: font7,
        },
        {
          font: font8,
        },
        {
          font: font9,
        },
        {
          font: font10,
        },
        {
          font: font11,
        },
        {
          font: font12,
        },
      ]}
    />
  );
}

export default Fonts;
Fonts.propTypes = {
  font1: PropTypes.string,
  font2: PropTypes.string,
  font3: PropTypes.string,
  font4: PropTypes.string,
  font5: PropTypes.string,
  font6: PropTypes.string,
  font7: PropTypes.string,
  font8: PropTypes.string,
  font9: PropTypes.string,
  font10: PropTypes.string,
  font11: PropTypes.string,
  font12: PropTypes.string,
};
