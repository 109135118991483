import React from "react";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import { UncontrolledAlert } from "reactstrap";
import WarningIcon from "../../components/Icons/WarningIcon";
import PropTypes from "prop-types";

function AlertBanner(props) {
  const { PrivacyUpdate, showPage } = props;

  return (
    <UncontrolledAlert className="info__alert mt-5" color="danger">
      <WarningIcon />
      <span className="info__alert-action">ACTION REQUIRED:</span>
      <span className="info__alert-text">
        The CCPA requires that you update your Privacy Policy every 12 months.
        It’s time for your update.
      </span>
      <div
        style={{ cursor: "pointer" }}
        onClick={() =>
          showPage(
            PrivacyUpdate && PrivacyUpdate.length ? PrivacyUpdate[0].title : "",
            null,
            PrivacyUpdate && PrivacyUpdate.length ? PrivacyUpdate[0] : "",
            2
          )
        }
      >
        <span className="info__alert-review">Review page</span>
        <ArrowCircleRight color={"#f86b67"} />
      </div>
    </UncontrolledAlert>
  );
}

export default AlertBanner;
AlertBanner.propTypes = {
  PrivacyUpdate: PropTypes.array,
  showPage: PropTypes.func,
};
