import PropTypes from "prop-types";
import React from "react";

const Building = (props) => (
  <svg
    height={20}
    viewBox="0 0 20 20"
    width={20}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-3---Compliance"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2.1--DPA---My-DPA"
        transform="translate(-1120.000000, -174.000000)"
        fill="#7450C8"
        fillRule="nonzero"
      >
        <g id="Action-bar" transform="translate(1095.000000, 146.000000)">
          <g id="building" transform="translate(25.000000, 28.000000)">
            <path
              d="M12,6 L13,6 C13.5522847,6 14,5.55228475 14,5 C14,4.44771525 13.5522847,4 13,4 L12,4 C11.4477153,4 11,4.44771525 11,5 C11,5.55228475 11.4477153,6 12,6 Z M12,10 L13,10 C13.5522847,10 14,9.55228475 14,9 C14,8.44771525 13.5522847,8 13,8 L12,8 C11.4477153,8 11,8.44771525 11,9 C11,9.55228475 11.4477153,10 12,10 Z M7,6 L8,6 C8.55228475,6 9,5.55228475 9,5 C9,4.44771525 8.55228475,4 8,4 L7,4 C6.44771525,4 6,4.44771525 6,5 C6,5.55228475 6.44771525,6 7,6 Z M7,10 L8,10 C8.55228475,10 9,9.55228475 9,9 C9,8.44771525 8.55228475,8 8,8 L7,8 C6.44771525,8 6,8.44771525 6,9 C6,9.55228475 6.44771525,10 7,10 Z M19,18 L18,18 L18,1 C18,0.44771525 17.5522847,0 17,0 L3,0 C2.44771525,0 2,0.44771525 2,1 L2,18 L1,18 C0.44771525,18 0,18.4477153 0,19 C0,19.5522847 0.44771525,20 1,20 L19,20 C19.5522847,20 20,19.5522847 20,19 C20,18.4477153 19.5522847,18 19,18 Z M11,18 L9,18 L9,14 L11,14 L11,18 Z M16,18 L13,18 L13,13 C13,12.4477153 12.5522847,12 12,12 L8,12 C7.44771525,12 7,12.4477153 7,13 L7,18 L4,18 L4,2 L16,2 L16,18 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Building.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Building;
