import gql from "graphql-tag";

export const EDIT_PIA_NOTIFICATION = gql`
  mutation($interval: NotificationIntervalEnum!, $clientMutationId: String) {
    editPiaNotificationsInterval(
      input: {
        interval: $interval
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      privacyImpactAssessment {
        id
        notificationInterval
      }
    }
  }
`;