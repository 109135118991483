const industries = [
	"Human Resources",
	"Internet",
	"Computer Software",
	"Information Technology & Services",
	"Staffing & Recruiting",
	"Other",
];

export default industries;
