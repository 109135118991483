import PropTypes from "prop-types";
import React from "react";

const CheckIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1317.000000, -234.000000)" fill="#1DDCC0" fillRule="nonzero">
                <g id="Legal-site-box" transform="translate(1063.000000, 130.000000)">
                    <g id="Terms" transform="translate(12.000000, 84.000000)">
                        <g id="check-circle" transform="translate(242.000000, 20.000000)">
                            <path d="M12.72,6.79 L8.43,11.09 L6.78,9.44 C6.53561012,9.15462384 6.1518816,9.03031806 5.78658103,9.11818987 C5.42128045,9.20606167 5.13606167,9.49128045 5.04818987,9.85658103 C4.96031806,10.2218816 5.08462384,10.6056101 5.37,10.85 L7.72,13.21 C7.90871883,13.3971865 8.16419702,13.5015368 8.43,13.5000167 C8.69233988,13.4988954 8.94373936,13.3947442 9.13,13.21 L14.13,8.21 C14.3193127,8.0222334 14.4257983,7.76663754 14.4257983,7.5 C14.4257983,7.23336246 14.3193127,6.9777666 14.13,6.79 C13.7399625,6.40227641 13.1100375,6.40227641 12.72,6.79 L12.72,6.79 Z M10,-4.4408921e-16 C4.4771525,-4.4408921e-16 1.77635684e-15,4.4771525 1.77635684e-15,10 C1.77635684e-15,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,7.3478351 18.9464316,4.80429597 17.0710678,2.92893219 C15.195704,1.0535684 12.6521649,-4.4408921e-16 10,-4.4408921e-16 Z M10,18 C5.581722,18 2,14.418278 2,10 C2,5.581722 5.581722,2 10,2 C14.418278,2 18,5.581722 18,10 C18,12.1217319 17.1571453,14.1565632 15.6568542,15.6568542 C14.1565632,17.1571453 12.1217319,18 10,18 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CheckIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default CheckIcon;