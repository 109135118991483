import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";
import { Button, Table } from "reactstrap";
import IoSearch from "react-icons/lib/io/search";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Filter2 from "../../components/Icons/Filter2";
import SearchInput from "../../components/SearchInput/SearchInput";
import { SEARCH_QUERY } from "../../graphql/search";
import { graphql } from "@apollo/client/react/hoc";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MenuContent from "../../components/MenuContent/MenuContent";
import SyncLoader from "react-spinners/SyncLoader";
import "./search.scss";

class Search extends Component {
  static propTypes = {
    setPageName: PropTypes.func,
    search: PropTypes.object,
  };

  state = {
    isRighMenuOpen: false,
    filter: "NO_FILTER",
    name: "",
  };

  search = (name) => {
    this.setState({
      name,
    });
    this.props.search.refetch({ name });
  };

  render() {
    const { filter } = this.state;

    const { error, loading, searchPage } = this.props.search;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="d-flex position-relative">
        <SideMenu />
        <div className="dashboard w-100">
          <Navbar
            title="Search"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          <div className="search">
            <div className="container">
              <Link to="/dashboard" className="search__exit">
                Exit &nbsp;&nbsp;
                <MdClose size={20} />
              </Link>

              <SearchInput
                placeholder={"Type to search for a report or a user..."}
                search={this.search}
              />

              <div className="search__filter-title">
                <Filter2 />
                &nbsp;&nbsp;Filter
              </div>

              <div className="search__filter-btns">
                <Button
                  className={`${
                    filter === "DATA_SUBJECT" ? "-is-active" : ""
                  } search__btn`}
                  onClick={() =>
                    this.setState(
                      {
                        filter: "DATA_SUBJECT",
                      },
                      () =>
                        this.props.search.refetch({
                          name: this.state.name,
                          filter: "DATA_SUBJECT",
                        })
                    )
                  }
                >
                  User
                </Button>
                <Button
                  className={`${
                    filter === "DATA_CONTROLLER" ? "-is-active" : ""
                  } search__btn`}
                  onClick={() =>
                    this.setState(
                      {
                        filter: "DATA_CONTROLLER",
                      },
                      () =>
                        this.props.search.refetch({
                          name: this.state.name,
                          filter: "DATA_CONTROLLER",
                        })
                    )
                  }
                >
                  Company
                </Button>
                <Button
                  className={`${
                    filter === "DATA_PROCESSOR" ? "-is-active" : ""
                  } search__btn`}
                  onClick={() =>
                    this.setState(
                      {
                        filter: "DATA_PROCESSOR",
                      },
                      () =>
                        this.props.search.refetch({
                          name: this.state.name,
                          filter: "DATA_PROCESSOR",
                        })
                    )
                  }
                >
                  Data Processor
                </Button>
                <span
                  className="search__remove"
                  onClick={() =>
                    this.setState(
                      {
                        filter: "NO_FILTER",
                      },
                      () =>
                        this.props.search.refetch({
                          name: this.state.name,
                          filter: "NO_FILTER",
                        })
                    )
                  }
                >
                  Remove filters
                </span>
              </div>

              {searchPage && searchPage.length ? (
                <div className="search__results">
                  <p className="search__results-header">
                    <IoSearch size={20} />
                    &nbsp;Search results
                  </p>

                  <div className="search__results-table">
                    <Table>
                      <thead>
                        <tr>
                          <th>Result</th>
                          <th>Category</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {searchPage.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name}</td>
                            <td>
                              {item.type === "data_subject"
                                ? "Data Subject"
                                : item.type === "data_controller"
                                ? "Data Controller"
                                : item.type === "data_processor"
                                ? "Data Processor"
                                : item.type}
                            </td>
                            <td>
                              <Link
                                to={
                                  item.type === "data_subject" ||
                                  item.type === "data_controller"
                                    ? {
                                        pathname: `/users/${item.id}`,
                                        state: { type: item.type },
                                      }
                                    : {
                                        pathname: "/data-processors",
                                      }
                                }
                              >
                                <MdArrow color={"#7450c8"} size={25} />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(SEARCH_QUERY, {
  options: () => ({
    variables: {
      name: "",
      filter: "NO_FILTER",
    },
    fetchPolicy: "no-cache",
  }),
  name: "search",
})(Search);
