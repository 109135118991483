import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactFilestack from "filestack-react";
import MdClose from "react-icons/lib/md/close";
import { Button } from "reactstrap";
import Upload from "../../images/Users/Illustration.svg";
import Check from "react-icons/lib/fa/check";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { PARSE_USER_DATA_FILE } from "../../graphql/parseUserDataFile";
import { SCHEDULE_IMPORT } from "../../graphql/scheduleImport";
import { CONTINUE_IMPORT } from "../../graphql/continueImport";
import { SELECT_DATA_CONTROLLERS } from "../../graphql/selectDataControllers";
import { IMPORT_DATA_ENTITIES } from "../../graphql/importListOfDataEntities";
import { IMPORT_QUERY } from "../../graphql/importQuery";
import { ToastContainer, toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import UploadTable from "./UploadTable";

class UploadData extends Component {
  static propTypes = {
    showImport: PropTypes.func.isRequired,
    isCompliance: PropTypes.bool.isRequired,
    data: PropTypes.object,
    scheduleImport: PropTypes.func,
    selectDataControlers: PropTypes.func,
    continueImport: PropTypes.func,
    refetchData: PropTypes.func,
    getImportStatus: PropTypes.func,
  };

  state = {
    files: [],
    isUsersTable: false,
    isCheckedAll: false,
    users: [],
    pageNumber: 1,
    arr: [],
  };

  notify = (text) => toast(text);

  onFiles(res) {
    this.setState({ files: res.filesUploaded });

    this.props
      .scheduleImport({
        variables: {
          fileName:
            this.state.files && this.state.files.length
              ? this.state.files[0].key
              : "",
        },
      })
      .then(({ data }) => {
        this.setState(
          {
            id: data.scheduleImport.import.id,
          },
          () =>
            this.props.data.refetch({
              id: data.scheduleImport.import.id,
              importId: data.scheduleImport.import.id,
            })
        );

        this.refetchImport();
      })
      .catch((error) => {
        console.log(error);
        this.notify("File couldn't be imported");
      });
  }

  refetchImport = () => {
    this.props.data.startPolling(500);
  };

  changePage = (pageNumber) => {
    this.props.data.stopPolling();
    this.setState(
      {
        pageNumber: pageNumber,
      },
      () => this.props.data.refetch({ pageNumber: pageNumber })
    );
  };

  checkItem = (id) => {
    let checkedItems = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);

    if (this.state.arr.includes(id)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id) {
          this.state.arr.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
    });
  };

  checkAll = (users) => {
    const userId = users.map((item) => item.id);
    this.setState({
      isCheckedAll: !this.state.isCheckedAll,
      arr: userId,
    });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: !this.state.isCheckedAll,
      arr: [],
    });
  };

  selectDataControlers = () => {
    this.props
      .selectDataControlers({
        variables: {
          importId: this.state.id,
          ids: this.state.arr,
        },
      })
      .then(() => {
        this.props
          .continueImport({
            variables: {
              id: this.state.id,
            },
          })
          .then(() => {
            this.props.refetchData();
            this.props.getImportStatus(this.props.data.import);
            this.props.showImport();
          })
          .catch((error) => {
            console.log(error);
            this.notify("File couldn't be imported");
          });
      })
      .catch((error) => {
        console.log(error);
        this.notify("File couldn't be imported");
      });
  };

  addDataControllers = () => {
    this.props
      .continueImport({
        variables: {
          id: this.state.id,
        },
      })
      .then(() => {
        this.props.refetchData();
        this.props.getImportStatus(this.props.data.import);
        this.props.showImport();
      })
      .catch((error) => {
        console.log(error);
        this.notify("File couldn't be imported");
      });
  };

  render() {
    const { files, isUsersTable, isCheckedAll } = this.state;
    let users;

    if (
      this.props.data &&
      this.props.data.import &&
      this.props.data.import.status &&
      this.props.data.import.status === "waiting_for_selecting"
    ) {
      users = this.props.data.importRows.rows;
    }

    return !isUsersTable ? (
      <div className="container import__upload-container">
        <h2 className="import__upload-title">Import user data</h2>
        <p className="import__upload-text mb-1">
          Upload a file to import user data. This new record will be added to
          your index.
        </p>

        <p className="import__upload-text mb-1">
          Please name the columns on the file with the following titles and
          order: name, e-mail, company and phone number.
        </p>

        <p className="import__upload-text mb-1">
          On the content, all these fields need to be filled, so if an
          information is missing write N/A.
        </p>

        <p className="import__upload-text">
          Duplicate addresses will be removed and this new records will be added
          to your index.
        </p>

        {files.length ? (
          <div className="data-processor__file mb-4">
            <div className="data-processor__file-type">DOC</div>
            <p className="data-processor__file-name">{files[0].filename}</p>
            <MdClose
              onClick={() => this.setState({ files: [] })}
              className="data-processor__file-remove"
              size={20}
              color={"#969696"}
            />
          </div>
        ) : (
          <img src={Upload} alt="upload" />
        )}

        <div className={`${this.props.isCompliance ? "d-flex" : ""}`}>
          <div className="d-flex justify-content-between">
            <Button
              className="btn--secondary mt-5 mr-3"
              onClick={this.props.showImport}
            >
              Cancel
            </Button>
            {!files.length ? (
              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: (
                    <Button className="btn--primary mt-5">Upload file</Button>
                  ),
                  customClass: "show-upload",
                }}
                onSuccess={(res) => this.onFiles(res)}
                actionOptions={{
                  fromSources: [
                    "local_file_system",
                    "googledrive",
                    "dropbox",
                    "onedrive",
                  ],
                  accept: ["text/*"],
                  maxFiles: 1,
                }}
              />
            ) : (
              <Button
                className="btn--primary mt-5"
                onClick={() => this.setState({ isUsersTable: true })}
                disabled={
                  this.props.data &&
                  this.props.data.import &&
                  this.props.data.import.status &&
                  this.props.data.import.status !== "waiting_for_selecting"
                    ? true
                    : false
                }
              >
                Continue
              </Button>
            )}
          </div>
        </div>
      </div>
    ) : (
      <div className="container import__upload-container">
        <h2 className="import__upload-title">Import users</h2>
        <p className="import__upload-text text-center">
          Select which of these users are your Data Controllers. A Data
          Controller is a company that you process data for. By marking your
          Data Controllers here, you’ll know when a data request on your Inbox
          is made by a data controller or by a data subject.
        </p>

        <div className="w-100 justify-content-between">
          <div className="import__upload-table-header d-flex w-100 justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span
                className={`check ${isCheckedAll ? "-checked" : "-unchecked"}`}
                onClick={() =>
                  !isCheckedAll ? this.checkAll(users) : this.unselectAll()
                }
              >
                <Check color={"#fff"} />
              </span>
              Select all
            </div>

            <Button
              className="btn--primary"
              disabled={this.state.arr.length ? false : true}
              onClick={this.selectDataControlers}
            >
              Mark as Data Controllers
            </Button>
            <p className="mb-0 import__skip" onClick={this.addDataControllers}>
              Skip this step <MdArrow />
            </p>
          </div>
          <div className="mt-3 position-relative">
            <UploadTable
              checkItem={this.checkItem}
              isCheckedAll={isCheckedAll}
              users={users}
              arr={this.state.arr}
              changePage={this.changePage}
              numberOfPages={
                this.props.data &&
                this.props.data.importRows &&
                this.props.data.importRows.numberOfPages
              }
              pageNumber={this.state.pageNumber}
            />
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(PARSE_USER_DATA_FILE, {
    name: "parseFile",
  }),
  graphql(IMPORT_DATA_ENTITIES, {
    name: "importDataEntities",
  }),
  graphql(IMPORT_QUERY, {
    options: () => ({
      variables: {
        id: 0,
        importId: 0,
        pageNumber: 1,
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(SCHEDULE_IMPORT, {
    name: "scheduleImport",
  }),
  graphql(SELECT_DATA_CONTROLLERS, {
    name: "selectDataControlers",
  }),
  graphql(CONTINUE_IMPORT, {
    name: "continueImport",
  })
)(UploadData);
