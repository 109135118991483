import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Button, Row, Col, Alert } from "reactstrap";
import OverviewCards from "./OverviewCards";
import EmptyUsers from "./EmptyUsers";
import SyncLoader from "react-spinners/SyncLoader";
import RequestSignature from "./RequestSignature";
import ImportData from "./ImportData";
import ManualData from "./ManualData";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import axios from "axios";
import { USERS_QUERY } from "../../graphql/users";
import { REMOVE_DATA_ENTITIES } from "../../graphql/removeDataEntities";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import "./users.scss";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import UsersActions from "./UsersActions";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import UserTable from "./UserTable";

class Users extends Component {
  static propTypes = {
    setPageName: PropTypes.func,
    data: PropTypes.object,
    removeDataEntities: PropTypes.func,
    location: PropTypes.object,
  };

  state = {
    isCheckedAll: false,
    isUserVisible: false,
    isRequestSignature: false,
    isImporting: false,
    isManual: false,
    filteredUsers: null,
    isRighMenuOpen: false,
    user: null,
    arr: [],
    urls: [],
    userName: [],
    userType: [],
    userReports: [],
    hasFiltered: false,
    isDeleteModalVisible: false,
    userId: null,
    pageNumber: 1,
    filter: null,
    goToUser: false,
    hasUserId: null,
    hasUserType: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.user) {
      this.setState({
        isUserVisible: true,
        user: this.props.location.state.user,
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.state && this.props.location.state.userId) {
      this.setState({
        isUserVisible: true,
        userId: this.props.location.state.userId,
      });
    }
  }

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  getInitialPage = () => {
    this.props.data.stopPolling();
    this.setState({
      isImporting: false,
      isManual: false,
      filteredUsers: null,
      isUserVisible: false,
      isRequestSignature: false,
      user: null,
      isCheckedAll: false,
    });
  };

  showUser = (user) => {
    this.props.data.stopPolling();
    this.setState({
      goToUser: true,
      hasUserId: user.id,
      hasUserType: user.__typename,
    });
  };

  notify = (text) => toast(text);

  showRequestSignature = () => {
    this.props.data.stopPolling();
    if (this.state.isRequestSignature) {
      this.setState({
        isCheckedAll: false,
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        user: null,
      });
    }
    this.setState({
      isRequestSignature: !this.state.isRequestSignature,
    });
  };

  showImport = () => {
    this.props.data.stopPolling();
    this.setState({
      isImporting: !this.state.isImporting,
    });
  };

  showManualData = () => {
    this.props.data.stopPolling();
    this.setState({
      isManual: !this.state.isManual,
    });
  };

  searchUser = (user) => {
    this.props.data.stopPolling();
    this.props.data.refetch({ name: user });
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  checkItem = (id, downloadUrl, name, type, report) => {
    this.props.data.stopPolling();
    let checkedItems = [];
    let checkedUrls = [];
    let checkedNames = [];
    let checkedTypes = [];
    let checkedReports = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        userReports: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);
    checkedUrls.concat(downloadUrl);
    checkedNames.concat(name);
    checkedTypes.concat(type);
    checkedReports.concat(report);

    if (this.state.arr.includes(id) && this.state.urls.includes(downloadUrl)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id && this.state.urls[i] === downloadUrl) {
          this.state.arr.splice(i, 1);
          this.state.urls.splice(i, 1);
          this.state.userName.splice(i, 1);
          this.state.userType.splice(i, 1);
          this.state.userReports.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
      urls: this.state.urls.concat(downloadUrl),
      userName: this.state.userName.concat(name),
      userType: this.state.userType.concat(type),
      userReports: this.state.userReports.concat(report),
    });
  };

  checkAll = (users) => {
    this.props.data.stopPolling();
    this.setState({
      arr: [],
      urls: [],
      userName: [],
      userType: [],
      userReports: [],
    });

    this.checkEverything(this.state.userReports, users);
  };

  checkEverything = (reports, users) => {
    this.props.data.stopPolling();
    const userId = users.map((item) => item.id);
    const userUrl = users.map((item) => item.downloadUrl);
    const userName = users.map((item) => item.name);
    const userType = users.map((item) => item.__typename);

    this.setState({
      isCheckedAll: true,
      arr: userId,
      urls: userUrl,
      userName,
      userType,
      userReports: reports,
    });
  };

  unselectAll = () => {
    this.props.data.stopPolling();
    this.setState({
      isCheckedAll: false,
      arr: [],
      urls: [],
      userName: [],
      userType: [],
      userReports: [],
    });
  };

  setFilter = (filter) => {
    this.props.data.stopPolling();
    this.setState(
      {
        hasFiltered: true,
        pageNumber: 1,
        filter,
        filteredUsers: null,
      },
      () => this.props.data.refetch({ pageNumber: 1, filter })
    );
  };

  removeUser = () => {
    this.props.data.stopPolling();
    const entities = this.state.arr.map((item, index) => {
      return {
        id: item,
        type:
          this.state.userType[index] === "DataSubject"
            ? "DATA_SUBJECT"
            : "DATA_CONTROLLER",
      };
    });

    this.props
      .removeDataEntities({
        variables: {
          entities,
        },
      })
      .then(() => {
        this.setState({
          arr: [],
          urls: [],
          userName: [],
          userType: [],
          isCheckedAll: false,
          pageNumber: 1,
        });

        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were removed!"
            : "User was removed!"
        );

        this.props.data.refetch({ pageNumber: 1 });
      })
      .catch((error) => {
        console.log(error);
        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were not removed!"
            : "User was not removed"
        );
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  download = (url, name) => {
    this.props.data.stopPolling();
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  changePage = (pageNumber) => {
    this.props.data.stopPolling();
    this.setState(
      {
        pageNumber: pageNumber,
      },
      () => this.props.data.refetch({ pageNumber: pageNumber })
    );
  };

  getImportStatus = (data) => {
    this.setState({
      importStatus: data,
    });

    this.props.data.startPolling(1000);
  };

  render() {
    const {
      isCheckedAll,
      isUserVisible,
      isRequestSignature,
      isImporting,
      isManual,
      hasUserId,
      hasUserType,
      goToUser,
      importStatus,
    } = this.state;

    const { account, loading, error, usersStats, searchDataEntity } =
      this.props.data;

    const users =
      searchDataEntity && searchDataEntity.length
        ? searchDataEntity
        : this.props.data &&
          this.props.data.dataEntities &&
          this.props.data.dataEntities.dataEntities
        ? this.props.data.dataEntities.dataEntities
        : null;

    if (goToUser) {
      return (
        <Redirect
          to={{ pathname: `/users/${hasUserId}`, state: { type: hasUserType } }}
        />
      );
    }

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Users"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          {(this.props.data &&
            this.props.data.dataEntities &&
            this.props.data.dataEntities.dataEntities &&
            this.props.data.dataEntities.dataEntities.length) ||
          this.state.hasFiltered ? (
            !isUserVisible &&
            !isRequestSignature &&
            !isImporting &&
            !isManual ? (
              <div
                className={`${
                  account && account.needToUpgrade ? "-has-banner" : ""
                } users`}
              >
                <div className="container">
                  <Row className="users__header">
                    <Col sm={12} lg={4} className="users__header-info">
                      <span className="users__header-text">
                        Here you can manage your user index and request DPA’s.
                        You can import user data from a file or add a contact
                        manually.
                      </span>

                      <div className="users__header-btns">
                        <Button
                          className="users__header-import"
                          onClick={this.showImport}
                        >
                          Import
                        </Button>
                        <Button
                          className="btn--empty users__header-add bg-transparent"
                          onClick={this.showManualData}
                        >
                          Add manually
                        </Button>
                      </div>
                    </Col>

                    <OverviewCards usersStats={usersStats} />
                  </Row>
                </div>

                {importStatus && importStatus.status ? (
                  importStatus.status !== "waiting_for_selecting" ? (
                    <Alert color="danger">
                      We are importing you users, they will be available on you
                      index soon.
                    </Alert>
                  ) : (
                    <Alert color="danger">
                      We are importing you users, they will be available on you
                      index soon.
                    </Alert>
                  )
                ) : null}

                <div className="users__data container">
                  <div className="users__data-container">
                    <UsersActions
                      isCheckedAll={isCheckedAll}
                      users={users}
                      searchUser={this.searchUser}
                      showRequestSignature={this.showRequestSignature}
                      checkAll={this.checkAll}
                      unselectAll={this.unselectAll}
                      setFilter={this.setFilter}
                      filter={this.state.filter}
                      arr={this.state.arr}
                      download={this.download}
                      deleteUser={this.openDeleteModal}
                      isUsers={true}
                    />

                    <UserTable
                      users={users}
                      checkItem={this.checkItem}
                      isCheckedAll={isCheckedAll}
                      userName={this.state.userName}
                      userId={this.state.arr}
                      showUser={this.showUser}
                      download={this.download}
                      changePage={this.changePage}
                      numberOfPages={this.props.data.dataEntities.numberOfPages}
                      pageNumber={this.state.pageNumber}
                    />
                  </div>
                </div>
              </div>
            ) : !isUserVisible &&
              isRequestSignature &&
              !isImporting &&
              !isManual ? (
              <RequestSignature
                showRequestSignature={this.showRequestSignature}
                selectedUsers={this.state.arr.map((item, index) => {
                  return {
                    id: item,
                    userType:
                      this.state.userType[index] === "DataSubject"
                        ? "DATA_SUBJECT"
                        : this.state.userType[index] === "DataController"
                        ? "DATA_CONTROLLER"
                        : "DATA_PROCESSOR",
                  };
                })}
              />
            ) : !isUserVisible &&
              !isRequestSignature &&
              isImporting &&
              !isManual ? (
              <ImportData
                showImport={this.showImport}
                getImportStatus={this.getImportStatus}
                refetchData={this.refetchData}
              />
            ) : (
              <ManualData
                showManualData={this.showManualData}
                refetchData={this.refetchData}
                showNotify={() => this.notify("User added!")}
                notify={(msg) => this.notify(msg)}
              />
            )
          ) : (
            <EmptyUsers
              refetchData={this.refetchData}
              getImportStatus={this.getImportStatus}
              showImport={() => {}}
              showManualData={this.showManualData}
            />
          )}
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          isUsers={this.state.arr && this.state.arr.length > 1}
          removeUser={this.removeUser}
        />
      </div>
    );
  }
}

export default compose(
  graphql(USERS_QUERY, {
    options: () => ({
      variables: {
        filter: "ALL",
        pageNumber: 1,
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(REMOVE_DATA_ENTITIES, {
    name: "removeDataEntities",
  })
)(Users);
