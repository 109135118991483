import gql from "graphql-tag";

export const REVERT_TEMPLATE = gql`
  mutation($pageTemplateId: ID!, $templatePublicationId: ID!, $clientMutationId: String) {
    revertTemplateToPublication(
      input: {
        pageTemplateId: $pageTemplateId
        templatePublicationId: $templatePublicationId
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      pageTemplate {
        id
        sections {
          id
          text
          title
          applicable
        }
      }
    }
  }
`;
