import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import SearchIcon from "./../Icons/SearchIcon";
import SyncLoader from "react-spinners/SyncLoader";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav } from "reactstrap";
import "./navbar.scss";
import { Redirect } from "react-router-dom";
import { AUTH_TOKEN } from "../../constants";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { SIGN_OUT } from "../../graphql/signOut";
import { NAVIGATION_BAR_QUERY } from "../../graphql/navigationBar";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import { VIEW_NOTIFICATIONS } from "../../graphql/viewNotifications";
import NotificationsMenu from "./NotificationsMenu";
import ProfileMenu from "./ProfileMenu";

class NavigationBar extends Component {
  static propTypes = {
    title: PropTypes.string,
    showSideBarMenu: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    navigationBarQuery: PropTypes.object.isRequired,
    viewNotifications: PropTypes.func.isRequired,
  };

  state = {
    isOpen: false,
    cbInstance: window.Chargebee.getInstance(),
    datas: [],
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  redirectToPath = (path) => {
    window.location.href = path;
  };

  formatDate = (date) => {
    const year = date.slice(6, 10);
    const month = date.slice(3, 5);
    const day = date.slice(0, 2);
    const formatedDate = `${year}-${month}-${day}`;

    return formatedDate;
  };

  handlePortal = () => {
    this.state.cbInstance.createChargebeePortal().open({
      visit(visit) {
        console.log("portal visit", visit);
      },
    });
  };

  signOut = () => {
    this.props
      .signOut({
        variables: {
          clientMutationId: this.props.viewer.viewer.id,
        },
      })
      .then(() => {
        localStorage.removeItem(AUTH_TOKEN);
        window.location.href = "/signin";
      })
      .catch((error) => {
        console.log(error);
      });
  };

  viewNotifications = () => {
    const { viewer } = this.props.viewer;
    if (viewer && viewer.newNotifications) {
      this.props
        .viewNotifications()
        .then(() => {
          this.props.viewer.refetch();
        })
        .catch((error) => {
          console.log(error);
        });
    } else return;
  };

  render() {
    const { notifications, loading, error, account } =
      this.props.navigationBarQuery;
    const { viewer } = this.props.viewer;
    const { title } = this.props;
    const completedOnboarding = viewer && viewer.onboarded;
    const completedDpOnboarding = viewer && viewer.onboardedAsDp;
    const completedCompanyInfo = viewer && viewer.editedCompanyInfo;
    const completedPages = viewer && viewer.publishedPage;
    const editedPages = viewer && viewer.editedPage;
    const customizedPopup = viewer && viewer.customizedPopup;
    const customizedLegalsite = viewer && viewer.customizedLegalsite;
    const customizedEmails = viewer && viewer.customizedEmails;
    const isDP = viewer && viewer.invitedAsDp;
    const checklist = [
      completedOnboarding,
      completedCompanyInfo,
      completedPages,
      editedPages,
      customizedPopup,
      customizedLegalsite,
      customizedEmails,
    ].filter((v) => v).length;
    const dpChecklist = [completedCompanyInfo, completedDpOnboarding].filter(
      (v) => v
    ).length;

    const dpStepValue = dpChecklist === 2 ? 2 : dpChecklist === 1 ? 1 : 0;
    const stepValue =
      checklist === 7
        ? 7
        : checklist === 6
        ? 6
        : checklist === 5
        ? 5
        : checklist === 4
        ? 4
        : checklist === 3
        ? 3
        : checklist === 2
        ? 2
        : checklist === 1
        ? 1
        : 0;

    const getFirstLetter =
      viewer && viewer.name ? viewer.name.charAt(0).toUpperCase() : "";

    const datas = this.state.datas.concat(notifications);

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return console.log(error);
      }
    }

    return (
      <Navbar light expand="md" className="navigationBar">
        <NavbarBrand href="/dashboard" className="navigationBar__brand">
          {title}
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto display-flex align-items-center" navbar>
            {viewer && !viewer.appAdmin ? (
              <Link className="navigationBar__inbox" to={"/search"}>
                <SearchIcon className="navigationBar__search" />
              </Link>
            ) : null}
            {viewer && !viewer.appAdmin ? (
              <NotificationsMenu
                viewer={viewer}
                datas={datas}
                viewNotifications={this.viewNotifications}
                redirectToPath={(path) => this.redirectToPath(path)}
                alert={alert}
              />
            ) : null}

            <ProfileMenu
              viewer={viewer}
              getFirstLetter={getFirstLetter}
              account={account}
              isDP={isDP}
              dpStepValue={dpStepValue}
              stepValue={stepValue}
              handlePortal={this.handlePortal}
              redirectToPath={(path) => this.redirectToPath(path)}
              signOut={this.signOut}
              showSideBarMenu={this.props.showSideBarMenu}
            />
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default compose(
  graphql(NAVIGATION_BAR_QUERY, {
    name: "navigationBarQuery",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  }),
  graphql(SIGN_OUT, {
    name: "signOut",
  }),
  graphql(VIEW_NOTIFICATIONS, {
    name: "viewNotifications",
  })
)(NavigationBar);
