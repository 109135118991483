import gql from "graphql-tag";

export const REQUEST_SIGNATURES_ON_DPA = gql`
  mutation(
    $requests: [RequestObject!]!
    $clientMutationId: String
    $customMailText: String
  ) {
    requestSignaturesOnDpa(
      input: {
        requests: $requests
        clientMutationId: $clientMutationId
        customMailText: $customMailText
      }
    ) {
      errors
    }
  }
`;
