import React, { Component, Fragment } from "react";
import { Button, Input } from "reactstrap";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import PlusCircle from "../../components/Icons/PlusCircle";
import { toast } from "react-toastify";
import AddOther from "../../components/Modals/AddOther";
import { graphql } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";
import { ADD_COOKIE_QUERY } from "../../graphql/addCookieQuery";

class AddCookie extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    hideCookie: PropTypes.func.isRequired,
    showCookie: PropTypes.func.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    integration: PropTypes.object,
    editCookie: PropTypes.func.isRequired,
    removeConsent: PropTypes.func.isRequired,
  };

  state = {
    hasCookie:
      this.props.integration && this.props.integration.dataProcessorConsent
        ? true
        : false,
    isChecked: this.props.isCurrent
      ? this.props.integration &&
        this.props.integration.dataProcessorConsent &&
        this.props.integration.dataProcessorConsent.consentName
        ? this.props.integration.dataProcessorConsent.consentName
        : ""
      : null,
    isAddModalOpen: false,
    purpose: this.props.isCurrent
      ? this.props.integration &&
        this.props.integration.dataProcessorConsent &&
        this.props.integration.dataProcessorConsent.purpose
        ? this.props.integration.dataProcessorConsent.purpose
        : ""
      : "",
    id: null,
  };

  showOtherModal = () => {
    this.setState({
      isAddModalOpen: !this.state.isAddModalOpen,
    });
  };

  notify = (text) => toast(text);

  render() {
    const { hasCookie, isChecked } = this.state;
    const { consents } = this.props.data;
    const { integration, isCurrent } = this.props;

    let DPConsent;

    if (integration && integration.dataProcessorConsent && consents) {
      DPConsent = consents.filter(
        (item) =>
          item.categoryName === integration.dataProcessorConsent.consentName
      );
    }

    return (
      <div
        className={`${
          isCurrent ? "-has-border" : ""
        } data-processors__cookie-content`}
      >
        <h4 className="data-processors__add-title">Cookies</h4>
        <p className="data-processors__add-subtitle">
          Do you use cookies from this Data Processor on your website?
        </p>

        <div className="d-flex flex-wrap mt-3 mb-5 justify-content-center">
          <div className="d-flex align-items-center data-processors__keep ml-0">
            <span
              className={`${
                hasCookie ? "-is-active" : ""
              } data-processors__check mr-2`}
              onClick={() => {
                this.setState({ hasCookie: true });
              }}
            />
            Yes
          </div>

          <div className="d-flex align-items-center data-processors__keep">
            <span
              className={`${
                !hasCookie ? "-is-active" : ""
              } data-processors__check mr-2`}
              onClick={() => {
                this.setState({ hasCookie: false });
              }}
            />
            No
          </div>
        </div>

        {hasCookie ? (
          <Fragment>
            <div>
              <p className="data-processors__add-subtitle mb-3">
                Please select the category.
              </p>

              {consents
                ? consents.map((item) => (
                    <div
                      className="d-flex align-items-center mb-3"
                      key={item.id}
                    >
                      <div
                        className="settings__cookie-check"
                        onClick={() => {
                          this.setState({
                            isChecked:
                              isCurrent && integration.dataProcessorConsent
                                ? item.categoryName
                                : item.id,
                            id: isCurrent ? item.id : null,
                          });
                        }}
                      >
                        {(!isCurrent && isChecked === item.id) ||
                        (isCurrent && isChecked === item.categoryName) ||
                        (isCurrent &&
                          !integration.dataProcessorConsent &&
                          isChecked === item.id) ? (
                          <CheckActive />
                        ) : (
                          <CheckDisabled />
                        )}
                      </div>

                      <span className="">{item.categoryName}</span>
                    </div>
                  ))
                : null}

              <div
                className="d-flex align-items-center mb-5 mt-4 ml-2"
                onClick={this.showOtherModal}
              >
                <PlusCircle />
                <p className="data-processor__add-other">Add other</p>
              </div>
            </div>
            <p className="data-processors__add-subtitle mb-3">
              Please fill in the purpose
            </p>

            <Input
              placeholder="Purpose"
              name="purpose"
              value={this.state.purpose}
              className="data-processor__purpose"
              onChange={(e) => this.setState({ purpose: e.target.value })}
            />
          </Fragment>
        ) : null}

        <div className="data-processor__btns">
          {!this.props.isCurrent ? (
            <Fragment>
              <Button
                className="btn--secondary"
                onClick={this.props.hideCookie}
              >
                Cancel
              </Button>
              <Button
                disabled={hasCookie ? !isChecked : false}
                className="btn--primary"
                onClick={() =>
                  !hasCookie
                    ? this.props.hideCookie()
                    : this.props.showCookie(isChecked, this.state.purpose)
                }
              >
                Next
              </Button>
            </Fragment>
          ) : (
            <Button
              className="btn--primary"
              onClick={() =>
                hasCookie
                  ? integration.dataProcessorConsent
                    ? this.props.editCookie(
                        this.state.id
                          ? this.state.id
                          : DPConsent
                          ? DPConsent[0].id
                          : "",
                        this.state.purpose,
                        integration.dataProcessorConsent.id
                      )
                    : this.props.showCookie(isChecked, this.state.purpose)
                  : this.props.removeConsent(
                      integration.dataProcessorConsent.id
                    )
              }
            >
              Save changes
            </Button>
          )}
        </div>

        <AddOther
          showAddOther={this.showOtherModal}
          isAddOtherVisible={this.state.isAddModalOpen}
          refetchConsent={() => this.props.data.refetch()}
          notify={(msg) => this.notify(msg)}
        />
      </div>
    );
  }
}

export default graphql(ADD_COOKIE_QUERY, {
  options: () => ({
    fetchPolicy: "no-cache",
  }),
})(AddCookie);
