import PropTypes from "prop-types";
import React from "react";
import Moment from "react-moment";

function PageRightMenuItem(props) {
  const {
    isCurrent,
    date,
    id,
    publicationId,
    index,
    showCurrentVersion,
    resetActivePublicationId,
    getActivePublicationId,
    showOtherVersion,
  } = props;

  return (
    <div
      className={`${
        !isCurrent && publicationId === id ? "-is-active" : ""
      } page-right-menu__item`}
      onClick={
        !isCurrent && publicationId === id
          ? () => {}
          : !isCurrent
          ? () => {
              showOtherVersion(id);
              getActivePublicationId(id, index);
            }
          : () => {
              showCurrentVersion();
              resetActivePublicationId();
            }
      }
    >
      <p className="page-right-menu__item-date">
        <Moment format="MMMM D YYYY, hh:mm A">{date}</Moment>
      </p>
      <p
        className={`${
          isCurrent ? "-is-current" : ""
        } page-right-menu__item-version`}
      >
        <span
          onClick={
            !isCurrent && publicationId === id
              ? () =>
                  props.useVersion(
                    props.activePublicationId,
                    props.contentPageId
                  )
              : () => {}
          }
        >
          {isCurrent ? "Current version" : "Use this version"}
        </span>
        {!isCurrent && publicationId === id ? (
          <span className="page-right-menu__item-changes">
            <span className="page-right-menu__item-dot" /> Changes
          </span>
        ) : null}
      </p>
    </div>
  );
}

export default PageRightMenuItem;
PageRightMenuItem.propTypes = {
  isCurrent: PropTypes.bool,
  date: PropTypes.string,
  id: PropTypes.string,
  publicationId: PropTypes.string,
  index: PropTypes.number,
  resetActivePublicationId: PropTypes.func,
  showCurrentVersion: PropTypes.func,
  showOtherVersion: PropTypes.func,
  getActivePublicationId: PropTypes.func,
  useVersion: PropTypes.func,
  activePublicationId: PropTypes.string,
  contentPageId: PropTypes.string,
};
