import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Form,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import MdClose from "react-icons/lib/md/close";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ADD_USER } from "../../graphql/addUser";
import { EDIT_USER } from "../../graphql/editUser";
import ReactFilestack from "filestack-react";
import "./modals.scss";

class AddUserModal extends Component {
  static propTypes = {
    showAddModal: PropTypes.func.isRequired,
    isAddModalVisible: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
    viewer: PropTypes.object,
    isAdmin: PropTypes.bool,
    user: PropTypes.object,
    addUser: PropTypes.func.isRequired,
    editUser: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
    refetchAccount: PropTypes.func.isRequired,
  };

  state = {
    fullName: "",
    email: "",
    jobTitle: "",
    role: "",
    phone: "",
    formErrors: {},
    files: [],
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("fullName"),
      email = formData.get("email"),
      role = formData.get("role"),
      jobTitle = formData.get("jobTitle"),
      phone = formData.get("phone").trim();

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!jobTitle) {
      hasFormErrors = true;
      formErrors.jobTitle = true;
    }

    if (!role) {
      hasFormErrors = true;
      formErrors.role = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.isEdit) {
      this.props
        .editUser({
          variables: {
            fullName: name,
            email,
            jobTitle,
            role,
            avatar: this.state.files.length
              ? this.state.files[0].url
                ? this.state.files[0].url
                : null
              : this.props.user.avatar,
            phone,
            id: this.props.user.id,
            clientMutationId: this.props.user.id,
          },
        })
        .then(() => {
          this.props.notify("User info saved!");
          this.props.refetchAccount();
        })
        .then(() => {
          this.setState({
            fullName: "",
            email: "",
            jobTitle: "",
            role: "",
            phone: "",
            formErrors: {},
            files: [],
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.notify("User info was not saved!");
        });
    } else {
      this.props
        .addUser({
          variables: {
            fullName: name,
            email,
            jobTitle,
            role,
            avatar: this.state.files.length ? this.state.files[0].url : "",
            phone,
          },
        })
        .then(() => {
          this.props.notify("User added!");
          this.props.refetchAccount();
        })
        .then(() => {
          this.setState({
            fullName: "",
            email: "",
            jobTitle: "",
            role: "",
            phone: "",
            formErrors: {},
            files: [],
          });
        })
        .catch((error) => {
          console.log(error);
          this.props.notify(error.graphQLErrors[0].message);
        });
    }

    this.props.showAddModal();
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  clearForm = () => {
    this.setState({
      fullName: "",
      email: "",
      jobTitle: "",
      role: "",
      phone: "",
      formErrors: {},
      files: [],
    });
  };

  render() {
    const { isEdit, user, viewer, isAdmin } = this.props;
    const { fullName, email, jobTitle, phone, files } = this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isAddModalVisible}
          toggle={() => {
            this.props.showAddModal();
            this.clearForm();
          }}
          className="modal-add-user"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={() => {
                this.props.showAddModal();
                this.clearForm();
              }}
            />

            <h4 className="modal-add-user__title">
              {isEdit ? "Edit user" : "Add user"}
            </h4>
            <Form
              onSubmit={this.handleSubmit}
              className="d-flex justify-content-center"
            >
              <div
                className={`${isEdit ? "-change" : ""} modal-add-user__avatar`}
              >
                <div className="position-relative">
                  {(files.length && !isEdit) || (files.length && isEdit) ? (
                    files.map((file, i) => (
                      <div key={i} className="modal-add-user__avatar-container">
                        <img src={file.url} alt="avatar" />
                      </div>
                    ))
                  ) : isEdit && user.avatar ? (
                    <div className="modal-add-user__avatar-container">
                      <img src={user.avatar} alt="avatar" />
                    </div>
                  ) : (
                    <span className="image-upload-placeholder" />
                  )}

                  <ReactFilestack
                    apikey={"AJNM9qOpGRljTn17sgxrfz"}
                    componentDisplayMode={{
                      type: "link",
                      customText: <span />,
                      customClass: "show-upload",
                    }}
                    onSuccess={this.onFiles}
                    actionOptions={{
                      accept: ["image/*"],
                      maxFiles: 1,
                    }}
                  />
                </div>

                <span className="mt-3">
                  {isEdit ? "CHANGE PHOTO" : "ADD PHOTO"}
                </span>
              </div>
              <div className="modal-add-user__form-container">
                <FormGroup className="mb-4">
                  <Input
                    type="text"
                    name="fullName"
                    defaultValue={isEdit ? user.name : fullName}
                    className="mb-0"
                    placeholder="Full name"
                    onChange={(e) =>
                      this.setState({ fullName: e.target.value })
                    }
                    invalid={this.state.formErrors.name}
                  />
                  <FormFeedback>Please provide user's full name</FormFeedback>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Input
                    type="text"
                    name="jobTitle"
                    placeholder="Job title"
                    defaultValue={isEdit ? user.jobTitle : jobTitle}
                    className="mb-0"
                    invalid={this.state.formErrors.jobTitle}
                    onChange={(e) =>
                      this.setState({ jobTitle: e.target.value })
                    }
                  />
                  <FormFeedback>Please provide user's job title</FormFeedback>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Input
                    type="text"
                    name="email"
                    placeholder="E-mail"
                    defaultValue={isEdit ? user.email : email}
                    className="mb-0"
                    invalid={this.state.formErrors.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <FormFeedback>Please provide user's email</FormFeedback>
                </FormGroup>

                <Input
                  type="text"
                  name="phone"
                  placeholder="Phone number (optional)"
                  defaultValue={isEdit ? user.phoneNumber : phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />

                <FormGroup className="position-relative mb-4">
                  <Input
                    type="select"
                    name="role"
                    className="mb-0"
                    invalid={this.state.formErrors.role}
                    defaultValue={isEdit ? user.role : "default"}
                    onChange={(e) => this.setState({ role: e.target.value })}
                  >
                    <option value="default" disabled>
                      User type role
                    </option>
                    {(viewer && viewer.role === "ADMIN") ||
                    (viewer && viewer.appAdmin) ||
                    isAdmin ? (
                      <option value="ADMIN">Admin</option>
                    ) : null}
                    <option value="MANAGER">Manager</option>
                    <option value="DATA_MANAGER">Data Manager</option>
                  </Input>
                  <FormFeedback>Please provide user's Role</FormFeedback>
                  <FaCaret className="position-absolute modal-add-user__input-icon" />
                </FormGroup>

                <div className="modal-add-user__btns">
                  <Button
                    className="btn--secondary modal-add-user__cancel"
                    onClick={() => {
                      this.props.showAddModal();
                      this.clearForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="btn--primary modal-add-user__add"
                  >
                    {isEdit ? "Save changes" : "Add user"}
                  </Button>
                </div>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(ADD_USER, {
    name: "addUser",
  }),
  graphql(EDIT_USER, {
    name: "editUser",
  })
)(AddUserModal);
