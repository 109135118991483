import React, { Component } from "react";
import PropTypes from "prop-types";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import EditCookiesModal from "../../components/Modals/EditCookies";
import PlusCircle from "../../components/Icons/PlusCircle";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import TrashIcon from "../../components/Icons/TrashIcon";
import DeleteCategory from "../../components/Modals/DeleteCategory";

export default class CookieItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    enabled: PropTypes.bool,
    manageable: PropTypes.bool,
    smallDescription: PropTypes.string,
    completeDescription: PropTypes.string,
    isOther: PropTypes.bool,
    id: PropTypes.string,
    dp: PropTypes.string,
    deleteCookie: PropTypes.func,
    saveChanges: PropTypes.func,
    refetchConsent: PropTypes.func,
    notify: PropTypes.func,
  };

  state = {
    isChecked: this.props.enabled,
    isEditModalVisible: false,
    isInitialCheck: true,
    isDeleteModalOpen: false,
  };

  checkItem = (value) => {
    this.setState({
      isChecked: value,
    });
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: !this.state.isDeleteModalOpen,
    });
  };

  showEditModal = () => {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
    });
  };

  deleteCookie = () => {
    this.props.deleteCookie(this.props.id);
  };

  render() {
    const {
      title,
      text,
      manageable,
      smallDescription,
      completeDescription,
      isOther,
      id,
    } = this.props;
    const { isChecked, isEditModalVisible } = this.state;

    return (
      <div>
        {!isOther ? (
          <div
            className={`${
              isChecked ? "-is-active" : ""
            } settings__cookie justify-content-between`}
          >
            <div className="d-flex align-items-center">
              <div
                className="settings__cookie-check"
                onClick={() => {
                  this.setState({
                    isChecked: !this.state.isChecked,
                  });
                  this.props.saveChanges(!isChecked, id);
                }}
              >
                {isChecked ? <CheckActive /> : <CheckDisabled />}
              </div>

              <div className="d-flex flex-column">
                <span className="settings__cookie-title">{title}</span>

                {isChecked ? (
                  <span className="settings__cookie-text">{text}</span>
                ) : null}
              </div>
            </div>

            <div className="d-flex align-items-center">
              {title !== "Essential" ? (
                <TrashIcon
                  className="mr-2 delete-cookie"
                  style={{ cursor: "pointer" }}
                  color="#969696"
                  onClick={this.openDeleteModal}
                />
              ) : null}

              {isChecked ? (
                <div
                  className="d-flex align-items-center settings__cookie-edit"
                  onClick={this.showEditModal}
                >
                  Edit&nbsp;{" "}
                  <ArrowCircleRight
                    color={"#EBA601"}
                    className="settings__cookie-icon"
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center mb-5 mt-4"
            onClick={this.showEditModal}
          >
            <PlusCircle />
            <p className="settings__add-text">Add another</p>
          </div>
        )}

        <DeleteCategory
          isDeleteModalOpen={this.state.isDeleteModalOpen}
          openDeleteModal={this.openDeleteModal}
          isCookie={true}
          delete={this.deleteCookie}
        />

        <EditCookiesModal
          showEditModal={this.showEditModal}
          categoryName={title}
          dp={this.props.dp}
          manageable={manageable}
          isEditModalVisible={isEditModalVisible}
          smallDescription={smallDescription}
          completeDescription={completeDescription}
          title={title}
          id={id}
          isOther={this.props.isOther}
          refetchConsent={this.props.refetchConsent}
          notify={this.props.notify}
        />
      </div>
    );
  }
}
