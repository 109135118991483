import gql from "graphql-tag";

export const TOGGLE_REQUEST_ARCHIVE = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleRequestArchive(input: { id: $id, clientMutationId: $clientMutationId }) {
      errors
      request {
        id
        archived
        status
      }
    }
  }
`;
