import PropTypes from "prop-types";
import React from "react";

const CheckCircle = props => (
    <svg
        height={40}
        viewBox='0 0 40 40'
        width={40}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-3---Compliance" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.2---PIA---overview" transform="translate(-173.000000, -427.000000)" fill="#1DDCC0" fillRule="nonzero">
                <g id="Group-3" transform="translate(149.000000, 354.000000)">
                    <g id="check-circle" transform="translate(24.000000, 73.000000)">
                        <path d="M25.44,13.58 L16.86,22.18 L13.56,18.88 C13.0712202,18.3092477 12.3037632,18.0606361 11.5731621,18.2363797 C10.8425609,18.4121233 10.2721233,18.9825609 10.0963797,19.7131621 C9.92063611,20.4437632 10.1692477,21.2112202 10.74,21.7 L15.44,26.42 C15.8174377,26.794373 16.328394,27.0030735 16.86,27 C17.3846798,26.9977908 17.8874787,26.7894883 18.26,26.42 L28.26,16.42 C28.6386253,16.0444668 28.8515966,15.5332751 28.8515966,15 C28.8515966,14.4667249 28.6386253,13.9555332 28.26,13.58 C27.4799251,12.8045528 26.2200749,12.8045528 25.44,13.58 Z M20,8.8817842e-16 C8.954305,-4.4408921e-16 8.8817842e-16,8.954305 0,20 C-8.8817842e-16,31.045695 8.954305,40 20,40 C31.045695,40 40,31.045695 40,20 C40,14.6956702 37.8928632,9.60859195 34.1421356,5.85786438 C30.3914081,2.10713681 25.3043298,4.4408921e-16 20,8.8817842e-16 Z M20,36 C11.163444,36 4,28.836556 4,20 C4,11.163444 11.163444,4 20,4 C28.836556,4 36,11.163444 36,20 C36,24.2434638 34.3142906,28.3131264 31.3137085,31.3137085 C28.3131264,34.3142906 24.2434638,36 20,36 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CheckCircle.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default CheckCircle;