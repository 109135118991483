import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import PagesCardItem from "./PagesCardItem";

function PagesCard(props) {
  const { pages } = props;

  return (
    <div className="pages-card container">
      <h4 className="pages-card__title">Pages</h4>
      <p className="pages-card__text">
        Edit the content of the pages templates available or create a new page.
        You can also choose to make them visible or not for users.
      </p>
      <Link
        to={{ pathname: "/pages", state: { isAdmin: true, newPage: true } }}
        className="btn--primary pages-card__add"
      >
        Add page
      </Link>

      <Row className="pages-card__items">
        {pages
          .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
          .map((page, i) => (
            <PagesCardItem key={page.id} page={page} index={i} />
          ))}
      </Row>
    </div>
  );
}

export default PagesCard;
PagesCard.propTypes = {
  pages: PropTypes.array.isRequired,
};
