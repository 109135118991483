import PropTypes from "prop-types";
import React from "react";

const HistoryIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Expanded-side-navigation-bar" transform="translate(-29.000000, -372.000000)" fill="#DFE4EE" fillRule="nonzero">
                <g id="history" transform="translate(29.000000, 372.000000)">
                    <path d="M10,-4.4408921e-16 C7.43638565,0.00731219276 4.97349028,0.998913963 3.12,2.77 L3.12,1 C3.12,0.44771525 2.67228475,-4.4408921e-16 2.12,-4.4408921e-16 C1.56771525,-4.4408921e-16 1.12,0.44771525 1.12,1 L1.12,5.5 C1.12,6.05228475 1.56771525,6.5 2.12,6.5 L6.62,6.5 C7.17228475,6.5 7.62,6.05228475 7.62,5.5 C7.62,4.94771525 7.17228475,4.5 6.62,4.5 L4.22,4.5 C6.87217657,1.72846915 11.1183628,1.22963525 14.3406536,3.31104482 C17.5629444,5.39245439 18.8542391,9.46817397 17.4184148,13.0253965 C15.9825904,16.582619 12.2238429,18.6199579 8.45955631,17.8813308 C4.69526969,17.1427037 1.98512073,13.8360399 2,10 C2,9.44771525 1.55228475,9 1,9 C0.44771525,9 8.8817842e-16,9.44771525 8.8817842e-16,10 C8.8817842e-16,15.5228475 4.4771525,20 10,20 C15.5228475,20 20,15.5228475 20,10 C20,4.4771525 15.5228475,-4.4408921e-16 10,-4.4408921e-16 Z M10,6 C9.44771525,6 9,6.44771525 9,7 L9,10 C9,10.5522847 9.44771525,11 10,11 L12,11 C12.5522847,11 13,10.5522847 13,10 C13,9.44771525 12.5522847,9 12,9 L11,9 L11,7 C11,6.44771525 10.5522847,6 10,6 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
);

HistoryIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default HistoryIcon;