import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Alert,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import DpoModal from "../../components/Modals/DpoModal";
import PreviewModal from "../../components/Modals/PreviewModal";
import { ToastContainer, toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import SyncLoader from "react-spinners/SyncLoader";
import { COMPLIANCE_DATA_OFFICER_QUERY } from "../../graphql/complianceDataOfficer";
import PublishLegalSiteModal from "../../components/Modals/PublishLegalSite";
import { EDIT_DPO } from "../../graphql/editDataProtectionOfficer";
import { PUBLISH_DPO } from "../../graphql/publishDataProtectionOfficer";
import ReactFilestack from "filestack-react";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class ComplianceDataOfficer extends Component {
  static propTypes = {
    data: PropTypes.object,
    editDpo: PropTypes.func,
    publishDpo: PropTypes.func,
    showDataOfficer: PropTypes.func,
    getInitialPage: PropTypes.func,
  };

  state = {
    isYes: false,
    isDpoModalVisible: false,
    isPreviewModalVisible: false,
    contactBg: null,
    btnBg: null,
    welcomeBg: null,
    bg: null,
    titleColor: null,
    titleSize: null,
    bodyColor: null,
    bodySize: null,
    subtitleColor: null,
    subtitleSize: null,
    btnColor: null,
    siteBtnSize: null,
    titleFont: null,
    bodyFont: null,
    footerFont: null,
    buttonFont: null,
    isPublishLegalSiteVisible: false,
    description: null,
    isInitialShow: true,
    files: [],
    formErrors: {},
    name: null,
    email: null,
    company: null,
    phone: null,
  };

  showPreviewModal = () => {
    this.setState({
      isPreviewModalVisible: !this.state.isPreviewModalVisible,
    });
  };

  showDpoModal = () => {
    this.setState({
      isDpoModalVisible: !this.state.isDpoModalVisible,
    });
  };

  showPublishLegalSite = () => {
    this.setState({
      isPublishLegalSiteVisible: !this.state.isPublishLegalSiteVisible,
    });
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  notify = (text) => toast(text);

  publishDpo = () => {
    this.props
      .publishDpo()
      .then(() => {
        this.showPublishLegalSite();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Legalsite didn't published");
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      email = formData.get("email"),
      company = formData.get("company"),
      description = formData.get("description"),
      phone = formData.get("phone").trim(),
      avatar =
        this.state.files && this.state.files.length
          ? this.state.files[0].url
            ? this.state.files[0].url
            : ""
          : this.props.data &&
            this.props.data.dataProtectionOfficer &&
            this.props.data.dataProtectionOfficer.avatar
          ? this.props.data.dataProtectionOfficer.avatar
          : "";

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!company) {
      hasFormErrors = true;
      formErrors.company = true;
    }

    if (!description) {
      hasFormErrors = true;
      formErrors.description = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editDpo({
        variables: {
          name,
          email,
          phoneNumber: phone,
          company,
          description,
          avatar,
          show: this.state.isInitialShow
            ? this.props.data.dataProtectionOfficer &&
              this.props.data.dataProtectionOfficer.show
              ? this.props.data.dataProtectionOfficer.show
              : false
            : this.state.isYes,
        },
      })
      .then(() => {
        this.props.data.refetch();
        this.notify("DPO info saved!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("DPO info was not saved!");
      });
  };

  render() {
    const {
      isYes,
      isDpoModalVisible,
      contactBg,
      btnBg,
      welcomeBg,
      bg,
      titleColor,
      titleSize,
      bodyColor,
      bodySize,
      subtitleColor,
      subtitleSize,
      btnColor,
      siteBtnSize,
      titleFont,
      bodyFont,
      footerFont,
      buttonFont,
      isInitialShow,
      files,
    } = this.state;
    const {
      legalsite,
      loading,
      error,
      dataProtectionOfficer,
      legalsiteSettings,
      pages,
      dontSellMyDataButton,
      account,
    } = this.props.data;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="compliance-data -officer container">
        <Row className="d-flex justify-content-start">
          <Col md={6} lg={9}>
            <p
              onClick={this.props.getInitialPage}
              className="compliance-breach__back"
            >
              <MdArrowLeft
                size={25}
                className="customize-menu__back position-relative"
                color="#7450c8"
              />
              &nbsp;Back to Compliance
            </p>
            <h5 className="compliance-data__title">
              Data Protection Officer (DPO)
            </h5>
            <p className="compliance-data__text">
              Your company is required to appoint a Data Protection Officer that
              will be responsible to ensure your company’s compliance with GDPR
              and other data protection laws requirements. A DPO can be an
              existing employee or externally appointed and will act as the
              contact point for the supervisory authority, train the
              organization employees on compliance requirements and conduct
              regular assessments and audits.
            </p>
          </Col>
          <Col md={6} lg={3} className="p-0">
            <div className="compliance-data__suppliers">
              <Button
                className="btn--primary compliance-data__suppliers-btn"
                disabled={
                  (dataProtectionOfficer &&
                    dataProtectionOfficer.show === false &&
                    isInitialShow) ||
                  (!isInitialShow && !isYes) ||
                  !dataProtectionOfficer
                }
                onClick={this.publishDpo}
              >
                Publish LegalSite
              </Button>
              <Button
                className="btn--secondary compliance-data__suppliers-btn"
                onClick={this.showDpoModal}
              >
                Preview LegalSite
              </Button>
            </div>
          </Col>
        </Row>

        <div className="compliance-data__officer">
          <p className="compliance-data__officer-title">
            Data Protection Officer
          </p>

          <Form
            onSubmit={this.handleSubmit}
            className="d-flex flex-wrap compliance-data__officer-form-container"
          >
            <div className="compliance-data__officer-avatar">
              <div className="position-relative">
                {files.length ? (
                  files.map((file, i) => (
                    <div
                      key={i}
                      className="compliance-data__officer-avatar-container"
                    >
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : dataProtectionOfficer && dataProtectionOfficer.avatar ? (
                  <div className="compliance-data__officer-avatar-container">
                    <img src={dataProtectionOfficer.avatar} alt="avatar" />
                  </div>
                ) : (
                  <span className="image-upload-placeholder" />
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span />,
                    customClass: "show-upload",
                  }}
                  onSuccess={this.onFiles}
                />
              </div>

              <span className="mt-3">CHANGE PHOTO</span>
            </div>

            <div className="compliance-data__officer-form flex-wrap">
              <div className="d-flex w-100">
                <FormGroup className="mb-4">
                  <Input
                    className="compliance-data__officer-input mb-0"
                    defaultValue={
                      dataProtectionOfficer ? dataProtectionOfficer.name : null
                    }
                    name="name"
                    type="text"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    invalid={this.state.formErrors.name}
                    placeholder="Full name"
                  />
                  <FormFeedback className="ml-3">
                    Please provide the DPO name
                  </FormFeedback>
                </FormGroup>

                <FormGroup className="mb-4">
                  <Input
                    className="compliance-data__officer-input mb-0"
                    defaultValue={
                      dataProtectionOfficer ? dataProtectionOfficer.email : null
                    }
                    onChange={(e) => this.setState({ email: e.target.value })}
                    type="text"
                    name="email"
                    invalid={this.state.formErrors.email}
                    placeholder="E-mail"
                  />
                  <FormFeedback className="ml-3">
                    Please provide the DPO's email
                  </FormFeedback>
                </FormGroup>
              </div>

              <div className="d-flex w-100">
                <FormGroup className="mb-4">
                  <Input
                    className="compliance-data__officer-input mb-0"
                    type="text"
                    onChange={(e) => this.setState({ company: e.target.value })}
                    name="company"
                    invalid={this.state.formErrors.company}
                    defaultValue={
                      dataProtectionOfficer
                        ? dataProtectionOfficer.company
                        : null
                    }
                    placeholder="Company"
                  />
                  <FormFeedback className="ml-3">
                    Please provide the DPO's company
                  </FormFeedback>
                </FormGroup>
                <Input
                  className="compliance-data__officer-input"
                  name="phone"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  defaultValue={
                    dataProtectionOfficer
                      ? dataProtectionOfficer.phoneNumber
                      : null
                  }
                  type="text"
                  placeholder="Phone number (optional)"
                />
              </div>

              <FormGroup className="mb-4">
                <Input
                  className="compliance-data__officer-description mt-0 mb-0"
                  type="textarea"
                  invalid={this.state.formErrors.description}
                  name="description"
                  placeholder="Description"
                  value={
                    this.state.description !== null
                      ? this.state.description
                      : dataProtectionOfficer
                      ? dataProtectionOfficer.description
                      : "This is our Data Protection Officer. He is responsible to ensure our company’s compliance with GDPR and other data protection laws requirements, act as the contact point for the supervisory authority, train our organization employees on compliance requirements and conduct regular assessments and audits. We have appointed a DPO to meet the requirements of the GDPR set out in Articles 37-39."
                  }
                  onChange={(e) => {
                    this.setState({ description: e.target.value });
                  }}
                />
                <FormFeedback className="ml-3">
                  Please provide the DPO's description
                </FormFeedback>
              </FormGroup>

              <div className="compliance-data__officer-checks">
                <p className="compliance-data__officer-text ml-3">
                  Do you want to display your DPO information on your LegalSite?
                </p>

                <div className="d-flex ml-3">
                  <div className="d-flex align-items-center compliance-data__officer-check-container mr-5">
                    <span
                      className={`${
                        (dataProtectionOfficer &&
                          dataProtectionOfficer.show === true &&
                          isInitialShow) ||
                        (!isInitialShow && isYes) ||
                        (!dataProtectionOfficer && isYes)
                          ? "-is-active"
                          : ""
                      } compliance-data__officer-check mr-2`}
                      onClick={() =>
                        this.setState({
                          isYes: true,
                          isInitialShow: false,
                          isNo: false,
                        })
                      }
                    />
                    Yes
                  </div>

                  <div className="d-flex align-items-center compliance-data__officer-check-container">
                    <span
                      className={`${
                        (dataProtectionOfficer &&
                          dataProtectionOfficer.show === false &&
                          isInitialShow) ||
                        (!isInitialShow && !isYes) ||
                        (!dataProtectionOfficer && !isYes)
                          ? "-is-active"
                          : ""
                      } compliance-data__officer-check mr-2`}
                      onClick={() =>
                        this.setState({
                          isNo: true,
                          isInitialShow: false,
                          isYes: false,
                        })
                      }
                    />
                    No
                  </div>
                </div>
              </div>

              <Button
                className="btn--primary compliance-data__officer-btn ml-3"
                type="submit"
              >
                Save
              </Button>
            </div>
          </Form>

          <DpoModal
            showDpoModal={this.showDpoModal}
            isDpoModalVisible={isDpoModalVisible}
            dataProtectionOfficer={
              dataProtectionOfficer ? dataProtectionOfficer : null
            }
            account={account}
            name={this.state.name}
            description={this.state.description}
            phone={this.state.phone}
            company={this.state.company}
            email={this.state.email}
            legalsite={legalsite}
            legalsiteSettings={legalsiteSettings}
            pages={pages}
            dontSellMyDataButton={dontSellMyDataButton}
            avatar={
              this.state.files.length
                ? this.state.files[0].url
                  ? this.state.files[0].url
                  : null
                : null
            }
          />

          <PreviewModal
            isSiteCustomization={true}
            showPreviewModal={this.showPreviewModal}
            isPreviewModalVisible={this.state.isPreviewModalVisible}
            contactBg={contactBg ? contactBg : legalsite?.backgroundColor}
            btnBg={btnBg ? btnBg : legalsite?.backgroundButtonsColor}
            welcomeBg={welcomeBg ? welcomeBg : legalsite?.background1}
            bg={bg ? bg : legalsite?.background2}
            titleSiteColor={titleColor ? titleColor : legalsite?.titleColor}
            titleSiteSize={titleSize ? titleSize : legalsite?.titleSize}
            bodySiteColor={bodyColor ? bodyColor : legalsite?.bodyColor}
            bodySiteSize={bodySize ? bodySize : legalsite?.bodySize}
            subtitleColor={
              subtitleColor ? subtitleColor : legalsite?.subtitleColor
            }
            subtitleSize={subtitleSize ? subtitleSize : legalsite?.subtitleSize}
            siteBtnColor={btnColor ? btnColor : legalsite?.buttonColor}
            siteBtnSize={siteBtnSize ? siteBtnSize : legalsite?.buttonSize}
            siteTitleFont={titleFont ? titleFont : legalsite?.titleFont}
            siteBodyFont={bodyFont ? bodyFont : legalsite?.bodyFont}
            siteButtonFont={footerFont ? footerFont : legalsite?.buttonFont}
            dataProtectionOfficer={dataProtectionOfficer}
            siteSubtitleFont={buttonFont ? buttonFont : legalsite?.subtitleFont}
            isCDO={true}
            legalsiteSettings={legalsiteSettings}
          />

          <PublishLegalSiteModal
            showPublishLegalSite={this.showPublishLegalSite}
            isPublishLegalSiteVisible={this.state.isPublishLegalSiteVisible}
            showPreviewModal={this.showPreviewModal}
            showDataOfficer={this.props.showDataOfficer}
            subdomain={
              legalsiteSettings && legalsiteSettings.subdomain
                ? legalsiteSettings.subdomain
                : ""
            }
          />

          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            newestOnTop={false}
            closeButton={false}
            closeOnClick
            rtl={false}
            draggable
            hideProgressBar
          />
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(COMPLIANCE_DATA_OFFICER_QUERY),
  graphql(EDIT_DPO, {
    name: "editDpo",
  }),
  graphql(PUBLISH_DPO, {
    name: "publishDpo",
  })
)(ComplianceDataOfficer);
