import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import Moment from "react-moment";

function ComplianceMenu(props) {
  return (
    <div className="compliance-menu">
      <Button className="btn--primary mb-2" onClick={props.showSurvey}>
        Save and close
      </Button>
      <Button
        className="btn--secondary mb-2"
        onClick={() => {
          props.showPreviewCompliance();
          props.refetchData();
        }}
      >
        Preview
      </Button>

      <p className="compliance-menu__last-edit">
        Last edit was made{" "}
        <Moment fromNow>{props.privacyImpactAssessment.lastUpdated}</Moment>
      </p>

      <div
        className="w-100 compliance-menu__items"
        onClick={() =>
          props.downloadBreach(
            props.privacyImpactAssessment.downloadUrl
              ? props.privacyImpactAssessment.downloadUrl
              : null,
            "PIA_report"
          )
        }
      >
        <p className="compliance-menu__download">
          <DownloadIcon color="#7450c8" /> Download
        </p>
      </div>
    </div>
  );
}

export default ComplianceMenu;
ComplianceMenu.propTypes = {
  showSurvey: PropTypes.func,
  showPreviewCompliance: PropTypes.func,
  refetchData: PropTypes.func,
  downloadBreach: PropTypes.func,
  privacyImpactAssessment: PropTypes.object,
};
