import React, { Component } from "react";
import Image from "../../images/Compliance/Image.svg";

export default class EmptyAnnouncements extends Component {
  render() {
    return (
      <div className="empty-announcements container">
        <div className="empty-announcements__content">
          <img className="empty-announcements__img" src={Image} alt="empty" />
          <h5 className="empty-announcements__title">
            We don't have any announcements yet
          </h5>
          <p className="empty-announcements__text">
            When there is any news with LegalSite, we'll post them here.
          </p>
        </div>
      </div>
    );
  }
}
