import React, { Component } from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import MdArrow from "react-icons/lib/ti/arrow-right";
import AddDPStepOne from "./AddDPStepOne";
import AddDPStepTwo from "./AddDPStepTwo";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { CREATE_DP } from "../../graphql/createDataProcessor";
import { CREATE_INTEGRATION } from "../../graphql/createIntegration";
import { ToastContainer, toast } from "react-toastify";

class AddDataProcessor extends Component {
  static propTypes = {
    addDataProcessor: PropTypes.func.isRequired,
    createDataProcessor: PropTypes.func.isRequired,
    createIntegration: PropTypes.func.isRequired,
    refetchDP: PropTypes.func.isRequired,
  };

  state = {
    isAddNew: true,
    step: 1,
    formErrors: {},
    isRegisterNew: false,
    registerStep: 0,
    createdDPId: null,
    DPName: null,
  };

  handleStep1 = (e) => {
    e.preventDefault();

    let formData = e.target;
    const company = formData["company"].value,
      email = formData["email"].value,
      website = formData["website"].value;

    let hasFormErrors = false;
    let formErrors = {};

    if (!company) {
      hasFormErrors = true;
      formErrors.company = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!website) {
      hasFormErrors = true;
      formErrors.website = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createDataProcessor({
        variables: {
          companyName: company,
          email,
          website,
        },
      })
      .then(({ data }) => {
        this.setState({
          createdDPId: data.createDataProcessor.dataProcessor.id,
          DPName: data.createDataProcessor.dataProcessor.companyName,
          step: 2,
        });

        formData.reset();
      })
      .catch((e) => {
        console.log(e.graphQLErrors[0].message);
        this.notify(e.graphQLErrors[0].message);
      });
  };

  notify = (text) => toast(text);

  handleStep2 = (e) => {
    e.preventDefault();

    let formData = e.target;
    const companyName = formData["companyName"].value,
      contact = formData["contact"].value,
      companyEmail = formData["companyEmail"].value;

    let hasFormErrors = false;
    let formErrors = {};

    if (!companyName) {
      hasFormErrors = true;
      formErrors.companyName = true;
    }

    if (!contact) {
      hasFormErrors = true;
      formErrors.contact = true;
    }

    if (!companyEmail) {
      hasFormErrors = true;
      formErrors.companyEmail = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createIntegration({
        variables: {
          dataProcessorId: this.state.createdDPId,
          companyName,
          email: companyEmail,
          contactName: contact,
        },
      })
      .then(() => {
        formData.reset();

        this.setState({
          step: 3,
        });

        this.props.refetchDP();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Data Processor couldn't be added!");
      });
  };

  render() {
    const { isAddNew, step, isRegisterNew, registerStep } = this.state;
    return (
      <div className="data-processors container">
        {isAddNew && step === 1 && !isRegisterNew && registerStep === 0 ? (
          <AddDPStepOne
            handleStep1={this.handleStep1}
            companyErrors={this.state.formErrors.company}
            emailErrors={this.state.formErrors.email}
            websiteErrors={this.state.formErrors.website}
            addDataProcessor={this.props.addDataProcessor}
          />
        ) : isAddNew && step === 2 && !isRegisterNew && registerStep === 0 ? (
          <AddDPStepTwo
            handleStep2={this.handleStep2}
            companyErrors={this.state.formErrors.companyName}
            contactErrors={this.state.formErrors.contact}
            emailErrors={this.state.formErrors.companyEmail}
            addDataProcessor={this.props.addDataProcessor}
          />
        ) : (
          <div className="data-processors__added-content">
            <p className="data-processors__add-title">
              {this.state.DPName} was added as a Data Processor!
            </p>
            <p className="data-processors__add-subtitle">
              {this.state.DPName} was successfully added as a Data Processor. We
              have configured an Integration, so now you can save even more time
              with privacy related procedures.{" "}
            </p>
            <Button
              className="bg-transparent data-processors__back"
              onClick={this.props.addDataProcessor}
            >
              Back to Data Processors <MdArrow color={"#7450c8"} size={25} />
            </Button>
          </div>
        )}

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(CREATE_DP, {
    name: "createDataProcessor",
  }),
  graphql(CREATE_INTEGRATION, {
    name: "createIntegration",
  })
)(AddDataProcessor);
