import gql from "graphql-tag";

export const REMOVE_CLIENT = gql`
  mutation($accountIds: [ID]!, $clientMutationId: String) {
    removeClients(
      input: { accountIds: $accountIds, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
