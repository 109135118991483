import PropTypes from "prop-types";
import React, { Component } from "react";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import ViewIcon from "../../components/Icons/ViewIcon";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import { graphql } from "@apollo/client/react/hoc";
import ReactTable from "react-table-6";
import Moment from "react-moment";
import "react-table-6/react-table.css";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { Button } from "reactstrap";

class UsersTable extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool.isRequired,
    showUser: PropTypes.func,
    user: PropTypes.object,
    isCompliance: PropTypes.bool,
    isDP: PropTypes.bool,
    isImport: PropTypes.bool,
    checkItem: PropTypes.func,
    generateReport: PropTypes.func,
    index: PropTypes.number,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userCompany: PropTypes.string,
    userId: PropTypes.string,
    users: PropTypes.array,
    changePage: PropTypes.func,
    numberOfPages: PropTypes.number,
    pageNumber: PropTypes.number,
    download: PropTypes.func,
  };

  state = {
    isChecked: false,
    report: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === false
    ) {
      this.setState({
        isChecked: false,
      });
    }

    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === true
    ) {
      this.setState({
        isChecked: true,
      });
    }
  }

  handleCheck = (user) => {
    if (
      this.props.userName.includes(user.name) &&
      this.props.userId.includes(user.id)
    ) {
      return (
        <CheckActive
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.id,
              user.downloadUrl,
              this.props.isDP ? user.companyName : user.name,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    } else {
      return (
        <CheckDisabled
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(
              user.id,
              user.downloadUrl,
              this.props.isDP ? user.companyName : user.name,
              user.__typename,
              this.state.report
            );
          }}
        />
      );
    }
  };

  generateReport = (id, type, name) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType:
            type === "DataSubject"
              ? "DATA_SUBJECT"
              : type === "DataController"
              ? "DATA_CONTROLLER"
              : "USER",
        },
      })
      .then(({ data }) => {
        this.props.download(data.generateReport.downloadUrl, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { users } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span className="rt-check">{this.handleCheck(props.value)}</span>
        ),
        width: 70,
      },
      {
        Header: "User",
        accessor: "",
        Cell: (props) =>
          props.value.name
            ? props.value.name
            : `N/A ${props.value.UID ? props.value.UID : ""}`,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => <span className="number">{props.value}</span>,
      },
      {
        Header: "Company",
        accessor: "company",
      },
      // {
      //   Header: 'Telephone',
      //   accessor: 'phoneNumber',
      // },
      {
        Header: "Type",
        accessor: "__typename",
        Cell: (props) => (
          <span>
            {props.value === "DataSubject" ? "Data Subject" : "Data Controller"}
          </span>
        ),
        width: 140,
      },
      {
        Header: "Date added",
        accessor: "addedAt",
        Cell: (props) => <Moment format="DD/MM/YYYY">{props.value}</Moment>,
        width: 120,
      },
      {
        Header: "View",
        accessor: "",
        Cell: (props) => (
          <span
            className="users__view"
            onClick={() => this.props.showUser(props.value)}
          >
            <ViewIcon className="mr-2" color={"#7450c8"} /> View
          </span>
        ),
        width: 100,
      },
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span
            className="users__view"
            onClick={() =>
              this.generateReport(
                props.value.id,
                props.value.__typename,
                props.value.name
              )
            }
          >
            <DownloadIcon className="mr-2" color={"#7450c8"} />
          </span>
        ),
        width: 60,
      },
    ];

    return (
      <div className="users__table-pagination">
        <ReactTable
          data={users}
          columns={columns}
          minRows={0}
          showPagination={this.props.numberOfPages > 1 ? true : false}
          onPageChange={(pageNumber) => this.props.changePage(pageNumber)}
          renderTotalPagesCount={() => this.props.numberOfPages}
          showPageSizeOptions={false}
          defaultPageSize={20}
          showPageJump={false}
          resizable={false}
          noDataText="No Users"
        />
        {this.props.numberOfPages > 1 ? (
          <div className="d-flex justify-content-between users__btns-pagination">
            <Button
              className="users__prev btn--primary"
              onClick={() => this.props.changePage(this.props.pageNumber - 1)}
              disabled={this.props.pageNumber === 1 ? true : false}
            >
              Previous
            </Button>
            <div className="d-flex align-items-center">
              Page {this.props.pageNumber} of {this.props.numberOfPages}
            </div>
            <Button
              className="users__next btn--primary"
              onClick={() => this.props.changePage(this.props.pageNumber + 1)}
              disabled={
                this.props.pageNumber === this.props.numberOfPages ||
                users.length < 19
                  ? true
                  : false
              }
            >
              Next
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default graphql(GENERATE_REPORT, {
  name: "generateReport",
})(UsersTable);
