const categories = [
	"OTHERS",
	"STRATEGY_AND_ORGANIZATION",
	"BUSINESS_OPERATIONS",
	"HUMAN_RESOURCES",
	"SALES",
	"MARKETING",
	"FINANCE",
	"LEGAL",
	"FACILITIES",
	"GOVERMENT_OR_AUTHORITY"
];

export default categories;
