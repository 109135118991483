import React from "react";
import PropTypes from "prop-types";
import AgreementItem from "./AgreementItem";

function Step1Popup(props) {
  const { pages, showSteps, getSelectedPage } = props;

  return (
    <div className="d-flex align-items-end flex-wrap">
      <div>
        <h3 className="customization-cookie__title mb-3">
          1. Agreement Pop-up
        </h3>
        <p className="customization-cookie__subtitle">
          Select a legal page to change your pop-up style. This pop-up registers
          your users consent on your Legal pages.{" "}
        </p>

        <AgreementItem
          showNextSection={() => showSteps(2)}
          getSelectedPage={getSelectedPage}
        />

        {pages.map((page, i) => (
          <AgreementItem
            key={i}
            showNextSection={() => showSteps(2)}
            page={page}
            getSelectedPage={getSelectedPage}
          />
        ))}
      </div>
    </div>
  );
}

export default Step1Popup;
Step1Popup.propTypes = {
  pages: PropTypes.array.isRequired,
  showSteps: PropTypes.func.isRequired,
  getSelectedPage: PropTypes.func.isRequired,
};
