import PropTypes from "prop-types";
import React from "react";

const Cookies = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-8---Settings" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--basic-settings---account" transform="translate(-181.000000, -1400.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="user-management" transform="translate(149.000000, 1364.000000)">
                    <g id="Privacy-settings">
                        <g id="desktop-alt" transform="translate(32.000000, 36.000000)">
                            <path d="M17,0 L3,0 C1.34314575,-4.4408921e-16 0,1.34314575 -4.4408921e-16,3 L-4.4408921e-16,13 C0,14.6568542 1.34314575,16 3,16 L6,16 L6,18 L3,18 C2.44771525,18 2,18.4477153 2,19 C2,19.5522847 2.44771525,20 3,20 L17,20 C17.5522847,20 18,19.5522847 18,19 C18,18.4477153 17.5522847,18 17,18 L14,18 L14,16 L17,16 C18.6568542,16 20,14.6568542 20,13 L20,3 C20,1.34314575 18.6568542,-4.4408921e-16 17,0 Z M12,18 L8,18 L8,16 L12,16 L12,18 Z M18,13 C18,13.5522847 17.5522847,14 17,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,12 L18,12 L18,13 Z M18,10 L2,10 L2,3 C2,2.44771525 2.44771525,2 3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,10 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Cookies.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Cookies;