import gql from "graphql-tag";

export const CUSTOMIZE_SELL_QUERY = gql`
  {
    legalsite {
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      showLegalsiteBranding
    }
    legalsiteSettings {
      companyLogo
      id
    }
    dontSellMyDataButton {
      buttonType
      color
      id
      snippet
      targetAudience
      text
      textColor
      textFont
      textSize
    }
    pages {
      applicable
      description
      id
      title
    }
    dataProtectionOfficer {
      show
      id
    }
    account {
      id
      needToUpgrade
    }
  }
`;
