import gql from "graphql-tag";

export const MENU_CONTENT_QUERY = gql`
  {
    account {
      access
      id
    }
  }
`;
