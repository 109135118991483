import PropTypes from "prop-types";
import React from "react";

const DoneIcon = props => (
    <svg
        height={12}
        viewBox='0 0 12 12'
        width={12}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-630.000000, -1121.000000)" stroke="#1DDCC0" strokeWidth="2">
                <g id="user-requests" transform="translate(149.000000, 918.000000)">
                    <g id="Data" transform="translate(32.000000, 86.000000)">
                        <g id="Group-6" transform="translate(0.000000, 110.000000)">
                            <g id="Group-8" transform="translate(449.000000, 3.000000)">
                                <circle id="Colour" cx="6" cy="10" r="5"></circle>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

DoneIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default DoneIcon;