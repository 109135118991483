import React, { Component } from "react";
import PropTypes from "prop-types";
import Check from "react-icons/lib/fa/check";
import "./check.scss";

export default class Checked extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool,
    enabled: PropTypes.bool,
  };

  state = {
    isChecked: false,
    isInitialCheck: true,
  };

  render() {
    const { isCheckedAll, enabled } = this.props;
    const { isChecked, isInitialCheck } = this.state;

    return (
      <span
        className={`check ${
          (enabled && isInitialCheck) ||
          (!isInitialCheck && isChecked) ||
          (!isInitialCheck && isCheckedAll)
            ? "-checked"
            : "-unchecked"
        }`}
        onClick={() =>
          this.setState({
            isChecked: !this.state.isChecked,
            isInitialCheck: false,
          })
        }
      >
        <Check color={"#fff"} />
      </span>
    );
  }
}
