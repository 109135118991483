import PropTypes from "prop-types";
import React from "react";
import { Button, Form } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";

function HelpTextForm(props) {
  const { handleSubmit, cancel, value } = props;

  return (
    <Form onSubmit={handleSubmit} className="page-right-menu__form">
      <TextareaAutosize
        name="help-text"
        defaultValue={value ? value : ""}
        className="w-100 p-2 page-right-menu__textarea"
        placeholder="Write your text to help users in this section…"
      />
      <div className="page-right-menu__help-section-btns">
        <Button
          type="submit"
          className="btn--primary page-right-menu__help-section-save"
        >
          Save
        </Button>
        <Button
          className="btn--secondary page-right-menu__help-section-save"
          onClick={cancel}
        >
          Cancel
        </Button>
      </div>
    </Form>
  );
}

export default HelpTextForm;
HelpTextForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  value: PropTypes.string,
};
