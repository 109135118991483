import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Email1 from "../../images/Settings/email1.svg";
import Signed from "../../images/Settings/Signed.svg";
import Request from "../../images/Settings/Request.svg";
import { Alert } from "reactstrap";
import RightSideMenu from "./RightSideMenu";
import SyncLoader from "react-spinners/SyncLoader";
import FontLoader from "./FontLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { EMAILS_QUERY } from "../../graphql/emails";
import { CUSTOMIZE_EMAILS } from "../../graphql/editEmails";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import Step1Email from "./Step1Email";
import Step2Email from "./Step2Email";

class CustomizeEmail extends Component {
  static propTypes = {
    showCustomization: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    email: PropTypes.object.isRequired,
    customizeEmails: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    accountData: PropTypes.object.isRequired,
    notifyMsg: PropTypes.func.isRequired,
    getInitialPage: PropTypes.func.isRequired,
  };

  state = {
    step: 1,
    bgColor: null,
    footerBg: null,
    btnBg: null,
    titleColor: null,
    titleSize: null,
    bodyColor: null,
    bodySize: null,
    buttonColor: null,
    buttonSize: null,
    buttonTextColor: null,
    buttonTextSize: null,
    updatedBody: null,
    subjectType: "",
    emailTitle: null,
    updatedFooterText: null,
    updatedButtonText: null,
    emailType: null,
    index: 0,
    emailSubtitle: "",
    emailText: null,
    buttonText: "BUTTON",
    footerText:
      "If at any time you have questions or remarks, please let us know.",
    titleFont: null,
    bodyFont: null,
    footerFont: null,
    buttonFont: null,
    logo: null,
    updatedTitle: null,
    avatar: null,
    files: [],
    emailTitleAlign: null,
    emailTitleBold: null,
    emailTitleItalic: null,
    emailBodyAlign: null,
    emailBodyBold: null,
    emailBodyItalic: null,
    emailBtnBold: null,
    emailBtnItalic: null,
    emailFooterBold: null,
    emailFooterItalic: null,
  };

  getSubjectType = (type, title, index) => {
    this.props.email.refetch({ emailType: type });
    this.setState(
      {
        emailTitle: title,
        index,
        updatedTitle: null,
        emailType: type,
        updatedBody: null,
        updatedButtonText: null,
        updatedFooterText: null,
      },
      () => {
        this.props.email.refetch({ emailType: type }).then(() => {
          this.showSteps(3);
        });
      }
    );
  };

  getAligned = (name, align) => {
    this.setState({
      [name]: align,
    });
  };

  getBold = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getItalic = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  notify = (text) => toast(text);

  showSteps = (step) => {
    this.setState({
      step,
    });

    if (step === 1) {
      this.setState({
        bgColor: null,
        footerBg: null,
        btnBg: null,
        titleColor: null,
        titleSize: null,
        bodyColor: null,
        bodySize: null,
        buttonColor: null,
        buttonSize: null,
        buttonTextColor: null,
        buttonTextSize: null,
        updatedBody: null,
        subjectType: "",
        emailTitle: null,
        updatedFooterText: null,
        updatedButtonText: null,
        emailType: null,
        index: 0,
        emailSubtitle: "",
        emailText: null,
        buttonText: "BUTTON",
        footerText:
          "If at any time you have questions or remarks, please let us know.",
        titleFont: null,
        bodyFont: null,
        footerFont: null,
        buttonFont: null,
        logo: null,
        updatedTitle: null,
        avatar: null,
        files: [],
        emailTitleAlign: null,
        emailTitleBold: null,
        emailTitleItalic: null,
        emailBodyAlign: null,
        emailBtnBold: null,
        emailBtnItalic: null,
        emailBodyBold: null,
        emailFooterBold: null,
        emailFooterItalic: null,
      });
    }
  };

  changeEmailBg = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeTextColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeFontSize = (name, size) => {
    this.setState({
      [name]: parseInt(size),
    });
  };

  getBannerImage = () => {
    const { emailTitle, index } = this.state;

    if (index === 1) return Email1;
    else if (
      emailTitle === "Data Processing Agreement" ||
      emailTitle === "Signed Data Processing Agreement" ||
      emailTitle === "Data Processing Agreement signature"
    )
      return Signed;
    else if (
      emailTitle === "Data access request" ||
      emailTitle === "Data access requested" ||
      emailTitle === "Do not sell data request"
    )
      return Request;
  };

  getButtonText = () => {
    const { emailTitle } = this.state;
    let buttonText;

    if (
      emailTitle === "Data Processing Agreement" ||
      emailTitle === "Data Processing Agreement signature"
    ) {
      buttonText = "Sign DPA";

      return buttonText;
    } else if (emailTitle === "Data access request") {
      buttonText = "See data";

      return buttonText;
    } else if (emailTitle === "Signed Data Processing Agreement") {
      buttonText = "See DPA";

      return buttonText;
    } else if (emailTitle === "Data erasure request") {
      buttonText = "See confirmation";

      return buttonText;
    }

    this.setState({
      buttonText,
    });
  };

  saveCustomizedEmails = () => {
    const { email } = this.props.email;

    const id = email.id,
      titleColor = this.state.titleColor
        ? this.state.titleColor
        : email.titleColor,
      titleSize = this.state.titleSize ? this.state.titleSize : email.titleSize,
      titleFont = this.state.titleFont ? this.state.titleFont : email.titleFont,
      bodyColor = this.state.bodyColor ? this.state.bodyColor : email.bodyColor,
      bodySize = this.state.bodySize ? this.state.bodySize : email.bodySize,
      bodyFont = this.state.bodyFont ? this.state.bodyFont : email.bodyFont,
      logo = this.state.avatar
        ? this.state.avatar[0]
        : email.logo
        ? email.logo
        : null,
      backgroundColor = this.state.bgColor
        ? this.state.bgColor
        : email.backgroundColor,
      footerBackgroundColor = this.state.footerBg
        ? this.state.footerBg
        : email.footerBackgroundColor,
      buttonColor = this.state.btnBg ? this.state.btnBg : email.buttonColor,
      buttonTextColor = this.state.buttonColor
        ? this.state.buttonColor
        : email.buttonTextColor,
      buttonSize = this.state.buttonSize
        ? this.state.buttonSize
        : email.buttonSize,
      titleAlign = this.state.emailTitleAlign
        ? this.state.emailTitleAlign
        : email.titleAlign,
      titleBold = this.state.emailTitleBold
        ? this.state.emailTitleBold
        : email.titleBold,
      titleItalic = this.state.emailTitleItalic
        ? this.state.emailTitleItalic
        : email.titleItalic,
      bodyAlign = this.state.emailBodyAlign
        ? this.state.emailBodyAlign
        : email.bodyAlign,
      bodyBold = this.state.emailBodyBold
        ? this.state.emailBodyBold
        : email.bodyBold,
      bodyItalic = this.state.emailBodyItalic
        ? this.state.emailBodyItalic
        : email.bodyItalic,
      buttonFont = this.state.buttonFont
        ? this.state.buttonFont
        : email.buttonFont,
      buttonBold = this.state.emailBtnBold
        ? this.state.emailBtnBold
        : email.buttonBold,
      buttonItalic = this.state.emailBtnItalic
        ? this.state.emailBtnItalic
        : email.buttonItalic,
      footerColor = this.state.buttonTextColor
        ? this.state.buttonTextColor
        : email.footerColor,
      footerSize = this.state.buttonTextSize
        ? this.state.buttonTextSize
        : email.footerSize,
      footerBold = this.state.emailFooterBold
        ? this.state.emailFooterBold
        : email.footerBold,
      footerItalic = this.state.emailFooterItalic
        ? this.state.emailFooterItalic
        : email.footerItalic,
      footerFont = this.state.footerFont
        ? this.state.footerFont
        : email.footerFont,
      title = this.state.updatedTitle ? this.state.updatedTitle : email.title,
      body = this.state.updatedBody ? this.state.updatedBody : email.body,
      footer = this.state.updatedFooterText
        ? this.state.updatedFooterText
        : email.footer,
      buttonText = this.state.updatedButtonText
        ? this.state.updatedButtonText
        : email.buttonText,
      signature = this.state.emailText ? this.state.emailText : email.signature,
      bannerImage = this.state.files.length
        ? this.state.files[0].url
        : email.bannerImage
        ? email.bannerImage
        : null;

    this.props
      .customizeEmails({
        variables: {
          id,
          titleColor,
          titleSize,
          titleFont,
          bodyColor,
          footerBold,
          footerItalic,
          bodySize,
          bodyFont,
          logo,
          titleAlign,
          titleBold,
          titleItalic,
          buttonBold,
          buttonItalic,
          bodyAlign,
          bodyBold,
          bodyItalic,
          backgroundColor,
          footerBackgroundColor,
          buttonColor,
          buttonTextColor,
          buttonSize,
          buttonFont,
          footerColor,
          footerSize,
          footerFont,
          title,
          body,
          footer,
          buttonText,
          bannerImage,
          signature,
        },
      })
      .then(() => {
        this.props.viewer.refetch();

        this.showSteps(1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getGoogleFont = (name, font) => {
    this.setState({
      [name]: font,
    });
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  showAvatar = (avatar) => {
    this.setState({
      avatar,
    });
  };

  showEmail = (emailType, emailTitle, index) => {
    this.getSubjectType(emailType, emailTitle, index);
  };

  render() {
    const {
      bgColor,
      footerBg,
      btnBg,
      bodyColor,
      bodySize,
      buttonColor,
      buttonSize,
      buttonTextColor,
      buttonTextSize,
      titleColor,
      titleSize,
      step,
      emailTitle,
      emailText,
      index,
      titleFont,
      bodyFont,
      footerFont,
      buttonFont,
      updatedTitle,
      updatedBody,
      updatedButtonText,
      updatedFooterText,
      files,
      avatar,
      emailTitleAlign,
      emailTitleBold,
      emailTitleItalic,
      emailBodyAlign,
      emailBodyBold,
      emailBodyItalic,
      emailBtnBold,
      emailBtnItalic,
      emailFooterBold,
      emailFooterItalic,
    } = this.state;
    const { email, loading, error, account, legalsite, legalsiteSettings } =
      this.props.email;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    const sideMenu = document.getElementsByClassName(
      "customization__navigation"
    );

    if (step === 3) {
      if (sideMenu && sideMenu.length) {
        sideMenu[0].style.display = "none";
      }
    } else {
      if (sideMenu && sideMenu.length) {
        sideMenu[0].style.display = "block";
      }
    }

    return (
      <Fragment>
        {email ? (
          <div className="justify-content-between d-flex w-100">
            <FontLoader
              font1={
                titleFont
                  ? titleFont
                  : email && email.titleFont
                  ? email.titleFont
                  : "Roboto"
              }
              font2={
                bodyFont
                  ? bodyFont
                  : email && email.bodyFont
                  ? email.bodyFont
                  : "Roboto"
              }
              font3={
                footerFont
                  ? footerFont
                  : email && email.footerFont
                  ? email.footerFont
                  : "Roboto"
              }
              font4={
                buttonFont
                  ? buttonFont
                  : email && email.buttonFont
                  ? email.buttonFont
                  : "Roboto"
              }
            />

            {step === 1 ? (
              <Step1Email
                showSteps={this.showSteps}
                getSubjectType={this.getSubjectType}
                showEmail={this.showEmail}
              />
            ) : (
              <Fragment>
                <Step2Email
                  emailBodyAlign={emailBodyAlign}
                  emailBodyBold={emailBodyBold}
                  emailBodyItalic={emailBodyItalic}
                  emailBtnBold={emailBtnBold}
                  emailFooterBold={emailFooterBold}
                  emailFooterItalic={emailFooterItalic}
                  emailBtnItalic={emailBtnItalic}
                  bgColor={bgColor}
                  email={email}
                  avatar={avatar}
                  emailTitle={emailTitle}
                  emailTitleItalic={emailTitleItalic}
                  emailTitleBold={emailTitleBold}
                  files={files}
                  titleColor={titleColor}
                  titleFont={titleFont}
                  updatedTitle={updatedTitle}
                  titleSize={titleSize}
                  bodyFont={bodyFont}
                  bodyColor={bodyColor}
                  bodySize={bodySize}
                  updatedBody={updatedBody}
                  updatedButtonText={updatedButtonText}
                  updatedFooterText={updatedFooterText}
                  buttonFont={buttonFont}
                  buttonSize={buttonSize}
                  buttonColor={buttonColor}
                  emailText={emailText}
                  buttonTextColor={buttonTextColor}
                  buttonTextSize={buttonTextSize}
                  footerFont={footerFont}
                  emailTitleAlign={emailTitleAlign}
                  index={index}
                  btnBg={btnBg}
                  footerBg={footerBg}
                  getBannerImage={this.getBannerImage()}
                  onFiles={this.onFiles}
                  updateTitle={(text) => this.setState({ updatedTitle: text })}
                  updateBody={(text) => this.setState({ updatedBody: text })}
                  updateButtonText={(text) =>
                    this.setState({ updatedButtonText: text })
                  }
                  updateEmailText={(text) => this.setState({ emailText: text })}
                  updateFooterText={(text) =>
                    this.setState({ updatedFooterText: text })
                  }
                  logo={
                    legalsiteSettings && legalsiteSettings.companyLogo
                      ? legalsiteSettings.companyLogo
                      : null
                  }
                />

                <RightSideMenu
                  isAdmin={this.props.isAdmin}
                  showCustomization={this.props.showCustomization}
                  isEmailCustomization={true}
                  account={account}
                  isCookieCustomization={false}
                  isSiteCustomizatio={false}
                  index={index}
                  emailBgColor={bgColor ? bgColor : email.backgroundColor}
                  emailFooterBgColor={
                    footerBg ? footerBg : email.footerBackgroundColor
                  }
                  accountData={this.props.accountData}
                  notifyMsg={this.props.notifyMsg}
                  emailLogo={email.logo}
                  getInitialPage={this.props.getInitialPage}
                  changeTextColor={this.changeTextColor}
                  changeEmailBg={this.changeEmailBg}
                  siteBtnSize={legalsite ? legalsite.buttonSize : null}
                  changeFontSize={this.changeFontSize}
                  saveCustomizedEmails={this.saveCustomizedEmails}
                  showSteps={this.showSteps}
                  back={() => this.showSteps(1)}
                  getGoogleFont={this.getGoogleFont}
                  showAvatar={this.showAvatar}
                  emailTitleColor={titleColor ? titleColor : email.titleColor}
                  emailTitleSize={titleSize ? titleSize : email.titleSize}
                  emailTitleFont={titleFont ? titleFont : email.titleFont}
                  emailBodyColor={bodyColor ? bodyColor : email.bodyColor}
                  emailBodySize={bodySize ? bodySize : email.bodySize}
                  emailBodyFont={bodyFont ? bodyFont : email.bodyFont}
                  emailButtonColor={
                    buttonColor ? buttonColor : email.buttonTextColor
                  }
                  emailButtonSize={buttonSize ? buttonSize : email.buttonSize}
                  emailButtonBg={btnBg ? btnBg : email.buttonColor}
                  emailButtonFont={buttonFont ? buttonFont : email.buttonFont}
                  emailFooterColor={
                    buttonTextColor ? buttonTextColor : email.footerColor
                  }
                  emailFooterSize={
                    buttonTextSize ? buttonTextSize : email.footerSize
                  }
                  emailFooterFont={footerFont ? footerFont : email.footerFont}
                  email={email}
                  siteButtonFont={legalsite ? legalsite.siteButtonFont : null}
                  getAligned={this.getAligned}
                  getBold={this.getBold}
                  getItalic={this.getItalic}
                  emailTitleAlign={
                    emailTitleAlign
                      ? emailTitleAlign
                      : email.titleAlign
                      ? email.titleAlign
                      : "left"
                  }
                  emailTitleBold={
                    emailTitleBold ? emailTitleBold : email.titleBold
                  }
                  emailTitleItalic={
                    emailTitleItalic ? emailTitleItalic : email.titleItalic
                  }
                  emailBodyAlign={
                    emailBodyAlign
                      ? emailBodyAlign
                      : email.bodyAlign
                      ? email.bodyAlign
                      : "left"
                  }
                  emailBodyBold={emailBodyBold ? emailBodyBold : email.bodyBold}
                  emailBodyItalic={
                    emailBodyItalic ? emailBodyItalic : email.bodyItalic
                  }
                  emailBtnBold={emailBtnBold ? emailBtnBold : email.buttonBold}
                  emailBtnItalic={
                    emailBtnItalic ? emailBtnItalic : email.buttonItalic
                  }
                  emailFooterBold={
                    emailFooterBold ? emailFooterBold : email.footerBold
                  }
                  emailFooterItalic={
                    emailFooterItalic ? emailFooterItalic : email.footerItalic
                  }
                />
              </Fragment>
            )}

            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              newestOnTop={false}
              closeButton={false}
              closeOnClick
              rtl={false}
              draggable
              hideProgressBar
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default compose(
  graphql(EMAILS_QUERY, {
    options: () => ({
      variables: {
        emailType: "DATA_RECEIVED",
      },
      fetchPolicy: "no-cache",
    }),
    name: "email",
  }),
  graphql(CUSTOMIZE_EMAILS, {
    name: "customizeEmails",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(CustomizeEmail);
