import PropTypes from "prop-types";
import React from "react";

const CheckActive = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-0---Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--Dashboard---empty-state" transform="translate(-1154.000000, -332.000000)">
                <g id="Gettings-started-bar" transform="translate(1130.000000, 98.000000)">
                    <g id="Tasks" transform="translate(24.000000, 231.000000)">
                        <g id="Checkboxes" transform="translate(0.000000, 3.000000)">
                            <g id="Check-active">
                                <g id="Group">
                                    <rect id="Rectangle" fill="#EBA601" x="0" y="0" width="20" height="20" rx="4"></rect>
                                    <path d="M8.75053293,11.049872 L6.93553293,9.23487197 C6.66670406,8.92095819 6.24460269,8.78422183 5.84277206,8.88088082 C5.44094142,8.97753981 5.12720077,9.29128046 5.03054178,9.6931111 C4.93388279,10.0949417 5.07061915,10.5170431 5.38453293,10.785872 L7.96953293,13.381872 C8.17712364,13.5877771 8.45814965,13.7025624 8.75053293,13.700872 C9.03910679,13.6996569 9.31564623,13.5850905 9.52053293,13.381872 L15.0205329,7.88187197 C15.2287768,7.67532871 15.3459111,7.39417326 15.3459111,7.10087197 C15.3459111,6.80757068 15.2287768,6.52641523 15.0205329,6.31987197 C14.5914917,5.89337601 13.8985741,5.89337601 13.4695329,6.31987197 L8.75053293,11.049872 Z" id="Path" fill="#FFFFFF" fillRule="nonzero"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CheckActive.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default CheckActive;