import React, { Fragment } from "react";
import CloudExclamation from "../../components/Icons/CloudExclamation";
import InboxIcon from "../../components/Icons/InboxIcon";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

function BreachMenu(props) {
  const { sectionActive, incidentType, isEmail, emailType } = props;

  return (
    <div className="breach-menu">
      <div className="breach-menu__header-container">
        <p onClick={props.getInitialPage} className="breach-menu__back">
          <MdArrowLeft
            size={25}
            className="customize-menu__back position-relative"
            color="#7450c8"
          />
          &nbsp;Back to Compliance
        </p>
        <div
          className={`${isEmail ? "-extra-margin" : ""} breach-menu__header`}
        >
          {!isEmail ? (
            <CloudExclamation color={"#333"} />
          ) : (
            <InboxIcon color={"#333"} />
          )}
          <p className="breach-menu__title">
            {incidentType === "update" && !isEmail ? "Update" : "New"} Breach
            Incident
          </p>
        </div>

        {!isEmail ? (
          <p className="breach-menu__text">
            Fill out this form so we can build a report and an e-mail to notify
            your users.
          </p>
        ) : null}
      </div>

      <div className="breach-menu__content">
        {!isEmail ? (
          <Fragment>
            <div
              className={`${
                sectionActive === "where" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("where")}
            >
              <p className="breach-menu__item-text">Where</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "risk" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("risk")}
            >
              <p className="breach-menu__item-text">Determine risk</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "when" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("when")}
            >
              <p className="breach-menu__item-text">When did it happen</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "nature" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("nature")}
            >
              <p className="breach-menu__item-text">Nature of the breach</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "cause" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("cause")}
            >
              <p className="breach-menu__item-text">Cause of the breach</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "data" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("data")}
            >
              <p className="breach-menu__item-text">Data kind</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "users" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("users")}
            >
              <p className="breach-menu__item-text">Users details</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "measures" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("measures")}
            >
              <p className="breach-menu__item-text">Measures taken</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                sectionActive === "consequences" ? "-is-active" : ""
              } breach-menu__item`}
              onClick={() => props.showSection("consequences")}
            >
              <p className="breach-menu__item-text">Consequences</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div
              className={`${
                (emailType && emailType === "users") ||
                sectionActive === "your-users"
                  ? "-is-active"
                  : ""
              } breach-menu__item`}
              onClick={() => props.showSection("your-users")}
            >
              <p className="breach-menu__item-text">Your users</p>
              <ArrowCircleRight color={"#EBA601"} />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default BreachMenu;
BreachMenu.propTypes = {
  sectionActive: PropTypes.string,
  showSection: PropTypes.func,
  isEmail: PropTypes.bool,
  emailType: PropTypes.string,
  incidentType: PropTypes.string,
  getInitialPage: PropTypes.func,
};
