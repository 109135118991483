import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../images/Settings/Illustration.svg";
import { Button, Form, Input, FormGroup, FormFeedback } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { EDIT_INTEGRATION } from "../../graphql/editIntegration";
import { graphql } from "@apollo/client/react/hoc";

class CompanyDetails extends Component {
  static propTypes = {
    dataProcessor: PropTypes.object.isRequired,
    editIntegration: PropTypes.func.isRequired,
  };

  state = {
    formErrors: {},
    companyName:
      this.props.dataProcessor && this.props.dataProcessor.companyName
        ? this.props.dataProcessor.companyName
        : "",
    contactName:
      this.props.dataProcessor && this.props.dataProcessor.contactName
        ? this.props.dataProcessor.contactName
        : "",
    email:
      this.props.dataProcessor && this.props.dataProcessor.email
        ? this.props.dataProcessor.email
        : "",
  };

  handleSubmission = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const companyName = formData.get("companyName"),
      contactName = formData.get("contactName"),
      email = formData.get("email");

    let hasFormErrors = false;
    let formErrors = {};

    if (!companyName) {
      hasFormErrors = true;
      formErrors.companyName = true;
    }

    if (!contactName) {
      hasFormErrors = true;
      formErrors.contactName = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editIntegration({
        variables: {
          id: this.props.dataProcessor.id,
          companyName,
          email,
          contactName,
        },
      })
      .then(() => {
        this.notify("Changes saved!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes were not saved!");
      });
  };

  notify = (text) => toast(text);

  render() {
    return (
      <div className="data-processor__company-details">
        <h4 className="data-processor__company-details-title">
          Your company details
        </h4>
        <p className="data-processor__company-details-text">
          Your company details within this Data Processor.
        </p>
        <img src={Image} alt="api" />

        <Form
          onSubmit={this.handleSubmission}
          className="data-processor__company-details-form"
        >
          <FormGroup className="mb-4 w-100">
            <Input
              invalid={this.state.formErrors.companyName}
              name="companyName"
              type="text"
              defaultValue={this.state.companyName}
              placeholder="Company name"
            />
            <FormFeedback>Please add the Company name</FormFeedback>
          </FormGroup>
          <FormGroup className="mb-4 w-100">
            <Input
              invalid={this.state.formErrors.contactName}
              name="contactName"
              type="text"
              defaultValue={this.state.contactName}
              placeholder="Contact name "
            />
            <FormFeedback>Please add the Contact name</FormFeedback>
          </FormGroup>
          <FormGroup className="mb-4 w-100">
            <Input
              invalid={this.state.formErrors.email}
              name="email"
              type="email"
              defaultValue={this.state.email}
              placeholder="E-mail"
            />
            <FormFeedback>Please add the Company email</FormFeedback>
          </FormGroup>

          <Button
            type="submit"
            className="btn--primary data-processor__company-details-btn"
          >
            Save changes
          </Button>
        </Form>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default graphql(EDIT_INTEGRATION, {
  name: "editIntegration",
})(CompanyDetails);
