import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, ModalBody, Input, Form, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Illustration from "../../images/Inbox/Illustration.svg";
import "./modals.scss";

export default class FeedbackModal extends Component {
  static propTypes = {
    showFeedbackModal: PropTypes.func.isRequired,
    isFeedbackModalVisible: PropTypes.bool.isRequired,
  };

  state = {
    isFeedbackSent: false,
  };

  sendFeedback = () => {
    this.setState({
      isFeedbackSent: true,
    });
  };

  render() {
    const { isFeedbackSent } = this.state;

    return (
      <div>
        <Modal
          isOpen={this.props.isFeedbackModalVisible}
          toggle={this.props.showFeedbackModal}
          className="modal-feedback"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showFeedbackModal}
            />
            {!isFeedbackSent ? (
              <Fragment>
                <p className="modal-assign__title">Feedback</p>
                <p className="modal-feedback__message">
                  Help us improve LegalSite by giving your feedback!
                </p>
                <Form>
                  <Input
                    type="textarea"
                    name="text"
                    placeholder="Write your feedback here..."
                  />
                  <div className="modal-feedback__btns">
                    <Button
                      className="modal-feedback__cancel"
                      onClick={this.props.showFeedbackModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="modal-feedback__send"
                      onClick={this.sendFeedback}
                    >
                      Send feedback
                    </Button>
                  </div>
                </Form>
              </Fragment>
            ) : (
              <div className="d-flex align-items-center justify-content-center flex-column">
                <img
                  className="modal-feedback__img"
                  src={Illustration}
                  alt="thanks"
                />
                <p className="modal-feedback__title">Feedback submitted</p>
                <p className="modal-feedback__thanks">
                  Thank you for your feedback!
                </p>
                <Link to="/dashboard" className="modal-feedback__link">
                  Home page <MdArrow size={23} />
                </Link>
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
