import gql from "graphql-tag";

export const PUBLISH_ALL_PAGES = gql`
  mutation($clientMutationId: String) {
    publishAllPages(input: { clientMutationId: $clientMutationId }) {
      errors
      publications {
        id
      }
    }
  }
`;
