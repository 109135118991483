import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import Check from "react-icons/lib/fa/check";

export default class DataBreach extends Component {
  static propTypes = {
    identifyingDetails: PropTypes.array,
    personalDataCriminals: PropTypes.string,
    specialCategories: PropTypes.array,
    identifyingDetailsData: PropTypes.func,
    personalDataCriminalsData: PropTypes.func,
    specialCategoriesData: PropTypes.func,
  };

  state = {
    isDataSubject: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Data subject") > -1
        ? true
        : false
      : null,
    ppsn: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("PPSN") > -1
        ? true
        : false
      : null,
    isContactDetails: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Contact details") > -1
        ? true
        : false
      : null,
    isIdentificationData: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Identification data") > -1
        ? true
        : false
      : null,
    isEconomic: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Economic") > -1
        ? true
        : false
      : null,
    isLocation: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Location data") > -1
        ? true
        : false
      : null,
    isCriminal: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Criminal convictions") > -1
        ? true
        : false
      : null,
    isUnknown: this.props.identifyingDetails
      ? this.props.identifyingDetails.indexOf("Unknown") > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.identifyingDetails
      ? this.props.identifyingDetails.filter(
          (item) =>
            item !== "Data subject" &&
            item !== "PPSN" &&
            item !== "Contact details" &&
            item !== "Identification data" &&
            item !== "Economic" &&
            item !== "Location data" &&
            item !== "Criminal convictions" &&
            item !== "Unknown"
        ).length
        ? true
        : false
      : false,
    isPersonalInitial: true,
    isPersonalData: "FALSE",
    isNo: this.props.specialCategories
      ? this.props.specialCategories.indexOf("No") > -1
        ? true
        : false
      : null,
    isDataUnknown: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Unknown") > -1
        ? true
        : false
      : null,
    isRevealing: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Data revealing") > -1
        ? true
        : false
      : null,
    isPolitical: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Political opinions") > -1
        ? true
        : false
      : null,
    isTrade: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Trade union") > -1
        ? true
        : false
      : null,
    isSex: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Sex life") > -1
        ? true
        : false
      : null,
    isHealth: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Health data") > -1
        ? true
        : false
      : null,
    isGenetic: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Genetic data") > -1
        ? true
        : false
      : null,
    isBiometric: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Biometric data") > -1
        ? true
        : false
      : null,
    isReligious: this.props.specialCategories
      ? this.props.specialCategories.indexOf("Religious") > -1
        ? true
        : false
      : null,
  };

  getDetails = () => {
    const info = this.props.identifyingDetails
      ? this.props.identifyingDetails.filter(
          (item) =>
            item !== "Data subject" &&
            item !== "PPSN" &&
            item !== "Contact details" &&
            item !== "Identification data" &&
            item !== "Economic" &&
            item !== "Location data" &&
            item !== "Criminal convictions" &&
            item !== "Unknown"
        )
      : "";

    this.props.identifyingDetailsData([
      this.state.isDataSubject === true ? "Data subject" : null,
      this.state.ppsn === true ? "PPSN" : null,
      this.state.isContactDetails === true ? "Contact details" : null,
      this.state.isIdentificationData === true ? "Identification data" : null,
      this.state.isEconomic === true ? "Economic" : null,
      this.state.isLocation === true ? "Location data" : null,
      this.state.isCriminal === true ? "Criminal convictions" : null,
      this.state.isUnknown === true ? "Unknown" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  getSpecialCategories = () => {
    this.props.specialCategoriesData([
      this.state.isNo === true ? "No" : null,
      this.state.isDataUnknown === true ? "Unknown" : null,
      this.state.isRevealing === true ? "Data revealing" : null,
      this.state.isPolitical === true ? "Political opinions" : null,
      this.state.isTrade === true ? "Trade union" : null,
      this.state.isSex === true ? "Sex life" : null,
      this.state.isHealth === true ? "Health data" : null,
      this.state.isGenetic === true ? "Genetic data" : null,
      this.state.isBiometric === true ? "Biometric data" : null,
      this.state.isReligious === true ? "Religious" : null,
    ]);
  };

  render() {
    const { isPersonalData, isPersonalInitial } = this.state;
    const { personalDataCriminals } = this.props;
    const info = this.props.identifyingDetails
      ? this.props.identifyingDetails.filter(
          (item) =>
            item !== "Data subject" &&
            item !== "PPSN" &&
            item !== "Contact details" &&
            item !== "Identification data" &&
            item !== "Economic" &&
            item !== "Location data" &&
            item !== "Criminal convictions" &&
            item !== "Unknown"
        )
      : "";

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title mb-3">
          What identifying details relating to individuals were disclosed?
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDataSubject ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isDataSubject: !this.state.isDataSubject }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Data subject identify (name, surname, date of birth)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${this.state.ppsn ? "-checked" : "-unchecked"}`}
            onClick={() => {
              this.setState({ ppsn: !this.state.ppsn }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              PPSN or other national identification number
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isContactDetails ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                { isContactDetails: !this.state.isContactDetails },
                () => this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Contact details
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isIdentificationData ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isIdentificationData: !this.state.isIdentificationData,
                },
                () => this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Identification data (passport, licence data etc)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isEconomic ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isEconomic: !this.state.isEconomic }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Economic or Financial data
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isLocation ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isLocation: !this.state.isLocation }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Location data
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isCriminal ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isCriminal: !this.state.isCriminal }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Criminal convictions, offences or security measures
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isUnknown ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isUnknown: !this.state.isUnknown }, () =>
                this.getDetails()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Unknown
            </span>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getDetails()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Other
              </span>
            </div>
          </div>
          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getDetails())
            }
          />
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Does the breach involve personal data maintained for the prevention,
            investigation, detection or prosecution of criminal offences or the
            execution of criminal penalties in the State?
          </h5>
          <div className="d-flex mb-3">
            <span
              className={`${
                (personalDataCriminals === "TRUE" && isPersonalInitial) ||
                (!isPersonalInitial && isPersonalData === "TRUE")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isPersonalData: "TRUE",
                  isPersonalInitial: false,
                });
                this.props.personalDataCriminalsData("TRUE");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">Yes</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (personalDataCriminals === "FALSE" && isPersonalInitial) ||
                (!isPersonalInitial && isPersonalData === "FALSE")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isPersonalData: "FALSE",
                  isPersonalInitial: false,
                });
                this.props.personalDataCriminalsData("FALSE");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">No</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`${
                (personalDataCriminals === "UNKNOWN" && isPersonalInitial) ||
                (!isPersonalInitial && isPersonalData === "UNKNOWN")
                  ? "-is-active"
                  : ""
              } compliance-breaches__where-radio mr-2 mt-1`}
              onClick={() => {
                this.setState({
                  isPersonalData: "UNKNOWN",
                  isPersonalInitial: false,
                });
                this.props.personalDataCriminalsData("UNKNOWN");
              }}
            />
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Unknown
              </span>
            </div>
          </div>
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Were any special categories of data involved?
          </h5>
          <div className="d-flex mb-3">
            <span
              className={`check ${this.state.isNo ? "-checked" : "-unchecked"}`}
              onClick={() => {
                this.setState({ isNo: !this.state.isNo }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">No</span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isDataUnknown ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState(
                  { isDataUnknown: !this.state.isDataUnknown },
                  () => this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Unknown
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isRevealing ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isRevealing: !this.state.isRevealing }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Data revealing racial or ethnic origin
              </span>
            </div>
          </div>
          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isPolitical ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isPolitical: !this.state.isPolitical }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Political opinions
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isTrade ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isTrade: !this.state.isTrade }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Trade Union membership
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isSex ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isSex: !this.state.isSex }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Sex life data
              </span>
            </div>
          </div>
          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isHealth ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isHealth: !this.state.isHealth }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Health data
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isGenetic ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isGenetic: !this.state.isGenetic });
                this.getSpecialCategories();
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Genetic data
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isBiometric ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isBiometric: !this.state.isBiometric }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Biometric data
              </span>
            </div>
          </div>

          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isReligious ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isReligious: !this.state.isReligious }, () =>
                  this.getSpecialCategories()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Religious or philosophical beliefs
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
