import PropTypes from "prop-types";
import React from "react";

const Lightbulb = props => (
    <svg
        height={16}
        viewBox='0 0 11 16'
        width={11}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="suggested-action" transform="translate(-32.000000, -12.000000)" fill={props.color} fillRule="nonzero">
                <g id="Group-6">
                    <g transform="translate(32.000000, 11.000000)">
                        <g id="lightbulb" transform="translate(0.000000, 1.000000)">
                            <path d="M9.02425064,8.28185941 C10.7920804,6.10521341 10.466914,2.90882795 8.29697791,1.1327685 C7.08773865,0.163650477 5.50676057,-0.210229473 3.99152336,0.114586684 C2.28387232,0.492286359 0.889640138,1.7201008 0.299076306,3.36629748 C-0.291487526,5.01249416 0.00430015497,6.84659376 1.08243246,8.22367759 C1.56583271,8.81178696 1.84189495,9.54284065 1.867887,10.3036776 L1.867887,10.5145867 C1.59115483,10.781941 1.43163374,11.148055 1.42425064,11.5327685 L1.42425064,12.987314 C1.42425064,13.7906372 2.07547282,14.4418594 2.87879609,14.4418594 L2.87879609,15.1691321 C2.87879609,15.5707938 3.20440718,15.8964049 3.60606882,15.8964049 C4.00773046,15.8964049 4.33334155,15.5707938 4.33334155,15.1691321 L4.33334155,14.4418594 L5.787887,14.4418594 L5.787887,15.1691321 C5.787887,15.5707938 6.11349809,15.8964049 6.51515973,15.8964049 C6.91682136,15.8964049 7.24243246,15.5707938 7.24243246,15.1691321 L7.24243246,14.4418594 C8.04575573,14.4418594 8.69697791,13.7906372 8.69697791,12.987314 L8.69697791,11.5327685 C8.69731096,11.1522134 8.54848809,10.7866835 8.28243246,10.5145867 L8.28243246,10.2018594 C8.30367007,9.49602161 8.56539446,8.81861731 9.02425064,8.28185941 Z M2.87879609,12.987314 L2.87879609,11.5327685 L7.24243246,11.5327685 L7.24243246,12.987314 L2.87879609,12.987314 Z M7.88970518,7.3727685 C7.24590997,8.13210562 6.87243564,9.08369776 6.827887,10.078223 L3.28606882,10.078223 C3.2174655,9.07064243 2.83609779,8.10959579 2.19515973,7.32913214 C1.41242878,6.34682818 1.19204152,5.03013431 1.61223687,3.84648543 C2.03243223,2.66283656 3.03371159,1.77983434 4.26061427,1.51095032 C5.34383566,1.26661414 6.47933006,1.53061182 7.34366347,2.22774631 C8.20799688,2.9248808 8.70642583,3.97873605 8.69697791,5.08913214 C8.70154077,5.92104362 8.41608569,6.72854719 7.88970518,7.3727685 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Lightbulb.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Lightbulb;