import React, { Component } from "react";
import { Button, Table } from "reactstrap";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import ReactFilestack from "filestack-react";
import TrashIcon from "../../components/Icons/TrashIcon";
import { SEND_REQUEST_TASK } from "../../graphql/sendRequestExternalTask";
import { SEND_NOTIFICATIONS } from "../../graphql/sendNotificationsdontselldata";
import { FILE_RECEIVED_TASK } from "../../graphql/fileReceivedTasks";
import { REMOVE_TASK } from "../../graphql/removeTasks";
import MdClose from "react-icons/lib/md/close";
import send from "../../images/Inbox/send.svg";
import file from "../../images/Inbox/file.svg";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import TaskTable from "./TaskTable";
import PropTypes from "prop-types";

class ExternalTasks extends Component {
  static propTypes = {
    selectedRequestFile: PropTypes.array,
    selectedRequest: PropTypes.object,
    assigned: PropTypes.bool,
    isSell: PropTypes.bool,
    showAssignedModal: PropTypes.func,
    onExternalFiles: PropTypes.func,
    removeFile: PropTypes.func,
    redirectToDp: PropTypes.func,
    sendNotifications: PropTypes.func,
    sendRequest: PropTypes.func,
    fileReceived: PropTypes.func,
    removeTask: PropTypes.func,
    hasNotify: PropTypes.func,
  };

  state = {
    isCheckedAll: false,
    arr: [],
    isUserVisible: false,
  };

  checkAll = (tasks) => {
    const tasksId = tasks.map((item) => item.id);

    this.setState({
      isCheckedAll: !this.state.isCheckedAll,
      arr: tasksId,
    });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: !this.state.isCheckedAll,
      arr: [],
    });
  };

  checkItem = (id) => {
    let checkedItems = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);

    if (this.state.arr.includes(id)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id) {
          this.state.arr.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
    });
  };

  sendRequest = (tasks) => {
    if (this.props.isSell) {
      this.props
        .sendNotifications({
          variables: {
            taskIds: tasks,
          },
        })
        .then(() => {
          this.props.hasNotify("Notification sent!");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.props
        .sendRequest({
          variables: {
            taskIds: tasks,
          },
        })
        .then(() => {
          this.props.hasNotify("Request sent!");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  receivedFile = (tasks) => {
    this.props
      .fileReceived({
        variables: {
          taskIds: tasks,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  removeTask = (tasks) => {
    this.props
      .removeTask({
        variables: {
          taskIds: tasks,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { selectedRequestFile, selectedRequest, isSell, assigned } =
      this.props;
    const { isCheckedAll, arr } = this.state;

    return (
      <div className="inbox__internal">
        <p className="inbox__internal-title">
          {isSell
            ? "Step 1. Notify Data Processors"
            : "Step 2. External tasks - Data Processors"}
        </p>

        <p className="inbox__internal-text">
          {isSell
            ? "Notify your data processors that your company will stop selling this user’s information."
            : selectedRequest && selectedRequest.category === "DATA_ERASURE"
            ? "Request these companies to send you their data erasure confirmation log. Then, upload the files and mark them as `File received`. Data Processors with Legalsite accounts will receive Inbox requests and have files automatically uploaded."
            : "Request these companies to send you the user’s data. Then, upload the files and mark them as ‘File received’. Data Processors with LegalSite accounts will receive Inbox requests and have files automatically uploaded."}
        </p>

        <div className="d-flex flex-wrap justify-content-between">
          {selectedRequestFile
            ? selectedRequestFile.map((item) =>
                item.category === "EXTERNAL_USER_DATA" ? (
                  <div className="inbox__internal-file mb-4" key={item.id}>
                    <div className="inbox__internal-file-type">DOC</div>
                    <p className="inbox__internal-file-name">{item.url}</p>
                    <MdClose
                      onClick={() => this.props.removeFile(item.id)}
                      className="inbox__internal-file-remove"
                      size={20}
                      color={"#969696"}
                    />
                  </div>
                ) : null
              )
            : null}
        </div>

        <div className="d-flex">
          {!isSell ? (
            assigned ? (
              <Button
                className="btn--primary inbox__internal-btn mr-3"
                onClick={this.props.showAssignedModal}
              >
                Upload file
              </Button>
            ) : (
              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: (
                    <Button className="btn--primary inbox__internal-btn mr-3">
                      Upload file
                    </Button>
                  ),
                  customClass: "show-upload",
                }}
                onSuccess={this.props.onExternalFiles}
                actionOptions={{
                  fromSources: [
                    "local_file_system",
                    "googledrive",
                    "dropbox",
                    "onedrive",
                  ],
                  accept: [
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ],
                  maxFiles: 1,
                }}
              />
            )
          ) : null}
          <Button
            className="btn--secondary inbox__internal-btn"
            onClick={this.props.redirectToDp}
          >
            Add DP
          </Button>
        </div>

        {selectedRequest &&
        selectedRequest.tasks &&
        selectedRequest.tasks.length ? (
          <div className="inbox__internal-table-container">
            <div className="inbox__internal-header d-flex justify-content-between">
              <div className="d-flex align-items-center inbox__internal-header-select">
                <span
                  onClick={() =>
                    assigned
                      ? this.props.showAssignedModal()
                      : !isCheckedAll
                      ? this.checkAll(
                          selectedRequest &&
                            selectedRequest.tasks &&
                            selectedRequest.tasks.length
                            ? selectedRequest.tasks
                            : null
                        )
                      : this.unselectAll()
                  }
                >
                  {!isCheckedAll ? (
                    <CheckDisabled className="mr-3" />
                  ) : (
                    <CheckActive className="mr-3" />
                  )}
                </span>{" "}
                Select all
              </div>
              <div className="d-flex flex-row align-items-center">
                <div
                  onClick={() =>
                    assigned
                      ? this.props.showAssignedModal()
                      : this.sendRequest(this.state.arr)
                  }
                  className={`${
                    arr.length ? "" : "-is-disabled"
                  } d-flex align-items-center inbox__internal-header-send`}
                >
                  <img src={send} className="inbox__icon" alt="file" />
                  &nbsp;{isSell ? "Send notification" : "Send request"}
                </div>

                <span className="inbox__internal-header-divider" />

                {!isSell ? (
                  <div
                    onClick={() =>
                      assigned
                        ? this.props.showAssignedModal()
                        : this.receivedFile(this.state.arr)
                    }
                    className={`${
                      arr.length ? "" : "-is-disabled"
                    } d-flex align-items-center inbox__internal-header-received`}
                  >
                    <img src={file} className="inbox__icon" alt="file" />
                    &nbsp;File received
                  </div>
                ) : null}

                {!isSell ? (
                  <span className="inbox__internal-header-divider" />
                ) : null}

                <div
                  onClick={() =>
                    assigned
                      ? this.props.showAssignedModal()
                      : this.removeTask(this.state.arr)
                  }
                  className={`${
                    arr.length ? "" : "-is-disabled"
                  } d-flex align-items-center inbox__internal-header-received`}
                >
                  <TrashIcon color="#7450c8" />
                  &nbsp;Remove
                </div>
              </div>
            </div>
            <Table className="inbox__internal-table">
              <thead className="border-0">
                <tr className="border-0">
                  <th></th>
                  <th>Company</th>
                  {!isSell ? <th>Integration type</th> : null}
                  <th>Status</th>
                  <th className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedRequest &&
                selectedRequest.tasks &&
                selectedRequest.tasks.length
                  ? selectedRequest.tasks
                      .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                      .map((item) => (
                        <TaskTable
                          user={item}
                          assigned={assigned}
                          showAssignedModal={this.props.showAssignedModal}
                          key={item.id}
                          isCheckedAll={isCheckedAll}
                          isSell={isSell}
                          checkItem={this.checkItem}
                          sendRequest={this.sendRequest}
                          receivedFile={this.receivedFile}
                          removeTask={this.removeTask}
                        />
                      ))
                  : null}
              </tbody>
            </Table>
          </div>
        ) : (
          <p className="inbox__internal-text mt-5">No Companies</p>
        )}
      </div>
    );
  }
}

export default compose(
  graphql(SEND_REQUEST_TASK, {
    name: "sendRequest",
  }),
  graphql(SEND_NOTIFICATIONS, {
    name: "sendNotifications",
  }),
  graphql(FILE_RECEIVED_TASK, {
    name: "fileReceived",
  }),
  graphql(REMOVE_TASK, {
    name: "removeTask",
  })
)(ExternalTasks);
