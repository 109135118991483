import React, { Fragment } from "react";
import ManageItem from "./ManageItem";
import CustomizationSitePage from "./CustomizationSitePage";
import CustomizationSiteItem from "./CustomizationSiteItem";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

function BrandingLegalsite(props) {
  const {
    legalsite,
    pages,
    legalsiteSettings,
    brandingTextColor,
    brandingTextSize,
    brandingTextFont,
    brandingTitleAlign,
    brandingTitleBold,
    brandingTitleItalic,
    brandingSubtitleColor,
    brandingSubtitleSize,
    brandingSubtitleFont,
    brandingSubtitleAlign,
    brandingSubtitleBold,
    brandingSubtitleItalic,
    brandingBodyColor,
    brandingBodySize,
    brandingBodyFont,
    brandingBodyAlign,
    brandingBodyBold,
    brandingBodyItalic,
    brandingButtonColor,
    brandingButtonSize,
    brandingButtonFont,
    brandingButtonAlign,
    brandingButtonBold,
    iconsColor,
    brandingButtonItalic,
    cardsColor,
    legalsiteBg,
    btnBg,
    manageTextColor,
    manageTextSize,
    manageTextFont,
    manageTitleAlign,
    manageTitleBold,
    manageTitleItalic,
    headerBg,
    brandingLinksColor,
    brandingLinksSize,
    brandingLinksBold,
    brandingLinksItalic,
    brandingLinksFont,
    dontSellMyDataButton,
  } = props;

  return (
    <div className="customization-site -is-branding w-100 pr-0">
      <div
        className="customization-site__header"
        style={{
          backgroundColor: cardsColor ? cardsColor : legalsite.navigationBg,
        }}
      >
        <div
          className={`${
            legalsiteSettings && legalsiteSettings.companyLogo
              ? "-no-opacity"
              : ""
          } customization-site__logo d-flex align-items-center`}
        >
          {legalsiteSettings && legalsiteSettings.companyLogo ? (
            <img src={legalsiteSettings.companyLogo} alt="logo" />
          ) : (
            <Fragment>
              <span className="customization-site__logo-img" />
              YOUR LOGO
            </Fragment>
          )}
        </div>

        <p
          className="customization-site__get"
          style={{
            borderColor: iconsColor
              ? iconsColor
              : legalsite.navigationLinksColor,
            color: iconsColor ? iconsColor : legalsite.navigationLinksColor,
            fontSize: legalsite.navigationLinksSize,
            fontFamily: legalsite.navigationLinksFont,
            textAlign: legalsite.navigationLinksAlign,
            fontWeight: legalsite.navigationLinksBold ? 900 : 300,
            fontStyle: legalsite.navigationLinksItalic ? "italic" : "normal",
          }}
        >
          Submit a request
        </p>
      </div>
      <div className="customization-site__container -is-branding">
        <div
          className="customization-site__welcome-section"
          style={{
            background: headerBg ? headerBg : legalsite.headerBg,
          }}
        >
          <h1
            className="customization-site__welcome-title"
            style={{
              color: brandingTextColor
                ? brandingTextColor
                : legalsite.headerTitleColor,
              fontSize: brandingTextSize
                ? brandingTextSize
                : legalsite.headerTitleSize,
              fontFamily: brandingTextFont
                ? brandingTextFont
                : legalsite.headerTitleFont,
              textAlign:
                brandingTitleAlign !== null
                  ? brandingTitleAlign
                  : legalsite.headerTitleAlign,
              fontWeight:
                brandingTitleBold !== null
                  ? brandingTitleBold === true
                    ? 900
                    : 300
                  : legalsite.headerTitleBold
                  ? 900
                  : 300,
              fontStyle:
                brandingTitleItalic !== null
                  ? brandingTitleItalic === true
                    ? "italic"
                    : "normal"
                  : legalsite.headerTitleItalic
                  ? "italic"
                  : "normal",
            }}
          >
            {legalsite && legalsite.homepageTitle
              ? legalsite.homepageTitle
              : "Welcome!"}
          </h1>
          <p
            className="customization-site__welcome-text"
            style={{
              color: brandingSubtitleColor
                ? brandingSubtitleColor
                : legalsite.headerBodyColor,
              fontSize: brandingSubtitleSize
                ? brandingSubtitleSize
                : legalsite.headerBodySize,
              fontFamily: brandingSubtitleFont
                ? brandingSubtitleFont
                : legalsite.headerBodyFont,
              textAlign:
                brandingSubtitleAlign !== null
                  ? brandingSubtitleAlign
                  : legalsite.headerBodyAlign,
              fontWeight:
                brandingSubtitleBold !== null
                  ? brandingSubtitleBold === true
                    ? 900
                    : 300
                  : legalsite.headerBodyBold
                  ? 900
                  : 300,
              fontStyle:
                brandingSubtitleItalic !== null
                  ? brandingSubtitleItalic === true
                    ? "italic"
                    : "normal"
                  : legalsite.headerBodyItalic
                  ? "italic"
                  : "normal",
            }}
          >
            {legalsite && legalsite.homepageDescription
              ? legalsite.homepageDescription
              : "It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need."}
          </p>
        </div>

        <div
          className="customization-site__info"
          style={{ background: legalsiteBg ? legalsiteBg : legalsite.bodyBg }}
        >
          <Row className="customization-site__info-content">
            {pages
              ? pages.map((page) =>
                  (page.applicable && page.title === "Terms & Conditions") ||
                  (page.applicable && page.title === "Privacy Policy") ? (
                    <CustomizationSitePage
                      key={page.id}
                      cardsBtnBg={iconsColor}
                      page={page}
                      legalsite={legalsite}
                      contactBg={cardsColor}
                      cardsIconsColor={iconsColor}
                      cardsBodyColor={brandingSubtitleColor}
                      cardsBodySize={brandingSubtitleSize}
                      cardsBodyFont={brandingSubtitleFont}
                      cardsBodyAlign={brandingSubtitleAlign}
                      cardsBodyBold={brandingSubtitleBold}
                      cardsBodyItalic={brandingSubtitleItalic}
                      cardsTitleColor={brandingBodyColor}
                      cardsTitleSize={brandingBodySize}
                      cardsTitleFont={brandingBodyFont}
                      cardsTitleAlign={brandingBodyAlign}
                      cardsTitleBold={brandingBodyBold}
                      cardsTitleItalic={brandingBodyItalic}
                    />
                  ) : null
                )
              : null}

            <CustomizationSitePage
              isContact={true}
              cardsBtnBg={iconsColor}
              legalsite={legalsite}
              contactBg={cardsColor}
              cardsIconsColor={iconsColor}
              cardsBodyColor={brandingSubtitleColor}
              cardsBodySize={brandingSubtitleSize}
              cardsBodyFont={brandingSubtitleFont}
              cardsBodyAlign={brandingSubtitleAlign}
              cardsBodyBold={brandingSubtitleBold}
              cardsBodyItalic={brandingSubtitleItalic}
              cardsTitleColor={brandingBodyColor}
              cardsTitleSize={brandingBodySize}
              cardsTitleFont={brandingBodyFont}
              cardsTitleAlign={brandingBodyAlign}
              cardsTitleBold={brandingBodyBold}
              cardsTitleItalic={brandingBodyItalic}
            />
          </Row>

          <h3
            className="customization-site__info-subtitle"
            style={{
              color: manageTextColor
                ? manageTextColor
                : legalsite.bodyTitleColor,
              fontSize: manageTextSize
                ? manageTextSize
                : legalsite.bodyTitleSize,
              fontFamily: manageTextFont
                ? manageTextFont
                : legalsite.bodyTitleFont,
              textAlign:
                manageTitleAlign !== null
                  ? manageTitleAlign
                  : legalsite.bodyTitleAlign,
              fontWeight:
                manageTitleBold !== null
                  ? manageTitleBold === true
                    ? 900
                    : 300
                  : legalsite.bodyTitleBold
                  ? 900
                  : 300,
              fontStyle:
                manageTitleItalic !== null
                  ? manageTitleItalic === true
                    ? "italic"
                    : "normal"
                  : legalsite.bodyTitleItalic
                  ? "italic"
                  : "normal",
            }}
          >
            Manage your information
          </h3>
          <p
            className="customization-site__info-details px-2"
            style={{
              color: brandingSubtitleColor
                ? brandingSubtitleColor
                : legalsite.bodyTextColor,
              fontSize: brandingSubtitleSize
                ? brandingSubtitleSize
                : legalsite.bodyTextSize,
              fontFamily: brandingSubtitleFont
                ? brandingSubtitleFont
                : legalsite.bodyTextFont,
              textAlign:
                brandingSubtitleAlign !== null
                  ? brandingSubtitleAlign
                  : legalsite.bodyTextAlign,
              fontWeight:
                brandingSubtitleBold !== null
                  ? brandingSubtitleBold === true
                    ? 900
                    : 300
                  : legalsite.bodyTextBold
                  ? 900
                  : 300,

              fontStyle:
                brandingSubtitleItalic !== null
                  ? brandingSubtitleItalic === true
                    ? "italic"
                    : "normal"
                  : legalsite.bodyTextItalic
                  ? "italic"
                  : "normal",
            }}
          >
            When you interact with our service, we probably manage information
            about you. You have the right to understand and manage how this
            information is handled by us.
          </p>

          <div className="d-flex justify-content-center align-items-center flex-wrap mb-0">
            <ManageItem
              btnBg={btnBg}
              legalsite={legalsite}
              bodyBtnBg={btnBg}
              bodyBtnColor={brandingButtonColor}
              bodyBtnSize={brandingButtonSize}
              bodyBtnFont={brandingButtonFont}
              bodyBtnAlign={brandingButtonAlign}
              bodyBtnBold={brandingButtonBold}
              bodyBtnItalic={brandingButtonItalic}
              iconsColor={iconsColor}
              bodyIconsColor={iconsColor}
              bodySubtitleColor={brandingBodyColor}
              bodySubtitleSize={brandingBodySize}
              bodySubtitleFont={brandingBodyFont}
              bodySubtitleAlign={brandingBodyAlign}
              bodySubtitleBold={brandingBodyBold}
              bodySubtitleItalic={brandingBodyItalic}
              bodyTextColor={brandingSubtitleColor}
              bodyTextSize={brandingSubtitleSize}
              bodyTextFont={brandingSubtitleFont}
              bodyTextAlign={brandingSubtitleAlign}
              bodyTextBold={brandingSubtitleBold}
              bodyTextItalic={brandingSubtitleItalic}
            />
            <ManageItem
              isManage={true}
              bodyBtnBg={btnBg}
              legalsite={legalsite}
              bodyBtnColor={brandingButtonColor}
              bodyBtnSize={brandingButtonSize}
              bodyBtnFont={brandingButtonFont}
              bodyBtnAlign={brandingButtonAlign}
              bodyBtnBold={brandingButtonBold}
              bodyBtnItalic={brandingButtonItalic}
              bodyIconsColor={iconsColor}
              bodySubtitleColor={brandingBodyColor}
              bodySubtitleSize={brandingBodySize}
              bodySubtitleFont={brandingBodyFont}
              bodySubtitleAlign={brandingBodyAlign}
              bodySubtitleBold={brandingBodyBold}
              bodySubtitleItalic={brandingBodyItalic}
              bodyTextColor={brandingSubtitleColor}
              bodyTextSize={brandingSubtitleSize}
              bodyTextFont={brandingSubtitleFont}
              bodyTextAlign={brandingSubtitleAlign}
              bodyTextBold={brandingSubtitleBold}
              bodyTextItalic={brandingSubtitleItalic}
            />
          </div>

          <div className="pb-3 customization-site__items-container">
            {pages
              ? pages.map((item) =>
                  item.applicable &&
                  item.title !== "Terms & Conditions" &&
                  item.title !== "Privacy Policy" ? (
                    <CustomizationSiteItem
                      key={item.id}
                      cardsBtnColor={brandingButtonColor}
                      item={item}
                      legalsite={legalsite}
                      contactBg={cardsColor}
                      cardsBtnBg={btnBg}
                      cardsIconsColor={btnBg}
                      cardsBodyColor={brandingSubtitleColor}
                      cardsBodySize={brandingSubtitleSize}
                      cardsBodyFont={brandingSubtitleFont}
                      cardsBodyAlign={brandingSubtitleAlign}
                      cardsBodyBold={brandingSubtitleBold}
                      cardsBodyItalic={brandingSubtitleItalic}
                      cardsTitleColor={brandingBodyColor}
                      cardsTitleSize={brandingBodySize}
                      cardsTitleFont={brandingBodyFont}
                      cardsTitleAlign={brandingBodyAlign}
                      cardsTitleBold={brandingBodyBold}
                      cardsTitleItalic={brandingBodyItalic}
                    />
                  ) : null
                )
              : null}

            {/* {dataProtectionOfficer && dataProtectionOfficer.show ? ( */}
            <CustomizationSiteItem
              isDPO={true}
              legalsite={legalsite}
              cardsBtnColor={brandingButtonColor}
              contactBg={cardsColor}
              cardsBtnBg={btnBg}
              cardsIconsColor={btnBg}
              cardsBodyColor={brandingSubtitleColor}
              cardsBodySize={brandingSubtitleSize}
              cardsBodyFont={brandingSubtitleFont}
              cardsBodyAlign={brandingSubtitleAlign}
              cardsBodyBold={brandingSubtitleBold}
              cardsBodyItalic={brandingSubtitleItalic}
              cardsTitleColor={brandingBodyColor}
              cardsTitleSize={brandingBodySize}
              cardsTitleFont={brandingBodyFont}
              cardsTitleAlign={brandingBodyAlign}
              cardsTitleBold={brandingBodyBold}
              cardsTitleItalic={brandingBodyItalic}
            />
            {/* ) : null} */}
          </div>
        </div>
        <div
          className="footer"
          style={{
            background: headerBg ? headerBg : legalsite.footerBg,
          }}
        >
          <div
            className="footer__container"
            style={{
              background: headerBg ? headerBg : legalsite.footerBg,
            }}
          >
            <Row className="w-100">
              <Col sm={12} md={3} className="d-flex flex-column">
                <span className="d-flex align-items-center text-decoration-none">
                  {props.legalsiteSettings &&
                  props.legalsiteSettings.companyLogo &&
                  legalsite &&
                  !legalsite.footerLogo ? (
                    <div className="footer__logo-container">
                      <img
                        src={props.legalsiteSettings.companyLogo}
                        alt="logo"
                      />
                    </div>
                  ) : legalsite && legalsite.footerLogo ? (
                    <div className="footer__logo-container">
                      <img src={legalsite.footerLogo} alt="logo" />
                    </div>
                  ) : (
                    <span className="footer__logo" />
                  )}
                </span>
              </Col>

              <Col sm={12} md={3} className="d-flex flex-column">
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerTextColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerTextSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerTextFont,
                    textAlign: legalsite.footerTextAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerTextBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerTextItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  <strong>Website</strong>
                </p>
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerLinksColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerLinksSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerLinksFont,
                    textAlign: legalsite.footerLinksAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Homepage
                </p>
                <p
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://app.legalsite.co/"
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerLinksColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerLinksSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerLinksFont,
                    textAlign: legalsite.footerLinksAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Go back to main website
                </p>
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerLinksColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerLinksSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerLinksFont,
                    textAlign: legalsite.footerLinksAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Legal Information
                </p>
              </Col>

              <Col sm={12} md={3} className="d-flex flex-column">
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerTextColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerTextSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerTextFont,
                    textAlign: legalsite.footerTextAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerTextBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerTextItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  <strong>Legal Pages</strong>
                </p>
                {pages
                  ? pages.map((item) => (
                      <p
                        className="mb-4"
                        style={{
                          color: brandingLinksColor
                            ? brandingLinksColor
                            : legalsite.footerLinksColor,
                          fontSize: brandingLinksSize
                            ? brandingLinksSize
                            : legalsite.footerLinksSize,
                          fontFamily: brandingLinksFont
                            ? brandingLinksFont
                            : legalsite.footerLinksFont,
                          textAlign: legalsite.footerLinksAlign,
                          fontWeight:
                            brandingLinksBold !== null
                              ? brandingLinksBold === true
                                ? 900
                                : 300
                              : legalsite.footerLinksBold
                              ? 900
                              : 300,
                          fontStyle:
                            brandingLinksItalic !== null
                              ? brandingLinksItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite.footerLinksItalic
                              ? "italic"
                              : "normal",
                        }}
                        key={item.id}
                      >
                        {item.title}
                      </p>
                    ))
                  : null}
              </Col>

              <Col sm={12} md={3} className="d-flex flex-column">
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerTextColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerTextSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerTextFont,
                    textAlign: legalsite.footerTextAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerTextBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerTextItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  <strong>Manage your information</strong>
                </p>
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerLinksColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerLinksSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerLinksFont,
                    textAlign: legalsite.footerLinksAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Manage Consent
                </p>
                <p
                  className="mb-4"
                  style={{
                    color: brandingLinksColor
                      ? brandingLinksColor
                      : legalsite.footerLinksColor,
                    fontSize: brandingLinksSize
                      ? brandingLinksSize
                      : legalsite.footerLinksSize,
                    fontFamily: brandingLinksFont
                      ? brandingLinksFont
                      : legalsite.footerLinksFont,
                    textAlign: legalsite.footerLinksAlign,
                    fontWeight:
                      brandingLinksBold !== null
                        ? brandingLinksBold === true
                          ? 900
                          : 300
                        : legalsite.footerLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      brandingLinksItalic !== null
                        ? brandingLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Send request
                </p>
                <div
                  className={`d-flex ${
                    legalsite && legalsite.footerLinksAlign !== null
                      ? legalsite.footerLinksAlign === "center"
                        ? "justify-content-center"
                        : legalsite.footerLinksAlign === "left"
                        ? "justify-content-start"
                        : "justify-content-end"
                      : null
                  }`}
                >
                  <p
                    className={`${
                      (dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained") ||
                      (dontSellMyDataButton && !dontSellMyDataButton.buttonType)
                        ? "-is-contained"
                        : ""
                    }`}
                    style={{
                      background:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        btnBg
                          ? btnBg
                          : (dontSellMyDataButton &&
                              dontSellMyDataButton.buttonType ===
                                "contained") ||
                            (dontSellMyDataButton &&
                              !dontSellMyDataButton.buttonType)
                          ? dontSellMyDataButton && dontSellMyDataButton.color
                            ? dontSellMyDataButton.color
                            : "#7450c8"
                          : null,
                      color:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        brandingButtonColor
                          ? brandingButtonColor
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "text_only" &&
                            brandingLinksColor
                          ? brandingLinksColor
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.textColor
                          ? dontSellMyDataButton.textColor
                          : "#fff",
                      fontSize:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        brandingButtonSize
                          ? brandingButtonSize
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "text_only" &&
                            brandingLinksSize
                          ? brandingLinksSize
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.textSize
                          ? parseInt(dontSellMyDataButton.textSize)
                          : 14,
                      fontFamily:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        brandingButtonFont
                          ? brandingButtonFont
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "text_only" &&
                            brandingLinksFont
                          ? brandingLinksFont
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.textFont
                          ? dontSellMyDataButton.textFont
                          : "Roboto",
                      textAlign: props.footerLinkAlign
                        ? props.footerLinkAlign
                        : legalsite && legalsite.footerLinksAlign !== null
                        ? legalsite.footerLinksAlign
                        : null,
                      fontWeight:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        brandingButtonBold !== null
                          ? brandingButtonBold === true
                            ? 900
                            : 300
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "text_only" &&
                            brandingLinksBold !== null
                          ? brandingLinksBold === true
                            ? 900
                            : 300
                          : props.footerLinkBold !== null
                          ? props.footerLinkAlign === true
                            ? 900
                            : 300
                          : legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        dontSellMyDataButton &&
                        dontSellMyDataButton.buttonType === "contained" &&
                        brandingButtonItalic !== null
                          ? brandingButtonItalic === true
                            ? "italic"
                            : "normal"
                          : dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "text_only" &&
                            brandingLinksItalic !== null
                          ? brandingLinksItalic === true
                            ? "italic"
                            : "normal"
                          : props.footerLinkItalic !== null
                          ? props.footerLinkItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Do Not Sell My Info
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div
            className="footer-text"
            style={{
              background: headerBg ? headerBg : legalsite.footerBg,
            }}
          >
            <span>
              <span
                style={{
                  color: brandingLinksColor
                    ? brandingLinksColor
                    : legalsite.footerTextColor,
                  fontSize: brandingLinksSize
                    ? brandingLinksSize
                    : legalsite.footerTextSize,
                  fontFamily: brandingLinksFont
                    ? brandingLinksFont
                    : legalsite.footerTextFont,
                  textAlign: legalsite.footerTextAlign,
                  fontWeight:
                    brandingLinksBold !== null
                      ? brandingLinksBold === true
                        ? 900
                        : 300
                      : legalsite.footerTextBold
                      ? 900
                      : 300,

                  fontStyle:
                    brandingLinksItalic !== null
                      ? brandingLinksItalic === true
                        ? "italic"
                        : "normal"
                      : legalsite.footerTextItalic
                      ? "italic"
                      : "normal",
                }}
              >
                © {new Date().getFullYear()} LegalSite BV. All rights reserved.
              </span>{" "}
              <span
                style={{
                  color: brandingLinksColor
                    ? brandingLinksColor
                    : legalsite.footerLinksColor,
                  fontSize: brandingLinksSize
                    ? brandingLinksSize
                    : legalsite.footerLinksSize,
                  fontFamily: brandingLinksFont
                    ? brandingLinksFont
                    : legalsite.footerLinksFont,
                  textAlign: legalsite.footerLinksAlign,
                  fontWeight:
                    brandingLinksBold !== null
                      ? brandingLinksBold === true
                        ? 900
                        : 300
                      : legalsite.footerLinksBold
                      ? 900
                      : 300,
                  fontStyle:
                    brandingLinksItalic !== null
                      ? brandingLinksItalic === true
                        ? "italic"
                        : "normal"
                      : legalsite.footerLinksItalic
                      ? "italic"
                      : "normal",
                }}
              >
                <u>Get your LegalSite!</u>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandingLegalsite;
BrandingLegalsite.propTypes = {
  legalsite: PropTypes.string,
  pages: PropTypes.array,
  legalsiteSettings: PropTypes.object,
  brandingTextColor: PropTypes.string,
  brandingTextSize: PropTypes.number,
  brandingTextFont: PropTypes.string,
  brandingTitleAlign: PropTypes.string,
  brandingTitleBold: PropTypes.bool,
  brandingTitleItalic: PropTypes.bool,
  brandingSubtitleColor: PropTypes.string,
  brandingSubtitleSize: PropTypes.number,
  brandingSubtitleFont: PropTypes.string,
  brandingSubtitleAlign: PropTypes.string,
  brandingSubtitleBold: PropTypes.bool,
  brandingSubtitleItalic: PropTypes.bool,
  brandingBodyColor: PropTypes.string,
  brandingBodySize: PropTypes.number,
  brandingBodyFont: PropTypes.string,
  brandingBodyAlign: PropTypes.string,
  brandingBodyBold: PropTypes.bool,
  brandingBodyItalic: PropTypes.bool,
  brandingButtonColor: PropTypes.string,
  brandingButtonSize: PropTypes.number,
  brandingButtonFont: PropTypes.string,
  brandingButtonAlign: PropTypes.string,
  brandingButtonBold: PropTypes.bool,
  brandingButtonItalic: PropTypes.bool,
  iconsColor: PropTypes.string,
  cardsColor: PropTypes.string,
  legalsiteBg: PropTypes.string,
  btnBg: PropTypes.string,
  manageTextColor: PropTypes.string,
  manageTextSize: PropTypes.number,
  manageTextFont: PropTypes.string,
  manageTitleAlign: PropTypes.string,
  manageTitleBold: PropTypes.bool,
  manageTitleItalic: PropTypes.bool,
  headerBg: PropTypes.string,
  brandingLinksColor: PropTypes.string,
  brandingLinksSize: PropTypes.number,
  brandingLinksBold: PropTypes.bool,
  brandingLinksItalic: PropTypes.bool,
  brandingLinksFont: PropTypes.string,
  dontSellMyDataButton: PropTypes.bool,
  footerLinkBold: PropTypes.bool,
  footerLinkItalic: PropTypes.bool,
  footerLinkAlign: PropTypes.string,
};
