import React, { Component } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { HEADER_QUERY } from "../../graphql/headerQuery";
import PropTypes from "prop-types";
import "./preview-header.scss";

class PreviewHeader extends Component {
  render() {
    const { legalsiteSettings, legalsite } = this.props.data;

    return (
      <div
        className={`legalsite__nav ${
          this.props.isConsent ? "position-static" : ""
        }`}
        style={{
          backgroundColor: this.props.navigationBg
            ? this.props.navigationBg
            : legalsite
            ? legalsite.navigationBg
            : null,
        }}
      >
        <p className="d-flex align-items-center text-decoration-none mb-0">
          {legalsiteSettings ? (
            legalsiteSettings.companyLogo ? (
              <span className="legalsite__logo-container">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </span>
            ) : (
              <span className="legalsite__logo" />
            )
          ) : null}
        </p>

        <span
          onClick={this.redirectToLandingPage}
          className="legalsite__link -submit"
          style={{
            borderColor: this.props.navigationLinksColor
              ? this.props.navigationLinksColor
              : legalsite
              ? legalsite.navigationLinksColor
              : null,
            color: this.props.navigationLinksColor
              ? this.props.navigationLinksColor
              : legalsite
              ? legalsite.navigationLinksColor
              : null,
            fontSize: this.props.navigationSize
              ? this.props.navigationSize
              : legalsite
              ? legalsite.navigationSize
              : null,
            fontFamily: this.props.navigationFont
              ? this.props.navigationFont
              : legalsite
              ? legalsite.navigationFont
              : null,
            textAlign: this.props.navigationLinksAlign
              ? this.props.navigationLinksAlign
              : legalsite
              ? legalsite.navigationLinksAlign
              : null,
            fontWeight:
              this.props.navigationLinksBold !== null
                ? this.props.navigationLinksBold === true
                  ? 900
                  : 300
                : legalsite && legalsite.navigationLinksBold
                ? legalsite.navigationLinksBold === true
                  ? 900
                  : 300
                : null,
            fontStyle:
              this.props.navigationLinksItalic !== null
                ? this.props.navigationLinksItalic === true
                  ? "italic"
                  : "bold"
                : legalsite && legalsite.navigationLinksItalic
                ? legalsite.navigationLinksItalic === true
                  ? "italic"
                  : "normal"
                : "normal",
          }}
        >
          Submit a request
        </span>
      </div>
    );
  }
}

PreviewHeader.propTypes = {
  data: PropTypes.object.isRequired,
  isConsent: PropTypes.bool,
  navigationBg: PropTypes.string,
  navigationLinksColor: PropTypes.string,
  navigationSize: PropTypes.string,
  navigationFont: PropTypes.string,
  navigationLinksAlign: PropTypes.string,
  navigationLinksBold: PropTypes.bool,
  navigationLinksItalic: PropTypes.bool,
};

export default graphql(HEADER_QUERY)(PreviewHeader);
