import React, { Component } from "react";
import FaCaret from "react-icons/lib/fa/angle-down";
import AnnouncementItem from "./AnnouncementItem";

export default class Announcements extends Component {
  render() {
    return (
      <div className="announcements">
        <div className="container">
          <h2 className="announcements__title">Announcements</h2>
          <span className="announcements__text">
            Check the latest news we shared.
          </span>
        </div>

        <div className="announcements__items">
          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />

          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />

          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />

          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />

          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />

          <AnnouncementItem
            title={"new gdpr checklist update"}
            text={"Send us your question and we’ll be in touch soon."}
            date={"21 april 2019"}
          />
        </div>

        <div className="container">
          <h4 className="announcements__previous-title">
            Previous announcements
          </h4>

          <div className="announcements__previous">
            <div className="announcements__previous-item">
              <div className="d-flex flex-column">
                <span className="announcements__previous-item-title">
                  New GDPR checklist update
                </span>
                <span className="announcements__previous-item-text">
                  Your company has a list of places where it keeps personal
                  information and the ways data flows between them.
                </span>
              </div>
              <FaCaret />
            </div>

            <div className="announcements__previous-item">
              <div className="d-flex flex-column">
                <span className="announcements__previous-item-title">
                  New GDPR checklist update
                </span>
                <span className="announcements__previous-item-text">
                  Your company has a list of places where it keeps personal
                  information and the ways data flows between them.
                </span>
              </div>
              <FaCaret />
            </div>

            <div className="announcements__previous-item">
              <div className="d-flex flex-column">
                <span className="announcements__previous-item-title">
                  New GDPR checklist update
                </span>
                <span className="announcements__previous-item-text">
                  Your company has a list of places where it keeps personal
                  information and the ways data flows between them.
                </span>
              </div>
              <FaCaret />
            </div>

            <div className="announcements__previous-item">
              <div className="d-flex flex-column">
                <span className="announcements__previous-item-title">
                  New GDPR checklist update
                </span>
                <span className="announcements__previous-item-text">
                  Your company has a list of places where it keeps personal
                  information and the ways data flows between them.
                </span>
              </div>
              <FaCaret />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
