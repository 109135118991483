import gql from "graphql-tag";

export const UPDATE_PAGE_TEMPLATE = gql`
  mutation(
    $id: ID!
    $title: String!
    $description: String!
    $clientMutationId: String
  ) {
    editPageTemplate(
      input: {
        id: $id
        title: $title
        description: $description
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
