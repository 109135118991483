import gql from "graphql-tag";

export const SIDEMENU_QUERY = gql`
  {
    viewer {
      id
      appAdmin
    }
  }
`;
