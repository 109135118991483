import React, { Component } from "react";
import PropTypes from "prop-types";
import InboxItem from "./InboxItem";
import { graphql } from "@apollo/client/react/hoc";
import SyncLoader from "react-spinners/SyncLoader";
import { INBOX_QUERY } from "../../graphql/inbox";
import MdClose from "react-icons/lib/fa/angle-left";
import Switch from "react-switch";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-right";
import InfiniteScroll from "react-infinite-scroller";

class InboxMenu extends Component {
  static propTypes = {
    requests: PropTypes.array,
    selectedRequestId: PropTypes.string,
    showSection: PropTypes.func,
    filterRequest: PropTypes.func,
    showVerified: PropTypes.func,
    filterValue: PropTypes.string,
    fetchMoreData: PropTypes.func,
    hasMore: PropTypes.bool,
    diff: PropTypes.number,
    isMobileDevice: PropTypes.bool,
    inbox: PropTypes.object,
  };

  state = {
    checked: false,
    showMenu: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.inbox !== this.props.inbox) {
      this.props.inbox.refetch();
    }
  }

  refetchData = () => {
    this.props.inbox.refetch();
  };

  toggleVerified = (checked) => {
    this.setState({ checked });

    this.props.showVerified(checked);
  };

  render() {
    const {
      requests,
      selectedRequestId,
      showSection,
      filterValue,
      isMobileDevice,
    } = this.props;

    return isMobileDevice && !this.state.showMenu ? (
      <p
        onClick={() => this.setState({ showMenu: true })}
        className="position-absolute"
        style={{ zIndex: 999 }}
      >
        <FaCaret size={30} />
      </p>
    ) : (isMobileDevice && this.state.showMenu) || !isMobileDevice ? (
      <div className={`${isMobileDevice ? "-is-mobile" : ""} inbox__menu`}>
        {isMobileDevice ? (
          <strong
            className="d-flex align-items-center justify-content-end mb-2 mr-2"
            onClick={() => this.setState({ showMenu: false })}
          >
            <MdClose size={25} />
          </strong>
        ) : null}
        <div className="inbox__menu-header">
          <div className="inbox__menu-stats">
            <span className="inbox__menu-amount">
              {this.props.inbox &&
              this.props.inbox.requestPager &&
              this.props.inbox.requestPager.actionRequiredCount
                ? this.props.inbox.requestPager.actionRequiredCount
                : 0}
            </span>
            <span className="inbox__menu-text">Action required</span>
          </div>

          <div className="inbox__menu-divider" />

          <div className="inbox__menu-stats">
            <span className="inbox__menu-amount">
              {this.props.inbox &&
              this.props.inbox.requestPager &&
              this.props.inbox.requestPager.inProgressCount
                ? this.props.inbox.requestPager.inProgressCount
                : 0}
            </span>
            <span className="inbox__menu-text">In progress</span>
          </div>

          <div className="inbox__menu-divider" />

          <div className="inbox__menu-stats">
            <span className="inbox__menu-amount">
              {this.props.inbox &&
              this.props.inbox.requestPager &&
              this.props.inbox.requestPager.solvedCount
                ? this.props.inbox.requestPager.solvedCount
                : 0}
            </span>
            <span className="inbox__menu-text">Solved</span>
          </div>
        </div>

        <div className="d-flex inbox__menu-recent align-items-center justify-content-between">
          <UncontrolledDropdown>
            <DropdownToggle className="bg-transparent border-0 p-0 inbox__menu-recent-dropdown">
              <span className="inbox__menu-recent-text">
                {filterValue === "MOST_RECENT"
                  ? "Recent"
                  : filterValue === "DONT_SELL_DATA"
                  ? "Do not sell data"
                  : filterValue === "DATA_TRANSFER"
                  ? "Data transfer"
                  : filterValue === "URGENT"
                  ? "Urgent"
                  : filterValue === "ACTION_REQUIRED"
                  ? "Action Required"
                  : filterValue === "IN_PROGRESS"
                  ? "In Progress"
                  : filterValue === "SOLVED"
                  ? "Solved"
                  : filterValue === "DELAYED"
                  ? "Delayed"
                  : filterValue === "ARCHIVED"
                  ? "Archived"
                  : filterValue === "DATA_SUBJECT_REQUEST"
                  ? "Data Subject Request"
                  : filterValue === "DATA_CONTROLLER_REQUEST"
                  ? "Data Controller request"
                  : filterValue === "MANUALLY_CREATED"
                  ? "Manually created"
                  : filterValue === "DATA_ACCESS"
                  ? "Data access"
                  : filterValue === "QUESTION"
                  ? "Question"
                  : filterValue === "COMPLAINT"
                  ? "Complaint"
                  : filterValue === "ASSIGNED_TO_ME"
                  ? "Assigned to me"
                  : filterValue === "DATA_ERASURE"
                  ? "Data erasure"
                  : "Spam"}
              </span>
              <FaCaret color="#333" size="20" />
            </DropdownToggle>
            <DropdownMenu className="inbox__menu-status">
              <DropdownItem
                className="pl-4"
                onClick={() => this.props.filterRequest("MOST_RECENT")}
              >
                Most Recent
              </DropdownItem>
              <DropdownItem
                className="pl-4"
                onClick={() => this.props.filterRequest("ASSIGNED_TO_ME")}
              >
                Assigned to me
              </DropdownItem>
              <DropdownItem header>Source</DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("DATA_SUBJECT_REQUEST")}
              >
                Data Subject request
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  this.props.filterRequest("DATA_CONTROLLER_REQUEST")
                }
              >
                Data Controller request
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("MANUALLY_CREATED")}
              >
                Manually created
              </DropdownItem>
              <DropdownItem header>Status</DropdownItem>
              <DropdownItem onClick={() => this.props.filterRequest("URGENT")}>
                Urgent
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("ACTION_REQUIRED")}
              >
                Action required
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("IN_PROGRESS")}
              >
                In progress
              </DropdownItem>
              <DropdownItem onClick={() => this.props.filterRequest("SOLVED")}>
                Solved
              </DropdownItem>
              <DropdownItem onClick={() => this.props.filterRequest("DELAYED")}>
                Delayed
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("ARCHIVED")}
              >
                Archived
              </DropdownItem>
              <DropdownItem onClick={() => this.props.filterRequest("SPAM")}>
                Spam
              </DropdownItem>
              <DropdownItem header>Type</DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("DATA_ACCESS")}
              >
                Data access
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("QUESTION")}
              >
                Question
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("COMPLAINT")}
              >
                Complaint
              </DropdownItem>
              <DropdownItem
                className="mb-2"
                onClick={() => this.props.filterRequest("DATA_ERASURE")}
              >
                Data erasure
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("DATA_TRANSFER")}
              >
                Data transfer
              </DropdownItem>
              <DropdownItem
                onClick={() => this.props.filterRequest("DONT_SELL_DATA")}
              >
                Do not sell data
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          <div className="d-flex align-items-center">
            <span className="inbox__menu-verified">Verfied only</span>
            <Switch
              onColor={"#1DDCC0"}
              offColor={"#B0BAC9"}
              height={20}
              width={36}
              uncheckedIcon={false}
              checkedIcon={false}
              checked={this.state.checked}
              onChange={this.toggleVerified}
            />
          </div>
        </div>
        <div
          className="inbox__menu-content"
          ref={(ref) => (this.scrollParentRef = ref)}
        >
          <InfiniteScroll
            loadMore={this.props.fetchMoreData}
            hasMore={this.props.hasMore}
            loader={
              <SyncLoader
                css={`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 30px;
                  height: 70px;
                  position: relative;
                `}
                key={0}
                sizeUnit={"px"}
                size={10}
                color={"#7450c8"}
                loading={true}
              />
            }
            useWindow={false}
            getScrollParent={() => this.scrollParentRef}
          >
            <div>
              {requests
                ? requests.map((item, index) => (
                    <InboxItem
                      key={item.id}
                      item={item}
                      closeMenu={() => this.setState({ showMenu: false })}
                      id={item.id}
                      selectedRequestId={selectedRequestId}
                      showSection={showSection}
                      diff={this.props.diff}
                      index={index}
                    />
                  ))
                : null}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    ) : null;
  }
}

export default graphql(INBOX_QUERY, {
  name: "inbox",
  options: () => ({
    variables: {
      requestsStatus: "MOST_RECENT",
      requestsVerified: false,
    },
    fetchPolicy: "no-cache",
  }),
})(InboxMenu);
