import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import "./modals.scss";

export default class UserNotification extends Component {
  static propTypes = {
    showUserNotification: PropTypes.func.isRequired,
    isUserNotificationVisible: PropTypes.bool.isRequired,
    publishPage: PropTypes.func.isRequired,
    showPublishLegalSite: PropTypes.func.isRequired,
    accountId: PropTypes.string,
    name: PropTypes.string,
  };

  state = {};

  showLegalSite = () => {
    window.open(`/legalSites?id=${this.props.accountId}`);
    this.props.showPublishLegalSite();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isUserNotificationVisible}
          toggle={this.props.showUserNotification}
          className="notify-legalsite"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showUserNotification}
            />
            <p className="modal-assign__title mt-5">Notify your users</p>

            <p className="notify-legalsite__text">
              Updating your {this.props.name} requires that you notify all of
              your users so they can review it and give you consent again. You
              should send an e-mail notifying your users about the update.
            </p>

            <div className="d-flex">
              <Button
                className="btn--primary publish-legalsite__btn mr-2"
                onClick={() => {
                  this.props.publishPage();
                  this.props.showUserNotification();
                  this.props.showPublishLegalSite();
                }}
              >
                Publish
              </Button>
              <Button
                className="btn--secondary publish-legalsite__btn"
                onClick={this.props.showUserNotification}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
