import gql from "graphql-tag";

export const PUBLISH_PAGE = gql`
  mutation($pageId: ID!, $clientMutationId: String) {
    createPublication(
      input: { pageId: $pageId, clientMutationId: $clientMutationId }
    ) {
      errors
      publication {
        id
        title
        updatedAt
        createdAt
      }
    }
  }
`;
