import React, { Component } from "react";
import logo from "../../images/Login/PurpleLogo.svg";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import Check from "../../images/Settings/Check.svg";
import ReactSlider from "react-slider";
// import Slider from 'react-rangeslider';
// import 'react-rangeslider/lib/index.css';
import { SELECT_PLAN } from "../../graphql/selectPlan";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { CREATE_BUSINESS_SUBSCRIPTION } from "../../graphql/createBusinessSubscription";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import "./dashboard.scss";

class SelectPlan extends Component {
  state = {
    value: 5000,
    cbInstance: window.Chargebee.init({
      site: "legalsite",
    }),
  };

  handleChange = (value) => {
    this.setState({
      value: value,
    });
  };

  addDataSubjects = () => {
    if (this.state.value === 5000) {
      window.location.replace(
        `https://legalsite.chargebee.com/hosted_pages/plans/business-edition?customer[email]=${
          this.props.data.account && this.props.data.account.email
            ? this.props.data.account.email
            : ""
        }`
      );
    } else {
      this.props
        .addQuantity({
          variables: {
            quantity: this.state.value / 5000,
          },
        })
        .then(({ data }) => {
          window.location.replace(data.editDataSubjectsQuantity.chargebeeUrl);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  goToDashboard = () => {
    window.location.href = "/dashboard";
  };

  handlePortal = () => {
    this.state.cbInstance.createChargebeePortal().open({
      visit(visit) {
        console.log("portal visit", visit);
      },
    });
  };

  render() {
    const { value } = this.state;
    const { account } = this.props.data;

    if (account && account.subscription) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }

    return (
      <div className="select-plan">
        <div className="select-plan__header">
          <div className="select-plan__logo">
            <img src={logo} alt="logo" />
          </div>

          <h1 className="select-plan__title">Select a plan</h1>
        </div>
        <div className="select-plan__plans">
          <Row className="container mx-auto">
            <Col lg={7} sm={12} className="select-plan__plans-business">
              <span className="select-plan__plans-badge">
                <p>MOST POPULAR</p>
              </span>

              <p className="select-plan__plans-business-title">Business</p>

              <div className="d-flex justify-content-between align-items-center">
                <div className="select-plan__plans-business-upgrade">
                  <span className="select-plan__plans-business-upgrade-amount">
                    <sup>$</sup>29
                  </span>
                  <p className="select-plan__plans-business-upgrade-month">
                    per month
                  </p>

                  <p className="select-plan__plans-business-upgrade-subjects">
                    Add up to 5.000 <u id="ds9">Data Subjects</u>
                    <UncontrolledTooltip placement="auto" target="ds9">
                      What is a Data Subject? Anybody that you keep personal
                      identifiable information about (e.g. email adress, name,
                      address).
                    </UncontrolledTooltip>{" "}
                  </p>

                  <p className="select-plan__plans-business-upgrade-subjects">
                    + $20/month per 5.000 <u id="ds10">Data Subjects</u>
                    <UncontrolledTooltip placement="auto" target="ds10">
                      What is a Data Subject? Anybody that you keep personal
                      identifiable information about (e.g. email adress, name,
                      address).
                    </UncontrolledTooltip>{" "}
                  </p>
                </div>

                <div className="select-plan__plans-business-legal">
                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        Legal Pages
                      </p>
                      <p className="select-plan__plans-business-secondary">
                        Your LegalSite with all legal pages and pop-ups.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        Privacy Compliance
                      </p>
                      <p className="select-plan__plans-business-secondary">
                        All compliance features such as DPA, PIA and Breach
                        Incidents.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        Privacy Operations
                      </p>
                      <p className="select-plan__plans-business-secondary">
                        All privacy operations features to handle user requests
                        with legal inbox.
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        1.000 <u id="ds3">Data Subjects</u>
                        <UncontrolledTooltip placement="auto" target="ds3">
                          What is a Data Subject? Anybody that you keep personal
                          identifiable information about (e.g. email adress,
                          name, address).
                        </UncontrolledTooltip>{" "}
                        <span className="select-plan__plans-business-secondary">
                          included
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        Customization{" "}
                        <span className="select-plan__plans-business-secondary">
                          of LegalSite, Pop-ups and E-mails
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="d-flex align-items-start mb-4">
                    <img src={Check} alt="check" />
                    <div className="ml-3">
                      <p className="select-plan__plans-business-main">
                        VIP Support{" "}
                        <span className="select-plan__plans-business-secondary">
                          jump to the front of the line
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <p className="select-plan__plans-add-subtitle">
                Add <u id="ds2">Data Subjects</u>
                <UncontrolledTooltip placement="auto" target="ds2">
                  What is a Data Subject? Anybody that you keep personal
                  identifiable information about (e.g. email adress, name,
                  address).
                </UncontrolledTooltip>
              </p>

              <p className="select-plan__plans-add-content">
                How many <u id="ds">Data Subjects</u>
                <UncontrolledTooltip placement="auto" target="ds">
                  What is a Data Subject? Anybody that you keep personal
                  identifiable information about (e.g. email adress, name,
                  address).
                </UncontrolledTooltip>{" "}
                does your company have?
                <br />
                Add + $20/month per 5.000 <u id="ds4">Data Subjects</u>{" "}
                <UncontrolledTooltip placement="auto" target="ds4">
                  What is a Data Subject? Anybody that you keep personal
                  identifiable information about (e.g. email adress, name,
                  address).
                </UncontrolledTooltip>
              </p>

              <div className="slider w-75">
                <div className="value text-white">{value.toLocaleString()}</div>
                <ReactSlider
                  min={5000}
                  max={150000}
                  step={5000}
                  value={value}
                  onChange={this.handleChange}
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props) => <div {...props} />}
                />
              </div>

              <div className="d-flex justify-content-center align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-column">
                  <span className="select-plan__plans-add-value text-white">
                    Total{" "}
                  </span>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <span className="select-plan__plans-add-total-value text-white">
                        <sup>$</sup>
                        {value === 5000
                          ? 29
                          : ((value / 5000) * 20 + 29 - 20).toLocaleString()}
                      </span>
                      <span className="select-plan__plans-add-monthly text-white">
                        per month
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center w-100">
                <Button
                  className="btn--outline mt-3 mb-5"
                  onClick={this.addDataSubjects}
                >
                  Choose this plan
                </Button>
              </div>
            </Col>
            <Col lg={5} sm={12}>
              <div className="select-plan__plans-free">
                <p className="select-plan__plans-free-title">Free</p>

                <Button
                  onClick={this.goToDashboard}
                  className="btn--secondary mb-3"
                >
                  Go to Dashboard
                </Button>

                <div className="d-flex align-items-center mb-4">
                  <img src={Check} alt="check" />
                  <p className="mb-0 ml-3 select-plan__plans-free-text">
                    Add up to 1.000 <u>Data Subjects</u>
                  </p>
                </div>

                <div className="d-flex align-items-center">
                  <img src={Check} alt="check" />
                  <p className="mb-0 ml-3 select-plan__plans-free-text">
                    Branded LegalSite, e-mails and pop-ups
                  </p>
                </div>
              </div>
              <div className="select-plan__plans-free">
                <p className="select-plan__plans-free-title">Enterprise</p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://legalsite.co/contact-enterprise.html"
                  className="btn--secondary mb-5 d-flex align-items-center justify-content-center"
                >
                  Contact us
                </a>
                <div className="d-flex align-items-center mb-4">
                  <p className="mb-0 ml-3 select-plan__plans-free-text">
                    Let’s talk!
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <p className="select-plan__questions">
            Questions or special requirements?
          </p>
          <p className="select-plan__message">
            Leave a message and we’ll get in touch soon.
          </p>

          <div className="d-flex w-100 justify-content-center mt-5 mb-5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://legalsite.co/contact.html"
              className="btn--secondary mb-5 d-flex align-items-center justify-content-center"
            >
              <Button className="btn--secondary">Contact</Button>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(SELECT_PLAN),
  graphql(CREATE_BUSINESS_SUBSCRIPTION, {
    name: "addQuantity",
  })
)(SelectPlan);
SelectPlan.propTypes = {
  data: {
    account: PropTypes.object,
  },
  addQuantity: PropTypes.func,
};
