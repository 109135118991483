import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import "./help.scss";
import Robot from "../../components/Icons/Robot";
import Info from "../../components/Icons/Info";
import Contact from "../../components/Icons/Contact";
import Feedback from "../../components/Icons/Feedback";
import Learn from "../../components/Icons/Learn";
import Newspaper from "../../components/Icons/Newspaper";
import Announcements from "./Announcements";
import FeedbackModal from "../../components/Modals/FeedbackModal";
import Chat from "./Chat";
import { Redirect } from "react-router-dom";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import EmptyAnnouncements from "./EmptyAnnouncements";

export default class Help extends Component {
  static propTypes = {};

  state = {
    isAnnouncements: false,
    isFeedbackModalVisible: false,
    isContactOpen: false,
    isRighMenuOpen: false,
    restartOnboarding: false,
    announcementData: null,
  };

  showAnnouncements = () => {
    this.setState({
      isAnnouncements: !this.state.isAnnouncements,
    });
  };

  showFeedbackModal = () => {
    this.setState({
      isFeedbackModalVisible: !this.state.isFeedbackModalVisible,
    });
  };

  toggleContact = () => {
    this.setState({
      isContactOpen: !this.state.isContactOpen,
    });
  };

  restartOnboarding = () => {
    this.setState({
      restartOnboarding: true,
    });
  };

  render() {
    const { isAnnouncements, announcementData } = this.state;

    if (this.state.restartOnboarding === true) {
      return (
        <Redirect
          to={{ pathname: "/dashboard", state: { restartOnboarding: true } }}
        />
      );
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu />
        <div className="dashboard w-100">
          <Navbar
            title="Help"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          {!isAnnouncements ? (
            <div className="help">
              <div className="help__bg" />
              <div className="container help__container">
                <Row className="help__items">
                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={this.restartOnboarding}
                  >
                    <Robot />
                    <span className="help__title">Getting started</span>
                    <span className="help__info">
                      Click to restart the onboarding and see the checklist.
                    </span>
                  </Col>

                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={() =>
                      window.open("https://legalsite.co/faq.html", "_blank")
                    }
                  >
                    <Info />
                    <span className="help__title">FAQ</span>
                    <span className="help__info">
                      See the most frequently asked questions.
                    </span>
                  </Col>

                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={() =>
                      window.open("http://legalsite.co/contact.html", "_blank")
                    }
                  >
                    <Contact />
                    <span className="help__title">Contact us</span>
                    <span className="help__info">
                      Send us your question and we’ll be in touch soon.
                    </span>
                  </Col>

                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={() =>
                      window.open("http://legalsite.co/contact.html", "_blank")
                    }
                  >
                    <Feedback />
                    <span className="help__title">Feedback</span>
                    <span className="help__info">
                      Help us improve LegalSite by giving your feedback!
                    </span>
                  </Col>

                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={() =>
                      window.open(
                        "https://legalsite.co/privacy-compliance.html",
                        "_blank"
                      )
                    }
                  >
                    <Learn />
                    <span className="help__title">Learn more</span>
                    <span className="help__info">
                      Learn about the GDPR, Privacy compliance and other
                      requirements.
                    </span>
                  </Col>

                  <Col
                    className="help__item"
                    sm="6"
                    md="4"
                    onClick={this.showAnnouncements}
                  >
                    <Newspaper />
                    <span className="help__title">Announcements</span>
                    <span className="help__info">
                      Check the latest news we shared.
                    </span>
                  </Col>
                </Row>

                <Chat
                  isContactOpen={this.state.isContactOpen}
                  toggleContact={this.toggleContact}
                />
              </div>
            </div>
          ) : announcementData ? (
            <Announcements />
          ) : (
            <EmptyAnnouncements />
          )}

          <FeedbackModal
            isFeedbackModalVisible={this.state.isFeedbackModalVisible}
            showFeedbackModal={this.showFeedbackModal}
          />
        </div>
      </div>
    );
  }
}
