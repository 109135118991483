import gql from "graphql-tag";

export const REMOVE_DATA_PROCESSOR_ADMIN = gql`
  mutation($id: ID!, $clientMutationId: String) {
    removeDataProcessorAdmin(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
      dataProcessor {
        id
      }
    }
  }
`;
