import React, { Component } from "react";
import Check from "react-icons/lib/fa/check";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

export default class ConsequencesBreach extends Component {
  static propTypes = {
    potentialConsequences: PropTypes.array,
    additionalInformation: PropTypes.string,
    potentialConsequencesData: PropTypes.func,
    additionalInformationData: PropTypes.func,
  };

  state = {
    isLossControl: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Loss control") > -1
        ? true
        : false
      : null,
    isLimitation: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Limitation") > -1
        ? true
        : false
      : null,
    isDiscrimination: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Discrimination") > -1
        ? true
        : false
      : null,
    isIdentity: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Identity theft") > -1
        ? true
        : false
      : null,
    isFraud: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Fraud") > -1
        ? true
        : false
      : null,
    isFinancial: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Financial loss") > -1
        ? true
        : false
      : null,
    isUnauthorised: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Unauthorised reversal") > -1
        ? true
        : false
      : null,
    isDamage: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Damage reputation") > -1
        ? true
        : false
      : null,
    isLoss: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Loss of confidentiality") > -1
        ? true
        : false
      : null,
    isOther: this.props.potentialConsequences
      ? this.props.potentialConsequences.indexOf("Loss control") < 0 ||
        this.props.potentialConsequences.indexOf("Limitation") < 0 ||
        this.props.potentialConsequences.indexOf("Discrimination") < 0 ||
        this.props.potentialConsequences.indexOf("Identity theft") < 0 ||
        this.props.potentialConsequences.indexOf("Fraud") < 0 ||
        this.props.potentialConsequences.indexOf("Financial loss") < 0 ||
        this.props.potentialConsequences.indexOf("Unauthorised reversal") < 0 ||
        this.props.potentialConsequences.indexOf("Damage reputation") < 0 ||
        this.props.potentialConsequences.indexOf("Loss of confidentiality") < 0
        ? true
        : false
      : null,
    otherDescription: null,
  };

  getConsequences = () => {
    this.props.potentialConsequencesData([
      this.state.isLossControl === true ? "Loss control" : null,
      this.state.isLimitation === true ? "Limitation" : null,
      this.state.isDiscrimination === true ? "Discrimination" : null,
      this.state.isIdentity === true ? "Identity theft" : null,
      this.state.isFraud === true ? "Fraud" : null,
      this.state.isFinancial === true ? "Financial loss" : null,
      this.state.isUnauthorised === true ? "Unauthorised reversal" : null,
      this.state.isDamage === true ? "Damage reputation" : null,
      this.state.isLoss === true ? "Loss of confidentiality" : null,
      this.state.otherDescription !== null ? this.state.otherDescription : null,
    ]);
  };

  render() {
    const { additionalInformation } = this.props;

    const info = this.props.potentialConsequences
      ? this.props.potentialConsequences.filter(
          (item) =>
            item !== "Loss control" &&
            item !== "Limitation" &&
            item !== "Discrimination" &&
            item !== "Identity theft" &&
            item !== "Fraud" &&
            item !== "Financial loss" &&
            item !== "Unauthorised reversal" &&
            item !== "Damage reputation" &&
            item !== "Loss of confidentiality"
        )
      : "";

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title mb-3">
          What in your view are the potential consequences of the breach for
          affected individuals?
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isLossControl ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isLossControl: !this.state.isLossControl }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Loss of control over their personal data
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isLimitation ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isLimitation: !this.state.isLimitation }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Limitation of their rights
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDiscrimination ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                { isDiscrimination: !this.state.isDiscrimination },
                () => this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Discrimination
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isIdentity ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isIdentity: !this.state.isIdentity }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Identity theft
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isFraud ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isFraud: !this.state.isFraud }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">Fraud</span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isFinancial ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isFinancial: !this.state.isFinancial }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Financial loss
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isUnauthorised ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                { isUnauthorised: !this.state.isUnauthorised },
                () => this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Unauthorised reversal of pseudonymisation
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDamage ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isDamage: !this.state.isDamage }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Damage to reputation
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${this.state.isLoss ? "-checked" : "-unchecked"}`}
            onClick={() => {
              this.setState({ isLoss: !this.state.isLoss }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Loss of confidentiality of personal data protected by professional
              secrecy
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isOther ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isOther: !this.state.isOther }, () =>
                this.getConsequences()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">Other</span>
          </div>
        </div>

        <Input
          type="text"
          className="compliance-breaches__where-input"
          defaultValue={info ? info : ""}
          placeholder="Please describe"
          disabled={!this.state.isOther}
          onChange={(e) => {
            this.setState({ otherDescription: e.target.value }, () =>
              this.getConsequences()
            );
          }}
        />

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            Add any information you want on your report about this new breach
            incident
          </h5>
          <Input
            type="textarea"
            defaultValue={additionalInformation}
            placeholder="Write a message..."
            onChange={(e) =>
              this.props.additionalInformationData(e.target.value)
            }
          />
        </div>
      </div>
    );
  }
}
