import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Alert, Col } from "reactstrap";
import RightSideMenu from "./RightSideMenu";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { LEGALSITE_QUERY } from "../../graphql/legalSite";
import { CUSTOMIZE_LEGALSITE } from "../../graphql/customizeLegalsite";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import CustomizationSiteItem from "./CustomizationSiteItem";
import { ToastContainer, toast } from "react-toastify";
import CustomizationSitePage from "./CustomizationSitePage";
import ManageItem from "./ManageItem";
import Fonts from "./Fonts";
import FontLoader from "./FontLoader";

class CustomizeLegalSite extends Component {
  static propTypes = {
    showCustomization: PropTypes.func.isRequired,
    legalsite: PropTypes.object.isRequired,
    customizeLegalsite: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    showLegalInformation: PropTypes.func.isRequired,
    footerLinkAlign: PropTypes.string,
    footerLinkBold: PropTypes.bool,
    footerLinkItalic: PropTypes.bool,
    getInitialPage: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    accountData: PropTypes.object.isRequired,
    notifyMsg: PropTypes.func.isRequired,
  };

  state = {
    isPreview: false,
    contactBg: null,
    btnBg: null,
    welcomeBg: null,
    bg: null,
    titleColor: null,
    titleSize: null,
    bodyColor: null,
    bodySize: null,
    subtitleColor: null,
    subtitleSize: null,
    btnColor: null,
    siteBtnSize: null,
    navigationFont: null,
    navigationSize: null,
    subtitleFont: null,
    titleFont: null,
    bodyFont: null,
    footerFont: null,
    buttonFont: null,
    linkColor: null,
    navigationLinksAlign: null,
    navigationLinksBold: null,
    navigationLinksItalic: null,
    navigationBg: null,
    headerTitleColor: null,
    headerTitleSize: null,
    headerTitleFont: null,
    headerTitleAlign: null,
    headerTitleItalic: null,
    headerTitleBold: null,
    headerBodyItalic: null,
    headerBodyAlign: null,
    headerBodyBold: null,
    headerBodyColor: null,
    headerBodyFont: null,
    headerBodySize: null,
    headerBg: null,
    cardsBg: null,
    cardsTitleSize: null,
    cardsIconsColor: null,
    cardsTitleColor: null,
    homepageDescription: null,
    homepageTitle: null,
    cardsTitleFont: null,
    cardsBodyFont: null,
    cardsBodySize: null,
    cardsBodyColor: null,
    cardsBtnColor: null,
    cardsBtnFont: null,
    cardsBtnSize: null,
    cardsBodyAlign: null,
    cardsBodyBold: null,
    cardsBodyItalic: null,
    cardsBtnAlign: null,
    cardsBtnBold: null,
    cardsBtnItalic: null,
    cardsTitleAlign: null,
    cardsTitleBold: null,
    cardsTitleItalic: null,
    bodyBtnBg: null,
    cardsBtnBg: null,
    bodyBg: null,
    bodyTitleColor: null,
    bodyTitleFont: null,
    bodyTitleSize: null,
    bodyTitleAlign: null,
    bodyTitleBold: null,
    bodyTitleItalic: null,
    bodySubtitleColor: null,
    bodySubtitleSize: null,
    bodySubtitleFont: null,
    bodySubtitleAlign: null,
    bodySubtitleBold: null,
    bodySubtitleItalic: null,
    bodyTextColor: null,
    bodyTextSize: null,
    bodyTextFont: null,
    bodyTextAlign: null,
    bodyTextBold: null,
    bodyTextItalic: null,
    bodyBtnColor: null,
    bodyBtnSize: null,
    bodyBtnFont: null,
    bodyBtnAlign: null,
    bodyBtnBold: null,
    bodyBtnItalic: null,
    bodyIconsColor: null,
    footerBg: null,
    footerTextColor: null,
    footerTextSize: null,
    footerTextFont: null,
    footerTextAlign: null,
    footerTextBold: null,
    footerTextItalic: null,
    footerLinkColor: null,
    footerLinkSize: null,
    footerLinkFont: null,
    footerLinkAlign: null,
    footerLinkBold: null,
    footerLinkItalic: null,
  };

  getGoogleFont = (name, font) => {
    this.setState({
      [name]: font,
    });
  };

  notify = (text) => toast(text);

  changeTextColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeFontSize = (name, size) => {
    this.setState({
      [name]: parseInt(size),
    });
  };

  changeBgColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  submitLegalsite = () => {
    const { legalsite } = this.props.legalsite;

    const navigationLinksColor = this.state.linkColor
        ? this.state.linkColor
        : legalsite.navigationLinksColor,
      bodyBtnBg = this.state.bodyBtnBg
        ? this.state.bodyBtnBg
        : legalsite.bodyBtnBg,
      navigationLinksSize = this.state.navigationSize
        ? this.state.navigationSize
        : legalsite.navigationLinksSize,
      navigationLinksFont = this.state.navigationFont
        ? this.state.navigationFont
        : legalsite.navigationLinksFont,
      navigationLinksAlign = this.state.navigationLinksAlign
        ? this.state.navigationLinksAlign
        : legalsite.navigationLinksAlign,
      navigationLinksItalic =
        this.state.navigationLinksItalic !== null
          ? this.state.navigationLinksItalic
          : legalsite.navigationLinksItalic,
      navigationLinksBold =
        this.state.navigationLinksBold !== null
          ? this.state.navigationLinksBold
          : legalsite.navigationLinksBold,
      navigationBg = this.state.navigationBg
        ? this.state.navigationBg
        : legalsite.navigationBg,
      headerTitleColor = this.state.headerTitleColor
        ? this.state.headerTitleColor
        : legalsite.headerTitleColor,
      homepageTitle = this.state.homepageTitle
        ? this.state.homepageTitle
        : legalsite && legalsite.homepageTitle
        ? legalsite.homepageTitle
        : "Welcome!",
      homepageDescription = this.state.homepageDescription
        ? this.state.homepageDescription
        : legalsite && legalsite.homepageDescription
        ? legalsite.homepageDescription
        : "It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need.",
      headerTitleFont = this.state.headerTitleFont
        ? this.state.headerTitleFont
        : legalsite.headerTitleFont,
      headerTitleSize = this.state.headerTitleSize
        ? this.state.headerTitleSize
        : legalsite.headerTitleSize,
      headerTitleAlign =
        this.state.headerTitleAlign !== null
          ? this.state.headerTitleAlign
          : legalsite.headerTitleAlign,
      headerTitleItalic =
        this.state.headerTitleItalic !== null
          ? this.state.headerTitleItalic
          : legalsite.headerTitleItalic,
      headerTitleBold =
        this.state.headerTitleBold !== null
          ? this.state.headerTitleBold
          : legalsite.headerTitleBold,
      headerBodyFont = this.state.headerBodyFont
        ? this.state.headerBodyFont
        : legalsite.headerBodyFont,
      headerBodySize = this.state.headerBodySize
        ? this.state.headerBodySize
        : legalsite.headerBodySize,
      headerBodyColor = this.state.headerBodyColor
        ? this.state.headerBodyColor
        : legalsite.headerBodyColor,
      headerBodyAlign =
        this.state.headerBodyAlign !== null
          ? this.state.headerBodyAlign
          : legalsite.headerBodyAlign,
      headerBodyItalic =
        this.state.headerBodyItalic !== null
          ? this.state.headerBodyItalic
          : legalsite.headerBodyItalic,
      headerBodyBold =
        this.state.headerBodyBold !== null
          ? this.state.headerBodyBold
          : legalsite.headerBodyBold,
      headerBg = this.state.headerBg ? this.state.headerBg : legalsite.headerBg,
      cardsTitleColor = this.state.cardsTitleColor
        ? this.state.cardsTitleColor
        : legalsite.cardsTitleColor,
      cardsTitleSize = this.state.cardsTitleSize
        ? this.state.cardsTitleSize
        : legalsite.cardsTitleSize,
      cardsTitleFont = this.state.cardsTitleFont
        ? this.state.cardsTitleFont
        : legalsite.cardsTitleFont,
      cardsTitleAlign =
        this.state.cardsTitleAlign !== null
          ? this.state.cardsTitleAlign
          : legalsite.cardsTitleAlign,
      cardsTitleItalic =
        this.state.cardsTitleItalic !== null
          ? this.state.cardsTitleItalic
          : legalsite.cardsTitleItalic,
      cardsTitleBold =
        this.state.cardsTitleBold !== null
          ? this.state.cardsTitleBold
          : legalsite.cardsTitleBold,
      cardsBodyColor = this.state.cardsBodyColor
        ? this.state.cardsBodyColor
        : legalsite.cardsBodyColor,
      cardsBodySize = this.state.cardsBodySize
        ? this.state.cardsBodySize
        : legalsite.cardsBodySize,
      cardsBodyFont = this.state.cardsBodyFont
        ? this.state.cardsBodyFont
        : legalsite.cardsBodyFont,
      cardsBodyAlign =
        this.state.cardsBodyAlign !== null
          ? this.state.cardsBodyAlign
          : legalsite.cardsBodyAlign,
      cardsBodyItalic =
        this.state.cardsBodyItalic !== null
          ? this.state.cardsBodyItalic
          : legalsite.cardsBodyItalic,
      cardsBodyBold =
        this.state.cardsBodyBold !== null
          ? this.state.cardsBodyBold
          : legalsite.cardsBodyBold,
      cardsBg = this.state.cardsBg ? this.state.cardsBg : legalsite.cardsBg,
      cardsIconsColor = this.state.cardsIconsColor
        ? this.state.cardsIconsColor
        : legalsite.cardsIconsColor,
      cardsBtnBg = this.state.cardsBtnBg
        ? this.state.cardsBtnBg
        : legalsite.cardsBtnBg,
      cardsBtnColor = this.state.cardsBtnColor
        ? this.state.cardsBtnColor
        : legalsite.cardsBtnColor,
      cardsBtnFont = this.state.cardsBtnFont
        ? this.state.cardsBtnFont
        : legalsite.cardsBtnFont,
      cardsBtnSize = this.state.cardsBtnSize
        ? this.state.cardsBtnSize
        : legalsite.cardsBtnSize,
      cardsBtnAlign =
        this.state.cardsBtnAlign !== null
          ? this.state.cardsBtnAlign
          : legalsite.cardsBtnAlign,
      cardsBtnBold =
        this.state.cardsBtnBold !== null
          ? this.state.cardsBtnBold
          : legalsite.cardsBtnBold,
      cardsBtnItalic =
        this.state.cardsBtnItalic !== null
          ? this.state.cardsBtnItalic
          : legalsite.cardsBtnItalic,
      bodyTitleFont = this.state.bodyTitleFont
        ? this.state.bodyTitleFont
        : legalsite.bodyTitleFont,
      bodyTitleSize = this.state.bodyTitleSize
        ? this.state.bodyTitleSize
        : legalsite.bodyTitleSize,
      bodyTitleColor = this.state.bodyTitleColor
        ? this.state.bodyTitleColor
        : legalsite.bodyTitleColor,
      bodyTitleAlign =
        this.state.bodyTitleAlign !== null
          ? this.state.bodyTitleAlign
          : legalsite.bodyTitleAlign,
      bodyTitleBold =
        this.state.bodyTitleBold !== null
          ? this.state.bodyTitleBold
          : legalsite.bodyTitleBold,
      bodyTitleItalic =
        this.state.bodyTitleItalic !== null
          ? this.state.bodyTitleItalic
          : legalsite.bodyTitleItalic,
      bodySubtitleSize = this.state.bodySubtitleSize
        ? this.state.bodySubtitleSize
        : legalsite.bodySubtitleSize,
      bodySubtitleColor = this.state.bodySubtitleColor
        ? this.state.bodySubtitleColor
        : legalsite.bodySubtitleColor,
      bodySubtitleFont = this.state.bodySubtitleFont
        ? this.state.bodySubtitleFont
        : legalsite.bodySubtitleFont,
      bodySubtitleAlign =
        this.state.bodySubtitleAlign !== null
          ? this.state.bodySubtitleAlign
          : legalsite.bodySubtitleAlign,
      bodySubtitleBold =
        this.state.bodySubtitleBold !== null
          ? this.state.bodySubtitleBold
          : legalsite.bodySubtitleBold,
      bodySubtitleItalic =
        this.state.bodySubtitleItalic !== null
          ? this.state.bodySubtitleItalic
          : legalsite.bodySubtitleItalic,
      bodyTextSize = this.state.bodyTextSize
        ? this.state.bodyTextSize
        : legalsite.bodyTextSize,
      bodyTextFont = this.state.bodyTextFont
        ? this.state.bodyTextFont
        : legalsite.bodyTextFont,
      bodyTextColor = this.state.bodyTextColor
        ? this.state.bodyTextColor
        : legalsite.bodyTextColor,
      bodyTextAlign =
        this.state.bodyTextAlign !== null
          ? this.state.bodyTextAlign
          : legalsite.bodyTextAlign,
      bodyTextBold =
        this.state.bodyTextBold !== null
          ? this.state.bodyTextBold
          : legalsite.bodyTextBold,
      bodyTextItalic =
        this.state.bodyTextItalic !== null
          ? this.state.bodyTextItalic
          : legalsite.bodyTextItalic,
      bodyBg = this.state.bodyBg ? this.state.bodyBg : legalsite.bodyBg,
      bodyIconsColor = this.state.bodyIconsColor
        ? this.state.bodyIconsColor
        : legalsite.bodyIconsColor,
      bodyBtnColor = this.state.bodyBtnColor
        ? this.state.bodyBtnColor
        : legalsite.bodyBtnColor,
      bodyBtnSize = this.state.bodyBtnSize
        ? this.state.bodyBtnSize
        : legalsite.bodyBtnSize,
      bodyBtnFont = this.state.bodyBtnFont
        ? this.state.bodyBtnFont
        : legalsite.bodyBtnFont,
      bodyBtnAlign =
        this.state.bodyBtnAlign !== null
          ? this.state.bodyBtnAlign
          : legalsite.bodyBtnAlign,
      bodyBtnBold =
        this.state.bodyBtnBold !== null
          ? this.state.bodyBtnBold
          : legalsite.bodyBtnBold,
      bodyBtnItalic =
        this.state.bodyBtnItalic !== null
          ? this.state.bodyBtnItalic
          : legalsite.bodyBtnItalic,
      footerLogo = this.state.footerLogo
        ? this.state.footerLogo
        : legalsite.footerLogo,
      footerLinksColor = this.state.footerLinkColor
        ? this.state.footerLinkColor
        : legalsite.footerLinksColor,
      footerLinksSize = this.state.footerLinkSize
        ? this.state.footerLinkSize
        : legalsite.footerLinksSize,
      footerLinksFont = this.state.footerLinkFont
        ? this.state.footerLinkFont
        : legalsite.footerLinksFont,
      footerLinksAlign =
        this.state.footerLinkAlign !== null
          ? this.state.footerLinkAlign
          : legalsite.footerLinksAlign,
      footerLinksBold =
        this.state.footerLinkBold !== null
          ? this.state.footerLinkBold
          : legalsite.footerLinksBold,
      footerLinksItalic =
        this.state.footerLinkItalic !== null
          ? this.state.footerLinkItalic
          : legalsite.footerLinksItalic,
      footerBg = this.state.footerBg ? this.state.footerBg : legalsite.footerBg,
      footerTextColor = this.state.footerTextColor
        ? this.state.footerTextColor
        : legalsite.footerTextColor,
      footerTextFont = this.state.footerTextFont
        ? this.state.footerTextFont
        : legalsite.footerTextFont,
      footerTextSize = this.state.footerTextSize
        ? this.state.footerTextSize
        : legalsite.footerTextSize,
      footerTextAlign =
        this.state.footerTextAlign !== null
          ? this.state.footerTextAlign
          : legalsite.footerTextAlign,
      footerTextBold =
        this.state.footerTextBold !== null
          ? this.state.footerTextBold
          : legalsite.footerTextBold,
      footerTextItalic =
        this.state.footerTextItalic !== null
          ? this.state.footerTextItalic
          : legalsite.footerTextItalic;

    this.props
      .customizeLegalsite({
        variables: {
          navigationLinksColor,
          navigationLinksSize,
          navigationLinksFont,
          navigationLinksAlign,
          navigationLinksItalic,
          navigationLinksBold,
          navigationBg,
          headerTitleColor,
          headerTitleFont,
          headerTitleSize,
          headerTitleAlign,
          homepageTitle,
          homepageDescription,
          headerTitleItalic,
          headerTitleBold,
          headerBodyFont,
          headerBodySize,
          headerBodyColor,
          headerBodyAlign,
          headerBodyItalic,
          headerBodyBold,
          headerBg,
          bodyBtnBg,
          cardsTitleColor,
          cardsTitleSize,
          cardsTitleFont,
          cardsTitleAlign,
          cardsTitleItalic,
          cardsTitleBold,
          cardsBodyColor,
          cardsBodySize,
          cardsBodyFont,
          cardsBodyAlign,
          cardsBodyItalic,
          cardsBodyBold,
          cardsBg,
          cardsIconsColor,
          cardsBtnBg,
          cardsBtnColor,
          cardsBtnFont,
          cardsBtnSize,
          cardsBtnAlign,
          cardsBtnBold,
          cardsBtnItalic,
          bodyTitleFont,
          bodyTitleSize,
          bodyTitleColor,
          bodyTitleAlign,
          bodyTitleBold,
          bodyTitleItalic,
          bodySubtitleSize,
          bodySubtitleColor,
          bodySubtitleFont,
          bodySubtitleAlign,
          bodySubtitleBold,
          bodySubtitleItalic,
          bodyTextSize,
          bodyTextFont,
          bodyTextColor,
          bodyTextAlign,
          bodyTextBold,
          bodyTextItalic,
          bodyBg,
          bodyIconsColor,
          bodyBtnColor,
          bodyBtnSize,
          bodyBtnFont,
          bodyBtnAlign,
          bodyBtnBold,
          bodyBtnItalic,
          footerLogo,
          footerLinksColor,
          footerLinksSize,
          footerLinksFont,
          footerLinksAlign,
          footerLinksBold,
          footerLinksItalic,
          footerBg,
          footerTextColor,
          footerTextFont,
          footerTextSize,
          footerTextAlign,
          footerTextBold,
          footerTextItalic,
        },
      })
      .then(() => {
        this.props.legalsite.refetch();
        this.props.viewer.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  refetchData = () => {
    this.props.legalsite.refetch();
  };

  getAligned = (name, align) => {
    this.setState({
      [name]: align,
    });
  };

  changeText = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getBold = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getItalic = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      isPreview,
      contactBg,
      btnBg,
      welcomeBg,
      bg,
      titleColor,
      titleSize,
      bodyColor,
      bodySize,
      subtitleColor,
      subtitleSize,
      btnColor,
      siteBtnSize,
      navigationSize,
      titleFont,
      bodyFont,
      footerFont,
      buttonFont,
      linkColor,
      bodyBtnBg,
      navigationFont,
      navigationLinksAlign,
      navigationLinksBold,
      navigationLinksItalic,
      navigationBg,
      headerTitleColor,
      headerTitleSize,
      headerTitleFont,
      headerTitleAlign,
      headerTitleBold,
      headerTitleItalic,
      headerBodyItalic,
      headerBodyAlign,
      headerBodyBold,
      headerBodyColor,
      headerBodyFont,
      headerBodySize,
      headerBg,
      cardsBg,
      cardsBodySize,
      cardsIconsColor,
      cardsTitleColor,
      cardsTitleFont,
      cardsTitleSize,
      cardsBodyFont,
      cardsBodyColor,
      cardsBtnColor,
      cardsBtnFont,
      cardsBtnSize,
      cardsBodyAlign,
      cardsBodyBold,
      cardsBodyItalic,
      cardsBtnAlign,
      cardsBtnBold,
      cardsBtnItalic,
      cardsTitleAlign,
      cardsTitleBold,
      cardsTitleItalic,
      cardsBtnBg,
      bodyBg,
      bodyTitleColor,
      bodyTitleFont,
      bodyTitleSize,
      bodyTitleAlign,
      bodyTitleBold,
      bodyTitleItalic,
      bodySubtitleColor,
      bodySubtitleSize,
      bodySubtitleFont,
      bodySubtitleAlign,
      bodySubtitleBold,
      bodySubtitleItalic,
      bodyTextColor,
      bodyTextSize,
      bodyTextFont,
      bodyTextAlign,
      bodyTextBold,
      bodyTextItalic,
      bodyBtnColor,
      bodyBtnSize,
      bodyBtnFont,
      bodyBtnAlign,
      bodyBtnBold,
      bodyBtnItalic,
      bodyIconsColor,
      footerBg,
      footerTextColor,
      footerTextSize,
      footerTextFont,
      footerTextAlign,
      footerTextBold,
      homepageTitle,
      homepageDescription,
      footerTextItalic,
      footerLinkColor,
      footerLinkSize,
      footerLinkFont,
      footerLinkAlign,
      footerLinkBold,
      footerLinkItalic,
    } = this.state;
    const {
      pages,
      legalsite,
      dataProtectionOfficer,
      legalsiteSettings,
      dontSellMyDataButton,
      loading,
      error,
    } = this.props.legalsite;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <Fragment>
        {legalsite ? (
          <div
            className={`${
              isPreview ? "justify-content-center" : "justify-content-between"
            } d-flex w-100`}
          >
            <Fonts
              font1={
                navigationFont
                  ? navigationFont
                  : legalsite.navigationLinksFont
                  ? legalsite.navigationLinksFont
                  : "Roboto"
              }
              font2={
                headerTitleFont
                  ? headerTitleFont
                  : legalsite.headerTitleFont
                  ? legalsite.headerTitleFont
                  : "Roboto"
              }
              font3={
                headerBodyFont
                  ? headerBodyFont
                  : legalsite.headerBodyFont
                  ? legalsite.headerBodyFont
                  : "Roboto"
              }
              font4={
                cardsTitleFont
                  ? cardsTitleFont
                  : legalsite.cardsTitleFont
                  ? legalsite.cardsTitleFont
                  : "Roboto"
              }
              font5={
                cardsBodyFont
                  ? cardsBodyFont
                  : legalsite.cardsBodyFont
                  ? legalsite.cardsBodyFont
                  : "Roboto"
              }
              font6={
                cardsBtnFont
                  ? cardsBtnFont
                  : legalsite.cardsBtnFont
                  ? legalsite.cardsBtnFont
                  : "Roboto"
              }
              font7={
                bodyTitleFont
                  ? bodyTitleFont
                  : legalsite.bodyTitleFont
                  ? legalsite.bodyTitleFont
                  : "Roboto"
              }
              font8={
                bodySubtitleFont
                  ? bodySubtitleFont
                  : legalsite.bodySubtitleFont
                  ? legalsite.bodySubtitleFont
                  : "Roboto"
              }
              font9={
                bodyTextFont
                  ? bodyTextFont
                  : legalsite.bodyTextFont
                  ? legalsite.bodyTextFont
                  : "Roboto"
              }
              font10={
                bodyBtnFont
                  ? bodyBtnFont
                  : legalsite.bodyBtnFont
                  ? legalsite.bodyBtnFont
                  : "Roboto"
              }
              font11={
                footerLinkFont
                  ? footerLinkFont
                  : legalsite.footerLinksFont
                  ? legalsite.footerLinksFont
                  : "Roboto"
              }
              font12={
                footerTextFont
                  ? footerTextFont
                  : legalsite.footerTextFont
                  ? legalsite.footerTextFont
                  : "Roboto"
              }
            />

            <FontLoader
              font1={
                navigationFont
                  ? navigationFont
                  : legalsite.navigationLinksFont
                  ? legalsite.navigationLinksFont
                  : "Roboto"
              }
              font2={
                headerTitleFont
                  ? headerTitleFont
                  : legalsite.headerTitleFont
                  ? legalsite.headerTitleFont
                  : "Roboto"
              }
              font3={
                headerBodyFont
                  ? headerBodyFont
                  : legalsite.headerBodyFont
                  ? legalsite.headerBodyFont
                  : "Roboto"
              }
              font4={
                dontSellMyDataButton && dontSellMyDataButton.textFont
                  ? dontSellMyDataButton.textFont
                  : "Roboto"
              }
            />

            <div className="customization-site">
              <div
                className="customization-site__header"
                style={{
                  backgroundColor: navigationBg
                    ? navigationBg
                    : legalsite.navigationBg,
                }}
              >
                <div
                  className={`${
                    legalsiteSettings && legalsiteSettings.companyLogo
                      ? "-no-opacity"
                      : ""
                  } customization-site__logo d-flex align-items-center`}
                >
                  {legalsiteSettings && legalsiteSettings.companyLogo ? (
                    <img src={legalsiteSettings.companyLogo} alt="logo" />
                  ) : (
                    <Fragment>
                      <span className="customization-site__logo-img" />
                      YOUR LOGO
                    </Fragment>
                  )}
                </div>

                <p
                  className="customization-site__get"
                  style={{
                    borderColor: linkColor
                      ? linkColor
                      : legalsite.navigationLinksColor,
                    color: linkColor
                      ? linkColor
                      : legalsite.navigationLinksColor,
                    fontSize: navigationSize
                      ? navigationSize
                      : legalsite.navigationLinksSize,
                    fontFamily: navigationFont
                      ? navigationFont
                      : legalsite.navigationLinksFont,
                    textAlign: navigationLinksAlign
                      ? navigationLinksAlign
                      : legalsite.navigationLinksAlign,
                    fontWeight:
                      navigationLinksBold !== null
                        ? navigationLinksBold === true
                          ? 900
                          : 300
                        : legalsite.navigationLinksBold
                        ? 900
                        : 300,
                    fontStyle:
                      navigationLinksItalic !== null
                        ? navigationLinksItalic === true
                          ? "italic"
                          : "normal"
                        : legalsite.navigationLinksItalic
                        ? "italic"
                        : "normal",
                  }}
                >
                  Submit a request
                </p>
              </div>
              <div className="customization-site__container">
                <div
                  className="customization-site__welcome-section"
                  style={{
                    background: headerBg ? headerBg : legalsite.headerBg,
                  }}
                >
                  <h1
                    className="customization-site__welcome-title"
                    style={{
                      color: headerTitleColor
                        ? headerTitleColor
                        : legalsite.headerTitleColor,
                      fontSize: headerTitleSize
                        ? headerTitleSize
                        : legalsite.headerTitleSize,
                      fontFamily: headerTitleFont
                        ? headerTitleFont
                        : legalsite.headerTitleFont,
                      textAlign:
                        headerTitleAlign !== null
                          ? headerTitleAlign
                          : legalsite.headerTitleAlign,
                      fontWeight:
                        headerTitleBold !== null
                          ? headerTitleBold === true
                            ? 900
                            : 300
                          : legalsite.headerTitleBold
                          ? 900
                          : 300,
                      fontStyle:
                        headerTitleItalic !== null
                          ? headerTitleItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite.headerTitleItalic
                          ? "italic"
                          : "normal",
                    }}
                  >
                    {homepageTitle
                      ? homepageTitle
                      : legalsite && legalsite.homepageTitle
                      ? legalsite.homepageTitle
                      : "Welcome!"}
                  </h1>
                  <p
                    className="customization-site__welcome-text"
                    style={{
                      color: headerBodyColor
                        ? headerBodyColor
                        : legalsite.headerBodyColor,
                      fontSize: headerBodySize
                        ? headerBodySize
                        : legalsite.headerBodySize,
                      fontFamily: headerBodyFont
                        ? headerBodyFont
                        : legalsite.headerBodyFont,
                      textAlign:
                        headerBodyAlign !== null
                          ? headerBodyAlign
                          : legalsite.headerBodyAlign,
                      fontWeight:
                        headerBodyBold !== null
                          ? headerBodyBold === true
                            ? 900
                            : 300
                          : legalsite.headerBodyBold
                          ? 900
                          : 300,
                      fontStyle:
                        headerBodyItalic !== null
                          ? headerBodyItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite.headerBodyItalic
                          ? "italic"
                          : "normal",
                    }}
                  >
                    {homepageDescription
                      ? homepageDescription
                      : legalsite && legalsite.homepageDescription
                      ? legalsite.homepageDescription
                      : "It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need."}
                  </p>
                </div>

                <div
                  className="customization-site__info"
                  style={{ background: bodyBg ? bodyBg : legalsite.bodyBg }}
                >
                  <Row className="customization-site__info-content">
                    {pages
                      ? pages.map((page) =>
                          (page.applicable &&
                            page.title === "Terms & Conditions") ||
                          (page.applicable &&
                            page.title === "Privacy Policy") ? (
                            <CustomizationSitePage
                              key={page.id}
                              page={page}
                              contactBg={cardsBg}
                              cardsIconsColor={cardsIconsColor}
                              cardsTitleColor={cardsTitleColor}
                              cardsTitleFont={cardsTitleFont}
                              cardsTitleSize={cardsTitleSize}
                              cardsBtnBg={cardsBtnBg}
                              cardsBodyFont={cardsBodyFont}
                              cardsBodySize={cardsBodySize}
                              cardsBodyColor={cardsBodyColor}
                              cardsBtnColor={cardsBtnColor}
                              cardsBtnFont={cardsBtnFont}
                              cardsBtnSize={cardsBtnSize}
                              legalsite={legalsite}
                              btnBg={btnBg}
                              subtitleColor={subtitleColor}
                              subtitleSize={subtitleSize}
                              buttonFont={buttonFont}
                              bodyColor={bodyColor}
                              bodySize={bodySize}
                              bodyFont={bodyFont}
                              siteBtnSize={siteBtnSize}
                              footerFont={footerFont}
                              cardsBodyAlign={cardsBodyAlign}
                              cardsBodyBold={cardsBodyBold}
                              cardsBodyItalic={cardsBodyItalic}
                              cardsBtnAlign={cardsBtnAlign}
                              cardsBtnBold={cardsBtnBold}
                              cardsBtnItalic={cardsBtnItalic}
                              cardsTitleAlign={cardsTitleAlign}
                              cardsTitleBold={cardsTitleBold}
                              cardsTitleItalic={cardsTitleItalic}
                            />
                          ) : null
                        )
                      : null}

                    <CustomizationSitePage
                      isContact={true}
                      legalsite={legalsite}
                      cardsBtnBg={cardsBtnBg}
                      btnBg={btnBg}
                      subtitleColor={subtitleColor}
                      subtitleSize={subtitleSize}
                      buttonFont={buttonFont}
                      bodyColor={bodyColor}
                      bodySize={bodySize}
                      bodyFont={bodyFont}
                      siteBtnSize={siteBtnSize}
                      footerFont={footerFont}
                      contactBg={cardsBg}
                      cardsIconsColor={cardsIconsColor}
                      cardsTitleColor={cardsTitleColor}
                      cardsTitleFont={cardsTitleFont}
                      cardsTitleSize={cardsTitleSize}
                      cardsBodyFont={cardsBodyFont}
                      cardsBodySize={cardsBodySize}
                      cardsBodyColor={cardsBodyColor}
                      cardsBtnColor={cardsBtnColor}
                      cardsBtnFont={cardsBtnFont}
                      cardsBtnSize={cardsBtnSize}
                      cardsBodyAlign={cardsBodyAlign}
                      cardsBodyBold={cardsBodyBold}
                      cardsBodyItalic={cardsBodyItalic}
                      cardsBtnAlign={cardsBtnAlign}
                      cardsBtnBold={cardsBtnBold}
                      cardsBtnItalic={cardsBtnItalic}
                      cardsTitleAlign={cardsTitleAlign}
                      cardsTitleBold={cardsTitleBold}
                      cardsTitleItalic={cardsTitleItalic}
                    />
                  </Row>

                  <h3
                    className="customization-site__info-subtitle"
                    style={{
                      color: bodyTitleColor
                        ? bodyTitleColor
                        : legalsite.bodyTitleColor,
                      fontSize: bodyTitleSize
                        ? bodyTitleSize
                        : legalsite.bodyTitleSize,
                      fontFamily: bodyTitleFont
                        ? bodyTitleFont
                        : legalsite.bodyTitleFont,
                      textAlign:
                        bodyTitleAlign !== null
                          ? bodyTitleAlign
                          : legalsite.bodyTitleAlign,
                      fontWeight:
                        bodyTitleBold !== null
                          ? bodyTitleBold === true
                            ? 900
                            : 300
                          : legalsite.bodyTitleBold
                          ? 900
                          : 300,
                      fontStyle:
                        bodyTitleItalic !== null
                          ? bodyTitleItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite.bodyTitleItalic
                          ? "italic"
                          : "normal",
                    }}
                  >
                    Manage your information
                  </h3>
                  <p
                    className="customization-site__info-details px-2"
                    style={{
                      color: bodyTextColor
                        ? bodyTextColor
                        : legalsite.bodyTextColor,
                      fontSize: bodyTextSize
                        ? bodyTextSize
                        : legalsite.bodyTextSize,
                      fontFamily: bodyTextFont
                        ? bodyTextFont
                        : legalsite.bodyTextFont,
                      textAlign:
                        bodyTextAlign !== null
                          ? bodyTextAlign
                          : legalsite.bodyTextAlign,
                      fontWeight:
                        bodyTextBold !== null
                          ? bodyTextBold === true
                            ? 900
                            : 300
                          : legalsite.bodyTextBold
                          ? 900
                          : 300,

                      fontStyle:
                        bodyTextItalic !== null
                          ? bodyTextItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite.bodyTextItalic
                          ? "italic"
                          : "normal",
                    }}
                  >
                    When you interact with our service, we probably manage
                    information about you. You have the right to understand and
                    manage how this information is handled by us.
                  </p>

                  <div className="d-flex justify-content-center align-items-center flex-wrap mb-0">
                    <ManageItem
                      btnBg={btnBg}
                      legalsite={legalsite}
                      subtitleColor={subtitleColor}
                      bodyBtnBg={bodyBtnBg}
                      subtitleSize={subtitleSize}
                      buttonFont={buttonFont}
                      bodyColor={bodyColor}
                      bodySize={bodySize}
                      bodyFont={bodyFont}
                      btnColor={btnColor}
                      siteBtnSize={siteBtnSize}
                      footerFont={footerFont}
                      bodySubtitleColor={bodySubtitleColor}
                      bodySubtitleSize={bodySubtitleSize}
                      bodySubtitleFont={bodySubtitleFont}
                      bodySubtitleAlign={bodySubtitleAlign}
                      bodySubtitleBold={bodySubtitleBold}
                      bodySubtitleItalic={bodySubtitleItalic}
                      bodyBtnColor={bodyBtnColor}
                      bodyBtnSize={bodyBtnSize}
                      bodyBtnFont={bodyBtnFont}
                      bodyBtnAlign={bodyBtnAlign}
                      bodyBtnBold={bodyBtnBold}
                      bodyBtnItalic={bodyBtnItalic}
                      bodyTextColor={bodyTextColor}
                      bodyTextSize={bodyTextSize}
                      bodyTextFont={bodyTextFont}
                      bodyTextAlign={bodyTextAlign}
                      bodyTextBold={bodyTextBold}
                      bodyTextItalic={bodyTextItalic}
                      bodyIconsColor={bodyIconsColor}
                    />
                    <ManageItem
                      isManage={true}
                      bodyBtnBg={bodyBtnBg}
                      btnBg={btnBg}
                      legalsite={legalsite}
                      subtitleColor={subtitleColor}
                      subtitleSize={subtitleSize}
                      buttonFont={buttonFont}
                      bodyColor={bodyColor}
                      bodySize={bodySize}
                      bodyFont={bodyFont}
                      btnColor={btnColor}
                      siteBtnSize={siteBtnSize}
                      footerFont={footerFont}
                      bodySubtitleColor={bodySubtitleColor}
                      bodySubtitleSize={bodySubtitleSize}
                      bodySubtitleFont={bodySubtitleFont}
                      bodySubtitleAlign={bodySubtitleAlign}
                      bodySubtitleBold={bodySubtitleBold}
                      bodySubtitleItalic={bodySubtitleItalic}
                      bodyBtnColor={bodyBtnColor}
                      bodyBtnSize={bodyBtnSize}
                      bodyBtnFont={bodyBtnFont}
                      bodyBtnAlign={bodyBtnAlign}
                      bodyBtnBold={bodyBtnBold}
                      bodyBtnItalic={bodyBtnItalic}
                      bodyTextColor={bodyTextColor}
                      bodyTextSize={bodyTextSize}
                      bodyTextFont={bodyTextFont}
                      bodyTextAlign={bodyTextAlign}
                      bodyTextBold={bodyTextBold}
                      bodyTextItalic={bodyTextItalic}
                      bodyIconsColor={bodyIconsColor}
                    />
                  </div>

                  <div className="pb-3 customization-site__items-container">
                    {pages
                      ? pages.map((item) =>
                          item.applicable &&
                          item.title !== "Terms & Conditions" &&
                          item.title !== "Privacy Policy" ? (
                            <CustomizationSiteItem
                              key={item.id}
                              item={item}
                              showLegalInformation={
                                this.props.showLegalInformation
                              }
                              btnBg={btnBg}
                              legalsite={legalsite}
                              subtitleColor={subtitleColor}
                              subtitleSize={subtitleSize}
                              buttonFont={buttonFont}
                              bodyColor={bodyColor}
                              bodySize={bodySize}
                              bodyFont={bodyFont}
                              btnColor={btnColor}
                              siteBtnSize={siteBtnSize}
                              footerFont={footerFont}
                              contactBg={cardsBg}
                              cardsIconsColor={cardsIconsColor}
                              cardsTitleColor={cardsTitleColor}
                              cardsTitleFont={cardsTitleFont}
                              cardsTitleSize={cardsTitleSize}
                              cardsBodyFont={cardsBodyFont}
                              cardsBodySize={cardsBodySize}
                              cardsBodyColor={cardsBodyColor}
                              cardsBtnColor={cardsBtnColor}
                              cardsBtnFont={cardsBtnFont}
                              cardsBtnSize={cardsBtnSize}
                              cardsBodyAlign={cardsBodyAlign}
                              cardsBodyBold={cardsBodyBold}
                              cardsBodyItalic={cardsBodyItalic}
                              cardsBtnAlign={cardsBtnAlign}
                              cardsBtnBold={cardsBtnBold}
                              cardsBtnItalic={cardsBtnItalic}
                              cardsTitleAlign={cardsTitleAlign}
                              cardsTitleBold={cardsTitleBold}
                              cardsTitleItalic={cardsTitleItalic}
                              cardsBtnBg={cardsBtnBg}
                            />
                          ) : null
                        )
                      : null}

                    {dataProtectionOfficer && dataProtectionOfficer.show ? (
                      <CustomizationSiteItem
                        isDPO={true}
                        btnBg={btnBg}
                        showLegalInformation={this.props.showLegalInformation}
                        legalsite={legalsite}
                        subtitleColor={subtitleColor}
                        subtitleSize={subtitleSize}
                        buttonFont={buttonFont}
                        bodyColor={bodyColor}
                        bodySize={bodySize}
                        bodyFont={bodyFont}
                        btnColor={btnColor}
                        siteBtnSize={siteBtnSize}
                        footerFont={footerFont}
                        contactBg={cardsBg}
                        cardsIconsColor={cardsIconsColor}
                        cardsTitleColor={cardsTitleColor}
                        cardsTitleFont={cardsTitleFont}
                        cardsTitleSize={cardsTitleSize}
                        cardsBodyFont={cardsBodyFont}
                        cardsBodySize={cardsBodySize}
                        cardsBodyColor={cardsBodyColor}
                        cardsBtnColor={cardsBtnColor}
                        cardsBtnFont={cardsBtnFont}
                        cardsBtnSize={cardsBtnSize}
                        cardsBodyAlign={cardsBodyAlign}
                        cardsBodyBold={cardsBodyBold}
                        cardsBodyItalic={cardsBodyItalic}
                        cardsBtnAlign={cardsBtnAlign}
                        cardsBtnBold={cardsBtnBold}
                        cardsBtnItalic={cardsBtnItalic}
                        cardsTitleAlign={cardsTitleAlign}
                        cardsTitleBold={cardsTitleBold}
                        cardsTitleItalic={cardsTitleItalic}
                        cardsBtnBg={cardsBtnBg}
                      />
                    ) : null}
                  </div>
                </div>

                <div
                  className="footer"
                  style={{
                    background: footerBg ? footerBg : legalsite.footerBg,
                  }}
                >
                  <div
                    className="footer__container"
                    style={{
                      background: footerBg ? footerBg : legalsite.footerBg,
                    }}
                  >
                    <Row className="w-100">
                      <Col sm={12} md={3} className="d-flex flex-column">
                        <span className="d-flex align-items-center text-decoration-none">
                          {legalsiteSettings &&
                          legalsiteSettings.companyLogo &&
                          legalsite &&
                          !legalsite.footerLogo ? (
                            <div className="footer__logo-container">
                              <img
                                src={legalsiteSettings.companyLogo}
                                alt="logo"
                              />
                            </div>
                          ) : legalsite && legalsite.footerLogo ? (
                            <div className="footer__logo-container">
                              <img src={legalsite.footerLogo} alt="logo" />
                            </div>
                          ) : (
                            <span className="footer__logo" />
                          )}
                        </span>
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: footerTextColor
                              ? footerTextColor
                              : legalsite.footerTextColor,
                            fontSize: footerTextSize
                              ? footerTextSize
                              : legalsite.footerTextSize,
                            fontFamily: footerTextFont
                              ? footerTextFont
                              : legalsite.footerTextFont,
                            textAlign:
                              footerTextAlign !== null
                                ? footerTextAlign
                                : legalsite.footerTextAlign,
                            fontWeight:
                              footerTextBold !== null
                                ? footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerTextBold
                                ? 900
                                : 300,

                            fontStyle:
                              footerTextItalic !== null
                                ? footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerTextItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          <strong>Website</strong>
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: footerLinkColor
                              ? footerLinkColor
                              : legalsite.footerLinksColor,
                            fontSize: footerLinkSize
                              ? footerLinkSize
                              : legalsite.footerLinksSize,
                            fontFamily: footerLinkFont
                              ? footerLinkFont
                              : legalsite.footerLinksFont,
                            textAlign:
                              footerLinkAlign !== null
                                ? footerLinkAlign
                                : legalsite.footerLinksAlign,
                            fontWeight:
                              footerLinkBold !== null
                                ? footerLinkBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerLinksBold
                                ? 900
                                : 300,
                            fontStyle:
                              footerLinkItalic !== null
                                ? footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerLinksItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          Homepage
                        </p>
                        <p
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.legalsite.co/"
                          className="mb-4"
                          style={{
                            color: footerLinkColor
                              ? footerLinkColor
                              : legalsite.footerLinksColor,
                            fontSize: footerLinkSize
                              ? footerLinkSize
                              : legalsite.footerLinksSize,
                            fontFamily: footerLinkFont
                              ? footerLinkFont
                              : legalsite.footerLinksFont,
                            textAlign:
                              footerLinkAlign !== null
                                ? footerLinkAlign
                                : legalsite.footerLinksAlign,
                            fontWeight:
                              footerLinkBold !== null
                                ? footerLinkBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerLinksBold
                                ? 900
                                : 300,
                            fontStyle:
                              footerLinkItalic !== null
                                ? footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerLinksItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          Go back to main website
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: footerLinkColor
                              ? footerLinkColor
                              : legalsite.footerLinksColor,
                            fontSize: footerLinkSize
                              ? footerLinkSize
                              : legalsite.footerLinksSize,
                            fontFamily: footerLinkFont
                              ? footerLinkFont
                              : legalsite.footerLinksFont,
                            textAlign:
                              footerLinkAlign !== null
                                ? footerLinkAlign
                                : legalsite.footerLinksAlign,
                            fontWeight:
                              footerLinkBold !== null
                                ? footerLinkBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerLinksBold
                                ? 900
                                : 300,
                            fontStyle:
                              footerLinkItalic !== null
                                ? footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerLinksItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          Legal Information
                        </p>
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: footerTextColor
                              ? footerTextColor
                              : legalsite.footerTextColor,
                            fontSize: footerTextSize
                              ? footerTextSize
                              : legalsite.footerTextSize,
                            fontFamily: footerTextFont
                              ? footerTextFont
                              : legalsite.footerTextFont,
                            textAlign:
                              footerTextAlign !== null
                                ? footerTextAlign
                                : legalsite.footerTextAlign,
                            fontWeight:
                              footerTextBold !== null
                                ? footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerTextBold
                                ? 900
                                : 300,

                            fontStyle:
                              footerTextItalic !== null
                                ? footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerTextItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          <strong>Legal Pages</strong>
                        </p>
                        {pages
                          ? pages.map((item) => (
                              <p
                                className="mb-4"
                                style={{
                                  color: footerLinkColor
                                    ? footerLinkColor
                                    : legalsite.footerLinksColor,
                                  fontSize: footerLinkSize
                                    ? footerLinkSize
                                    : legalsite.footerLinksSize,
                                  fontFamily: footerLinkFont
                                    ? footerLinkFont
                                    : legalsite.footerLinksFont,
                                  textAlign:
                                    footerLinkAlign !== null
                                      ? footerLinkAlign
                                      : legalsite.footerLinksAlign,
                                  fontWeight:
                                    footerLinkBold !== null
                                      ? footerLinkBold === true
                                        ? 900
                                        : 300
                                      : legalsite.footerLinksBold
                                      ? 900
                                      : 300,
                                  fontStyle:
                                    footerLinkItalic !== null
                                      ? footerLinkItalic === true
                                        ? "italic"
                                        : "normal"
                                      : legalsite.footerLinksItalic
                                      ? "italic"
                                      : "normal",
                                }}
                                key={item.id}
                              >
                                {item.title}
                              </p>
                            ))
                          : null}
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: footerTextColor
                              ? footerTextColor
                              : legalsite.footerTextColor,
                            fontSize: footerTextSize
                              ? footerTextSize
                              : legalsite.footerTextSize,
                            fontFamily: footerTextFont
                              ? footerTextFont
                              : legalsite.footerTextFont,
                            textAlign:
                              footerTextAlign !== null
                                ? footerTextAlign
                                : legalsite.footerTextAlign,
                            fontWeight:
                              footerTextBold !== null
                                ? footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerTextBold
                                ? 900
                                : 300,

                            fontStyle:
                              footerTextItalic !== null
                                ? footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerTextItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          <strong>Manage your information</strong>
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: footerLinkColor
                              ? footerLinkColor
                              : legalsite.footerLinksColor,
                            fontSize: footerLinkSize
                              ? footerLinkSize
                              : legalsite.footerLinksSize,
                            fontFamily: footerLinkFont
                              ? footerLinkFont
                              : legalsite.footerLinksFont,
                            textAlign:
                              footerLinkAlign !== null
                                ? footerLinkAlign
                                : legalsite.footerLinksAlign,
                            fontWeight:
                              footerLinkBold !== null
                                ? footerLinkBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerLinksBold
                                ? 900
                                : 300,
                            fontStyle:
                              footerLinkItalic !== null
                                ? footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerLinksItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          Manage Consent
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: footerLinkColor
                              ? footerLinkColor
                              : legalsite.footerLinksColor,
                            fontSize: footerLinkSize
                              ? footerLinkSize
                              : legalsite.footerLinksSize,
                            fontFamily: footerLinkFont
                              ? footerLinkFont
                              : legalsite.footerLinksFont,
                            textAlign:
                              footerLinkAlign !== null
                                ? footerLinkAlign
                                : legalsite.footerLinksAlign,
                            fontWeight:
                              footerLinkBold !== null
                                ? footerLinkBold === true
                                  ? 900
                                  : 300
                                : legalsite.footerLinksBold
                                ? 900
                                : 300,
                            fontStyle:
                              footerLinkItalic !== null
                                ? footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite.footerLinksItalic
                                ? "italic"
                                : "normal",
                          }}
                        >
                          Send request
                        </p>
                        <div
                          className={`d-flex ${
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign === "center"
                                ? "justify-content-center"
                                : legalsite.footerLinksAlign === "left"
                                ? "justify-content-start"
                                : "justify-content-end"
                              : null
                          }`}
                        >
                          <p
                            className={`${
                              (dontSellMyDataButton &&
                                dontSellMyDataButton.buttonType ===
                                  "contained") ||
                              (dontSellMyDataButton &&
                                !dontSellMyDataButton.buttonType)
                                ? "-is-contained"
                                : ""
                            }`}
                            style={{
                              background:
                                (dontSellMyDataButton &&
                                  dontSellMyDataButton.buttonType ===
                                    "contained") ||
                                (dontSellMyDataButton &&
                                  !dontSellMyDataButton.buttonType)
                                  ? dontSellMyDataButton &&
                                    dontSellMyDataButton.color
                                    ? dontSellMyDataButton.color
                                    : "#7450c8"
                                  : null,
                              color:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textColor
                                  ? dontSellMyDataButton.textColor
                                  : "#fff",
                              fontSize:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textSize
                                  ? parseInt(dontSellMyDataButton.textSize)
                                  : 14,
                              fontFamily:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textFont
                                  ? dontSellMyDataButton.textFont
                                  : "Roboto",
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Do Not Sell My Info
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="footer-text"
                    style={{
                      background: footerBg ? footerBg : legalsite.footerBg,
                    }}
                  >
                    <span>
                      <span
                        style={{
                          color: footerTextColor
                            ? footerTextColor
                            : legalsite.footerTextColor,
                          fontSize: footerTextSize
                            ? footerTextSize
                            : legalsite.footerTextSize,
                          fontFamily: footerTextFont
                            ? footerTextFont
                            : legalsite.footerTextFont,
                          textAlign:
                            footerTextAlign !== null
                              ? footerTextAlign
                              : legalsite.footerTextAlign,
                          fontWeight:
                            footerTextBold !== null
                              ? footerTextBold === true
                                ? 900
                                : 300
                              : legalsite.footerTextBold
                              ? 900
                              : 300,

                          fontStyle:
                            footerTextItalic !== null
                              ? footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite.footerTextItalic
                              ? "italic"
                              : "normal",
                        }}
                      >
                        © {new Date().getFullYear()} LegalSite BV. All rights
                        reserved.
                      </span>{" "}
                      <span
                        style={{
                          color: footerLinkColor
                            ? footerLinkColor
                            : legalsite.footerLinksColor,
                          fontSize: footerLinkSize
                            ? footerLinkSize
                            : legalsite.footerLinksSize,
                          fontFamily: footerLinkFont
                            ? footerLinkFont
                            : legalsite.footerLinksFont,
                          textAlign:
                            footerLinkAlign !== null
                              ? footerLinkAlign
                              : legalsite.footerLinksAlign,
                          fontWeight:
                            footerLinkBold !== null
                              ? footerLinkBold === true
                                ? 900
                                : 300
                              : legalsite.footerLinksBold
                              ? 900
                              : 300,
                          fontStyle:
                            footerLinkItalic !== null
                              ? footerLinkItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite.footerLinksItalic
                              ? "italic"
                              : "normal",
                        }}
                      >
                        <u>Get your LegalSite!</u>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <RightSideMenu
              footerLogo={legalsite ? legalsite.footerLogo : ""}
              getInitialPage={this.props.getInitialPage}
              homepageDescription={
                homepageDescription
                  ? homepageDescription
                  : legalsite && legalsite.homepageDescription
                  ? legalsite.homepageDescription
                  : "It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need."
              }
              homepageTitle={
                homepageTitle
                  ? homepageTitle
                  : legalsite && legalsite.homepageTitle
                  ? legalsite.homepageTitle
                  : "Welcome!"
              }
              changeText={this.changeText}
              footerLinkColor={
                footerLinkColor ? footerLinkColor : legalsite.footerLinksColor
              }
              footerLinkSize={
                footerLinkSize ? footerLinkSize : legalsite.footerLinksSize
              }
              footerLinkFont={
                footerLinkFont ? footerLinkFont : legalsite.footerLinksFont
              }
              footerLinkAlign={
                footerLinkAlign !== null
                  ? footerLinkAlign
                  : legalsite.footerLinksAlign
              }
              footerLinkBold={
                footerLinkBold !== null
                  ? footerLinkBold
                  : legalsite.footerLinksBold
              }
              footerLinkItalic={
                footerLinkItalic !== null
                  ? footerLinkItalic
                  : legalsite.footerLinksItalic
              }
              footerTextColor={
                footerTextColor ? footerTextColor : legalsite.footerTextColor
              }
              footerTextSize={
                footerTextSize ? footerTextSize : legalsite.footerTextSize
              }
              footerTextFont={
                footerTextFont ? footerTextFont : legalsite.footerTextFont
              }
              footerTextAlign={
                footerTextAlign !== null
                  ? footerTextAlign
                  : legalsite.footerTextAlign
              }
              footerTextBold={
                footerTextBold !== null
                  ? footerTextBold
                  : legalsite.footerTextBold
              }
              footerTextItalic={
                footerTextItalic !== null
                  ? footerTextItalic
                  : legalsite.footerTextItalic
              }
              footerBg={footerBg ? footerBg : legalsite.footerBg}
              bodyIconsColor={
                bodyIconsColor ? bodyIconsColor : legalsite.bodyIconsColor
              }
              bodyTitleColor={
                bodyTitleColor ? bodyTitleColor : legalsite.bodyTitleColor
              }
              bodyTitleFont={
                bodyTitleFont ? bodyTitleFont : legalsite.bodyTitleFont
              }
              bodyTitleSize={
                bodyTitleSize ? bodyTitleSize : legalsite.bodyTitleSize
              }
              bodyTitleAlign={
                bodyTitleAlign !== null
                  ? bodyTitleAlign
                  : legalsite.bodyTitleAlign
              }
              bodyTitleBold={
                bodyTitleBold !== null ? bodyTitleBold : legalsite.bodyTitleBold
              }
              bodyTitleItalic={
                bodyTitleItalic !== null
                  ? bodyTitleItalic
                  : legalsite.bodyTitleItalic
              }
              bodySubtitleColor={
                bodySubtitleColor
                  ? bodySubtitleColor
                  : legalsite.bodySubtitleColor
              }
              bodySubtitleSize={
                bodySubtitleSize ? bodySubtitleSize : legalsite.bodySubtitleSize
              }
              bodySubtitleFont={
                bodySubtitleFont ? bodySubtitleFont : legalsite.bodySubtitleFont
              }
              bodySubtitleAlign={
                bodySubtitleAlign !== null
                  ? bodySubtitleAlign
                  : legalsite.bodySubtitleAlign
              }
              bodySubtitleBold={
                bodySubtitleBold !== null
                  ? bodySubtitleBold
                  : legalsite.bodySubtitleBold
              }
              bodySubtitleItalic={
                bodySubtitleItalic !== null
                  ? bodySubtitleItalic
                  : legalsite.bodySubtitleItalic
              }
              bodyTextColor={
                bodyTextColor ? bodyTextColor : legalsite.bodyTextColor
              }
              bodyTextSize={
                bodyTextSize ? bodyTextSize : legalsite.bodyTextSize
              }
              bodyTextFont={
                bodyTextFont ? bodyTextFont : legalsite.bodyTextFont
              }
              bodyTextAlign={
                bodyTextAlign !== null ? bodyTextAlign : legalsite.bodyTextAlign
              }
              bodyTextBold={
                bodyTextBold !== null ? bodyTextBold : legalsite.bodyTextBold
              }
              bodyTextItalic={
                bodyTextItalic !== null
                  ? bodyTextItalic
                  : legalsite.bodyTextItalic
              }
              bodyBtnColor={
                bodyBtnColor ? bodyBtnColor : legalsite.bodyBtnColor
              }
              bodyBtnSize={bodyBtnSize ? bodyBtnSize : legalsite.bodyBtnSize}
              bodyBtnFont={bodyBtnFont ? bodyBtnFont : legalsite.bodyBtnFont}
              bodyBtnAlign={
                bodyBtnAlign !== null ? bodyBtnAlign : legalsite.bodyBtnAlign
              }
              bodyBtnBold={
                bodyBtnBold !== null ? bodyBtnBold : legalsite.bodyBtnBold
              }
              bodyBtnItalic={
                bodyBtnItalic !== null ? bodyBtnItalic : legalsite.bodyBtnItalic
              }
              bodyBg={bodyBg ? bodyBg : legalsite.bodyBg}
              cardsBodyAlign={
                cardsBodyAlign !== null
                  ? cardsBodyAlign
                  : legalsite.cardsBodyAlign
              }
              cardsBodyBold={
                cardsBodyBold !== null ? cardsBodyBold : legalsite.cardsBodyBold
              }
              cardsBodyItalic={
                cardsBodyItalic !== null
                  ? cardsBodyItalic
                  : legalsite.cardsBodyItalic
              }
              cardsBtnAlign={
                cardsBtnAlign !== null ? cardsBtnAlign : legalsite.cardsBtnAlign
              }
              cardsBtnBold={
                cardsBtnBold !== null ? cardsBtnBold : legalsite.cardsBtnBold
              }
              cardsBtnItalic={
                cardsBtnItalic !== null
                  ? cardsBtnItalic
                  : legalsite.cardsBtnItalic
              }
              cardsTitleAlign={
                cardsTitleAlign !== null
                  ? cardsTitleAlign
                  : legalsite.cardsTitleAlign
              }
              cardsTitleBold={
                cardsTitleBold !== null
                  ? cardsTitleBold
                  : legalsite.cardsTitleBold
              }
              cardsTitleItalic={
                cardsTitleItalic !== null
                  ? cardsTitleItalic
                  : legalsite.cardsTitleItalic
              }
              cardsBg={cardsBg ? cardsBg : legalsite.cardsBg}
              cardsBodySize={
                cardsBodySize ? cardsBodySize : legalsite.cardsBodySize
              }
              cardsIconsColor={
                cardsIconsColor ? cardsIconsColor : legalsite.cardsIconsColor
              }
              cardsTitleColor={
                cardsTitleColor ? cardsTitleColor : legalsite.cardsTitleColor
              }
              cardsTitleFont={
                cardsTitleFont ? cardsTitleFont : legalsite.cardsTitleFont
              }
              cardsTitleSize={
                cardsTitleSize ? cardsTitleSize : legalsite.cardsTitleSize
              }
              cardsBodyFont={
                cardsBodyFont ? cardsBodyFont : legalsite.cardsBodyFont
              }
              cardsBodyColor={
                cardsBodyColor ? cardsBodyColor : legalsite.cardsBodyColor
              }
              cardsBtnColor={
                cardsBtnColor ? cardsBtnColor : legalsite.cardsBtnColor
              }
              cardsBtnFont={
                cardsBtnFont ? cardsBtnFont : legalsite.cardsBtnFont
              }
              cardsBtnSize={
                cardsBtnSize ? cardsBtnSize : legalsite.cardsBtnSize
              }
              cardsBtnBg={cardsBtnBg ? cardsBtnBg : legalsite.cardsBtnBg}
              headerBg={headerBg ? headerBg : legalsite.headerBg}
              headerBodySize={
                headerBodySize ? headerBodySize : legalsite.headerBodySize
              }
              headerBodyColor={
                headerBodyColor ? headerBodyColor : legalsite.headerBodyColor
              }
              headerBodyFont={
                headerBodyFont ? headerBodyFont : legalsite.headerBodyFont
              }
              headerBodyAlign={
                headerBodyAlign !== null
                  ? headerBodyAlign
                  : legalsite.headerBodyAlign
              }
              headerBodyBold={
                headerBodyBold !== null
                  ? headerBodyBold
                  : legalsite.headerBodyBold
              }
              headerBodyItalic={
                headerBodyItalic !== null
                  ? headerBodyItalic
                  : legalsite.headerBodyItalic
              }
              headerTitleSize={
                headerTitleSize ? headerTitleSize : legalsite.headerTitleSize
              }
              headerTitleColor={
                headerTitleColor ? headerTitleColor : legalsite.headerTitleColor
              }
              headerTitleFont={
                headerTitleFont ? headerTitleFont : legalsite.headerTitleFont
              }
              headerTitleAlign={
                headerTitleAlign !== null
                  ? headerTitleAlign
                  : legalsite.headerTitleAlign
              }
              headerTitleBold={
                headerTitleBold !== null
                  ? headerTitleBold
                  : legalsite.headerTitleBold
              }
              headerTitleItalic={
                headerTitleItalic !== null
                  ? headerTitleItalic
                  : legalsite.headerTitleItalic
              }
              getAligned={this.getAligned}
              getBold={this.getBold}
              getItalic={this.getItalic}
              navigationLinksAlign={
                navigationLinksAlign
                  ? navigationLinksAlign
                  : legalsite.navigationLinksAlign
              }
              navigationLinksBold={
                navigationLinksBold !== null
                  ? navigationLinksBold
                  : legalsite.navigationLinksBold
              }
              navigationLinksItalic={
                navigationLinksItalic !== null
                  ? navigationLinksItalic
                  : legalsite.navigationLinksItalic
              }
              navigationBg={
                navigationBg ? navigationBg : legalsite.navigationBg
              }
              bodyBtnBg={bodyBtnBg ? bodyBtnBg : legalsite.bodyBtnBg}
              isSiteCustomization={true}
              isCookieCustomization={false}
              isEmailCustomization={false}
              submitLegalsite={this.submitLegalsite}
              changeTextColor={this.changeTextColor}
              changeBgColor={this.changeBgColor}
              contactBg={contactBg ? contactBg : legalsite.backgroundColor}
              btnBg={btnBg ? btnBg : legalsite.backgroundButtonsColor}
              welcomeBg={welcomeBg ? welcomeBg : legalsite.background1}
              bg={bg ? bg : legalsite.background2}
              notifyMsg={this.props.notifyMsg}
              changeFontSize={this.changeFontSize}
              linkColor={linkColor ? linkColor : legalsite.navigationLinksColor}
              navigationFont={
                navigationFont ? navigationFont : legalsite.navigationLinksFont
              }
              navigationSize={
                navigationSize ? navigationSize : legalsite.navigationLinksSize
              }
              titleSiteColor={titleColor ? titleColor : legalsite.titleColor}
              titleSiteSize={titleSize ? titleSize : legalsite.titleSize}
              bodySiteColor={bodyColor ? bodyColor : legalsite.bodyColor}
              bodySiteSize={bodySize ? bodySize : legalsite.bodySize}
              subtitleColor={
                subtitleColor ? subtitleColor : legalsite.subtitleColor
              }
              subtitleSize={
                subtitleSize ? subtitleSize : legalsite.subtitleSize
              }
              siteBtnColor={btnColor ? btnColor : legalsite.buttonColor}
              siteBtnSize={siteBtnSize ? siteBtnSize : legalsite.buttonSize}
              siteTitleFont={titleFont ? titleFont : legalsite.titleFont}
              siteBodyFont={bodyFont ? bodyFont : legalsite.bodyFont}
              siteButtonFont={footerFont ? footerFont : legalsite.buttonFont}
              siteSubtitleFont={
                buttonFont ? buttonFont : legalsite.subtitleFont
              }
              getGoogleFont={this.getGoogleFont}
              account={this.props.account}
              refetchData={this.refetchData}
              accountData={this.props.accountData}
              dataProtectionOfficer={dataProtectionOfficer}
              legalsiteSettings={legalsiteSettings}
              legalsite={legalsite}
            />
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              newestOnTop={false}
              closeButton={false}
              closeOnClick
              rtl={false}
              draggable
              hideProgressBar
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default compose(
  graphql(LEGALSITE_QUERY, {
    name: "legalsite",
  }),
  graphql(CUSTOMIZE_LEGALSITE, {
    name: "customizeLegalsite",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(CustomizeLegalSite);
