import React, { Component } from "react";
import Lock from "../../components/Icons/Lock";
import { Input, Form, Button, FormGroup, FormFeedback } from "reactstrap";
import { toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { CHANGE_PASSWORD } from "../../graphql/changePassword";
import PropTypes from "prop-types";

class Password extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
  };
  state = {
    formErrors: {},
  };

  notify = (text) => toast(text);

  changePassword = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const currentPassword = formData.get("currentPassword"),
      password1 = formData.get("password1"),
      password2 = formData.get("password2");

    let hasFormErrors = false;
    let formErrors = {};

    if (!currentPassword) {
      hasFormErrors = true;
      formErrors.currentPassword = true;
    }

    if (!password1) {
      hasFormErrors = true;
      formErrors.password1 = true;
    }

    if (!password2) {
      hasFormErrors = true;
      formErrors.password2 = true;
    }

    if (password1 !== password2) {
      hasFormErrors = true;
      formErrors.confirm = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .changePassword({
        variables: {
          currentPassword,
          password1,
          password2,
        },
      })
      .then(() => {
        this.notify("Password changed!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Password couldn't be changed!");
      });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  render() {
    const { sectionVisible, showSection } = this.props;
    const isActive = sectionVisible === "password" ? true : false;

    return (
      <div
        // eslint-disable-next-line react/no-string-refs
        ref="password"
        className={`${isActive ? "-is-active" : ""} settings__account`}
        onClick={
          !isActive
            ? () => {
                showSection("password");
                this.scrollToSection("password");
              }
            : () => showSection("password")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <Lock />
          &nbsp; Password Settings
        </h2>
        <p className="settings__text">Update your password below.</p>

        {isActive ? (
          <div>
            <Form
              onSubmit={this.changePassword}
              className="settings__password-form"
            >
              <FormGroup className="mb-4">
                <Input
                  type="password"
                  className="mb-0"
                  invalid={this.state.formErrors.currentPassword}
                  placeholder="Type your current password"
                  name="currentPassword"
                />
                <FormFeedback>
                  Please provide your current password
                </FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  name="password1"
                  type="password"
                  className="mb-0"
                  invalid={this.state.formErrors.password1}
                  placeholder="New password"
                />
                <FormFeedback>Please provide a new password</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  name="password2"
                  className="mb-0"
                  type="password"
                  invalid={
                    this.state.formErrors.password2 ||
                    this.state.formErrors.confirm
                  }
                  placeholder="Confirm password"
                />
                <FormFeedback>
                  {this.state.formErrors.password2
                    ? "Please confirm your password"
                    : this.state.formErrors.confirm
                    ? "It doesn't match your password!"
                    : ""}
                </FormFeedback>
              </FormGroup>

              <Button className="settings__user-save btn--primary">
                Save changes
              </Button>
            </Form>
          </div>
        ) : null}
      </div>
    );
  }
}

export default graphql(CHANGE_PASSWORD, {
  name: "changePassword",
})(Password);
