import PropTypes from "prop-types";
import React from "react";

const Paperclip = props => (
    <svg
        height={16}
        viewBox='0 0 13 16'
        width={13}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-7---Help" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--contact-2" transform="translate(-1325.000000, -945.000000)" fill="#969696" fillRule="nonzero">
                <g id="Chatbot" transform="translate(1018.000000, 396.000000)">
                    <g id="write-bar" transform="translate(0.000000, 530.000000)">
                        <g id="icons" transform="translate(275.000000, 19.000000)">
                            <g id="paperclip" transform="translate(32.000000, 0.000000)">
                                <path d="M11.5563169,8.46801105 L6.87963435,13.5091947 C5.60686281,14.7265054 3.67303376,14.665241 2.46900267,13.3694644 C1.26497159,12.0736878 1.20804498,9.99250353 2.3391658,8.62274852 L8.39312386,2.10748699 C9.14374569,1.34013073 10.3213785,1.34013073 11.0720003,2.10748699 C11.8047925,2.90669397 11.8047925,4.19128323 11.0720003,4.99049021 L5.85046148,10.6017592 C5.70987487,10.7646967 5.49919603,10.8345401 5.29778565,10.7849799 C5.09637526,10.7354198 4.93483236,10.5739856 4.87400858,10.3614879 C4.81318481,10.1489903 4.86232074,9.91771272 5.00290735,9.75477521 L8.88500795,5.58500783 C9.18174434,5.26566051 9.18174434,4.74789623 8.88500795,4.42854892 C8.58827155,4.10920161 8.1071668,4.1092016 7.8104304,4.42854891 L3.92832979,8.61460444 C3.53598015,9.03357592 3.31530282,9.6037922 3.31530282,10.1986274 C3.31530282,10.7934626 3.53598015,11.3636789 3.92832979,11.7826504 C4.75253773,12.627566 6.04785895,12.627566 6.8720669,11.7826504 L12.0860383,6.16323729 C13.3362965,4.71924479 13.2994053,2.4690365 12.0025926,1.07340846 C10.70578,-0.322219568 8.61489437,-0.361921868 7.27314162,0.983604374 L1.21918356,7.4988659 C-0.476861177,9.52047282 -0.393605511,12.6207129 1.40809653,14.5335291 C3.20979857,16.4463454 6.09139612,16.4938063 7.94664445,14.6412214 L12.6308945,9.60818182 C12.8228493,9.40160033 12.8978162,9.10050125 12.8275559,8.81830568 C12.7572955,8.53611012 12.5524822,8.31569029 12.2902671,8.24007622 C12.0280519,8.16446215 11.7482717,8.24514141 11.5563169,8.4517229 L11.5563169,8.46801105 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Paperclip.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Paperclip;