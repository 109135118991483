import PropTypes from "prop-types";
import React from "react";
import { Col } from "reactstrap";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";

function PagesCardItem(props) {
  const { page, index } = props;
  return (
    <Col lg={3} md={12} className="pages-card__item">
      <div>
        <p className="pages-card__item-title">{page.title}</p>
        <p className="pages-card__item-update">
          Last update: <Moment fromNow>{page.updatedAt}</Moment>
        </p>
        <Link
          to={{ pathname: "/admin-page", state: { page, index } }}
          className="pages-card__item-edit"
        >
          Edit <MdArrow size={20} />
        </Link>
      </div>
    </Col>
  );
}

export default PagesCardItem;
PagesCardItem.propTypes = {
  page: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
