import React, { Fragment, Component } from "react";
import CustomiseColor from "./CustomiseColor";
import ReactFilestack from "filestack-react";
import CustomiseText from "./CustomiseText";
import { EDIT_LEGALSITE_SETTINGS } from "../../graphql/editLegalsiteSettings";
import { graphql } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

class CustomizeNavigation extends Component {
  static propTypes = {
    legalsiteSettings: PropTypes.object.isRequired,
    editLegalsiteSettings: PropTypes.func.isRequired,
    capitalize: PropTypes.func.isRequired,
    titleSiteColor: PropTypes.string,
    titleSiteSize: PropTypes.string,
    siteTitleFont: PropTypes.string,
    changeBgColor: PropTypes.func.isRequired,
    changeTextColor: PropTypes.func.isRequired,
    getGoogleFont: PropTypes.func.isRequired,
    changeFontSize: PropTypes.func.isRequired,
    getAligned: PropTypes.func.isRequired,
    getBold: PropTypes.func.isRequired,
    getItalic: PropTypes.func.isRequired,
    navigationLinksBold: PropTypes.bool,
    navigationLinksItalic: PropTypes.bool,
    navigationBg: PropTypes.string,
  };

  state = {
    files: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });

    const avatar = this.state.files.length
      ? this.state.files[0].url
      : this.props.legalsiteSettings.companyLogo
      ? this.props.legalsiteSettings.companyLogo
      : null;

    this.props
      .editLegalsiteSettings({
        variables: {
          companyLogo: avatar,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      legalsiteSettings,
      capitalize,
      titleSiteColor,
      titleSiteSize,
      siteTitleFont,
      changeBgColor,
      changeTextColor,
      getGoogleFont,
      changeFontSize,
    } = this.props;

    const { files } = this.state;

    return (
      <Fragment>
        <div className="customize-menu__logo-container">
          <p className="customize-menu__logo">Logo</p>
          <div className="d-flex align-items-center settings__legal-logo-container">
            <div className="position-relative">
              {files.length || (files.length && !legalsiteSettings) ? (
                files.map((file, i) => (
                  <div key={i} className="settings__avatar-container">
                    <img src={file.url} alt="avatar" />
                  </div>
                ))
              ) : !files.length &&
                legalsiteSettings &&
                legalsiteSettings.companyLogo ? (
                <div className="settings__avatar-container">
                  <img src={legalsiteSettings.companyLogo} alt="avatar" />
                </div>
              ) : (
                <span className="settings__image-placeholder" />
              )}

              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: <span />,
                  customClass: "show-upload",
                }}
                actionOptions={{
                  accept: ["image/*"],
                  maxFiles: 1,
                }}
                onSuccess={this.onFiles}
              />

              <p className="settings__legal-color mt-2">CHANGE LOGO</p>
            </div>
          </div>
        </div>
        <div>
          <CustomiseText
            title="Buttons"
            color={titleSiteColor ? titleSiteColor : "#7450C8"}
            size={titleSiteSize ? titleSiteSize : 14}
            isLegalsite={true}
            font={siteTitleFont ? capitalize(siteTitleFont) : "Roboto"}
            changeColor={changeTextColor}
            colorName="linkColor"
            fontSizeName="navigationSize"
            fontFamilyName="navigationFont"
            alignName="navigationLinksAlign"
            boldName="navigationLinksBold"
            italicName="navigationLinksItalic"
            getAligned={this.props.getAligned}
            getBold={this.props.getBold}
            getItalic={this.props.getItalic}
            getGoogleFont={getGoogleFont}
            changeFontSize={changeFontSize}
            boldValue={this.props.navigationLinksBold}
            italicValue={this.props.navigationLinksItalic}
            index={1}
            isNavigation={true}
          />
        </div>

        <div>
          <p className="customise-text__label">Background color</p>
          <CustomiseColor
            isLegalsite={true}
            changeColor={changeBgColor}
            name="navigationBg"
            color={this.props.navigationBg ? this.props.navigationBg : "#fff"}
            index={11}
          />
        </div>
      </Fragment>
    );
  }
}

export default graphql(EDIT_LEGALSITE_SETTINGS, {
  name: "editLegalsiteSettings",
})(CustomizeNavigation);
