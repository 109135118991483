import gql from "graphql-tag";

export const TOGGLE_CONSENTS = gql`
  mutation($consents: [ConsentObject!]!, $clientMutationId: String) {
    toggleConsents(
      input: { consents: $consents, clientMutationId: $clientMutationId }
    ) {
      errors
      consents {
        id
        enabled
      }
    }
  }
`;
