import gql from "graphql-tag";

export const UPDATE_PAGE = gql`
  mutation(
    $id: ID!
    $title: String
    $description: String
    $clientMutationId: String
  ) {
    editPage(
      input: {
        id: $id
        title: $title
        description: $description
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      page {
        id
        title
        description
        updatedAt
      }
    }
  }
`;
