import React, { Component } from "react";
import PropTypes from "prop-types";
import Check from "react-icons/lib/fa/check";
import { Input } from "reactstrap";

export default class CauseBreach extends Component {
  static propTypes = {
    description: PropTypes.string,
    causes: PropTypes.array,
    getCauses: PropTypes.func,
    getCauseDescription: PropTypes.func,
  };

  state = {
    isEmployeeError: this.props.causes
      ? this.props.causes.indexOf("Employee error") > -1
        ? true
        : false
      : null,
    isEmployeeIntentional: this.props.causes
      ? this.props.causes.indexOf("Employee intentional") > -1
        ? true
        : false
      : null,
    isContractorError: this.props.causes
      ? this.props.causes.indexOf("Contractor error") > -1
        ? true
        : false
      : null,
    isContractorIntentional: this.props.causes
      ? this.props.causes.indexOf("Contractor intentional") > -1
        ? true
        : false
      : null,
    isExternalIntentional: this.props.causes
      ? this.props.causes.indexOf("External intentional") > -1
        ? true
        : false
      : null,
    isExternalUnintentional: this.props.causes
      ? this.props.causes.indexOf("External unintentional") > -1
        ? true
        : false
      : null,
    isUnknown: this.props.causes
      ? this.props.causes.indexOf("Unknown") > -1
        ? true
        : false
      : null,
    description: this.props.description,
  };

  getCauses = () => {
    this.props.getCauses([
      this.state.isEmployeeError === true ? "Employee error" : null,
      this.state.isEmployeeIntentional ? "Employee intentional" : null,
      this.state.isContractorError ? "Contractor error" : null,
      this.state.isContractorIntentional ? "Contractor intentional" : null,
      this.state.isExternalIntentional ? "External intentional" : null,
      this.state.isExternalUnintentional ? "External unintentional" : null,
      this.state.isUnknown ? "Unknown" : null,
    ]);
  };

  render() {
    const { description } = this.state;

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title mb-3">
          Please select the cause of the Breach
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isEmployeeError ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                { isEmployeeError: !this.state.isEmployeeError },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Employee error or omission
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isEmployeeIntentional ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isEmployeeIntentional: !this.state.isEmployeeIntentional,
                },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Employee intentional act
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isContractorError ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isContractorError: !this.state.isContractorError,
                },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Contractor error or omission
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isContractorIntentional ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isContractorIntentional: !this.state.isContractorIntentional,
                },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Contractor intentional act
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isExternalIntentional ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isExternalIntentional: !this.state.isExternalIntentional,
                },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              External intentional act
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isExternalUnintentional ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState(
                {
                  isExternalUnintentional: !this.state.isExternalUnintentional,
                },
                () => this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              External unintentional act
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isUnknown ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isUnknown: !this.state.isUnknown }, () =>
                this.getCauses()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Unknown
            </span>
          </div>
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">
            What deficiencies in these organizational or technical measures have
            been identified as a result of this breach?
          </h5>
          <Input
            type="textarea"
            defaultValue={description}
            onChange={(e) => {
              this.setState({ description: e.target.value });
              this.props.getCauseDescription(e.target.value);
            }}
            placeholder="Write a message..."
          />
        </div>
      </div>
    );
  }
}
