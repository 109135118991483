import React from "react";
import Request from "../../images/Settings/Request.svg";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

function BrandingEmail(props) {
  const {
    legalsiteSettings,
    brandingSubtitleColor,
    brandingSubtitleSize,
    brandingSubtitleFont,
    brandingSubtitleAlign,
    brandingSubtitleBold,
    brandingSubtitleItalic,
    brandingBodyColor,
    brandingBodySize,
    brandingBodyFont,
    brandingBodyAlign,
    brandingBodyBold,
    brandingBodyItalic,
    brandingButtonColor,
    brandingButtonSize,
    brandingButtonFont,
    brandingButtonAlign,
    brandingButtonBold,
    brandingButtonItalic,
    brandingLinksColor,
    brandingLinksSize,
    brandingLinksFont,
    brandingLinksAlign,
    brandingLinksBold,
    brandingLinksItalic,
    bgColor,
    footerBg,
    btnBg,
  } = props;
  return (
    <div>
      <p className="customization-site__branding-title">Email template</p>

      <div className="customization-email customization-email__type -is-branding">
        <div
          className="customization-email__email-template mt-4"
          style={{
            background: bgColor ? bgColor : "#fff",
          }}
        >
          <div className="customization-email__header">
            {legalsiteSettings && legalsiteSettings.companyLogo ? (
              <div className="customization-email__logo-container d-flex align-items-center">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </div>
            ) : (
              <p className="customization-email__header-logo">Logo</p>
            )}

            <span className="customization-email__header-link">
              Open in browser
            </span>
          </div>
          <div>
            <div className="customization-email__banner position-relative">
              <img src={Request} alt="getbanner" />
            </div>

            <p
              className="customization-site__branding-subtitle border-0 px-5 mt-3"
              style={{
                color: brandingBodyColor ? brandingBodyColor : null,
                fontSize: brandingBodySize ? brandingBodySize : null,
                fontFamily: brandingBodyFont ? brandingBodyFont : null,
                textAlign:
                  brandingBodyAlign !== null ? brandingBodyAlign : null,
                fontWeight:
                  brandingBodyBold !== null
                    ? brandingBodyBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingBodyItalic !== null
                    ? brandingBodyItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              Your e-mail title
            </p>

            <p
              style={{
                color: brandingSubtitleColor ? brandingSubtitleColor : null,
                fontSize: brandingSubtitleSize ? brandingSubtitleSize : null,
                fontFamily: brandingSubtitleFont ? brandingSubtitleFont : null,
                textAlign:
                  brandingSubtitleAlign !== null ? brandingSubtitleAlign : null,
                fontWeight:
                  brandingSubtitleBold !== null
                    ? brandingSubtitleBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingSubtitleItalic !== null
                    ? brandingSubtitleItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
              className="text-left mt-4 customization-email__subtitle border-0 px-5"
            >
              This is a placeholder text. We’ll adapt this title, text and
              images to the e-mail, such as request received, solving requests,
              etc. This is a placeholder text. We’ll adapt this title, text and
              images to the e-mail, such as request received, solving requests,
              etc.
            </p>

            <Button
              className="customization-site__btn ml-5 mb-4"
              style={{
                color: brandingButtonColor ? brandingButtonColor : null,
                fontSize: brandingButtonSize ? brandingButtonSize : null,
                fontFamily: brandingButtonFont ? brandingButtonFont : null,
                textAlign:
                  brandingButtonAlign !== null ? brandingButtonAlign : null,
                backgroundColor: btnBg ? btnBg : "#7450C8",
                fontWeight:
                  brandingButtonBold !== null
                    ? brandingButtonBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingButtonItalic !== null
                    ? brandingButtonItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              Button
            </Button>

            <p
              className="customization-email__email-text border-0 mb-0"
              style={{
                color: brandingSubtitleColor ? brandingSubtitleColor : null,
                fontSize: brandingSubtitleSize ? brandingSubtitleSize : null,
                fontFamily: brandingSubtitleFont ? brandingSubtitleFont : null,
                textAlign:
                  brandingSubtitleAlign !== null ? brandingSubtitleAlign : null,
                fontWeight:
                  brandingSubtitleBold !== null
                    ? brandingSubtitleBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingSubtitleItalic !== null
                    ? brandingSubtitleItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              Best regards,
              <br />
              Company team.
            </p>
          </div>

          <div
            className="customization-email__footer"
            style={{ background: footerBg ? footerBg : null }}
          >
            <p
              className="customization-email__footer-text border-0"
              style={{
                color: brandingLinksColor ? brandingLinksColor : null,
                fontSize: brandingLinksSize ? brandingLinksSize : null,
                fontFamily: brandingLinksFont ? brandingLinksFont : null,
                textAlign:
                  brandingLinksAlign !== null ? brandingLinksAlign : null,
                fontWeight:
                  brandingLinksBold !== null
                    ? brandingLinksBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingLinksItalic !== null
                    ? brandingLinksItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              If at any time you have questions or remarks, please let us know.
            </p>

            <p
              className="customization-email__footer-link"
              style={{
                color: brandingLinksColor ? brandingLinksColor : null,
                fontSize: brandingLinksSize ? brandingLinksSize : null,
                fontFamily: brandingLinksFont ? brandingLinksFont : null,
                textAlign:
                  brandingLinksAlign !== null ? brandingLinksAlign : null,
                fontWeight:
                  brandingLinksBold !== null
                    ? brandingLinksBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingLinksItalic !== null
                    ? brandingLinksItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              Get your LegalSite
            </p>

            <span className="customization-email__footer-divider" />

            <p
              className="customization-email__footer-info"
              style={{
                color: brandingLinksColor ? brandingLinksColor : null,
                fontSize: brandingLinksSize ? brandingLinksSize : null,
                fontFamily: brandingLinksFont ? brandingLinksFont : null,
                textAlign:
                  brandingLinksAlign !== null ? brandingLinksAlign : null,
                fontWeight:
                  brandingLinksBold !== null
                    ? brandingLinksBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingLinksItalic !== null
                    ? brandingLinksItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              www.legalsite.com | LegalSite | All rights reserved{" "}
              {new Date().getFullYear()} | Terms & Conditions | Privacy Policy
            </p>

            <p
              className="customization-email__footer-unsubscribe"
              style={{
                color: brandingLinksColor ? brandingLinksColor : null,
                fontSize: brandingLinksSize ? brandingLinksSize : null,
                fontFamily: brandingLinksFont ? brandingLinksFont : null,
                textAlign:
                  brandingLinksAlign !== null ? brandingLinksAlign : null,
                fontWeight:
                  brandingLinksBold !== null
                    ? brandingLinksBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  brandingLinksItalic !== null
                    ? brandingLinksItalic === true
                      ? "italic"
                      : "normal"
                    : null,
              }}
            >
              Unsubscribe
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandingEmail;
BrandingEmail.propTypes = {
  legalsiteSettings: PropTypes.string,
  brandingSubtitleColor: PropTypes.string,
  brandingSubtitleSize: PropTypes.number,
  brandingSubtitleFont: PropTypes.string,
  brandingSubtitleAlign: PropTypes.string,
  brandingSubtitleBold: PropTypes.bool,
  brandingSubtitleItalic: PropTypes.bool,
  brandingBodyColor: PropTypes.string,
  brandingBodySize: PropTypes.number,
  brandingBodyFont: PropTypes.string,
  brandingBodyAlign: PropTypes.string,
  brandingBodyBold: PropTypes.bool,
  brandingBodyItalic: PropTypes.bool,
  brandingButtonColor: PropTypes.string,
  brandingButtonSize: PropTypes.number,
  brandingButtonFont: PropTypes.string,
  brandingButtonAlign: PropTypes.string,
  brandingButtonBold: PropTypes.bool,
  brandingButtonItalic: PropTypes.bool,
  brandingLinksColor: PropTypes.string,
  brandingLinksSize: PropTypes.number,
  brandingLinksFont: PropTypes.string,
  brandingLinksAlign: PropTypes.string,
  brandingLinksBold: PropTypes.bool,
  brandingLinksItalic: PropTypes.bool,
  bgColor: PropTypes.string,
  footerBg: PropTypes.string,
  btnBg: PropTypes.string,
};
