import React, { Component } from "react";
import { EMAILS_QUERY } from "../../graphql/emails";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import Step2Email from "../Settings/Step2Email";
import { CUSTOMIZE_EMAILS } from "../../graphql/editEmails";
import PropTypes from "prop-types";

class EmailBreach extends Component {
  static propTypes = {
    data: PropTypes.object,
    customizeEmails: PropTypes.func,
    emailType: PropTypes.string,
    sectionActive: PropTypes.string,
    updateBody: PropTypes.func,
    updateFooterText: PropTypes.func,
    updateEmailText: PropTypes.func,
    updatedBody: PropTypes.string,
    updatedFooterText: PropTypes.string,
    emailText: PropTypes.string,
  };

  state = {
    emailType: null,
  };

  getTitle = () => {
    const { sectionActive, emailType } = this.props;

    if (
      (emailType && emailType === "users") ||
      sectionActive === "your-users"
    ) {
      this.props.data.refetch({ emailType: "DATA_BREACH_USERS" });

      return "E-mail your users";
    } else if (
      (emailType && emailType === "companies") ||
      sectionActive === "interested"
    ) {
      this.props.data.refetch({ emailType: "DATA_BREACH_OTHER_COMPANIES" });

      return "E-mail interested companies";
    } else if (
      (emailType && emailType === "company") ||
      sectionActive === "own-company"
    ) {
      this.props.data.refetch({ emailType: "DATA_BREACH_OWN_COMPANY" });

      return "E-mail your own company";
    }
  };

  getText = () => {
    const { sectionActive, emailType } = this.props;

    if ((emailType && emailType === "users") || sectionActive === "your-users")
      return "This is the e-mail we’ll send to your users to notify about the data breach incident.";
    else if (
      (emailType && emailType === "companies") ||
      sectionActive === "interested"
    )
      return "This is the e-mail we’ll send to interested companies to notify about the data breach incident.";
    else if (
      (emailType && emailType === "company") ||
      sectionActive === "own-company"
    )
      return "This is the e-mail we’ll send to your own company to notify about the data breach incident.";
  };

  getBannerImage = () => {
    return null;
  };

  saveEmail = () => {
    const { email } = this.props.data;

    const id = email.id,
      titleColor = email.titleColor,
      titleSize = email.titleSize,
      titleFont = email.titleFont,
      bodyColor = email.bodyColor,
      bodySize = email.bodySize,
      bodyFont = email.bodyFont,
      logo = email.logo ? email.logo : null,
      backgroundColor = email.backgroundColor,
      footerBackgroundColor = email.footerBackgroundColor,
      buttonColor = email.buttonColor,
      buttonTextColor = email.buttonTextColor,
      buttonSize = email.buttonSize,
      buttonFont = email.buttonFont,
      footerColor = email.footerColor,
      footerSize = email.footerSize,
      footerFont = email.footerFont,
      title = email.title,
      body = this.props.updatedBody ? this.props.updatedBody : email.body,
      footer = this.props.updatedFooterText
        ? this.props.updatedFooterText
        : email.footer,
      buttonText = email.buttonText,
      signature = this.props.emailText ? this.props.emailText : email.signature,
      bannerImage = email.bannerImage ? email.bannerImage : null;

    this.props
      .customizeEmails({
        variables: {
          id,
          titleColor,
          titleSize,
          titleFont,
          bodyColor,
          bodySize,
          bodyFont,
          logo,
          backgroundColor,
          footerBackgroundColor,
          buttonColor,
          buttonTextColor,
          buttonSize,
          buttonFont,
          footerColor,
          footerSize,
          footerFont,
          title,
          body,
          footer,
          buttonText,
          bannerImage,
          signature,
        },
      })
      .then(() => {
        this.props.data.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { email, emailType, legalsiteSettings } = this.props.data;
    const { updatedBody, updatedFooterText, emailText } = this.props;

    return email ? (
      <div className="email-breach w-100">
        <h5 className="email-breach__title">{this.getTitle()}</h5>
        <p className="email-breach__text">{this.getText()}</p>

        <Step2Email
          bgColor={email.bgColor}
          email={email}
          avatar={null}
          emailTitle={
            emailType === "company"
              ? "breach"
              : emailType === "companies"
              ? "breach2"
              : "breach3"
          }
          files={null}
          getBannerImage={this.getBannerImage()}
          updatedBody={updatedBody}
          updatedFooterText={updatedFooterText}
          emailText={emailText}
          index={1}
          updateBody={this.props.updateBody}
          updateEmailText={this.props.updateEmailText}
          updateFooterText={this.props.updateFooterText}
          isForInbox={true}
          logo={
            legalsiteSettings && legalsiteSettings.companyLogo
              ? legalsiteSettings.companyLogo
              : null
          }
        />
      </div>
    ) : null;
  }
}

export default compose(
  graphql(EMAILS_QUERY, {
    options: () => ({
      variables: {
        emailType: "DATA_BREACH_USERS",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(CUSTOMIZE_EMAILS, {
    name: "customizeEmails",
  })
)(EmailBreach);
