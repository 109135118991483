import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input, Form, Button, FormGroup, FormFeedback } from "reactstrap";
import ReactFilestack from "filestack-react";
import { graphql } from "@apollo/client/react/hoc";
import MdClose from "react-icons/lib/md/close";
import Image from "../../images/Users/Illustration.svg";
import { CREATE_DATA_ENTITY } from "../../graphql/createDataEntity";

class ManualData extends Component {
  static propTypes = {
    showManualData: PropTypes.func.isRequired,
    showNotify: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool,
    notify: PropTypes.func,
    createDataEntity: PropTypes.func,
  };

  state = {
    isDataController: true,
    hasDPA: true,
    name: "",
    email: "",
    phone: "",
    company: "",
    formErrors: {},
    files: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const company = formData.get("company"),
      name = formData.get("name"),
      email = formData.get("email"),
      phone = formData.get("phone");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createDataEntity({
        variables: {
          name,
          email,
          phoneNumber: phone,
          company,
          dpa:
            this.state.files && this.state.files.length
              ? this.state.files[0].url
              : "",
          isDataController: this.state.isDataController,
        },
      })
      .then(() => {
        if (this.props.isEmpty) {
          this.props.refetchData();
        } else {
          this.props.refetchData();
          this.props.showNotify();
          this.props.showManualData();
        }
      })
      .catch((error) => {
        console.log(error);
        this.props.notify("User was not created");
      });
  };

  onFiles(res) {
    this.setState({ files: res.filesUploaded });
  }

  render() {
    const { files } = this.state;

    return (
      <div className="users">
        <div className="container import__manual-container">
          <h2 className="import__manual-title">Add user manually</h2>
          <p className="import__manual-text">
            This new record will be added to your index.
          </p>

          <div className="import__manual-img">
            <img src={Image} alt="add" />
          </div>

          <Form onSubmit={this.handleSubmit} className="import__manual-form">
            <FormGroup className="mb-4">
              <Input
                type="text"
                name="name"
                className="mb-0"
                placeholder="Full name"
                defaultValue={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                invalid={this.state.formErrors.name}
              />
              <FormFeedback>Please add user's name</FormFeedback>
            </FormGroup>

            <FormGroup className="mb-4">
              <Input
                type="email"
                name="email"
                className="mb-0"
                placeholder="E-mail"
                defaultValue={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                invalid={this.state.formErrors.email}
              />
              <FormFeedback>Please add user's email</FormFeedback>
            </FormGroup>

            <FormGroup className="mb-4">
              <Input
                type="tel"
                name="phone"
                className="mb-0"
                placeholder="Phone number"
                defaultValue={this.state.phone}
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
            </FormGroup>

            <FormGroup className="mb-4">
              <Input
                type="text"
                className="mb-0"
                name="company"
                placeholder="Company (optional)"
                defaultValue={this.state.company}
                onChange={(e) => this.setState({ company: e.target.value })}
              />
            </FormGroup>

            <p className="import__manual-text mb-3 text-center mt-5">
              Is this user a Data Controller? A Data Controller is a company
              that you process data for.
            </p>

            <div className="d-flex flex-wrap mt-4 justify-content-center">
              <div className="d-flex align-items-center import__manual-keep ml-0">
                <span
                  className={`${
                    this.state.isDataController ? "-is-active" : ""
                  } import__manual-check mr-2`}
                  onClick={() => this.setState({ isDataController: true })}
                />
                Yes
              </div>

              <div className="d-flex align-items-center import__manual-keep">
                <span
                  className={`${
                    !this.state.isDataController ? "-is-active" : ""
                  } import__manual-check mr-2`}
                  onClick={() => this.setState({ isDataController: false })}
                />
                No
              </div>
            </div>

            <p className="import__manual-text mb-3 text-center mt-5">
              Upload a signed Data Processing Agreement with this user
              (optional):
            </p>

            {!files.length ? (
              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: (
                    <Button className="btn--secondary mt-2 mb-5 d-block mx-auto">
                      Upload DPA
                    </Button>
                  ),
                  customClass: "show-upload",
                }}
                onSuccess={(res) => this.onFiles(res)}
                actionOptions={{
                  fromSources: [
                    "local_file_system",
                    "googledrive",
                    "dropbox",
                    "onedrive",
                  ],
                  accept: [
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  ],
                  maxFiles: 1,
                }}
              />
            ) : (
              <div className="d-flex flex-wrap justify-content-center">
                {files && files.length ? (
                  <div className="inbox__internal-file mb-4">
                    <div className="inbox__internal-file-type">DOC</div>
                    <p className="inbox__internal-file-name">{files[0].key}</p>
                    <MdClose
                      onClick={() => this.setState({ files: [] })}
                      className="inbox__internal-file-remove"
                      size={20}
                      color={"#969696"}
                    />
                  </div>
                ) : null}
              </div>
            )}

            <div className="d-flex justify-content-center mt-5">
              <Button
                className="btn--secondary import__manual-btn mr-4"
                onClick={this.props.showManualData}
              >
                Cancel
              </Button>
              <Button className="btn--primary import__manual-btn" type="submit">
                Add data
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default graphql(CREATE_DATA_ENTITY, {
  name: "createDataEntity",
})(ManualData);
