import PropTypes from "prop-types";
import React from "react";

const LegalIcon = props => (
    <svg
        height={18}
        viewBox='0 0 20 18'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Side-navigation-bar" transform="translate(-29.000000, -222.000000)" fill="#DFE4EE" fillRule="nonzero">
                <g id="desktop">
                    <g transform="translate(29.000000, 222.000000)">
                        <path d="M17,0 L3,0 C1.34314575,0 -4.4408921e-16,1.34314575 -4.4408921e-16,3 L-4.4408921e-16,11 C-4.4408921e-16,12.6568542 1.34314575,14 3,14 L9,14 L9,16 L5,16 C4.44771525,16 4,16.4477153 4,17 C4,17.5522847 4.44771525,18 5,18 L15,18 C15.5522847,18 16,17.5522847 16,17 C16,16.4477153 15.5522847,16 15,16 L11,16 L11,14 L17,14 C18.6568542,14 20,12.6568542 20,11 L20,3 C20,1.34314575 18.6568542,0 17,0 Z M18,11 C18,11.5522847 17.5522847,12 17,12 L3,12 C2.44771525,12 2,11.5522847 2,11 L2,3 C2,2.44771525 2.44771525,2 3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,11 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

LegalIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default LegalIcon;