import React, { Component } from "react";
import PropTypes from "prop-types";
import MdArrow from "react-icons/lib/ti/arrow-right";
import MdClose from "react-icons/lib/md/close";
import CopyPasteData from "./CopyPasteData";
import UploadData from "./UploadData";

export default class ImportData extends Component {
  static propTypes = {
    showImport: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool,
    getImportStatus: PropTypes.func,
  };

  state = {
    isPasteData: false,
    isUploadContainer: false,
  };

  showPasteContainer = () => {
    this.setState({
      isPasteData: !this.state.isPasteData,
    });
  };

  showUploadContainer = () => {
    this.setState({
      isUploadContainer: !this.state.isUploadContainer,
    });
  };

  render() {
    const { isPasteData, isUploadContainer } = this.state;

    return (
      <div className="users import">
        {!isPasteData && !isUploadContainer ? (
          <div className="container import__container">
            <div
              className="search__exit d-flex justify-content-end w-100"
              onClick={this.props.showImport}
            >
              Exit &nbsp;
              <MdClose size={20} />
            </div>
            <h2 className="import__title">Import user data</h2>

            <p className="import__text">
              Upload file to import user data. This new record will be added to
              your index.
            </p>

            <div className="import__type" onClick={this.showUploadContainer}>
              <div className="d-flex flex-column flex-column justify-content-center">
                <span className="import__file-type">CSV - XLS</span>
                <span className="import__file-info">
                  Import user data from a .csv, .xls, .xlsx or txt files.
                </span>
              </div>

              <MdArrow size={30} color={"#7450c8"} />
            </div>

            <div className="import__type" onClick={this.showPasteContainer}>
              <div className="d-flex flex-column flex-column justify-content-center">
                <span className="import__file-type">Copy/paste from file</span>
                <span className="import__file-info">
                  Copy user data from a .csv, .xls, .xlsx or txt files.
                </span>
              </div>

              <MdArrow size={30} color={"#7450c8"} />
            </div>
          </div>
        ) : isPasteData && !isUploadContainer ? (
          <CopyPasteData
            isEmpty={this.props.isEmpty}
            showImport={this.props.showImport}
            refetchData={this.props.refetchData}
          />
        ) : (
          <UploadData
            isEmpty={this.props.isEmpty}
            showImport={this.props.showImport}
            refetchData={this.props.refetchData}
            getImportStatus={this.props.getImportStatus}
          />
        )}
      </div>
    );
  }
}
