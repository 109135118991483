import gql from "graphql-tag";

export const CREATE_DATA_ENTITY = gql`
  mutation(
    $name: String!
    $email: String!
    $phoneNumber: String
    $avatar: String
    $company: String
    $verified: Boolean
    $clientMutationId: String
    $isDataController: Boolean
    $dpa: String
  ) {
    createDataEntity(
      input: {
        name: $name
        email: $email
        phoneNumber: $phoneNumber
        avatar: $avatar
        company: $company
        verified: $verified
        clientMutationId: $clientMutationId
        isDataController: $isDataController
        dpa: $dpa
      }
    ) {
      errors
      dataEntity {
        id
      }
    }
  }
`;
