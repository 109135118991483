import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import "./modals.scss";

export default class EditPageDetails extends Component {
  static propTypes = {
    isEditPageDetailsVisible: PropTypes.bool.isRequired,
    showEditPageDetails: PropTypes.func.isRequired,
    pageDescription: PropTypes.string,
    pageName: PropTypes.string,
    isAdminNewPage: PropTypes.bool,
    isAdmin: PropTypes.bool,
    submitPageTemplateDetails: PropTypes.func,
    submitPageDetails: PropTypes.func,
    pageTemplate: PropTypes.object,
    detailsAlert: PropTypes.array,
    savePageDetails: PropTypes.func,
    pageId: PropTypes.string,
  };

  state = {
    pageDescription: null,
    pageName: null,
    formErrors: {},
    isEnterprise: null,
    isProfessional: null,
    isBusiness: null,
    isGrowth: null,
    isFree: null,
  };

  getFormValues = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  resetValues = () => {
    this.setState({
      pageDescription: this.props.pageDescription,
      pageName: this.props.pageName,
    });
  };

  submitPageDetails = (e) => {
    e.preventDefault();
    const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    const formData = new FormData(e.target);
    const title = formData.get("title"),
      description = formData.get("description");

    let hasFormErrors = false;
    let formErrors = {};

    const formatPageName = format.test(title);

    if (this.props.pageName.trim() !== "Terms & Conditions") {
      if (!title) {
        hasFormErrors = true;
        formErrors.title = true;
      }

      if (formatPageName) {
        hasFormErrors = true;
        formErrors.format = true;
      }
    }

    if (!description) {
      hasFormErrors = true;
      formErrors.description = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.isAdmin) {
      this.props.submitPageTemplateDetails(
        title,
        description,
        this.props.pageId
      );
    } else {
      this.props.submitPageDetails(title, description, this.props.pageId);
    }

    this.props.showEditPageDetails();
  };

  render() {
    const { isAdminNewPage, isAdmin, detailsAlert } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isEditPageDetailsVisible}
          toggle={this.props.showEditPageDetails}
          className="edit-page-details"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showEditPageDetails}
            />
            <p className="modal-assign__title mb-3">Edit page details</p>
            <p className="modal-assign__text mb-0 mt-0">
              {isAdminNewPage || isAdmin
                ? "Edit your page’s name, description and choose to make them visible or not for users."
                : "This page name and description will be show on your LegalSite page."}
            </p>

            {detailsAlert && detailsAlert.length > 0 ? (
              <span className="text-center">
                {detailsAlert.length} new page details changes
              </span>
            ) : null}
            <Form
              onSubmit={this.submitPageDetails}
              className="edit-page-details__form"
            >
              <FormGroup className="position-relative">
                <Label className="add-page__label">Page name</Label>
                <Input
                  className="edit-page-details__input"
                  type="text"
                  name="title"
                  invalid={
                    this.props.pageName.trim() === "Terms & Conditions"
                      ? null
                      : this.state.formErrors.title ||
                        this.state.formErrors.format
                  }
                  value={
                    this.state.pageName !== null
                      ? this.state.pageName
                      : this.props.pageName
                  }
                  readOnly={
                    this.props.pageName.trim() === "Terms & Conditions" ||
                    this.props.pageName.trim() === "Privacy policy" ||
                    this.props.pageName.trim() === "Privacy Policy" ||
                    this.props.pageName.trim() ===
                      "Data Processing Agreement" ||
                    this.props.pageName.trim() === "Cookies Policy"
                      ? true
                      : false
                  }
                  placeholder="Write page name"
                  onChange={(e) =>
                    this.getFormValues("pageName", e.target.value)
                  }
                />
                <FormFeedback>
                  {this.state.formErrors.title
                    ? "Please provide a title"
                    : "Please don't use special characters"}
                </FormFeedback>
              </FormGroup>

              <FormGroup>
                <Label className="add-page__label">Add small description</Label>
                <Input
                  className="edit-page-details__input"
                  type="text"
                  name="description"
                  invalid={this.state.formErrors.description}
                  value={
                    this.state.pageDescription !== null
                      ? this.state.pageDescription
                      : this.props.pageDescription
                  }
                  onChange={(e) =>
                    this.getFormValues("pageDescription", e.target.value)
                  }
                  placeholder="Write page's description"
                />
                <FormFeedback>Please provide a description</FormFeedback>
              </FormGroup>

              {detailsAlert && detailsAlert.length > 0 ? (
                <div className="edit-page-details__btns">
                  <Button
                    className="btn--secondary"
                    onClick={() => {
                      this.resetValues();
                      this.props.savePageDetails(
                        detailsAlert[0].id,
                        detailsAlert[0].pageTemplate.id,
                        false
                      );
                      this.props.showEditPageDetails();
                    }}
                  >
                    Discard
                  </Button>
                  <Button
                    onClick={() => {
                      this.resetValues();
                      this.props.savePageDetails(
                        detailsAlert[0].id,
                        detailsAlert[0].pageTemplate.id,
                        true
                      );
                      this.props.showEditPageDetails();
                    }}
                    className="btn--primary"
                  >
                    Approve
                  </Button>
                </div>
              ) : (
                <div className="edit-page-details__btns">
                  <Button
                    className="btn--secondary"
                    onClick={() => {
                      this.resetValues();
                      this.props.showEditPageDetails();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="btn--primary">
                    Save and close
                  </Button>
                </div>
              )}
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
