import gql from "graphql-tag";

export const COMPLIANCE_DATA_PROCESSING_QUERY = gql`
  {
    currentDataProcessors(category: ALL) {
      id
      companyName
      email
      dpaStatus
      dataProcessor {
        id
        companyName
        email
        logo
        description
        profile {
          id
          logo
          smallDescription
          companyName
        }
      }
    }
  }
`;
