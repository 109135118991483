import React, { Component } from "react";
import PropTypes from "prop-types";
import GreenCheck from "../../components/Icons/GreenCheck";
import download from "../../images/Inbox/download.svg";
import Request from "../../images/Inbox/Request.svg";
import trash from "../../images/Inbox/trash.svg";
import YellowUser from "../../components/Icons/YellowUser";
import Moment from "react-moment";

class InboxAction extends Component {
  static propTypes = {
    status: PropTypes.string,
    category: PropTypes.string,
    code: PropTypes.string,
    receivedDate: PropTypes.string,
    dataSubject: PropTypes.object,
    id: PropTypes.string,
    removeInteraction: PropTypes.func,
    generateReport: PropTypes.func,
    getRequest: PropTypes.func,
    item: PropTypes.object,
  };

  state = {};

  render() {
    const { item } = this.props;

    return (
      <div className="inbox__action d-flex justify-content-between align-items-center">
        <div className="inbox__action-divider" />

        <div
          className={`${
            item.requestNumber ? "-has-cursor" : ""
          } d-flex align-items-center`}
          onClick={() =>
            item.requestNumber ? this.props.getRequest(item.requestNumber) : {}
          }
        >
          {item.activity === "Data Subject created" ||
          item.activity === "Manual request created" ? (
            <YellowUser className="inbox__action-icon" />
          ) : item.activity === "Request Action Needed" ? (
            <img src={Request} alt="request" />
          ) : (
            <GreenCheck />
          )}

          <div>
            <p className="inbox__action-label mb-0">{item.activity}</p>

            {item && item.requestNumber ? (
              <div className="inbox__action-status mt-0 mb-0">
                <span className="mr-5">Request #{item.requestNumber}</span>
              </div>
            ) : null}
          </div>
        </div>

        <div>
          <div className="d-flex justify-content-end">
            {item && item.request ? (
              <img
                className="inbox__data-user-action mr-2"
                src={download}
                alt="download"
                onClick={() =>
                  this.props.generateReport(item.requestId, item.activity)
                }
              />
            ) : null}
            <img
              className="inbox__data-user-action"
              src={trash}
              alt="trash"
              onClick={() => this.props.removeInteraction(item.id)}
            />
          </div>

          <p className="inbox__action-date">
            <Moment format="DD/MM/YYYY">
              {item && item.createdAt ? item.createdAt : null}
            </Moment>
          </p>
        </div>
      </div>
    );
  }
}

export default InboxAction;
