import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import GreenCheck from "../Icons/GreenCheck";
import Customize from "../../images/Upgrade/Group 4.svg";
import Image from "../../images/Upgrade/Image.svg";
import Image2 from "../../images/Upgrade/Image.png";
import Icon from "../../images/Upgrade/Icon bubble.svg";
import Cookie from "../../images/Upgrade/Cookie popup.svg";
import Image5 from "../../images/Upgrade/Group 5.svg";
import Image6 from "../../images/Upgrade/Image6.svg";
import Image7 from "../../images/Upgrade/Solved.svg";
import Image8 from "../../images/Upgrade/Pages.svg";

export default class Sources extends Component {
  static propTypes = {
    showUpgrade: PropTypes.func.isRequired,
    isUpgradeVisible: PropTypes.bool.isRequired,
    getSample: PropTypes.func.isRequired,
    isCookiesConsent: PropTypes.bool.isRequired,
    type: PropTypes.string,
    account: PropTypes.object,
  };

  state = {};

  render() {
    const { type, account } = this.props;

    return (
      <div>
        <Modal
          fade={false}
          isOpen={this.props.isUpgradeVisible}
          toggle={this.props.showUpgrade}
          className="modal-upgrade"
        >
          <ModalBody className="modal-upgrade__container">
            <div className="modal-upgrade__content">
              <h3 className="modal-upgrade__content-title">
                It’s time for your upgrade!
              </h3>
              <p className="modal-upgrade__content-subtitle">
                {type === "add pages"
                  ? "Upgrade your plan to add another custom page and to have access to many other features:"
                  : type === "add users"
                  ? "Upgrade your plan to add users to your team and have access to many other features:"
                  : type === "cookie"
                  ? "Upgrade your plan to customize your Cookies consent pop-up and have access to many other features:"
                  : type === "email"
                  ? "Upgrade your plan to customize your e-mail templates and have access to many other features:"
                  : type === "legalsite"
                  ? "Upgrade your plan to remove LegalSite branding and have access to many other features:"
                  : "Upgrade your plan to customize your LegalSite and have access to many other features:"}
              </p>

              {type === "add pages" || type === "add users" ? (
                <ul>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Complete inbox with user data requests.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" /> Data
                    Processing Agreements.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Custom additional legal pages.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" /> GDPR
                    requests API.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" /> Make
                    your Privacy Impact Assesment.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" /> Many
                    other great features.
                  </li>
                </ul>
              ) : type === "legalsite" ? (
                <ul>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>Legal Pages</strong>
                      <br />
                      Your LegalSite with all legal pages and pop-ups.
                    </span>
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>Privacy Compliance</strong>
                      <br />
                      All compliance features such as DPA, PIA and Breach
                      Incidents.
                    </span>
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>Privacy Operations</strong>
                      <br />
                      All privacy operations features to handle user requests
                      with legal inbox.
                    </span>
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>1.000 Data Subjects</strong> included
                    </span>
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>Customization</strong> of LegalSite, Pop-ups and
                      E-mails
                    </span>
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    <span>
                      <strong>VIP Support</strong> jump to the front of the
                      line.
                    </span>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Complete inbox with user questions, complaints and data
                    requests.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Become compliant.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Customize your legal pages.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Cookies consent pop-up.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" />{" "}
                    Manage your users index.
                  </li>
                  <li>
                    <GreenCheck className="modal-upgrade__content-icon" /> Many
                    other great features.
                  </li>
                </ul>
              )}

              {type !== "legalsite" ? (
                <div className="d-flex">
                  <Button
                    className="btn--primary modal-upgrade__content-upgrade"
                    onClick={() =>
                      window.open(
                        "http://legalsite.co/select-plan.html",
                        "_blank"
                      )
                    }
                  >
                    Upgrade
                  </Button>

                  {!this.props.isCookiesConsent &&
                  (type === "legalsite" ||
                    type === "cookie" ||
                    type === "email") ? (
                    <Button
                      className="btn--outline inbox-free__content-data"
                      onClick={() => this.props.getSample(type)}
                    >
                      SEE SAMPLE DATA
                    </Button>
                  ) : null}
                </div>
              ) : (
                <a
                  href={
                    account &&
                    account.subscription &&
                    account.subscription.upgradeToBusinessUrl
                      ? account.subscription.upgradeToBusinessUrl
                      : `https://legalsite.chargebee.com/hosted_pages/plans/business-edition?customer[email]=${
                          account ? account.email : null
                        }`
                  }
                >
                  <Button className="btn--primary">
                    Add billing information
                  </Button>
                </a>
              )}
            </div>

            <div className="modal-upgrade__banner">
              <MdClose
                onClick={this.props.showUpgrade}
                className="modal-upgrade__close"
                color={"#fff"}
                size={25}
              />

              {type === "legalsite" ? (
                <Fragment>
                  <img
                    className="modal-upgrade__banner-img1"
                    src={Customize}
                    alt="upgrade"
                  />

                  <div className="position-relative">
                    <img
                      className="modal-upgrade__banner-img2"
                      src={Image}
                      alt="upgrade"
                    />
                    <img
                      className="modal-upgrade__banner-check"
                      src={Icon}
                      alt="upgrade"
                    />
                  </div>
                </Fragment>
              ) : type === "cookie" ? (
                <Fragment>
                  <img
                    className="modal-upgrade__banner-img3"
                    src={Image2}
                    alt="upgrade"
                  />
                  <img
                    className="modal-upgrade__banner-img4"
                    src={Cookie}
                    alt="upgrade"
                  />
                </Fragment>
              ) : type === "add users" ? (
                <Fragment>
                  <img
                    className="modal-upgrade__banner-img6"
                    src={Image6}
                    alt="upgrade"
                  />
                  <img
                    className="modal-upgrade__banner-img7"
                    src={Image7}
                    alt="upgrade"
                  />
                </Fragment>
              ) : type === "add pages" ? (
                <img
                  className="modal-upgrade__banner-img5"
                  src={Image8}
                  alt="upgrade"
                />
              ) : (
                <Fragment>
                  <img
                    className="modal-upgrade__banner-img5"
                    src={Image5}
                    alt="upgrade"
                  />
                </Fragment>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
