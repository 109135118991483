import PropTypes from "prop-types";
import React from "react";

const Browser = (props) => (
  <svg
    height={20}
    viewBox="0 0 16 16"
    width={24}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-8---Settings"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="2.7--color-picker"
        transform="translate(-1174.000000, -270.000000)"
        fill="#333"
        fillRule="nonzero"
      >
        <g id="Side-bar-right" transform="translate(1130.000000, 97.000000)">
          <g id="Group-2" transform="translate(22.000000, 168.000000)">
            <g id="Group-5" transform="translate(22.000000, 0.000000)">
              <g id="browser-(1)" transform="translate(0.000000, 5.000000)">
                <path
                  d="M13.3333333,0 L2.66666667,0 C1.19390733,0 0,1.19390733 0,2.66666667 L0,13.3333333 C0,14.8060927 1.19390733,16 2.66666667,16 L13.3333333,16 C14.8060927,16 16,14.8060927 16,13.3333333 L16,2.66666667 C16,1.19390733 14.8060927,0 13.3333333,0 Z M8,2.66666667 C8.49091978,2.66666667 8.88888889,3.06463578 8.88888889,3.55555556 C8.88888889,4.04647533 8.49091978,4.44444444 8,4.44444444 C7.50908022,4.44444444 7.11111111,4.04647533 7.11111111,3.55555556 C7.11111111,3.06463578 7.50908022,2.66666667 8,2.66666667 Z M4.44444444,2.66666667 C4.93536422,2.66666667 5.33333333,3.06463578 5.33333333,3.55555556 C5.33333333,4.04647533 4.93536422,4.44444444 4.44444444,4.44444444 C3.95352467,4.44444444 3.55555556,4.04647533 3.55555556,3.55555556 C3.55555556,3.06463578 3.95352467,2.66666667 4.44444444,2.66666667 Z M14.2222222,13.3333333 C14.2222222,13.8242531 13.8242531,14.2222222 13.3333333,14.2222222 L2.66666667,14.2222222 C2.17574689,14.2222222 1.77777778,13.8242531 1.77777778,13.3333333 L1.77777778,7.11111111 L14.2222222,7.11111111 L14.2222222,13.3333333 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Browser.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Browser;
