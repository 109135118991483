import React from "react";
import DashboardEmptyBig from "../../images/Dashboard/dashboard-empty-1.png";
import DashboardEmptySmall from "../../images/Dashboard/dashboard-empty-2.svg";
import PropTypes from "prop-types";
import Check from "../../images/Dashboard/check.svg";
import Cloud from "../../images/Dashboard/cloud.svg";
import { Button } from "reactstrap";
import LearnIcon from "../Icons/LearnIcon";
import ContactIcon from "../Icons/ContactIcon";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import InfoIcon from "../Icons/InfoIcon";

function EmptyDashboard(props) {
  const { isAdmin, closeStep, showNextStep, step, name } = props;

  return (
    <div className={`${isAdmin ? "h-auto" : ""} info-empty container info`}>
      <div className="info-empty__container">
        <h3 className="info-empty__header">Hello {name}!</h3>
        <div className="info-empty__images">
          <img
            className="info-empty__image1"
            src={DashboardEmptyBig}
            alt="Welcome"
          />
          <img
            className="info-empty__image2"
            src={DashboardEmptySmall}
            alt="Welcome"
          />
          <img className="info-empty__image3" src={Check} alt="Check" />
          <img className="info-empty__image4" src={Cloud} alt="Cloud" />
        </div>
        <p className="info-empty__text">You don't have any data yet</p>
        <p className="info-empty__secondary-text">
          {isAdmin ? (
            "When you have clients, user, lawyers and requests their data will appear here."
          ) : (
            <span>
              Go through the Getting Started checklist and get your LegalSite
              published in minutes.
            </span>
          )}
        </p>

        <Button
          className="d-flex justify-content-center mx-0 btn--primary mb-3 align-items-center"
          onClick={props.openMenu}
        >
          Getting Started Checklist
        </Button>

        {step === "first" ? (
          <div className="info__step1">
            <div>
              <span className="info__step1-close" onClick={closeStep}>
                <MdClose size={20} color={"#fff"} />
              </span>
              <h5 className="info__step1-title">This is your dashboard!</h5>
              <p className="info__step1-text">
                After the onboarding go through this checklist on the right.
                Here on the dashboard you’ll be able to have an overview of your
                company and see if there is any urgent tasks.
              </p>
            </div>

            <span
              className="info__step1-next"
              onClick={() => showNextStep("second")}
            >
              Next&nbsp;
              <MdArrow size={20} color="#fff" />
            </span>
          </div>
        ) : null}
      </div>

      {!isAdmin ? (
        <div className="info-empty__column-container">
          <div
            className="info-empty__column"
            onClick={() =>
              window.open(
                "https://legalsite.co/privacy-compliance.html",
                "_blank"
              )
            }
          >
            <LearnIcon />
            <span className="text-center info-empty__column-text">
              Learn more
            </span>
            <span className="text-center info-empty__column-text-secondary">
              Learn about the GDPR, Privacy compliance and other requirements
            </span>
          </div>
          <div
            className="info-empty__column"
            onClick={() =>
              window.open("https://legalsite.co/faq.html", "_blank")
            }
          >
            <InfoIcon />
            <span className="text-center info-empty__column-text">FAQ</span>
            <span className="text-center info-empty__column-text-secondary">
              See the most frequently asked questions.
            </span>
          </div>
          <div
            className="info-empty__column"
            onClick={() =>
              window.open("http://legalsite.co/contact.html", "_blank")
            }
          >
            <ContactIcon />
            <span className="text-center info-empty__column-text">
              Contact us
            </span>
            <span className="text-center info-empty__column-text-secondary">
              Send us your questions and we'll be in touch soon.
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default EmptyDashboard;
EmptyDashboard.propTypes = {
  name: PropTypes.string.isRequired,
  step: PropTypes.string,
  closeStep: PropTypes.func,
  showNextStep: PropTypes.func,
  isAdmin: PropTypes.bool,
  openMenu: PropTypes.func,
};
