import React, { Component, Fragment } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { EDIT_LEGALSITE_INFORMATION } from "../../graphql/editLegalInformation";
import {
  Form,
  FormGroup,
  Input,
  Button,
  Alert,
  FormFeedback,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import SyncLoader from "react-spinners/SyncLoader";
import countries from "../../config/countries";
import law from "../../images/Settings/law.svg";
import PropTypes from "prop-types";

class LegalInformation extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    editLegalsiteInformation: PropTypes.func.isRequired,
    notify: PropTypes.func.isRequired,
  };

  state = {
    countries: [],
    country: "",
    formErrors: {},
    isDisplay: false,
    isInitialShow: true,
  };

  componentDidMount() {
    this.showData();
  }

  showData = async () => {
    try {
      this.setState({
        countries: countries,
      });
    } catch (err) {
      console.log(err);
    }
  };

  selectCountry = (e) => {
    this.setState({
      country: e.target.value,
    });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const country = formData.get("country"),
      companyName = formData.get("companyName"),
      companyAddress = formData.get("companyAddress"),
      city = formData.get("city"),
      zip = formData.get("zip"),
      companyWebsite = formData.get("companyWebsite"),
      registrationNo = formData.get("registrationNo"),
      representativeFullName = formData.get("representativeFullName"),
      representativeEmail = formData.get("representativeEmail"),
      companyPhone = formData.get("companyPhone"),
      taxID = formData.get("taxID");

    let hasFormErrors = false;
    let formErrors = {};

    if (!country) {
      hasFormErrors = true;
      formErrors.country = true;
    }

    if (!companyName) {
      hasFormErrors = true;
      formErrors.companyName = true;
    }

    if (!companyAddress) {
      hasFormErrors = true;
      formErrors.companyAddress = true;
    }

    if (!city) {
      hasFormErrors = true;
      formErrors.city = true;
    }

    if (!zip) {
      hasFormErrors = true;
      formErrors.zip = true;
    }

    if (!companyWebsite) {
      hasFormErrors = true;
      formErrors.companyWebsite = true;
    }

    if (!representativeFullName) {
      hasFormErrors = true;
      formErrors.representativeFullName = true;
    }

    if (!representativeEmail) {
      hasFormErrors = true;
      formErrors.representativeEmail = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editLegalsiteInformation({
        variables: {
          companyName: companyName,
          companyAddress: companyAddress,
          companyCity: city,
          country: country,
          companyZipCode: zip,
          companyWebsite: companyWebsite,
          phoneNumber: companyPhone,
          vat: taxID,
          fullName: representativeFullName,
          companyRegistration: registrationNo,
          email: representativeEmail,
          showLegalInformation: this.state.isInitialShow
            ? this.props.account.showLegalInformation
            : this.state.isDisplay,
        },
      })
      .then(() => {
        this.props.notify("Legal Information was updated!");
      })
      .catch((error) => {
        console.log(error.graphQLErrors);

        this.setState({
          hasError: error.graphQLErrors[0].message,
        });
        this.props.notify("Legal Information was not updated!");
      });
  };

  render() {
    const { sectionVisible, showSection, account, loading, error } = this.props;
    const { country, countries, isDisplay, isInitialShow } = this.state;
    const isActive = sectionVisible === "legal-info" ? true : false;

    if (isActive) {
      if (loading)
        return (
          <SyncLoader
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              top: 30px;
              position: relative;
            `}
            sizeUnit={"px"}
            size={10}
            color={"#7450c8"}
            loading={true}
          />
        );

      if (error)
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
    }

    return (
      <div
        className={`${isActive ? "-is-active" : ""} settings__account`}
        // eslint-disable-next-line react/no-string-refs
        ref="legal-info"
        onClick={
          !isActive
            ? () => {
                showSection("legal-info");
                this.scrollToSection("legal-info");
              }
            : () => showSection("legal-info")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <img src={law} alt="legalsite" />
          &nbsp; Legal Information
        </h2>
        <p className="settings__text">
          Fill the legal information about your company. This information will
          be used in the legal pages published on your LegalSite.
        </p>

        {isActive ? (
          <Fragment>
            <Form className="settings__legal-form" onSubmit={this.handleSubmit}>
              <FormGroup className="mb-4">
                <Input
                  placeholder="Company name"
                  name="companyName"
                  className="mb-0"
                  invalid={this.state.formErrors.companyName}
                  defaultValue={
                    account && account.companyName ? account.companyName : ""
                  }
                />
                <FormFeedback>Please provide the company name</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Company address"
                  className="mb-0"
                  name="companyAddress"
                  invalid={this.state.formErrors.companyAddress}
                  defaultValue={
                    account && account.companyAddress
                      ? account.companyAddress
                      : ""
                  }
                />
                <FormFeedback>Please provide the company address</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="City"
                  className="mb-0"
                  name="city"
                  invalid={this.state.formErrors.city}
                  defaultValue={
                    account && account.companyCity ? account.companyCity : ""
                  }
                />
                <FormFeedback>Please provide the city</FormFeedback>
              </FormGroup>

              <div className="d-flex settings__legal-form-group justify-content-between">
                <FormGroup className="position-relative mb-4 mr-2">
                  <Input
                    type="select"
                    name="country"
                    className="mb-0"
                    onChange={this.selectCountry}
                    invalid={this.state.formErrors.country}
                    value={
                      country
                        ? country
                        : account && account.country
                        ? account.country
                        : "Country"
                    }
                  >
                    <option value="Country" disabled>
                      {account && account.country ? account.country : "Country"}
                    </option>
                    {countries.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </Input>
                  <FormFeedback>Please provide your country</FormFeedback>
                  <FaCaret className="position-absolute settings__input-icon" />
                </FormGroup>

                <FormGroup className="d-flex justify-content-end mb-4">
                  <Input
                    placeholder="Zip code"
                    className="mb-0"
                    name="zip"
                    invalid={this.state.formErrors.zip}
                    defaultValue={
                      account && account.companyZipCode
                        ? account.companyZipCode
                        : ""
                    }
                  />
                  <FormFeedback>Please provide the zip code</FormFeedback>
                </FormGroup>
              </div>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Company website"
                  className="mb-0"
                  name="companyWebsite"
                  invalid={this.state.formErrors.companyWebsite}
                  defaultValue={
                    account && account.companyWebsite
                      ? account.companyWebsite
                      : ""
                  }
                />
                <FormFeedback>Please provide the company website</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Company phone number (optional)"
                  name="companyPhone"
                  className="mb-0"
                  defaultValue={
                    account && account.phoneNumber ? account.phoneNumber : ""
                  }
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Tax ID"
                  name="taxID"
                  className="mb-0"
                  defaultValue={account && account.vat ? account.vat : ""}
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Company Registration Number"
                  name="registrationNo"
                  className="mb-0"
                  defaultValue={
                    account && account.companyRegistration
                      ? account.companyRegistration
                      : ""
                  }
                />
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Legal representative full name"
                  name="representativeFullName"
                  className="mb-0"
                  invalid={this.state.formErrors.representativeFullName}
                  defaultValue={
                    account && account.fullName ? account.fullName : ""
                  }
                />
                <FormFeedback>
                  Please provide the representative's full name
                </FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Input
                  placeholder="Legal representative e-mail"
                  name="representativeEmail"
                  type="email"
                  className="mb-0"
                  invalid={this.state.formErrors.representativeEmail}
                  defaultValue={account && account.email ? account.email : ""}
                />
                <FormFeedback>
                  Please provide the representative's email
                </FormFeedback>
              </FormGroup>

              <p className="settings__text">
                Do you want to display your Legal Information on your LegalSite?
              </p>

              <div className="d-flex flex-wrap mt-3 mb-5">
                <div className="d-flex align-items-center settings__user-keep ml-0">
                  <span
                    className={`${
                      (account &&
                        account.showLegalInformation === true &&
                        isInitialShow) ||
                      (!isInitialShow && isDisplay) ||
                      (account && !account.showLegalInformation && isDisplay)
                        ? "-is-active"
                        : ""
                    } settings__user-check mr-2`}
                    onClick={() => {
                      this.setState({ isDisplay: true, isInitialShow: false });
                    }}
                  />
                  Yes
                </div>

                <div className="d-flex align-items-center settings__user-keep">
                  <span
                    className={`${
                      (account &&
                        account.showLegalInformation === false &&
                        isInitialShow) ||
                      (!isInitialShow && !isDisplay) ||
                      (account && !account.showLegalInformation && !isDisplay)
                        ? "-is-active"
                        : ""
                    } settings__user-check mr-2`}
                    onClick={() => {
                      this.setState({ isDisplay: false, isInitialShow: false });
                    }}
                  />
                  No
                </div>
              </div>

              <Button className="btn--primary settings__legal-form-save">
                Save changes
              </Button>
            </Form>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

export default graphql(EDIT_LEGALSITE_INFORMATION, {
  name: "editLegalsiteInformation",
})(LegalInformation);
