import gql from "graphql-tag";

export const SCHEDULE_IMPORT = gql`
  mutation($clientMutationId: String, $fileName: String!) {
    scheduleImport(input: { clientMutationId: $clientMutationId, fileName: $fileName }) {
      errors
      import {
        id
        status
      }
    }
  }
`;
