import React, { Component } from "react";
import { Link } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { graphql } from "@apollo/client/react/hoc";
import { LEGALSITE_SETTINGS_QUERY } from "../../graphql/legalSiteSettings";
import { Alert } from "reactstrap";
import Found from "../../images/NotFound/Group 4.svg";
import PropTypes from "prop-types";
import "./not-found.scss";

class NotFound extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {};

  render() {
    const { legalsiteSettings, loading, error } = this.props.data;

    if (loading) return <p>Loading...</p>;

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="not-found">
        <div className="legalsite__nav">
          <Link
            to="/legalSites"
            className="d-flex align-items-center text-decoration-none"
          >
            {legalsiteSettings?.companyLogo ? (
              <div className="legalsite__logo-container">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </div>
            ) : (
              <span className="legalsite__logo" />
            )}
            <span className="legalsite__brand">
              {legalsiteSettings.companyName}
            </span>
          </Link>

          <span className="legalsite__link">Get your LegalSite</span>
        </div>

        <div className="not-found__content container">
          <div className="not-found__item">
            <img src={Found} alt="not found" />
            <h3 className="not-found__item-title">Page not found</h3>
            <p className="not-found__item-text">
              The requested URL was not found on this
              <br />
              server.
            </p>
            <Link className="not-found__item-home" to="/LegalSites">
              Home page <MdArrow size={20} className="ml-2 my-0" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(LEGALSITE_SETTINGS_QUERY)(NotFound);
