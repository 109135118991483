import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, FormGroup, FormFeedback } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import { flowRight as compose } from "lodash";
import { Form, Input, Button } from "reactstrap";
import { EDIT_CONSENT } from "../../graphql/editConsent";
import { ADD_CONSENT } from "../../graphql/addConsent";
import EditPencilSquare from "../../components/Icons/EditPencilSquare";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import { graphql } from "@apollo/client/react/hoc";

import "./modals.scss";

class EditCookiesModal extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    isEditModalVisible: PropTypes.bool.isRequired,
    isOther: PropTypes.bool,
    manageable: PropTypes.bool,
    addConsent: PropTypes.func,
    id: PropTypes.string,
    editConsent: PropTypes.func,
    notify: PropTypes.func,
    refetchConsent: PropTypes.func,
    title: PropTypes.string,
    smallDescription: PropTypes.string,
    completeDescription: PropTypes.string,
    dp: PropTypes.array,
  };

  state = {
    isManageContent:
      !this.props.isOther && this.props.manageable !== null
        ? this.props.manageable
        : true,
    updatedTitle: null,
    updatedSmallDescription: null,
    updatedCompleteDescription: null,
    formErrors: {},
  };

  editConsent = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      completeDescription = formData.get("completeDescription"),
      smallDescription = formData.get("smallDescription"),
      manageableByUser = this.state.isManageContent;

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!smallDescription) {
      hasFormErrors = true;
      formErrors.smallDescription = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    if (this.props.isOther) {
      this.props
        .addConsent({
          variables: {
            manageableByUser,
            categoryName: name,
            smallDescription,
            completeDescription,
          },
        })
        .then(() => {
          this.props.refetchConsent();
          this.props.showEditModal();
          this.props.notify("Cookie Consent added!");
        })
        .catch((error) => {
          console.log(error);
          this.props.notify("Cookie Consent was not added!");
        });
    }

    if (!this.props.isOther) {
      this.props
        .editConsent({
          variables: {
            id: this.props.id,
            manageableByUser,
            categoryName: name,
            smallDescription,
            completeDescription,
            enabled: true,
          },
        })
        .then(() => {
          this.props.showEditModal();
          this.props.notify("Cookie Consent saved!");
        })
        .catch((error) => {
          console.log(error);
          this.props.notify("Cookie Consent was not saved!");
        });
    }
  };

  render() {
    const { title, smallDescription, completeDescription, dp } = this.props;
    const {
      isManageContent,
      updatedTitle,
      updatedSmallDescription,
      updatedCompleteDescription,
    } = this.state;

    const columns = [
      {
        Header: "Data Processor:",
        accessor: "",
        Cell: (props) => (
          <span className="d-flex">
            {props.value.dataProcessorLogo ? (
              <img
                className="modal-edit-cookies__dp-logo"
                src={props.value.dataProcessorLogo}
                alt="logo"
              />
            ) : (
              <span className="modal-edit-cookies__dp-placeholder" />
            )}{" "}
            {props.value.dataProcessorCompanyName}
          </span>
        ),
        width: 170,
      },
      {
        Header: "Category:",
        accessor: "consentName",
        Cell: (props) => props.value,
      },
      {
        Header: "Purpose:",
        accessor: "purpose",
        Cell: (props) => <span>{props.value}</span>,
      },
    ];

    return (
      <div>
        <Modal
          isOpen={this.props.isEditModalVisible}
          toggle={this.props.showEditModal}
          className="modals modal-edit-cookies"
        >
          <ModalBody>
            <div className="modal-edit-cookies__header">
              <MdClose
                className="modal-assign__close"
                size={25}
                onClick={this.props.showEditModal}
              />
              <p className="modal-edit-cookies__title">{title}</p>
              <p className="modal-edit-cookies__text">
                Fill the information about this category of data. This will be
                shown on your LegalSite page.
              </p>
            </div>
            <div className="modal-edit-cookies__content">
              <div className="d-flex align-items-center justify-content-center">
                <p className="mb-0 align-items-center modal-edit-cookies__consent-label">
                  Can the user manage his consent?
                </p>
                <p className="d-flex mb-0 align-items-center modal-edit-cookies__consent">
                  <span
                    onClick={() =>
                      this.setState({
                        isManageContent: true,
                      })
                    }
                    className={`${
                      isManageContent === true ? "-is-checked" : ""
                    } check`}
                  />
                  Yes
                </p>
                <p className="d-flex mb-0 align-items-center modal-edit-cookies__consent">
                  <span
                    onClick={() =>
                      this.setState({
                        isManageContent: false,
                      })
                    }
                    className={`${
                      isManageContent === false ? "-is-checked" : ""
                    } check`}
                  />
                  No
                </p>
              </div>

              <Form
                onSubmit={this.editConsent}
                className="modal-edit-cookies__form"
              >
                <FormGroup className="mb-4">
                  <Input
                    className="modal-edit-cookies__name mb-0"
                    value={
                      updatedTitle !== null ? updatedTitle : title ? title : ""
                    }
                    readOnly={title === "Essential"}
                    onChange={(e) =>
                      this.setState({ updatedTitle: e.target.value })
                    }
                    type="text"
                    name="name"
                    placeholder="Public category name"
                    invalid={this.state.formErrors.name}
                  />
                  <FormFeedback>Please provide category name</FormFeedback>
                </FormGroup>
                <FormGroup className="mb-4">
                  <Input
                    type="text"
                    className="modal-edit-cookies__small-description mb-0"
                    value={
                      updatedSmallDescription !== null
                        ? updatedSmallDescription
                        : smallDescription
                        ? smallDescription
                        : ""
                    }
                    name="smallDescription"
                    placeholder="Write a small description..."
                    invalid={this.state.formErrors.smallDescription}
                    onChange={(e) =>
                      this.setState({ updatedSmallDescription: e.target.value })
                    }
                  />
                  <FormFeedback>
                    Please provide a small description
                  </FormFeedback>
                </FormGroup>
                <Input
                  type="textarea"
                  name="completeDescription"
                  className="modal-edit-cookies__description"
                  value={
                    updatedCompleteDescription !== null
                      ? updatedCompleteDescription
                      : completeDescription
                      ? completeDescription
                      : ""
                  }
                  placeholder="Write a more complete description (optional)."
                  onChange={(e) =>
                    this.setState({
                      updatedCompleteDescription: e.target.value,
                    })
                  }
                />

                {dp && dp.length ? (
                  <div className="modal-edit-cookies__dp">
                    <p className="modal-edit-cookies__dp-title">
                      Data Processors: &nbsp;{" "}
                      <EditPencilSquare
                        color="#7450c8"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          (window.location.href = "/data-processors")
                        }
                      />
                    </p>

                    <ReactTable
                      data={dp}
                      columns={columns}
                      minRows={0}
                      showPagination={false}
                      showPageSizeOptions={false}
                      defaultPageSize={100}
                      showPageJump={false}
                      resizable={false}
                      noDataText="No Users"
                    />
                  </div>
                ) : null}

                <div className="mb-3">
                  <Button
                    className="modal-edit-cookies__cancel btn--secondary"
                    onClick={this.props.showEditModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="modal-edit-cookies__save btn--primary"
                  >
                    Save and close
                  </Button>
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default compose(
  graphql(EDIT_CONSENT, {
    name: "editConsent",
  }),
  graphql(ADD_CONSENT, {
    name: "addConsent",
  })
)(EditCookiesModal);
