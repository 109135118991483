import React, { Component } from "react";
import PropTypes from "prop-types";
import FaCaret from "react-icons/lib/fa/angle-down";
import FaCaretUp from "react-icons/lib/fa/angle-up";
import Switch from "react-switch";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import "./manage-consent.scss";

export default class ManageConsentItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    hasToggle: PropTypes.bool,
    isChecked: PropTypes.bool,
    dp: PropTypes.array,
    completeDescription: PropTypes.string,
  };

  state = {
    isMore: false,
  };

  showMore = () => {
    this.setState({
      isMore: !this.state.isMore,
    });
  };

  render() {
    const { isMore } = this.state;
    const { title, text, hasToggle, dp } = this.props;

    const columns = [
      {
        Header: "Data Processor:",
        accessor: "",
        Cell: (props) => (
          <span className="d-flex">
            {props.value.dataProcessorLogo ? (
              <img
                className="manage-consent__item-logo"
                src={props.value.dataProcessorLogo}
                alt="logo"
              />
            ) : (
              <span className="manage-consent__item-placeholder" />
            )}{" "}
            {props.value.dataProcessorCompanyName}
          </span>
        ),
        width: "200px",
      },
      {
        Header: "Category:",
        accessor: "consentName",
        Cell: (props) => props.value,
        width: "200px",
      },
      {
        Header: "Purpose:",
        accessor: "purpose",
        Cell: (props) => <span>{props.value}</span>,
        width: "200px",
      },
    ];

    return (
      <div className="d-flex manage-consent__item">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column">
            <span className="manage-consent__item-title">{title}</span>
            <span className="manage-consent__item-text">{text}</span>
          </div>
          <div>
            {!hasToggle || title === "Essential" ? (
              <span className="manage-consent__item-text">
                Necessary
                {this.props.completeDescription ? (
                  isMore ? (
                    <FaCaretUp
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  ) : (
                    <FaCaret
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  )
                ) : null}
              </span>
            ) : (
              <span className="manage-consent__item-text manage-consent__item-text d-flex align-items-center">
                <Switch
                  onColor={"#1DDCC0"}
                  offColor={"#B0BAC9"}
                  height={20}
                  width={36}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onChange={() => {}}
                  checked={this.props.isChecked}
                />
                {this.props.completeDescription || (dp && dp.length) ? (
                  isMore ? (
                    <FaCaretUp
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  ) : (
                    <FaCaret
                      className="ml-3"
                      size="20"
                      onClick={this.showMore}
                    />
                  )
                ) : null}
              </span>
            )}
          </div>
        </div>
        {isMore ? (
          <div className="manage-consent__item-more">
            <p>{this.props.completeDescription}</p>

            {dp && dp.length ? (
              <ReactTable
                data={dp}
                columns={columns}
                minRows={0}
                showPagination={false}
                showPageSizeOptions={false}
                defaultPageSize={100}
                showPageJump={false}
                resizable={false}
                noDataText="No Users"
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
