import gql from "graphql-tag";

export const CREATE_DP_CONSENT = gql`
  mutation(
    $integrationId: ID!
    $consentId: ID!
    $purpose: String!
    $clientMutationId: String
  ) {
    createDataProcessorConsent(
      input: {
        integrationId: $integrationId
        consentId: $consentId
        purpose: $purpose
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
