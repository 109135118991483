import React, { Fragment } from "react";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Check from "react-icons/lib/fa/check";
import TrashIcon from "../../components/Icons/TrashIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import FileEdit from "../../components/Icons/FileEdit";
import FaCaret from "react-icons/lib/fa/angle-down";
import SearchInput from "../../components/SearchInput/SearchInput";
import Upload from "../../components/Icons/Upload";
import PropTypes from "prop-types";

function UsersActions(props) {
  const {
    isCheckedAll,
    users,
    searchUser,
    showRequestSignature,
    checkAll,
    unselectAll,
    setFilter,
    filter,
    arr,
    download,
    deleteUser,
    isDP,
    showUpload,
    isUsers,
  } = props;

  return (
    <div className="users__actions">
      <span
        className={`check ${isCheckedAll ? "-checked" : "-unchecked"}`}
        onClick={() => (!isCheckedAll ? checkAll(users) : unselectAll())}
      >
        <Check color={"#fff"} />
      </span>
      <div
        className={`${arr && arr.length ? "" : "-is-disabled"} users__action`}
        onClick={deleteUser}
      >
        <TrashIcon className="mr-2" color={"#7450c8"} /> Delete
      </div>

      {!isDP && !isUsers ? (
        <Fragment>
          <div className="users__divider" />

          <div
            className={`${
              arr && arr.length ? "" : "-is-disabled"
            } users__action`}
            onClick={download}
          >
            <DownloadIcon className="mr-2" color={"#7450c8"} /> Download
          </div>
        </Fragment>
      ) : null}

      {isDP ? (
        <Fragment>
          <div className="users__divider mr-0" />
          <div className="users__action" onClick={showUpload}>
            <Upload color={"#7450C8"} />
            Upload DPA
          </div>
        </Fragment>
      ) : null}

      <div className="users__divider" />

      <div
        className={`${arr && arr.length ? "" : "-is-disabled"} users__action`}
        onClick={showRequestSignature}
      >
        <FileEdit color={"#7450C8"} className="mr-2" />
        {isDP ? "Request DPA" : "Request signature on DPA"}
      </div>

      <div className="users__divider" />

      {isDP ? (
        <UncontrolledDropdown>
          <DropdownToggle className="users__action -filter">
            {filter
              ? filter === "HAVE_DPA"
                ? "Have DPA"
                : filter === "NO_DPA"
                ? "No DPA"
                : "Filter"
              : "Filter"}{" "}
            <FaCaret className="ml-4" color="#333" size="20" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setFilter("HAVE_DPA")}>
              Have DPA
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("NO_DPA")}>
              No Dpa
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("ALL")}>
              No Filter
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <UncontrolledDropdown>
          <DropdownToggle className="users__action -filter">
            {filter
              ? filter === "DATA_SUBJECTS"
                ? "Data Subjects"
                : filter === "DATA_CONTROLLERS"
                ? "Data Controllers"
                : filter === "HAVE_DPA"
                ? "Have DPA"
                : filter === "NO_DPA"
                ? "No DPA"
                : filter === "LAST_ADDED"
                ? "Last Added"
                : "Filter"
              : "Filter"}{" "}
            <FaCaret className="ml-4" color="#333" size="20" />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => setFilter("DATA_SUBJECTS")}>
              Data Subjects
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("DATA_CONTROLLERS")}>
              Data Controllers
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("HAVE_DPA")}>
              Have DPA
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("NO_DPA")}>
              No Dpa
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("LAST_ADDED")}>
              Last added
            </DropdownItem>
            <DropdownItem onClick={() => setFilter("ALL")}>
              No Filter
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}

      <SearchInput
        placeholder={
          isDP
            ? "Type company name and press enter"
            : "Type user name and press enter"
        }
        search={searchUser}
      />
    </div>
  );
}

export default UsersActions;
UsersActions.propTypes = {
  isCheckedAll: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  searchUser: PropTypes.func.isRequired,
  showRequestSignature: PropTypes.func.isRequired,
  checkAll: PropTypes.func.isRequired,
  unselectAll: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  arr: PropTypes.array,
  download: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  isDP: PropTypes.bool.isRequired,
  showUpload: PropTypes.func.isRequired,
  isUsers: PropTypes.bool.isRequired,
};
