import React, { Component, Fragment } from "react";
import { Form, Input, Button, FormGroup, FormFeedback } from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import requestStatus from "../../config/requestStatus";
import DatePicker from "react-datepicker";
import Flag from "../../images/Inbox/Flag.svg";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerCustomInput from "../Compliance/DatePickerCustomInput";
import SelectUser from "../../components/Modals/SelectUser";
import MdClose from "react-icons/lib/md/close";
import ReactFilestack from "filestack-react";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { CREATE_REQUEST_DC } from "../../graphql/createRequestDataController";
import { CREATE_REQUEST_INBOX } from "../../graphql/createRequestInbox";
import { CREATE_DATA_ENTITY } from "../../graphql/createDataEntity";
import CreateRequestDC from "./CreateRequestDC";
import Moment from "react-moment";
import SelectDataController from "../../components/Modals/SelectDataController";
import PropTypes from "prop-types";

class CreateRequest extends Component {
  static propTypes = {
    closeCreate: PropTypes.func.isRequired,
    showCreatedInbox: PropTypes.func.isRequired,
    selectedRequest: PropTypes.object,
    users: PropTypes.array,
    createRequestInbox: PropTypes.func.isRequired,
    createRequestDC: PropTypes.func.isRequired,
    createDataEntity: PropTypes.func.isRequired,
  };

  state = {
    requestType: this.props.selectedRequest
      ? this.props.selectedRequest.category
      : null,
    status: null,
    startDate: new Date().setHours(12),
    selectedUsers: null,
    selectedDP: null,
    isSelected: null,
    isSelectUsersVisible: false,
    isSelectDCVisible: false,
    isAddNew: false,
    files: [],
    identityVerified: true,
    message: "",
    userName: "",
    userEmail: "",
    userPhone: "",
    dcCompany: "",
    dcName: "",
    dcEmail: "",
    dcPhone: "",
    companyName: "",
    sourceType: null,
    showNewDC: false,
    showNewDS: false,
    DCfiles: [],
    newDSId: null,
    newDCId: null,
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  onDCFiles = ({ filesUploaded }) => {
    this.setState({ DCfiles: filesUploaded });
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  showSelectUsers = () => {
    this.setState({
      isSelectUsersVisible: !this.state.isSelectUsersVisible,
    });
  };

  showSelectDC = () => {
    this.setState({
      isSelectDCVisible: !this.state.isSelectDCVisible,
    });
  };

  getSelectedUsers = (isSelected, user) => {
    this.setState({
      selectedUsers: user,
      isSelectedType: isSelected,
    });
  };

  getSelectedDC = (isSelected, dp) => {
    this.setState({
      selectedDP: dp,
      isSelectedType: isSelected,
    });
  };

  addNewUser = () => {
    this.setState({
      isAddNew: !this.state.isAddNew,
      selectedUsers: null,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    const {
      companyName,
      userName,
      userEmail,
      userPhone,
      identityVerified,
      dcCompany,
      dcName,
      dcEmail,
      dcPhone,
      showNewDC,
      showNewDS,
      requestType,
    } = this.state;

    if (
      this.state.sourceType === "DataSubject" ||
      requestType === "QUESTION" ||
      requestType === "COMPLAINT"
    ) {
      if (this.state.isAddNew) {
        this.props
          .createDataEntity({
            variables: {
              name: userName,
              email: userEmail,
              phoneNumber: userPhone,
              avatar: this.state.files.length ? this.state.files[0].url : null,
              company: companyName,
              verified: identityVerified,
            },
          })
          .then(({ data }) => {
            this.props
              .createRequestInbox({
                variables: {
                  type: this.state.requestType,
                  status: this.state.status,
                  createdAt: this.state.startDate.toString(),
                  dataSubjectId: data.createDataEntity.dataEntity.id,
                  dataSubjectVerified: this.state.identityVerified,
                  message: this.state.message,
                },
              })
              .then(() => {
                this.notify("Request created!");
                this.props.closeCreate();
                this.props.showCreatedInbox();
              })
              .catch((error) => {
                console.log(error);
                this.notify("Request was not created");
              });
          })
          .catch((error) => {
            console.log(error);
            this.notify("User was not created");
          });
      } else {
        this.props
          .createRequestInbox({
            variables: {
              type: this.state.requestType,
              status: this.state.status,
              createdAt: this.state.startDate.toString(),
              dataSubjectId: this.state.selectedUsers.id,
              dataSubjectVerified: this.state.identityVerified,
              message: this.state.message,
            },
          })
          .then(() => {
            this.notify("Request created!");
            this.props.closeCreate();
            this.props.showCreatedInbox();
          })
          .catch((error) => {
            console.log(error);
            this.notify("Request was not created");
          });
      }
    } else {
      if (showNewDC && showNewDS) {
        this.props
          .createDataEntity({
            variables: {
              isDataController: true,
              name: dcName,
              email: dcEmail,
              phoneNumber: dcPhone,
              avatar: this.state.DCfiles.length
                ? this.state.DCfiles[0].url
                : null,
              company: dcCompany,
            },
          })
          .then(({ data }) => {
            this.setState({
              newDCId: data.createDataEntity.dataEntity.id,
            });
          })
          .then(() => {
            this.props
              .createDataEntity({
                variables: {
                  isDataController: false,
                  name: userName,
                  email: userEmail,
                  phoneNumber: userPhone,
                  avatar: this.state.files.length
                    ? this.state.files[0].url
                    : null,
                  company: companyName,
                  verified: identityVerified,
                },
              })
              .then(({ data }) => {
                this.setState({
                  newDSId: data.createDataEntity.dataEntity.id,
                });
              })
              .then(() => {
                this.props
                  .createRequestDC({
                    variables: {
                      type: this.state.requestType,
                      status: this.state.status,
                      createdAt: this.state.startDate.toString(),
                      dataSubjectId: this.state.newDSId,
                      dataControllerId: this.state.newDCId,
                      dataSubjectVerified: this.state.identityVerified,
                    },
                  })
                  .then(() => {
                    this.notify("Request created!");
                    this.props.closeCreate();
                    this.props.showCreatedInbox();
                  })
                  .catch((error) => {
                    console.log(error);

                    if (
                      error &&
                      error.graphQLErrors &&
                      error.graphQLErrors.length
                    ) {
                      if (error.graphQLErrors[0].message) {
                        this.notify(error.graphQLErrors[0].message);
                      }
                    } else {
                      this.notify("Request was not created");
                    }
                  });
              })
              .catch((error) => {
                console.log(error);
                this.notify("Data Controller was not created");
                return;
              });
          })
          .catch((error) => {
            console.log(error);
            this.notify("User was not created");
            return;
          });
      }

      if (showNewDC && !showNewDS) {
        this.props
          .createDataEntity({
            variables: {
              isDataController: true,
              name: dcName,
              email: dcEmail,
              phoneNumber: dcPhone,
              avatar: this.state.DCfiles.length
                ? this.state.DCfiles[0].url
                : null,
              company: dcCompany,
            },
          })
          .then(({ data }) => {
            this.setState({
              newDCId: data.createDataEntity.dataEntity.id,
            });
          })
          .then(() => {
            this.props
              .createRequestDC({
                variables: {
                  type: this.state.requestType,
                  status: this.state.status,
                  createdAt: this.state.startDate.toString(),
                  dataSubjectId: this.state.selectedUsers.id,
                  dataControllerId: this.state.newDCId,
                  dataSubjectVerified: this.state.identityVerified,
                },
              })
              .then(() => {
                this.notify("Request created!");
                this.props.closeCreate();
                this.props.showCreatedInbox();
              })
              .catch((error) => {
                console.log(error);

                if (
                  error &&
                  error.graphQLErrors &&
                  error.graphQLErrors.length
                ) {
                  if (error.graphQLErrors[0].message) {
                    this.notify(error.graphQLErrors[0].message);
                  }
                } else {
                  this.notify("Request was not created");
                }
              });
          })
          .catch((error) => {
            console.log(error);
            this.notify("Data Controller was not created");
            return;
          });
      }

      if (!showNewDC && showNewDS) {
        this.props
          .createDataEntity({
            variables: {
              isDataController: false,
              name: userName,
              email: userEmail,
              phoneNumber: userPhone,
              avatar: this.state.files.length ? this.state.files[0].url : null,
              company: companyName,
              verified: identityVerified,
            },
          })
          .then(({ data }) => {
            this.setState({
              newDSId: data.createDataEntity.dataEntity.id,
            });
          })
          .then(() => {
            this.props
              .createRequestDC({
                variables: {
                  type: this.state.requestType,
                  status: this.state.status,
                  createdAt: this.state.startDate.toString(),
                  dataSubjectId: this.state.newDSId,
                  dataControllerId: this.state.selectedDP.id,
                  dataSubjectVerified: this.state.identityVerified,
                },
              })
              .then(() => {
                this.notify("Request created!");
                this.props.closeCreate();
                this.props.showCreatedInbox();
              })
              .catch((error) => {
                console.log(error);

                if (
                  error &&
                  error.graphQLErrors &&
                  error.graphQLErrors.length
                ) {
                  if (error.graphQLErrors[0].message) {
                    this.notify(error.graphQLErrors[0].message);
                  }
                } else {
                  this.notify("Request was not created");
                }
              });
          })
          .catch((error) => {
            console.log(error);
            this.notify("User was not created");
            return;
          });
      }

      if (!showNewDC && !showNewDS) {
        this.props
          .createRequestDC({
            variables: {
              type: this.state.requestType,
              status: this.state.status,
              createdAt: this.state.startDate.toString(),
              dataSubjectId: this.state.selectedUsers.id,
              dataControllerId: this.state.selectedDP.id,
              dataSubjectVerified: this.state.identityVerified,
            },
          })
          .then(() => {
            this.notify("Request created!");
            this.props.closeCreate();
            this.props.showCreatedInbox();
          })
          .catch((error) => {
            console.log(error);

            if (error && error.graphQLErrors && error.graphQLErrors.length) {
              if (error.graphQLErrors[0].message) {
                this.notify(error.graphQLErrors[0].message);
              }
            } else {
              this.notify("Request was not created");
            }
          });
      }
    }
  };

  notify = (text) => toast(text);

  render() {
    const {
      requestType,
      isAddNew,
      files,
      identityVerified,
      sourceType,
      selectedUsers,
      selectedDP,
      showNewDC,
      showNewDS,
    } = this.state;

    return (
      <div className="inbox__data-info -create bg-white">
        <Form onSubmit={this.submitForm}>
          <p className="inbox__create-title">Request type</p>

          <div className="d-flex align-items-center inbox__create mb-3">
            <span
              className={`${
                requestType === "DATA_ACCESS" ? "-is-active" : ""
              } inbox__create-check mr-3`}
              onClick={() => this.setState({ requestType: "DATA_ACCESS" })}
            />
            Data Access
          </div>

          <div className="d-flex align-items-center inbox__create mb-3">
            <span
              className={`${
                requestType === "DATA_ERASURE" ? "-is-active" : ""
              } inbox__create-check mr-3`}
              onClick={() => this.setState({ requestType: "DATA_ERASURE" })}
            />
            Data Erasure
          </div>

          <div className="d-flex align-items-center inbox__create mb-3">
            <span
              className={`${
                requestType === "DATA_TRANSFER" ? "-is-active" : ""
              } inbox__create-check mr-3`}
              onClick={() => this.setState({ requestType: "DATA_TRANSFER" })}
            />
            Data Transfer
          </div>

          <div className="d-flex align-items-center inbox__create mb-3">
            <span
              className={`${
                requestType === "QUESTION" ? "-is-active" : ""
              } inbox__create-check mr-3`}
              onClick={() => this.setState({ requestType: "QUESTION" })}
            />
            Question
          </div>

          <div className="d-flex align-items-center inbox__create mb-3">
            <span
              className={`${
                requestType === "COMPLAINT" ? "-is-active" : ""
              } inbox__create-check mr-3`}
              onClick={() => this.setState({ requestType: "COMPLAINT" })}
            />
            Complaint
          </div>

          <span className="divider" />

          <p className="inbox__create-title">Request info</p>

          <FormGroup className="position-relative mr-4 mb-4 inbox__select-status">
            <Input
              type="select"
              name="status"
              className="mb-0"
              onChange={(e) => this.setState({ status: e.target.value })}
              value={this.state.status ? this.state.status : "Select Status"}
            >
              <option value="Select Status" disabled>
                Select Status
              </option>
              {requestStatus.map((item, index) => (
                <option value={item.value} key={index}>
                  {item.name}
                </option>
              ))}
            </Input>
            <FormFeedback>
              Please provide a status for your request
            </FormFeedback>
            <FaCaret className="position-absolute settings__input-icon" />
          </FormGroup>

          <p className="inbox__select-label">
            Select the date when the request was made:
          </p>

          <div className="d-flex inbox__select-date-container">
            <span className="inbox__select-date">
              <DatePicker
                customInput={<DatePickerCustomInput />}
                selected={this.state.startDate}
                maxDate={new Date()}
                onChange={this.handleChange}
                placeholderText="Click to select a date"
                dateFormat="yyyy-MM-dd"
              />
            </span>

            {this.state.startDate ? (
              <div className="mb-4">
                <p className="inbox__select-date-due mb-0">
                  <strong>Due date</strong> <img src={Flag} alt="due" />
                </p>
                <Moment add={{ days: 30 }} format="DD MMM YYYY">
                  {this.state.startDate}
                </Moment>
              </div>
            ) : null}
          </div>

          <span className="divider" />

          <Fragment>
            <p className="inbox__create-title">Source</p>

            <div className="d-flex align-items-center inbox__create mb-3">
              <span
                className={`${
                  sourceType === "DataSubject" ||
                  requestType === "QUESTION" ||
                  requestType === "COMPLAINT"
                    ? "-is-active"
                    : ""
                } inbox__create-check mr-3`}
                onClick={() => this.setState({ sourceType: "DataSubject" })}
              />
              Data Subject
            </div>

            {requestType === "QUESTION" ||
            requestType === "COMPLAINT" ? null : (
              <div className="d-flex align-items-center inbox__create mb-3">
                <span
                  className={`${
                    sourceType === "DataController" ? "-is-active" : ""
                  } inbox__create-check mr-3`}
                  onClick={() =>
                    this.setState({ sourceType: "DataController" })
                  }
                />
                Data Controller
              </div>
            )}

            {requestType === "COMPLAINT" || requestType === "QUESTION" ? (
              <Fragment>
                <p className="inbox__select-question">
                  Write the user’s{" "}
                  {requestType === "COMPLAINT" ? "complaint:" : "question:"}
                </p>

                <Input
                  type="textarea"
                  name="question"
                  placeholder={
                    requestType === "COMPLAINT"
                      ? "Write the complaint"
                      : "Write the question"
                  }
                  className="inbox__create-textarea"
                  onChange={(e) => this.setState({ message: e.target.value })}
                />
              </Fragment>
            ) : null}

            {sourceType === "DataSubject" ||
            requestType === "QUESTION" ||
            requestType === "COMPLAINT" ? (
              <span className="divider mb-4" />
            ) : null}

            {sourceType === "DataSubject" ||
            requestType === "QUESTION" ||
            requestType === "COMPLAINT" ? (
              !this.state.selectedUsers && !isAddNew ? (
                <div className="d-flex inbox__select-btns">
                  <Button
                    className="btn--secondary inbox__select-user"
                    onClick={this.showSelectUsers}
                  >
                    Select user
                  </Button>
                  <Button
                    className="btn--secondary inbox__add-user"
                    onClick={this.addNewUser}
                  >
                    Add new
                  </Button>
                </div>
              ) : this.state.selectedUsers ? (
                <Fragment>
                  <div className="inbox__selected">
                    {this.state.selectedUsers.name}(
                    {this.state.selectedUsers.email}
                    )
                    <MdClose
                      size={20}
                      className="inbox__remove-selected"
                      onClick={() => this.setState({ selectedUsers: null })}
                    />
                  </div>
                  <div className="inbox__create-verify">
                    <p>
                      Was the data subject’s identity verified on the request?
                    </p>

                    <div className="d-flex">
                      <div className="d-flex flex-row align-items-center inbox__create mb-3 pl-0">
                        <span
                          className={`${
                            identityVerified ? "-is-active" : ""
                          } inbox__create-check mr-3`}
                          onClick={() =>
                            this.setState({ identityVerified: true })
                          }
                        />
                        Yes
                      </div>

                      <div className="d-flex align-items-center inbox__create mb-3">
                        <span
                          className={`${
                            !identityVerified ? "-is-active" : ""
                          } inbox__create-check mr-3`}
                          onClick={() =>
                            this.setState({ identityVerified: false })
                          }
                        />
                        No
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : isAddNew ? (
                <div className="d-flex inbox__add-new-user">
                  <div className="d-flex inbox__logo-container">
                    <div className="position-relative d-flex align-items-center">
                      {files.length ? (
                        files.map((file, i) => (
                          <div key={i} className="inbox__avatar-container">
                            <img src={file.url} alt="avatar" />
                          </div>
                        ))
                      ) : (
                        <span className="inbox__image-placeholder" />
                      )}

                      <ReactFilestack
                        apikey={"AJNM9qOpGRljTn17sgxrfz"}
                        componentDisplayMode={{
                          type: "link",
                          customText: <span />,
                          customClass: "show-upload",
                        }}
                        actionOptions={{
                          accept: ["image/*"],
                          maxFiles: 1,
                        }}
                        onSuccess={this.onFiles}
                      />
                    </div>
                  </div>
                  <div className="inbox__add-user-form">
                    <FormGroup className="mb-4">
                      <Input
                        type="text"
                        name="userName"
                        placeholder="Name"
                        onChange={(e) =>
                          this.setState({ userName: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        type="email"
                        name="userEmail"
                        placeholder="Email"
                        onChange={(e) =>
                          this.setState({ userEmail: e.target.value })
                        }
                      />
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        type="tel"
                        name="userPhone"
                        placeholder="Telephone"
                        onChange={(e) =>
                          this.setState({ userPhone: e.target.value })
                        }
                      />
                    </FormGroup>
                    <Input
                      name="companyName"
                      type="text"
                      className="mb-4"
                      placeholder="Company (if request comes from a company)"
                      onChange={(e) =>
                        this.setState({ companyName: e.target.value })
                      }
                    />

                    <p>
                      Was the data subject’s identity verified on the request?
                    </p>

                    <div className="d-flex">
                      <div className="d-flex flex-row align-items-center inbox__create mb-3 pl-0">
                        <span
                          className={`${
                            identityVerified ? "-is-active" : ""
                          } inbox__create-check mr-3`}
                          onClick={() =>
                            this.setState({ identityVerified: true })
                          }
                        />
                        Yes
                      </div>

                      <div className="d-flex align-items-center inbox__create mb-3">
                        <span
                          className={`${
                            !identityVerified ? "-is-active" : ""
                          } inbox__create-check mr-3`}
                          onClick={() =>
                            this.setState({ identityVerified: false })
                          }
                        />
                        No
                      </div>
                    </div>

                    <Button
                      className="btn--secondary inbox__add-user"
                      onClick={() => {
                        this.addNewUser();
                        this.setState({
                          userName: "",
                          userEmail: "",
                          userPhone: "",
                          companyName: "",
                          files: [],
                        });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : null
            ) : null}

            {sourceType === "DataController" ? (
              <CreateRequestDC
                showNewDC={showNewDC}
                files={files}
                showNewDS={showNewDS}
                identityVerified={identityVerified}
                showNewDSAction={(val) => this.setState({ showNewDS: val })}
                showNewDCAction={(val) => this.setState({ showNewDC: val })}
                verifyIdentity={(val) =>
                  this.setState({ identityVerified: val })
                }
                showSelectUsers={this.showSelectUsers}
                removeDC={() => this.setState({ selectedDP: null })}
                showSelectDC={this.showSelectDC}
                selectedUsers={selectedUsers}
                selectedDP={selectedDP}
                removeUser={() => this.setState({ selectedUsers: null })}
                getDSInfo={(state, val) => this.setState({ [state]: val })}
                onFiles={this.onFiles}
                onDCFiles={this.onDCFiles}
                DCfiles={this.state.DCfiles}
              />
            ) : null}
          </Fragment>

          <span className="divider" />

          <div className="d-flex inbox__select-btns mt-4">
            <Button
              className="btn--primary inbox__select-user"
              disabled={
                (requestType === "COMPLAINT" &&
                  this.state.status &&
                  this.state.startDate &&
                  this.state.selectedUsers) ||
                (requestType === "COMPLAINT" &&
                  this.state.status &&
                  this.state.startDate &&
                  isAddNew &&
                  this.state.userName &&
                  this.state.userEmail) ||
                (requestType === "QUESTION" &&
                  this.state.status &&
                  this.state.startDate &&
                  isAddNew &&
                  this.state.userName &&
                  this.state.userEmail) ||
                (requestType === "QUESTION" &&
                  this.state.status &&
                  this.state.startDate &&
                  this.state.selectedUsers) ||
                (this.state.sourceType === "DataSubject" &&
                  this.state.status &&
                  this.state.startDate &&
                  this.state.selectedUsers) ||
                (this.state.sourceType === "DataSubject" &&
                  this.state.status &&
                  this.state.startDate &&
                  isAddNew &&
                  this.state.userName &&
                  this.state.userEmail) ||
                (sourceType === "DataController" &&
                  this.state.status &&
                  this.state.startDate &&
                  this.state.selectedUsers &&
                  this.state.selectedDP) ||
                (sourceType === "DataController" &&
                  this.state.status &&
                  this.state.startDate &&
                  showNewDS &&
                  this.state.userName &&
                  this.state.userEmail &&
                  this.state.selectedDP) ||
                (sourceType === "DataController" &&
                  this.state.status &&
                  this.state.startDate &&
                  this.state.selectedUsers &&
                  showNewDC &&
                  this.state.dcName &&
                  this.state.dcEmail) ||
                (sourceType === "DataController" &&
                  this.state.status &&
                  this.state.startDate &&
                  showNewDC &&
                  this.state.dcName &&
                  this.state.dcEmail &&
                  showNewDS &&
                  this.state.userName &&
                  this.state.userEmail)
                  ? false
                  : true
              }
              type="submit"
            >
              Create request
            </Button>
            <Button
              className="btn--secondary inbox__add-user"
              onClick={this.props.closeCreate}
            >
              Cancel
            </Button>
          </div>
        </Form>

        <SelectUser
          showSelectUsers={this.showSelectUsers}
          isSelectUsersVisible={this.state.isSelectUsersVisible}
          users={this.props.users}
          getSelectedUsers={this.getSelectedUsers}
        />

        <SelectDataController
          showSelectDC={this.showSelectDC}
          isSelectDCVisible={this.state.isSelectDCVisible}
          users={this.props.users}
          getSelectedUsers={this.getSelectedDC}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(CREATE_REQUEST_INBOX, {
    name: "createRequestInbox",
  }),
  graphql(CREATE_REQUEST_DC, {
    name: "createRequestDC",
  }),
  graphql(CREATE_DATA_ENTITY, {
    name: "createDataEntity",
  })
)(CreateRequest);
