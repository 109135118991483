import PropTypes from "prop-types";
import React from "react";

const CheckDisabled = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Journey-0---Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.5--Dashboard---empty-state" transform="translate(-1154.000000, -416.000000)" stroke="#B0BAC9">
                <g id="Gettings-started-bar" transform="translate(1130.000000, 98.000000)">
                    <g id="Tasks" transform="translate(24.000000, 231.000000)">
                        <g id="Checkboxes" transform="translate(0.000000, 3.000000)">
                            <g id="Check-disabled" transform="translate(0.000000, 84.000000)">
                                <g id="Group">
                                    <rect id="Rectangle" x="0.5" y="0.5" width="19" height="19" rx="4"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

CheckDisabled.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default CheckDisabled;