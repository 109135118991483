import React, { Component } from "react";
import Check from "react-icons/lib/fa/check";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

export default class HowBreach extends Component {
  static propTypes = {
    description: PropTypes.string,
    natures: PropTypes.array,
    getNatures: PropTypes.func,
    getDescription: PropTypes.func,
  };

  state = {
    isEncrypted: this.props.natures
      ? this.props.natures.indexOf("Encrypted") > -1
        ? true
        : false
      : null,
    isUnencrypted: this.props.natures
      ? this.props.natures.indexOf("Unencrypted") > -1
        ? true
        : false
      : null,
    isPaper: this.props.natures
      ? this.props.natures.indexOf("Paper") > -1
        ? true
        : false
      : null,
    isDisclosure: this.props.natures
      ? this.props.natures.indexOf("Disclosure") > -1
        ? true
        : false
      : null,
    isDisposal: this.props.natures
      ? this.props.natures.indexOf("Disposal") > -1
        ? true
        : false
      : null,
    isHacking: this.props.natures
      ? this.props.natures.indexOf("Hacking") > -1
        ? true
        : false
      : null,
    isMalware: this.props.natures
      ? this.props.natures.indexOf("Malware") > -1
        ? true
        : false
      : null,
    isPhishing: this.props.natures
      ? this.props.natures.indexOf("Phishing") > -1
        ? true
        : false
      : null,
    isEWaste: this.props.natures
      ? this.props.natures.indexOf("EWaste") > -1
        ? true
        : false
      : null,
    isUnintended: this.props.natures
      ? this.props.natures.indexOf("Unintended") > -1
        ? true
        : false
      : null,
    isNetwork: this.props.natures
      ? this.props.natures.indexOf("Network") > -1
        ? true
        : false
      : null,
    isWebsite: this.props.natures
      ? this.props.natures.indexOf("Website") > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.natures
      ? this.props.natures.filter(
          (item) =>
            item !== "Encrypted" &&
            item !== "Unencrypted" &&
            item !== "Paper" &&
            item !== "Disclosure" &&
            item !== "Disposal" &&
            item !== "Hacking" &&
            item !== "Malware" &&
            item !== "Phishing" &&
            item !== "EWaste" &&
            item !== "Unintended" &&
            item !== "Network" &&
            item !== "Website"
        ).length
        ? true
        : false
      : false,
    description: "",
  };

  getNatures = () => {
    const info = this.props.natures
      ? this.props.natures.filter(
          (item) =>
            item !== "Encrypted" &&
            item !== "Unencrypted" &&
            item !== "Paper" &&
            item !== "Disclosure" &&
            item !== "Disposal" &&
            item !== "Hacking" &&
            item !== "Malware" &&
            item !== "Phishing" &&
            item !== "EWaste" &&
            item !== "Unintended" &&
            item !== "Network" &&
            item !== "Website"
        )
      : "";

    this.props.getNatures([
      this.state.isEncrypted === true ? "Encrypted" : null,
      this.state.isUnencrypted === true ? "Unencrypted" : null,
      this.state.isPaper === true ? "Paper" : null,
      this.state.isDisclosure === true ? "Disclosure" : null,
      this.state.isDisposal === true ? "Disposal" : null,
      this.state.isHacking === true ? "Hacking" : null,
      this.state.isMalware === true ? "Malware" : null,
      this.state.isPhishing === true ? "Phishing" : null,
      this.state.isEWaste === true ? "EWaste" : null,
      this.state.isUnintended === true ? "Unintended" : null,
      this.state.isNetwork === true ? "Network" : null,
      this.state.isWebsite === true ? "Website" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  getDescription = () => {
    this.props.getDescription(this.state.description);
  };

  render() {
    const { description } = this.props;
    const info = this.props.natures
      ? this.props.natures.filter(
          (item) =>
            item !== "Encrypted" &&
            item !== "Unencrypted" &&
            item !== "Paper" &&
            item !== "Disclosure" &&
            item !== "Disposal" &&
            item !== "Hacking" &&
            item !== "Malware" &&
            item !== "Phishing" &&
            item !== "EWaste" &&
            item !== "Unintended" &&
            item !== "Network" &&
            item !== "Website"
        )
      : "";

    return (
      <div className="compliance-breaches__where">
        <h5 className="compliance-breaches__where-title mb-3">
          Nature of the breach
        </h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isEncrypted ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isEncrypted: !this.state.isEncrypted }, () =>
                this.getNatures()
              )
            }
          >
            <Check color={"#fff"} />
          </span>

          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Device Lost or Stolen (encrypted)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isUnencrypted ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isUnencrypted: !this.state.isUnencrypted }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Device Lost or Stolen (unencrypted)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isPaper ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isPaper: !this.state.isPaper }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Paper lost/stolen
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDisclosure ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isDisclosure: !this.state.isDisclosure }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Disclosure (unauthorized)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDisposal ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isDisposal: !this.state.isDisposal }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Inappropriate disposal of paper
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isHacking ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isHacking: !this.state.isHacking }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Hacking
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isMalware ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isMalware: !this.state.isMalware }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Malware
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isPhishing ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isPhishing: !this.state.isPhishing }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Phishing
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isEWaste ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isEWaste: !this.state.isEWaste }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              E-Waste (personal data present on obsolete device)
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isUnintended ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isUnintended: !this.state.isUnintended }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Unintended online publication
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isNetwork ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isNetwork: !this.state.isNetwork }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Network security compromised
            </span>
          </div>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isWebsite ? "-checked" : "-unchecked"
            }`}
            onClick={() => {
              this.setState({ isWebsite: !this.state.isWebsite }, () =>
                this.getNatures()
              );
            }}
          >
            <Check color={"#fff"} />
          </span>
          <div className="d-flex flex-column compliance-breaches__where-check-content">
            <span className="compliance-breaches__where-check-text">
              Website security breach
            </span>
          </div>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() => {
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getNatures()
                );
              }}
            >
              <Check color={"#fff"} />
            </span>
            <div className="d-flex flex-column compliance-breaches__where-check-content">
              <span className="compliance-breaches__where-check-text">
                Other
              </span>
            </div>
          </div>
          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getNatures())
            }
          />
        </div>

        <div className="compliance-breaches__where-description">
          <h5 className="compliance-breaches__where-title mb-3">Description</h5>
          <Input
            defaultValue={description}
            type="textarea"
            onChange={(e) => {
              this.setState({ description: e.target.value });
              this.getDescription();
            }}
            placeholder="Write a message..."
          />
        </div>
      </div>
    );
  }
}
