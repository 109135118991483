import gql from "graphql-tag";

export const ADMIN_PUBLISH_DP_PROFILE = gql`
  mutation(
    $logo: String!
    $clientMutationId: String
    $smallDescription: String!
    $dataProcessorId: ID!
    $companyName: String!
    $photo1: String!
    $title1: String!
    $text1: String!
    $photo2: String!
    $title2: String!
    $text2: String!
  ) {
    adminPublishProfileDataProcessor(
      input: {
        logo: $logo
        clientMutationId: $clientMutationId
        smallDescription: $smallDescription
        companyName: $companyName
        photo1: $photo1
        title1: $title1
        dataProcessorId: $dataProcessorId
        text1: $text1
        photo2: $photo2
        title2: $title2
        text2: $text2
      }
    ) {
      errors
      dataProcessor {
        id
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
  }
`;
