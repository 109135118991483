import gql from "graphql-tag";

export const RESET_PASSWORD = gql`
  mutation(
    $password1: String!
    $password2: String!
    $token: String!
    $clientMutationId: String
  ) {
    resetPassword(
      input: {
        password1: $password1
        password2: $password2
        token: $token
        clientMutationId: $clientMutationId
      }
    ) {
      authenticationToken
      errors
    }
  }
`;
