import React, { Component, Fragment } from "react";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import DatePickerCustomInput from "./DatePickerCustomInput";

export default class BreachDtepicker extends Component {
  static propTypes = {
    date: PropTypes.string,
    getDate: PropTypes.func,
    removeDate: PropTypes.func,
  };

  state = {
    startDate: null,
    isNone: this.props.date === null ? true : false,
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
      isNone: false,
    });

    this.props.getDate(date);
  };

  checkNone = () => {
    this.setState({
      isNone: true,
      startDate: null,
    });
  };

  render() {
    const { isNone } = this.state;

    return (
      <Fragment>
        <DatePicker
          customInput={<DatePickerCustomInput date={this.props.date} />}
          selected={this.state.startDate}
          onChange={this.handleChange}
          showTimeSelect
          placeholderText={
            this.props.date ? this.props.date : "Click to select a date"
          }
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          timeCaption="time"
        />

        <div className="d-flex align-items-center compliance-breaches__where-radio-label mt-2">
          <span
            className={`${
              isNone ? "-is-active" : ""
            } compliance-breaches__where-radio mr-2`}
            onClick={() => {
              this.props.removeDate();
              this.checkNone();
              this.props.getDate();
            }}
          />
          I don't know
        </div>
      </Fragment>
    );
  }
}
