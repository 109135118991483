import gql from "graphql-tag";

export const REMOVE_DATA_ENTITIES = gql`
  mutation($entities: [DataEntityTypeObject!]!, $clientMutationId: String) {
    removeDataEntities(
      input: { entities: $entities, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
