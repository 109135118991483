import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class DiscardChanges extends Component {
  static propTypes = {
    discardChanges: PropTypes.func.isRequired,
    isDiscardModalVisible: PropTypes.bool.isRequired,
    removeChanges: PropTypes.func.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isDiscardModalVisible}
          toggle={this.props.discardChanges}
          className="discard-changes"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.discardChanges}
            />
            <p className="modal-assign__title mb-3">
              Are you sure you want to discard these changes?
            </p>
            <p className="modal-assign__text mb-0 mt-0">
              This step can not be undone.
            </p>

            <div className="discard-changes__btns">
              <Button
                className="btn--secondary"
                onClick={this.props.discardChanges}
              >
                Cancel
              </Button>
              <Button
                className="btn--primary"
                onClick={() => {
                  this.props.discardChanges();
                  this.props.removeChanges();
                }}
              >
                Discard changes
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
