import React, { Fragment } from "react";
import { Button, FormGroup, Input } from "reactstrap";
import ReactFilestack from "filestack-react";
import MdClose from "react-icons/lib/md/close";
import PropTypes from "prop-types";

function CreateRequestDC(props) {
  const {
    showNewDC,
    files,
    showNewDS,
    identityVerified,
    showNewDSAction,
    showNewDCAction,
    verifyIdentity,
    selectedUsers,
    selectedDP,
    removeUser,
    getDSInfo,
    onFiles,
    onDCFiles,
    removeDC,
    DCfiles,
  } = props;

  return (
    <Fragment>
      <span className="divider" />
      <p className="inbox__create-title">Data Controller info</p>
      <p className="inbox__select-label">
        A Data Controller is a company that you process data for.
      </p>

      {!showNewDC && !selectedDP ? (
        <div className="d-flex inbox__select-btns">
          <Button
            className="btn--secondary inbox__add-user mr-3"
            onClick={() => props.showSelectDC()}
          >
            Select
          </Button>
          <Button
            className="btn--secondary inbox__add-user"
            onClick={() => showNewDCAction(true)}
          >
            Add new
          </Button>
        </div>
      ) : selectedDP && !showNewDC ? (
        <Fragment>
          <div className="inbox__selected">
            {selectedDP.name}({selectedDP.email}
            )
            <MdClose
              size={20}
              className="inbox__remove-selected"
              onClick={removeDC}
            />
          </div>
        </Fragment>
      ) : (
        <div className="d-flex inbox__add-new-user">
          <div className="d-flex inbox__logo-container">
            <div className="position-relative d-flex align-items-center">
              {DCfiles.length ? (
                DCfiles.map((file, i) => (
                  <div key={i} className="inbox__avatar-container">
                    <img src={file.url} alt="avatar" />
                  </div>
                ))
              ) : (
                <span className="inbox__image-placeholder" />
              )}

              <ReactFilestack
                apikey={"AJNM9qOpGRljTn17sgxrfz"}
                componentDisplayMode={{
                  type: "link",
                  customText: <span />,
                  customClass: "show-upload",
                }}
                actionOptions={{
                  accept: ["image/*"],
                  maxFiles: 1,
                }}
                onSuccess={onDCFiles}
              />
            </div>
          </div>
          <div className="inbox__add-user-form">
            <FormGroup className="mb-4">
              <Input
                type="text"
                name="dcName"
                placeholder="Name"
                onChange={(e) => getDSInfo("dcName", e.target.value)}
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <Input
                type="email"
                name="dcEmail"
                placeholder="Email"
                onChange={(e) => getDSInfo("dcEmail", e.target.value)}
              />
            </FormGroup>
            <FormGroup className="mb-4">
              <Input
                type="tel"
                name="dcPhone"
                placeholder="Telephone"
                onChange={(e) => getDSInfo("dcPhone", e.target.value)}
              />
            </FormGroup>
            <Input
              name="dcCompany"
              type="text"
              className="mb-4"
              onChange={(e) => getDSInfo("dcCompany", e.target.value)}
              placeholder="Company"
            />

            <Button
              className="btn--secondary inbox__add-user"
              onClick={() => {
                showNewDCAction(false);
                getDSInfo("dcName", "");
                getDSInfo("dcEmail", "");
                getDSInfo("dcPhone", "");
                getDSInfo("dcCompany", "");
                getDSInfo("DCfiles", []);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

      <span className="divider" />
      <p className="inbox__create-title">Data Subject info</p>
      <p className="inbox__select-label">
        The data subject is the person whose personal data are collected, held
        or processed.
      </p>

      {!showNewDS && !selectedUsers ? (
        <div className="d-flex inbox__select-btns">
          <Button
            className="btn--secondary inbox__add-user mr-3"
            onClick={() => props.showSelectUsers()}
          >
            Select
          </Button>
          <Button
            className="btn--secondary inbox__add-user"
            onClick={() => showNewDSAction(true)}
          >
            Add new
          </Button>
        </div>
      ) : selectedUsers && !showNewDS ? (
        <Fragment>
          <div className="inbox__selected">
            {selectedUsers.name}({selectedUsers.email}
            )
            <MdClose
              size={20}
              className="inbox__remove-selected"
              onClick={removeUser}
            />
          </div>
          <div className="inbox__create-verify">
            <p>Was the data subject’s identity verified on the request?</p>

            <div className="d-flex">
              <div className="d-flex flex-row align-items-center inbox__create mb-3 pl-0">
                <span
                  className={`${
                    identityVerified ? "-is-active" : ""
                  } inbox__create-check mr-3`}
                  onClick={() => verifyIdentity(true)}
                />
                Yes
              </div>

              <div className="d-flex align-items-center inbox__create mb-3">
                <span
                  className={`${
                    !identityVerified ? "-is-active" : ""
                  } inbox__create-check mr-3`}
                  onClick={() => verifyIdentity(false)}
                />
                No
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className="d-flex inbox__add-new-user">
            <div className="d-flex inbox__logo-container">
              <div className="position-relative d-flex align-items-center">
                {files.length ? (
                  files.map((file, i) => (
                    <div key={i} className="inbox__avatar-container">
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : (
                  <span className="inbox__image-placeholder" />
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span />,
                    customClass: "show-upload",
                  }}
                  actionOptions={{
                    accept: ["image/*"],
                    maxFiles: 1,
                  }}
                  onSuccess={onFiles}
                />
              </div>
            </div>
            <div className="inbox__add-user-form">
              <FormGroup className="mb-4">
                <Input
                  type="text"
                  name="userName"
                  placeholder="Name"
                  onChange={(e) => getDSInfo("userName", e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-4">
                <Input
                  type="email"
                  name="userEmail"
                  placeholder="Email"
                  onChange={(e) => getDSInfo("userEmail", e.target.value)}
                />
              </FormGroup>
              <FormGroup className="mb-4">
                <Input
                  type="tel"
                  name="userPhone"
                  placeholder="Telephone"
                  onChange={(e) => getDSInfo("userPhone", e.target.value)}
                />
              </FormGroup>
              <Input
                name="companyName"
                type="text"
                className="mb-4"
                placeholder="Company (if request comes from a company)"
                onChange={(e) => getDSInfo("companyName", e.target.value)}
              />

              <div className="inbox__create-verify ml-0">
                <p>Was the data subject’s identity verified on the request?</p>

                <div className="d-flex">
                  <div className="d-flex flex-row align-items-center inbox__create mb-3 pl-0">
                    <span
                      className={`${
                        identityVerified ? "-is-active" : ""
                      } inbox__create-check mr-3`}
                      onClick={() => verifyIdentity(true)}
                    />
                    Yes
                  </div>

                  <div className="d-flex align-items-center inbox__create mb-3">
                    <span
                      className={`${
                        !identityVerified ? "-is-active" : ""
                      } inbox__create-check mr-3`}
                      onClick={() => verifyIdentity(false)}
                    />
                    No
                  </div>
                </div>
              </div>

              <Button
                className="btn--secondary inbox__add-user"
                onClick={() => {
                  showNewDSAction(false);
                  getDSInfo("userName", "");
                  getDSInfo("userEmail", "");
                  getDSInfo("userPhone", "");
                  getDSInfo("companyName", "");
                  getDSInfo("files", []);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default CreateRequestDC;
CreateRequestDC.propTypes = {
  showNewDC: PropTypes.bool,
  files: PropTypes.array,
  showNewDS: PropTypes.bool,
  identityVerified: PropTypes.bool,
  showNewDSAction: PropTypes.func,
  showNewDCAction: PropTypes.func,
  verifyIdentity: PropTypes.func,
  selectedUsers: PropTypes.object,
  selectedDP: PropTypes.object,
  removeUser: PropTypes.func,
  getDSInfo: PropTypes.func,
  onFiles: PropTypes.func,
  onDCFiles: PropTypes.func,
  removeDC: PropTypes.func,
  DCfiles: PropTypes.array,
  showSelectDC: PropTypes.func,
  showSelectUsers: PropTypes.func,
};
