import React, { Component } from "react";
import PropTypes from "prop-types";
import "./pages.scss";
import CheckCircle from "../../components/Icons/CheckCircle";
import Switch from "react-switch";
import MdClose from "react-icons/lib/md/close";
import Moment from "react-moment";
import WarningIcon from "../../components/Icons/WarningIcon";
import DeletePage from "../../components/Modals/DeletePage";

export default class PagesItem extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    update: PropTypes.string.isRequired,
    actionNeeded: PropTypes.bool,
    applicable: PropTypes.bool.isRequired,
    item: PropTypes.object,
    removePage: PropTypes.func.isRequired,
    makePageApplicable: PropTypes.func.isRequired,
    page: PropTypes.object,
    index: PropTypes.number,
    showPage: PropTypes.func,
    alerts: PropTypes.array,
    viewer: PropTypes.object,
  };

  state = {
    isApplicable: this.props.page.applicable,
    isDeleteModalVisible: false,
  };

  handleChange = (isApplicable) => {
    this.setState({ isApplicable });

    this.props.makePageApplicable(this.props.page.id);
  };

  showDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  render() {
    const { title, text, update, actionNeeded, page, index, alerts, viewer } =
      this.props;

    const currentAlert = alerts.filter((item) =>
      item.category === "REVIEW_NEW_TEMPLATE_PUBLICATION" ||
      item.category === "REVIEW_TEMPLATE_CHANGES"
        ? item.page.id === page.id
        : null
    );

    return (
      <div className="pages__item">
        <div
          className="d-flex align-items-center flex-grow-1"
          onClick={() => this.props.showPage(title, actionNeeded, page, index)}
        >
          {currentAlert.length ? (
            <WarningIcon className="mr-5 pages__item-icon" />
          ) : (
            <CheckCircle className="mr-5" />
          )}

          <div>
            <p className="pages__item-terms">{title}</p>
            <p className="pages__item-done">
              {currentAlert.length
                ? `Action needed - ${
                    currentAlert[0].category ===
                    "REVIEW_NEW_TEMPLATE_PUBLICATION"
                      ? "review new template publication"
                      : "review page details"
                  }`
                : text === "UP_TO_DATE"
                ? "Done and up-to-date"
                : text === "PENDING"
                ? "Pending"
                : null}
            </p>
            {viewer && viewer.viewer && viewer.viewer.editedPage === true ? (
              <p className="pages__item-update">
                Last update <Moment fromNow>{update}</Moment>
              </p>
            ) : null}
          </div>
        </div>
        <div className="d-flex">
          {title !== "Terms & Conditions" &&
          title !== "Privacy policy" &&
          title !== "Privacy Policy" &&
          title !== "Data Processing Agreement" &&
          title.trim() !== "Cookies Policy" ? (
            <MdClose
              size={20}
              color="#969696"
              className="pages__item-remove mr-3"
              onClick={this.showDeleteModal}
            />
          ) : null}
          <div className="pl-3">
            <Switch
              onColor={"#1DDCC0"}
              offColor={"#B0BAC9"}
              height={20}
              width={36}
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={this.handleChange}
              checked={this.state.isApplicable}
            />
          </div>
        </div>

        <DeletePage
          showDeleteModal={this.showDeleteModal}
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          removePage={() => this.props.removePage(page.id)}
        />
      </div>
    );
  }
}
