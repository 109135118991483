import PropTypes from "prop-types";
import React, { Component } from "react";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import NoData from "../../images/Inbox/NoData.svg";
import Warning from "../../images/Inbox/Warning.svg";
import Delayed from "../../images/Inbox/Delayed.svg";
import FileReceived from "../../images/Inbox/FileReceived.svg";
import RequestSent from "../../images/Inbox/RequestSent.svg";
import send from "../../images/Inbox/send.svg";
import file from "../../images/Inbox/file.svg";
import TrashIcon from "../../components/Icons/TrashIcon";

class TaskTable extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool.isRequired,
    user: PropTypes.object,
    checkItem: PropTypes.func,
    sendRequest: PropTypes.func,
    receivedFile: PropTypes.func,
    removeTask: PropTypes.func,
    isSell: PropTypes.bool,
    assigned: PropTypes.bool,
    showAssignedModal: PropTypes.func,
  };

  state = {
    isChecked: false,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === false
    ) {
      this.setState({
        isChecked: false,
      });
    }

    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === true
    ) {
      this.setState({
        isChecked: true,
      });
    }
  }

  handleCheck = () => {
    if (this.state.isChecked) {
      return (
        <CheckActive
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(this.props.user.id);
          }}
        />
      );
    } else if (!this.state.isChecked) {
      return (
        <CheckDisabled
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(this.props.user.id);
          }}
        />
      );
    }
  };

  render() {
    const { user, isSell, assigned } = this.props;

    return (
      <tr>
        <th>
          <span>{this.handleCheck()}</span>
        </th>

        <td>
          <span className="d-flex align-items-center justify-content-start">
            {user.dataProcessor && user.dataProcessor.logo ? (
              <img src={user.dataProcessor.logo} className="mr-2" alt="logo" />
            ) : (
              <span className="inbox__internal-logo-placeholder mr-2">
                {user && user.dataProcessor && user.dataProcessor.companyName
                  ? user.dataProcessor.companyName.charAt(0).toUpperCase()
                  : ""}
              </span>
            )}{" "}
            {user && user.dataProcessor && user.dataProcessor.companyName
              ? user.dataProcessor.companyName
              : ""}
          </span>
        </td>
        {!isSell ? (
          <td>
            <span className="d-flex align-items-center justify-content-start">
              {user && user.dataProcessor && user.dataProcessor.processingType
                ? user.dataProcessor.processingType === "LEGALSITE_ACCOUNT"
                  ? "Legalsite Account"
                  : user.dataProcessor.processingType === "USER_INTERFACE"
                  ? "User Interface"
                  : user.dataProcessor.processingType === "EMAIL"
                  ? "Email"
                  : "No Type"
                : null}
            </span>
          </td>
        ) : null}
        <td>
          <span className="d-flex align-items-center justify-content-start">
            {user.status === "NOT_REQUESTED" ||
            user.status === "NOT_NOTIFIED" ? (
              <img src={Warning} alt="status" />
            ) : user.status === "DELAYED" ? (
              <img src={Delayed} alt="status" />
            ) : user.status === "REQUEST_SEND" ? (
              <img src={RequestSent} alt="status" />
            ) : user.status === "FILE_RECEIVED" ||
              user.status === "NOTIFIED" ? (
              <img src={FileReceived} alt="status" />
            ) : user.status === "Can't find a data" ? (
              <img src={NoData} alt="status" />
            ) : null}
            &nbsp;
            <div>
              {user.status === "NOT_REQUESTED"
                ? "Not requested"
                : user.status === "NOTIFIED"
                ? "Notified"
                : user.status === "REQUEST_SEND"
                ? "Request sent"
                : user.status === "FILE_RECEIVED"
                ? "File received"
                : user.status === "DELAYED"
                ? "Delayed"
                : user.status === "NOT_NOTIFIED"
                ? "Not notified"
                : "Can't find a data"}
              {isSell && user && user.status === "NOT_NOTIFIED" ? (
                <p className="mb-0">Send notification.</p>
              ) : null}

              {isSell && user && user.status === "NOTIFIED" ? (
                <p className="mb-0">E-mail sent</p>
              ) : null}
            </div>
          </span>
        </td>
        <td className={`${isSell ? "pt-5" : ""} text-right`}>
          <div className="d-flex flex-column justify-content-start">
            <div
              onClick={() =>
                assigned
                  ? this.props.showAssignedModal()
                  : this.props.sendRequest(user.id)
              }
              className="d-flex align-items-center inbox__internal-header-send justify-content-center mb-3"
            >
              <img src={send} className="inbox__icon" alt="file" />
            </div>

            {!isSell ? (
              <div
                onClick={() =>
                  assigned
                    ? this.props.showAssignedModal()
                    : this.props.receivedFile(user.id)
                }
                className="d-flex align-items-center inbox__internal-header-received justify-content-center mb-3"
              >
                <img src={file} className="inbox__icon" alt="file" />
              </div>
            ) : null}

            <div
              onClick={() =>
                assigned
                  ? this.props.showAssignedModal()
                  : this.props.removeTask(user.id)
              }
              className="d-flex align-items-center inbox__internal-header-received justify-content-center"
            >
              <TrashIcon className="inbox__icon" color="#7450c8" />
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default TaskTable;
