import gql from "graphql-tag";

export const HEADER_QUERY = gql`
  {
    legalsite {
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    legalsiteSettings {
      companyLogo
      id
    }
  }
`;
