import gql from "graphql-tag";

export const TOGGLE_SIDEBAR = gql`
  mutation($clientMutationId: String) {
    toggleSidebar(input: { clientMutationId: $clientMutationId }) {
      errors
      user {
        id
        showSidebar
      }
    }
  }
`;
