import React, { Component, Fragment } from "react";
import { Alert } from "reactstrap";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { REMOVE_DATA_ENTITIES } from "../../graphql/removeDataEntities";
import { COMPLIANCE_USERS } from "../../graphql/complianceUsers";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import UsersActions from "../Users/UsersActions";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";
import DPAUserTable from "./DPAUserTable";
import PropTypes from "prop-types";

class DPAUsers extends Component {
  static propTypes = {
    showRequestSignature: PropTypes.func.isRequired,
    data: PropTypes.object,
    removeDataEntities: PropTypes.func,
    sendRequest: PropTypes.func,
  };

  state = {
    isCheckedAll: false,
    filteredUsers: null,
    isUploadDpaVisible: false,
    isRequestSignature: false,
    isSendRequest: false,
    user: null,
    arr: [],
    urls: [],
    userName: [],
    userType: [],
    userReports: [],
    hasFiltered: false,
    isDeleteModalVisible: false,
    removedCheck: false,
    pageNumber: 1,
    filter: "ALL",
  };

  notify = (text) => toast(text);

  showRequestSignature = () => {
    if (this.state.isRequestSignature) {
      this.setState({
        isCheckedAll: false,
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        user: null,
      });
    }
    this.setState({
      isRequestSignature: !this.state.isRequestSignature,
    });
  };

  searchUser = (user) => {
    this.props.data.refetch({ name: user });
  };

  uploadDpa = () => {
    this.setState({
      isUploadDpaVisible: !this.state.isUploadDpaVisible,
    });
  };

  sendRequest = () => {
    this.props.sendRequest();
  };

  setFilter = (filter) => {
    this.setState(
      {
        hasFiltered: true,
        pageNumber: 1,
        filteredUsers: null,
        filter,
      },
      () => this.props.data.refetch({ filter, pageNumber: 1 })
    );
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  checkAll = (users) => {
    this.setState({
      arr: [],
      urls: [],
      userName: [],
      userType: [],
      userReports: [],
    });

    this.checkEverything(this.state.userReports, users);
  };

  checkEverything = (reports, users) => {
    const userId = users.map((item) => item.id);
    const userUrl = users.map((item) => item.downloadUrl);
    const userName = users.map((item) => item.name);
    const userType = users.map((item) => item.__typename);

    this.setState({
      isCheckedAll: true,
      arr: userId,
      urls: userUrl,
      userName,
      userType,
      userReports: reports,
    });
  };

  unselectAll = () => {
    this.setState({
      isCheckedAll: false,
      arr: [],
      urls: [],
      userName: [],
      userType: [],
      userReports: [],
    });
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  removeUser = () => {
    const entities = this.state.arr.map((item, index) => {
      return {
        id: item,
        type:
          this.state.userType[index] === "DataSubject"
            ? "DATA_SUBJECT"
            : "DATA_CONTROLLER",
      };
    });

    this.props
      .removeDataEntities({
        variables: {
          entities,
        },
      })
      .then(() => {
        this.setState({
          arr: [],
          urls: [],
          userName: [],
          userType: [],
          isCheckedAll: false,
          pageNumber: 1,
        });

        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were removed!"
            : "User was removed!"
        );

        this.props.data.refetch({ pageNumber: 1 });
      })
      .catch((error) => {
        console.log(error);
        this.notify(
          this.state.arr && this.state.arr.length > 1
            ? "Users were not removed!"
            : "User was not removed"
        );
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  checkItem = (id, downloadUrl, name, type, report) => {
    let checkedItems = [];
    let checkedUrls = [];
    let checkedNames = [];
    let checkedTypes = [];
    let checkedReports = [];

    if (this.state.isCheckedAll) {
      this.setState({
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        userReports: [],
      });
    }
    this.setState({
      isCheckedAll: false,
    });

    checkedItems.concat(id);
    checkedUrls.concat(downloadUrl);
    checkedNames.concat(name);
    checkedTypes.concat(type);
    checkedReports.concat(report);

    if (this.state.arr.includes(id) && this.state.urls.includes(downloadUrl)) {
      for (var i = 0; i < this.state.arr.length; i++) {
        if (this.state.arr[i] === id && this.state.urls[i] === downloadUrl) {
          this.state.arr.splice(i, 1);
          this.state.urls.splice(i, 1);
          this.state.userName.splice(i, 1);
          this.state.userType.splice(i, 1);
          this.state.userReports.splice(i, 1);
          return;
        }
      }
    }

    this.setState({
      arr: this.state.arr.concat(id),
      urls: this.state.urls.concat(downloadUrl),
      userName: this.state.userName.concat(name),
      userType: this.state.userType.concat(type),
      userReports: this.state.userReports.concat(report),
    });
  };

  changePage = (pageNumber) => {
    this.setState(
      {
        pageNumber: pageNumber,
      },
      () => this.props.data.refetch({ pageNumber: pageNumber })
    );
  };

  render() {
    const { isCheckedAll } = this.state;
    const { loading, error, searchDataEntity } = this.props.data;

    const users =
      searchDataEntity && searchDataEntity.length
        ? searchDataEntity
        : this.props.data &&
          this.props.data.dataEntities &&
          this.props.data.dataEntities.dataEntities
        ? this.props.data.dataEntities.dataEntities
        : null;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <Fragment>
        <UsersActions
          isCheckedAll={isCheckedAll}
          users={users}
          searchUser={this.searchUser}
          showRequestSignature={() =>
            this.props.showRequestSignature(this.state.arr, this.state.userType)
          }
          checkAll={this.checkAll}
          unselectAll={this.unselectAll}
          setFilter={this.setFilter}
          arr={this.state.arr}
          download={this.download}
          deleteUser={this.openDeleteModal}
          isUsers={true}
          filter={this.state.filter}
        />

        <div className="users__table">
          <DPAUserTable
            users={users}
            isCheckedAll={isCheckedAll}
            checkItem={this.checkItem}
            userName={this.state.userName}
            userId={this.state.arr}
            download={this.download}
            changePage={this.changePage}
            numberOfPages={this.props.data.dataEntities.numberOfPages}
            pageNumber={this.state.pageNumber}
          />
        </div>

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          isUsers={this.state.arr && this.state.arr.length > 1}
          removeUser={this.removeUser}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </Fragment>
    );
  }
}

export default compose(
  graphql(COMPLIANCE_USERS, {
    options: () => ({
      variables: {
        filter: "ALL",
        pageNumber: 1,
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(REMOVE_DATA_ENTITIES, {
    name: "removeDataEntities",
  })
)(DPAUsers);
