import gql from "graphql-tag";

export const REMOVE_REQUEST = gql`
  mutation($id: [ID!]!, $clientMutationId: String) {
    removeRequests(
      input: { ids: $id, clientMutationId: $clientMutationId }
    ) {
      errors
    }
  }
`;
