import gql from "graphql-tag";

export const COMPLIANCE_USERS = gql`
  query($filter: DataEntityFilterEnum!, $pageNumber: Int!, $name: String!) {
    searchDataEntity(name: $name) {
        company
        addedAt
        dpaSigned
        email
        id
        name
    }
    dataEntities(filter: $filter, pageNumber: $pageNumber) {
      numberOfPages
      dataEntities {
        ... on DataSubject {
          id
          UID
        }
        company
        addedAt
        dpaSigned
        email
        id
        name
      }
    }
  }
`;
