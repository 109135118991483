import React, { Component } from "react";
import { Button } from "reactstrap";
import MdArrow from "react-icons/lib/ti/arrow-right";
import AddDPStepOne from "./AddDPStepOne";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { CREATE_DP } from "../../graphql/createDataProcessor";
import { ToastContainer, toast } from "react-toastify";
import PropTypes from "prop-types";
import DpProfile from "./DpProfile";

class AddAdminDP extends Component {
  static propTypes = {
    createDataProcessor: PropTypes.func.isRequired,
    addAdminDataProcessor: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
  };
  state = {
    step: 1,
    formErrors: {},
    createdDPId: null,
    DPName: null,
  };

  handleStep1 = (e) => {
    e.preventDefault();

    let formData = e.target;
    const company = formData["company"].value,
      email = formData["email"].value,
      website = formData["website"].value;

    let hasFormErrors = false;
    let formErrors = {};

    if (!company) {
      hasFormErrors = true;
      formErrors.company = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!website) {
      hasFormErrors = true;
      formErrors.website = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .createDataProcessor({
        variables: {
          companyName: company,
          email,
          website,
        },
      })
      .then(({ data }) => {
        this.setState({
          createdDPId: data.createDataProcessor.dataProcessor.id,
          DPName: data.createDataProcessor.dataProcessor.companyName,
          step: 2,
        });

        formData.reset();
      })
      .catch((e) => {
        console.log(e);
        this.notify("DP wasn't added");
      });
  };

  notify = (text) => toast(text);

  render() {
    const { step } = this.state;
    return (
      <div className="data-processors container">
        {step === 1 ? (
          <AddDPStepOne
            handleStep1={this.handleStep1}
            companyErrors={this.state.formErrors.company}
            emailErrors={this.state.formErrors.email}
            websiteErrors={this.state.formErrors.website}
            addDataProcessor={this.props.addAdminDataProcessor}
          />
        ) : step === 2 ? (
          <DpProfile
            addAdminDataProcessor={this.props.addAdminDataProcessor}
            seeNextStep={() => this.setState({ step: 3 })}
            createdDPId={this.state.createdDPId}
            refetchData={this.props.refetchData}
          />
        ) : (
          <div className="data-processors__added-content">
            <p className="data-processors__add-title">
              {this.state.DPName} was added as a Data Processor!
            </p>
            <p className="data-processors__add-subtitle">
              {this.state.DPName} was successfully added as a Data Processor. We
              have configured an Integration, so now you can save even more time
              with privacy related procedures.{" "}
            </p>
            <Button
              className="bg-transparent data-processors__back"
              onClick={() => {
                this.props.addAdminDataProcessor();
                this.props.refetchData();
              }}
            >
              Back to Data Processors <MdArrow color={"#7450c8"} size={25} />
            </Button>
          </div>
        )}

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(CREATE_DP, {
    name: "createDataProcessor",
  })
)(AddAdminDP);
