import React from "react";
import { TabPane } from "reactstrap";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import "react-table-6/react-table.css";
import ReactTable from "react-table-6";
import PropTypes from "prop-types";

function HistoryAgreements(props) {
  const {
    historyAgreements,
    formatDate,
    date,
    parseDate,
    day,
    wholeMonth,
    year,
    generateReport,
  } = props;

  const requests = historyAgreements
    ? historyAgreements.filter(
        (item) => formatDate(date) === formatDate(parseDate(item.createdAt))
      )
    : null;

  const columns = [
    {
      Header: "User",
      accessor: "dataSubject",
      Cell: (props) =>
        props.value && props.value.name
          ? `${props.value && props.value.name ? props.value.name : ""} (${
              props.value ? props.value.email : ""
            })`
          : props.value.email,
    },
    {
      Header: "IP Number",
      accessor: "ipNumber",
    },
    {
      Header: "Time",
      Cell: () => (
        <span>
          {day} {wholeMonth} {year}
        </span>
      ),
      width: 160,
    },
    {
      Header: "User agent",
      accessor: "userAgent",
    },
    {
      Header: "URL",
      accessor: "originUrl",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Page Version",
      accessor: "publicationVersion",
      width: 100,
    },
    {
      Header: "",
      accessor: "",
      Cell: (props) => (
        <span
          onClick={() => generateReport(props.value.id, "AGREEMENT")}
          style={{ cursor: "pointer" }}
        >
          <DownloadIcon color={"#969696"} />
        </span>
      ),
      width: 60,
    },
  ];

  return (
    <TabPane tabId="3" className="history__table-container">
      <ReactTable
        data={requests}
        columns={columns}
        showPageSizeOptions={false}
        defaultPageSize={20}
        minRows={0}
        showPagination={requests && requests.length > 20 ? true : false}
        noDataText="No Agreements"
      />
    </TabPane>
  );
}

export default HistoryAgreements;
HistoryAgreements.propTypes = {
  historyAgreements: PropTypes.array,
  formatDate: PropTypes.func,
  date: PropTypes.object,
  day: PropTypes.string,
  wholeMonth: PropTypes.string,
  year: PropTypes.string,
  parseDate: PropTypes.func,
  generateReport: PropTypes.func,
  value: PropTypes.object,
};
