import React from "react";
import PropTypes from "prop-types";
import Image from "../../images/Users/Document.svg";
import MdArrow from "react-icons/lib/ti/arrow-right";

function RequestSent(props) {
  return (
    <div className="compliance-request-sent">
      <div>
        <img src={Image} alt="DPA" />
      </div>

      <p className="compliance-request-sent__title">Request sent!</p>

      <p className="compliance-request-sent__text">
        You have successfully asked for your user’s signature on your Data
        Processing Agreement.
      </p>

      <p className="compliance-request-sent__link" onClick={props.sendRequest}>
        Back to Data Processing Agreements <MdArrow size={20} />
      </p>
    </div>
  );
}

export default RequestSent;
RequestSent.propTypes = {
  sendRequest: PropTypes.func.isRequired,
};
