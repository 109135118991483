import React, { Component, Fragment } from "react";
import { Row, Col, Alert } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import MdClose from "react-icons/lib/md/close";
import ZoomIn from "react-icons/lib/md/add";
import ZoomOut from "react-icons/lib/md/remove";
import FontLoader from "../Settings/FontLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser from "react-html-parser";
import ReadOnly from "../../components/Icons/ReadOnly";
import Read from "../../components/Icons/Read";
import PropTypes from "prop-types";

class PageContent extends Component {
  static propTypes = {
    isPreview: PropTypes.bool,
    otherVersionId: PropTypes.string,
    isNotCurrentVersion: PropTypes.bool,
    activeItemIndex: PropTypes.number,
    contentPage: PropTypes.object,
    alerts: PropTypes.array,
    isAdmin: PropTypes.bool,
    pageTemplate: PropTypes.object,
    siteSubtitleFont: PropTypes.string,
    subtitleColor: PropTypes.string,
    legalsite: PropTypes.object,
    pages: PropTypes.array,
    subtitleSize: PropTypes.string,
    dontSellMyDataButton: PropTypes.object,
    updateTitle: PropTypes.func,
    updateText: PropTypes.func,
    makeSectionApplicable: PropTypes.func,
    shouldTitleReset: PropTypes.bool,
    shouldTextReset: PropTypes.bool,
    editSection: PropTypes.func,
    activePublicationId: PropTypes.string,
    showPreview: PropTypes.func,
    legalsiteSettings: PropTypes.object,
    btnType: PropTypes.string,
    footerLinkAlign: PropTypes.bool,
    footerLinkItalic: PropTypes.bool,
    footerLinkBold: PropTypes.bool,
  };

  state = {
    updatedTitle: null,
    updatedText: null,
    isChecked: true,
    subtitle: "Write subtitle",
    showTextarea: false,
    content: "Start writing a text here.",
    isZoomedIn: false,
    activeItem: this.props.activeItemIndex,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.shouldTitleReset !== this.props.shouldTitleReset) {
      this.setState({
        updatedTitle: null,
      });
    }

    if (prevProps.shouldTextReset !== this.props.shouldTextReset) {
      this.setState({
        updatedText: null,
      });
    }
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    if (prevState.activeItem !== prevProps.activeItemIndex) {
      return {
        updatedTitle: null,
        activeItem: prevProps.activeItemIndex,
        updatedText: null,
      };
    }
    return null;
  }

  updateTitle = (e) => {
    this.setState({
      updatedTitle: e.target.value,
    });

    this.props.updateTitle(e.target.value);
    // this.props.resetTitle(false);
  };

  // updateText = (e) => {
  //   this.setState({
  //     updatedText: e.target.value,
  //   });

  //   this.props.updateText(e.target.value);
  //   // this.props.resetText(false);
  // };

  updateText = (e) => {
    this.setState({
      updatedText: e,
    });

    this.props.updateText(e);
  };

  handleChange = (id) => {
    this.props.makeSectionApplicable(id);
  };

  render() {
    const {
      isPreview,
      otherVersionId,
      isNotCurrentVersion,
      activeItemIndex,
      contentPage,
      alerts,
      isAdmin,
      pageTemplate,
      siteSubtitleFont,
      subtitleColor,
      legalsite,
      pages,
      subtitleSize,
      dontSellMyDataButton,
    } = this.props;

    const { showTextarea } = this.state;
    const selectedVersion = !isAdmin
      ? this.props.contentPage.publications.filter(
          (item) => item.id === otherVersionId
        )
      : pageTemplate &&
        pageTemplate.templatePublications.filter(
          (item) => item.id === otherVersionId
        );
    const currentAlert = !isAdmin
      ? alerts.filter((item) =>
          item.category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
            ? item.page.id === contentPage.id
            : null
        )
      : 0;

    let activeItemId;

    if (currentAlert.length === 0 || isAdmin) {
      activeItemId =
        !isAdmin && !pageTemplate
          ? !isNotCurrentVersion
            ? contentPage && contentPage.sections.length
              ? contentPage.sections[activeItemIndex - 1].id
              : ""
            : selectedVersion && selectedVersion.length
            ? selectedVersion[0].sections[activeItemIndex - 1].id
            : ""
          : pageTemplate &&
            !isNotCurrentVersion &&
            pageTemplate.sections[activeItemIndex - 1]
          ? pageTemplate.sections[activeItemIndex - 1].id
          : isNotCurrentVersion && isAdmin
          ? selectedVersion && selectedVersion.length
            ? selectedVersion[0].sections[activeItemIndex - 1].id
            : ""
          : "";
    }

    const publicationContent =
      isNotCurrentVersion && !isAdmin
        ? this.props.contentPage.publications.filter(
            (item) => item.id === this.props.activePublicationId
          )
        : isNotCurrentVersion && isAdmin
        ? this.props.pageTemplate.templatePublications.filter(
            (item) => item.id === this.props.activePublicationId
          )
        : null;

    const content = !isAdmin
      ? !isNotCurrentVersion
        ? [...this.props.contentPage.sections]
            .sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
            .filter((item) => item.id === activeItemId)
        : publicationContent[0].sections.filter(
            (item) => item.id === activeItemId
          )
      : !isNotCurrentVersion
      ? pageTemplate &&
        pageTemplate.sections
          .sort((a, b) => parseFloat(a.position) - parseFloat(b.position))
          .filter((item) => item.id === activeItemId)
      : publicationContent[0].sections.filter(
          (item) => item.id === activeItemId
        );

    if (currentAlert.length > 0) {
      activeItemId =
        content.pageTemplate &&
        content.pageTemplate.sections[activeItemIndex - 1]
          ? content.pageTemplate.sections[activeItemIndex - 1].id
          : null;
    }

    return !isPreview ? (
      <div className="page-content">
        {isAdmin && content && content.length ? (
          <div
            className="page-content__edit"
            onClick={() =>
              this.props.editSection(
                this.state.updatedTitle !== null
                  ? this.state.updatedTitle
                  : content[0].title,
                this.state.updatedText
                  ? this.state.updatedText
                  : content[0].text,
                activeItemId,
                !content[0].readOnly
              )
            }
          >
            {!content[0].readOnly ? <Read /> : <ReadOnly />}
            &nbsp; {!content[0].readOnly ? "Editable" : "Read only"}
          </div>
        ) : null}
        {currentAlert && currentAlert.length > 0 ? (
          <Alert color="danger">
            First approve/discard changes in order to edit the page template
          </Alert>
        ) : null}
        <div className="d-flex align-items-center">
          <TextareaAutosize
            value={
              currentAlert.length > 0
                ? contentPage.pageTemplate.sections[activeItemIndex - 1]
                  ? contentPage.pageTemplate.sections[activeItemIndex - 1].title
                  : ""
                : this.state.updatedTitle !== null
                ? this.state.updatedTitle
                : content && content.length
                ? content[0].title
                : ""
            }
            minRows={1}
            placeholder="Write a title"
            onChange={this.updateTitle}
            onBlur={
              currentAlert.length === 0 || isAdmin
                ? () => {
                    this.props.editSection(
                      this.state.updatedTitle !== null
                        ? this.state.updatedTitle
                        : content[0].title,
                      this.state.updatedText
                        ? this.state.updatedText
                        : content[0].text,
                      activeItemId,
                      isAdmin ? content[0].readOnly : null
                    );
                  }
                : null
            }
            readOnly={isNotCurrentVersion}
            className="border-0 page-content__title"
          />
        </div>

        {(!isNotCurrentVersion || isAdmin) && currentAlert.length === 0 && (
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "bold",
                "italic",
                "undo",
                "redo",
                "link",
                "insertTable",
                "selectall",
                "blockQuote",
                "numberedList",
                "bulletedList",
              ],
            }}
            data={
              this.state.updatedText !== null
                ? this.state.updatedText
                : content[0].text || ""
            }
            onChange={(event, editor) => {
              const data = editor.getData();
              this.updateText(data);
            }}
            onBlur={() => {
              this.props.editSection(
                this.state.updatedTitle !== null
                  ? this.state.updatedTitle
                  : content[0].title,
                this.state.updatedText
                  ? this.state.updatedText
                  : content[0].text,
                activeItemId,
                isAdmin ? content[0].readOnly : null
              );
            }}
          />
        )}

        {(isNotCurrentVersion && !showTextarea && currentAlert.length === 0) ||
        (isNotCurrentVersion && isAdmin && !showTextarea) ? (
          <div
            className="page-content__diff"
            onClick={() =>
              isNotCurrentVersion ? {} : this.setState({ showTextarea: true })
            }
          >
            <del className="page-content__del">
              {!isAdmin
                ? this.props.contentPage.sections[activeItemIndex - 1]
                  ? ReactHtmlParser(
                      this.props.contentPage.sections[activeItemIndex - 1].text
                    )
                  : ""
                : pageTemplate.sections[activeItemIndex - 1]
                ? ReactHtmlParser(
                    pageTemplate.sections[activeItemIndex - 1].text
                  )
                : ""}
            </del>
            <ins className="page-content__ins">
              {ReactHtmlParser(content[0].text)}
            </ins>
          </div>
        ) : (
          !isNotCurrentVersion &&
          !showTextarea &&
          currentAlert.length > 0 && (
            <div className="page-content__diff">
              <del className="page-content__del">
                {!isAdmin
                  ? this.props.contentPage.sections[activeItemIndex - 1]
                    ? ReactHtmlParser(
                        this.props.contentPage.sections[activeItemIndex - 1]
                          .text
                      )
                    : ""
                  : pageTemplate.sections[activeItemIndex - 1]
                  ? ReactHtmlParser(
                      pageTemplate.sections[activeItemIndex - 1].text
                    )
                  : ""}
              </del>
              <ins className="page-content__ins">
                {contentPage.pageTemplate.sections[activeItemIndex - 1]
                  ? ReactHtmlParser(
                      contentPage.pageTemplate.sections[activeItemIndex - 1]
                        .text
                    )
                  : ""}
              </ins>
            </div>
          )
        )}

        {showTextarea && (
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: [
                "heading",
                "bold",
                "italic",
                "undo",
                "redo",
                "link",
                "insertTable",
                "selectall",
                "blockQuote",
                "numberedList",
                "bulletedList",
              ],
            }}
            data={
              this.state.updatedText !== null
                ? this.state.updatedText
                : content[0].text
            }
            onChange={(event, editor) => {
              const data = editor.getData();
              this.updateText(data);
            }}
            onBlur={() => {
              this.setState({
                showTextarea: false,
              });
              this.props.editSection(
                this.state.updatedTitle !== null
                  ? this.state.updatedTitle
                  : content[0].title,
                this.state.updatedText
                  ? this.state.updatedText
                  : content[0].text,
                activeItemId,
                isAdmin ? content[0].readOnly : null
              );
            }}
          />
        )}
      </div>
    ) : (
      <div className="d-flex flex-column flex-fill modal-preview__page-container">
        <FontLoader
          font1={
            legalsite && legalsite.headerTitleFont
              ? legalsite.headerTitleFont
              : "Roboto"
          }
          font2={
            legalsite && legalsite.navigationFont
              ? legalsite.navigationFont
              : "Roboto"
          }
          font3={
            legalsite && legalsite.headerBodyFont
              ? legalsite.headerBodyFont
              : "Roboto"
          }
          font4={
            legalsite && legalsite.bodySubtitleFont
              ? legalsite.bodySubtitleFont
              : "Roboto"
          }
        />

        <FontLoader
          font1={
            legalsite && legalsite.footerLinksFont
              ? legalsite.footerLinksFont
              : "Roboto"
          }
          font2={
            legalsite && legalsite.footerTextFont
              ? legalsite.footerTextFont
              : "Roboto"
          }
          font3={
            legalsite && legalsite.headerBodyFont
              ? legalsite.headerBodyFont
              : "Roboto"
          }
          font4={
            legalsite && legalsite.bodySubtitleFont
              ? legalsite.bodySubtitleFont
              : "Roboto"
          }
        />

        <FontLoader
          font1={
            legalsite && legalsite.footerLinksFont
              ? legalsite.footerLinksFont
              : "Roboto"
          }
          font2={
            legalsite && legalsite.footerTextFont
              ? legalsite.footerTextFont
              : "Roboto"
          }
          font3={
            legalsite && legalsite.headerBodyFont
              ? legalsite.headerBodyFont
              : "Roboto"
          }
          font4={
            dontSellMyDataButton && dontSellMyDataButton.textFont
              ? dontSellMyDataButton.textFont
              : "Roboto"
          }
        />

        <div className="modal-preview__header">
          <h3 className="modal-preview__title">Preview Mode</h3>
          <div
            className="modal-preview__close-btn"
            onClick={this.props.showPreview}
          >
            <span className="modal-preview__exit mr-2">Exit</span>
            <MdClose className="modal-assign__close" color={"#fff"} size={25} />
          </div>
        </div>

        <div
          className={`${
            this.state.isZoomedIn ? "-has-zoom" : ""
          } page-preview container`}
        >
          <div
            className="page-preview__nav"
            style={{
              backgroundColor: legalsite ? legalsite.navigationBg : null,
            }}
          >
            <div className="d-flex align-items-center">
              {this.props.legalsiteSettings &&
              this.props.legalsiteSettings.companyLogo ? (
                <img
                  src={this.props.legalsiteSettings.companyLogo}
                  alt="logo"
                />
              ) : (
                <Fragment>
                  <div className="page-preview__nav-logo" />
                  <p className="page-preview__nav-logo-text mb-0">Your Logo</p>
                </Fragment>
              )}
            </div>
            <p
              className="page-preview__nav-get"
              style={{
                borderColor: legalsite ? legalsite.navigationLinksColor : null,
                color: legalsite ? legalsite.navigationLinksColor : null,
                fontSize: legalsite ? legalsite.navigationSize : null,
                fontFamily: legalsite ? legalsite.navigationFont : null,
                textAlign: legalsite ? legalsite.navigationLinksAlign : null,
                fontWeight:
                  legalsite &&
                  legalsite.navigationLinksBold &&
                  legalsite.navigationLinksBold !== null
                    ? legalsite.navigationLinksBold === true
                      ? 900
                      : 300
                    : null,
                fontStyle:
                  legalsite &&
                  legalsite.navigationLinksItalic &&
                  legalsite.navigationLinksItalic !== null
                    ? legalsite.navigationLinksItalic === true
                      ? "italic"
                      : "normal"
                    : "normal",
              }}
            >
              Submit a request
            </p>
          </div>
          <div
            className="page-preview__header"
            style={{
              background:
                legalsite && legalsite.headerBg
                  ? legalsite.headerBg
                  : "#dfe4ee",
            }}
          >
            <h5
              className="page-preview__header-title"
              style={{
                color:
                  legalsite && legalsite.headerTitleColor
                    ? legalsite.headerTitleColor
                    : "#333",
                fontSize:
                  legalsite && legalsite.headerTitleSize
                    ? legalsite.headerTitleSize
                    : 56,
                fontFamily:
                  legalsite && legalsite.headerTitleFont
                    ? legalsite.headerTitleFont
                    : null,
                textAlign:
                  legalsite && legalsite.headerTitleAlign !== null
                    ? legalsite.headerTitleAlign
                    : null,
                fontWeight:
                  legalsite && legalsite.headerTitleBold !== null
                    ? legalsite.headerTitleBold === true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  legalsite && legalsite.headerTitleItalic !== null
                    ? legalsite.headerTitleItalic === true
                      ? "italic"
                      : "normal"
                    : "normal",
              }}
            >
              {isAdmin ? pageTemplate.title : contentPage.title}
            </h5>
            <p
              className="page-preview__header-text"
              style={{
                color:
                  legalsite && legalsite.headerBodyColor
                    ? legalsite.headerBodyColor
                    : "#969696",
                fontSize:
                  legalsite && legalsite.headerBodySize
                    ? legalsite.headerBodySize
                    : 16,
                fontFamily:
                  legalsite && legalsite.headerBodyFont
                    ? legalsite.headerBodyFont
                    : null,
                textAlign:
                  legalsite && legalsite.headerBodyAlign !== null
                    ? legalsite.headerBodyAlign
                    : null,
                fontWeight:
                  legalsite && legalsite.headerBodyBold !== null
                    ? legalsite.headerBodyBold === true
                      ? 900
                      : 500
                    : 500,
                fontStyle:
                  legalsite && legalsite.headerBodyItalic !== null
                    ? legalsite.headerBodyItalic === true
                      ? "italic"
                      : "normal"
                    : "normal",
              }}
            >
              {isAdmin ? pageTemplate.description : contentPage.description}
            </p>
          </div>
          <div
            className="page-preview__content -inherit-height"
            style={{
              background:
                legalsite && legalsite.bodyBg ? legalsite.bodyBg : "#f4f6fc",
            }}
          >
            <div className="page-preview__content-menu">
              {!isAdmin
                ? contentPage.sections.map((item, index) =>
                    item.applicable ? (
                      <p
                        key={index}
                        className="page-preview__content-menu-item"
                        style={{
                          color:
                            legalsite && legalsite.bodySubtitleColor
                              ? legalsite.bodySubtitleColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.bodySubtitleSize
                              ? legalsite.bodySubtitleSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.bodySubtitleFont
                              ? legalsite.bodySubtitleFont
                              : "Roboto",
                          fontWeight:
                            legalsite && legalsite.bodySubtitleBold !== null
                              ? legalsite.bodySubtitleBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.bodySubtitleItalic !== null
                              ? legalsite.bodySubtitleItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        {item.title}
                      </p>
                    ) : null
                  )
                : pageTemplate.sections.map((item, index) =>
                    item.applicable ? (
                      <p
                        key={index}
                        className="page-preview__content-menu-item"
                        style={{
                          color:
                            legalsite && legalsite.bodySubtitleColor
                              ? legalsite.bodySubtitleColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.bodySubtitleSize
                              ? legalsite.bodySubtitleSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.bodySubtitleFont
                              ? legalsite.bodySubtitleFont
                              : "Roboto",
                          textAlign:
                            legalsite && legalsite.bodySubtitleAlign !== null
                              ? legalsite.bodySubtitleAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.bodySubtitleBold !== null
                              ? legalsite.bodySubtitleBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.bodySubtitleItalic !== null
                              ? legalsite.bodySubtitleItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        {item.title}
                      </p>
                    ) : null
                  )}
            </div>
            <div className="page-preview__content-container">
              {!isAdmin
                ? contentPage.sections.map((item, index) =>
                    item.applicable ? (
                      <div key={index}>
                        <p
                          className="page-preview__content-title"
                          style={{
                            color:
                              legalsite && legalsite.bodySubtitleColor
                                ? legalsite.bodySubtitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.bodySubtitleSize
                                ? legalsite.bodySubtitleSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodySubtitleFont
                                ? legalsite.bodySubtitleFont
                                : "Roboto",
                            fontWeight:
                              legalsite && legalsite.bodySubtitleBold !== null
                                ? legalsite.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodySubtitleItalic !== null
                                ? legalsite.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {item.title}
                        </p>
                        <div
                          className="page-preview__content-text"
                          style={{
                            color:
                              legalsite && legalsite.bodyTextColor
                                ? legalsite.bodyTextColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.bodyTextSize
                                ? legalsite.bodyTextSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodyTextFont
                                ? legalsite.bodyTextFont
                                : null,
                          }}
                        >
                          {ReactHtmlParser(item.text)}
                        </div>
                      </div>
                    ) : null
                  )
                : pageTemplate.sections.map((item, index) =>
                    item.applicable ? (
                      <div key={index}>
                        <p
                          className="page-preview__content-title"
                          style={{
                            color: subtitleColor ? subtitleColor : null,
                            fontSize: subtitleSize ? subtitleSize : null,
                            fontFamily: siteSubtitleFont
                              ? siteSubtitleFont
                              : "Roboto",
                          }}
                        >
                          {item.title}
                        </p>
                        <p
                          className="page-preview__content-text"
                          style={{
                            color:
                              legalsite && legalsite.cardsBodyColor
                                ? legalsite.cardsBodyColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.cardsBodySize
                                ? legalsite.cardsBodySize
                                : null,
                            fontFamily:
                              legalsite && legalsite.cardsBodyFont
                                ? legalsite.cardsBodyFont
                                : null,
                            textAlign:
                              legalsite && legalsite.cardsBodyAlign !== null
                                ? legalsite.cardsBodyAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.cardsBodyBold !== null
                                ? legalsite.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.cardsBodyItalic !== null
                                ? legalsite.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {ReactHtmlParser(item.text)}
                        </p>
                      </div>
                    ) : null
                  )}
            </div>
          </div>
          <div
            className="footer"
            style={{
              background:
                legalsite && legalsite.footerBg
                  ? legalsite.footerBg
                  : "#dfe4ee",
            }}
          >
            <div
              className="footer__container"
              style={{
                background:
                  legalsite && legalsite.footerBg
                    ? legalsite.footerBg
                    : "#dfe4ee",
              }}
            >
              <Row className="w-100">
                <Col sm={12} md={3} className="d-flex flex-column">
                  <span className="d-flex align-items-center text-decoration-none">
                    {this.props.legalsiteSettings &&
                    this.props.legalsiteSettings.companyLogo &&
                    legalsite &&
                    !legalsite.footerLogo ? (
                      <div className="footer__logo-container">
                        <img
                          src={this.props.legalsiteSettings.companyLogo}
                          alt="logo"
                        />
                      </div>
                    ) : legalsite && legalsite.footerLogo ? (
                      <div className="footer__logo-container">
                        <img src={legalsite.footerLogo} alt="logo" />
                      </div>
                    ) : (
                      <span className="footer__logo" />
                    )}
                  </span>
                </Col>

                <Col sm={12} md={3} className="d-flex flex-column">
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerTextColor
                          ? legalsite.footerTextColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerTextSize
                          ? legalsite.footerTextSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerTextFont
                          ? legalsite.footerTextFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerTextAlign !== null
                          ? legalsite.footerTextAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerTextBold !== null
                          ? legalsite.footerTextBold === true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        legalsite && legalsite.footerTextItalic !== null
                          ? legalsite.footerTextItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    <strong>Website</strong>
                  </p>
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerLinksColor
                          ? legalsite.footerLinksColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerLinksSize
                          ? legalsite.footerLinksSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerLinksFont
                          ? legalsite.footerLinksFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Homepage
                  </p>
                  <p
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.legalsite.co/"
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerLinksColor
                          ? legalsite.footerLinksColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerLinksSize
                          ? legalsite.footerLinksSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerLinksFont
                          ? legalsite.footerLinksFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Go back to main website
                  </p>
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerLinksColor
                          ? legalsite.footerLinksColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerLinksSize
                          ? legalsite.footerLinksSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerLinksFont
                          ? legalsite.footerLinksFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Legal Information
                  </p>
                </Col>

                <Col sm={12} md={3} className="d-flex flex-column">
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerTextColor
                          ? legalsite.footerTextColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerTextSize
                          ? legalsite.footerTextSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerTextFont
                          ? legalsite.footerTextFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerTextAlign !== null
                          ? legalsite.footerTextAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerTextBold !== null
                          ? legalsite.footerTextBold === true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        legalsite && legalsite.footerTextItalic !== null
                          ? legalsite.footerTextItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    <strong>Legal Pages</strong>
                  </p>
                  {pages
                    ? pages.map((item) => (
                        <p
                          className="mb-4"
                          style={{
                            color:
                              legalsite && legalsite.footerLinksColor
                                ? legalsite.footerLinksColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.footerLinksSize
                                ? legalsite.footerLinksSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.footerLinksFont
                                ? legalsite.footerLinksFont
                                : null,
                            textAlign:
                              legalsite && legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                          key={item.id}
                        >
                          {item.title}
                        </p>
                      ))
                    : null}
                </Col>

                <Col sm={12} md={3} className="d-flex flex-column">
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerTextColor
                          ? legalsite.footerTextColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerTextSize
                          ? legalsite.footerTextSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerTextFont
                          ? legalsite.footerTextFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerTextAlign !== null
                          ? legalsite.footerTextAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerTextBold !== null
                          ? legalsite.footerTextBold === true
                            ? 900
                            : 500
                          : 500,

                      fontStyle:
                        legalsite && legalsite.footerTextItalic !== null
                          ? legalsite.footerTextItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    <strong>Manage your information</strong>
                  </p>
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerLinksColor
                          ? legalsite.footerLinksColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerLinksSize
                          ? legalsite.footerLinksSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerLinksFont
                          ? legalsite.footerLinksFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Manage Consent
                  </p>
                  <p
                    className="mb-4"
                    style={{
                      color:
                        legalsite && legalsite.footerLinksColor
                          ? legalsite.footerLinksColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.footerLinksSize
                          ? legalsite.footerLinksSize
                          : null,
                      fontFamily:
                        legalsite && legalsite.footerLinksFont
                          ? legalsite.footerLinksFont
                          : null,
                      textAlign:
                        legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.footerLinksBold !== null
                          ? legalsite.footerLinksBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.footerLinksItalic !== null
                          ? legalsite.footerLinksItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    Send request
                  </p>

                  <div
                    className={`d-flex ${
                      legalsite && legalsite.footerLinksAlign !== null
                        ? legalsite.footerLinksAlign === "center"
                          ? "justify-content-center"
                          : legalsite.footerLinksAlign === "left"
                          ? "justify-content-start"
                          : "justify-content-end"
                        : null
                    }`}
                  >
                    <p
                      className={`${
                        (dontSellMyDataButton &&
                          dontSellMyDataButton.buttonType === "contained") ||
                        (dontSellMyDataButton &&
                          !dontSellMyDataButton.buttonType)
                          ? "-is-contained"
                          : ""
                      }`}
                      style={{
                        background:
                          this.props.btnType === "contained" ||
                          (dontSellMyDataButton &&
                            dontSellMyDataButton.buttonType === "contained") ||
                          (dontSellMyDataButton &&
                            !dontSellMyDataButton.buttonType)
                            ? dontSellMyDataButton && dontSellMyDataButton.color
                              ? dontSellMyDataButton.color
                              : "#7450c8"
                            : null,
                        color:
                          dontSellMyDataButton && dontSellMyDataButton.textColor
                            ? dontSellMyDataButton.textColor
                            : "#fff",
                        fontSize:
                          dontSellMyDataButton && dontSellMyDataButton.textSize
                            ? parseInt(dontSellMyDataButton.textSize)
                            : 14,
                        fontFamily:
                          dontSellMyDataButton && dontSellMyDataButton.textFont
                            ? dontSellMyDataButton.textFont
                            : "Roboto",
                        textAlign: this.props.footerLinkAlign
                          ? this.props.footerLinkAlign
                          : legalsite && legalsite.footerLinksAlign !== null
                          ? legalsite.footerLinksAlign
                          : null,
                        fontWeight:
                          this.props.footerLinkBold !== null
                            ? this.props.footerLinkAlign === true
                              ? 900
                              : 300
                            : legalsite && legalsite.footerLinksBold !== null
                            ? legalsite.footerLinksBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          this.props.footerLinkItalic !== null
                            ? this.props.footerLinkItalic === true
                              ? "italic"
                              : "normal"
                            : legalsite && legalsite.footerLinksItalic !== null
                            ? legalsite.footerLinksItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      Do Not Sell My Info
                    </p>
                  </div>
                </Col>
              </Row>
            </div>

            <div
              className="footer-text"
              style={{
                background:
                  legalsite && legalsite.footerBg
                    ? legalsite.footerBg
                    : "#dfe4ee",
              }}
            >
              <span>
                <span
                  style={{
                    color:
                      legalsite && legalsite.footerTextColor
                        ? legalsite.footerTextColor
                        : "#333",
                    fontSize:
                      legalsite && legalsite.footerTextSize
                        ? legalsite.footerTextSize
                        : null,
                    fontFamily:
                      legalsite && legalsite.footerTextFont
                        ? legalsite.footerTextFont
                        : null,
                    textAlign:
                      legalsite && legalsite.footerTextAlign !== null
                        ? legalsite.footerTextAlign
                        : null,
                    fontWeight:
                      legalsite && legalsite.footerTextBold !== null
                        ? legalsite.footerTextBold === true
                          ? 900
                          : 500
                        : 500,

                    fontStyle:
                      legalsite && legalsite.footerTextItalic !== null
                        ? legalsite.footerTextItalic === true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  © {new Date().getFullYear()} LegalSite BV. All rights
                  reserved.
                </span>
                <u
                  style={{
                    color:
                      legalsite && legalsite.footerLinksColor
                        ? legalsite.footerLinksColor
                        : "#333",
                    fontSize:
                      legalsite && legalsite.footerLinksSize
                        ? legalsite.footerLinksSize
                        : null,
                    fontFamily:
                      legalsite && legalsite.footerLinksFont
                        ? legalsite.footerLinksFont
                        : null,
                    textAlign:
                      legalsite && legalsite.footerLinksAlign !== null
                        ? legalsite.footerLinksAlign
                        : null,
                    fontWeight:
                      legalsite && legalsite.footerLinksBold !== null
                        ? legalsite.footerLinksBold === true
                          ? 900
                          : 500
                        : 500,
                    fontStyle:
                      legalsite && legalsite.footerLinksItalic !== null
                        ? legalsite.footerLinksItalic === true
                          ? "italic"
                          : "normal"
                        : "normal",
                  }}
                >
                  Get your LegalSite!
                </u>
              </span>
            </div>
          </div>
        </div>

        <div className="page-preview__zoom">
          <span onClick={() => this.setState({ isZoomedIn: true })}>
            <ZoomIn size={20} />
          </span>
          <span onClick={() => this.setState({ isZoomedIn: false })}>
            <ZoomOut size={20} />
          </span>
        </div>
      </div>
    );
  }
}

export default PageContent;
