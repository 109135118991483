import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Alert,
} from "reactstrap";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { REMOVE_DATA_ENTITIES } from "../../graphql/removeDataEntities";
import { COMPLIANCE_DP_QUERY } from "../../graphql/complianceDPQuery";
import UploadDpa from "./UploadDpa";
import NoData from "./NoData";
import RequestSent from "./RequestSent";
import RequestSignature from "../Users/RequestSignature";
import DPAUsers from "./DPAUsers";
import DPADP from "./DPADP";
import MdArrowLeft from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class ComplianceDataProcessing extends Component {
  static propTypes = {
    data: PropTypes.object,
    getInitialPage: PropTypes.func,
  };

  state = {
    activeTab: "1",
    isUploadDpaVisible: false,
    isRequestSignature: false,
    isSendRequest: false,
    arr: [],
    userType: [],
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  showRequestSignature = (arr, userType) => {
    if (this.state.isRequestSignature) {
      this.setState({
        isCheckedAll: false,
        arr: [],
        urls: [],
        userName: [],
        userType: [],
        user: null,
      });
    }
    this.setState({
      isRequestSignature: !this.state.isRequestSignature,
      arr,
      userType,
    });
  };

  uploadDpa = () => {
    this.setState({
      isUploadDpaVisible: !this.state.isUploadDpaVisible,
    });
  };

  sendRequest = () => {
    this.setState({
      isSendRequest: !this.state.isSendRequest,
      isRequestSignature: false,
    });
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  redirectToDP = () => {
    window.location.href = "/data-processors";
  };

  render() {
    const { isUploadDpaVisible, isRequestSignature, isSendRequest } =
      this.state;
    const { loading, error } = this.props.data;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="compliance-data container -has-overflow">
        {!isUploadDpaVisible && !isRequestSignature && !isSendRequest ? (
          <Fragment>
            <Row className="d-flex justify-content-start">
              <Col md={6} lg={9}>
                <p
                  onClick={this.props.getInitialPage}
                  className="compliance-breach__back"
                >
                  <MdArrowLeft
                    size={25}
                    className="customize-menu__back position-relative"
                    color="#7450c8"
                  />
                  &nbsp;Back to Compliance
                </p>
                <h5 className="compliance-data__title">
                  Data Processing Agreements
                </h5>
                <p className="compliance-data__text">
                  Manage the DPA’s you receive from Data Processors and the DPA
                  available on your LegalSite. You can request a company to sign
                  your document, upload a DPA or request a Data Processor to
                  send their document.
                </p>
                <p className="compliance-data__text">
                  A Data Processing Agreement is a written contract required by
                  the GDPR when one business processes personal data on behalf
                  of another business. It’s a way to give the controller an
                  assurance that their data processors also protect the data and
                  act in a GDPR compliant manner.
                </p>
              </Col>
            </Row>

            {(this.props.data.currentDataProcessors &&
              this.props.data.currentDataProcessors.length) ||
            (this.props.data.dataEntities &&
              this.props.data.dataEntities.dataEntities &&
              this.props.data.dataEntities.dataEntities.length) ? (
              <div className="compliance-data__content mb-5">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "1" ? "active" : ""
                      } -users`}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      Users
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${
                        this.state.activeTab === "2" ? "active" : ""
                      }`}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Data Processors
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {this.props.data.dataEntities &&
                  this.props.data.dataEntities.dataEntities ? (
                    <TabPane tabId="1">
                      {this.props.data.dataEntities &&
                      this.props.data.dataEntities.dataEntities &&
                      this.props.data.dataEntities.dataEntities.length ? (
                        <DPAUsers
                          showRequestSignature={this.showRequestSignature}
                          sendRequest={this.sendRequest}
                        />
                      ) : (
                        <p className="text-center my-3 pb-3">No users yet</p>
                      )}
                    </TabPane>
                  ) : null}

                  {this.props.data.currentDataProcessors ? (
                    <TabPane tabId="2">
                      <DPADP
                        showRequestSignature={this.showRequestSignature}
                        showUpload={this.uploadDpa}
                      />
                    </TabPane>
                  ) : null}
                </TabContent>
              </div>
            ) : (
              <NoData redirectToDP={this.redirectToDP} />
            )}
          </Fragment>
        ) : isRequestSignature ? (
          <RequestSignature
            isDP={this.state.userType.includes("Integration")}
            showRequestSignature={this.showRequestSignature}
            selectedUsers={this.state.arr.map((item, index) => {
              return {
                id: item,
                userType:
                  this.state.userType[index] === "DataSubject"
                    ? "DATA_SUBJECT"
                    : this.state.userType[index] === "DataController"
                    ? "DATA_CONTROLLER"
                    : "DATA_PROCESSOR",
              };
            })}
          />
        ) : isSendRequest ? (
          <RequestSent sendRequest={this.sendRequest} />
        ) : (
          <UploadDpa
            currentDataProcessors={this.props.data.currentDataProcessors}
            showUpload={this.uploadDpa}
            refetchData={this.refetchData}
          />
        )}
      </div>
    );
  }
}

export default compose(
  graphql(COMPLIANCE_DP_QUERY, {
    options: () => ({
      variables: {
        filter: "ALL",
        pageNumber: 1,
        name: "",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(REMOVE_DATA_ENTITIES, {
    name: "removeDataEntities",
  })
)(ComplianceDataProcessing);
