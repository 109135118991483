import React, { Component } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";
import FaCaret from "react-icons/lib/fa/angle-down";
import { Link } from "react-router-dom";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import ViewIcon from "../../components/Icons/ViewIcon";
import { REMOVE_USER } from "../../graphql/removeUser";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import axios from "axios";
import { getCountries } from "../../api/CountriesApi";
import { ToastContainer, toast } from "react-toastify";
import ReactFilestack from "filestack-react";
import Moment from "react-moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Form,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import TrashIcon from "../../components/Icons/TrashIcon";
import DeleteUserModal from "../../components/Modals/DeleteUserModal";

class UserView extends Component {
  static propTypes = {
    showUser: PropTypes.func.isRequired,
    isUsers: PropTypes.bool,
    user: PropTypes.object.isRequired,
    isFromClient: PropTypes.bool,
    editUser: PropTypes.func.isRequired,
    refetchUsers: PropTypes.func.isRequired,
    removeUser: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
  };

  state = {
    name: this.props.user.name,
    email: this.props.user.email,
    phone: this.props.user.phoneNumber,
    job: this.props.user.jobTitle,
    role: this.props.user.role,
    companyName: this.props.user.account.companyName,
    plan: this.props.user.account.access,
    files: [],
    formErrors: {},
    countries: [],
    country: "",
    isDeleteModalVisible: false,
    deleteAccountId: null,
  };

  componentDidMount() {
    this.showData();
  }

  notify = (text) => toast(text);

  showData = async () => {
    try {
      const countries = await getCountries();

      this.setState({
        countries,
      });
    } catch (err) {
      console.log(err);
    }
  };

  openDeleteModal = () => {
    this.setState({
      isDeleteModalVisible: !this.state.isDeleteModalVisible,
    });
  };

  generateReport = (id, name) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: "USER",
        },
      })
      .then(({ data }) => {
        this.download(data.generateReport.downloadUrl, name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  removeUser = () => {
    this.props
      .removeUser({
        variables: {
          ids: this.state.deleteAccountId,
        },
      })
      .then(() => {
        this.props.refetchUsers();
      })
      .then(() => {
        this.props.isFromClient
          ? this.redirectToClients()
          : this.props.showUser();
      })
      .catch((error) => {
        console.log(error);
        this.notify("User was not removed");
      });

    this.setState({
      isDeleteModalVisible: false,
    });
  };

  setUserRole = (role) => {
    this.setState({
      role,
    });
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      email = formData.get("email"),
      tel = formData.get("tel"),
      job = formData.get("job"),
      company = formData.get("company"),
      country = formData.get("country"),
      avatar = this.state.files.length
        ? this.state.files[0].url
          ? this.state.files[0].url
          : null
        : this.props.user.avatar;

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!job) {
      hasFormErrors = true;
      formErrors.job = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props.editUser(
      name,
      email,
      job,
      this.state.role,
      avatar,
      tel,
      this.props.user.id,
      this.props.user.account.id,
      company,
      this.props.user.account.email,
      country
    );
  };

  selectCountry = (e) => {
    this.setState({
      country: e.target.value,
    });
  };

  redirectToClients = () => {
    window.location.href = "/clients";
  };

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${this.props.user.name ? this.props.user.name : "report"}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      this.notify(this.showDownloadNotifyText());
    });
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  render() {
    const { user, isFromClient } = this.props;
    const { files, country, countries } = this.state;

    return (
      <div className="user-view container">
        <div
          className="user-view__close"
          onClick={isFromClient ? this.redirectToClients : this.props.showUser}
        >
          Exit &nbsp;&nbsp;
          <MdClose size={20} />
        </div>

        <h5 className="user-view__title">User: {user.name}</h5>

        <div className="user-view__container">
          <div className="d-flex justify-content-between flex-wrap">
            <p className="user-view__info">User Info</p>
            <div className="d-flex">
              <p
                className="text-right user-view__download mb-3"
                onClick={() => this.generateReport(user.id, user.name)}
              >
                <DownloadIcon className="mr-2" color="#7450c8" /> Download
              </p>
              <p
                className="text-right user-view__delete"
                onClick={() => {
                  this.openDeleteModal();
                  this.setState({ deleteAccountId: user.id });
                }}
              >
                <TrashIcon className="mr-2" color="#7450c8" /> Delete
              </p>
            </div>
          </div>

          <Form onSubmit={this.handleSubmit} className="d-flex flex-wrap">
            <div className="d-flex user-view__details">
              <div className="position-relative">
                {files.length ? (
                  files.map((file, i) => (
                    <div key={i} className="user-view__avatar">
                      <img src={file.url} alt="avatar" />
                    </div>
                  ))
                ) : user.avatar ? (
                  <img
                    className="user-view__avatar"
                    src={user.avatar}
                    alt="avatar"
                  />
                ) : (
                  <span className="user-view__avatar d-flex align-items-center justify-content-center">
                    {user.name ? user.name.charAt(0).toUpperCase() : ""}
                  </span>
                )}

                <ReactFilestack
                  apikey={"AJNM9qOpGRljTn17sgxrfz"}
                  componentDisplayMode={{
                    type: "link",
                    customText: <span />,
                    customClass: "user-view__show-upload",
                  }}
                  onSuccess={this.onFiles}
                />
              </div>

              <div className="user-view__form form">
                <FormGroup className="position-relative mb-4">
                  <Input
                    type="text"
                    placeholder="Name"
                    className="mb-0"
                    name="name"
                    invalid={this.state.formErrors.name}
                    defaultValue={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                  <FormFeedback>Please provide user name</FormFeedback>
                </FormGroup>

                <FormGroup className="position-relative mb-4">
                  <Input
                    type="text"
                    placeholder="Email"
                    className="mb-0"
                    name="email"
                    invalid={this.state.formErrors.email}
                    defaultValue={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                  <FormFeedback>Please provide user email</FormFeedback>
                </FormGroup>

                <Input
                  type="tel"
                  placeholder="Phone"
                  name="tel"
                  defaultValue={this.state.phone}
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />

                <FormGroup className="position-relative mb-4">
                  <Input
                    type="text"
                    name="job"
                    className="mb-0"
                    invalid={this.state.formErrors.job}
                    placeholder="Job title"
                    defaultValue={this.state.job}
                    onChange={(e) => this.setState({ job: e.target.value })}
                  />
                  <FormFeedback>Please provide user job title</FormFeedback>
                </FormGroup>

                <div className="d-flex justify-content-between">
                  <div>
                    <p className="user-view__label">Access</p>
                    <UncontrolledDropdown className="chart__dropdown">
                      <DropdownToggle className="w-100 d-flex align-items-center chart__toggle pl-0 pt-0">
                        {this.state.role === "ADMIN"
                          ? "Admin"
                          : this.state.role === "MANAGER"
                          ? "Manager"
                          : "Data Manager"}{" "}
                        <FaCaret
                          className="chart__caret"
                          size="20"
                          color="#969696"
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {this.state.role !== "ADMIN" ? (
                          <DropdownItem
                            onClick={() => this.setUserRole("ADMIN")}
                          >
                            Admin
                          </DropdownItem>
                        ) : null}

                        {this.state.role !== "MANAGER" ? (
                          <DropdownItem
                            onClick={() => this.setUserRole("MANAGER")}
                          >
                            Manager
                          </DropdownItem>
                        ) : null}

                        {this.state.role !== "DATA_MANAGER" ? (
                          <DropdownItem
                            onClick={() => this.setUserRole("DATA_MANAGER")}
                          >
                            Data Manager
                          </DropdownItem>
                        ) : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex user-view__details">
              {user.account.companyLogo ? (
                <img
                  className="user-view__avatar"
                  src={user.account.companyLogo}
                  alt="avatar"
                />
              ) : (
                <span className="user-view__avatar d-flex align-items-center justify-content-center">
                  {user.account.companyName
                    ? user.account.companyName.charAt(0).toUpperCase()
                    : ""}
                </span>
              )}

              <div className="form">
                <Input
                  type="text"
                  placeholder="Company name"
                  defaultValue={this.state.companyName}
                  onChange={(e) =>
                    this.setState({ companyName: e.target.value })
                  }
                  name="company"
                />
                <Input
                  type="text"
                  placeholder="Users"
                  readOnly={true}
                  defaultValue={`${this.props.user.account.teamSize} ${
                    this.props.user.account.teamSize === 1 ? " user" : "users"
                  }`}
                  name="users"
                />

                <div className="d-flex">
                  <FormGroup className="position-relative mb-4">
                    <Input
                      type="select"
                      name="country"
                      className="mb-0 w-100"
                      onChange={this.selectCountry}
                      invalid={this.state.formErrors.country}
                      value={
                        country
                          ? country
                          : this.props.user.account.country
                          ? this.props.user.account.country
                          : "Country"
                      }
                    >
                      <option value="Country" disabled>
                        {this.props.user.account.country
                          ? this.props.user.account.country
                          : "Country"}
                      </option>
                      {countries.map((item, index) => (
                        <option value={item.name} key={index}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                    <FormFeedback>Please provide your country</FormFeedback>
                    <FaCaret className="position-absolute settings__input-icon" />
                  </FormGroup>
                </div>

                <div>
                  {!isFromClient ? (
                    <Link
                      className="user-view__view"
                      to={{
                        pathname: "/clients",
                        state: {
                          isClientView: true,
                          client: this.props.user.account,
                        },
                      }}
                    >
                      <ViewIcon className="mr-2" color="#7450c8" />
                      View Company profile
                    </Link>
                  ) : null}
                  <p className="user-view__added">Added</p>
                  <p className="user-view__date">
                    <Moment format="DD/MM/YYYY">
                      {this.props.user.dateAdded}
                    </Moment>
                  </p>
                </div>
              </div>
            </div>
            <div className="user-view__btn">
              <Button type="submit" className="btn--primary">
                Save and close
              </Button>
            </div>
          </Form>
        </div>

        <DeleteUserModal
          isDeleteModalVisible={this.state.isDeleteModalVisible}
          openDeleteModal={this.openDeleteModal}
          removeUser={this.removeUser}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(REMOVE_USER, {
    name: "removeUser",
  }),
  graphql(GENERATE_REPORT, {
    name: "generateReport",
  })
)(UserView);
