import gql from "graphql-tag";

export const SEND_NOTIFICATIONS = gql`
  mutation($taskIds: [ID!]!, $clientMutationId: String) {
    sendNotificationsdontselldata(
      input: { taskIds: $taskIds, clientMutationId: $clientMutationId }
    ) {
      errors
      request {
        id
        tasks {
          id
          status
        }
      }
    }
  }
`;
