import gql from "graphql-tag";

export const TOGGLE_SECTION = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleSection(input: { id: $id, clientMutationId: $clientMutationId }) {
      errors
      section {
        id
        applicable
      }
    }
  }
`;
