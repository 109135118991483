import gql from "graphql-tag";

export const CLIENTS_QUERY = gql`
  query($clientsFilter: ClientsFilterEnum!, $name: String!) {
    searchClient(name: $name) {
      id
      companyRegistration
      access
      email
      createdAt
      teamSize
      companyName
      phoneNumber
      plan
      fullName
      country
      downloadUrl
      companyLogo
      users {
        id
        avatar
        name
        email
        phoneNumber
        jobTitle
        role
        account {
          companyName
          companyLogo
          country
          teamSize
          access
          downloadUrl
          email
          id
        }
      }
    }
    allClients(filter: $clientsFilter) {
      id
      companyRegistration
      access
      email
      createdAt
      teamSize
      companyName
      phoneNumber
      plan
      fullName
      country
      downloadUrl
      companyLogo
      users {
        id
        avatar
        name
        email
        phoneNumber
        jobTitle
        role
        account {
          companyName
          companyLogo
          country
          teamSize
          access
          downloadUrl
          email
          id
        }
      }
    }
  }
`;
