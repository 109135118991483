import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import { Alert } from "reactstrap";
import SyncLoader from "react-spinners/SyncLoader";
import NoRequests from "./NoRequests";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { INBOX_QUERY } from "../../graphql/inbox";
import axios from "axios";
import SideMenu from "../../components/SideMenu/SideMenu";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import Moment from "react-moment";
import { UPLOAD_FILE } from "../../graphql/uploadFile";
import "./inbox.scss";
import InboxMenu from "./InboxMenu";
import SuggestedAction from "./SuggestedAction";
import SuggestedActionModal from "../../components/Modals/SuggestedActionModal";
import Request from "./Request";
import { ToastContainer, toast } from "react-toastify";
import InboxAction from "./InboxAction";
import { REMOVE_INTERACTION } from "../../graphql/removeInteraction";
import { REMOVE_FILE_OF_REQUEST } from "../../graphql/removeFileOfRequest";
import { getMobileDevice } from "../../utils/helpers";
import CreateRequest from "./CreateRequest";
import { Redirect } from "react-router-dom";
import MailPreview from "./MailPreview";
import { SEND_DEFAULT_MAIL } from "../../graphql/sendDefaultInboxMail";
import { VIEW_REQUESTS } from "../../graphql/viewRequests";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import DelayNotice from "./DelayNotice";
import PropTypes from "prop-types";

let selectedRequest;

class Inbox extends Component {
  static propTypes = {
    requests: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
    viewRequests: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    uploadFile: PropTypes.func.isRequired,
    removeFiles: PropTypes.func.isRequired,
    removeInteraction: PropTypes.func.isRequired,
    inbox: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      error: PropTypes.object,
      account: PropTypes.object,
      viewer: PropTypes.object,
      requestPager: PropTypes.shape({
        requests: PropTypes.array,
        totalCount: PropTypes.number,
      }),
      refetch: PropTypes.func.isRequired,
    }).isRequired,
    sendDefaultMail: PropTypes.func.isRequired,
  };

  state = {
    isRighMenuOpen: false,
    selectedRequestId: null,
    selectedRequest: null,
    isSuggestedActionVisible: false,
    isCreateRequest: false,
    isPreviewMail: false,
    answer: "",
    isEditMail: false,
    isSameRequest: true,
    isDelay: false,
    isSolved: false,
    isSolvingSectionVisible: false,
    assigned: null,
    files: [],
    index: 0,
    externalFiles: [],
    showInitial: false,
    filterValue: "MOST_RECENT",
    isCreated: false,
    hasProps: false,
    isInteraction: false,
    interactionId: null,
    hasFiles: false,
    showMenu: false,
    isMobileDevice: false,
    hasMore: true,
    isVerified: false,
  };

  componentDidMount() {
    const isMobileDevice = getMobileDevice();

    this.setState({
      isMobileDevice,
    });

    if (this.props.location.state && this.props.location.state.id) {
      this.setState({
        hasProps: true,
      });
    }

    this.props
      .viewRequests()
      .then()
      .catch((error) => {
        console.log(error);
      });
  }

  notify = (text) => toast(text);

  showSection = (item, index) => {
    this.setState({
      isSameRequest: false,
      answer: "",
      selectedRequestId: item.id,
      selectedRequest: item,
      index: index,
      isCreateRequest: false,
      isEditMail: false,
      isPreviewMail: false,
      isDelay: false,
      isSolved: false,
      isSolvingSectionVisible: false,
      assigned: null,
      files: [],
      externalFiles: [],
      showInitial: false,
      showFilter: false,
      isCreated: false,
      hasProps: false,
      isInteraction: false,
      interactionId: null,
      hasFile: false,
    });

    this.scrollToSection("inbox");
  };

  generateReport = (id) => {
    this.props
      .generateReport({
        variables: {
          id: id,
          reportType: "REQUEST",
        },
      })
      .then(({ data }) => {
        this.download(data.generateReport.downloadUrl);
      })
      .catch((error) => {
        this.notify("Report couldn't be generated");
        console.log(error);
      });
  };

  scrollToSection = (section) => {
    // eslint-disable-next-line
    this.refs[section].scrollIntoView({ block: "start" });
  };

  onFiles = (res) => {
    this.setState({ files: res.filesUploaded });

    this.props
      .uploadFile({
        variables: {
          requestId: selectedRequest.id,
          type: "INTERNAL_USER_DATA",
          url: this.state.files[0].key,
        },
      })
      .then(() => {
        this.notify("File uploaded!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("File was not uploaded!");
      });
  };

  onFile = (res) => {
    this.setState({ files: res.filesUploaded });

    this.props
      .uploadFile({
        variables: {
          requestId:
            this.state.index &&
            this.props.inbox &&
            this.props.inbox.requestPager &&
            this.props.inbox.requestPager.requests
              ? this.props.inbox.requestPager.requests[this.state.index].id
              : this.props.inbox.requestPager.requests[0].id,
          type: "INTERNAL_USER_DATA",
          url: this.state.files[0].key,
        },
      })
      .then(() => {
        this.props
          .sendDefaultMail({
            variables: {
              requestId:
                this.state.index &&
                this.props.inbox &&
                this.props.inbox.requestPager &&
                this.props.inbox.requestPager.requests
                  ? this.props.inbox.requestPager.requests[this.state.index].id
                  : this.props.inbox.requestPager.requests[0].id,
              mailType: "DATA_REQUEST_SOLVED",
              reasonOfUnreasonable: null,
            },
          })
          .then(() => {
            this.showSolved();
          })
          .catch((error) => {
            this.notify("Response couldn't be sent!");
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onExternalFiles = ({ filesUploaded }) => {
    this.setState({ externalFiles: filesUploaded, files: [] });

    this.props
      .uploadFile({
        variables: {
          requestId: selectedRequest.id,
          type: "EXTERNAL_USER_DATA",
          url: this.state.externalFiles[0].key,
        },
      })
      .then(() => {
        this.notify("File uploaded!");
      })
      .catch((error) => {
        this.notify("File was not uploaded!");
        console.log(error);
      });
  };

  removeFile = (id) => {
    this.props
      .removeFiles({
        variables: {
          fileId: id,
        },
      })
      .then(() => {
        this.notify("File was removed!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("File was not removed!");
      });
  };

  setAssigned = (assigned) => {
    this.setState({
      assigned,
    });
  };

  showSolvingSection = () => {
    this.setState({
      isSolvingSectionVisible: !this.state.isSolvingSectionVisible,
    });
  };

  showDelay = () => {
    this.setState({
      isDelay: !this.state.isDelay,
    });
  };

  showSolved = () => {
    this.setState({
      isSolved: !this.state.isSolved,
    });
  };

  showSuggestedActionModal = () => {
    this.setState({
      isSuggestedActionVisible: !this.state.isSuggestedActionVisible,
    });
  };

  showTitle = (title) => {
    if (title === "DATA_ACCESS") return "Data Access Request";
    else if (title === "DATA_ERASURE") return "Data Erasure";
    else if (title === "QUESTION") return "Question";
    else if (title === "COMPLAINT") return "Complaint";
    else if (title === "DATA_TRANSFER") return "Data Transfer";
    else if (title === "DONT_SELL_DATA") return "Do not sell data";
    else return title;
  };

  showMailPreview = (answer) => {
    this.setState({
      isPreviewMail: !this.state.isPreviewMail,
      answer,
    });
  };

  editEmail = (answer) => {
    this.setState({
      isEditMail: !this.state.isEditMail,
      answer,
    });
  };

  removeInteraction = (id) => {
    this.props
      .removeInteraction({
        variables: {
          ids: [id],
        },
      })
      .then(() => {
        this.notify("Interaction was removed!");
        this.props.inbox.refetch();
      })
      .catch((error) => {
        this.notify("Interaction was not removed!");
        console.log(error);
      });
  };

  filterRequest = (value) => {
    this.setState(
      {
        filterValue: value,
        showFilter: true,
        isCreated: false,
        index: 0,
        hasProps: false,
        isInteraction: false,
        interactionId: null,
        selectedRequestId: null,
        selectedRequest: null,
        isSuggestedActionVisible: false,
        isCreateRequest: false,
        isPreviewMail: false,
        answer: "",
        isEditMail: false,
        isSameRequest: true,
        isDelay: false,
        isSolved: false,
        isSolvingSectionVisible: false,
        assigned: null,
        files: [],
        externalFiles: [],
        showInitial: false,
        hasFiles: false,
        showMenu: false,
        isMobileDevice: false,
        hasMore: true,
      },
      () =>
        this.props.inbox.refetch({
          requestsStatus: value,
          index: 0,
        })
    );
  };

  download = (url) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  createRequests = () => {
    this.setState({
      isCreateRequest: true,
      isCreated: false,
      isSolved: false,
      isDelay: false,
      index: 0,
    });
  };

  getRequest = (id) => {
    this.setState({
      isInteraction: true,
      interactionId: id,
    });
  };

  fetchMoreData = () => {
    const { requestPager } = this.props.inbox;
    const requests =
      this.props.inbox &&
      this.props.inbox.requestPager &&
      this.props.inbox.requestPager.requests;

    if (!this.state.isVerified && requests && requests.length) {
      if (requests.length >= requestPager.totalCount) {
        this.setState({ hasMore: false });
        return;
      }

      this.props.inbox.refetch({
        limit: requests.length + 5,
      });
    } else {
      this.setState({ hasMore: false });
    }
  };

  render() {
    const { loading, error, account, viewer } = this.props.inbox;
    const requests =
      this.props.inbox &&
      this.props.inbox.requestPager &&
      this.props.inbox.requestPager.requests;
    const {
      isCreateRequest,
      isPreviewMail,
      isEditMail,
      isDelay,
      isSolved,
      showInitial,
      showFilter,
      isCreated,
      isMobileDevice,
    } = this.state;

    let i;

    if (this.state.hasProps === true && requests && this.props.location.state) {
      [...requests]
        .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        .filter((item, index) => {
          if (item.id === this.props.location.state.id) {
            i = index;
          }
          return i;
        });
    }

    if (
      this.state.isInteraction === true &&
      requests &&
      this.state.interactionId
    ) {
      [...requests]
        .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        .filter((item, index) => {
          if (item.id == this.state.interactionId) {
            i = index;
          }
          return i;
        });
    }

    if (!this.state.hasFile) {
      selectedRequest =
        (requests && this.state.hasProps) ||
        (requests && this.state.isInteraction)
          ? [...requests].sort((a, b) => parseFloat(b.id) - parseFloat(a.id))[i]
          : requests && !this.state.hasProps
          ? showInitial
            ? [...requests].sort(
                (a, b) => parseFloat(b.id) - parseFloat(a.id)
              )[0]
            : [...requests].sort((a, b) => parseFloat(b.id) - parseFloat(a.id))[
                this.state.index
              ]
          : null;
    }

    if (isCreated && !this.state.hasFile) {
      selectedRequest = [...requests].sort(
        (a, b) => parseFloat(b.id) - parseFloat(a.id)
      )[0];
    }

    const receivedDate = (
      <Moment
        date={
          selectedRequest && selectedRequest.receivedDate
            ? selectedRequest.receivedDate
            : null
        }
      />
    );
    const dueDate = (
      <Moment
        date={
          selectedRequest && selectedRequest.dueDate
            ? selectedRequest.dueDate
            : null
        }
      />
    );

    const date1 = new Date(dueDate.props.date),
      date2 = new Date(receivedDate.props.date),
      Difference_In_Time = date1.getTime() - date2.getTime(),
      Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Inbox"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />
          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          {(requests && requests.length) || isCreateRequest || showFilter ? (
            <div
              className={`${
                account && account.needToUpgrade ? "-has-banner" : ""
              } inbox`}
            >
              <InboxMenu
                isMobileDevice={isMobileDevice}
                requests={requests}
                fetchMoreData={this.fetchMoreData}
                hasMore={this.state.hasMore}
                selectedRequestId={selectedRequest ? selectedRequest.id : null}
                showSection={this.showSection}
                diff={Difference_In_Days}
                filterRequest={this.filterRequest}
                filterValue={this.state.filterValue}
                showVerified={(value) => {
                  this.fetchMoreData();
                  this.setState(
                    {
                      index: 0,
                      showFilter: value,
                      isVerified: value,
                    },
                    () =>
                      this.props.inbox.refetch({
                        requestsVerified: value,
                        index: 0,
                      })
                  );
                }}
              />

              <div className="inbox__content position-relative container">
                {isPreviewMail ? (
                  <div className="inbox__data-preview-header">
                    <p>Preview Mode</p>
                    <span
                      onClick={() => this.showMailPreview(this.state.answer)}
                    >
                      Exit &nbsp;
                      <MdClose size={20} />
                    </span>
                  </div>
                ) : null}
                <div className="inbox__data">
                  {!isPreviewMail && !isEditMail ? (
                    <div
                      // eslint-disable-next-line
                      ref="inbox"
                      className={`${
                        selectedRequest && selectedRequest.status === "SOLVED"
                          ? "mb-4"
                          : ""
                      } d-flex justify-content-between align-items-center pt-3`}
                    >
                      <h3 className="inbox__data-header">
                        {(!isCreateRequest && requests) ||
                        (!isCreateRequest && requests && showFilter)
                          ? this.showTitle(
                              selectedRequest ? selectedRequest.category : ""
                            )
                          : isCreateRequest
                          ? "Create request"
                          : requests && requests.length === 0
                          ? "Create request"
                          : ""}
                      </h3>

                      {!isCreateRequest && requests && requests.length ? (
                        <Button
                          className="btn--empty bg-transparent inbox__transparent-btn"
                          onClick={() =>
                            this.setState({
                              isCreateRequest: !this.state.isCreateRequest,
                              isSolved: false,
                              isDelay: false,
                            })
                          }
                        >
                          Create request
                        </Button>
                      ) : null}
                    </div>
                  ) : null}

                  {requests &&
                  requests.length &&
                  selectedRequest &&
                  selectedRequest.status !== "SOLVED" ? (
                    !isPreviewMail &&
                    !isEditMail &&
                    !isDelay &&
                    !isSolved &&
                    !isCreateRequest ? (
                      <SuggestedAction
                        isQuestionShowing={
                          selectedRequest
                            ? selectedRequest.category === "QUESTION"
                            : false
                        }
                        isSellShowing={
                          selectedRequest
                            ? selectedRequest.category === "DONT_SELL_DATA"
                            : false
                        }
                        isDataAccessShowing={
                          selectedRequest
                            ? selectedRequest.category === "DATA_ACCESS"
                            : false
                        }
                        isDataErasureShowing={
                          selectedRequest
                            ? selectedRequest.category === "DATA_ERASURE"
                            : false
                        }
                        isDataTransfer={
                          selectedRequest
                            ? selectedRequest.category === "DATA_TRANSFER"
                            : false
                        }
                        showSuggestedActionModal={this.showSuggestedActionModal}
                      />
                    ) : null
                  ) : null}

                  {!isCreateRequest &&
                  !isPreviewMail &&
                  !isEditMail &&
                  !isDelay &&
                  !isSolved &&
                  requests &&
                  requests.length ? (
                    <Fragment>
                      <Request
                        selectedRequest={selectedRequest}
                        diff={Difference_In_Days}
                        mailType={
                          selectedRequest &&
                          selectedRequest.category &&
                          (selectedRequest.category === "COMPLAINT" ||
                            selectedRequest.category === "QUESTION")
                            ? "QUESTION_COMPLAINT_SOLVED"
                            : selectedRequest.category === "DATA_ERASURE"
                            ? "DATA_ERASURE_REQUESTED_DATA_PROCESSOR"
                            : "DATA_REQUEST_SOLVED"
                        }
                        account={account}
                        showMailPreview={this.showMailPreview}
                        editEmail={this.editEmail}
                        answer={this.state.answer}
                        getItem={() => this.setState({ isSameRequest: true })}
                        isSameRequest={this.state.isSameRequest}
                        viewer={viewer}
                        generateReport={this.generateReport}
                        showDelay={this.showDelay}
                        showSolved={this.showSolved}
                        isSolvingSectionVisible={
                          this.state.isSolvingSectionVisible
                        }
                        showSolvingSection={this.showSolvingSection}
                        setAssigned={this.setAssigned}
                        assignedTo={this.state.assigned}
                        onFiles={this.onFiles}
                        onFile={this.onFile}
                        files={this.state.files}
                        removeFile={this.removeFile}
                        onExternalFiles={this.onExternalFiles}
                        externalFiles={this.state.externalFiles}
                        showInitialRequest={() =>
                          this.setState({ showInitial: true, index: 0 }, () =>
                            this.props.inbox.refetch()
                          )
                        }
                      />
                      {selectedRequest &&
                      selectedRequest.dataSubject &&
                      selectedRequest.dataSubject.interactions
                        ? [...selectedRequest.dataSubject.interactions]
                            .sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
                            .map((item) => (
                              <InboxAction
                                download={this.download}
                                generateReport={this.generateReport}
                                getRequest={this.getRequest}
                                item={item}
                                key={item.id}
                                title={
                                  selectedRequest.dataSubject.interactions
                                    .activity
                                }
                                removeInteraction={this.removeInteraction}
                              />
                            ))
                        : null}
                    </Fragment>
                  ) : isDelay ? (
                    <DelayNotice showDelay={this.showDelay} />
                  ) : isSolved ? (
                    <DelayNotice isSolved={true} showDelay={this.showSolved} />
                  ) : isEditMail ? (
                    <div className="inbox__data-edit">
                      <MailPreview
                        mailType={
                          selectedRequest &&
                          (selectedRequest.category === "COMPLAINT" ||
                            selectedRequest.category === "QUESTION")
                            ? "QUESTION_COMPLAINT_SOLVED"
                            : selectedRequest.category === "DONT_SELL_DATA"
                            ? "DONT_SELL_DATA_REQUEST_DATA_SUBJECT"
                            : selectedRequest.category === "DATA_ERASURE"
                            ? "DATA_ERASURE_REQUESTED_DATA_PROCESSOR"
                            : "DATA_REQUEST_SOLVED"
                        }
                        requestId={selectedRequest ? selectedRequest.id : null}
                        answer={this.state.answer}
                        isEdit={true}
                        showSolved={this.showSolved}
                        editEmail={this.editEmail}
                        closeEdit={() => this.editEmail(this.state.answer)}
                      />
                    </div>
                  ) : isPreviewMail ? (
                    <MailPreview
                      mailType={
                        selectedRequest &&
                        (selectedRequest.category === "COMPLAINT" ||
                          selectedRequest.category === "QUESTION")
                          ? "QUESTION_COMPLAINT_SOLVED"
                          : selectedRequest.category === "DONT_SELL_DATA"
                          ? "DONT_SELL_DATA_REQUEST_DATA_SUBJECT"
                          : selectedRequest.category === "DATA_ERASURE"
                          ? "DATA_ERASURE_REQUESTED_DATA_PROCESSOR"
                          : "DATA_REQUEST_SOLVED"
                      }
                      answer={this.state.answer}
                    />
                  ) : (
                    <CreateRequest
                      closeCreate={() =>
                        this.setState({ isCreateRequest: false }, () =>
                          this.props.inbox.refetch()
                        )
                      }
                      selectedRequest={selectedRequest}
                      users={account ? account.dataSubjects : null}
                      showCreatedInbox={() =>
                        this.setState({ isCreated: true })
                      }
                      isDataController={
                        selectedRequest && selectedRequest.dataController
                          ? true
                          : false
                      }
                    />
                  )}
                </div>
              </div>

              <SuggestedActionModal
                showSuggestedActionModal={this.showSuggestedActionModal}
                isSuggestedActionVisible={this.state.isSuggestedActionVisible}
                isQuestionShowing={
                  selectedRequest
                    ? selectedRequest.category === "QUESTION"
                    : false
                }
                isDataAccessShowing={
                  selectedRequest
                    ? selectedRequest.category === "DATA_ACCESS"
                    : false
                }
                isSellShowing={
                  selectedRequest
                    ? selectedRequest.category === "DONT_SELL_DATA"
                    : false
                }
                isDataErasureShowing={
                  selectedRequest
                    ? selectedRequest.category === "DATA_ERASURE"
                    : false
                }
                isDataTransfer={
                  selectedRequest
                    ? selectedRequest.category === "DATA_TRANSFER"
                    : false
                }
              />
            </div>
          ) : !showFilter ? (
            <NoRequests
              account={account}
              createRequests={this.createRequests}
            />
          ) : null}{" "}
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            newestOnTop={false}
            closeButton={false}
            closeOnClick
            rtl={false}
            draggable
            hideProgressBar
          />
        </div>
      </div>
    );
  }
}

export default compose(
  graphql(INBOX_QUERY, {
    options: () => ({
      variables: {
        requestsStatus: "MOST_RECENT",
        requestsVerified: false,
        limit: 8,
      },
      fetchPolicy: "network-only",
    }),
    name: "inbox",
  }),
  graphql(UPLOAD_FILE, {
    name: "uploadFile",
  }),
  graphql(VIEW_REQUESTS, {
    name: "viewRequests",
  }),
  graphql(REMOVE_INTERACTION, {
    name: "removeInteraction",
  }),
  graphql(REMOVE_FILE_OF_REQUEST, {
    name: "removeFiles",
  }),
  graphql(SEND_DEFAULT_MAIL, {
    name: "sendDefaultMail",
  }),
  graphql(GENERATE_REPORT, {
    name: "generateReport",
  })
)(Inbox);
