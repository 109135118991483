import gql from "graphql-tag";

export const SELECT_DATA_CONTROLLERS = gql`
  mutation($clientMutationId: String, $importId: ID!, $ids: [ID!]!) {
    selectDataControllers(
      input: {
        clientMutationId: $clientMutationId
        importId: $importId
        ids: $ids
      }
    ) {
      errors
    }
  }
`;
