import gql from "graphql-tag";

export const TOGGLE_POPUP_BRANDING = gql`
  mutation($id: ID!, $clientMutationId: String) {
    toggleShowLegalsiteBrandingPopup(
      input: { id: $id, clientMutationId: $clientMutationId }
    ) {
      errors
      popup {
        showLegalsiteBranding
      }
    }
  }
`;
