import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Tooltip } from "reactstrap";
import Flag from "../../images/Inbox/Flag.svg";
import Building from "../../images/Inbox/building.png";
import Moment from "react-moment";

export default class InboxItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    closeMenu: PropTypes.func.isRequired,
    selectedRequestId: PropTypes.string,
    index: PropTypes.number,
  };

  state = {};

  toggle = (targetName) => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen,
        },
      });
    }
  };

  isToolTipOpen = (targetName) => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  getTypeClass = (type) => {
    if (type === "DATA_ACCESS") return "-is-purple";
    if (type === "QUESTION") return "-is-aqua";
    if (type === "COMPLAINT") return "-is-coral";
    if (type === "DONT_SELL_DATA") return "-is-blue";
    if (type === "DATA_TRANSFER") return "-is-black";
    else return "-is-yellow";
  };

  showStatus = (status) => {
    if (status === "SOLVED") return "Solved";
    else if (status === "IN_PROGRESS") return "In Progress";
    else if (status === "ACTION_REQUIRED") return "Action required";
    else if (status === "DELAYED") return "Delayed";
    else return status;
  };

  render() {
    const { id, item, index } = this.props;

    const date1 = new Date(item && item.dueDate ? item.dueDate.toString() : ""),
      date2 = new Date(),
      Difference_In_Time = date1.getTime() - date2.getTime(),
      Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

    return (
      <div
        className={`inbox__item d-flex flex-column justify-content-around ${
          id === this.props.selectedRequestId ? "-is-active" : ""
        }`}
        onClick={() => {
          this.props.showSection(item, index);
          this.props.closeMenu();
        }}
      >
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <span className="inbox__item-name">
            {item && item.dataController && item.dataController.name
              ? item.dataController.name
              : item && item.dataSubject
              ? item.dataSubject.name
              : ""}
          </span>
          <span className="inbox__item-date">
            <Moment format="DD/MM/YYYY">
              {item && item.receivedDate ? item.receivedDate : null}
            </Moment>
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <p className="inbox__item-email mb-0">
            {item && item.dataController && item.dataController.email
              ? item.dataController.email
              : item && item.dataSubject
              ? item.dataSubject.email
              : ""}
          </p>
          <div>
            {(item && item.urgent) || Difference_In_Days < 7 ? (
              <Fragment>
                <img
                  src={Flag}
                  alt="urgent"
                  className="mr-1"
                  id={`urgent-${id}`}
                />
                <Tooltip
                  placement="top"
                  isOpen={this.isToolTipOpen(`urgent-${id}`)}
                  target={`urgent-${id}`}
                  toggle={() => this.toggle(`urgent-${id}`)}
                >
                  Urgent
                </Tooltip>
              </Fragment>
            ) : null}

            {item && item.dataController ? (
              <Fragment>
                <img
                  src={Building}
                  alt="request"
                  className="ml-1"
                  id={`request-${id}`}
                />
                <Tooltip
                  placement="top"
                  isOpen={this.isToolTipOpen(`request-${id}`)}
                  target={`request-${id}`}
                  toggle={() => this.toggle(`request-${id}`)}
                >
                  Request by data controller
                </Tooltip>
              </Fragment>
            ) : null}
          </div>
        </div>

        <div className="d-flex inbox__item-notification">
          <div
            className={`inbox__item-type align-items-center d-flex justify-content-center ${this.getTypeClass(
              item.category
            )}`}
          >
            {item.category === "DATA_ACCESS"
              ? "DATA ACCESS"
              : item.category === "DATA_ERASURE"
              ? "DATA ERASURE"
              : item.category === "QUESTION"
              ? "QUESTION"
              : item.category === "DONT_SELL_DATA"
              ? "DON'T SELL DATA"
              : item.category === "DATA_TRANSFER"
              ? "DATA TRANSFER"
              : "COMPLAINT"}
          </div>

          <span className="inbox__item-action">
            {item && item.spam === true
              ? "Spam"
              : item && item.archived === true
              ? "Archived"
              : this.showStatus(item.status)}
          </span>
        </div>
      </div>
    );
  }
}
