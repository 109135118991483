import gql from "graphql-tag";

export const EDIT_CLIENT = gql`
  mutation(
    $accountId: ID!
    $companyName: String
    $email: String
    $country: String
    $clientMutationId: String
  ) {
    editClient(
      input: {
        accountId: $accountId
        companyName: $companyName
        clientMutationId: $clientMutationId
        email: $email
        country: $country
      }
    ) {
      errors
      client {
        id
        companyName
        country
        email
      }
    }
  }
`;
