import React, { Component, Fragment } from "react";
import BreachMenu from "./BreachMenu";
import BreachRightMenu from "./BreachRightMenu";
import WhereBreach from "./WhereBreach";
import RiskBreach from "./RiskBreach";
import HowBreach from "./HowBreach";
import CauseBreach from "./CauseBreach";
import DataBreach from "./DataBreach";
import UsersBreach from "./UsersBreach";
import MeasuresBreach from "./MeasuresBreach";
import ConsequencesBreach from "./ConsequencesBreach";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import WhenBreach from "./WhenBreach";
import axios from "axios";
import { EMAILS_QUERY } from "../../graphql/emails";
import NotifyBreach from "./NotifyBreach";
import EmailBreach from "./EmailBreach";
import BreachSentEmail from "../../components/Modals/BreachSentEmail";
import ConcludedBreach from "../../components/Modals/ConcludedBreach";
import { ToastContainer, toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { CREATE_BREACH_INCIDENT } from "../../graphql/createBreachIncident";
import { EDIT_BREACH_INCIDENT } from "../../graphql/editBreachIncident";
import { SEND_BREACH_MAIL } from "../../graphql/sendBreachMail";
import PropTypes from "prop-types";

class Breaches extends Component {
  static propTypes = {
    incidentType: PropTypes.string,
    breachName: PropTypes.string,
    showIncidents: PropTypes.func,
    refetchData: PropTypes.func,
    getInitialPage: PropTypes.func,
    createBreachIncident: PropTypes.func,
    editBreachIncident: PropTypes.func,
    sendBreachMail: PropTypes.func,
    incident: PropTypes.object,
    data: PropTypes.object,
  };

  state = {
    sectionActive: "where",
    isNotify: false,
    isEmail: false,
    emailType: null,
    isBreachSentModalVisible: false,
    isModalOpen: false,
    where: "",
    risk: null,
    when: "",
    awareness: "",
    period: "",
    breachEnd: "",
    natures: [],
    description: "",
    causeDescription: "",
    causes: [],
    identifyingDetailsData: [],
    personalDataCriminals: "",
    specialCategoriesData: [],
    affectedRecords: "",
    numberOfAffectedRecords: "",
    vulnerable: "",
    measure: "",
    mitigatingActions: "",
    securedBreached: "",
    potentialConsequences: [],
    additionalInformation: "",
    emailContent: null,
    isOther: false,
    updatedBody: null,
    updatedFooterText: null,
    emailText: null,
  };

  componentDidMount() {
    this.props.refetchData();
  }

  showSection = (section) => {
    this.setState(
      {
        sectionActive: section,
        emailType: null,
        isOther: true,
        updatedBody: null,
        updatedFooterText: null,
        emailText: null,
      },
      () =>
        this.props.data.refetch({
          emailType:
            section === "your-users"
              ? "DATA_BREACH_USERS"
              : section === "interested"
              ? "DATA_BREACH_OTHER_COMPANIES"
              : "DATA_BREACH_OWN_COMPANY",
        })
    );
  };

  showNotify = (saveOnly) => {
    const { incident } = this.props;

    if (this.props.incidentType === "new") {
      this.props
        .createBreachIncident({
          variables: {
            name: this.props.breachName,
            where: this.state.where,
            risk: this.state.risk,
            breachDatetime: this.state.when,
            awenessedDatetime: this.state.awareness,
            breachOngoing: this.state.period ? this.state.period : null,
            endedDatetime: this.state.breachEnd,
            natures: this.state.natures,
            natureDescription: this.state.description,
            causes: this.state.causes,
            causeDescription: this.state.causeDescription,
            indentifyingDetailsInIndividuals: this.state.identifyingDetailsData,
            personalDataCriminals: this.state.personalDataCriminals
              ? this.state.personalDataCriminals
              : null,
            specialCategoriesOfDatas: this.state.specialCategoriesData,
            affectedRecordsKnown: this.state.affectedRecords,
            numberOfAffectedRecords: parseInt(
              this.state.numberOfAffectedRecords
            ),
            vulnerableIndividualsAffectedKnown: this.state.vulnerable
              ? this.state.vulnerable
              : null,
            takenMeasures: this.state.measure,
            mitigatingActionsImplemented: this.state.mitigatingActions
              ? this.state.mitigatingActions
              : null,
            securedBreachedData: this.state.securedBreached
              ? this.state.securedBreached
              : null,
            potentialConsequences: this.state.potentialConsequences,
            additionalInformation: this.state.additionalInformation,
          },
        })
        .then(() => {
          this.props.refetchData();
          if (saveOnly === false) {
            this.setState({
              isEmail: false,
            });
          } else {
            this.setState({
              isNotify: !this.state.isNotify,
              isEmail: false,
              isModalOpen: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.notify("Breach Incident couldn't be created!");
        });
    } else {
      this.props
        .editBreachIncident({
          variables: {
            id: this.props.incident.id,
            where: this.state.where
              ? this.state.where
              : incident
              ? incident.where
              : null,
            risk: this.state.risk
              ? this.state.risk
              : incident
              ? incident.risk
              : null,
            breachedDatetime:
              this.state.when === undefined
                ? ""
                : this.state.when
                ? this.state.when
                : incident
                ? incident.breachedDatetime
                : null,
            awenessedDatetime:
              this.state.awareness === undefined
                ? ""
                : this.state.awareness
                ? this.state.awareness
                : incident
                ? incident.awenessedDatetime
                : null,
            breachOngoing:
              this.state.period !== null && this.state.period !== ""
                ? this.state.period
                : incident
                ? incident.breachOngoing
                : null,
            endedDatetime:
              this.state.breachEnd === undefined
                ? ""
                : this.state.endedDatetime
                ? this.state.breachEnd
                : incident
                ? incident.endedDatetime
                : null,
            natures:
              this.state.natures && this.state.natures.length
                ? this.state.natures
                : incident
                ? incident.natures
                : null,
            natureDescription: this.state.description
              ? this.state.description
              : incident
              ? incident.natureDescription
              : null,
            causes:
              this.state.causes && this.state.causes.length
                ? this.state.causes
                : incident
                ? incident.causes
                : null,
            causeDescription: this.state.causeDescription
              ? this.state.causeDescription
              : incident
              ? incident.causeDescription
              : null,
            indentifyingDetailsInIndividuals:
              this.state.identifyingDetailsData &&
              this.state.identifyingDetailsData.length
                ? this.state.identifyingDetailsData
                : incident
                ? incident.indentifyingDetailsInIndividuals
                : null,
            personalDataCriminals: this.state.personalDataCriminals
              ? this.state.personalDataCriminals
              : incident
              ? incident.personalDataCriminals
              : null,
            specialCategoriesOfData:
              this.state.specialCategoriesData &&
              this.state.specialCategoriesData.length
                ? this.state.specialCategoriesData
                : incident
                ? incident.specialCategoriesOfData
                : null,
            affectedRecordsKnown: this.state.affectedRecords
              ? this.state.affectedRecords
              : incident
              ? incident.affectedRecordsKnown
              : null,
            numberOfAffectedRecords: parseInt(
              this.state.numberOfAffectedRecords
                ? this.state.numberOfAffectedRecords
                : incident
                ? incident.numberOfAffectedRecords
                : null
            ),
            vulnerableIndividualsAffectedKnown: this.state.vulnerable
              ? this.state.vulnerable
              : incident
              ? incident.vulnerableIndividualsAffectedKnown
              : null,
            takenMeasures: this.state.measure
              ? this.state.measure
              : incident
              ? incident.takenMeasures
              : null,
            mitigatingActionsImplemented: this.state.mitigatingActions
              ? this.state.mitigatingActions
              : incident
              ? incident.mitigatingActionsImplemented
              : null,
            securedBreachedData: this.state.securedBreached
              ? this.state.securedBreached
              : incident
              ? incident.securedBreachedData
              : null,
            potentialConsequences:
              this.state.potentialConsequences &&
              this.state.potentialConsequences.length
                ? this.state.potentialConsequences
                : incident
                ? incident.potentialConsequences
                : null,
            additionalInformation: this.state.additionalInformation
              ? this.state.additionalInformation
              : incident
              ? incident.additionalInformation
              : null,
          },
        })
        .then(() => {
          if (saveOnly === false) {
            this.setState({
              isEmail: false,
            });
          } else {
            this.setState({
              isNotify: !this.state.isNotify,
              isEmail: false,
              isModalOpen: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.notify("Breach Incident couldn't be edited!");
        });
    }
  };

  notify = (text) => toast(text);

  showEmail = (type, email) => {
    this.setState(
      {
        isEmail: !this.state.isEmail,
        isNotify: false,
        emailType: type,
        emailContent: email,
      },
      () =>
        this.props.data.refetch({
          emailType:
            type === "users"
              ? "DATA_BREACH_USERS"
              : type === "companies"
              ? "DATA_BREACH_OTHER_COMPANIES"
              : "DATA_BREACH_OWN_COMPANY",
        })
    );
  };

  showBreachSentModal = () => {
    this.setState({
      isBreachSentModalVisible: !this.state.isBreachSentModalVisible,
    });
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    this.notify(this.showDownloadNotifyText());
  };

  openModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  sendEmail = () => {
    const email = this.props.incident.breachMails.filter(
      (item) => item.target === "VISITORS"
    );

    this.props
      .sendBreachMail({
        variables: {
          id: email[0].id,
          body: this.state.updatedBody
            ? this.state.updatedBody
            : this.props.data.email.body,
          signature: this.state.updatedFooterText
            ? this.state.updatedFooterText
            : this.props.data.email.signature,
          footer: this.state.emailText
            ? this.state.emailText
            : this.props.data.email.footer,
        },
      })
      .then(() => {
        this.showBreachSentModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showReport = () => {
    window.open(this.props.incident.downloadUrl, "_blank");
  };

  render() {
    const { sectionActive, isNotify, isEmail, emailType } = this.state;
    const { incident } = this.props;

    const date = incident && incident.lastUpdated ? incident.lastUpdated : "",
      dateParts = date.split("/"),
      dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    return (
      <div className="compliance-breaches">
        {!isNotify && !isEmail ? (
          <Fragment>
            <BreachMenu
              showSection={this.showSection}
              sectionActive={sectionActive}
              incidentType={this.props.incidentType}
              getInitialPage={this.props.getInitialPage}
            />

            {sectionActive === "where" ? (
              <WhereBreach
                where={
                  this.state.where
                    ? this.state.where
                    : incident && this.props.incidentType !== "new"
                    ? incident.where
                    : null
                }
                showWhere={(where) => this.setState({ where })}
              />
            ) : sectionActive === "risk" ? (
              <RiskBreach
                risk={
                  this.state.risk !== null
                    ? this.state.risk
                    : incident && this.props.incidentType !== "new"
                    ? incident.risk
                    : null
                }
                getRisk={(risk) => this.setState({ risk })}
              />
            ) : sectionActive === "nature" ? (
              <HowBreach
                description={
                  this.state.description
                    ? this.state.description
                    : incident && this.props.incidentType !== "new"
                    ? incident.natureDescription
                    : null
                }
                natures={
                  this.state.natures && this.state.natures.length
                    ? this.state.natures
                    : incident && this.props.incidentType !== "new"
                    ? incident.natures
                    : null
                }
                getNatures={(natures) =>
                  this.setState({
                    natures: natures.filter((item) => item !== null),
                  })
                }
                getDescription={(description) => this.setState({ description })}
              />
            ) : sectionActive === "cause" ? (
              <CauseBreach
                description={
                  this.state.causeDescription
                    ? this.state.causeDescription
                    : incident && this.props.incidentType !== "new"
                    ? incident.causeDescription
                    : null
                }
                causes={
                  this.state.causes && this.state.causes.length
                    ? this.state.causes
                    : incident && this.props.incidentType !== "new"
                    ? incident.causes
                    : null
                }
                getCauses={(causes) =>
                  this.setState({
                    causes: causes.filter((item) => item !== null),
                  })
                }
                getCauseDescription={(description) =>
                  this.setState({
                    causeDescription: description,
                  })
                }
              />
            ) : sectionActive === "data" ? (
              <DataBreach
                identifyingDetails={
                  this.state.identifyingDetailsData &&
                  this.state.identifyingDetailsData.length
                    ? this.state.identifyingDetailsData
                    : incident && this.props.incidentType !== "new"
                    ? incident.indentifyingDetailsInIndividuals
                    : null
                }
                personalDataCriminals={
                  this.state.personalDataCriminals
                    ? this.state.personalDataCriminals
                    : incident && this.props.incidentType !== "new"
                    ? incident.personalDataCriminals
                    : null
                }
                specialCategories={
                  this.state.specialCategoriesData &&
                  this.state.specialCategoriesData.length
                    ? this.state.specialCategoriesData
                    : incident && this.props.incidentType !== "new"
                    ? incident.specialCategoriesOfData
                    : null
                }
                identifyingDetailsData={(details) =>
                  this.setState({
                    identifyingDetailsData: details.filter(
                      (item) => item !== null
                    ),
                  })
                }
                personalDataCriminalsData={(data) =>
                  this.setState({
                    personalDataCriminals: data,
                  })
                }
                specialCategoriesData={(data) =>
                  this.setState({
                    specialCategoriesData: data.filter((item) => item !== null),
                  })
                }
              />
            ) : sectionActive === "users" ? (
              <UsersBreach
                affectedRecordsKnown={
                  this.state.affectedRecords
                    ? this.state.affectedRecords
                    : incident && this.props.incidentType !== "new"
                    ? incident.affectedRecordsKnown
                    : null
                }
                vulnerableIndividuals={
                  this.state.vulnerable
                    ? this.state.vulnerable
                    : incident && this.props.incidentType !== "new"
                    ? incident.vulnerableIndividualsAffectedKnown
                    : null
                }
                number={
                  this.state.numberOfAffectedRecords
                    ? this.state.numberOfAffectedRecords
                    : incident && this.props.incidentType !== "new"
                    ? incident.numberOfAffectedRecords
                    : null
                }
                affectedRecords={(record) =>
                  this.setState({ affectedRecords: record })
                }
                numberOfAffectedRecords={(number) =>
                  this.setState({
                    numberOfAffectedRecords: number,
                  })
                }
                vulnerable={(data) => this.setState({ vulnerable: data })}
              />
            ) : sectionActive === "measures" ? (
              <MeasuresBreach
                takenMeasures={
                  this.state.measure
                    ? this.state.measure
                    : incident && this.props.incidentType !== "new"
                    ? incident.takenMeasures
                    : null
                }
                mitigatingActionsImplemented={
                  this.state.mitigatingActions
                    ? this.state.mitigatingActions
                    : incident && this.props.incidentType !== "new"
                    ? incident.mitigatingActionsImplemented
                    : null
                }
                securedBreachedData={
                  this.state.securedBreached
                    ? this.state.securedBreached
                    : incident && this.props.incidentType !== "new"
                    ? incident.securedBreachedData
                    : null
                }
                takenMeasuresData={(measure) => this.setState({ measure })}
                mitigatingActions={(data) =>
                  this.setState({ mitigatingActions: data })
                }
                securedBreached={(data) =>
                  this.setState({ securedBreached: data })
                }
              />
            ) : sectionActive === "consequences" ? (
              <ConsequencesBreach
                potentialConsequences={
                  this.state.potentialConsequences &&
                  this.state.potentialConsequences.length
                    ? this.state.potentialConsequences
                    : incident && this.props.incidentType !== "new"
                    ? incident.potentialConsequences
                    : null
                }
                additionalInformation={
                  this.state.additionalInformation
                    ? this.state.additionalInformation
                    : incident && this.props.incidentType !== "new"
                    ? incident.additionalInformation
                    : null
                }
                potentialConsequencesData={(data) =>
                  this.setState({
                    potentialConsequences: data.filter((item) => item !== null),
                  })
                }
                additionalInformationData={(data) =>
                  this.setState({
                    additionalInformation: data,
                  })
                }
              />
            ) : (
              <WhenBreach
                breachedDatetime={
                  this.state.when === undefined
                    ? ""
                    : this.state.when
                    ? this.state.when
                    : incident && this.props.incidentType !== "new"
                    ? incident.breachedDatetime
                    : null
                }
                awenessedDatetime={
                  this.state.awareness === undefined
                    ? ""
                    : this.state.awareness
                    ? this.state.awareness
                    : incident && this.props.incidentType !== "new"
                    ? incident.awenessedDatetime
                    : null
                }
                endedDatetime={
                  this.state.breachEnd === undefined
                    ? ""
                    : this.state.breachEnd
                    ? this.state.breachEnd
                    : incident && this.props.incidentType !== "new"
                    ? incident.endedDatetime
                    : null
                }
                breachOngoing={
                  this.state.period
                    ? this.state.period
                    : incident && this.props.incidentType !== "new"
                    ? incident.breachOngoing
                    : null
                }
                getWhen={(when) => this.setState({ when })}
                getAwareness={(awareness) => this.setState({ awareness })}
                getPeriod={(period) => this.setState({ period })}
                getBreachEnd={(breachEnd) => this.setState({ breachEnd })}
                removeEnding={() => {
                  this.setState({ breachEnd: "empty" });
                }}
                removeBeginning={() => {
                  this.setState({ when: "empty" });
                }}
                removeAwareness={() => {
                  this.setState({ awareness: "empty" });
                }}
              />
            )}
          </Fragment>
        ) : isNotify ? (
          <NotifyBreach
            showEmail={this.showEmail}
            incident={incident}
            getInitialPage={this.props.getInitialPage}
          />
        ) : (
          <Fragment>
            <BreachMenu
              isEmail={true}
              showSection={this.showSection}
              sectionActive={sectionActive}
              emailType={emailType}
              getInitialPage={this.props.getInitialPage}
            />
            <EmailBreach
              sectionActive={sectionActive}
              emailType={emailType}
              isOther={this.state.isOther}
              hideOther={() => this.setState({ isOther: false })}
              updateTitle={(text) => this.setState({ updatedTitle: text })}
              updateBody={(text) => this.setState({ updatedBody: text })}
              updateEmailText={(text) => this.setState({ emailText: text })}
              updateFooterText={(text) =>
                this.setState({ updatedFooterText: text })
              }
              updatedBody={this.state.updatedBody}
              updatedFooterText={this.state.updatedFooterText}
              emailText={this.state.emailText}
            />
          </Fragment>
        )}
        <BreachRightMenu
          showIncidents={this.props.showIncidents}
          incidentType={this.props.incidentType}
          showNotify={this.showNotify}
          isNotify={isNotify}
          showBreachSentModal={this.showBreachSentModal}
          isEmail={isEmail}
          updated={dateObject.toString()}
          download={
            incident && incident.downloadUrl ? incident.downloadUrl : ""
          }
          name={incident && incident.name ? incident.name : ""}
          downloadBreach={this.download}
          incident={incident}
          sendEmail={this.sendEmail}
          showReport={this.showReport}
        />

        <BreachSentEmail
          isBreachSentModalVisible={this.state.isBreachSentModalVisible}
          showBreachSentModal={this.showBreachSentModal}
          emailType={emailType}
          sectionActive={sectionActive}
        />

        <ConcludedBreach
          openModal={this.openModal}
          isModalOpen={this.state.isModalOpen}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(CREATE_BREACH_INCIDENT, {
    name: "createBreachIncident",
  }),
  graphql(EDIT_BREACH_INCIDENT, {
    name: "editBreachIncident",
  }),
  graphql(EMAILS_QUERY, {
    options: () => ({
      variables: {
        emailType: "DATA_BREACH_USERS",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(SEND_BREACH_MAIL, {
    name: "sendBreachMail",
  })
)(Breaches);
