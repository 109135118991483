import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import { Button } from "reactstrap";
import { BREACHES_DATA_PROCESSORS_QUERY } from "../../graphql/breachesDataProcessors";
import { graphql } from "@apollo/client/react/hoc";
import "./modals.scss";
import SearchInput from "../SearchInput/SearchInput";
import DPSupplier from "../../containers/Compliance/DPSupplier";

class SelectSuppliersModal extends Component {
  static propTypes = {
    showSuppliersModal: PropTypes.func.isRequired,
    isSuppliersModalVisible: PropTypes.bool.isRequired,
    getItem: PropTypes.func.isRequired,
    isBreach: PropTypes.bool,
    dataSuppliers: PropTypes.array,
    data: PropTypes.object,
  };

  state = {
    isGoogle: false,
    isFacebook: false,
    isZendesk: false,
    filteredPublicDataProcessors: null,
  };

  searchDataProcessor = (dataProcessor) => {
    let publicList = [],
      newPublicList = [];

    if (dataProcessor !== "") {
      publicList = this.props.data.currentDataProcessors;

      newPublicList = publicList.filter((item) => {
        const dataProcessorName = item.dataProcessor.companyName.toLowerCase(),
          filter = dataProcessor.toLowerCase();

        return dataProcessorName.includes(filter);
      });
    } else {
      newPublicList = this.props.data.currentDataProcessors;
    }

    this.setState({
      filteredPublicDataProcessors: newPublicList,
    });
  };

  render() {
    const { isBreach, dataSuppliers } = this.props;
    const { currentDataProcessors } = this.props.data;

    let publicDP = this.state.filteredPublicDataProcessors
      ? this.state.filteredPublicDataProcessors
      : currentDataProcessors;

    return (
      <div>
        <Modal
          isOpen={this.props.isSuppliersModalVisible}
          toggle={this.props.showSuppliersModal}
          className={`${
            currentDataProcessors && currentDataProcessors.length
              ? ""
              : "-is-empty"
          } modal-suppliers`}
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showSuppliersModal}
            />
            <p className="modal-assign__title">Select Data Processors</p>

            {isBreach ? (
              <p className="modal-assign__text mt-2 text-center mx-auto">
                Select the Data Processors in which this breach has occurred.{" "}
              </p>
            ) : null}

            <SearchInput
              search={this.searchDataProcessor}
              placeholder="Type to search for a data processor..."
            />

            <div className="d-flex justify-content-around modal-suppliers__container">
              {publicDP && publicDP.length ? (
                publicDP.map((item) => (
                  <DPSupplier
                    item={item}
                    getItem={this.props.getItem}
                    key={item.id}
                    dataSuppliers={dataSuppliers}
                  />
                ))
              ) : (
                <p className="w-100 text-center">
                  Currently there's no Data Processor
                </p>
              )}
            </div>

            <div className="modal-suppliers__btns mt-5">
              <Button
                className="modal-suppliers__cancel"
                onClick={this.props.showSuppliersModal}
              >
                Cancel
              </Button>
              {currentDataProcessors && currentDataProcessors.length ? (
                <Button
                  className="modal-suppliers__select"
                  onClick={this.props.showSuppliersModal}
                >
                  Save
                </Button>
              ) : null}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(BREACHES_DATA_PROCESSORS_QUERY)(SelectSuppliersModal);
