import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import UsersIcon from "../../components/Icons/UsersIcon";
import Sitemap from "../../components/Icons/Sitemap";

function EmailType(props) {
  const { item } = props;

  return (
    <div className="notify-breach__item">
      <div className="d-flex">
        {item.target === "SUPERVISORY_AUTHORITY" ? (
          <Sitemap color={"#333"} className="ml-3 notify-breach__item-icon" />
        ) : item.target === "VISITORS" ? (
          <UsersIcon color={"#333"} className="ml-3 notify-breach__item-icon" />
        ) : null}
        <div>
          <p className="notify-breach__item-title">
            {item.target === "SUPERVISORY_AUTHORITY"
              ? "Relevant supervisory authority"
              : item.target === "VISITORS"
              ? "Data Subjects"
              : null}
          </p>
          <p className="notify-breach__item-text">
            {item.target === "SUPERVISORY_AUTHORITY"
              ? "Organizations must do this within 72 hours after becoming aware of the breach."
              : item.target === "VISITORS"
              ? "Send an e-mail notifying your data subjetcs about this data breach incident."
              : null}
          </p>
          {item.target !== "SUPERVISORY_AUTHORITY" ? (
            <Button
              className="btn--primary notify-breach__btn"
              onClick={() =>
                props.showEmail(
                  `${item.target === "VISITORS" ? "users" : null}`,
                  item
                )
              }
            >
              Send e-mail
            </Button>
          ) : (
            <Button className="btn--primary notify-breach__btn">
              <a
                href="https://edpb.europa.eu/about-edpb/board/members_en"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                Contact
              </a>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailType;
EmailType.propTypes = {
  showEmail: PropTypes.func.isRequired,
  item: PropTypes.object,
};
