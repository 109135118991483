import React from "react";
import "./upgrade-bar.scss";
import { graphql } from "@apollo/client/react/hoc";
import { UPGRADE_QUERY } from "../../graphql/upgradeQuery";

import PropTypes from "prop-types";

function UpgradeBar(props) {
  const { account } = props.data;

  return (
    <div className="upgrade-bar">
      You have reached the limit of your plan, please &nbsp;{" "}
      <a
        className="text-white"
        href={`https://legalsite.chargebee.com/hosted_pages/plans/business-edition?customer[email]=${
          account ? account.email : ""
        }`}
      >
        <u>add your billing information</u>
      </a>
      .
    </div>
  );
}

export default graphql(UPGRADE_QUERY)(UpgradeBar);
UpgradeBar.propTypes = {
  data: PropTypes.shape({
    account: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
};
