import React, { Component } from "react";
import { Input } from "reactstrap";
import PropTypes from "prop-types";
import Check from "react-icons/lib/fa/check";
import MdClose from "react-icons/lib/md/close";

export default class ActionsRequired extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    elements: PropTypes.array,
    getElements: PropTypes.func.isRequired,
  };

  state = {
    isName: this.props.elements
      ? this.props.elements.indexOf("Name") > -1
        ? true
        : false
      : null,
    isAddress: this.props.elements
      ? this.props.elements.indexOf("Address") > -1
        ? true
        : false
      : null,
    isBanking: this.props.elements
      ? this.props.elements.indexOf("Banking details") > -1
        ? true
        : false
      : null,
    isOnline: this.props.elements
      ? this.props.elements.indexOf("Online browsing history") > -1
        ? true
        : false
      : null,
    isPurchasing: this.props.elements
      ? this.props.elements.indexOf("Purchasing history") > -1
        ? true
        : false
      : null,
    isImages: this.props.elements
      ? this.props.elements.indexOf("Images") > -1
        ? true
        : false
      : null,
    isVideo: this.props.elements
      ? this.props.elements.indexOf("Video") > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.elements
      ? this.props.elements.filter(
          (item) =>
            item !== "Name" &&
            item !== "Address" &&
            item !== "Banking details" &&
            item !== "Online browsing history" &&
            item !== "Purchasing history" &&
            item !== "Video" &&
            item !== "Images"
        ).length
        ? true
        : false
      : false,
  };

  getElements = () => {
    const info = this.props.elements
      ? this.props.elements.filter(
          (item) =>
            item !== "Name" &&
            item !== "Address" &&
            item !== "Banking details" &&
            item !== "Online browsing history" &&
            item !== "Purchasing history" &&
            item !== "Video" &&
            item !== "Images"
        )
      : "";

    this.props.getElements([
      this.state.isName === true ? "Name" : null,
      this.state.isAddress === true ? "Address" : null,
      this.state.isBanking === true ? "Banking details" : null,
      this.state.isOnline === true ? "Online browsing history" : null,
      this.state.isPurchasing === true ? "Purchasing history" : null,
      this.state.isImages === true ? "Images" : null,
      this.state.isVideo === true ? "Video" : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  render() {
    const info = this.props.elements
      ? this.props.elements.filter(
          (item) =>
            item !== "Name" &&
            item !== "Address" &&
            item !== "Banking details" &&
            item !== "Online browsing history" &&
            item !== "Purchasing history" &&
            item !== "Video" &&
            item !== "Images"
        )
      : "";

    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title">Elements</h5>

        <div className="d-flex mb-3">
          <span
            className={`check ${this.state.isName ? "-checked" : "-unchecked"}`}
            onClick={() =>
              this.setState({ isName: !this.state.isName }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Name
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isAddress ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isAddress: !this.state.isAddress }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Address
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isBanking ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isBanking: !this.state.isBanking }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Banking details
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isOnline ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isOnline: !this.state.isOnline }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Online browsing history
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isPurchasing ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isPurchasing: !this.state.isPurchasing }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Purchasing history
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isImages ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isImages: !this.state.isImages }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Images
          </span>
        </div>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isVideo ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isVideo: !this.state.isVideo }, () =>
                this.getElements()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Video
          </span>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex mb-3">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getElements()
                )
              }
            >
              <Check color={"#fff"} />
            </span>
            <span className="modal-edit-privacy-category__content-text">
              Other
            </span>
          </div>
          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getElements())
            }
          />
        </div>
      </div>
    );
  }
}
