import React, { Component } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import Building from "../../components/Icons/Building";
import Check from "react-icons/lib/fa/check";
import { Input, Form, Button, Table } from "reactstrap";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import Checked from "../../components/Check/Checked";
import ViewIcon from "../../components/Icons/ViewIcon";
import TrashIcon from "../../components/Icons/TrashIcon";

export default class LawyerView extends Component {
  static propTypes = {
    showLawyer: PropTypes.func.isRequired,
  };

  state = {
    isCheckedAll: false,
  };

  render() {
    const { isCheckedAll } = this.state;

    return (
      <div className="lawyer-view container">
        <div className="lawyer-view__close" onClick={this.props.showLawyer}>
          Exit &nbsp;&nbsp;
          <MdClose size={20} />
        </div>

        <h5 className="lawyer-view__title">Lawyer: Tim Maia</h5>

        <div className="lawyer-view__container">
          <div className="d-flex justify-content-between flex-wrap">
            <p className="lawyer-view__info">Lawyer Info</p>
            <div className="d-flex">
              <p className="text-right lawyer-view__download">
                <DownloadIcon className="mr-2" color="#7450c8" /> Download
              </p>
              <p className="text-right lawyer-view__delete">
                <TrashIcon className="mr-2" color="#7450c8" /> Delete
              </p>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            <div className="d-flex lawyer-view__details">
              <div>
                <span className="lawyer-view__avatar" />
              </div>
              <Form className="lawyer-view__form">
                <Input type="text" placeholder="Name" />
                <Input type="text" placeholder="Email" />
                <Input type="text" placeholder="Phone" />
                <Input type="text" placeholder="Country" />
              </Form>
            </div>

            <div className="d-flex lawyer-view__details">
              <div>
                <span className="lawyer-view__avatar" />
              </div>
              <Form>
                <Input type="text" placeholder="Company name" />

                <p className="lawyer-view__label">Bank account</p>
                <div className="lawyer-view__bank">
                  <Building />

                  <div className="d-flex flex-column">
                    <p className="lawyer-view__bank-name">
                      <strong>Bank account </strong>0092377-9173827
                    </p>
                    <p className="lawyer-view__bank-code">
                      <strong>ID/ SWIFT code </strong>0092377-9173827
                    </p>
                  </div>

                  <MdClose size={20} />
                </div>

                <div>
                  <p className="lawyer-view__added">Added</p>
                  <p className="lawyer-view__date">19/04/2019</p>
                </div>
              </Form>
            </div>
            <div className="lawyer-view__btn">
              <Button className="btn--primary" onClick={this.props.showLawyer}>
                Save and close
              </Button>
            </div>
          </div>
        </div>

        <div className="lawyer-view__invoices">
          <p className="lawyer-view__info pt-4 pl-4">Invoices</p>
          <p className="lawyer-view__info-text pl-4">
            See all your invoices history.
          </p>

          <div className="lawyers__table">
            <div className="lawyers__table-content">
              <div className="lawyers__actions">
                <div className="d-flex align-items-center">
                  <span
                    className={`check ${
                      isCheckedAll ? "-checked" : "-unchecked"
                    }`}
                    onClick={() =>
                      this.setState({ isCheckedAll: !this.state.isCheckedAll })
                    }
                  >
                    <Check color={"#fff"} />
                  </span>

                  <div className="lawyers__action">
                    <DownloadIcon className="mr-1" color={"#969696"} /> Download
                    invoice
                  </div>

                  <div className="lawyers__action" onClick={this.showUser}>
                    <ViewIcon className="mr-1" color={"#969696"} /> See invoice
                  </div>

                  <div className="lawyers__action">
                    <TrashIcon className="mr-1" color={"#969696"} /> Delete
                    invoice
                  </div>
                </div>
              </div>

              <div className="users__table">
                <Table>
                  <thead>
                    <tr>
                      <th />
                      <th>ID NUMBER</th>
                      <th>PERIOD</th>
                      <th>VALUE</th>
                      <th>STATUS</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border-0 px-0">
                        <Checked isCheckedAll={isCheckedAll} />
                      </td>
                      <td>0029</td>
                      <td>04/16/2018</td>
                      <td>$430</td>
                      <td>Pending</td>
                      <td className="pr-3">
                        <span className="d-flex align-items-center justify-content-between">
                          <ArrowCircleRight color="#EBA601" />{" "}
                          <DownloadIcon color="#969696" />{" "}
                          <TrashIcon color="#969696" />
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td className="border-0 px-0">
                        <Checked isCheckedAll={isCheckedAll} />
                      </td>
                      <td>0029</td>
                      <td>04/16/2018</td>
                      <td>$430</td>
                      <td>Pending</td>
                      <td className="pr-3">
                        <span className="d-flex align-items-center justify-content-between">
                          <ArrowCircleRight color="#EBA601" />{" "}
                          <DownloadIcon color="#969696" />{" "}
                          <TrashIcon color="#969696" />
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td className="border-0 px-0">
                        <Checked isCheckedAll={isCheckedAll} />
                      </td>
                      <td>0029</td>
                      <td>04/16/2018</td>
                      <td>$430</td>
                      <td>Pending</td>
                      <td className="pr-3">
                        <span className="d-flex align-items-center justify-content-between">
                          <ArrowCircleRight color="#EBA601" />{" "}
                          <DownloadIcon color="#969696" />{" "}
                          <TrashIcon color="#969696" />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
