import gql from "graphql-tag";

export const REVERT_PAGE = gql`
  mutation($pageId: ID!, $publicationId: ID!, $clientMutationId: String) {
    revertPageToPublication(
      input: {
        pageId: $pageId
        publicationId: $publicationId
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      page {
        applicable
        description
        id
        pageTemplate {
          description
          id
          publicationDownloadUrl
          sections {
            applicable
            id
            position
            text
            title
          }
          templatePublications {
            createdAt
            description
            id
            sections {
              applicable
              id
              position
              text
              title
            }
            title
          }
          title
          updatedAt
        }
        publicationDownloadUrl
        publications {
          applicable
          createdAt
          description
          id
          sections {
            applicable
            id
            position
            text
            title
          }
          title
          updatedAt
          version
        }
        sections {
          applicable
          id
          position
          text
          title
        }
        status
        title
        updatedAt
      }
    }
  }
`;
