import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UsersIcon from "../../components/Icons/UsersIcon";
import MdArrow from "react-icons/lib/ti/arrow-right";
import BookPurple from "../../components/Icons/BookPurple";
import Moment from "react-moment";

function NotificationItem(props) {
  const { notification } = props;

  return (
    <div className="notification__item d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        {notification.category === "COMPLAINT" ||
        notification.category === "DATA_ACCESS" ||
        notification.category === "DONT_SELL_DATA" ||
        notification.category === "QUESTION" ||
        notification.category === "DATA_TRANSFER" ||
        notification.category === "DATA_ERASURE" ? (
          <UsersIcon color={"#7450C8"} />
        ) : (
          <BookPurple />
        )}
        <p className="notification__item-text mb-0">
          {notification.category === "DATA_ACCESS"
            ? "New user request - data access"
            : notification.category === "DONT_SELL_DATA"
            ? "New user request - do not sell data"
            : notification.category === "QUESTION"
            ? "New user request - question"
            : notification.category === "NEW_ASSIGNED_REQUEST"
            ? "New assigned request"
            : notification.category === "DATA_TRANSFER"
            ? "New user request - data transfer"
            : notification.category === "DATA_ERASURE"
            ? "New user request - data erasure"
            : notification.category === "CREATE_ACCOUNT"
            ? "New account created"
            : notification.category === "COMPLAINT"
            ? "New user request - complaint"
            : notification.category === "REVIEW_NEW_TEMPLATE_PUBLICATION"
            ? `We revised ${notification.templatePublication.title}`
            : notification.category === "REVIEW_TEMPLATE_CHANGES"
            ? `We changed ${
                notification.pageTemplate ? notification.pageTemplate.title : ""
              } page details`
            : ""}
        </p>
      </div>
      <div className="d-flex align-items-center">
        <p className="notification__item-date mb-0">
          <Moment fromNow>
            {notification.receivedAt
              ? notification.receivedAt
              : notification.receivedDate}
          </Moment>
        </p>
        <Link
          to={
            notification.category !== "DATA_ACCESS" &&
            notification.category !== "DATA_TRANSFER" &&
            notification.category !== "DONT_SELL_DATA" &&
            notification.category !== "QUESTION" &&
            notification.category !== "DATA_ERASURE" &&
            notification.category !== "COMPLAINT" &&
            notification.category !== "NEW_ASSIGNED_REQUEST" &&
            notification.category !== "CREATE_ACCOUNT"
              ? { pathname: "/pages" }
              : {
                  pathname: "/inbox",
                  // state: { id: notification.request.id },
                }
          }
          className="notification__item-see"
        >
          See more &nbsp;&nbsp;
          <MdArrow size={23} />
        </Link>
      </div>
    </div>
  );
}

export default NotificationItem;
NotificationItem.propTypes = {
  notification: PropTypes.object,
};
