import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import CheckActive from "../../components/Icons/CheckActive";
import CheckDisabled from "../../components/Icons/CheckDisabled";
import { GENERATE_REPORT } from "../../graphql/generateReport";
import { Button } from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";

class UploadTable extends Component {
  static propTypes = {
    isCheckedAll: PropTypes.bool.isRequired,
    showUser: PropTypes.func,
    user: PropTypes.object,
    isCompliance: PropTypes.bool,
    isDP: PropTypes.bool,
    isImport: PropTypes.bool,
    checkItem: PropTypes.func,
    generateReport: PropTypes.func,
    index: PropTypes.number,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
    userCompany: PropTypes.string,
    userId: PropTypes.string,
    users: PropTypes.array,
    changePage: PropTypes.func,
    numberOfPages: PropTypes.number,
    pageNumber: PropTypes.number,
    arr: PropTypes.array,
  };

  state = {
    isChecked: false,
    report: null,
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === false
    ) {
      this.setState({
        isChecked: false,
      });
    }

    if (
      prevProps.isCheckedAll !== this.props.isCheckedAll &&
      this.props.isCheckedAll === true
    ) {
      this.setState({
        isChecked: true,
      });
    }
  }

  handleCheck = (id) => {
    if (this.props.arr.includes(id)) {
      return (
        <CheckActive
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(id);
          }}
        />
      );
    } else {
      return (
        <CheckDisabled
          className="ml-3"
          onClick={() => {
            this.setState({ isChecked: !this.state.isChecked });
            this.props.checkItem(id);
          }}
        />
      );
    }
  };

  render() {
    const { users } = this.props;

    const columns = [
      {
        Header: "",
        accessor: "",
        Cell: (props) => (
          <span className="rt-check">{this.handleCheck(props.value.id)}</span>
        ),
      },
      {
        Header: "User",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (props) => (
          <span onClick={() => console.log(props)}>{props.value}</span>
        ),
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "Telephone",
        accessor: "phoneNumber",
      },
    ];

    return (
      <Fragment>
        <ReactTable
          data={users}
          columns={columns}
          showPagination={this.props.numberOfPages > 1 ? true : false}
          minRows={0}
          onPageChange={(pageNumber) => this.props.changePage(pageNumber)}
          renderTotalPagesCount={() => this.props.numberOfPages}
          showPageSizeOptions={false}
          defaultPageSize={20}
          showPageJump={false}
          resizable={false}
          noDataText="No Users"
        />
        {this.props.numberOfPages > 1 ? (
          <div className="d-flex justify-content-between users__btns-pagination -is-import">
            <Button
              className="users__prev btn--primary"
              onClick={() => this.props.changePage(this.props.pageNumber - 1)}
              disabled={this.props.pageNumber === 1 ? true : false}
            >
              Previous
            </Button>
            <div className="d-flex align-items-center bg-white">
              Page {this.props.pageNumber} of {this.props.numberOfPages}
            </div>
            <Button
              className="users__next btn--primary"
              onClick={() => this.props.changePage(this.props.pageNumber + 1)}
              disabled={
                this.props.pageNumber
                  ? this.props.pageNumber === this.props.numberOfPages ||
                    (users && users.length ? users.length < 19 : null)
                    ? true
                    : false
                  : true
              }
            >
              Next
            </Button>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default graphql(GENERATE_REPORT, {
  name: "generateReport",
})(UploadTable);
