import React, { Component } from "react";
import AccountSettings from "./AccountSettings";
import Password from "./Password";
import LegalSiteSettings from "./LegalSiteSettings";
import Cookies from "./Cookies";
import PlanSettings from "./PlanSettings";
import NotificationSettings from "./NotificationSettings";
import SyncLoader from "react-spinners/SyncLoader";
import "./settings.scss";
import Customization from "./Customization";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import { ToastContainer, toast } from "react-toastify";
import { graphql } from "@apollo/client/react/hoc";
import { SETTINGS_QUERY } from "../../graphql/settings";
import MenuContent from "../../components/MenuContent/MenuContent";
import Upgrade from "../../components/Modals/Upgrade";
import UserView from "./UserView";
import SampleLegalsite from "./SampleLegalsite";
import SampleCookie from "./SampleCookie";
import SampleEmails from "./SampleEmails";
import DataProcessing from "./DataProcessing";
import DpProfile from "./DpProfile";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import LegalInformation from "./LegalInformation";
import CustomizationSettings from "./CustomizationSettings";
import PropTypes from "prop-types";

class Settings extends Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.shape({
        isAdmin: PropTypes.bool,
        showEmail: PropTypes.bool,
        isAddUser: PropTypes.bool,
        companyInfo: PropTypes.any,
        notifications: PropTypes.any,
        customizeLegalsite: PropTypes.bool,
        customizeEmails: PropTypes.bool,
        customizePopups: PropTypes.bool,
      }),
    }),
    settings: PropTypes.shape({
      refetch: PropTypes.func,
      account: PropTypes.object,
      legalsiteSettings: PropTypes.object,
      viewer: PropTypes.object,
      consents: PropTypes.array,
      popup: PropTypes.any,
      loading: PropTypes.bool,
      dataProcessorSettings: PropTypes.object,
    }),
  };

  state = {
    sectionVisible: "",
    isCustomizationVisible: false,
    sectionSelected: "",
    isRighMenuOpen: false,
    isUpgradeVisible: false,
    type: "cookie",
    isUserView: false,
    userId: null,
    isAdminInterface: false,
    sampleType: null,
    consents: [],
    isDpProfile: false,
    dpId: null,
    isVisible: null,
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.isAdmin) {
      if (this.props.location.state.showEmail) {
        this.setState({
          isCustomizationVisible: !this.state.isCustomizationVisible,
          sectionSelected: "E-mail templates",
          isAdminInterface: true,
        });
      }
    }

    if (this.props.location.state && this.props.location.state.isAddUser) {
      this.setState({
        sectionVisible: "account",
      });
    }

    if (this.props.location.state) {
      if (this.props.location.state.companyInfo) {
        this.setState({
          sectionVisible: "legal",
        });
      }

      if (this.props.location.state.notifications) {
        this.setState({
          sectionVisible: "notifications",
        });
      }

      if (this.props.location.state.customizeLegalsite) {
        this.showCustomization("LegalSite");
      }

      if (this.props.location.state.customizeEmails) {
        this.showCustomization("E-mail templates");
      }

      if (this.props.location.state.customizePopups) {
        this.showCustomization("Pop-ups");
      }
    }
  }

  getInitialPage = () => {
    this.setState({
      sectionVisible: "",
      isCustomizationVisible: false,
      sectionSelected: "",
      isUserView: false,
      sampleType: null,
    });
  };

  showSection = (section) => {
    this.setState(
      {
        sectionVisible: section,
      },
      () => this.props.settings.refetch()
    );
  };

  notify = (text) => toast(text);

  showUser = (id) => {
    this.setState({
      userId: id,
      isUserView: !this.state.isUserView,
    });
  };

  showDpProfile = (id, value) => {
    this.setState({
      isDpProfile: !this.state.isDpProfile,
      dpId: id,
      isVisible: value,
    });
  };

  showCustomization = (item) => {
    this.setState({
      isCustomizationVisible: !this.state.isCustomizationVisible,
      sectionSelected: item,
    });
  };

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  refetchAccount = () => {
    this.props.settings.refetch();
  };

  getSample = (type) => {
    this.setState({
      sampleType: type,
    });
  };

  render() {
    const {
      isCustomizationVisible,
      isUserView,
      userId,
      sampleType,
      isDpProfile,
    } = this.state;
    const {
      account,
      legalsiteSettings,
      viewer,
      consents,
      popup,
      loading,
      dataProcessorSettings,
    } = this.props.settings;

    if (loading) {
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Settings"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent location={this.props.location.pathname} />
          </Menu>

          {!isCustomizationVisible &&
          !isUserView &&
          !sampleType &&
          !isDpProfile ? (
            <div
              className={`${
                account && account.needToUpgrade ? "-has-banner" : ""
              } settings`}
            >
              <div className="container">
                <AccountSettings
                  showSection={this.showSection}
                  sectionVisible={this.state.sectionVisible}
                  showUser={this.showUser}
                  account={account}
                  viewer={viewer}
                  refetchAccount={this.refetchAccount}
                />

                <LegalInformation
                  showSection={this.showSection}
                  sectionVisible={this.state.sectionVisible}
                  account={account}
                  notify={(msg) => this.notify(msg)}
                />

                {viewer && !viewer.appAdmin ? (
                  <DataProcessing
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                    account={account}
                    showDpProfile={this.showDpProfile}
                    isVisible={this.state.isVisible}
                    refetchAccount={this.refetchAccount}
                  />
                ) : null}

                <Password
                  showSection={this.showSection}
                  sectionVisible={this.state.sectionVisible}
                />

                {viewer && !viewer.appAdmin ? (
                  <CustomizationSettings
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                    showCustomization={this.showCustomization}
                    isCustomizationVisible={this.state.isCustomizationVisible}
                    legalsiteSettings={legalsiteSettings}
                    account={account}
                    refetchAccount={this.refetchAccount}
                    getSample={this.getSample}
                  />
                ) : null}

                {viewer && !viewer.appAdmin ? (
                  <LegalSiteSettings
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                    showCustomization={this.showCustomization}
                    isCustomizationVisible={this.state.isCustomizationVisible}
                    legalsiteSettings={legalsiteSettings}
                    account={account}
                    refetchAccount={this.refetchAccount}
                    getSample={this.getSample}
                  />
                ) : null}

                {viewer && !viewer.appAdmin ? (
                  <Cookies
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                    consents={consents}
                    popup={popup}
                    legalsiteSettings={legalsiteSettings}
                    refetchConsent={this.refetchAccount}
                    notify={this.notify}
                    refetchData={() => this.props.settings.refetch()}
                  />
                ) : null}

                {viewer && !viewer.appAdmin ? (
                  <PlanSettings
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                    account={account}
                  />
                ) : null}

                {viewer && !viewer.appAdmin ? (
                  <NotificationSettings
                    showSection={this.showSection}
                    sectionVisible={this.state.sectionVisible}
                  />
                ) : null}
              </div>
            </div>
          ) : isUserView && !isCustomizationVisible && !isDpProfile ? (
            <UserView
              showUser={this.showUser}
              user={
                account
                  ? account.users.filter((item) => item.id === userId)
                  : null
              }
              legalsiteSettings={legalsiteSettings ? legalsiteSettings : null}
              users={account && account.users ? account.users.length : null}
            />
          ) : !isUserView &&
            !isCustomizationVisible &&
            sampleType === "legalsite" &&
            !isDpProfile ? (
            <SampleLegalsite
              closeSample={() => this.setState({ sampleType: null })}
            />
          ) : !isUserView &&
            !isCustomizationVisible &&
            sampleType === "cookie" &&
            !isDpProfile ? (
            <SampleCookie
              closeSample={() => this.setState({ sampleType: null })}
            />
          ) : !isUserView &&
            !isCustomizationVisible &&
            sampleType === "email" &&
            !isDpProfile ? (
            <SampleEmails
              closeSample={() => this.setState({ sampleType: null })}
            />
          ) : !isUserView && !isCustomizationVisible && isDpProfile ? (
            <DpProfile
              showDpProfile={this.showDpProfile}
              dpId={dataProcessorSettings ? dataProcessorSettings.id : null}
            />
          ) : (
            <Customization
              isAdmin={this.state.isAdminInterface}
              showCustomization={this.showCustomization}
              sectionSelected={this.state.sectionSelected}
              account={account && account.access ? account.access : null}
              accountData={account}
              showLegalInformation={
                account && account.showLegalInformation
                  ? account.showLegalInformation
                  : null
              }
              getInitialPage={this.getInitialPage}
              notifyMsg={(msg) => this.notify(msg)}
            />
          )}
        </div>

        <Upgrade
          showUpgrade={this.showUpgrade}
          isCookiesConsent={true}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type={this.state.type}
          account={account}
        />

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default graphql(SETTINGS_QUERY, {
  name: "settings",
  options: () => ({
    variables: {
      category: "Cookies Policy",
    },
    fetchPolicy: "no-cache",
  }),
})(Settings);
