import PropTypes from "prop-types";
import React from "react";

const EditPencilSquare = props => (
    <svg
        height={15}
        viewBox='0 0 15 15'
        width={15}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Actions-side-bar-right" transform="translate(-53.000000, -278.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Side-bar-right">
                    <g id="Group-2" transform="translate(44.000000, 267.000000)">
                        <g id="Group-11">
                            <path d="M23.2458699,18.5021737 C22.8317764,18.5021737 22.4960873,18.8378629 22.4960873,19.2519564 L22.4960873,23.7506521 C22.4960873,24.1647456 22.1603981,24.5004347 21.7463046,24.5004347 L11.2493479,24.5004347 C10.8352544,24.5004347 10.4995653,24.1647456 10.4995653,23.7506521 L10.4995653,13.2536954 C10.4995653,12.8396019 10.8352544,12.5039127 11.2493479,12.5039127 L15.7480436,12.5039127 C16.1621371,12.5039127 16.4978263,12.1682236 16.4978263,11.7541301 C16.4978263,11.3400366 16.1621371,11.0043475 15.7480436,11.0043475 L11.2493479,11.0043475 C10.0070673,11.0043475 9,12.0114148 9,13.2536954 L9,23.7506521 C9,24.9929327 10.0070673,26 11.2493479,26 L21.7463046,26 C22.9885852,26 23.9956525,24.9929327 23.9956525,23.7506521 L23.9956525,19.2519564 C23.9956525,18.8378629 23.6599634,18.5021737 23.2458699,18.5021737 Z M11.9991305,19.0720085 L11.9991305,22.2510869 C11.9991305,22.6651804 12.3348196,23.0008695 12.7489131,23.0008695 L15.9279915,23.0008695 C16.1272859,23.0020217 16.318839,22.9237817 16.4603371,22.7834325 L21.6488329,17.5874389 L23.7782156,15.5030432 C23.9201589,15.3622591 24,15.1706178 24,14.9706976 C24,14.7707774 23.9201589,14.579136 23.7782156,14.4383519 L20.5991372,11.2217844 C20.4583531,11.0798411 20.2667117,11 20.0667916,11 C19.8668714,11 19.67523,11.0798411 19.5344459,11.2217844 L17.4200589,13.3436693 L12.2165675,18.5396629 C12.0762183,18.681161 11.9979783,18.8727141 11.9991305,19.0720085 Z M20.0667916,12.8113236 L22.1886764,14.9332084 L21.1239851,15.9978998 L19.0021002,13.8760149 L20.0667916,12.8113236 Z M13.4986958,19.3794194 L17.9449067,14.9332084 L20.0667916,17.0550933 L15.6205806,21.5013042 L13.4986958,21.5013042 L13.4986958,19.3794194 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

EditPencilSquare.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default EditPencilSquare;