import React, { Component, Fragment } from "react";
import { Col, Row, Button, Alert } from "reactstrap";
import CheckCircle from "../../components/Icons/CheckCircle";
import WarningIcon from "../../components/Icons/WarningIcon";
import Moment from "react-moment";
import PreviewReport from "./PreviewReport";
import SyncLoader from "react-spinners/SyncLoader";
import { PIA_QUERY } from "../../graphql/pia";
import { REMOVE_DATA_CATEGORY } from "../../graphql/removeDataCategory";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import ComplianceNotificationItem from "./ComplianceNotificationItem";
import axios from "axios";
import PIASurvey from "./PIASurvey";
import MdArrow from "react-icons/lib/ti/arrow-left";
import PropTypes from "prop-types";

class CompliancePrivacy extends Component {
  static propTypes = {
    data: PropTypes.object,
    removeDataCategory: PropTypes.func,
    getInitialPage: PropTypes.func,
  };

  state = {
    isSurvey: false,
    isPreview: false,
    isEditModalVisible: false,
    isAddModalVisible: false,
  };

  notify = (text) => toast(text);

  showSurvey = () => {
    this.setState({
      isSurvey: !this.state.isSurvey,
    });
  };

  showPreview = () => {
    this.setState({
      isPreview: !this.state.isPreview,
    });
  };

  showEditModal = () => {
    this.setState({
      isEditModalVisible: !this.state.isEditModalVisible,
    });
  };

  downloadBreach = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  showAddModal = () => {
    this.setState({
      isAddModalVisible: !this.state.isAddModalVisible,
    });
  };

  deleteCategory = (id) => {
    this.props
      .removeDataCategory({
        variables: {
          id,
        },
      })
      .then(() => {
        this.notify("Category deleted!");
        this.props.data.refetch();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Category was not deleted!");
      });
  };

  render() {
    const { isSurvey, isPreview } = this.state;

    const { privacyImpactAssessment, legalsiteSettings, loading, error } =
      this.props.data;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="compliance-privacy">
        {!isPreview && privacyImpactAssessment ? (
          <Fragment>
            <p
              onClick={this.props.getInitialPage}
              className="compliance-privacy__back"
            >
              <MdArrow
                size={25}
                className="customize-menu__back position-relative"
                color="#7450c8"
              />
              &nbsp;Back to Compliance
            </p>

            <h3 className="compliance-privacy__title">
              Privacy Impact Assessment
            </h3>

            {!isSurvey ? (
              <Fragment>
                <p className="compliance-privacy__text">
                  A Privacy Impact Assessment (PIA) is a tool for identifying
                  and assessing privacy risks. It’s a questionary so you can
                  document how your user’s data is maintained, how it will be
                  protected and how it will be shared. It’s important to know if
                  the information being collected complies with privacy-related
                  legal and regulatory compliance requirements.
                </p>
                <p className="compliance-privacy__text">
                  Click to start the survey and it will generate a report for
                  your company.
                </p>

                <Row className="compliance-privacy__items">
                  <Col lg={6} md={12} className="compliance-privacy__item">
                    <p className="compliance-privacy__item-title">Survey</p>
                    <div className="d-flex ml-4 align-items-center justify-content-between flex-wrap">
                      <div className="d-flex align-items-center mb-2">
                        {privacyImpactAssessment.status === "REQUESTED" ? (
                          <WarningIcon />
                        ) : (
                          <CheckCircle />
                        )}
                        <div>
                          <p className="compliance-privacy__item-done">
                            {privacyImpactAssessment.status === "REQUESTED"
                              ? "Requested"
                              : "Done and up-to-date"}
                          </p>
                          <p className="compliance-privacy__item-update">
                            Last updated{" "}
                            <Moment fromNow>
                              {privacyImpactAssessment.lastUpdated}
                            </Moment>
                          </p>
                        </div>
                      </div>
                      <Button
                        className="btn--primary compliance-privacy__item-btn mr-4 mb-2"
                        onClick={this.showSurvey}
                      >
                        Edit survey
                      </Button>
                    </div>
                  </Col>

                  <Col lg={6} md={12} className="compliance-privacy__item">
                    <ComplianceNotificationItem
                      notificationInterval={
                        privacyImpactAssessment.notificationInterval
                      }
                    />
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <PIASurvey
                privacyImpactAssessment={privacyImpactAssessment}
                showSurvey={this.showSurvey}
                showPreview={this.showPreview}
                isEditModalVisible={this.state.isEditModalVisible}
                showEditModal={this.showEditModal}
                downloadBreach={this.downloadBreach}
                refetchData={this.refetchData}
                showAddModal={this.showAddModal}
                isAddModalVisible={this.state.isAddModalVisible}
                deleteCategory={this.deleteCategory}
              />
            )}
          </Fragment>
        ) : (
          <PreviewReport
            showPreviewCompliance={this.showPreview}
            privacyImpactAssessment={privacyImpactAssessment}
            legalsiteSettings={legalsiteSettings}
          />
        )}

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default compose(
  graphql(PIA_QUERY),
  graphql(REMOVE_DATA_CATEGORY, {
    name: "removeDataCategory",
  })
)(CompliancePrivacy);
