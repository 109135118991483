/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Comments from "../Icons/Comments";
import ChatBubble from "../Icons/ChatBubble";
import ShieldCheck from "../Icons/ShieldCheck";
import LockIcon from "../Icons/LockIcon";
import { PAGES_PAGE_QUERY } from "../../graphql/pagesQuery";
import { graphql } from "@apollo/client/react/hoc";
import Consent from "../Icons/Consent";
import FileCheck from "../Icons/FileCheck";
import "./modals.scss";
import UsersIcon from "../Icons/UsersIcon";
import PreviewHeader from "../PreviewHeader/PreviewHeader";
import Fonts from "../../containers/Settings/Fonts";
import FontLoader from "../../containers/Settings/FontLoader";
import ReactHtmlParser from "react-html-parser";

// TODO
// Refactor this huge component
// Causing a lot of issues
class PreviewModal extends Component {
  state = {};

  render() {
    const {
      templateActive,
      titleColor,
      titleSize,
      bodyColor,
      bodySize,
      buttonColor,
      buttonSize,
      buttonTextColor,
      buttonTextSize,
      cookieDescription,
      cookieTitle,
      buttonShape,
      popupSide,
      popupPosition,
      popupOverlay,
      cookieBg,
      isSiteCustomization,
      isSell,
      welcomeBg,
      bg,
      cookieTitleFont,
      cookieBodyFont,
      cookieButtonTextFont,
      cookieButtonFont,
      isCookieCustomization,
      selectedPage,
      buttonText,
      secondaryText,
    } = this.props;

    const { pages, legalsite, consents, account, dontSellMyDataButton } =
      this.props.pagesQuery;

    const activeConsents = consents
      ? consents.filter((item) => item.enabled)
      : "";

    return (
      <div>
        <Modal
          fade={false}
          isOpen={this.props.isPreviewModalVisible}
          toggle={this.props.showPreviewModal}
          className="modal-preview"
        >
          <Fonts
            font1={
              legalsite && legalsite.navigationLinksFont
                ? legalsite.navigationLinksFont
                : "Roboto"
            }
            font2={
              legalsite && legalsite.headerTitleFont
                ? legalsite.headerTitleFont
                : "Roboto"
            }
            font3={
              legalsite && legalsite.headerBodyFont
                ? legalsite.headerBodyFont
                : "Roboto"
            }
            font4={
              legalsite && legalsite.cardsTitleFont
                ? legalsite.cardsTitleFont
                : "Roboto"
            }
            font5={
              legalsite && legalsite.cardsBodyFont
                ? legalsite.cardsBodyFont
                : "Roboto"
            }
            font6={
              legalsite && legalsite.cardsBtnFont
                ? legalsite.cardsBtnFont
                : "Roboto"
            }
            font7={
              legalsite && legalsite.bodyTitleFont
                ? legalsite.bodyTitleFont
                : "Roboto"
            }
            font8={
              legalsite && legalsite.bodySubtitleFont
                ? legalsite.bodySubtitleFont
                : "Roboto"
            }
            font9={
              legalsite && legalsite.bodyTextFont
                ? legalsite.bodyTextFont
                : "Roboto"
            }
            font10={
              legalsite && legalsite.bodyBtnFont
                ? legalsite.bodyBtnFont
                : "Roboto"
            }
            font11={
              legalsite && legalsite.footerLinksFont
                ? legalsite.footerLinksFont
                : "Roboto"
            }
            font12={
              legalsite && legalsite.footerTextFont
                ? legalsite.footerTextFont
                : "Roboto"
            }
          />

          <FontLoader
            font1={"Roboto"}
            font2={"Roboto"}
            font3={"Roboto"}
            font4={
              dontSellMyDataButton && dontSellMyDataButton.textFont
                ? dontSellMyDataButton.textFont
                : "Roboto"
            }
          />
          <ModalBody>
            <div className="modal-preview__header">
              <h3 className="modal-preview__title">Preview Mode</h3>
              <div
                className="modal-preview__close-btn"
                onClick={this.props.showPreviewModal}
              >
                <span className="modal-preview__exit mr-2">Exit</span>
                <MdClose
                  className="modal-assign__close"
                  color={"#fff"}
                  size={25}
                />
              </div>
            </div>

            <PreviewHeader
              navigationLinksAlign={this.props.navigationLinksAlign}
              navigationLinksBold={this.props.navigationLinksBold}
              navigationLinksItalic={this.props.navigationLinksItalic}
              navigationBg={this.props.navigationBg}
              navigationFont={this.props.navigationFont}
              navigationSize={this.props.navigationSize}
              navigationLinksColor={this.props.linkColor}
            />

            {!isCookieCustomization ||
            (isCookieCustomization && selectedPage === "all") ? (
              <div
                className={`${
                  isSiteCustomization || isSell ? "-has-overflow" : ""
                } modal-preview__container`}
              >
                <div
                  className="modal-preview__welcome-section"
                  style={{
                    background: this.props.headerBg
                      ? this.props.headerBg
                      : legalsite && legalsite.headerBg
                      ? legalsite.headerBg
                      : "#dfe4ee",
                  }}
                >
                  <h1
                    className="modal-preview__welcome-title"
                    style={{
                      color: this.props.headerTitleColor
                        ? this.props.headerTitleColor
                        : legalsite && legalsite.headerTitleColor
                        ? legalsite.headerTitleColor
                        : "#333",
                      fontSize: this.props.headerTitleSize
                        ? this.props.headerTitleSize
                        : legalsite && legalsite.headerTitleSize
                        ? legalsite.headerTitleSize
                        : 56,
                      fontFamily: this.props.headerTitleFont
                        ? this.props.headerTitleFont
                        : legalsite && legalsite.headerTitleFont
                        ? legalsite.headerTitleFont
                        : null,
                      textAlign: this.props.headerTitleAlign
                        ? this.props.headerTitleAlign
                        : legalsite && legalsite.headerTitleAlign !== null
                        ? legalsite.headerTitleAlign
                        : null,
                      fontWeight:
                        this.props.headerTitleBold !== null
                          ? this.props.headerTitleBold === true
                            ? 900
                            : 500
                          : legalsite && legalsite.headerTitleBold !== null
                          ? legalsite.headerTitleBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        this.props.headerTitleItalic !== null
                          ? this.props.headerTitleItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite && legalsite.headerTitleItalic !== null
                          ? legalsite.headerTitleItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    {this.props.homepageTitle
                      ? this.props.homepageTitle
                      : legalsite && legalsite.homepageTitle
                      ? legalsite.homepageTitle
                      : "Welcome!"}
                  </h1>
                  <p
                    className="modal-preview__welcome-text"
                    style={{
                      color: this.props.headerBodyColor
                        ? this.props.headerBodyColor
                        : legalsite && legalsite.headerBodyColor
                        ? legalsite.headerBodyColor
                        : "#969696",
                      fontSize: this.props.headerBodySize
                        ? this.props.headerBodySize
                        : legalsite && legalsite.headerBodySize
                        ? legalsite.headerBodySize
                        : 16,
                      fontFamily: this.props.headerBodyFont
                        ? this.props.headerBodyFont
                        : legalsite && legalsite.headerBodyFont
                        ? legalsite.headerBodyFont
                        : null,
                      textAlign: this.props.headerBodyAlign
                        ? this.props.headerBodyAlign
                        : legalsite && legalsite.headerBodyAlign !== null
                        ? legalsite.headerBodyAlign
                        : null,
                      fontWeight:
                        this.props.headerBodyBold !== null
                          ? this.props.headerBodyBold === true
                            ? 900
                            : 500
                          : legalsite && legalsite.headerBodyBold !== null
                          ? legalsite.headerBodyBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        this.props.headerBodyItalic !== null
                          ? this.props.headerBodyItalic === true
                            ? "italic"
                            : "normal"
                          : legalsite && legalsite.headerBodyItalic !== null
                          ? legalsite.headerBodyItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    {this.props.homepageDescription
                      ? this.props.homepageDescription
                      : legalsite && legalsite.homepageDescription
                      ? legalsite.homepageDescription
                      : "It’s important for us to explain the legal aspects of using our services. This mini-site is designed to provide you with the information you need."}
                  </p>
                </div>

                <div
                  className="modal-preview__info"
                  style={{
                    background: this.props.bodyBg
                      ? this.props.bodyBg
                      : legalsite && legalsite.bodyBg
                      ? legalsite.bodyBg
                      : "#f4f6fc",
                  }}
                >
                  <Row className="modal-preview__info-content">
                    {pages
                      ? pages.map((page) =>
                          (page.applicable &&
                            page.title === "Terms & Conditions") ||
                          (page.applicable &&
                            page.title === "Privacy Policy") ? (
                            <Col
                              key={page.id}
                              sm={12}
                              md={4}
                              className="modal-preview__info-privacy justify-content-between"
                              style={{
                                background: this.props.cardsBg
                                  ? this.props.cardsBg
                                  : legalsite && legalsite.cardsBg
                                  ? legalsite.cardsBg
                                  : "#fafafa",
                              }}
                            >
                              <div>
                                {page.title === "Terms & Conditions" ? (
                                  <ShieldCheck
                                    color={
                                      this.props.cardsIconsColor
                                        ? this.props.cardsIconsColor
                                        : legalsite && legalsite.cardsIconsColor
                                        ? legalsite.cardsIconsColor
                                        : "#7450c8"
                                    }
                                  />
                                ) : (
                                  <LockIcon
                                    color={
                                      this.props.cardsIconsColor
                                        ? this.props.cardsIconsColor
                                        : legalsite && legalsite.cardsIconsColor
                                        ? legalsite.cardsIconsColor
                                        : "#7450c8"
                                    }
                                  />
                                )}
                                <h4
                                  className="modal-preview__info-title"
                                  style={{
                                    color: this.props.cardsTitleColor
                                      ? this.props.cardsTitleColor
                                      : legalsite && legalsite.cardsTitleColor
                                      ? legalsite.cardsTitleColor
                                      : "#333",
                                    fontSize: this.props.cardsTitleSize
                                      ? this.props.cardsTitleSize
                                      : legalsite && legalsite.cardsTitleSize
                                      ? legalsite.cardsTitleSize
                                      : null,
                                    fontFamily: this.props.cardsTitleFont
                                      ? this.props.cardsTitleFont
                                      : legalsite && legalsite.cardsTitleFont
                                      ? legalsite.cardsTitleFont
                                      : null,
                                    textAlign: this.props.cardsTitleAlign
                                      ? this.props.cardsTitleAlign
                                      : legalsite &&
                                        legalsite.cardsTitleAlign !== null
                                      ? legalsite.cardsTitleAlign
                                      : null,
                                    fontWeight:
                                      this.props.cardsTitleBold !== null
                                        ? this.props.cardsTitleBold === true
                                          ? 900
                                          : 500
                                        : legalsite &&
                                          legalsite.cardsTitleBold !== null
                                        ? legalsite.cardsTitleBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.cardsTitleItalic !== null
                                        ? this.props.cardsTitleItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.cardsTitleItalic !== null
                                        ? legalsite.cardsTitleItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  {page.title}
                                </h4>
                                <p
                                  className="modal-preview__info-text"
                                  style={{
                                    color: this.props.cardsBodyColor
                                      ? this.props.cardsBodyColor
                                      : legalsite && legalsite.cardsBodyColor
                                      ? legalsite.cardsBodyColor
                                      : "#969696",
                                    fontSize: this.props.cardsBodySize
                                      ? this.props.cardsBodySize
                                      : legalsite && legalsite.cardsBodySize
                                      ? legalsite.cardsBodySize
                                      : null,
                                    fontFamily: this.props.cardsBodyFont
                                      ? this.props.cardsBodyFont
                                      : legalsite && legalsite.cardsBodyFont
                                      ? legalsite.cardsBodyFont
                                      : null,
                                    textAlign: this.props.cardsBodyAlign
                                      ? this.props.cardsBodyAlign
                                      : legalsite &&
                                        legalsite.cardsBodyAlign !== null
                                      ? legalsite.cardsBodyAlign
                                      : null,
                                    fontWeight:
                                      this.props.cardsBodyBold !== null
                                        ? this.props.cardsBodyBold === true
                                          ? 900
                                          : 500
                                        : legalsite &&
                                          legalsite.cardsBodyBold !== null
                                        ? legalsite.cardsBodyBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.cardsBodyItalic !== null
                                        ? this.props.cardsBodyItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.cardsBodyItalic !== null
                                        ? legalsite.cardsBodyItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  {page.description}
                                </p>
                              </div>
                              <div>
                                <p
                                  className="modal-preview__info-more"
                                  style={{
                                    fontSize: this.props.cardsBtnSize
                                      ? this.props.cardsBtnSize
                                      : legalsite && legalsite.cardsBtnSize
                                      ? legalsite.cardsBtnSize
                                      : null,
                                    fontFamily: this.props.cardsBtnFont
                                      ? this.props.cardsBtnFont
                                      : legalsite && legalsite.cardsBtnFont
                                      ? legalsite.cardsBtnFont
                                      : null,
                                    color: this.props.cardsBtnBg
                                      ? this.props.cardsBtnBg
                                      : legalsite && legalsite.cardsBtnBg
                                      ? legalsite.cardsBtnBg
                                      : "#7450c8",
                                    textAlign: this.props.cardsBtnAlign
                                      ? this.props.cardsBtnAlign
                                      : legalsite &&
                                        legalsite.cardsBtnAlign !== null
                                      ? legalsite.cardsBtnAlign
                                      : null,
                                    fontWeight:
                                      this.props.cardsBtnBold !== null
                                        ? this.props.cardsBtnBold === true
                                          ? 900
                                          : 500
                                        : legalsite &&
                                          legalsite.cardsBtnBold !== null
                                        ? legalsite.cardsBtnBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.cardsBtnItalic !== null
                                        ? this.props.cardsBtnItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.cardsBtnItalic !== null
                                        ? legalsite.cardsBtnItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  See more{" "}
                                  <MdArrow size={20} className="ml-2 my-0" />
                                </p>
                              </div>
                            </Col>
                          ) : null
                        )
                      : null}

                    <Col
                      sm={12}
                      md={4}
                      className="modal-preview__info-privacy mr-0 justify-content-between"
                      style={{
                        background: this.props.cardsBg
                          ? this.props.cardsBg
                          : legalsite && legalsite.cardsBg
                          ? legalsite.cardsBg
                          : "#fafafa",
                      }}
                    >
                      <div>
                        <Comments
                          color={
                            this.props.cardsIconsColor
                              ? this.props.cardsIconsColor
                              : legalsite && legalsite.cardsIconsColor
                              ? legalsite.cardsIconsColor
                              : "#7450c8"
                          }
                        />
                        <h4
                          className="modal-preview__info-title"
                          style={{
                            color: this.props.cardsTitleColor
                              ? this.props.cardsTitleColor
                              : legalsite && legalsite.cardsTitleColor
                              ? legalsite.cardsTitleColor
                              : "#333",
                            fontSize: this.props.cardsTitleSize
                              ? this.props.cardsTitleSize
                              : legalsite && legalsite.cardsTitleSize
                              ? legalsite.cardsTitleSize
                              : null,
                            fontFamily: this.props.cardsTitleFont
                              ? this.props.cardsTitleFont
                              : legalsite && legalsite.cardsTitleFont
                              ? legalsite.cardsTitleFont
                              : null,
                            textAlign: this.props.cardsTitleAlign
                              ? this.props.cardsTitleAlign
                              : legalsite && legalsite.cardsTitleAlign !== null
                              ? legalsite.cardsTitleAlign
                              : null,
                            fontWeight:
                              this.props.cardsTitleBold !== null
                                ? this.props.cardsTitleBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.cardsTitleBold !== null
                                ? legalsite.cardsTitleBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.cardsTitleItalic !== null
                                ? this.props.cardsTitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.cardsTitleItalic !== null
                                ? legalsite.cardsTitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Contact us
                        </h4>
                        <p
                          className="modal-preview__info-text"
                          style={{
                            color: this.props.cardsBodyColor
                              ? this.props.cardsBodyColor
                              : legalsite && legalsite.cardsBodyColor
                              ? legalsite.cardsBodyColor
                              : "#969696",
                            fontSize: this.props.cardsBodySize
                              ? this.props.cardsBodySize
                              : legalsite && legalsite.cardsBodySize
                              ? legalsite.cardsBodySize
                              : null,
                            fontFamily: this.props.cardsBodyFont
                              ? this.props.cardsBodyFont
                              : legalsite && legalsite.cardsBodyFont
                              ? legalsite.cardsBodyFont
                              : null,
                            textAlign: this.props.cardsBodyAlign
                              ? this.props.cardsBodyAlign
                              : legalsite && legalsite.cardsBodyAlign !== null
                              ? legalsite.cardsBodyAlign
                              : null,
                            fontWeight:
                              this.props.cardsBodyBold !== null
                                ? this.props.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.cardsBodyBold !== null
                                ? legalsite.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.cardsBodyItalic !== null
                                ? this.props.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.cardsBodyItalic !== null
                                ? legalsite.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Click to send a message and we’ll get in touch soon.
                        </p>
                      </div>
                      <div>
                        <p
                          className="modal-preview__info-more"
                          style={{
                            fontSize: this.props.cardsBtnSize
                              ? this.props.cardsBtnSize
                              : legalsite && legalsite.cardsBtnSize
                              ? legalsite.cardsBtnSize
                              : null,
                            fontFamily: this.props.cardsBtnFont
                              ? this.props.cardsBtnFont
                              : legalsite && legalsite.cardsBtnFont
                              ? legalsite.cardsBtnFont
                              : null,
                            color: this.props.cardsBtnBg
                              ? this.props.cardsBtnBg
                              : legalsite && legalsite.cardsBtnBg
                              ? legalsite.cardsBtnBg
                              : "#7450c8",
                            textAlign: this.props.cardsBtnAlign
                              ? this.props.cardsBtnAlign
                              : legalsite && legalsite.cardsBtnAlign !== null
                              ? legalsite.cardsBtnAlign
                              : null,
                            fontWeight:
                              this.props.cardsBtnBold !== null
                                ? this.props.cardsBtnBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.cardsBtnBold !== null
                                ? legalsite.cardsBtnBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.cardsBtnItalic !== null
                                ? this.props.cardsBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite && legalsite.cardsBtnItalic !== null
                                ? legalsite.cardsBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          See more <MdArrow size={20} className="ml-2 my-0" />
                        </p>
                      </div>
                    </Col>
                  </Row>

                  <Fragment>
                    <h3
                      className="modal-preview__info-subtitle"
                      style={{
                        color: this.props.bodyTitleColor
                          ? this.props.bodyTitleColor
                          : legalsite && legalsite.bodyTitleColor
                          ? legalsite.bodyTitleColor
                          : "#333",
                        fontSize: this.props.bodyTitleSize
                          ? this.props.bodyTitleSize
                          : legalsite && legalsite.bodyTitleSize
                          ? legalsite.bodyTitleSize
                          : 36,
                        fontFamily: this.props.bodyTitleFont
                          ? this.props.bodyTitleFont
                          : legalsite && legalsite.bodyTitleFont
                          ? legalsite.bodyTitleFont
                          : "Bookmania Semibold",
                        textAlign: this.props.bodyTitleAlign
                          ? this.props.bodyTitleAlign
                          : legalsite && legalsite.bodyTitleAlign !== null
                          ? legalsite.bodyTitleAlign
                          : null,
                        fontWeight:
                          this.props.bodyTitleBold !== null
                            ? this.props.bodyTitleBold === true
                              ? 900
                              : 500
                            : legalsite && legalsite.bodyTitleBold !== null
                            ? legalsite.bodyTitleBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          this.props.bodyTitleAlign !== null
                            ? this.props.bodyTitleAlign === true
                              ? "italic"
                              : "normal"
                            : legalsite && legalsite.bodyTitleItalic !== null
                            ? legalsite.bodyTitleItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      Manage your information
                    </h3>
                    <p
                      className="modal-preview__info-details"
                      style={{
                        color: this.props.bodyTextColor
                          ? this.props.bodyTextColor
                          : legalsite && legalsite.bodyTextColor
                          ? legalsite.bodyTextColor
                          : "#969696",
                        fontSize: this.props.bodyTextSize
                          ? this.props.bodyTextSize
                          : legalsite && legalsite.bodyTextSize
                          ? legalsite.bodyTextSize
                          : null,
                        fontFamily: this.props.bodyTextFont
                          ? this.props.bodyTextFont
                          : legalsite && legalsite.bodyTextFont
                          ? legalsite.bodyTextFont
                          : null,
                        textAlign: this.props.bodyTextAlign
                          ? this.props.bodyTextAlign
                          : legalsite && legalsite.bodyTextAlign !== null
                          ? legalsite.bodyTextAlign
                          : null,
                        fontWeight:
                          this.props.bodyTextBold !== null
                            ? this.props.bodyTextBold === true
                              ? 900
                              : 500
                            : legalsite && legalsite.bodyTextBold !== null
                            ? legalsite.bodyTextBold === true
                              ? 900
                              : 500
                            : 500,

                        fontStyle:
                          this.props.bodyTextItalic !== null
                            ? this.props.bodyTextItalic === true
                              ? "italic"
                              : "normal"
                            : legalsite && legalsite.bodyTextItalic !== null
                            ? legalsite.bodyTextItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      When you interact with our service, we probably manage
                      information about you. You have the right to understand
                      and manage how this information is handled by us.
                    </p>
                  </Fragment>

                  <div className="d-flex justify-content-center align-items-center pb-3 mb-5">
                    <div className="d-flex flex-column align-items-center justify-content-center modal-preview__info-details-item">
                      <ChatBubble
                        color={
                          this.props.bodyIconsColor
                            ? this.props.bodyIconsColor
                            : legalsite && legalsite.bodyIconsColor
                            ? legalsite.bodyIconsColor
                            : "#7450c8"
                        }
                      />
                      <p
                        className="modal-preview__info-title mt-4"
                        style={{
                          color: this.props.bodySubtitleColor
                            ? this.props.bodySubtitleColor
                            : legalsite && legalsite.bodySubtitleColor
                            ? legalsite.bodySubtitleColor
                            : "#333",
                          fontSize: this.props.bodySubtitleSize
                            ? this.props.bodySubtitleSize
                            : legalsite && legalsite.bodySubtitleSize
                            ? legalsite.bodySubtitleSize
                            : null,
                          fontFamily: this.props.bodySubtitleFont
                            ? this.props.bodySubtitleFont
                            : legalsite && legalsite.bodySubtitleFont
                            ? legalsite.bodySubtitleFont
                            : null,
                          textAlign: this.props.bodySubtitleAlign
                            ? this.props.bodySubtitleAlign
                            : legalsite && legalsite.bodySubtitleAlign !== null
                            ? legalsite.bodySubtitleAlign
                            : null,
                          fontWeight:
                            this.props.bodySubtitleBold !== null
                              ? this.props.bodySubtitleBold === true
                                ? 900
                                : 500
                              : legalsite && legalsite.bodySubtitleBold !== null
                              ? legalsite.bodySubtitleBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            this.props.bodySubtitleItalic !== null
                              ? this.props.bodySubtitleItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite &&
                                legalsite.bodySubtitleItalic !== null
                              ? legalsite.bodySubtitleItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Requests
                      </p>
                      <p
                        className="modal-preview__info-text"
                        style={{
                          color: this.props.bodyTextColor
                            ? this.props.bodyTextColor
                            : legalsite && legalsite.bodyTextColor
                            ? legalsite.bodyTextColor
                            : "#969696",
                          fontSize: this.props.bodyTextSize
                            ? this.props.bodyTextSize
                            : legalsite && legalsite.bodyTextSize
                            ? legalsite.bodyTextSize
                            : null,
                          fontFamily: this.props.bodyTextFont
                            ? this.props.bodyTextFont
                            : legalsite && legalsite.bodyTextFont
                            ? legalsite.bodyTextFont
                            : null,
                          textAlign: this.props.bodyTextAlign
                            ? this.props.bodyTextAlign
                            : legalsite && legalsite.bodyTextAlign !== null
                            ? legalsite.bodyTextAlign
                            : null,
                          fontWeight:
                            this.props.bodyTextBold !== null
                              ? this.props.bodyTextBold === true
                                ? 900
                                : 500
                              : legalsite && legalsite.bodyTextBold !== null
                              ? legalsite.bodyTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            this.props.bodyTextItalic !== null
                              ? this.props.bodyTextItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite && legalsite.bodyTextItalic !== null
                              ? legalsite.bodyTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Send data requests, questions or complaints
                      </p>
                      <Button
                        className="customization-site__btn"
                        style={{
                          color: this.props.bodyBtnColor
                            ? this.props.bodyBtnColor
                            : legalsite && legalsite.bodyBtnColor
                            ? legalsite.bodyBtnColor
                            : "#fff",
                          fontSize: this.props.bodyBtnSize
                            ? this.props.bodyBtnSize
                            : legalsite && legalsite.bodyBtnSize
                            ? legalsite.bodyBtnSize
                            : null,
                          fontFamily: this.props.bodyBtnFont
                            ? this.props.bodyBtnFont
                            : legalsite && legalsite.bodyBtnFont
                            ? legalsite.bodyBtnFont
                            : "Roboto",
                          textAlign: this.props.bodyBtnAlign
                            ? this.props.bodyBtnAlign
                            : legalsite && legalsite.bodyBtnAlign
                            ? legalsite.bodyBtnAlign
                            : null,
                          backgroundColor: this.props.bodyBtnBg
                            ? this.props.bodyBtnBg
                            : legalsite && legalsite.bodyBtnBg
                            ? legalsite.bodyBtnBg
                            : "#7450C8",
                          fontWeight:
                            this.props.bodyBtnBold !== null
                              ? this.props.bodyBtnBold === true
                                ? 900
                                : 500
                              : legalsite && legalsite.bodyBtnBold
                              ? legalsite.bodyBtnBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            this.props.bodyBtnItalic !== null
                              ? this.props.bodyBtnItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite && legalsite.bodyBtnItalic
                              ? legalsite.bodyBtnItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Request
                      </Button>
                    </div>

                    {activeConsents.length ? (
                      <div className="d-flex flex-column align-items-center justify-content-center modal-preview__info-details-item">
                        <Consent
                          color={
                            this.props.bodyIconsColor
                              ? this.props.bodyIconsColor
                              : legalsite && legalsite.bodyIconsColor
                              ? legalsite.bodyIconsColor
                              : "#7450c8"
                          }
                        />
                        <p
                          className="modal-preview__info-title mt-4"
                          style={{
                            color: this.props.bodySubtitleColor
                              ? this.props.bodySubtitleColor
                              : legalsite && legalsite.bodySubtitleColor
                              ? legalsite.bodySubtitleColor
                              : "#333",
                            fontSize: this.props.bodySubtitleSize
                              ? this.props.bodySubtitleSize
                              : legalsite && legalsite.bodySubtitleSize
                              ? legalsite.bodySubtitleSize
                              : null,
                            fontFamily: this.props.bodySubtitleFont
                              ? this.props.bodySubtitleFont
                              : legalsite && legalsite.bodySubtitleFont
                              ? legalsite.bodySubtitleFont
                              : null,
                            textAlign: this.props.bodySubtitleAlign
                              ? this.props.bodySubtitleAlign
                              : legalsite &&
                                legalsite.bodySubtitleAlign !== null
                              ? legalsite.bodySubtitleAlign
                              : null,
                            fontWeight:
                              this.props.bodySubtitleBold !== null
                                ? this.props.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : legalsite &&
                                  legalsite.bodySubtitleBold !== null
                                ? legalsite.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.bodySubtitleItalic !== null
                                ? this.props.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.bodySubtitleItalic !== null
                                ? legalsite.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Manage Consent
                        </p>
                        <p
                          className="modal-preview__info-text"
                          style={{
                            color: this.props.bodyTextColor
                              ? this.props.bodyTextColor
                              : legalsite && legalsite.bodyTextColor
                              ? legalsite.bodyTextColor
                              : "#969696",
                            fontSize: this.props.bodyTextSize
                              ? this.props.bodyTextSize
                              : legalsite && legalsite.bodyTextSize
                              ? legalsite.bodyTextSize
                              : null,
                            fontFamily: this.props.bodyTextFont
                              ? this.props.bodyTextFont
                              : legalsite && legalsite.bodyTextFont
                              ? legalsite.bodyTextFont
                              : null,
                            textAlign: this.props.bodyTextAlign
                              ? this.props.bodyTextAlign
                              : legalsite && legalsite.bodyTextAlign !== null
                              ? legalsite.bodyTextAlign
                              : null,
                            fontWeight:
                              this.props.bodyTextBold !== null
                                ? this.props.bodyTextBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.bodyTextBold !== null
                                ? legalsite.bodyTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.bodyTextItalic !== null
                                ? this.props.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite && legalsite.bodyTextItalic !== null
                                ? legalsite.bodyTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Review your consent settings here
                        </p>
                        <Button
                          className="customization-site__btn"
                          style={{
                            color: this.props.bodyBtnColor
                              ? this.props.bodyBtnColor
                              : legalsite && legalsite.bodyBtnColor
                              ? legalsite.bodyBtnColor
                              : "#fff",
                            fontSize: this.props.bodyBtnSize
                              ? this.props.bodyBtnSize
                              : legalsite && legalsite.bodyBtnSize
                              ? legalsite.bodyBtnSize
                              : null,
                            fontFamily: this.props.bodyBtnFont
                              ? this.props.bodyBtnFont
                              : legalsite && legalsite.bodyBtnFont
                              ? legalsite.bodyBtnFont
                              : "Roboto",
                            textAlign: this.props.bodyBtnAlign
                              ? this.props.bodyBtnAlign
                              : legalsite && legalsite.bodyBtnAlign
                              ? legalsite.bodyBtnAlign
                              : null,
                            backgroundColor: this.props.bodyBtnBg
                              ? this.props.bodyBtnBg
                              : legalsite && legalsite.bodyBtnBg
                              ? legalsite.bodyBtnBg
                              : "#7450C8",
                            fontWeight:
                              this.props.bodyBtnBold !== null
                                ? this.props.bodyBtnBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.bodyBtnBold
                                ? legalsite.bodyBtnBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.bodyBtnItalic !== null
                                ? this.props.bodyBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite && legalsite.bodyBtnItalic
                                ? legalsite.bodyBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Review
                        </Button>
                      </div>
                    ) : null}
                  </div>

                  <div className="pb-3">
                    {pages
                      ? pages.map((item) =>
                          item.applicable &&
                          item.title !== "Terms & Conditions" &&
                          item.title !== "Privacy Policy" ? (
                            <div
                              key={item.id}
                              className="customization-site__item"
                              style={{
                                background: this.props.cardsBg
                                  ? this.props.cardsBg
                                  : legalsite && legalsite.cardsBg
                                  ? legalsite.cardsBg
                                  : "#fafafa",
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <FileCheck
                                  color={
                                    this.props.cardsIconsColor
                                      ? this.props.cardsIconsColor
                                      : legalsite && legalsite.cardsIconsColor
                                      ? legalsite.cardsIconsColor
                                      : "#7450c8"
                                  }
                                  className="mr-1"
                                />

                                <p
                                  className="customization-site__item-title"
                                  style={{
                                    color: this.props.cardsTitleColor
                                      ? this.props.cardsTitleColor
                                      : legalsite && legalsite.cardsTitleColor
                                      ? legalsite.cardsTitleColor
                                      : "#333",
                                    fontSize: this.props.cardsTitleSize
                                      ? this.props.cardsTitleSize
                                      : legalsite && legalsite.cardsTitleSize
                                      ? legalsite.cardsTitleSize
                                      : null,
                                    fontFamily: this.props.cardsTitleFont
                                      ? this.props.cardsTitleFont
                                      : legalsite && legalsite.cardsTitleFont
                                      ? legalsite.cardsTitleFont
                                      : null,
                                    textAlign: this.props.cardsTitleAlign
                                      ? this.props.cardsTitleAlign
                                      : legalsite &&
                                        legalsite.cardsTitleAlign !== null
                                      ? legalsite.cardsTitleAlign
                                      : null,
                                    fontWeight:
                                      this.props.cardsTitleBold !== null
                                        ? this.props.cardsTitleBold === true
                                          ? 900
                                          : 500
                                        : legalsite &&
                                          legalsite.cardsTitleBold !== null
                                        ? legalsite.cardsTitleBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.cardsTitleItalic !== null
                                        ? this.props.cardsTitleItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.cardsTitleItalic !== null
                                        ? legalsite.cardsTitleItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  {item.title}
                                </p>
                              </div>
                              <p
                                className="customization-site__item-text"
                                style={{
                                  color: this.props.cardsBodyColor
                                    ? this.props.cardsBodyColor
                                    : legalsite && legalsite.cardsBodyColor
                                    ? legalsite.cardsBodyColor
                                    : "#969696",
                                  fontSize: this.props.cardsBodySize
                                    ? this.props.cardsBodySize
                                    : legalsite && legalsite.cardsBodySize
                                    ? legalsite.cardsBodySize
                                    : null,
                                  fontFamily: this.props.cardsBodyFont
                                    ? this.props.cardsBodyFont
                                    : legalsite && legalsite.cardsBodyFont
                                    ? legalsite.cardsBodyFont
                                    : null,
                                  textAlign: this.props.cardsBodyAlign
                                    ? this.props.cardsBodyAlign
                                    : legalsite &&
                                      legalsite.cardsBodyAlign !== null
                                    ? legalsite.cardsBodyAlign
                                    : null,
                                  fontWeight:
                                    this.props.cardsBodyBold !== null
                                      ? this.props.cardsBodyBold === true
                                        ? 900
                                        : 500
                                      : legalsite &&
                                        legalsite.cardsBodyBold !== null
                                      ? legalsite.cardsBodyBold === true
                                        ? 900
                                        : 500
                                      : 500,
                                  fontStyle:
                                    this.props.cardsBodyItalic !== null
                                      ? this.props.cardsBodyItalic === true
                                        ? "italic"
                                        : "normal"
                                      : legalsite &&
                                        legalsite.cardsBodyItalic !== null
                                      ? legalsite.cardsBodyItalic === true
                                        ? "italic"
                                        : "normal"
                                      : "normal",
                                }}
                              >
                                {item.description}
                              </p>

                              <Button
                                style={{
                                  fontSize: this.props.cardsBtnSize
                                    ? this.props.cardsBtnSize
                                    : legalsite && legalsite.cardsBtnSize
                                    ? legalsite.cardsBtnSize
                                    : null,
                                  fontFamily: this.props.cardsBtnFont
                                    ? this.props.cardsBtnFont
                                    : legalsite && legalsite.cardsBtnFont
                                    ? legalsite.cardsBtnFont
                                    : null,
                                  color: this.props.cardsBtnColor
                                    ? this.props.cardsBtnColor
                                    : legalsite && legalsite.cardsBtnColor
                                    ? legalsite.cardsBtnColor
                                    : "#fff",
                                  textAlign: this.props.cardsBtnAlign
                                    ? this.props.cardsBtnAlign
                                    : legalsite &&
                                      legalsite.cardsBtnAlign !== null
                                    ? legalsite.cardsBtnAlign
                                    : null,
                                  fontWeight:
                                    this.props.cardsBtnBold !== null
                                      ? this.props.cardsBtnBold === true
                                        ? 900
                                        : 500
                                      : legalsite &&
                                        legalsite.cardsBtnBold !== null
                                      ? legalsite.cardsBtnBold === true
                                        ? 900
                                        : 500
                                      : 500,
                                  backgroundColor: this.props.cardsBtnBg
                                    ? this.props.cardsBtnBg
                                    : legalsite && legalsite.cardsBtnBg
                                    ? legalsite.cardsBtnBg
                                    : "#7450C8",
                                  fontStyle:
                                    this.props.cardsBtnItalic !== null
                                      ? this.props.cardsBtnItalic === true
                                        ? "italic"
                                        : "normal"
                                      : legalsite &&
                                        legalsite.cardsBtnItalic !== null
                                      ? legalsite.cardsBtnItalic === true
                                        ? "italic"
                                        : "normal"
                                      : "normal",
                                }}
                                className="customization-site__btn"
                              >
                                See more
                              </Button>
                            </div>
                          ) : null
                        )
                      : null}

                    {(this.props.dataProtectionOfficer &&
                      this.props.dataProtectionOfficer.show) ||
                    (account && account.showLegalInformation) ? (
                      <div
                        className="customization-site__item"
                        style={{
                          background: this.props.cardsBg
                            ? this.props.cardsBg
                            : legalsite && legalsite.cardsBg
                            ? legalsite.cardsBg
                            : "#fafafa",
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <UsersIcon
                            color={
                              this.props.cardsIconsColor
                                ? this.props.cardsIconsColor
                                : legalsite && legalsite.cardsIconsColor
                                ? legalsite.cardsIconsColor
                                : "#7450c8"
                            }
                            className="mr-1"
                          />

                          <p
                            className="customization-site__item-title"
                            style={{
                              color: this.props.cardsTitleColor
                                ? this.props.cardsTitleColor
                                : legalsite && legalsite.cardsTitleColor
                                ? legalsite.cardsTitleColor
                                : "#333",
                              fontSize: this.props.cardsTitleSize
                                ? this.props.cardsTitleSize
                                : legalsite && legalsite.cardsTitleSize
                                ? legalsite.cardsTitleSize
                                : null,
                              fontFamily: this.props.cardsTitleFont
                                ? this.props.cardsTitleFont
                                : legalsite && legalsite.cardsTitleFont
                                ? legalsite.cardsTitleFont
                                : null,
                              textAlign: this.props.cardsTitleAlign
                                ? this.props.cardsTitleAlign
                                : legalsite &&
                                  legalsite.cardsTitleAlign !== null
                                ? legalsite.cardsTitleAlign
                                : null,
                              fontWeight:
                                this.props.cardsTitleBold !== null
                                  ? this.props.cardsTitleBold === true
                                    ? 900
                                    : 500
                                  : legalsite &&
                                    legalsite.cardsTitleBold !== null
                                  ? legalsite.cardsTitleBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.cardsTitleItalic !== null
                                  ? this.props.cardsTitleItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.cardsTitleItalic !== null
                                  ? legalsite.cardsTitleItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Legal Information
                          </p>
                        </div>
                        <p
                          className="customization-site__item-text"
                          style={{
                            color: this.props.cardsBodyColor
                              ? this.props.cardsBodyColor
                              : legalsite && legalsite.cardsBodyColor
                              ? legalsite.cardsBodyColor
                              : "#969696",
                            fontSize: this.props.cardsBodySize
                              ? this.props.cardsBodySize
                              : legalsite && legalsite.cardsBodySize
                              ? legalsite.cardsBodySize
                              : null,
                            fontFamily: this.props.cardsBodyFont
                              ? this.props.cardsBodyFont
                              : legalsite && legalsite.cardsBodyFont
                              ? legalsite.cardsBodyFont
                              : null,
                            textAlign: this.props.cardsBodyAlign
                              ? this.props.cardsBodyAlign
                              : legalsite && legalsite.cardsBodyAlign !== null
                              ? legalsite.cardsBodyAlign
                              : null,
                            fontWeight:
                              this.props.cardsBodyBold !== null
                                ? this.props.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.cardsBodyBold !== null
                                ? legalsite.cardsBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.cardsBodyItalic !== null
                                ? this.props.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.cardsBodyItalic !== null
                                ? legalsite.cardsBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Here you can find our Legal Information
                        </p>

                        <Button
                          className="customization-site__btn"
                          style={{
                            fontSize: this.props.cardsBtnSize
                              ? this.props.cardsBtnSize
                              : legalsite && legalsite.cardsBtnSize
                              ? legalsite.cardsBtnSize
                              : null,
                            fontFamily: this.props.cardsBtnFont
                              ? this.props.cardsBtnFont
                              : legalsite && legalsite.cardsBtnFont
                              ? legalsite.cardsBtnFont
                              : null,
                            color: this.props.cardsBtnColor
                              ? this.props.cardsBtnColor
                              : legalsite && legalsite.cardsBtnColor
                              ? legalsite.cardsBtnColor
                              : "#fff",
                            textAlign: this.props.cardsBtnAlign
                              ? this.props.cardsBtnAlign
                              : legalsite && legalsite.cardsBtnAlign !== null
                              ? legalsite.cardsBtnAlign
                              : null,
                            fontWeight:
                              this.props.cardsBtnBold !== null
                                ? this.props.cardsBtnBold === true
                                  ? 900
                                  : 500
                                : legalsite && legalsite.cardsBtnBold !== null
                                ? legalsite.cardsBtnBold === true
                                  ? 900
                                  : 500
                                : 500,
                            backgroundColor: this.props.cardsBtnBg
                              ? this.props.cardsBtnBg
                              : legalsite && legalsite.cardsBtnBg
                              ? legalsite.cardsBtnBg
                              : "#7450C8",
                            fontStyle:
                              this.props.cardsBtnItalic !== null
                                ? this.props.cardsBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite && legalsite.cardsBtnItalic !== null
                                ? legalsite.cardsBtnItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          See more
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div
                  className="footer"
                  style={{
                    background: this.props.footerBg
                      ? this.props.footerBg
                      : legalsite && legalsite.footerBg
                      ? legalsite.footerBg
                      : "#dfe4ee",
                  }}
                >
                  <div
                    className="footer__container"
                    style={{
                      background: this.props.footerBg
                        ? this.props.footerBg
                        : legalsite && legalsite.footerBg
                        ? legalsite.footerBg
                        : "#dfe4ee",
                    }}
                  >
                    <Row className="w-100">
                      <Col sm={12} md={3} className="d-flex flex-column">
                        <span className="d-flex align-items-center text-decoration-none">
                          {this.props.legalsiteSettings &&
                          this.props.legalsiteSettings.companyLogo &&
                          legalsite &&
                          !legalsite.footerLogo ? (
                            <div className="footer__logo-container">
                              <img
                                src={this.props.legalsiteSettings.companyLogo}
                                alt="logo"
                              />
                            </div>
                          ) : legalsite && legalsite.footerLogo ? (
                            <div className="footer__logo-container">
                              <img src={legalsite.footerLogo} alt="logo" />
                            </div>
                          ) : (
                            <span className="footer__logo" />
                          )}
                        </span>
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Website</strong>
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Homepage
                        </p>
                        <p
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.legalsite.co/"
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Go back to main website
                        </p>
                        {this.props.dataProtectionOfficer &&
                        this.props.dataProtectionOfficer.show ? (
                          <p
                            className="mb-4"
                            style={{
                              color: this.props.footerLinkColor
                                ? this.props.footerLinkColor
                                : legalsite && legalsite.footerLinksColor
                                ? legalsite.footerLinksColor
                                : "#333",
                              fontSize: this.props.footerLinkSize
                                ? this.props.footerLinkSize
                                : legalsite && legalsite.footerLinksSize
                                ? legalsite.footerLinksSize
                                : null,
                              fontFamily: this.props.footerLinkFont
                                ? this.props.footerLinkFont
                                : legalsite && legalsite.footerLinksFont
                                ? legalsite.footerLinksFont
                                : null,
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Legal Information
                          </p>
                        ) : null}
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Legal Pages</strong>
                        </p>
                        {pages
                          ? pages.map((item) =>
                              item.applicable ? (
                                <p
                                  className="mb-4"
                                  style={{
                                    color: this.props.footerLinkColor
                                      ? this.props.footerLinkColor
                                      : legalsite && legalsite.footerLinksColor
                                      ? legalsite.footerLinksColor
                                      : "#333",
                                    fontSize: this.props.footerLinkSize
                                      ? this.props.footerLinkSize
                                      : legalsite && legalsite.footerLinksSize
                                      ? legalsite.footerLinksSize
                                      : null,
                                    fontFamily: this.props.footerLinkFont
                                      ? this.props.footerLinkFont
                                      : legalsite && legalsite.footerLinksFont
                                      ? legalsite.footerLinksFont
                                      : null,
                                    textAlign: this.props.footerLinkAlign
                                      ? this.props.footerLinkAlign
                                      : legalsite &&
                                        legalsite.footerLinksAlign !== null
                                      ? legalsite.footerLinksAlign
                                      : null,
                                    fontWeight:
                                      this.props.footerLinkBold !== null
                                        ? this.props.footerLinkAlign === true
                                          ? 900
                                          : 300
                                        : legalsite &&
                                          legalsite.footerLinksBold !== null
                                        ? legalsite.footerLinksBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.footerLinkItalic !== null
                                        ? this.props.footerLinkItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.footerLinksItalic !== null
                                        ? legalsite.footerLinksItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                  key={item.id}
                                >
                                  {item.title}
                                </p>
                              ) : null
                            )
                          : null}
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Manage your information</strong>
                        </p>
                        {activeConsents.length ? (
                          <p
                            className="mb-4"
                            style={{
                              color: this.props.footerLinkColor
                                ? this.props.footerLinkColor
                                : legalsite && legalsite.footerLinksColor
                                ? legalsite.footerLinksColor
                                : "#333",
                              fontSize: this.props.footerLinkSize
                                ? this.props.footerLinkSize
                                : legalsite && legalsite.footerLinksSize
                                ? legalsite.footerLinksSize
                                : null,
                              fontFamily: this.props.footerLinkFont
                                ? this.props.footerLinkFont
                                : legalsite && legalsite.footerLinksFont
                                ? legalsite.footerLinksFont
                                : null,
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Manage Consent
                          </p>
                        ) : null}
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Send request
                        </p>
                        <div
                          className={`d-flex ${
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign === "center"
                                ? "justify-content-center"
                                : legalsite.footerLinksAlign === "left"
                                ? "justify-content-start"
                                : "justify-content-end"
                              : null
                          }`}
                        >
                          <p
                            className={`${
                              this.props.btnType
                                ? this.props.btnType === "contained"
                                  ? "-is-contained"
                                  : ""
                                : (dontSellMyDataButton &&
                                    dontSellMyDataButton.buttonType ===
                                      "contained") ||
                                  (dontSellMyDataButton &&
                                    !dontSellMyDataButton.buttonType)
                                ? "-is-contained"
                                : ""
                            }`}
                            style={{
                              background: this.props.btnType
                                ? this.props.btnType === "contained"
                                  ? this.props.sellBg
                                  : ""
                                : (dontSellMyDataButton &&
                                    dontSellMyDataButton.buttonType ===
                                      "contained") ||
                                  (dontSellMyDataButton &&
                                    !dontSellMyDataButton.buttonType)
                                ? dontSellMyDataButton &&
                                  dontSellMyDataButton.color
                                  ? dontSellMyDataButton.color
                                  : "#7450c8"
                                : null,
                              color: this.props.sellBtnColor
                                ? this.props.sellBtnColor
                                : dontSellMyDataButton &&
                                  dontSellMyDataButton.textColor
                                ? dontSellMyDataButton.textColor
                                : "#fff",
                              fontSize: this.props.sellBtnSize
                                ? this.props.sellBtnSize
                                : dontSellMyDataButton &&
                                  dontSellMyDataButton.textSize
                                ? parseInt(dontSellMyDataButton.textSize)
                                : 14,
                              fontFamily: this.props.sellBtnFont
                                ? this.props.sellBtnFont
                                : dontSellMyDataButton &&
                                  dontSellMyDataButton.textFont
                                ? dontSellMyDataButton.textFont
                                : "Roboto",
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Do Not Sell My Info
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="footer-text"
                    style={{ background: welcomeBg ? welcomeBg : "#dfe4ee" }}
                  >
                    <span>
                      <span
                        style={{
                          color: this.props.footerTextColor
                            ? this.props.footerTextColor
                            : legalsite && legalsite.footerTextColor
                            ? legalsite.footerTextColor
                            : "#333",
                          fontSize: this.props.footerTextSize
                            ? this.props.footerTextSize
                            : legalsite && legalsite.footerTextSize
                            ? legalsite.footerTextSize
                            : null,
                          fontFamily: this.props.footerTextFont
                            ? this.props.footerTextFont
                            : legalsite && legalsite.footerTextFont
                            ? legalsite.footerTextFont
                            : null,
                          textAlign:
                            this.props.footerTextAlign !== null
                              ? this.props.footerTextAlign
                              : legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            this.props.footerTextBold !== null
                              ? this.props.footerTextBold === true
                                ? 900
                                : 300
                              : legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            this.props.footerTextItalic !== null
                              ? this.props.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        © {new Date().getFullYear()} LegalSite BV. All rights
                        reserved.
                      </span>{" "}
                      <span
                        style={{
                          color: this.props.footerLinkColor
                            ? this.props.footerLinkColor
                            : legalsite && legalsite.footerLinksColor
                            ? legalsite.footerLinksColor
                            : "#333",
                          fontSize: this.props.footerLinkSize
                            ? this.props.footerLinkSize
                            : legalsite && legalsite.footerLinksSize
                            ? legalsite.footerLinksSize
                            : null,
                          fontFamily: this.props.footerLinkFont
                            ? this.props.footerLinkFont
                            : legalsite && legalsite.footerLinksFont
                            ? legalsite.footerLinksFont
                            : null,
                          textAlign: this.props.footerLinkAlign
                            ? this.props.footerLinkAlign
                            : legalsite && legalsite.footerLinksAlign !== null
                            ? legalsite.footerLinksAlign
                            : null,
                          fontWeight:
                            this.props.footerLinkBold !== null
                              ? this.props.footerLinkAlign === true
                                ? 900
                                : 300
                              : legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            this.props.footerLinkItalic !== null
                              ? this.props.footerLinkItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite &&
                                legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <u>Get your LegalSite!</u>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="overflow-auto page-preview__popups-preview">
                <div
                  className="page-preview__header"
                  style={{
                    background:
                      legalsite && legalsite.headerBg
                        ? legalsite.headerBg
                        : "#dfe4ee",
                  }}
                >
                  <h5
                    className="page-preview__header-title"
                    style={{
                      color:
                        legalsite && legalsite.headerTitleColor
                          ? legalsite.headerTitleColor
                          : "#333",
                      fontSize:
                        legalsite && legalsite.headerTitleSize
                          ? legalsite.headerTitleSize
                          : 56,
                      fontFamily:
                        legalsite && legalsite.headerTitleFont
                          ? legalsite.headerTitleFont
                          : null,
                      textAlign:
                        legalsite && legalsite.headerTitleAlign !== null
                          ? legalsite.headerTitleAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.headerTitleBold !== null
                          ? legalsite.headerTitleBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.headerTitleItalic !== null
                          ? legalsite.headerTitleItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    {selectedPage.title}
                  </h5>
                  <p
                    className="page-preview__header-text"
                    style={{
                      color:
                        legalsite && legalsite.headerBodyColor
                          ? legalsite.headerBodyColor
                          : "#969696",
                      fontSize:
                        legalsite && legalsite.headerBodySize
                          ? legalsite.headerBodySize
                          : 16,
                      fontFamily:
                        legalsite && legalsite.headerBodyFont
                          ? legalsite.headerBodyFont
                          : null,
                      textAlign:
                        legalsite && legalsite.headerBodyAlign !== null
                          ? legalsite.headerBodyAlign
                          : null,
                      fontWeight:
                        legalsite && legalsite.headerBodyBold !== null
                          ? legalsite.headerBodyBold === true
                            ? 900
                            : 500
                          : 500,
                      fontStyle:
                        legalsite && legalsite.headerBodyItalic !== null
                          ? legalsite.headerBodyItalic === true
                            ? "italic"
                            : "normal"
                          : "normal",
                    }}
                  >
                    {selectedPage.description}
                  </p>
                </div>
                <div
                  className="page-preview__content"
                  style={{
                    background:
                      legalsite && legalsite.bodyBg
                        ? legalsite.bodyBg
                        : "#f4f6fc",
                  }}
                >
                  <div className="page-preview__content-menu">
                    {selectedPage.sections.map((item, index) =>
                      item.applicable ? (
                        <p
                          key={index}
                          className="page-preview__content-menu-item"
                          style={{
                            color:
                              legalsite && legalsite.bodySubtitleColor
                                ? legalsite.bodySubtitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.bodySubtitleSize
                                ? legalsite.bodySubtitleSize
                                : null,
                            fontFamily:
                              legalsite && legalsite.bodySubtitleFont
                                ? legalsite.bodySubtitleFont
                                : "Roboto",
                            textAlign:
                              legalsite && legalsite.bodySubtitleAlign !== null
                                ? legalsite.bodySubtitleAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.bodySubtitleBold !== null
                                ? legalsite.bodySubtitleBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              legalsite && legalsite.bodySubtitleItalic !== null
                                ? legalsite.bodySubtitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {item.title}
                        </p>
                      ) : null
                    )}
                  </div>
                  <div className="page-preview__content-container">
                    {selectedPage.sections.map((item, index) =>
                      item.applicable ? (
                        <div key={index}>
                          <p
                            className="page-preview__content-title"
                            style={{
                              color:
                                legalsite && legalsite.bodySubtitleColor
                                  ? legalsite.bodySubtitleColor
                                  : "#333",
                              fontSize:
                                legalsite && legalsite.bodySubtitleSize
                                  ? legalsite.bodySubtitleSize
                                  : null,
                              fontFamily:
                                legalsite && legalsite.bodySubtitleFont
                                  ? legalsite.bodySubtitleFont
                                  : "Roboto",
                              textAlign:
                                legalsite &&
                                legalsite.bodySubtitleAlign !== null
                                  ? legalsite.bodySubtitleAlign
                                  : null,
                              fontWeight:
                                legalsite && legalsite.bodySubtitleBold !== null
                                  ? legalsite.bodySubtitleBold === true
                                    ? 900
                                    : 500
                                  : 500,

                              fontStyle:
                                legalsite &&
                                legalsite.bodySubtitleItalic !== null
                                  ? legalsite.bodySubtitleItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            {item.title}
                          </p>
                          <p
                            className="page-preview__content-text"
                            style={{
                              color:
                                legalsite && legalsite.cardsBodyColor
                                  ? legalsite.cardsBodyColor
                                  : "#969696",
                              fontSize:
                                legalsite && legalsite.cardsBodySize
                                  ? legalsite.cardsBodySize
                                  : null,
                              fontFamily:
                                legalsite && legalsite.cardsBodyFont
                                  ? legalsite.cardsBodyFont
                                  : null,
                            }}
                          >
                            {ReactHtmlParser(item.text)}
                          </p>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>

                <div
                  className="footer"
                  style={{
                    background: this.props.footerBg
                      ? this.props.footerBg
                      : legalsite && legalsite.footerBg
                      ? legalsite.footerBg
                      : "#dfe4ee",
                  }}
                >
                  <div
                    className="footer__container"
                    style={{
                      background: this.props.footerBg
                        ? this.props.footerBg
                        : legalsite && legalsite.footerBg
                        ? legalsite.footerBg
                        : "#dfe4ee",
                    }}
                  >
                    <Row className="w-100">
                      <Col sm={12} md={3} className="d-flex flex-column">
                        <span className="d-flex align-items-center text-decoration-none">
                          {legalsite && legalsite.footerLogo ? (
                            <div className="footer__logo-container">
                              <img src={legalsite.footerLogo} alt="logo" />
                            </div>
                          ) : (
                            <span className="footer__logo" />
                          )}
                        </span>
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Website</strong>
                        </p>
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Homepage
                        </p>
                        <p
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://app.legalsite.co/"
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Go back to main website
                        </p>
                        {this.props.dataProtectionOfficer &&
                        this.props.dataProtectionOfficer.show ? (
                          <p
                            className="mb-4"
                            style={{
                              color: this.props.footerLinkColor
                                ? this.props.footerLinkColor
                                : legalsite && legalsite.footerLinksColor
                                ? legalsite.footerLinksColor
                                : "#333",
                              fontSize: this.props.footerLinkSize
                                ? this.props.footerLinkSize
                                : legalsite && legalsite.footerLinksSize
                                ? legalsite.footerLinksSize
                                : null,
                              fontFamily: this.props.footerLinkFont
                                ? this.props.footerLinkFont
                                : legalsite && legalsite.footerLinksFont
                                ? legalsite.footerLinksFont
                                : null,
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Legal Information
                          </p>
                        ) : null}
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Legal Pages</strong>
                        </p>
                        {pages
                          ? pages.map((item) =>
                              item.applicable ? (
                                <p
                                  className="mb-4"
                                  style={{
                                    color: this.props.footerLinkColor
                                      ? this.props.footerLinkColor
                                      : legalsite && legalsite.footerLinksColor
                                      ? legalsite.footerLinksColor
                                      : "#333",
                                    fontSize: this.props.footerLinkSize
                                      ? this.props.footerLinkSize
                                      : legalsite && legalsite.footerLinksSize
                                      ? legalsite.footerLinksSize
                                      : null,
                                    fontFamily: this.props.footerLinkFont
                                      ? this.props.footerLinkFont
                                      : legalsite && legalsite.footerLinksFont
                                      ? legalsite.footerLinksFont
                                      : null,
                                    textAlign: this.props.footerLinkAlign
                                      ? this.props.footerLinkAlign
                                      : legalsite &&
                                        legalsite.footerLinksAlign !== null
                                      ? legalsite.footerLinksAlign
                                      : null,
                                    fontWeight:
                                      this.props.footerLinkBold !== null
                                        ? this.props.footerLinkAlign === true
                                          ? 900
                                          : 300
                                        : legalsite &&
                                          legalsite.footerLinksBold !== null
                                        ? legalsite.footerLinksBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      this.props.footerLinkItalic !== null
                                        ? this.props.footerLinkItalic === true
                                          ? "italic"
                                          : "normal"
                                        : legalsite &&
                                          legalsite.footerLinksItalic !== null
                                        ? legalsite.footerLinksItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                  key={item.id}
                                >
                                  {item.title}
                                </p>
                              ) : null
                            )
                          : null}
                      </Col>

                      <Col sm={12} md={3} className="d-flex flex-column">
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerTextColor
                              ? this.props.footerTextColor
                              : legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                            fontSize: this.props.footerTextSize
                              ? this.props.footerTextSize
                              : legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                            fontFamily: this.props.footerTextFont
                              ? this.props.footerTextFont
                              : legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                            textAlign:
                              this.props.footerTextAlign !== null
                                ? this.props.footerTextAlign
                                : legalsite &&
                                  legalsite.footerTextAlign !== null
                                ? legalsite.footerTextAlign
                                : null,
                            fontWeight:
                              this.props.footerTextBold !== null
                                ? this.props.footerTextBold === true
                                  ? 900
                                  : 300
                                : legalsite && legalsite.footerTextBold !== null
                                ? legalsite.footerTextBold === true
                                  ? 900
                                  : 500
                                : 500,

                            fontStyle:
                              this.props.footerTextItalic !== null
                                ? this.props.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerTextItalic !== null
                                ? legalsite.footerTextItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          <strong>Manage your information</strong>
                        </p>
                        {activeConsents.length ? (
                          <p
                            className="mb-4"
                            style={{
                              color: this.props.footerLinkColor
                                ? this.props.footerLinkColor
                                : legalsite && legalsite.footerLinksColor
                                ? legalsite.footerLinksColor
                                : "#333",
                              fontSize: this.props.footerLinkSize
                                ? this.props.footerLinkSize
                                : legalsite && legalsite.footerLinksSize
                                ? legalsite.footerLinksSize
                                : null,
                              fontFamily: this.props.footerLinkFont
                                ? this.props.footerLinkFont
                                : legalsite && legalsite.footerLinksFont
                                ? legalsite.footerLinksFont
                                : null,
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Manage Consent
                          </p>
                        ) : null}
                        <p
                          className="mb-4"
                          style={{
                            color: this.props.footerLinkColor
                              ? this.props.footerLinkColor
                              : legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                            fontSize: this.props.footerLinkSize
                              ? this.props.footerLinkSize
                              : legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                            fontFamily: this.props.footerLinkFont
                              ? this.props.footerLinkFont
                              : legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Send request
                        </p>
                        <div
                          className={`d-flex ${
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign === "center"
                                ? "justify-content-center"
                                : legalsite.footerLinksAlign === "left"
                                ? "justify-content-start"
                                : "justify-content-end"
                              : null
                          }`}
                        >
                          <p
                            className={`${
                              this.props.btnType === "contained" ||
                              (dontSellMyDataButton &&
                                dontSellMyDataButton.buttonType ===
                                  "contained") ||
                              (dontSellMyDataButton &&
                                !dontSellMyDataButton.buttonType)
                                ? "-is-contained"
                                : ""
                            }`}
                            style={{
                              background:
                                this.props.btnType === "contained" ||
                                (dontSellMyDataButton &&
                                  dontSellMyDataButton.buttonType ===
                                    "contained") ||
                                (dontSellMyDataButton &&
                                  !dontSellMyDataButton.buttonType)
                                  ? dontSellMyDataButton &&
                                    dontSellMyDataButton.color
                                    ? dontSellMyDataButton.color
                                    : "#7450c8"
                                  : "#7450c8",
                              color:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textColor
                                  ? dontSellMyDataButton.textColor
                                  : "#fff",
                              fontSize:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textSize
                                  ? parseInt(dontSellMyDataButton.textSize)
                                  : 14,
                              fontFamily:
                                dontSellMyDataButton &&
                                dontSellMyDataButton.textFont
                                  ? dontSellMyDataButton.textFont
                                  : "Roboto",
                              textAlign: this.props.footerLinkAlign
                                ? this.props.footerLinkAlign
                                : legalsite &&
                                  legalsite.footerLinksAlign !== null
                                ? legalsite.footerLinksAlign
                                : null,
                              fontWeight:
                                this.props.footerLinkBold !== null
                                  ? this.props.footerLinkAlign === true
                                    ? 900
                                    : 300
                                  : legalsite &&
                                    legalsite.footerLinksBold !== null
                                  ? legalsite.footerLinksBold === true
                                    ? 900
                                    : 500
                                  : 500,
                              fontStyle:
                                this.props.footerLinkItalic !== null
                                  ? this.props.footerLinkItalic === true
                                    ? "italic"
                                    : "normal"
                                  : legalsite &&
                                    legalsite.footerLinksItalic !== null
                                  ? legalsite.footerLinksItalic === true
                                    ? "italic"
                                    : "normal"
                                  : "normal",
                            }}
                          >
                            Do Not Sell My Info
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div
                    className="footer-text"
                    style={{ background: welcomeBg ? welcomeBg : "#dfe4ee" }}
                  >
                    <span>
                      <span
                        style={{
                          color: this.props.footerTextColor
                            ? this.props.footerTextColor
                            : legalsite && legalsite.footerTextColor
                            ? legalsite.footerTextColor
                            : "#333",
                          fontSize: this.props.footerTextSize
                            ? this.props.footerTextSize
                            : legalsite && legalsite.footerTextSize
                            ? legalsite.footerTextSize
                            : null,
                          fontFamily: this.props.footerTextFont
                            ? this.props.footerTextFont
                            : legalsite && legalsite.footerTextFont
                            ? legalsite.footerTextFont
                            : null,
                          textAlign:
                            this.props.footerTextAlign !== null
                              ? this.props.footerTextAlign
                              : legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            this.props.footerTextBold !== null
                              ? this.props.footerTextBold === true
                                ? 900
                                : 300
                              : legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            this.props.footerTextItalic !== null
                              ? this.props.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        © {new Date().getFullYear()} LegalSite BV. All rights
                        reserved.
                      </span>{" "}
                      <span
                        style={{
                          color: this.props.footerLinkColor
                            ? this.props.footerLinkColor
                            : legalsite && legalsite.footerLinksColor
                            ? legalsite.footerLinksColor
                            : "#333",
                          fontSize: this.props.footerLinkSize
                            ? this.props.footerLinkSize
                            : legalsite && legalsite.footerLinksSize
                            ? legalsite.footerLinksSize
                            : null,
                          fontFamily: this.props.footerLinkFont
                            ? this.props.footerLinkFont
                            : legalsite && legalsite.footerLinksFont
                            ? legalsite.footerLinksFont
                            : null,
                          textAlign: this.props.footerLinkAlign
                            ? this.props.footerLinkAlign
                            : legalsite && legalsite.footerLinksAlign !== null
                            ? legalsite.footerLinksAlign
                            : null,
                          fontWeight:
                            this.props.footerLinkBold !== null
                              ? this.props.footerLinkAlign === true
                                ? 900
                                : 300
                              : legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            this.props.footerLinkItalic !== null
                              ? this.props.footerLinkItalic === true
                                ? "italic"
                                : "normal"
                              : legalsite &&
                                legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <u>Get your LegalSite!</u>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}

            {!isSiteCustomization && !isSell ? (
              <div
                className={`${
                  popupSide === "left" && templateActive === 1
                    ? "-is-left"
                    : popupSide === "right" && templateActive === 1
                    ? "-is-right"
                    : popupPosition === "bottom" && templateActive === 2
                    ? "-is-bottom"
                    : popupPosition === "top" && templateActive === 2
                    ? "-is-top"
                    : (popupOverlay === "light" && templateActive === 3) ||
                      (popupOverlay === "light" && templateActive === 4)
                    ? "-is-white"
                    : "-is-gray"
                } modal-preview__cookie-popup`}
              >
                <div
                  style={
                    templateActive !== 4
                      ? { background: cookieBg ? cookieBg : "#fafafa" }
                      : null
                  }
                  className={`${
                    templateActive === 1
                      ? "customization-cookie__small"
                      : templateActive === 2
                      ? "customization-cookie__vertical"
                      : templateActive === 3
                      ? "customization-cookie__overlay"
                      : "customization-cookie__overlay customization-cookie__view"
                  } ${
                    selectedPage === "all" ? "customization-cookie__is-all" : ""
                  } position-relative`}
                >
                  {templateActive !== 4 ? (
                    <MdClose
                      className="modal-preview__cookie-close"
                      color={"#969696"}
                      size={20}
                      onClick={this.props.showPreviewModal}
                    />
                  ) : null}
                  {selectedPage !== "all" && templateActive === 4 ? (
                    <div className="page-view__container">
                      <div
                        className="page-view__header"
                        style={{
                          background:
                            legalsite && legalsite.headerBg
                              ? legalsite.headerBg
                              : "#dfe4ee",
                        }}
                      >
                        <p
                          className="page-view__title"
                          style={{
                            color:
                              legalsite && legalsite.headerTitleColor
                                ? legalsite.headerTitleColor
                                : "#333",
                            fontSize:
                              legalsite && legalsite.headerTitleSize
                                ? legalsite.headerTitleSize
                                : 56,
                            fontFamily:
                              legalsite && legalsite.headerTitleFont
                                ? legalsite.headerTitleFont
                                : null,
                            textAlign:
                              legalsite && legalsite.headerTitleAlign !== null
                                ? legalsite.headerTitleAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.headerTitleBold !== null
                                ? legalsite.headerTitleBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.headerTitleItalic !== null
                                ? legalsite.headerTitleItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {selectedPage.title}
                        </p>
                        <p
                          className="page-view__subtitle"
                          style={{
                            color:
                              legalsite && legalsite.headerBodyColor
                                ? legalsite.headerBodyColor
                                : "#969696",
                            fontSize:
                              legalsite && legalsite.headerBodySize
                                ? legalsite.headerBodySize
                                : 16,
                            fontFamily:
                              legalsite && legalsite.headerBodyFont
                                ? legalsite.headerBodyFont
                                : null,
                            textAlign:
                              legalsite && legalsite.headerBodyAlign !== null
                                ? legalsite.headerBodyAlign
                                : null,
                            fontWeight:
                              legalsite && legalsite.headerBodyBold !== null
                                ? legalsite.headerBodyBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              legalsite && legalsite.headerBodyItalic !== null
                                ? legalsite.headerBodyItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          {selectedPage.description}
                        </p>
                        <MdClose
                          className="page-view__close"
                          color={"#969696"}
                          size={20}
                          onClick={this.props.showPreviewModal}
                        />
                      </div>
                      <div
                        className="page-view__content"
                        style={{ background: bg ? bg : "#f4f6fc" }}
                      >
                        <div className="page-view__left-menu">
                          {selectedPage.sections.map((item) => (
                            <p
                              key={item.id}
                              className="page-view__left-menu-item"
                              style={{
                                color:
                                  legalsite && legalsite.cardsTitleColor
                                    ? legalsite.cardsTitleColor
                                    : "#333",
                                fontSize:
                                  legalsite && legalsite.cardsTitleSize
                                    ? legalsite.cardsTitleSize
                                    : null,
                                fontFamily:
                                  legalsite && legalsite.cardsTitleFont
                                    ? legalsite.cardsTitleFont
                                    : null,
                                textAlign:
                                  legalsite &&
                                  legalsite.cardsTitleAlign !== null
                                    ? legalsite.cardsTitleAlign
                                    : null,
                                fontWeight:
                                  legalsite && legalsite.cardsTitleBold !== null
                                    ? legalsite.cardsTitleBold === true
                                      ? 900
                                      : 500
                                    : 500,
                                fontStyle:
                                  legalsite &&
                                  legalsite.cardsTitleItalic !== null
                                    ? legalsite.cardsTitleItalic === true
                                      ? "italic"
                                      : "normal"
                                    : "normal",
                              }}
                            >
                              {item.title}
                            </p>
                          ))}
                        </div>
                        <div className="page-view__publication">
                          {selectedPage.sections.map((item) => (
                            <div key={item.id}>
                              <p
                                className="page-view__publication-title"
                                style={{
                                  color:
                                    legalsite && legalsite.cardsTitleColor
                                      ? legalsite.cardsTitleColor
                                      : "#333",
                                  fontSize:
                                    legalsite && legalsite.cardsTitleSize
                                      ? legalsite.cardsTitleSize
                                      : null,
                                  fontFamily:
                                    legalsite && legalsite.cardsTitleFont
                                      ? legalsite.cardsTitleFont
                                      : null,
                                  textAlign:
                                    legalsite &&
                                    legalsite.cardsTitleAlign !== null
                                      ? legalsite.cardsTitleAlign
                                      : null,
                                  fontWeight:
                                    legalsite &&
                                    legalsite.cardsTitleBold !== null
                                      ? legalsite.cardsTitleBold === true
                                        ? 900
                                        : 500
                                      : 500,
                                  fontStyle:
                                    legalsite &&
                                    legalsite.cardsTitleItalic !== null
                                      ? legalsite.cardsTitleItalic === true
                                        ? "italic"
                                        : "normal"
                                      : "normal",
                                }}
                              >
                                {item.title}
                              </p>
                              <p
                                className="page-view__publication-text"
                                style={{
                                  color:
                                    legalsite && legalsite.cardsBodyColor
                                      ? legalsite.cardsBodyColor
                                      : "#969696",
                                  fontSize:
                                    legalsite && legalsite.cardsBodySize
                                      ? legalsite.cardsBodySize
                                      : null,
                                  fontFamily:
                                    legalsite && legalsite.cardsBodyFont
                                      ? legalsite.cardsBodyFont
                                      : null,
                                  // textAlign:
                                  //   legalsite &&
                                  //   legalsite.cardsBodyAlign !== null
                                  //     ? legalsite.cardsBodyAlign
                                  //     : null,
                                  // fontWeight:
                                  //   legalsite &&
                                  //   legalsite.cardsBodyBold !== null
                                  //     ? legalsite.cardsBodyBold === true
                                  //       ? 900
                                  //       : 500
                                  //     : 500,
                                  // fontStyle:
                                  //   legalsite &&
                                  //   legalsite.cardsBodyItalic !== null
                                  //     ? legalsite.cardsBodyItalic === true
                                  //       ? 'italic'
                                  //       : 'normal'
                                  //     : 'normal',
                                }}
                              >
                                {ReactHtmlParser(item.text)}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : selectedPage === "all" && templateActive === 4 ? (
                    <div className="page-view__container position-relative">
                      {pages &&
                        pages.map((page) =>
                          page.title === "Cookies Policy" ? (
                            <div
                              key={page.id}
                              className="page-view__container position-relative"
                            >
                              <MdClose
                                className="page-view__close"
                                size={20}
                                color={"#969696"}
                                onClick={this.props.showPreviewModal}
                              />
                              <div
                                className="page-view__pages"
                                style={{
                                  backgroundColor:
                                    legalsite && legalsite.navigationBg
                                      ? legalsite.navigationBg
                                      : null,
                                }}
                              >
                                {pages.map((item) => (
                                  <p
                                    key={item.id}
                                    className="page-view__pages-title"
                                    style={{
                                      color: legalsite
                                        ? legalsite.navigationLinksColor
                                        : null,
                                      fontSize: legalsite
                                        ? legalsite.navigationSize
                                        : null,
                                      fontFamily: legalsite
                                        ? legalsite.navigationFont
                                        : null,
                                      textAlign: legalsite
                                        ? legalsite.navigationLinksAlign
                                        : null,
                                      fontWeight:
                                        legalsite.navigationLinksBold !== null
                                          ? legalsite.navigationLinksBold ===
                                            true
                                            ? 900
                                            : 300
                                          : null,
                                      fontStyle:
                                        legalsite.navigationLinksItalic !== null
                                          ? legalsite.navigationLinksItalic ===
                                            true
                                            ? "italic"
                                            : "normal"
                                          : "normal",
                                    }}
                                  >
                                    {item.title}
                                  </p>
                                ))}
                              </div>
                              <div
                                className="page-view__header"
                                style={{
                                  background:
                                    legalsite && legalsite.headerBg
                                      ? legalsite.headerBg
                                      : "#dfe4ee",
                                }}
                              >
                                <p
                                  className="page-view__title"
                                  style={{
                                    color:
                                      legalsite && legalsite.headerTitleColor
                                        ? legalsite.headerTitleColor
                                        : "#333",
                                    fontSize:
                                      legalsite && legalsite.headerTitleSize
                                        ? legalsite.headerTitleSize
                                        : 56,
                                    fontFamily:
                                      legalsite && legalsite.headerTitleFont
                                        ? legalsite.headerTitleFont
                                        : null,
                                    textAlign:
                                      legalsite &&
                                      legalsite.headerTitleAlign !== null
                                        ? legalsite.headerTitleAlign
                                        : null,
                                    fontWeight:
                                      legalsite &&
                                      legalsite.headerTitleBold !== null
                                        ? legalsite.headerTitleBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      legalsite &&
                                      legalsite.headerTitleItalic !== null
                                        ? legalsite.headerTitleItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  {page.title}
                                </p>
                                <p
                                  className="page-view__subtitle"
                                  style={{
                                    color:
                                      legalsite && legalsite.headerBodyColor
                                        ? legalsite.headerBodyColor
                                        : "#969696",
                                    fontSize:
                                      legalsite && legalsite.headerBodySize
                                        ? legalsite.headerBodySize
                                        : 16,
                                    fontFamily:
                                      legalsite && legalsite.headerBodyFont
                                        ? legalsite.headerBodyFont
                                        : null,
                                    textAlign:
                                      legalsite &&
                                      legalsite.headerBodyAlign !== null
                                        ? legalsite.headerBodyAlign
                                        : null,
                                    fontWeight:
                                      legalsite &&
                                      legalsite.headerBodyBold !== null
                                        ? legalsite.headerBodyBold === true
                                          ? 900
                                          : 500
                                        : 500,
                                    fontStyle:
                                      legalsite &&
                                      legalsite.headerBodyItalic !== null
                                        ? legalsite.headerBodyItalic === true
                                          ? "italic"
                                          : "normal"
                                        : "normal",
                                  }}
                                >
                                  {page.description}
                                </p>
                              </div>
                              <div
                                className="page-view__content"
                                style={{ background: bg ? bg : "#f4f6fc" }}
                              >
                                <div className="page-view__left-menu">
                                  {page.sections.map((item) => (
                                    <p
                                      key={item.id}
                                      className="page-view__left-menu-item"
                                      style={{
                                        color:
                                          legalsite && legalsite.cardsTitleColor
                                            ? legalsite.cardsTitleColor
                                            : "#333",
                                        fontSize:
                                          legalsite && legalsite.cardsTitleSize
                                            ? legalsite.cardsTitleSize
                                            : null,
                                        fontFamily:
                                          legalsite && legalsite.cardsTitleFont
                                            ? legalsite.cardsTitleFont
                                            : null,
                                        textAlign:
                                          legalsite &&
                                          legalsite.cardsTitleAlign !== null
                                            ? legalsite.cardsTitleAlign
                                            : null,
                                        fontWeight:
                                          legalsite &&
                                          legalsite.cardsTitleBold !== null
                                            ? legalsite.cardsTitleBold === true
                                              ? 900
                                              : 500
                                            : 500,
                                        fontStyle:
                                          legalsite &&
                                          legalsite.cardsTitleItalic !== null
                                            ? legalsite.cardsTitleItalic ===
                                              true
                                              ? "italic"
                                              : "normal"
                                            : "normal",
                                      }}
                                    >
                                      {item.title}
                                    </p>
                                  ))}
                                </div>
                                <div className="page-view__publication">
                                  {page.sections.map((item) => (
                                    <div key={item.id}>
                                      <p
                                        className="page-view__publication-title"
                                        style={{
                                          color:
                                            legalsite &&
                                            legalsite.cardsTitleColor
                                              ? legalsite.cardsTitleColor
                                              : "#333",
                                          fontSize:
                                            legalsite &&
                                            legalsite.cardsTitleSize
                                              ? legalsite.cardsTitleSize
                                              : null,
                                          fontFamily:
                                            legalsite &&
                                            legalsite.cardsTitleFont
                                              ? legalsite.cardsTitleFont
                                              : null,
                                          textAlign:
                                            legalsite &&
                                            legalsite.cardsTitleAlign !== null
                                              ? legalsite.cardsTitleAlign
                                              : null,
                                          fontWeight:
                                            legalsite &&
                                            legalsite.cardsTitleBold !== null
                                              ? legalsite.cardsTitleBold ===
                                                true
                                                ? 900
                                                : 500
                                              : 500,
                                          fontStyle:
                                            legalsite &&
                                            legalsite.cardsTitleItalic !== null
                                              ? legalsite.cardsTitleItalic ===
                                                true
                                                ? "italic"
                                                : "normal"
                                              : "normal",
                                        }}
                                      >
                                        {item.title}
                                      </p>
                                      <p
                                        className="page-view__publication-text"
                                        style={{
                                          color:
                                            legalsite &&
                                            legalsite.cardsBodyColor
                                              ? legalsite.cardsBodyColor
                                              : "#969696",
                                          fontSize:
                                            legalsite && legalsite.cardsBodySize
                                              ? legalsite.cardsBodySize
                                              : null,
                                          fontFamily:
                                            legalsite && legalsite.cardsBodyFont
                                              ? legalsite.cardsBodyFont
                                              : null,
                                          // textAlign:
                                          //   legalsite &&
                                          //   legalsite.cardsBodyAlign !== null
                                          //     ? legalsite.cardsBodyAlign
                                          //     : null,
                                          // fontWeight:
                                          //   legalsite &&
                                          //   legalsite.cardsBodyBold !== null
                                          //     ? legalsite.cardsBodyBold === true
                                          //       ? 900
                                          //       : 500
                                          //     : 500,
                                          // fontStyle:
                                          //   legalsite &&
                                          //   legalsite.cardsBodyItalic !== null
                                          //     ? legalsite.cardsBodyItalic ===
                                          //       true
                                          //       ? 'italic'
                                          //       : 'normal'
                                          //     : 'normal',
                                        }}
                                      >
                                        {ReactHtmlParser(item.text)}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                  ) : null}

                  <div
                    className={`${
                      templateActive === 4
                        ? "customization-cookie__page-view w-100"
                        : ""
                    } w-100 d-flex flex-column align-items-center`}
                    style={
                      templateActive === 4
                        ? { background: cookieBg ? cookieBg : "#fafafa" }
                        : null
                    }
                  >
                    <p
                      style={{
                        color: titleColor ? titleColor : "#7450C8",
                        textAlign: this.props.titleAlign,
                        fontStyle:
                          this.props.titleItalic !== null
                            ? this.props.titleItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                        fontWeight:
                          this.props.titleBold !== null
                            ? this.props.titleBold === true
                              ? 900
                              : 300
                            : 300,
                        fontSize: titleSize ? titleSize : 22,
                        fontFamily: cookieTitleFont
                          ? cookieTitleFont
                          : "Roboto",
                      }}
                      className="customization-cookie__popup-title w-100"
                    >
                      {cookieTitle}
                    </p>

                    <p
                      style={{
                        color: bodyColor ? bodyColor : "#969696",
                        fontSize: bodySize ? bodySize : 14,
                        fontFamily: cookieBodyFont ? cookieBodyFont : "Roboto",
                        textAlign: this.props.emailBodyAlign,
                        fontStyle:
                          this.props.emailBodyItalic !== null
                            ? this.props.emailBodyItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                        fontWeight:
                          this.props.emailBodyBold !== null
                            ? this.props.emailBodyBold === true
                              ? 900
                              : 300
                            : 300,
                      }}
                      className="customization-cookie__popup-text"
                    >
                      {cookieDescription}
                    </p>

                    {this.props.hasEmail ? (
                      <FormGroup className="customization-cookie__form-group">
                        {popupSide === "left" && templateActive === 1 ? (
                          <Label className="customization-cookie__label mb-0">
                            E-mail:
                          </Label>
                        ) : null}
                        <Input
                          type="text"
                          placeholder="Write your email"
                          className="bg-white"
                          readOnly={true}
                        />
                      </FormGroup>
                    ) : null}

                    <div
                      className={`${
                        templateActive !== 1
                          ? "d-flex align-items-center"
                          : "d-flex w-100 align-items-start"
                      }`}
                    >
                      <Button
                        className={`${
                          buttonShape === "rounded" ? "btn-round" : ""
                        } customization-cookie__popup-accept`}
                        style={{
                          color: buttonColor ? buttonColor : "#fff",
                          fontSize: buttonSize ? buttonSize : 14,
                          fontStyle:
                            this.props.emailBtnItalic !== null
                              ? this.props.emailBtnItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                          fontWeight:
                            this.props.emailBtnBold !== null
                              ? this.props.emailBtnBold === true
                                ? 900
                                : 300
                              : 300,
                          backgroundColor: this.props.buttonBg
                            ? this.props.buttonBg
                            : legalsite && legalsite.buttonBg
                            ? legalsite.buttonBg
                            : "#7450C8",
                          fontFamily: cookieButtonFont
                            ? cookieButtonFont
                            : "Roboto",
                        }}
                      >
                        {buttonText}
                      </Button>
                      <Button
                        className="customization-cookie__popup-options"
                        style={{
                          color: buttonTextColor ? buttonTextColor : "#7450C8",
                          fontSize: buttonTextSize ? buttonTextSize : 12,
                          fontStyle:
                            this.props.emailFooterItalic !== null
                              ? this.props.emailFooterItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                          fontWeight:
                            this.props.emailFooterBold !== null
                              ? this.props.emailFooterBold === true
                                ? 900
                                : 300
                              : 300,
                          fontFamily: cookieButtonTextFont
                            ? cookieButtonTextFont
                            : "Roboto",
                        }}
                      >
                        {secondaryText}
                      </Button>
                    </div>

                    <span
                      style={{
                        color: buttonTextColor ? buttonTextColor : "#7450C8",
                        fontSize: buttonTextSize ? buttonTextSize : 12,
                        fontStyle:
                          this.props.emailFooterItalic !== null
                            ? this.props.emailFooterItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                        fontWeight:
                          this.props.emailFooterBold !== null
                            ? this.props.emailFooterBold === true
                              ? 900
                              : 300
                            : 300,
                        fontFamily: cookieButtonTextFont
                          ? cookieButtonTextFont
                          : "Roboto",
                      }}
                      className="customization-cookie__get w-100"
                    >
                      Get your LegalSite
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default graphql(PAGES_PAGE_QUERY, {
  name: "pagesQuery",
})(PreviewModal);
