import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import Dashboard from "./containers/Dashboard/Dashboard";
import Notifications from "./containers/Notifications/Notifications";
import Inbox from "./containers/Inbox/Inbox";
import DataProcessors from "./containers/DataProcessors/DataProcessors";
import DataProcessor from "./containers/DataProcessors/DataProcessor";
import Search from "./containers/Search/Search";
import Help from "./containers/Help/Help";
import Pages from "./containers/Pages/Pages";
import Users from "./containers/Users/Users";
import History from "./containers/History/History";
import Settings from "./containers/Settings/Settings";
import Compliance from "./containers/Compliance/Compliance";
import "./App.scss";
import NotFound from "./containers/NotFound/NotFound";
import { AUTH_TOKEN } from "./constants";
import Dpo from "./containers/Dpo/Dpo";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache, ApolloClient } from "@apollo/client";
import ManageConsent from "./containers/ManageConsent/ManageConsent";
import { Redirect } from "react-router-dom";
import Dpa from "./containers/Dpa/Dpa";
import SignIn from "./containers/SignIn/SignIn";
import Reset from "./containers/SignIn/Reset";
import Invite from "./containers/SignIn/Invite";
import Lawyers from "./containers/Lawyers/Lawyers";
import UsersAdmin from "./containers/UsersAdmin/UsersAdmin";
import Clients from "./containers/Clients/Clients";
import AdminPage from "./containers/Pages/AdminPage";
import Signup from "./containers/SignIn/Signup";
import Integrations from "./containers/Integrations/Integrations";
import SelectPlan from "./containers/Dashboard/SelectPlan";
import ThankYou from "./containers/Dashboard/ThankYou";
import CheckEmail from "./containers/SignIn/CheckEmail";
import UserDetails from "./containers/Users/UserDetails";

import { HttpLink } from "@apollo/client";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT_URL,
  // Additional options
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default class App extends Component {
  state = {};

  render() {
    return (
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route
              exact
              path="/dashboard"
              render={(props) => <Dashboard {...props} />}
            />

            <Redirect exact from="/" to="/dashboard" />

            <Route
              exact
              path="/select-plan"
              render={(props) => <SelectPlan {...props} />}
            />
            <Route
              exact
              path="/thank-you"
              render={(props) => <ThankYou {...props} />}
            />
            <Route
              exact
              path="/notifications"
              render={() => <Notifications />}
            />
            <Route
              exact
              path="/data-processor"
              render={(props) => <DataProcessor {...props} />}
            />
            <Route
              exact
              path="/users/:id"
              render={(props) => <UserDetails {...props} />}
            />
            <Route
              exact
              path="/inbox"
              render={(props) => <Inbox {...props} />}
            />
            <Route
              path="/data-processors"
              render={(props) => <DataProcessors {...props} />}
            />
            <Route exact path="/search" render={() => <Search />} />
            <Route exact path="/help" render={() => <Help />} />
            <Route
              exact
              path="/pages"
              render={(props) => <Pages {...props} />}
            />
            <Route
              exact
              path="/users"
              render={(props) => <Users {...props} />}
            />
            <Route
              exact
              path="/history"
              render={(props) => <History {...props} />}
            />
            <Route
              exact
              path="/settings"
              render={(props) => <Settings {...props} />}
            />
            <Route
              exact
              path="/compliance"
              render={(props) => <Compliance {...props} />}
            />
            <Route exact path="/dpo" render={() => <Dpo />} />
            <Route
              exact
              path="/manageConsent"
              render={() => <ManageConsent />}
            />
            <Route exact path="/dpa" render={() => <Dpa />} />
            <Route exact path="/signin" render={() => <SignIn />} />
            <Route
              exact
              path="/reset"
              render={(props) => <Reset {...props} />}
            />
            <Route
              exact
              path="/invite"
              render={(props) => <Invite {...props} />}
            />
            <Route
              exact
              path="/lawyers"
              render={(props) => <Lawyers {...props} />}
            />
            <Route
              exact
              path="/users-admin"
              render={(props) => <UsersAdmin {...props} />}
            />
            <Route
              exact
              path="/clients"
              render={(props) => <Clients {...props} />}
            />
            <Route
              exact
              path="/admin-page"
              render={(props) => <AdminPage {...props} />}
            />
            <Route
              exact
              path="/signup"
              render={(props) => <Signup {...props} />}
            />
            <Route
              exact
              path="/integrations"
              render={(props) => <Integrations {...props} />}
            />
            <Route
              exact
              path="/check"
              render={(props) => <CheckEmail {...props} />}
            />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </ApolloProvider>
    );
  }
}
