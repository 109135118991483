import React, { Component } from "react";
import PropTypes from "prop-types";
import Site from "../../components/Icons/Site";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import CustomizeCookie from "./CustomizeCookie";
import CustomizeEmail from "./CustomizeEmail";
import CustomizeLegalSite from "./CustomizeLegalSite";
import MdArrow from "react-icons/lib/ti/arrow-left";
import CustomiseSell from "./CustomizeSell";
import Branding from "./Branding";

export default class Customization extends Component {
  static propTypes = {
    showCustomization: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    account: PropTypes.object.isRequired,
    accountData: PropTypes.object.isRequired,
    notifyMsg: PropTypes.func.isRequired,
    sectionSelected: PropTypes.string,
    showLegalInformation: PropTypes.func.isRequired,
    getInitialPage: PropTypes.func.isRequired,
  };

  state = {
    section: "legal",
    step: null,
  };

  componentDidMount() {
    this.showSelected();
  }

  showSelected = () => {
    const { sectionSelected } = this.props;

    if (sectionSelected === "LegalSite") {
      this.setState({
        section: "legal",
      });
    } else if (sectionSelected === "Pop-ups") {
      this.setState({
        section: "cookie",
      });
    } else if (sectionSelected === "E-mail templates") {
      this.setState({
        section: "emails",
      });
    } else if (sectionSelected === "branding") {
      this.setState({
        section: "branding",
      });
    } else {
      this.setState({
        section: "sell",
      });
    }
  };

  showSection = (section) => {
    this.setState({
      section,
    });
  };

  render() {
    const { section, step } = this.state;
    const { isAdmin } = this.props;

    return (
      <div className="customization">
        <div className="customization__container d-flex">
          <div
            className={`customization__navigation ${
              section === "legal" ||
              section === "sell" ||
              step === 3 ||
              section === "branding"
                ? "d-none"
                : ""
            }`}
          >
            <p
              onClick={this.props.getInitialPage}
              className="customization__back"
            >
              <MdArrow size={25} />
              &nbsp; Back to Settings
            </p>
            <h4 className="customization__navigation-title">
              <Site color="#333" className="mr-2" /> Customization
            </h4>

            {!isAdmin ? (
              <div
                className={`${
                  section === "legal" ? "-is-active" : ""
                } settings__captcha-item`}
                onClick={() => this.showSection("legal")}
              >
                <div>
                  <p className="settings__captcha-item-title">LegalSite</p>
                </div>

                <ArrowCircleRight color={"#EBA601"} />
              </div>
            ) : null}

            {!isAdmin ? (
              <div
                className={`${
                  section === "cookie" ? "-is-active" : ""
                } settings__captcha-item`}
                onClick={() => this.showSection("cookie")}
              >
                <div>
                  <p className="settings__captcha-item-title">
                    Agreement Pop-up
                  </p>
                </div>

                <ArrowCircleRight color={"#EBA601"} />
              </div>
            ) : null}

            <div
              className={`${
                section === "emails" ? "-is-active" : ""
              } settings__captcha-item`}
              onClick={() => this.showSection("emails")}
            >
              <div>
                <p className="settings__captcha-item-title">E-mails</p>
              </div>

              <ArrowCircleRight color={"#EBA601"} />
            </div>

            <div
              className={`${
                section === "sell" ? "-is-active" : ""
              } settings__captcha-item`}
              onClick={() => this.showSection("sell")}
            >
              <div>
                <p className="settings__captcha-item-title">
                  Don't sell data Button
                </p>
              </div>

              <ArrowCircleRight color={"#EBA601"} />
            </div>
          </div>

          <div className="customization__content">
            {section === "cookie" ? (
              <CustomizeCookie
                showCustomization={this.props.showCustomization}
                account={this.props.account}
                accountData={this.props.accountData}
                notifyMsg={this.props.notifyMsg}
              />
            ) : section === "emails" ? (
              <CustomizeEmail
                isAdmin={this.props.isAdmin}
                showCustomization={this.props.showCustomization}
                accountData={this.props.accountData}
                notifyMsg={this.props.notifyMsg}
                getInitialPage={this.props.getInitialPage}
              />
            ) : section === "branding" ? (
              <Branding getInitialPage={this.props.getInitialPage} />
            ) : section === "sell" ? (
              <CustomiseSell getInitialPage={this.props.getInitialPage} />
            ) : (
              <CustomizeLegalSite
                showCustomization={this.props.showCustomization}
                account={this.props.account}
                accountData={this.props.accountData}
                notifyMsg={this.props.notifyMsg}
                showLegalInformation={this.props.showLegalInformation}
                getInitialPage={this.props.getInitialPage}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
