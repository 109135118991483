import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ReactFilestack from "filestack-react";
import MdClose from "react-icons/lib/md/close";

function InternalTasks(props) {
  const { selectedRequestFile, selectedRequest, assigned } = props;

  return (
    <div className="inbox__internal">
      <p className="inbox__internal-title">Step 1. Internal tasks</p>

      <p className="inbox__internal-text">
        {selectedRequest && selectedRequest.category === "DATA_ERASURE"
          ? "First, upload the data erasure confirmation log from your company internal systems."
          : "First, upload the user’s data from your company internal systems."}
      </p>

      <div className="d-flex flex-wrap justify-content-between">
        {selectedRequestFile
          ? selectedRequestFile.map((item) =>
              item.category === "INTERNAL_USER_DATA" ? (
                <div className="inbox__internal-file mb-4" key={item.id}>
                  <div className="inbox__internal-file-type">DOC</div>
                  <p className="inbox__internal-file-name">{item.url}</p>
                  <MdClose
                    onClick={() => props.removeFile(item.id)}
                    className="inbox__internal-file-remove"
                    size={20}
                    color={"#969696"}
                  />
                </div>
              ) : null
            )
          : null}
      </div>

      {assigned ? (
        <Button
          className="btn--primary inbox__internal-btn"
          onClick={props.showAssignedModal}
        >
          Upload file
        </Button>
      ) : (
        <ReactFilestack
          apikey={"AJNM9qOpGRljTn17sgxrfz"}
          componentDisplayMode={{
            type: "link",
            customText: (
              <Button className="btn--primary inbox__internal-btn">
                Upload file
              </Button>
            ),
            customClass: "show-upload",
          }}
          onSuccess={(res) => props.onFiles(res)}
          actionOptions={{
            fromSources: [
              "local_file_system",
              "googledrive",
              "dropbox",
              "onedrive",
            ],
            accept: [
              "application/pdf",
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ],
            maxFiles: 1,
          }}
        />
      )}
    </div>
  );
}

export default InternalTasks;

InternalTasks.propTypes = {
  files: PropTypes.array,
  selectedRequestFile: PropTypes.array,
  selectedRequest: PropTypes.object,
  assigned: PropTypes.bool,
  onFiles: PropTypes.func,
  showAssignedModal: PropTypes.func,
  removeFile: PropTypes.func,
};
