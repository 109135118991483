import React, { Fragment } from "react";
import { Button, Row, Col } from "reactstrap";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Comments from "../../components/Icons/Comments";
import ChatBubble from "../../components/Icons/ChatBubble";
import ShieldCheck from "../../components/Icons/ShieldCheck";
import LockIcon from "../../components/Icons/LockIcon";
import Site from "../../components/Icons/Site";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import Consent from "../../components/Icons/Consent";
import FileCheck from "../../components/Icons/FileCheck";
import FaCaret from "react-icons/lib/fa/angle-down";
import MdClose from "react-icons/lib/md/close";
import PropTypes from "prop-types";
import "./sample.scss";

function SampleLegalSite(props) {
  return (
    <Fragment>
      <div className="users__banner">
        <p className="users__banner-title">Sample data</p>
        <div className="users__banner-exit" onClick={props.closeSample}>
          <span>Exit</span>
          <MdClose className="ml-2" size={20} />
        </div>
      </div>
      <div className="customization -is-sample">
        <div className="customization__navigation">
          <h4 className="customization__navigation-title">
            <Site color="#333" className="mr-2" /> Customization
          </h4>

          <div className="-is-active settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">LegalSite</p>
              <p className="settings__captcha-item-text">
                Customize your LegalSite
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">Agreement Pop-up</p>
              <p className="settings__captcha-item-text">
                Change your Pop-up style.
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>

          <div className="settings__captcha-item">
            <div>
              <p className="settings__captcha-item-title">E-mails</p>
              <p className="settings__captcha-item-text">
                Customize your e-mails
              </p>
            </div>

            <ArrowCircleRight color={"#EBA601"} />
          </div>
        </div>

        <div className="customization__content">
          <Fragment>
            <div className="justify-content-between d-flex w-100">
              <div className="customization-site -is-sample">
                <div className="customization-site__container">
                  <div className="customization-site__header d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <span className="customization-site__avatar" />
                      <p className="customization-site__logo">Your Logo</p>
                    </div>
                    <p className="customization-site__get">
                      Get your LegalSite
                    </p>
                  </div>
                  <div className="customization-site__welcome-section">
                    <h1 className="customization-site__welcome-title">
                      Welcome!
                    </h1>
                    <p className="customization-site__welcome-text">
                      It’s important for us to explain the legal aspects of
                      using our services. This mini-site is designed to provide
                      you with the information you need.
                    </p>
                  </div>

                  <div className="customization-site__info">
                    <Row className="customization-site__info-content">
                      <Col
                        md={12}
                        lg={4}
                        className="customization-site__info-privacy justify-content-between"
                      >
                        <div>
                          <LockIcon color={"#7450c8"} />
                          <h4 className="customization-site__info-title">
                            Privacy Policy
                          </h4>
                          <p className="customization-site__info-text">
                            Send data requests, questions or complaints
                          </p>
                        </div>
                        <div>
                          <p className="customization-site__info-more">
                            See more <MdArrow size={20} className="ml-2 my-0" />
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="customization-site__info-privacy justify-content-between"
                      >
                        <div>
                          <ShieldCheck color="#7450c8" />
                          <h4 className="customization-site__info-title">
                            Terms & Conditions
                          </h4>
                          <p className="customization-site__info-text">
                            See the Terms & Conditions that apply to our
                            service.
                          </p>
                        </div>
                        <div>
                          <p
                            className="customization-site__info-more"
                            style={{
                              color: "#7450c8",
                            }}
                          >
                            See more <MdArrow size={20} className="ml-2 my-0" />
                          </p>
                        </div>
                      </Col>
                      <Col
                        md={12}
                        lg={4}
                        className="customization-site__info-privacy justify-content-between"
                      >
                        <div>
                          <Comments color="#7450c8" />
                          <h4 className="customization-site__info-title">
                            Contact us
                          </h4>
                          <p className="customization-site__info-text">
                            Click to send a message and we’ll get in touch soon.
                          </p>
                        </div>
                        <div>
                          <p
                            className="customization-site__info-more"
                            style={{
                              color: "#7450c8",
                            }}
                          >
                            See more <MdArrow size={20} className="ml-2 my-0" />
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <h3 className="customization-site__info-subtitle">
                      Manage your information
                    </h3>
                    <p className="customization-site__info-details">
                      When you interact with our service, we probably manage
                      information about you. You have the right to understand
                      and manage how this information is handled by us.
                    </p>

                    <div className="d-flex justify-content-center align-items-center flex-wrap mb-4">
                      <div className="d-flex flex-column align-items-center justify-content-center customization-site__info-details-item">
                        <ChatBubble color={"#7450c8"} />
                        <p className="customization-site__info-title">
                          Requests
                        </p>
                        <p className="customization-site__info-text">
                          Send data requests, questions or complaints
                        </p>
                        <Button
                          className="customization-site__btn"
                          style={{ backgroundColor: "#7450c8" }}
                        >
                          Request
                        </Button>
                      </div>
                      <div className="d-flex flex-column align-items-center justify-content-center customization-site__info-details-item">
                        <Consent color={"#7450c8"} />
                        <p className="customization-site__info-title">
                          Manage Consent
                        </p>
                        <p className="customization-site__info-text">
                          Send data requests, questions or complaints
                        </p>
                        <Button
                          className="customization-site__btn"
                          style={{ backgroundColor: "#7450c8" }}
                        >
                          Request
                        </Button>
                      </div>
                    </div>

                    <div className="customization-site__item">
                      <div className="d-flex align-items-center">
                        <FileCheck color={"#7450C8"} className="mr-1" />

                        <p className="customization-site__item-title">
                          Data Procesing Agreement
                        </p>
                      </div>
                      <p className="customization-site__item-text">
                        Click if we process data for you on our service. You can
                        request our signed Data Processing Agreement
                      </p>

                      <Button
                        className="customization-site__btn"
                        style={{ backgroundColor: "#7450c8" }}
                      >
                        Request
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="customization-cookie__right-menu -is-sample">
                <Button className="customization-cookie__publish btn--primary mb-2">
                  Publish page
                </Button>
                <Button className="customization-cookie__preview btn--secondary">
                  Preview
                </Button>

                <div className="customization-cookie__right-menu-content">
                  <div className="d-flex mr-auto flex-column">
                    <p className="customization-cookie__right-menu-label">
                      Color scheme
                    </p>
                    <div className="d-flex sample-scheme">
                      <span className="sample-scheme__color -white" />
                      <span className="sample-scheme__color -purple" />
                      <span className="sample-scheme__color -black" />
                      <span className="sample-scheme__color -light-gray" />
                      <span className="sample-scheme__color -dark-gray" />
                    </div>

                    <div className="sample-text d-flex customise-text">
                      <p className="sample-text__label">Title</p>

                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span className="sample-text__color -black" />
                          <div className="sample-text__size">
                            40
                            <FaCaret className="settings__input-icon" />
                          </div>
                        </div>

                        <div className="sample-text__font">
                          Font
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>
                    </div>

                    <div className="sample-text d-flex customise-text">
                      <p className="sample-text__label">Body</p>

                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span className="sample-text__color -gray" />
                          <div className="sample-text__size">
                            40
                            <FaCaret className="settings__input-icon" />
                          </div>
                        </div>

                        <div className="sample-text__font">
                          Font
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>
                    </div>

                    <div className="sample-text d-flex customise-text">
                      <p className="sample-text__label">Subtitle</p>

                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span className="sample-text__color -black" />
                          <div className="sample-text__size">
                            40
                            <FaCaret className="settings__input-icon" />
                          </div>
                        </div>

                        <div className="sample-text__font">
                          Font
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>
                    </div>

                    <div className="sample-text d-flex customise-text">
                      <p className="sample-text__label">Button text</p>

                      <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                          <span className="sample-text__color -purple" />
                          <div className="sample-text__size">
                            40
                            <FaCaret className="settings__input-icon" />
                          </div>
                        </div>

                        <div className="sample-text__font">
                          Font
                          <FaCaret className="settings__input-icon" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

export default SampleLegalSite;
SampleLegalSite.propTypes = {
  closeSample: PropTypes.func.isRequired,
};
