import React, { Component, Fragment } from "react";
import { Button } from "reactstrap";
import Sitemap from "../../components/Icons/Sitemap";
import DataProcessingItem from "./DataProcessingItem";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import Img1 from "../../images/Settings/Illustration.svg";
import Img2 from "../../images/Settings/Group_5.svg";
import Img3 from "../../images/Settings/Illustration3.svg";
import { EDIT_DATA_KEEPING_SETTINGS } from "../../graphql/editDataKeepingSettings";
import { DATA_PROCESSOR_SETTINGS_QUERY } from "../../graphql/dataProcessorSettings";
import { EDIT_DP_SETTINGS } from "../../graphql/editDataProcessingSettings";
import { EDIT_DP } from "../../graphql/editDataProcessor";
import { toast } from "react-toastify";
import DPAccountInfo from "./DPAccountInfo";
import DataKeeping from "./DataKeeping";
import PropTypes from "prop-types";

class DataProcessing extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    account: PropTypes.object,
    editDPSettings: PropTypes.func.isRequired,
    dpSettings: PropTypes.object,
    editDataKeepingSettings: PropTypes.func.isRequired,
    refetchAccount: PropTypes.func.isRequired,
    editDP: PropTypes.func.isRequired,
    showDpProfile: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
  };

  state = {
    activeItem:
      this.props.account && this.props.account.dataProcessing
        ? this.props.account.dataProcessing === "NO"
          ? 1
          : this.props.account.dataProcessing === "LEGALSITE_ACCOUNT"
          ? 2
          : 3
        : 1,
    isIndex: null,
    is6Months: false,
    is1Year: false,
    is3Years: false,
    is5Years: false,
    is60Days: false,
    is120Days: false,
    isInbox1Year: false,
    isInitialLifespan: true,
    isInitialInboxLifespan: true,
    isInitialPublish: true,
    formErrors: {},
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  saveDataKeeping = () => {
    const { isInitialLifespan, isInitialInboxLifespan } = this.state;

    this.props
      .editDataKeepingSettings({
        variables: {
          lifespanData:
            (this.props.account &&
              this.props.account.lifespanData === "5_YEARS" &&
              isInitialLifespan) ||
            (!isInitialLifespan && this.state.is5Years)
              ? "5_YEARS"
              : (this.props.account &&
                  this.props.account.lifespanData === "1_YEAR" &&
                  isInitialLifespan) ||
                (!isInitialLifespan && this.state.is1Year)
              ? "1_YEAR"
              : (this.props.account &&
                  this.props.account.lifespanData === "3_YEARS" &&
                  isInitialLifespan) ||
                (!isInitialLifespan && this.state.is3Years)
              ? "3_YEARS"
              : "6_MONTHS",
          lifespanInbox:
            (this.props.account &&
              this.props.account.lifespanInbox === "1_YEAR" &&
              isInitialInboxLifespan) ||
            (!isInitialInboxLifespan && this.state.isInbox1Year)
              ? "1_YEAR"
              : (this.props.account &&
                  this.props.account.lifespanInbox === "120_DAYS" &&
                  isInitialInboxLifespan) ||
                (!isInitialInboxLifespan && this.state.is120Days)
              ? "120_DAYS"
              : "60_DAYS",
        },
      })
      .then(() => {
        this.notify("Changes saved!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes couldn't be saved!");
      });
  };

  notify = (text) => toast(text);

  handleDataProcessing = (item) => {
    this.props
      .editDPSettings({
        variables: {
          dataProcessing:
            item === 1
              ? "NO"
              : item === 2
              ? "LEGALSITE_ACCOUNT"
              : "USER_INTERFACE",
        },
      })
      .then(() => {
        this.props.refetchAccount();
      })
      .catch((error) => {
        console.log(error);
        this.notify("Changes couldn't be saved!");
      });
  };

  handleAccountInfo = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const companyName = formData.get("companyName"),
      email = formData.get("email"),
      category = formData.get("category"),
      website = formData.get("website");

    let hasFormErrors = false;
    let formErrors = {};

    if (!companyName) {
      hasFormErrors = true;
      formErrors.companyName = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    if (!website) {
      hasFormErrors = true;
      formErrors.website = true;
    }

    if (!category) {
      hasFormErrors = true;
      formErrors.category = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editDP({
        variables: {
          companyName,
          email,
          website,
          category,
          show:
            this.state.isIndex !== null
              ? this.state.isIndex
              : this.props.dpSettings &&
                this.props.dpSettings.dataProcessorSettings &&
                this.props.dpSettings.dataProcessorSettings.show
              ? true
              : false,
        },
      })
      .then(() => {
        this.notify("Info saved");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Info couldn't be saved!");
      });
  };

  render() {
    const { sectionVisible, showSection, account } = this.props;
    const isActive = sectionVisible === "data-processing" ? true : false;
    const {
      activeItem,
      isIndex,
      is6Months,
      is1Year,
      is3Years,
      is5Years,
      isInitialLifespan,
      isInitialInboxLifespan,
      is60Days,
      is120Days,
      isInbox1Year,
      isInitialPublish,
    } = this.state;
    const { dataProcessorSettings } = this.props.dpSettings;

    return (
      <div
        className={`${isActive ? "-is-active" : ""} settings__account`}
        // eslint-disable-next-line react/no-string-refs
        ref="data-processing"
        onClick={
          !isActive
            ? () => {
                showSection("data-processing");
                this.scrollToSection("data-processing");
              }
            : () => showSection("data-processing")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <Sitemap color={"#7450c8"} /> &nbsp; Data Processing Settings
        </h2>
        <p className={`${isActive ? "-is-active" : ""} settings__text`}>
          Are you a data processor for other companies, usually your clients?
          Select your role and the preferred way to process privacy requests.
        </p>
        {isActive ? (
          <div>
            <div className="d-flex justify-content-between flex-wrap mt-2">
              <DataProcessingItem
                title="I’m not a Data Processor"
                id={1}
                img={Img1}
                text="Select this if you don’t process privacy data on behalf of other businesses. You process only data requests only from your own data subjects."
                btnText="Not a Data Processor"
                setItemActive={
                  activeItem === 1
                    ? () => {}
                    : (item) => {
                        this.setState({ activeItem: item });
                        this.handleDataProcessing(1);
                      }
                }
                activeItem={activeItem}
              />
              <DataProcessingItem
                title="I am a Data Processor"
                id={2}
                img={Img2}
                text="Select if you process privacy data on behalf of other businesses and want to handle them using LegalSite."
                btnText="Process inside LegalSite"
                setItemActive={
                  activeItem === 2
                    ? () => {}
                    : (item) => {
                        this.setState({ activeItem: item });
                        this.handleDataProcessing(2);
                      }
                }
                activeItem={activeItem}
              />
              <DataProcessingItem
                title="I am a Data Processor"
                id={3}
                img={Img3}
                text="Select if you process privacy data on behalf of other businesses and want users to manage the data inside their account in your application. Requests will still show up in LegalSite for handling tasks at your external Data Processors."
                btnText="Process inside your Application"
                setItemActive={
                  activeItem === 3
                    ? () => {}
                    : (item) => {
                        this.setState({ activeItem: item });
                        this.handleDataProcessing(3);
                      }
                }
                activeItem={activeItem}
              />
            </div>

            {activeItem !== 1 ? (
              <Fragment>
                <p className="settings__legal-title mt-5">
                  Data Processor Profile Information
                </p>
                <p className="settings__text">
                  Please provide information for your Data Processor profile so
                  other data controllers can add your company as one of their
                  data processors.
                </p>

                <DPAccountInfo
                  isIndex={isIndex}
                  checkIndex={(value) => this.setState({ isIndex: value })}
                  showDpProfile={this.props.showDpProfile}
                  isVisible={this.props.isVisible}
                  dataProcessorSettings={
                    dataProcessorSettings ? dataProcessorSettings : null
                  }
                  handleSubmit={this.handleAccountInfo}
                  formErrors={this.state.formErrors}
                  isInitialPublish={isInitialPublish}
                />
              </Fragment>
            ) : null}

            <p className="settings__legal-title mt-5">Data Removal</p>
            <p className="settings__text">
              Select how long you would like LegalSite to keep your data before
              anonymizing it.
            </p>

            <DataKeeping
              account={account}
              isInitialLifespan={isInitialLifespan}
              is6Months={is6Months}
              is1Year={is1Year}
              is3Years={is3Years}
              is5Years={is5Years}
              isInitialInboxLifespan={isInitialInboxLifespan}
              is60Days={is60Days}
              is120Days={is120Days}
              isInbox1Year={isInbox1Year}
              setInboxLifespan={(
                is60Days,
                isInitialInboxLifespan,
                is120Days,
                isInbox1Year
              ) =>
                this.setState({
                  is60Days,
                  isInitialInboxLifespan,
                  is120Days,
                  isInbox1Year,
                })
              }
              setLifespan={(
                is6Months,
                isInitialLifespan,
                is1Year,
                is3Years,
                is5Years
              ) =>
                this.setState({
                  is6Months,
                  isInitialLifespan,
                  is1Year,
                  is3Years,
                  is5Years,
                })
              }
            />
            <Button
              className="btn--primary data-processing__save"
              onClick={this.saveDataKeeping}
            >
              Save changes
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(EDIT_DATA_KEEPING_SETTINGS, {
    name: "editDataKeepingSettings",
  }),
  graphql(DATA_PROCESSOR_SETTINGS_QUERY, {
    name: "dpSettings",
  }),
  graphql(EDIT_DP_SETTINGS, {
    name: "editDPSettings",
  }),
  graphql(EDIT_DP, {
    name: "editDP",
  })
)(DataProcessing);
