import gql from "graphql-tag";

export const CREATE_BREACH_INCIDENT = gql`
  mutation(
    $name: String!
    $where: String
    $risk: String
    $breachDatetime: String
    $awenessedDatetime: String
    $breachOngoing: Boolean
    $endedDatetime: String
    $natures: [String!]
    $natureDescription: String
    $causes: [String!]
    $causeDescription: String
    $indentifyingDetailsInIndividuals: [String!]
    $personalDataCriminals: TristateEnum
    $specialCategoriesOfDatas: [String!]
    $affectedRecordsKnown: String
    $numberOfAffectedRecords: Int
    $vulnerableIndividualsAffectedKnown: TristateEnum
    $takenMeasures: String
    $mitigatingActionsImplemented: Boolean
    $securedBreachedData: Boolean
    $potentialConsequences: [String!]
    $additionalInformation: String
    $clientMutationId: String
  ) {
    createBreachIncident(
      input: {
        name: $name
        where: $where
        risk: $risk
        breachDatetime: $breachDatetime
        awenessedDatetime: $awenessedDatetime
        breachOngoing: $breachOngoing
        endedDatetime: $endedDatetime
        natures: $natures
        natureDescription: $natureDescription
        causes: $causes
        causeDescription: $causeDescription
        indentifyingDetailsInIndividuals: $indentifyingDetailsInIndividuals
        personalDataCriminals: $personalDataCriminals
        specialCategoriesOfData: $specialCategoriesOfDatas
        affectedRecordsKnown: $affectedRecordsKnown
        numberOfAffectedRecords: $numberOfAffectedRecords
        vulnerableIndividualsAffectedKnown: $vulnerableIndividualsAffectedKnown
        takenMeasures: $takenMeasures
        mitigatingActionsImplemented: $mitigatingActionsImplemented
        securedBreachedData: $securedBreachedData
        potentialConsequences: $potentialConsequences
        additionalInformation: $additionalInformation
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      breachIncident {
        additionalInformation
        affectedRecordsKnown
        awenessedDatetime
        breachMails {
          id
        }
        breachOngoing
        breachedDatetime
        causeDescription
        causes
        downloadUrl
        endedDatetime
        id
        indentifyingDetailsInIndividuals
        lastUpdated
        mitigatingActionsImplemented
        name
        natureDescription
        natures
        numberOfAffectedRecords
        personalDataCriminals
        potentialConsequences
        risk
        securedBreachedData
        specialCategoriesOfData
        takenMeasures
        vulnerableIndividualsAffectedKnown
        where
      }
    }
  }
`;
