import gql from "graphql-tag";

export const EDIT_POSITION_SECTION = gql`
  mutation($id: ID!, $position: Int!, $clientMutationId: String) {
    editPositionSection(
      input: {
        id: $id
        position: $position
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      section {
        applicable
        id
        position
        text
        title
      }
    }
  }
`;
