import gql from "graphql-tag";

export const EDIT_DP_SETTINGS = gql`
  mutation(
    $dataProcessing: DataProcessingTypeEnum
    $clientMutationId: String
  ) {
    editDataProcessingSettings(
      input: {
        dataProcessing: $dataProcessing
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      account {
        id
        dataProcessing
      }
    }
  }
`;
