import PropTypes from "prop-types";
import React from "react";

const Avatar = (props) => (
  <svg
    height={36}
    viewBox="0 0 36 36"
    width={36}
    fill="currentColor"
    {...props}
    style={{
      verticalAlign: "middle",
      color: props.color,
      ...props.style,
    }}
  >
    <g
      id="Journey-7---Help"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="1.5--contact-2" transform="translate(-1034.000000, -547.000000)">
        <g id="Chatbot" transform="translate(1018.000000, 396.000000)">
          <g id="avatar" transform="translate(16.000000, 151.000000)">
            <circle id="Oval" fill="#7450C8" cx="18" cy="18" r="18"></circle>
            <g
              id="robot"
              transform="translate(7.000000, 8.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M8,13 C7.44771525,13 7,13.4477153 7,14 C7,14.5522847 7.44771525,15 8,15 C8.55228475,15 9,14.5522847 9,14 C9,13.4477153 8.55228475,13 8,13 Z M1,12 C0.44771525,12 0,12.4477153 0,13 L0,15 C0,15.5522847 0.44771525,16 1,16 C1.55228475,16 2,15.5522847 2,15 L2,13 C2,12.4477153 1.55228475,12 1,12 Z M21,12 C20.4477153,12 20,12.4477153 20,13 L20,15 C20,15.5522847 20.4477153,16 21,16 C21.5522847,16 22,15.5522847 22,15 L22,13 C22,12.4477153 21.5522847,12 21,12 Z M16,5 L12,5 L12,3.72 C12.6150891,3.36489541 12.9957131,2.71022212 13,2 C13,0.8954305 12.1045695,0 11,0 C9.8954305,0 9,0.8954305 9,2 C9.00428692,2.71022212 9.38491093,3.36489541 10,3.72 L10,5 L6,5 C4.34314575,5 3,6.34314575 3,8 L3,17 C3,18.6568542 4.34314575,20 6,20 L16,20 C17.6568542,20 19,18.6568542 19,17 L19,8 C19,6.34314575 17.6568542,5 16,5 Z M12.72,7 L12.22,9 L9.78,9 L9.28,7 L12.72,7 Z M17,17 C17,17.5522847 16.5522847,18 16,18 L6,18 C5.44771525,18 5,17.5522847 5,17 L5,8 C5,7.44771525 5.44771525,7 6,7 L7.22,7 L8,10.24 C8.11281553,10.6971833 8.52930282,11.0137136 9,11 L13,11 C13.4706972,11.0137136 13.8871845,10.6971833 14,10.24 L14.78,7 L16,7 C16.5522847,7 17,7.44771525 17,8 L17,17 Z M14,13 C13.4477153,13 13,13.4477153 13,14 C13,14.5522847 13.4477153,15 14,15 C14.5522847,15 15,14.5522847 15,14 C15,13.4477153 14.5522847,13 14,13 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

Avatar.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Avatar;
