import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import PropTypes from "prop-types";
import { IMPORT_DATA_ENTITIES } from "../../graphql/importListOfDataEntities";
import { graphql } from "@apollo/client/react/hoc";

class CopyPasteData extends Component {
  static propTypes = {
    showImport: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
    isEmpty: PropTypes.bool,
    importDataEntities: PropTypes.func,
  };

  state = {
    importedData: null,
  };

  submitUsers = () => {
    const data = this.state.importedData.split(",");

    const reducedData = data.reduce((result, value, index, array) => {
      if (index % 4 === 0) result.push(array.slice(index, index + 4));
      return result;
    }, []);

    const pairedData = reducedData.map((item) =>
      item.map((data, index) =>
        index === 0
          ? { name: data }
          : index === 1
          ? { email: data }
          : index === 2
          ? { company: data }
          : { phoneNumber: data }
      )
    );

    this.props
      .importDataEntities({
        variables: {
          dataEntities: pairedData.map((item) => ({
            ...item[0],
            ...item[1],
            ...item[2],
            ...item[3],
          })),
        },
      })
      .then(() => {
        if (this.props.isEmpty) {
          this.props.refetchData();
        } else {
          this.props.refetchData();
          this.props.showImport();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <div className="container">
        <h2 className="import__copy-title">Import Data Subjects</h2>
        <p className="import__copy-text">
          Paste users info separated by commas following the order: name,
          e-mail, company and phone number.
          <br />
          All these fields need to be filled, so if an information is missing
          write N/A. Duplicate addresses will be removed and this new record
          will be added to your index
        </p>

        <div className="import__copy-container">
          <div className="import__copy-form">
            <Input
              type="textarea"
              placeholder="Paste user name...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-mail address...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Company (optional)...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phone number..."
              onChange={(e) => this.setState({ importedData: e.target.value })}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button
            className="import__copy-cancel"
            onClick={this.props.showImport}
          >
            Cancel
          </Button>
          <Button className="import__copy-btn" onClick={this.submitUsers}>
            Continue
          </Button>
        </div>
      </div>
    );
  }
}

export default graphql(IMPORT_DATA_ENTITIES, {
  name: "importDataEntities",
})(CopyPasteData);
