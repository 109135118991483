import gql from "graphql-tag";

export const REMOVE_USER = gql`
  mutation($ids: [ID]!) {
    removeUsers(input: { ids: $ids }) {
      errors
      account {
        id
        users {
          name
          id
          email
          phoneNumber
          avatar
          jobTitle
        }
      }
    }
  }
`;
