import PropTypes from "prop-types";
import React from "react";

const Integration = props => (
    <svg
        height={18}
        viewBox='0 0 18 18'
        width={18}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="integrations-Side-navigation-bar" transform="translate(-30.000000, -774.000000)" fill="#DFE4EE" fillRule="nonzero">
                <g id="DP-Side-navigation-bar">
                    <g id="Side-navigation-bar">
                        <g id="Group-5" transform="translate(5.500000, 774.000000)">
                            <g id="copy-outline" transform="translate(24.500000, 0.000000)">
                                <path d="M15,18 L9,18 C7.34314575,18 6,16.6568542 6,15 L6,9 C6,7.34314575 7.34314575,6 9,6 L15,6 C16.6568542,6 18,7.34314575 18,9 L18,15 C18,16.6568542 16.6568542,18 15,18 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,15 C8,15.5522847 8.44771525,16 9,16 L15,16 C15.5522847,16 16,15.5522847 16,15 L16,9 C16,8.44771525 15.5522847,8 15,8 L9,8 Z" id="Shape"></path>
                                <path d="M6.73,12 L2.67,12 C1.19767707,11.9945164 0.00548356883,10.8023229 0,9.33 L0,2.67 C0.00548356883,1.19767707 1.19767707,0.00548356883 2.67,0 L9.33,0 C10.8023229,0.00548356883 11.9945164,1.19767707 12,2.67 L12,6.4 L10,6.4 L10,2.67 C10,2.29996922 9.70003078,2 9.33,2 L2.67,2 C2.29996922,2 2,2.29996922 2,2.67 L2,9.33 C2,9.70003078 2.29996922,10 2.67,10 L6.73,10 L6.73,12 Z" id="Path"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

Integration.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default Integration;