import PropTypes from "prop-types";
import React from "react";

const WhiteCheck = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="header" transform="translate(-96.000000, -55.000000)">
                <g id="verification">
                    <g transform="translate(96.000000, 55.000000)">
                        <g id="check-circle">
                            <circle id="Oval" fill="#1DDCC0" cx="8" cy="8" r="8"></circle>
                            <path d="M10.176,5.432 L6.744,8.872 L5.424,7.552 C5.22848809,7.32369907 4.92150528,7.22425444 4.62926482,7.29455189 C4.33702436,7.36484934 4.10884934,7.59302436 4.03855189,7.88526482 C3.96825444,8.17750528 4.06769907,8.48448809 4.296,8.68 L6.176,10.568 C6.32697506,10.7177492 6.53135761,10.8012294 6.744,10.8 C6.9538719,10.7991163 7.15499149,10.7157953 7.304,10.568 L11.304,6.568 C11.4554501,6.41778672 11.5406387,6.21331003 11.5406387,6 C11.5406387,5.78668997 11.4554501,5.58221328 11.304,5.432 C10.99197,5.12182112 10.48803,5.12182112 10.176,5.432 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

WhiteCheck.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default WhiteCheck;