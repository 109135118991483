const jobs = [
	"Assistant",
	"Associate",
	"Administrative Assistant",
	"Account Manager",
	"Assistant Manager",
	"Accountant",
	"Analyst",
	"Account Executive",
	"Business Specialist",
	"Business Development Manager",
	"Board Member",
	"Branch Manager",
	"Business Manager",
	"Bank Employee",
	"Bartender",
	"Consultant",
	"Chief Executive Officer",
	"Customer Service Representative",
	"Customer Service Specialist",
	"Coordinator",
	"Cashier",
	"Corporate Specialist",
	"Co-Founder",
	"Director",
	"Development Specialist",
	"Designer",
	"Business Development Specialist",
	"Design Specialist",
	"Driver",
	"Digital Specialist",
	"Executive",
	"Engineer",
	"Executive Assistant",
	"Founder",
	"Finance Specialist",
	"Field Specialist",
	"Finance Manager",
	"Food Server",
	"Financial Analyst",
	"Financial Advisor",
	"Food Specialist",
	"General Manager",
	"Graphic Designer",
	"General",
	"Graduate",
	"Graduate Student",
	"Graduate Research Assistant",
	"Guest Services Specialist",
	"Head",
	"Human Resources Specialist",
	"Human Recources Manager",
	"Homemaker",
	"Human Resources Assistant",
	"Hostess",
	"Healthcare Specialist",
	"Human Resources Director",
	"Human Resources Executive",
	"Human Resources Consultant",
	"Intern",
	"Instructor",
	"Information Technology Specialist",
	"International Specialist",
	"Information Specialist",
	"Information Technology Manager",
	"Journalist",
	"Java Software Engineer",
	"Disk Jockey",
	"Junior Engineer",
	"Junior Software Engineer",
	"Java Specialist",
	"Janitor",
	"Journeyman",
	"Judge",
	"Junior Accountant",
	"Key Account Manager",
	"Key Account",
	"Keyholder",
	"Kindergarten Teacher",
	"Kitchen Manager",
	"Knowledge Specialist",
	"Keeper",
	"Kitchen Staff",
	"Lecturer",
	"Lead",
	"Logistics Specialist",
	"Lawyer",
	"Legal Assistant",
	"Laboratory Specialist",
	"Manager",
	"Managing Director",
	"Marketing Specialist",
	"Nurse",
	"Network Specialist",
	"Network Engineer",
	"Network Administrator",
	"National Sales Specialist",
	"Nursing Manager",
	"Owner",
	"Operational Specialist",
	"Operations Manager",
	"Office Manager",
	"Operator",
	"Officer",
	"Office Associate",
	"Office Assistant",
	"Project Manager",
	"President",
	"Project Specialist",
	"Principal",
	"Program Specialist",
	"Partner",
	"Project Engineer ",
	"Professor",
	"Product Specialist",
	"Quality Assurance Specialist",
	"Quality Specialist",
	"Quality Assurance Manager",
	"Quality Engineer",
	"Quality Assurance Engineer",
	"Quality Assurance Analyst",
	"Quality Surveyor",
	"Quality Analyst",
	"Quality Control Inspector",
	"Quality Control Engineer",
	"Retired",
	"Registered Nurse",
	"Researcher",
	"Research Assistant",
	"Receptionist",
	"Representative",
	"Realtor",
	"Software Engineer",
	"Salesperson",
	"Sales Specialist",
	"Sales Manager",
	"Supervisor",
	"Student",
	"Teacher",
	"Technician",
	"Team Lead",
	"Team Specialist",
	"Training Specialist",
	"Technical Specialist",
	"Teaching Assistant",
	"Trainee",
	"Technology Specialist",
	"Unemployed",
	"Underwriter",
	"Undergraduate Research Assistant",
	"Utilities Specialist",
	"User Experience Design",
	"Undergraduate",
	"Undergraduate Research Fellow",
	"Unit Manager",
	"User Interface Designer",
	"Vice President",
	"Volunteer",
	"Vice President of Operations",
	"Vice President of Sales",
	"Vice President of Marketing",
	"Vice President of Business Development",
	"Writer",
	"Web Developer",
	"Worker",
	"Web Designer",
	"Web Specialist",
	"Warehouse Specialist",
	"Welder",
	"Warehouse Associate",
	"Xray Technician",
	"Xray Technologist",
	"Youth Specialist",
	"Yoga Instructor",
	"Youth Worker",
	"Yoga Teacher",
	"Youth Director",
	"Youth Development Specialist",
	"Youth Pastor",
	"Youth Coordinator",
	"Youth Counselor",
	"Zumba Instructor",
	"Zone Manager",
	"Zonal Manager",
	"Zonal Sales Manager",
	"Zonal Head",
	"Zone Sales Manager",
	"Zonal Business Manager",
	"Zoning Administrator",
	"Other"
];

export default jobs;
