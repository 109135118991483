import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";

function CustomizeSellFooter(props) {
  const { legalsite, pages, type, titleColor, titleFont, titleSize, sellBg } =
    props;

  return (
    <div
      className="footer"
      style={{
        background: legalsite.footerBg,
      }}
    >
      <div
        className="footer__container"
        style={{
          background: legalsite.footerBg,
        }}
      >
        <Row className="w-100">
          <Col sm={12} md={3} className="d-flex flex-column">
            <span className="d-flex align-items-center text-decoration-none">
              {props.legalsiteSettings &&
              props.legalsiteSettings.companyLogo &&
              legalsite &&
              !legalsite.footerLogo ? (
                <div className="footer__logo-container">
                  <img src={props.legalsiteSettings.companyLogo} alt="logo" />
                </div>
              ) : legalsite && legalsite.footerLogo ? (
                <div className="footer__logo-container">
                  <img src={legalsite.footerLogo} alt="logo" />
                </div>
              ) : (
                <span className="footer__logo" />
              )}
            </span>
          </Col>

          <Col sm={12} md={3} className="d-flex flex-column">
            <p
              className="mb-4"
              style={{
                color: legalsite.footerTextColor,
                fontSize: legalsite.footerTextSize,
                fontFamily: legalsite.footerTextFont,
                textAlign: legalsite.footerTextAlign,
                fontWeight: legalsite.footerTextBold ? 900 : 300,

                fontStyle: legalsite.footerTextItalic ? "italic" : "normal",
              }}
            >
              <strong>Website</strong>
            </p>
            <p
              className="mb-4"
              style={{
                color: legalsite.footerLinksColor,
                fontSize: legalsite.footerLinksSize,
                fontFamily: legalsite.footerLinksFont,
                textAlign: legalsite.footerLinksAlign,
                fontWeight: legalsite.footerLinksBold ? 900 : 300,
                fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
              }}
            >
              Homepage
            </p>
            <p
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.legalsite.co/"
              className="mb-4"
              style={{
                color: legalsite.footerLinksColor,
                fontSize: legalsite.footerLinksSize,
                fontFamily: legalsite.footerLinksFont,
                textAlign: legalsite.footerLinksAlign,
                fontWeight: legalsite.footerLinksBold ? 900 : 300,
                fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
              }}
            >
              Go back to main website
            </p>
            <p
              className="mb-4"
              style={{
                color: legalsite.footerLinksColor,
                fontSize: legalsite.footerLinksSize,
                fontFamily: legalsite.footerLinksFont,
                textAlign: legalsite.footerLinksAlign,
                fontWeight: legalsite.footerLinksBold ? 900 : 300,
                fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
              }}
            >
              Legal Information
            </p>
          </Col>

          <Col sm={12} md={3} className="d-flex flex-column">
            <p
              className="mb-4"
              style={{
                color: legalsite.footerTextColor,
                fontSize: legalsite.footerTextSize,
                fontFamily: legalsite.footerTextFont,
                textAlign: legalsite.footerTextAlign,
                fontWeight: legalsite.footerTextBold ? 900 : 300,

                fontStyle: legalsite.footerTextItalic ? "italic" : "normal",
              }}
            >
              <strong>Legal Pages</strong>
            </p>
            {pages
              ? pages.map((item) => (
                  <p
                    className="mb-4"
                    style={{
                      color: legalsite.footerLinksColor,
                      fontSize: legalsite.footerLinksSize,
                      fontFamily: legalsite.footerLinksFont,
                      textAlign: legalsite.footerLinksAlign,
                      fontWeight: legalsite.footerLinksBold ? 900 : 300,
                      fontStyle: legalsite.footerLinksItalic
                        ? "italic"
                        : "normal",
                    }}
                    key={item.id}
                  >
                    {item.title}
                  </p>
                ))
              : null}
          </Col>

          <Col sm={12} md={3} className="d-flex flex-column">
            <p
              className="mb-4"
              style={{
                color: legalsite.footerTextColor,
                fontSize: legalsite.footerTextSize,
                fontFamily: legalsite.footerTextFont,
                textAlign: legalsite.footerTextAlign,
                fontWeight: legalsite.footerTextBold ? 900 : 300,

                fontStyle: legalsite.footerTextItalic ? "italic" : "normal",
              }}
            >
              <strong>Manage your information</strong>
            </p>
            <p
              className="mb-4"
              style={{
                color: legalsite.footerLinksColor,
                fontSize: legalsite.footerLinksSize,
                fontFamily: legalsite.footerLinksFont,
                textAlign: legalsite.footerLinksAlign,
                fontWeight: legalsite.footerLinksBold ? 900 : 300,
                fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
              }}
            >
              Manage Consent
            </p>
            <p
              className="mb-4"
              style={{
                color: legalsite.footerLinksColor,
                fontSize: legalsite.footerLinksSize,
                fontFamily: legalsite.footerLinksFont,
                textAlign: legalsite.footerLinksAlign,
                fontWeight: legalsite.footerLinksBold ? 900 : 300,
                fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
              }}
            >
              Send request
            </p>
            <div
              className={`d-flex ${
                legalsite && legalsite.footerLinksAlign !== null
                  ? legalsite.footerLinksAlign === "center"
                    ? "justify-content-center"
                    : legalsite.footerLinksAlign === "left"
                    ? "justify-content-start"
                    : "justify-content-end"
                  : null
              }`}
            >
              <p
                className={`${type === "contained" ? "-is-contained" : ""}`}
                style={{
                  background: type === "contained" ? sellBg : null,
                  color: titleColor ? titleColor : "#fff",
                  fontSize: titleSize ? titleSize : 14,
                  fontFamily: titleFont ? titleFont : "Roboto",
                  textAlign: legalsite.footerLinksAlign,
                  fontWeight: legalsite.footerLinksBold ? 900 : 300,
                  fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
                }}
              >
                Do Not Sell My Info
              </p>
            </div>
          </Col>
        </Row>
      </div>

      <div
        className="footer-text"
        style={{
          background: legalsite.footerBg,
        }}
      >
        <span>
          <span
            style={{
              color: legalsite.footerTextColor,
              fontSize: legalsite.footerTextSize,
              fontFamily: legalsite.footerTextFont,
              textAlign: legalsite.footerTextAlign,
              fontWeight: legalsite.footerTextBold ? 900 : 300,

              fontStyle: legalsite.footerTextItalic ? "italic" : "normal",
            }}
          >
            © {new Date().getFullYear()} LegalSite BV. All rights reserved.
          </span>{" "}
          <span
            style={{
              color: legalsite.footerLinksColor,
              fontSize: legalsite.footerLinksSize,
              fontFamily: legalsite.footerLinksFont,
              textAlign: legalsite.footerLinksAlign,
              fontWeight: legalsite.footerLinksBold ? 900 : 300,
              fontStyle: legalsite.footerLinksItalic ? "italic" : "normal",
            }}
          >
            <u>Get your LegalSite!</u>
          </span>
        </span>
      </div>
    </div>
  );
}

export default CustomizeSellFooter;
CustomizeSellFooter.propTypes = {
  legalsite: PropTypes.object.isRequired,
  pages: PropTypes.array,
  type: PropTypes.string,
  titleColor: PropTypes.string,
  titleFont: PropTypes.string,
  titleSize: PropTypes.string,
  sellBg: PropTypes.string,
  legalsiteSettings: PropTypes.object,
};
