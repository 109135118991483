import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import "./graph.scss";

export default class Graph extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    getGraphType: PropTypes.func.isRequired,
    getGraphInterval: PropTypes.func.isRequired,
  };

  state = {
    isLegalVisible: true,
    isPendingVisible: false,
    isRequestsVisible: false,
    isSolvedVisible: false,
    isUserVisible: false,
    graphInterval: "WEEK",
  };

  render() {
    const {
      isLegalVisible,
      isUserVisible,
      isSolvedVisible,
      isRequestsVisible,
      isPendingVisible,
      graphInterval,
    } = this.state;
    const { data } = this.props;

    const legalSeries = data.requests,
      userSeriesData = data.requests;

    const options = {
        chart: {
          zoom: {
            enabled: false,
          },
        },
        colors: ["#7450c8"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: [7, 7],
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        xaxis: {
          categories: data.intervals,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      },
      optionsScattered = {
        colors: ["#EBA601"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: [7, 7],
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
        },
        xaxis: {
          categories: data.intervals,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
      },
      legalSiteSeries = [
        {
          name: "Visitors",
          data: legalSeries,
        },
      ],
      solvedSeries = [
        {
          name: "Requests",
          data: userSeriesData,
        },
      ];

    return (
      <div className="chart container">
        <div className="chart__tabs d-flex justify-content-between">
          <div className="d-flex h-100">
            <div
              className={`chart__tab ${isLegalVisible ? "-is-active" : null}`}
              onClick={() => {
                this.setState({
                  isLegalVisible: true,
                  isUserVisible: false,
                  isSolvedVisible: false,
                  isRequestsVisible: false,
                  isPendingVisible: false,
                  data: data.requests,
                });
                this.props.getGraphType("VISITORS");
              }}
            >
              <p className="chart__tab-text">Your LegalSite visitors</p>
            </div>

            <Fragment>
              <div
                className={`chart__tab ${isUserVisible ? "-is-active" : null}`}
                onClick={() => {
                  this.setState({
                    isLegalVisible: false,
                    isUserVisible: true,
                    isSolvedVisible: false,
                    isRequestsVisible: false,
                    isPendingVisible: false,
                  });
                  this.props.getGraphType("ALL");
                }}
              >
                <p className="chart__tab-text">Total Requests</p>
              </div>

              <div
                className={`chart__tab ${
                  isSolvedVisible ? "-is-active" : null
                }`}
                onClick={() => {
                  this.setState({
                    isLegalVisible: false,
                    isUserVisible: false,
                    isSolvedVisible: true,
                    isRequestsVisible: false,
                    isPendingVisible: false,
                  });
                  this.props.getGraphType("SOLVED");
                }}
              >
                <p className="chart__tab-text">Solved requests</p>
              </div>

              <div
                className={`chart__tab ${
                  isRequestsVisible ? "-is-active" : null
                }`}
                onClick={() => {
                  this.setState({
                    isLegalVisible: false,
                    isUserVisible: false,
                    isSolvedVisible: false,
                    isRequestsVisible: true,
                    isPendingVisible: false,
                  });
                  this.props.getGraphType("IN_PROGRESS");
                }}
              >
                <p className="chart__tab-text">Requests in progress</p>
              </div>

              <div
                className={`chart__tab ${
                  isPendingVisible ? "-is-active" : null
                }`}
                onClick={() => {
                  this.setState({
                    isLegalVisible: false,
                    isUserVisible: false,
                    isSolvedVisible: false,
                    isRequestsVisible: false,
                    isPendingVisible: true,
                  });
                  this.props.getGraphType("PENDING");
                }}
              >
                <p className="chart__tab-text">Pending Requests</p>
              </div>
            </Fragment>
          </div>

          <UncontrolledDropdown className="chart__dropdown">
            <DropdownToggle className="w-100 d-flex align-items-center chart__toggle">
              Past{" "}
              {graphInterval === "WEEK"
                ? "Week"
                : graphInterval === "MONTH"
                ? "Month"
                : "Year"}{" "}
              <FaCaret className="chart__caret" size="20" color="#969696" />
            </DropdownToggle>
            <DropdownMenu>
              {graphInterval !== "WEEK" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getGraphInterval("WEEK");
                    this.setState({ graphInterval: "WEEK" });
                  }}
                >
                  Past Week
                </DropdownItem>
              ) : null}

              {graphInterval !== "MONTH" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getGraphInterval("MONTH");
                    this.setState({ graphInterval: "MONTH" });
                  }}
                >
                  Past Month
                </DropdownItem>
              ) : null}

              {graphInterval !== "YEAR" ? (
                <DropdownItem
                  onClick={() => {
                    this.props.getGraphInterval("YEAR");
                    this.setState({ graphInterval: "YEAR" });
                  }}
                >
                  Past Year
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="chart__container">
          <p className="chart__legend d-flex align-items-center">
            <span
              className={`chart__marker mr-2 ${
                isLegalVisible ? "-is-yellow" : "-is-purple"
              }`}
            ></span>
            {isLegalVisible
              ? "LegalSite visitors"
              : isUserVisible
              ? "Total Requests"
              : isSolvedVisible
              ? "Solved Requests"
              : isRequestsVisible
              ? "Requests in progress"
              : "Pending Requests"}
          </p>

          <ReactApexChart
            className={`${isLegalVisible ? "d-none" : ""}`}
            options={options}
            series={!isLegalVisible ? solvedSeries : legalSiteSeries}
            type="line"
            height="356"
          />

          <ReactApexChart
            className={`${!isLegalVisible ? "d-none" : ""}`}
            options={optionsScattered}
            series={isLegalVisible ? legalSiteSeries : solvedSeries}
            type="line"
            height="356"
          />
        </div>
      </div>
    );
  }
}
