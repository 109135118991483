import React, { Component, Fragment } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import Switch from "react-switch";
import { EDIT_PIA_NOTIFICATION } from "../../graphql/editPiaNotificationsInterval";
import { graphql } from "@apollo/client/react/hoc";
import PropTypes from "prop-types";

class ComplianceNotificationItem extends Component {
  static propTypes = {
    notificationInterval: PropTypes.string,
    editNotification: PropTypes.func,
  };

  state = {
    interval:
      this.props.notificationInterval &&
      this.props.notificationInterval === "ONEYEAR"
        ? "Every Year"
        : "Every 3 Years",
    isChecked:
      this.props.notificationInterval === "ONEYEAR" ||
      this.props.notificationInterval === "TREEYEARS"
        ? true
        : false,
  };

  setInterval = (interval) => {
    this.props
      .editNotification({
        variables: {
          interval:
            interval === false
              ? "DISABLED"
              : interval === "Every 3 Years"
              ? "ONEYEAR"
              : "TREEYEARS",
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
        this.notify("PIA Notification couldn't be set");
      });
  };

  render() {
    const { interval, isChecked } = this.state;

    return (
      <Fragment>
        <p className="compliance-privacy__item-title">Notification</p>
        <p className="compliance-privacy__item-text">
          Regulations require that this process must be repeated every three
          years.
        </p>
        <div className="d-flex align-items-center flex-wrap">
          <span className="compliance-privacy__item-label">
            Remind me to review the checklist:
          </span>
          <Switch
            onColor={"#1DDCC0"}
            offColor={"#B0BAC9"}
            height={20}
            width={36}
            uncheckedIcon={false}
            className="mr-2 ml-3 my-sm-4"
            checkedIcon={false}
            onChange={() => {
              this.setState({ isChecked: !isChecked });
              this.setInterval(!isChecked);
            }}
            checked={isChecked}
          />

          <UncontrolledDropdown className="compliance-privacy__dropdown">
            <DropdownToggle className="w-100 d-flex align-items-center compliance-privacy__toggle">
              {interval === "Every Year" ? "Every Year" : "Every 3 Years"}{" "}
              <FaCaret
                className="compliance-privacy__caret"
                size="20"
                color="#969696"
              />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  this.setInterval(interval);
                  this.setState({
                    interval:
                      interval === "Every Year"
                        ? "Every 3 Years"
                        : "Every Year",
                    isChecked: true,
                  });
                }}
              >
                {interval !== "Every Year" ? "Every Year" : "Every 3 Years"}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </Fragment>
    );
  }
}

export default graphql(EDIT_PIA_NOTIFICATION, {
  name: "editNotification",
})(ComplianceNotificationItem);
