import React, { Component, Fragment } from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import SyncLoader from "react-spinners/SyncLoader";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import "./history.scss";
import ActivityTabs from "./ActivityTabs";
import EmptyHistory from "./EmptyHistory";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import Navbar from "../../components/Navbar/Navbar";
import MdClose from "react-icons/lib/md/close";
import MenuContent from "../../components/MenuContent/MenuContent";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import { graphql } from "@apollo/client/react/hoc";
import { HISTORY_QUERY } from "../../graphql/history";
import Moment from "react-moment";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class History extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    setPageName: PropTypes.func,
  };

  state = {
    filter: "CREATE_ACCOUNT",
    isRighMenuOpen: false,
    statusFilter: "ALL",
    last5Days: null,
  };

  componentDidMount() {
    this.last5Days();
  }

  notify = (text) => toast(text);

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  last5Days = () => {
    var result = [];
    for (var i = 0; i < 5; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(d);
    }

    this.setState({
      last5Days: result,
    });

    return result.join(",");
  };

  refetchData = () => {
    this.props.data.refetch();
  };

  render() {
    const {
      account,
      historyRequests,
      historyActivity,
      historyAgreements,
      loading,
      error,
    } = this.props.data;

    let monthToday = this.state.last5Days ? this.state.last5Days[0] : "";
    let month5DaysAgo = this.state.last5Days ? this.state.last5Days[4] : "";

    monthToday = <Moment format={"MMM"}>{monthToday}</Moment>;
    month5DaysAgo = <Moment format={"MMM"}>{month5DaysAgo}</Moment>;
    const yearToday = <Moment format={"YYYY"}>{monthToday}</Moment>;
    const year5DaysAgo = <Moment format={"YYYY"}>{month5DaysAgo}</Moment>;

    const showMonth = month5DaysAgo === monthToday ? month5DaysAgo : "";
    const showYear = year5DaysAgo === yearToday ? year5DaysAgo : "";

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return (
      <div className="d-flex position-relative">
        <SideMenu />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="History"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>
          <div
            className={`${
              account && account.needToUpgrade ? "-has-banner" : ""
            } history`}
          >
            {(historyRequests && historyRequests.length) ||
            (historyActivity && historyActivity.length) ||
            (historyAgreements && historyAgreements.length) ? (
              <div className="container history__container">
                <span className="history__date container">
                  {monthToday} {showMonth} {yearToday} {showYear}
                </span>

                {this.state.last5Days
                  ? this.state.last5Days.map((item, index) => (
                      <Fragment key={index}>
                        <ActivityTabs
                          date={item}
                          day={<Moment format="DD">{item}</Moment>}
                          month={<Moment format="MMM">{item}</Moment>}
                          wholeMonth={<Moment format="MMMM">{item}</Moment>}
                          year={<Moment format="YYYY">{item}</Moment>}
                          historyRequests={historyRequests}
                          historyActivity={historyActivity}
                          historyAgreements={historyAgreements}
                          refetchData={this.refetchData}
                          notify={() =>
                            this.notify(this.showDownloadNotifyText())
                          }
                          notifyMsg={(msg) => this.notify(msg)}
                        />
                      </Fragment>
                    ))
                  : null}
              </div>
            ) : (
              <EmptyHistory
                openMenu={() => this.setState({ isRighMenuOpen: true })}
              />
            )}
          </div>
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />
      </div>
    );
  }
}

export default graphql(HISTORY_QUERY, {
  options: () => ({
    variables: {
      status: "ALL",
      daysAgo: 5,
    },
    fetchPolicy: "no-cache",
  }),
})(History);
