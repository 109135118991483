import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import collect from "../../images/Settings/collect.svg";

export default class CollectModal extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isModalOpen}
          toggle={this.props.openModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openModal}
            />

            <img src={collect} alt="collect" />
            <p className="modal-assign__title mb-3">
              Collect styling from my website
            </p>
            <p className="modal-assign__text mb-0 mt-0">
              Enter your website domain on the field below and your assets will
              be automatically updated with your website colors, fonts and logo.
            </p>

            <Form className="mt-4">
              <FormGroup>
                <Label className="modal-assign__text mb-2 mt-2">
                  Website url
                </Label>
                <Input type="text" name="website" placeholder="Website url" />
              </FormGroup>
              <div className="discard-changes__btns">
                <Button
                  className="btn--secondary"
                  onClick={this.props.openModal}
                >
                  Cancel
                </Button>
                <Button className="btn--primary" onClick={() => {}}>
                  Collect
                </Button>
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
