import gql from "graphql-tag";

export const COOKIE_QUERY = gql`
  query($popupCategory: String!) {
    popup(category: $popupCategory) {
      backgroundColor
      body
      bodyColor
      bodyFont
      bodySize
      buttonColor
      buttonFont
      buttonShapes
      buttonSize
      overlay
      popupPosition
      popupSide
      template
      snippet
      title
      titleColor
      titleFont
      titleSize
      buttonText
      catchEmail
      buttonBgColor
      catchSignature
      destinationPage
      secondaryButtonColor
      secondaryButtonFont
      secondaryButtonSize
      secondaryButtonText
      verifyUser
      showLegalsiteBranding
      titleBold
      titleAlign
      titleItalic
      bodyAlign
      bodyBold
      bodyItalic
      buttonBold
      buttonItalic
    }
    dataProtectionOfficer {
      id
      show
    }
    legalsite {
      bodyBg
      bodyBtnAlign
      bodyBtnBold
      bodyBtnColor
      bodyBtnFont
      bodyBtnBg
      bodyBtnItalic
      bodyBtnSize
      bodyIconsColor
      bodySubtitleAlign
      bodySubtitleBold
      bodySubtitleColor
      bodySubtitleFont
      bodySubtitleItalic
      bodySubtitleSize
      bodyTextAlign
      bodyTextBold
      bodyTextColor
      bodyTextFont
      bodyTextItalic
      bodyTextSize
      bodyTitleAlign
      bodyTitleBold
      bodyTitleColor
      bodyTitleFont
      bodyTitleItalic
      bodyTitleSize
      cardsBg
      cardsBodyAlign
      cardsBodyBold
      cardsBodyColor
      cardsBodyFont
      cardsBodyItalic
      cardsBodySize
      cardsBtnAlign
      cardsBtnBg
      cardsBtnBold
      cardsBtnColor
      cardsBtnFont
      cardsBtnItalic
      cardsBtnSize
      cardsIconsColor
      cardsTitleAlign
      cardsTitleBold
      cardsTitleColor
      cardsTitleFont
      cardsTitleItalic
      cardsTitleSize
      footerBg
      footerLinksAlign
      footerLinksBold
      footerLinksColor
      footerLinksFont
      footerLinksItalic
      footerLinksSize
      footerLogo
      footerTextAlign
      footerTextBold
      footerTextColor
      footerTextFont
      footerTextItalic
      footerTextSize
      headerBg
      headerBodyAlign
      headerBodyBold
      headerBodyColor
      headerBodyFont
      headerBodyItalic
      headerBodySize
      headerTitleAlign
      headerTitleBold
      headerTitleColor
      headerTitleFont
      headerTitleItalic
      homepageTitle
      homepageDescription
      headerTitleSize
      navigationBg
      navigationLinksAlign
      navigationLinksBold
      navigationLinksColor
      navigationLinksFont
      navigationLinksItalic
      navigationLinksSize
      showLegalsiteBranding
    }
    legalsiteSettings {
      companyLogo
      id
    }
    pages {
      applicable
      description
      id
      publications {
        id
      }
      sections {
        applicable
        id
        text
        title
      }
      status
      title
      updatedAt
    }
  }
`;
