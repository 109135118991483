import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Col, Row } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import "./modals.scss";
import ManageConsentItem from "../../containers/ManageConsent/ManageConsentItem";
import FontLoader from "../../containers/Settings/FontLoader";
import PreviewHeader from "../PreviewHeader/PreviewHeader";

class PreviewConsent extends Component {
  static propTypes = {
    showPreviewModal: PropTypes.func.isRequired,
    isPreviewModalVisible: PropTypes.bool.isRequired,
    consents: PropTypes.array.isRequired,
    selectedConsents: PropTypes.array.isRequired,
    legalsite: PropTypes.object,
    pages: PropTypes.array,
    descr: PropTypes.string,
    dontSellMyDataButton: PropTypes.object,
    legalsiteSettings: PropTypes.object,
    footerLinkAlign: PropTypes.string,
    footerLinkBold: PropTypes.bool,
    footerLinkItalic: PropTypes.bool,
  };

  state = {};

  render() {
    const {
      consents,
      selectedConsents,
      legalsite,
      pages,
      descr,
      dontSellMyDataButton,
    } = this.props;

    return (
      <div>
        <Modal
          fade={false}
          isOpen={this.props.isPreviewModalVisible}
          toggle={this.props.showPreviewModal}
          className="modal-preview modal-manage-consent"
        >
          <ModalBody className="overflow-auto">
            <FontLoader
              font1={
                legalsite && legalsite.headerTitleFont
                  ? legalsite.headerTitleFont
                  : "Roboto"
              }
              font2={
                legalsite && legalsite.headerBodyFont
                  ? legalsite.headerBodyFont
                  : "Roboto"
              }
              font3={
                legalsite && legalsite.footerLinksFont
                  ? legalsite.footerLinksFont
                  : "Roboto"
              }
              font4={
                legalsite && legalsite.footerTextFont
                  ? legalsite.footerTextFont
                  : "Roboto"
              }
            />

            <FontLoader
              font1={
                legalsite && legalsite.headerTitleFont
                  ? legalsite.headerTitleFont
                  : "Roboto"
              }
              font2={
                legalsite && legalsite.headerBodyFont
                  ? legalsite.headerBodyFont
                  : "Roboto"
              }
              font3={
                legalsite && legalsite.footerLinksFont
                  ? legalsite.footerLinksFont
                  : "Roboto"
              }
              font4={
                dontSellMyDataButton && dontSellMyDataButton.textFont
                  ? dontSellMyDataButton.textFont
                  : "Roboto"
              }
            />
            <div className="modal-preview__header">
              <h3 className="modal-preview__title">Preview Mode</h3>
              <div
                className="modal-preview__close-btn"
                onClick={this.props.showPreviewModal}
              >
                <span className="modal-preview__exit mr-2">Exit</span>
                <MdClose
                  className="modal-assign__close"
                  color={"#fff"}
                  size={25}
                />
              </div>
            </div>

            <PreviewHeader isConsent={true} />

            <div className="manage-consent">
              <div
                className="manage-consent__container"
                style={{
                  background:
                    legalsite && legalsite.bodyBg
                      ? legalsite.bodyBg
                      : "#f4f6fc",
                }}
              >
                <div
                  className="manage-consent__header"
                  style={{
                    background:
                      legalsite && legalsite.headerBg
                        ? legalsite.headerBg
                        : "#dfe4ee",
                  }}
                >
                  <div className="container">
                    <h1
                      className="manage-consent__header-title"
                      style={{
                        color:
                          legalsite && legalsite.headerTitleColor
                            ? legalsite.headerTitleColor
                            : "#333",
                        fontSize:
                          legalsite && legalsite.headerTitleSize
                            ? legalsite.headerTitleSize
                            : 56,
                        fontFamily:
                          legalsite && legalsite.headerTitleFont
                            ? legalsite.headerTitleFont
                            : null,
                        textAlign:
                          legalsite && legalsite.headerTitleAlign !== null
                            ? legalsite.headerTitleAlign
                            : null,
                        fontWeight:
                          legalsite && legalsite.headerTitleBold !== null
                            ? legalsite.headerTitleBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          legalsite && legalsite.headerTitleItalic !== null
                            ? legalsite.headerTitleItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      Manage Consent
                    </h1>
                    <p
                      className="manage-consent__header-text"
                      style={{
                        color:
                          legalsite && legalsite.headerBodyColor
                            ? legalsite.headerBodyColor
                            : "#969696",
                        fontSize:
                          legalsite && legalsite.headerBodySize
                            ? legalsite.headerBodySize
                            : 16,
                        fontFamily:
                          legalsite && legalsite.headerBodyFont
                            ? legalsite.headerBodyFont
                            : null,
                        textAlign:
                          legalsite && legalsite.headerBodyAlign !== null
                            ? legalsite.headerBodyAlign
                            : null,
                        fontWeight:
                          legalsite && legalsite.headerBodyBold !== null
                            ? legalsite.headerBodyBold === true
                              ? 900
                              : 500
                            : 500,
                        fontStyle:
                          legalsite && legalsite.headerBodyItalic !== null
                            ? legalsite.headerBodyItalic === true
                              ? "italic"
                              : "normal"
                            : "normal",
                      }}
                    >
                      {descr}
                    </p>
                  </div>
                </div>

                <div className="manage-consent__items container">
                  {selectedConsents
                    ? consents.map((item, i) =>
                        selectedConsents[i].enabled ? (
                          <ManageConsentItem
                            key={item.id}
                            title={item.categoryName}
                            text={item.smallDescription}
                            hasToggle={item.manageableByVisitor}
                            completeDescription={item.completeDescription}
                            isChecked={true}
                          />
                        ) : null
                      )
                    : consents.map((item) =>
                        item.enabled ? (
                          <ManageConsentItem
                            key={item.id}
                            dp={item.dataProcessorConsents}
                            title={item.categoryName}
                            text={item.smallDescription}
                            hasToggle={item.manageableByVisitor}
                            completeDescription={item.completeDescription}
                            isChecked={true}
                          />
                        ) : null
                      )}
                </div>
              </div>
              <div
                className="footer"
                style={{
                  background:
                    legalsite && legalsite.footerBg
                      ? legalsite.footerBg
                      : "#dfe4ee",
                }}
              >
                <div
                  className="footer__container"
                  style={{
                    background:
                      legalsite && legalsite.footerBg
                        ? legalsite.footerBg
                        : "#dfe4ee",
                  }}
                >
                  <Row className="w-100">
                    <Col sm={12} md={3} className="d-flex flex-column">
                      <span className="d-flex align-items-center text-decoration-none">
                        {this.props.legalsiteSettings &&
                        this.props.legalsiteSettings.companyLogo &&
                        legalsite &&
                        !legalsite.footerLogo ? (
                          <div className="footer__logo-container">
                            <img
                              src={this.props.legalsiteSettings.companyLogo}
                              alt="logo"
                            />
                          </div>
                        ) : legalsite && legalsite.footerLogo ? (
                          <div className="footer__logo-container">
                            <img src={legalsite.footerLogo} alt="logo" />
                          </div>
                        ) : (
                          <span className="footer__logo" />
                        )}
                      </span>
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Website</strong>
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Homepage
                      </p>
                      <p
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://app.legalsite.co/"
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Go back to main website
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Legal Information
                      </p>
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Legal Pages</strong>
                      </p>
                      {pages
                        ? pages.map((item) => (
                            <p
                              className="mb-4"
                              style={{
                                color:
                                  legalsite && legalsite.footerLinksColor
                                    ? legalsite.footerLinksColor
                                    : "#333",
                                fontSize:
                                  legalsite && legalsite.footerLinksSize
                                    ? legalsite.footerLinksSize
                                    : null,
                                fontFamily:
                                  legalsite && legalsite.footerLinksFont
                                    ? legalsite.footerLinksFont
                                    : null,
                                textAlign:
                                  legalsite &&
                                  legalsite.footerLinksAlign !== null
                                    ? legalsite.footerLinksAlign
                                    : null,
                                fontWeight:
                                  legalsite &&
                                  legalsite.footerLinksBold !== null
                                    ? legalsite.footerLinksBold === true
                                      ? 900
                                      : 500
                                    : 500,
                                fontStyle:
                                  legalsite &&
                                  legalsite.footerLinksItalic !== null
                                    ? legalsite.footerLinksItalic === true
                                      ? "italic"
                                      : "normal"
                                    : "normal",
                              }}
                              key={item.id}
                            >
                              {item.title}
                            </p>
                          ))
                        : null}
                    </Col>

                    <Col sm={12} md={3} className="d-flex flex-column">
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerTextColor
                              ? legalsite.footerTextColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerTextSize
                              ? legalsite.footerTextSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerTextFont
                              ? legalsite.footerTextFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerTextAlign !== null
                              ? legalsite.footerTextAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerTextBold !== null
                              ? legalsite.footerTextBold === true
                                ? 900
                                : 500
                              : 500,

                          fontStyle:
                            legalsite && legalsite.footerTextItalic !== null
                              ? legalsite.footerTextItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        <strong>Manage your information</strong>
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Manage Consent
                      </p>
                      <p
                        className="mb-4"
                        style={{
                          color:
                            legalsite && legalsite.footerLinksColor
                              ? legalsite.footerLinksColor
                              : "#333",
                          fontSize:
                            legalsite && legalsite.footerLinksSize
                              ? legalsite.footerLinksSize
                              : null,
                          fontFamily:
                            legalsite && legalsite.footerLinksFont
                              ? legalsite.footerLinksFont
                              : null,
                          textAlign:
                            legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                          fontWeight:
                            legalsite && legalsite.footerLinksBold !== null
                              ? legalsite.footerLinksBold === true
                                ? 900
                                : 500
                              : 500,
                          fontStyle:
                            legalsite && legalsite.footerLinksItalic !== null
                              ? legalsite.footerLinksItalic === true
                                ? "italic"
                                : "normal"
                              : "normal",
                        }}
                      >
                        Send request
                      </p>
                      <div
                        className={`d-flex ${
                          legalsite && legalsite.footerLinksAlign !== null
                            ? legalsite.footerLinksAlign === "center"
                              ? "justify-content-center"
                              : legalsite.footerLinksAlign === "left"
                              ? "justify-content-start"
                              : "justify-content-end"
                            : null
                        }`}
                      >
                        <p
                          className={`${
                            (dontSellMyDataButton &&
                              dontSellMyDataButton.buttonType ===
                                "contained") ||
                            (dontSellMyDataButton &&
                              !dontSellMyDataButton.buttonType)
                              ? "-is-contained"
                              : ""
                          }`}
                          style={{
                            background:
                              (dontSellMyDataButton &&
                                dontSellMyDataButton.buttonType ===
                                  "contained") ||
                              (dontSellMyDataButton &&
                                !dontSellMyDataButton.buttonType)
                                ? dontSellMyDataButton &&
                                  dontSellMyDataButton.color
                                  ? dontSellMyDataButton.color
                                  : "#7450c8"
                                : null,
                            color:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textColor
                                ? dontSellMyDataButton.textColor
                                : "#fff",
                            fontSize:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textSize
                                ? parseInt(dontSellMyDataButton.textSize)
                                : 14,
                            fontFamily:
                              dontSellMyDataButton &&
                              dontSellMyDataButton.textFont
                                ? dontSellMyDataButton.textFont
                                : "Roboto",
                            textAlign: this.props.footerLinkAlign
                              ? this.props.footerLinkAlign
                              : legalsite && legalsite.footerLinksAlign !== null
                              ? legalsite.footerLinksAlign
                              : null,
                            fontWeight:
                              this.props.footerLinkBold !== null
                                ? this.props.footerLinkAlign === true
                                  ? 900
                                  : 300
                                : legalsite &&
                                  legalsite.footerLinksBold !== null
                                ? legalsite.footerLinksBold === true
                                  ? 900
                                  : 500
                                : 500,
                            fontStyle:
                              this.props.footerLinkItalic !== null
                                ? this.props.footerLinkItalic === true
                                  ? "italic"
                                  : "normal"
                                : legalsite &&
                                  legalsite.footerLinksItalic !== null
                                ? legalsite.footerLinksItalic === true
                                  ? "italic"
                                  : "normal"
                                : "normal",
                          }}
                        >
                          Do Not Sell My Info
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div
                  className="footer-text"
                  style={{
                    background:
                      legalsite && legalsite.footerBg
                        ? legalsite.footerBg
                        : "#dfe4ee",
                  }}
                >
                  <span>
                    © {new Date().getFullYear()} LegalSite BV. All rights
                    reserved. Get your LegalSite!
                  </span>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default PreviewConsent;
