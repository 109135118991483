import React from "react";
import img from "../../images/Inbox/Illustration.svg";
import MdArrow from "react-icons/lib/ti/arrow-right";
import PropTypes from "prop-types";

function DelayNotice(props) {
  const { showDelay, isSolved } = props;

  return (
    <div className="inbox__delay">
      <img src={img} alt="sent" />
      <p className="inbox__delay-title">E-mail sent!</p>
      {isSolved ? (
        <p className="inbox__delay-text">
          You have successfully solved the request!
        </p>
      ) : (
        <p className="inbox__delay-text">
          E-mail letting the user know about the delay
          <br />
          was sent successfully.
        </p>
      )}

      <p className="inbox__delay-next" onClick={showDelay}>
        Next &nbsp; <MdArrow size={20} />
      </p>
    </div>
  );
}

export default DelayNotice;
DelayNotice.propTypes = {
  showDelay: PropTypes.func.isRequired,
  isSolved: PropTypes.bool,
};
