import gql from "graphql-tag";

export const EDIT_DP_CONSENT = gql`
  mutation(
    $dataProcessorConsentId: ID!
    $consentId: ID!
    $purpose: String!
    $clientMutationId: String
  ) {
    editDataProcessorConsent(
      input: {
        dataProcessorConsentId: $dataProcessorConsentId
        consentId: $consentId
        clientMutationId: $clientMutationId
        purpose: $purpose
      }
    ) {
      errors
    }
  }
`;
