import gql from "graphql-tag";

export const EDIT_AUDIENCE = gql`
  mutation($targetAudience: PopupTargetAudienceEnum!, $clientMutationId: String) {
    editTargetAudienceCookiePopup(
      input: { targetAudience: $targetAudience, clientMutationId: $clientMutationId }
    ) {
      errors
      popup {
        targetAudience
      }
    }
  }
`;
