import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Image from "../../images/Users/Document.svg";
import MdArrow from "react-icons/lib/ti/arrow-right";
import { Button } from "reactstrap";
import { REQUEST_SIGNATURES_ON_DPA } from "../../graphql/requestSignaturesOnDpa";
import { graphql } from "@apollo/client/react/hoc";

class RequestSignature extends Component {
  static propTypes = {
    showRequestSignature: PropTypes.func.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    isDP: PropTypes.bool.isRequired,
    requestSignatures: PropTypes.func.isRequired,
  };

  state = {
    hasSignature: false,
  };

  sendSignature = () => {
    this.props
      .requestSignatures({
        variables: {
          requests: this.props.selectedUsers,
        },
      })
      .then(() => {
        this.setState({
          hasSignature: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { hasSignature } = this.state;
    const { selectedUsers, isDP } = this.props;

    return (
      <div className="users request-signature">
        {!hasSignature ? (
          <div className="container request-signature__container">
            <h2 className="request-signature__title">
              Request signature on Data Processing Agreement
            </h2>
            <p className="request-signature__text">
              {isDP
                ? "Request these Data Processors to sign your DPA. You can edit the e-mail we’ll send with the request. When the Data Processor signs it, you’ll get a notification and the document will appear on your DPA table."
                : "Request these users to sign your DPA. You can edit the e-mail we’ll send with the request. When the user signs it, you’ll get a notification and the document will appear on your DPA table."}
            </p>
            <div className="request-signature__img">
              <img src={Image} alt="empty" />
            </div>

            <div className="request-signature__users">
              {selectedUsers.length}{" "}
              {selectedUsers.length > 1
                ? isDP
                  ? "data processors"
                  : "users"
                : isDP
                ? "data processor"
                : "user"}{" "}
              selected
            </div>

            <div className="request-signature__btns">
              <Button
                className="request-signature__send"
                onClick={this.sendSignature}
              >
                Send request
              </Button>

              <Link
                className="request-signature__edit"
                to={{
                  pathname: "/settings",
                  state: { customizeEmails: true },
                }}
              >
                Edit e-mail
              </Link>
              <Button
                className="request-signature__cancel"
                onClick={this.props.showRequestSignature}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div className="container signature__container">
            <div className="signature__img">
              <img src={Image} alt="empty" />
            </div>

            <h2 className="signature__title">Request sent!</h2>

            <p className="signature__text">
              When the company signs it, you’ll get a notification and the
              document will appear on your DPA table.
            </p>

            <Link to="/dashboard" className="signature__link">
              Back to Privacy Compliance <MdArrow size={23} />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default graphql(REQUEST_SIGNATURES_ON_DPA, {
  name: "requestSignatures",
})(RequestSignature);
