import gql from "graphql-tag";

export const PIA_QUERY = gql`
  {
    privacyImpactAssessment {
      dataCategories {
        actionRequired
        applicable
        elements
        id
        name
        purposes
        retentionPeriod
        sources
        status
        legalBasis
        sourceSuppliers
      }
      downloadUrl
      id
      notificationInterval
      lastUpdated
      status
    }
    legalsiteSettings {
      companyLogo
      id
    }
  }
`;

