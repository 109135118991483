import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import "./modals.scss";

export default class AssignRequestModal extends Component {
  static propTypes = {
    showSuggestedActionModal: PropTypes.func.isRequired,
    isSuggestedActionVisible: PropTypes.bool.isRequired,
    isQuestionShowing: PropTypes.bool,
    isDataAccessShowing: PropTypes.bool,
    isDataErasureShowing: PropTypes.bool,
    isSellShowing: PropTypes.bool,
    isDataTransfer: PropTypes.bool,
  };

  state = {};

  render() {
    const {
      isQuestionShowing,
      isDataAccessShowing,
      isDataErasureShowing,
      isSellShowing,
      isDataTransfer,
    } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isSuggestedActionVisible}
          toggle={this.props.showSuggestedActionModal}
          className="modal-suggested-action"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showSuggestedActionModal}
            />
            <p className="modal-assign__title">Suggested action:</p>
            <p className="modal-suggested-action__message text-center">
              {isDataErasureShowing ? (
                <Fragment>
                  <p className="mb-3">
                    Your user has sent a data erasure request through your
                    LegalSite page. Erase the data, upload the data erasure
                    confirmation log and we’ll send an e-mail to the user with a
                    link to download it on your LegalSite. Tell the user if you
                    are processing their personal data; Inform about the
                    processing (such as the purposes of the processing,
                    categories of personal data concerned, recipients of their
                    data, etc.). Provide a copy of the personal data being
                    processed.
                  </p>

                  <p className="mb-3">
                    If the data is also processed by another company you need to
                    request this data processor to also delete the data and send
                    you a confirmation log. Then, upload all the logs and send
                    them to the user.
                  </p>

                  <p className="mb-3">
                    Remember: you only have 1 month to solve a user’s request.
                  </p>
                </Fragment>
              ) : isDataTransfer ? (
                <Fragment>
                  <p className="mb-3">
                    Your user has requested data transfer on your LegalSite
                    page. Upload the data and we’ll send an e-mail to the user
                    with a link to download it on your LegalSite. Tell the user
                    if you are processing their personal data; Inform about the
                    processing (such as the purposes of the processing,
                    categories of personal data concerned, recipients of their
                    data, etc.). Provide a copy of the personal data being
                    processed.
                  </p>
                  <p className="mb-3">
                    If the data is also processed by another company you need to
                    request this data processor to send you this data also.
                    Then, upload all the data files and send them to the user.
                  </p>
                  <p className="mb-3">
                    Remember: you only have 1 month to solve a request.
                  </p>
                </Fragment>
              ) : isSellShowing ? (
                <Fragment>
                  <p className="mb-3">
                    Erase the user’s data and, if the data is also processed by
                    another company, you need to request this data processor to
                    also delete the data.
                  </p>
                </Fragment>
              ) : isQuestionShowing ? (
                <Fragment>
                  <p className="mb-3">
                    Your user has sent a question through your LegalSite page.
                    Write an answer to the question and we’ll send an e-mail to
                    the user with the answer.
                  </p>
                  <p className="mb-3">
                    Remember: you only have 1 month to solve a request.
                  </p>
                </Fragment>
              ) : isDataAccessShowing ? (
                <Fragment>
                  <p className="mb-3">
                    Your user has requested access to their data on your
                    LegalSite page. Upload the data and we’ll send an e-mail to
                    the user with a link to download it on your LegalSite.
                  </p>
                  <p className="mb-3">
                    Tell the user if you are processing their personal data;
                    Inform about the processing (such as the purposes of the
                    processing, categories of personal data concerned,
                    recipients of their data, etc.). Provide a copy of the
                    personal data being processed.
                  </p>

                  <p className="mb-3">
                    If the data is also processed by another company you need to
                    request this data processor to send you this data also.
                    Then, upload all the data files and send them to the user.
                  </p>

                  <p className="mb-3">
                    Remember: you only have 1 month to solve a request.
                  </p>
                </Fragment>
              ) : isDataAccessShowing ? (
                <Fragment>
                  <p className="mb-3">
                    Your user has requested data transfer on your LegalSite
                    page. Upload the data and we’ll send an e-mail to the user
                    with a link to download it on your LegalSite.
                  </p>
                  <p className="mb-3">
                    Tell the user if you are processing their personal data;
                    Inform about the processing (such as the purposes of the
                    processing, categories of personal data concerned,
                    recipients of their data, etc.). Provide a copy of the
                    personal data being processed.
                  </p>

                  <p className="mb-3">
                    If the data is also processed by another company you need to
                    request this data processor to send you this data also.
                    Then, upload all the data files and send them to the user.
                  </p>

                  <p className="mb-3">
                    {" "}
                    Remember: you only have 1 month to solve a request.
                  </p>
                </Fragment>
              ) : (
                <Fragment>
                  <p className="mb-3">
                    Your user has sent a complaint through your LegalSite page.
                    Write an answer to the complaint and we’ll send an e-mail to
                    the user with the answer.
                  </p>
                  <p className="mb-3">
                    Remember: you only have 1 month to solve a request.
                  </p>
                </Fragment>
              )}
            </p>
            <p
              className="modal-suggested-action__link"
              onClick={this.props.showSuggestedActionModal}
            >
              Back to request <MdArrow size={23} />
            </p>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
