import gql from "graphql-tag";

export const EDIT_SUBDOMAIN = gql`
  mutation($subdomain: String!, $clientMutationId: String) {
    editSubdomain(
      input: { subdomain: $subdomain, clientMutationId: $clientMutationId }
    ) {
      errors
      legalsiteSettings {
        subdomain
        id
      }
    }
  }
`;
