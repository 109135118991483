import React, { Component } from "react";
import PropTypes from "prop-types";
import MdClose from "react-icons/lib/md/close";
import Smile from "../../components/Icons/Smile";
import Paperclip from "../../components/Icons/Paperclip";
import Avatar from "../../components/Icons/Avatar";
import SendIcon from "../../components/Icons/SendIcon";

export default class Chat extends Component {
  static propTypes = {
    isContactOpen: PropTypes.bool.isRequired,
    toggleContact: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div
        className={`${
          this.props.isContactOpen ? "-is-visible" : ""
        } help__contact`}
      >
        <div className="help__contact-header">
          <span>LegalSite</span>
          <MdClose
            className="help__contact-close"
            size={25}
            onClick={this.props.toggleContact}
          />
        </div>

        <div className="help__contact-chat">
          <span className="help__contact-date">WED 12:30 PM</span>

          <div className="help__contact-message-sent">
            <Avatar />
            <span className="help__contact-message-sent-bubble">Hey there</span>
          </div>

          <div className="help__contact-message-sent">
            <span className="help__contact-message-sent-bubble -no-avatar">
              How can we help you?
            </span>
          </div>

          <div className="help__contact-message-received">
            <span className="help__contact-message-received-bubble">
              Question
            </span>
          </div>
        </div>

        <div className="help__contact-footer">
          <input
            placeholder="Type message..."
            className="help__contact-input"
          />
          <div className="help__contact-icons">
            <Smile className="help__contact-emoji mr-3" />
            <Paperclip className="help__contact-attachment mr-3" />
            <SendIcon className="help__contact-send mr-3" />
          </div>
        </div>
      </div>
    );
  }
}
