import gql from "graphql-tag";

export const ADD_DPA_TO_DATA_ENTITY = gql`
  mutation(
    $entityType: EntityTypeEnum!
    $id: ID!
    $dpa: String!
    $clientMutationId: String
  ) {
    addDpaToDataEntity(
      input: {
        entityType: $entityType
        clientMutationId: $clientMutationId
        id: $id
        dpa: $dpa
      }
    ) {
      errors
    }
  }
`;
