import React from "react";
import Image from "../../images/Users/Illustration.svg";
import PropTypes from "prop-types";
import { Button, Input } from "reactstrap";

function ApiKey(props) {
  const { account, copy } = props;

  return (
    <div className="data-processor__api">
      <h4 className="data-processor__api-title">API Key</h4>

      <img src={Image} alt="api" />

      <div>
        <p className="data-processor__header-label">API Key</p>
        <div className="d-flex data-processor__header-api">
          <Input
            type="text"
            readOnly={true}
            value={account && account.apiKey ? account.apiKey : ""}
          />
          <Button
            className="btn--secondary ml-3"
            onClick={() =>
              copy(account && account.apiKey ? account.apiKey : "")
            }
          >
            Copy to clipboard
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ApiKey;
ApiKey.propTypes = {
  account: PropTypes.object,
  copy: PropTypes.func,
};
