import PropTypes from "prop-types";
import React from "react";

const PrivacyIcon = props => (
    <svg
        height={20}
        viewBox='0 0 16 20'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Side-navigation-bar" transform="translate(-31.000000, -296.000000)" fill="#DFE4EE" fillRule="nonzero">
                <g id="shield-check">
                    <g transform="translate(31.000000, 296.000000)">
                        <path d="M15.63,1.65 C15.394693,1.45965217 15.0858385,1.38611538 14.79,1.45 C12.6302455,1.90262903 10.3794449,1.44306042 8.57,0.18 C8.2272054,-0.0578070772 7.7727946,-0.0578070772 7.43,0.18 C5.62055509,1.44306042 3.36975449,1.90262903 1.21,1.45 C0.914161495,1.38611538 0.605306971,1.45965217 0.37,1.65 C0.135008535,1.84063005 -0.00102886041,2.12741159 -5.79421745e-06,2.43 L-5.79421745e-06,9.88 C-0.00180382211,12.7890256 1.40256364,15.5195331 3.77,17.21 L7.42,19.81 C7.76716431,20.057178 8.23283569,20.057178 8.58,19.81 L12.23,17.21 C14.5974364,15.5195331 16.0018038,12.7890256 16.0000058,9.88 L16.0000058,2.43 C16.0010289,2.12741159 15.8649915,1.84063005 15.63,1.65 Z M13.9999983,9.88 C14.0015563,12.1417797 12.910179,14.2649369 11.07,15.58 L7.99999834,17.77 L4.93,15.58 C3.08982097,14.2649369 1.99844369,12.1417797 1.99999834,9.88 L1.99999834,3.58 C4.09642839,3.75943304 6.19604057,3.27302288 7.99999834,2.19 C9.80395943,3.27302288 11.9035716,3.75943304 13.9999983,3.58 L13.9999983,9.88 Z M9.54,7.59 L6.85,10.29 L5.96,9.39 C5.56787783,8.99787784 4.93212218,8.99787784 4.54000001,9.39000001 C4.14787784,9.78212218 4.14787784,10.4178778 4.54,10.81 L6.14,12.41 C6.3277666,12.5993127 6.58336246,12.7057983 6.85,12.7057983 C7.11663754,12.7057983 7.3722334,12.5993127 7.56,12.41 L11,9 C11.3921222,8.60787783 11.3921222,7.97212218 11,7.58000001 C10.6078778,7.18787784 9.97212217,7.18787784 9.58,7.58 L9.54,7.59 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

PrivacyIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default PrivacyIcon;