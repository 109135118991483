import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class DeleteCategory extends Component {
  static propTypes = {
    openDeleteModal: PropTypes.func.isRequired,
    isDeleteModalOpen: PropTypes.bool.isRequired,
    delete: PropTypes.func.isRequired,
    isCookie: PropTypes.bool.isRequired,
  };

  state = {};

  render() {
    const { isCookie } = this.props;

    return (
      <div>
        <Modal
          isOpen={this.props.isDeleteModalOpen}
          toggle={this.props.openDeleteModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openDeleteModal}
            />
            <p className="modal-assign__title mb-3">
              Are you sure you want to delete this{" "}
              {isCookie ? "cookie" : "category"}?
            </p>
            <p className="modal-assign__text mb-0 mt-0">
              This step can not be undone.
            </p>

            <div className="discard-changes__btns">
              <Button
                className="btn--secondary"
                onClick={this.props.openDeleteModal}
              >
                Cancel
              </Button>
              <Button
                className="btn--primary"
                onClick={() => {
                  this.props.delete();
                  this.props.openDeleteModal();
                }}
              >
                Delete {isCookie ? "Cookie" : "Category"}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
