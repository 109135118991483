import gql from "graphql-tag";

export const HIDE_ONBOARDING = gql`
  mutation($clientMutationId: String) {
    hideOnboardingModal(
      input: {clientMutationId: $clientMutationId }
    ) {
      errors
      user {
        id
        showOnboardingModal
      }
    }
  }
`;
