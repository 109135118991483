import gql from "graphql-tag";

export const SEND_REQUEST_TASK = gql`
  mutation($taskIds: [ID!]!, $clientMutationId: String) {
    sendRequestExternalTask(
      input: { taskIds: $taskIds, clientMutationId: $clientMutationId }
    ) {
      errors
      request {
        id
        tasks {
          id
          status
        }
      }
    }
  }
`;
