import React, { Component } from "react";
import ArrowCircleRight from "../../components/Icons/ArrowCircleRight";
import DragIcon from "../../images/Pages/Drag.svg";
import TrashIcon from "../../components/Icons/TrashIcon";
import PropTypes from "prop-types";

export default class PageLeftMenuItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    index: PropTypes.number,
    item: PropTypes.object,
    itemIndex: PropTypes.number,
    length: PropTypes.number,
    isDeleting: PropTypes.bool,
    isNotCurrentVersion: PropTypes.bool,
    isAdmin: PropTypes.bool,
    updateTitle: PropTypes.func,
    showItem: PropTypes.func,
    openDeleteModal: PropTypes.func,
    setItem: PropTypes.func,
  };

  state = {
    updatedTitle: this.props.title,
    showDelete: false,
  };

  updatedTitle = (e) => {
    this.setState({
      updatedTitle: e.target.value,
    });

    const title = e.target.value ? e.target.value : this.props.title;

    this.props.updateTitle(title);
  };

  render() {
    const {
      title,
      index,
      item,
      itemIndex,
      length,
      isDeleting,
      isNotCurrentVersion,
      isAdmin,
    } = this.props;
    const { showDelete } = this.state;

    return (
      <div
        className={`${
          itemIndex - 1 === index ? "-is-active" : ""
        } page-left-menu__item`}
        id={item.id}
        onMouseEnter={() => this.setState({ showDelete: true })}
        onMouseLeave={() => this.setState({ showDelete: false })}
        onClick={() =>
          this.props.showItem(index + 1, this.state.updatedTitle, item)
        }
      >
        {!isNotCurrentVersion ? (
          <img src={DragIcon} alt="drag" className="page-left-menu__drag" />
        ) : null}
        <div className="d-flex flex-column">
          <p className="page-left-menu__item-title">{title}</p>
        </div>
        {showDelete ? (
          !isNotCurrentVersion ? (
            !isAdmin && item.readOnly ? null : (
              <TrashIcon
                className={`${
                  length === 1 || isDeleting ? "-is-disabled" : ""
                } mr-2`}
                style={{ cursor: "pointer" }}
                color="#969696"
                onClick={
                  length === 1 || isDeleting
                    ? () => {}
                    : () => {
                        this.props.openDeleteModal();
                        this.props.setItem(item);
                      }
                }
              />
            )
          ) : null
        ) : null}
        <ArrowCircleRight color={"#EBA601"} />
      </div>
    );
  }
}
