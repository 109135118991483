import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import SideMenu from "../../components/SideMenu/SideMenu";
import { slide as Menu } from "react-burger-menu";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import MenuContent from "../../components/MenuContent/MenuContent";
import SearchInput from "../../components/SearchInput/SearchInput";
import Checked from "../../components/Check/Checked";
import MdClose from "react-icons/lib/md/close";
import Check from "react-icons/lib/fa/check";
import FaCaret from "react-icons/lib/fa/angle-down";
import TrashIcon from "../../components/Icons/TrashIcon";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import ViewIcon from "../../components/Icons/ViewIcon";
import "./lawyers.scss";
import LawyerView from "./LawyerView";

export default class Lawyers extends Component {
  static propTypes = {};

  state = {
    isRighMenuOpen: false,
    isCheckedAll: false,
    isLawyerVisible: false,
  };

  searchUser = () => {
    console.log("search");
  };

  showLawyer = () => {
    this.setState({
      isLawyerVisible: !this.state.isLawyerVisible,
    });
  };

  render() {
    const { isCheckedAll, isLawyerVisible } = this.state;
    return (
      <div className="d-flex position-relative">
        <SideMenu />

        <div className="dashboard w-100">
          <Navbar
            title="Dashboard"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          {!isLawyerVisible ? (
            <div className="lawyers container position-relative">
              <h5 className="lawyers__header">Lawyers</h5>
              <p className="lawyers__text">
                Here you can manage and see your lawyers.
              </p>
              <div className="lawyers__table">
                <div className="lawyers__table-content">
                  <div className="users__actions">
                    <div className="d-flex align-items-center">
                      <span
                        className={`check ${
                          isCheckedAll ? "-checked" : "-unchecked"
                        }`}
                        onClick={() =>
                          this.setState({
                            isCheckedAll: !this.state.isCheckedAll,
                          })
                        }
                      >
                        <Check color={"#fff"} />
                      </span>
                      <div className="users__action">
                        <TrashIcon className="mr-2" color={"#7450c8"} /> Delete
                      </div>

                      <div className="users__divider" />

                      <div className="users__action">
                        <DownloadIcon className="mr-2" color={"#7450c8"} />{" "}
                        Download
                      </div>

                      <div className="users__divider" />

                      <div className="users__action" onClick={this.showUser}>
                        <ViewIcon className="mr-2" color={"#7450c8"} /> View
                      </div>

                      <div className="users__divider" />

                      <UncontrolledDropdown>
                        <DropdownToggle className="users__action -filter">
                          Filter{" "}
                          <FaCaret className="ml-4" color="#333" size="20" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem>Last added</DropdownItem>
                          <DropdownItem>Free plan</DropdownItem>
                          <DropdownItem>Professional plan</DropdownItem>
                          <DropdownItem>Growth plan</DropdownItem>
                          <DropdownItem>Business plan</DropdownItem>
                          <DropdownItem>Enterprise plan</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>

                    <span className="">
                      <SearchInput
                        placeholder="Type to search for a lawyer..."
                        search={this.searchUser}
                      />
                    </span>
                  </div>

                  <div className="users__table">
                    <Table>
                      <thead>
                        <tr>
                          <th />
                          <th>Name</th>
                          <th>Company</th>
                          <th>E-mail</th>
                          <th>Telephone</th>
                          <th>Date added</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-0 px-0">
                            <Checked isCheckedAll={isCheckedAll} />
                          </td>
                          <td>Meredith Grey</td>
                          <td>Industry Company</td>
                          <td>gob@bluth.com</td>
                          <td>+144-3412-4422</td>
                          <td>19/04/2019</td>
                          <td>
                            <span
                              className="lawyers__view"
                              onClick={() => this.showLawyer()}
                            >
                              <ViewIcon className="mr-2" color={"#7450c8"} />{" "}
                              View
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LawyerView showLawyer={this.showLawyer} />
          )}
        </div>
      </div>
    );
  }
}
