import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MdArrow from "react-icons/lib/ti/arrow-right";
import MailIcon from "../../images/search/mail.svg";
import DataIcon from "../../images/search/data.svg";
import CheckIcon from "../../images/search/check.svg";

export default class InteractionTimelineItem extends Component {
  static propTypes = {
    type: PropTypes.string,
    text: PropTypes.string.isRequired,
  };

  state = {};

  renderIcon = () => {
    const { type } = this.props;

    if (type === "request") return MailIcon;
    if (type === "sent") return DataIcon;
    else return CheckIcon;
  };

  render() {
    const { text, type } = this.props;

    return (
      <div className="search-user__interaction">
        <div className="d-flex align-items-center">
          <div className="search-user__interaction-icon">
            <img src={this.renderIcon()} alt="icon" />
          </div>
          <span className="search-user__interaction-text">{text}</span>
        </div>
        {type === "solved" ? (
          <Link className="search-user__interaction-link" to="/dashboard">
            See report &nbsp;&nbsp;
            <MdArrow color={"#7450c8"} size={25} />
          </Link>
        ) : null}
      </div>
    );
  }
}
