import React from "react";
import Moment from "react-moment";
import ShieldCheck from "../../components/Icons/ShieldCheck";
import CloudExclamation from "../../components/Icons/CloudExclamation";
import UsersIcon from "../../components/Icons/UsersIcon";
import FileEdit from "../../components/Icons/FileEdit";
import MdArrow from "react-icons/lib/ti/arrow-right";
import PropTypes from "prop-types";

function ComplianceItem(props) {
  const { status, lastUpdated, showSection, title, text, icon } = props;

  return (
    <div className="compliance__item">
      <div className="d-flex compliance__item-title-container">
        {icon === "shield" ? (
          <ShieldCheck color={"#7450C8"} />
        ) : icon === "cloud" ? (
          <CloudExclamation color="#7450c8" />
        ) : icon === "file" ? (
          <FileEdit color={"#7450C8"} />
        ) : (
          <UsersIcon color={"#7450C8"} />
        )}
        <p className="compliance__item-title">{title}</p>
      </div>
      <p className="compliance__item-text">{text}</p>
      <div className="d-flex align-items-center">
        {status ? (
          <span className="compliance__item-done">
            {status === "REQUESTED" ? "Requested" : "Done and up-to-date"}
          </span>
        ) : null}
        {lastUpdated ? (
          <span className="compliance__item-update">
            Last update{" "}
            <Moment fromNow>{lastUpdated ? lastUpdated : null}</Moment>
          </span>
        ) : null}
      </div>
      <div
        className="d-flex align-items-center compliance__item-link-container"
        onClick={showSection}
      >
        <span className="compliance__item-link">See more</span>
        <MdArrow color={"#7450c8"} size={25} />
      </div>
    </div>
  );
}

export default ComplianceItem;
ComplianceItem.propTypes = {
  status: PropTypes.string,
  lastUpdated: PropTypes.string,
  showSection: PropTypes.func,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(["shield", "cloud", "file", "users"]).isRequired,
};
