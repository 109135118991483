import React from "react";
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import FaCaret from "react-icons/lib/fa/angle-down";
import UserExclamationIcon from "../Icons/UserExclamation";
import ArrowCircleUpIcon from "../Icons/ArrowCircleUp";
import HelpIcon from "../Icons/HelpIcon";
import LogOutIcon from "../Icons/LogOutIcon";
import GetStartedIcon from "../Icons/GetStartedIcon";
import PropTypes from "prop-types";

function ProfileMenu(props) {
  const {
    viewer,
    getFirstLetter,
    account,
    isDP,
    dpStepValue,
    stepValue,
    handlePortal,
    redirectToPath,
    signOut,
    showSideBarMenu,
  } = props;

  return (
    <div className="d-flex align-items-center">
      <div className="navigationBar__avatar">
        {viewer && viewer.avatar ? (
          <img src={viewer.avatar} alt="avatar" />
        ) : (
          <span className="navigationBar__default-avatar">
            {getFirstLetter}
          </span>
        )}
      </div>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav className="navigationBar__user-name">
          {viewer ? viewer.name : ""} <FaCaret size="20" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header className="d-flex px-2">
            <div className="navigationBar__dropdown-avatar">
              {viewer && viewer.avatar ? (
                <img src={viewer.avatar} alt="avatar" />
              ) : (
                <span className="navigationBar__default-avatar2">
                  {getFirstLetter}
                </span>
              )}
            </div>

            <div>
              <p className="navigationBar__dropdown-name">
                {viewer ? viewer.name : ""}
              </p>
              <p className="navigationBar__dropdown-email">
                {viewer ? viewer.email : ""}
              </p>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          {viewer && !viewer.appAdmin ? (
            <DropdownItem
              className="navigationBar__dropdown-item"
              onClick={handlePortal}
            >
              <UserExclamationIcon className="navigationBar__dropdown-icon" />
              Account Settings
            </DropdownItem>
          ) : null}
          {viewer && !viewer.appAdmin ? (
            <DropdownItem className="navigationBar__dropdown-item">
              <a
                href={`https://legalsite.chargebee.com/hosted_pages/plans/business-edition?customer[email]=${account.email}`}
                style={{ textDecoration: "none" }}
              >
                <ArrowCircleUpIcon className="navigationBar__dropdown-icon" />{" "}
                Upgrade
              </a>
            </DropdownItem>
          ) : null}
          {viewer && !viewer.appAdmin ? (
            <DropdownItem
              className="navigationBar__dropdown-item"
              onClick={() => redirectToPath("/help")}
            >
              <HelpIcon
                color="#DFE4EE"
                className="navigationBar__dropdown-icon"
              />{" "}
              Help
            </DropdownItem>
          ) : null}
          <DropdownItem
            className="navigationBar__dropdown-item"
            onClick={signOut}
          >
            <LogOutIcon className="navigationBar__dropdown-icon" /> Log out
          </DropdownItem>
          {viewer && !viewer.appAdmin ? (
            <DropdownItem
              className="navigationBar__dropdown-item"
              onClick={showSideBarMenu}
            >
              <GetStartedIcon className="navigationBar__dropdown-icon" />{" "}
              Getting started - step {isDP ? dpStepValue : stepValue} of{" "}
              {isDP ? "2" : "7"} done
            </DropdownItem>
          ) : null}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

export default ProfileMenu;
ProfileMenu.propTypes = {
  viewer: PropTypes.object,
  getFirstLetter: PropTypes.func,
  account: PropTypes.object,
  isDP: PropTypes.bool,
  dpStepValue: PropTypes.number,
  stepValue: PropTypes.number,
  handlePortal: PropTypes.func,
  redirectToPath: PropTypes.func,
  signOut: PropTypes.func,
  showSideBarMenu: PropTypes.func,
};
