import gql from "graphql-tag";

export const CREATE_INTEGRATION = gql`
  mutation(
    $dataProcessorId: ID!
    $companyName: String!
    $email: String!
    $contactName: String!
    $clientMutationId: String
  ) {
    createIntegration(
      input: {
        dataProcessorId: $dataProcessorId
        companyName: $companyName
        email: $email
        contactName: $contactName
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      integration {
        id
      }
    }
  }
`;
