import gql from "graphql-tag";

export const EDIT_ACCOUNT = gql`
  mutation(
    $timezone: String
    $dateFormat: String
    $clientMutationId: String
  ) {
    editAccount(
      input: {
        timezone: $timezone
        dateFormat: $dateFormat
        clientMutationId: $clientMutationId
      }
    ) {
      errors
      account {
        id
        timezone
        dateFormat
      }
    }
  }
`;
