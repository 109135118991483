import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Button } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./status-card.scss";
import StatusItem from "./StatusItem";

export default class StatusCard extends PureComponent {
  static propTypes = {
    status: PropTypes.array,
    isAdmin: PropTypes.bool,
    dashboardStatsAdmin: PropTypes.object,
    alerts: PropTypes.array,
    showPreviewModal: PropTypes.func,
    openLegalsite: PropTypes.func,
  };

  state = {
    redirectToLegalsite: false,
  };

  openLegalsite = () => {
    this.props.openLegalsite();
  };

  render() {
    const { status, isAdmin, dashboardStatsAdmin, alerts } = this.props;
    let options;
    let series;

    if (isAdmin) {
      options = {
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: ["#f86b67", "#4f2DB8", "#1DDCC0"],
        },
        colors: ["#f86b67", "#4f2DB8", "#1DDCC0"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                maxWidth: 224,
                width: 224,
              },
              legend: {
                show: true,
              },
            },
          },
        ],
        legend: {
          position: "bottom",
        },
        labels: ["Pending requests", "Requests in progress", "Solved requests"],
      };
      series = [
        dashboardStatsAdmin.pendingRequests,
        dashboardStatsAdmin.requestsInProgress,
        dashboardStatsAdmin.solvedRequests,
      ];
    }

    return (
      <div
        className={`${
          isAdmin ? "align-items-center -small" : ""
        } status-card d-flex flex-column`}
      >
        <h4 className="status-card__header w-100 text-left">
          {isAdmin ? "User requests" : "LegalSite status"}
        </h4>

        {!isAdmin ? (
          <div className="status-card__container">
            {status.length &&
              status
                .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                .map((item, index) => (
                  <StatusItem
                    key={index}
                    item={item}
                    index={index}
                    alerts={alerts}
                  />
                ))}
          </div>
        ) : (
          <div className="status-card__chart">
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              width="250"
            />
          </div>
        )}

        {!isAdmin ? (
          <div className="status-card__btns">
            <Button
              className="status-card__preview"
              onClick={this.props.showPreviewModal}
            >
              Preview
            </Button>
            <Button
              className="status-card__publish"
              onClick={this.openLegalsite}
            >
              See LegalSite
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}
