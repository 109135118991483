import gql from "graphql-tag";

export const REMOVE_ASSIGNED_USER = gql`
  mutation($requestId: ID!, $clientMutationId: String) {
    removeAssignedUser(
      input: { requestId: $requestId, clientMutationId: $clientMutationId }
    ) {
      errors
      request {
        id
        assignedTo {
          id
        }
      }
    }
  }
`;
