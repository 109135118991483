import PropTypes from "prop-types";
import React from "react";

const PlusCircle = props => (
    <svg
        height={16}
        viewBox='0 0 16 16'
        width={16}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Add-section-button" transform="translate(-8.000000, -10.000000)" fill="#7450C8" fillRule="nonzero">
                <g id="Group-10">
                    <g id="plus-circle" transform="translate(8.000000, 10.000000)">
                        <path d="M8,-8.8817842e-17 C3.581722,-6.21724894e-16 2.13162821e-15,3.581722 1.77635684e-15,8 C1.42108547e-15,12.418278 3.581722,16 8,16 C12.418278,16 16,12.418278 16,8 C16,5.87826808 15.1571453,3.84343678 13.6568542,2.34314575 C12.1565632,0.842854723 10.1217319,-2.66453526e-16 8,-8.8817842e-17 Z M8,14.4 C4.4653776,14.4 1.6,11.5346224 1.6,8 C1.6,4.4653776 4.4653776,1.6 8,1.6 C11.5346224,1.6 14.4,4.4653776 14.4,8 C14.4,9.69738553 13.7257162,11.3252506 12.5254834,12.5254834 C11.3252506,13.7257162 9.69738553,14.4 8,14.4 Z M11.2,7.2 L8.8,7.2 L8.8,4.8 C8.8,4.3581722 8.4418278,4 8,4 C7.5581722,4 7.2,4.3581722 7.2,4.8 L7.2,7.2 L4.8,7.2 C4.3581722,7.2 4,7.5581722 4,8 C4,8.4418278 4.3581722,8.8 4.8,8.8 L7.2,8.8 L7.2,11.2 C7.2,11.6418278 7.5581722,12 8,12 C8.4418278,12 8.8,11.6418278 8.8,11.2 L8.8,8.8 L11.2,8.8 C11.6418278,8.8 12,8.4418278 12,8 C12,7.5581722 11.6418278,7.2 11.2,7.2 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

PlusCircle.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default PlusCircle;