import gql from "graphql-tag";

export const RESEND_CONFIRMATION_EMAIL = gql`
  mutation(
    $email: String!
    $clientMutationId: String
  ) {
    resendConfirmationMail(
      input: {
        email: $email
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
