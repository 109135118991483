import gql from "graphql-tag";

export const LEGALSITE_SETTINGS_QUERY = gql`
  {
    legalsiteSettings {
      brandColor
      companyLogo
      id
      subdomain
    }
  }
`;
