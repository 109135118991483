import gql from "graphql-tag";

export const IMPORT_DATA_ENTITIES = gql`
  mutation($dataEntities: [DataEntityObject!], $clientMutationId: String) {
    importListOfDataEntities(
      input: {
        dataEntities: $dataEntities
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
