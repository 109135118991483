import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import Image from "../../images/Compliance/Image.svg";
import "./modals.scss";

export default class PublishLegalSiteModal extends Component {
  static propTypes = {
    showPublishLegalSite: PropTypes.func.isRequired,
    isPublishLegalSiteVisible: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool,
    subdomain: PropTypes.string,
    isAdminPage: PropTypes.bool,
  };

  state = {};

  showLegalSite = () => {
    window.open(`https://${this.props.subdomain}.legalsites.app`, "_blank");
    this.props.showPublishLegalSite();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isPublishLegalSiteVisible}
          toggle={this.props.showPublishLegalSite}
          className="publish-legalsite bg-white"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showPublishLegalSite}
            />
            <div className="modal-assign__image">
              <img src={Image} alt="email" />
            </div>
            <p className="modal-assign__title mt-5">LegalSite published!</p>

            <p className="modal-assign__text">
              {this.props.isAdminPage
                ? "Congratulations! Your Page Template was published and updated."
                : "Congratulations! Your LegalSite was published and updated. You can see it live by clicking on the button below."}
            </p>

            <div className="d-flex">
              {!this.props.isAdminPage ? (
                <Button
                  className="btn--primary publish-legalsite__btn"
                  onClick={this.showLegalSite}
                >
                  See LegalSite
                </Button>
              ) : null}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
