import React from "react";
import { Col } from "reactstrap";
import OverviewCard from "../../components/OverviewCard/OverviewCard";
import PropTypes from "prop-types";

function OverviewCards(props) {
  const { usersStats } = props;

  return (
    <Col sm={12} lg={8} className="users__header-stats">
      <OverviewCard
        stats={usersStats ? usersStats.totalUsers : 0}
        text="Total users"
        color="purple"
        percentage={100}
      />

      <OverviewCard
        stats={usersStats ? usersStats.dataSubjects : 0}
        text="Personal"
        color="aqua"
        percentage={(100 * usersStats.dataSubjects) / usersStats.totalUsers}
      />

      <OverviewCard
        stats={usersStats ? usersStats.dataControllers : 0}
        text="Companies"
        color="yellow"
        percentage={(100 * usersStats.dataControllers) / usersStats.totalUsers}
      />

      <OverviewCard
        stats={usersStats ? usersStats.haveDpa : 0}
        text="Have DPA"
        color="coral"
        percentage={(100 * usersStats.haveDpa) / usersStats.totalUsers}
      />
    </Col>
  );
}

export default OverviewCards;
OverviewCards.propTypes = {
  usersStats: PropTypes.object,
};
