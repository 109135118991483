import gql from "graphql-tag";

export const ADD_SECTION = gql`
  mutation($pageId: ID!, $title: String!, $text: String!) {
    addSectionToPage(input: { pageId: $pageId, title: $title, text: $text }) {
      errors
      page {
        id
        sections {
          applicable
          id
          position
          text
          title
        }
      }
    }
  }
`;
