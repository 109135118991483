import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Alert } from "reactstrap";
import { DPO_QUERY } from "../../graphql/dataProtectionOfficer";
import { LEGALSITE_SETTINGS_QUERY } from "../../graphql/legalSiteSettings";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import PropTypes from "prop-types";
import "./dpo.scss";

class Dpo extends Component {
  static propTypes = {
    dpo: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
  };

  state = {};

  render() {
    const { dataProtectionOfficer, loading, error } = this.props.dpo;
    const { legalsiteSettings } = this.props.settings;

    if (loading) return <p>Loading...</p>;

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return dataProtectionOfficer ? (
      <div className="dpo">
        <div className="legalsite__nav">
          <Link
            to="/legalSites"
            className="d-flex align-items-center text-decoration-none"
          >
            {legalsiteSettings?.companyLogo ? (
              <div className="legalsite__logo-container">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </div>
            ) : (
              <span className="legalsite__logo" />
            )}
            <span className="legalsite__brand">
              {legalsiteSettings.companyName}
            </span>
          </Link>

          <span className="legalsite__link">Get your LegalSite</span>
        </div>

        <div className="dpo__content">
          <div className="dpo__container">
            <div className="dpo__banner">
              <div className="container">
                <h1 className="dpo__banner-title">
                  Data Protection Officer (DPO)
                </h1>
                <p className="dpo__banner-text">
                  Meet our appointed Data Protection Officer.
                </p>
              </div>
            </div>

            <div className="container">
              <Row className="dpo__dpo">
                <Col lg={3}>
                  <div className="dpo__dpo-avatar">
                    <img src={dataProtectionOfficer.avatar} alt="avatar" />
                  </div>
                </Col>
                <Col lg={9}>
                  <div>
                    <p className="dpo__dpo-name">
                      {dataProtectionOfficer.name}
                    </p>
                    <p className="dpo__dpo-description">
                      {dataProtectionOfficer
                        ? dataProtectionOfficer.description
                        : ""}
                    </p>
                  </div>

                  <div>
                    <p className="dpo__dpo-name">Contact</p>
                    <p className="dpo__dpo-description">
                      If you want to contact our Data Protection Officer, please
                      send an e-mail to{" "}
                      <a href={`mailto:${dataProtectionOfficer.email}`}>
                        {dataProtectionOfficer.email}
                      </a>
                      . You can also get in touch with us and send requests
                      regarding your data on our{" "}
                      <span className="modal-preview__dpo-request">
                        Requests
                      </span>{" "}
                      section.{" "}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}

export default compose(
  graphql(DPO_QUERY, {
    name: "dpo",
  }),
  graphql(LEGALSITE_SETTINGS_QUERY, {
    name: "settings",
  })
)(Dpo);
