import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import SyncLoader from "react-spinners/SyncLoader";
import RightSideMenu from "./RightSideMenu";
import FontLoader from "./FontLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { COOKIE_QUERY } from "../../graphql/cookie";
import { CUSTOMIZE_COOKIE } from "../../graphql/customizeCookie";
import { ToastContainer, toast } from "react-toastify";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import Step1Popup from "./Step1Popup";
import Step2Popup from "./Step2Popup";
import Step3Popup from "./Step3Popup";

class CustomizeCookie extends Component {
  static propTypes = {
    showCustomization: PropTypes.func.isRequired,
    notifyMsg: PropTypes.func.isRequired,
    cookie: PropTypes.object.isRequired,
    customizeCookie: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    accountData: PropTypes.object.isRequired,
  };

  state = {
    step: 1,
    templateActive: null,
    buttonShape: null,
    popupSide: null,
    isInitialShape: true,
    isInitialTemplate: true,
    isInitialPosition: true,
    isInitialOverlay: true,
    isInitialSide: true,
    cookieTitle: null,
    cookieDescription: null,
    titleColor: null,
    titleSize: null,
    bodyColor: null,
    bodySize: null,
    buttonColor: null,
    buttonSize: null,
    buttonTextColor: null,
    buttonTextSize: null,
    cookieBg: null,
    secondaryButtonColor: null,
    cookieBtnColor: null,
    popupPosition: null,
    popupOverlay: null,
    titleFont: null,
    bodyFont: null,
    footerFont: null,
    buttonFont: null,
    btnText: null,
    secondaryBtnText: null,
    selectedPage: null,
    type: null,
    destinationUrl: null,
    catchEmail: null,
    catchSignature: null,
    verifyUser: null,
    isInitialCatch: true,
    isInitialCatchSignature: true,
    isInitialVerify: true,
    titleBold: null,
    titleItalic: null,
    titleAlign: null,
    emailBodyAlign: null,
    emailBodyBold: null,
    emailBodyItalic: null,
    emailBtnBold: null,
    emailBtnItalic: null,
    emailFooterBold: null,
    emailFooterItalic: null,
  };

  notify = (text) => toast(text);

  getAligned = (name, align) => {
    this.setState({
      [name]: align,
    });
  };

  getBold = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getItalic = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  getGoogleFont = (name, font) => {
    this.setState({
      [name]: font,
    });
  };

  showSteps = (step) => {
    this.setState({
      step,
    });
  };

  chooseTemplate = (template) => {
    this.setState({
      templateActive: template,
      isInitialTemplate: false,
    });
  };

  changeButtonsShape = (shape) => {
    this.setState({
      buttonShape: shape,
      isInitialShape: false,
    });
  };

  changePopupSide = (side) => {
    this.setState({
      popupSide: side,
      isInitialSide: false,
    });
  };

  changePopupOverlay = (overlay) => {
    this.setState({
      popupOverlay: overlay,
      isInitialOverlay: false,
    });
  };

  changePopupPosition = (position) => {
    this.setState({
      popupPosition: position,
      isInitialPosition: false,
    });
  };

  changeCookieTitle = (e) => {
    this.setState({
      cookieTitle: e.target.value,
    });
  };

  changeCookieSecondarBtn = (e) => {
    this.setState({
      secondaryBtnText: e.target.value,
    });
  };

  changeCookieBtn = (e) => {
    this.setState({
      btnText: e.target.value,
    });
  };

  changeCookieDescription = (e) => {
    this.setState({
      cookieDescription: e.target.value,
    });
  };

  changeTextColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeFontSize = (name, size) => {
    this.setState({
      [name]: parseInt(size),
    });
  };

  changeCookieColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  setCatchEmail = (value) => {
    this.setState({
      catchEmail: value,
      isInitialCatch: false,
    });
  };

  setCatchSignature = (value) => {
    this.setState({
      catchSignature: value,
      isInitialCatchSignature: false,
    });
  };

  setVerifyUser = (value) => {
    this.setState({
      verifyUser: value,
      isInitialVerify: false,
    });
  };

  setDestinationUrl = (value) => {
    this.setState({ destinationUrl: value });
  };

  submitCookieSettings = () => {
    const {
      templateActive,
      buttonShape,
      popupSide,
      cookieTitle,
      cookieDescription,
      popupPosition,
      popupOverlay,
      destinationUrl,
      catchEmail,
      catchSignature,
      verifyUser,
      btnText,
      secondaryBtnText,
    } = this.state;
    const { popup } = this.props.cookie;
    const emailCatch = catchEmail !== null ? catchEmail : popup.catchEmail,
      signatureCatch =
        catchSignature !== null ? catchSignature : popup.catchSignature,
      userVerify = verifyUser !== null ? verifyUser : popup.verifyUser;

    const url = destinationUrl ? destinationUrl : "";

    const buttonText = btnText
      ? btnText
      : popup.buttonText
      ? popup.buttonText
      : "ACCEPT";
    const secondaryText = secondaryBtnText
      ? secondaryBtnText
      : popup.secondaryButtonText
      ? popup.secondaryButtonText
      : popup.title === "Cookies preferences"
      ? "OPTIONS"
      : "SEE LEGALSITE";

    const description = cookieDescription
        ? cookieDescription
        : this.props.cookie.popup.body
        ? this.props.cookie.popup.body
        : "This website uses cookies. Click on the button bellow to consent to all cookies in accordance with our Privacy Policy or customize your preferences at any time on our legal page.",
      title = cookieTitle
        ? cookieTitle
        : this.props.cookie.popup.title
        ? this.props.cookie.popup.title
        : "Cookies preferences",
      cookieButtonShape = buttonShape
        ? buttonShape
        : this.props.cookie.popup.buttonShapes,
      cookieSide = popupSide ? popupSide : this.props.cookie.popup.popupSide,
      cookiePosition = popupPosition
        ? popupPosition
        : this.props.cookie.popup.popupPosition,
      cookieOverlay = popupOverlay
        ? popupOverlay
        : this.props.cookie.popup.overlay;

    const titleColor = this.state.titleColor
        ? this.state.titleColor
        : popup.titleColor,
      template = templateActive
        ? templateActive === 1
          ? "squared popup"
          : templateActive === 2
          ? "vertical popup"
          : templateActive === 3
          ? "overlay popup"
          : "page view"
        : popup.template
        ? popup.template
        : "squared popup",
      titleSize = this.state.titleSize ? this.state.titleSize : popup.titleSize,
      titleFont = this.state.titleFont ? this.state.titleFont : popup.titleFont,
      bodyColor = this.state.bodyColor ? this.state.bodyColor : popup.bodyColor,
      bodySize = this.state.bodySize ? this.state.bodySize : popup.bodySize,
      bodyFont = this.state.bodyFont ? this.state.bodyFont : popup.bodyFont,
      titleBold = this.state.titleBold ? this.state.titleBold : popup.titleBold,
      titleItalic = this.state.titleItalic
        ? this.state.titleItalic
        : popup.titleItalic,
      buttonBold = this.state.emailBtnBold
        ? this.state.emailBtnBold
        : popup.buttonBold,
      buttonItalic = this.state.emailBtnItalic
        ? this.state.emailBtnItalic
        : popup.buttonBold,
      bodyAlign = this.state.emailBodyAlign
        ? this.state.emailBodyAlign
        : popup.bodyAlign,
      bodyBold = this.state.emailBodyBold
        ? this.state.emailBodyBold
        : popup.bodyBold,
      bodyItalic = this.state.emailBodyItalic
        ? this.state.emailBodyItalic
        : popup.bodyItalic,
      titleAlign = this.state.titleAlign
        ? this.state.titleAlign
        : popup.titleAlign,
      backgroundColor = this.state.cookieBg
        ? this.state.cookieBg
        : popup.backgroundColor,
      buttonColor = this.state.buttonColor
        ? this.state.buttonColor
        : popup.buttonColor,
      buttonSize = this.state.buttonSize
        ? this.state.buttonSize
        : parseInt(popup.buttonSize),
      buttonFont = this.state.buttonFont
        ? this.state.buttonFont
        : popup.buttonFont,
      secondaryButtonBold = this.state.emailFooterBold
        ? this.state.emailFooterBold
        : popup.secondaryButtonBold
        ? popup.secondaryButtonBold
        : false,
      secondaryButtonItalic = this.state.emailFooterItalic
        ? this.state.emailFooterItalic
        : popup.secondaryButtonItalic
        ? popup.secondaryButtonItalic
        : false,
      textButtonAndLinkColor = this.state.buttonTextColor
        ? this.state.buttonTextColor
        : popup.secondaryButtonColor,
      textButtonAndLinkSize = this.state.buttonTextSize
        ? this.state.buttonTextSize
        : parseInt(popup.secondaryButtonSize),
      textButtonAndLinkFont = this.state.footerFont
        ? this.state.footerFont
        : popup.secondaryButtonFont;

    this.props
      .customizeCookie({
        variables: {
          titleColor,
          secondaryButtonBold,
          secondaryButtonItalic,
          titleSize,
          titleFont,
          bodyColor,
          bodySize,
          bodyFont,
          template,
          buttonShapes: cookieButtonShape,
          popupSide: cookieSide,
          title,
          titleBold,
          titleItalic,
          titleAlign,
          bodyAlign,
          bodyBold,
          bodyItalic,
          body: description,
          backgroundColor,
          buttonColor,
          buttonSize,
          buttonFont,
          secondaryButtonColor: textButtonAndLinkColor,
          secondaryButtonSize: textButtonAndLinkSize,
          secondaryButtonFont: textButtonAndLinkFont,
          buttonBold,
          buttonItalic,
          verifyUser: userVerify,
          overlay: cookieOverlay,
          popupPosition: cookiePosition,
          category: this.state.type,
          catchSignature: signatureCatch,
          catchEmail: signatureCatch === true ? true : emailCatch,
          destinationPage: url,
          buttonText,
          secondaryButtonText: secondaryText,
          buttonBgColor: this.state.secondaryButtonColor
            ? this.state.secondaryButtonColor
            : popup.buttonBgColor,
        },
      })
      .then(() => {
        this.props.viewer.refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getSelectedPage = (page, type) => {
    this.setState(
      {
        selectedPage: page,
        type,
      },
      () => {
        this.props.cookie.refetch({ popupCategory: type });
      }
    );
  };

  refetchData = () => {
    this.props.cookie.refetch();
  };

  render() {
    const {
      step,
      templateActive,
      buttonShape,
      popupSide,
      cookieTitle,
      cookieDescription,
      titleColor,
      titleSize,
      bodyColor,
      bodySize,
      buttonColor,
      buttonSize,
      buttonTextColor,
      buttonTextSize,
      popupPosition,
      popupOverlay,
      cookieBg,
      cookieBtnColor,
      titleFont,
      bodyFont,
      footerFont,
      buttonFont,
      isInitialTemplate,
      isInitialShape,
      isInitialSide,
      isInitialPosition,
      isInitialOverlay,
      secondaryButtonColor,
      catchEmail,
      catchSignature,
      verifyUser,
      titleBold,
      titleItalic,
      titleAlign,
      emailBodyAlign,
      emailBodyBold,
      emailBodyItalic,
      emailBtnBold,
      emailBtnItalic,
      emailFooterBold,
      emailFooterItalic,
    } = this.state;

    const { account } = this.props;

    const {
      popup,
      dataProtectionOfficer,
      legalsite,
      loading,
      error,
      pages,
      legalsiteSettings,
    } = this.props.cookie;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    const sideMenu = document.getElementsByClassName(
      "customization__navigation"
    );

    if (step === 3) {
      if (sideMenu && sideMenu.length) {
        sideMenu[0].style.display = "none";
      }
    } else {
      if (sideMenu && sideMenu.length) {
        sideMenu[0].style.display = "block";
      }
    }

    return (
      <Fragment>
        {popup ? (
          <div
            className={`${
              step === 1 || step === 2 ? "-has-scroll" : ""
            } customization-cookie`}
          >
            <FontLoader
              font1={
                titleFont
                  ? titleFont
                  : popup.titleFont
                  ? popup.titleFont
                  : "Roboto"
              }
              font2={
                bodyFont ? bodyFont : popup.bodyFont ? popup.bodyFont : "Roboto"
              }
              font3={
                footerFont
                  ? footerFont
                  : popup.secondaryButtonFont
                  ? popup.secondaryButtonFont
                  : "Roboto"
              }
              font4={
                buttonFont
                  ? buttonFont
                  : popup.buttonFont
                  ? popup.buttonFont
                  : "Roboto"
              }
            />

            {step === 1 ? (
              <Step1Popup
                showSteps={this.showSteps}
                getSelectedPage={this.getSelectedPage}
                pages={pages}
                account={account}
              />
            ) : step === 2 ? (
              <Step2Popup
                popup={popup}
                isInitialTemplate={isInitialTemplate}
                templateActive={templateActive}
                chooseTemplate={this.chooseTemplate}
                showSteps={this.showSteps}
              />
            ) : step === 3 ? (
              <div className="d-flex justify-content-between w-100 customization-cookie__menus">
                <div className="container">
                  <Step3Popup
                    popup={popup}
                    legalsite={legalsite}
                    isInitialTemplate={isInitialTemplate}
                    templateActive={templateActive}
                    selectedPage={this.state.selectedPage}
                    pages={pages}
                    account={account}
                    cookieBg={cookieBg}
                    titleColor={titleColor}
                    titleSize={titleSize}
                    cookieDescription={cookieDescription}
                    titleFont={titleFont}
                    cookieTitle={cookieTitle}
                    bodySize={bodySize}
                    bodyColor={bodyColor}
                    bodyFont={bodyFont}
                    isInitialShape={isInitialShape}
                    buttonShape={buttonShape}
                    buttonColor={buttonColor}
                    isInitialSide={isInitialSide}
                    popupSide={popupSide}
                    buttonSize={buttonSize}
                    secondaryButtonColor={secondaryButtonColor}
                    buttonTextColor={buttonTextColor}
                    buttonFont={buttonFont}
                    buttonTextSize={buttonTextSize}
                    footerFont={footerFont}
                    isInitialPosition={isInitialPosition}
                    popupPosition={popupPosition}
                    catchEmail={catchEmail}
                    isInitialOverlay={isInitialOverlay}
                    popupOverlay={popupOverlay}
                    catchSignature={catchSignature}
                    verifyUser={verifyUser}
                    btnText={this.state.btnText}
                    secondaryBtnText={this.state.secondaryBtnText}
                    changeButtonsShape={this.changeButtonsShape}
                    changePopupSide={this.changePopupSide}
                    changePopupPosition={this.changePopupPosition}
                    changePopupOverlay={this.changePopupOverlay}
                    changeCookieTitle={this.changeCookieTitle}
                    changeCookieDescription={this.changeCookieDescription}
                    changeCookieBtn={this.changeCookieBtn}
                    changeCookieSecondarBtn={this.changeCookieSecondarBtn}
                    destinationUrl={this.state.destinationUrl}
                    setDestinationUrl={this.setDestinationUrl}
                    setCatchEmail={this.setCatchEmail}
                    setCatchSignature={this.setCatchSignature}
                    setVerifyUser={this.setVerifyUser}
                    isInitialCatch={this.state.isInitialCatch}
                    isInitialCatchSignature={this.state.isInitialCatchSignature}
                    isInitialVerify={this.state.isInitialVerify}
                    titleBold={titleBold}
                    titleItalic={titleItalic}
                    titleAlign={titleAlign}
                    emailBodyAlign={emailBodyAlign}
                    emailBodyBold={emailBodyBold}
                    emailBodyItalic={emailBodyItalic}
                    emailBtnBold={emailBtnBold}
                    emailBtnItalic={emailBtnItalic}
                    emailFooterBold={emailFooterBold}
                    emailFooterItalic={emailFooterItalic}
                  />
                </div>

                <RightSideMenu
                  hasEmail={
                    (popup.catchEmail === true && this.state.isInitialCatch) ||
                    (!this.state.isInitialCatch && catchEmail)
                  }
                  changeTextColor={this.changeTextColor}
                  notifyMsg={this.props.notifyMsg}
                  isCookieCustomization={true}
                  isEmailCustomization={false}
                  isSiteCustomizatio={false}
                  legalsiteSettings={legalsiteSettings}
                  snippet={popup.snippet}
                  changeFontSize={this.changeFontSize}
                  showCustomization={this.props.showCustomization}
                  changeCookieColor={this.changeCookieColor}
                  templateActive={
                    (popup.template === "squared popup" && isInitialTemplate) ||
                    (!isInitialTemplate && templateActive === 1)
                      ? 1
                      : (popup.template === "vertical popup" &&
                          isInitialTemplate) ||
                        (!isInitialTemplate && templateActive === 2)
                      ? 2
                      : (popup.template === "page view" && isInitialTemplate) ||
                        (!isInitialTemplate && templateActive === 4)
                      ? 4
                      : 3
                  }
                  titleColor={titleColor ? titleColor : popup.titleColor}
                  titleSize={titleSize ? titleSize : popup.titleSize}
                  bodyColor={bodyColor ? bodyColor : popup.bodyColor}
                  bodySize={bodySize ? bodySize : popup.bodySize}
                  selectedPage={this.state.selectedPage}
                  cookieBg={cookieBg ? cookieBg : popup.backgroundColor}
                  cookieBtnColor={
                    cookieBtnColor ? cookieBtnColor : popup.secondaryButtonColor
                  }
                  goBack={() => {
                    this.setState({
                      step: 1,
                      templateActive: null,
                      buttonShape: null,
                      popupSide: null,
                      isInitialShape: true,
                      isInitialTemplate: true,
                      isInitialPosition: true,
                      isInitialOverlay: true,
                      isInitialSide: true,
                      cookieTitle: null,
                      cookieDescription: null,
                      titleColor: null,
                      titleSize: null,
                      bodyColor: null,
                      bodySize: null,
                      buttonColor: null,
                      buttonSize: null,
                      buttonTextColor: null,
                      buttonTextSize: null,
                      cookieBg: null,
                      secondaryButtonColor: null,
                      cookieBtnColor: null,
                      popupPosition: null,
                      popupOverlay: null,
                      titleFont: null,
                      bodyFont: null,
                      footerFont: null,
                      buttonFont: null,
                      btnText: null,
                      secondaryBtnText: null,
                      selectedPage: null,
                      type: null,
                      destinationUrl: null,
                      catchEmail: null,
                      catchSignature: null,
                      verifyUser: null,
                      isInitialCatch: true,
                      isInitialCatchSignature: true,
                      isInitialVerify: true,
                    });
                  }}
                  buttonBg={
                    secondaryButtonColor
                      ? secondaryButtonColor
                      : popup.buttonBgColor
                      ? popup.buttonBgColor
                      : "#7450C8"
                  }
                  secondaryButtonColor={
                    secondaryButtonColor
                      ? secondaryButtonColor
                      : popup.buttonBgColor
                  }
                  buttonColor={buttonColor ? buttonColor : popup.buttonColor}
                  buttonSize={
                    buttonSize ? buttonSize : parseInt(popup.buttonSize)
                  }
                  buttonTextColor={
                    buttonTextColor
                      ? buttonTextColor
                      : popup.secondaryButtonColor
                  }
                  buttonTextSize={
                    buttonTextSize
                      ? buttonTextSize
                      : parseInt(popup.secondaryButtonSize)
                  }
                  cookieDescription={
                    cookieDescription
                      ? cookieDescription
                      : popup.body
                      ? popup.body
                      : "This website uses cookies. Click on the button bellow to consent to all cookies in accordance with our Privacy Policy or customize your preferences at any time on our legal page."
                  }
                  cookieTitle={
                    cookieTitle
                      ? cookieTitle
                      : popup.title
                      ? popup.title
                      : "Cookies preferences"
                  }
                  secondaryText={
                    this.state.secondaryBtnText
                      ? this.state.secondaryBtnText
                      : popup.secondaryButtonText
                      ? popup.secondaryButtonText
                      : popup.title === "Cookies preferences"
                      ? "OPTIONS"
                      : "SEE LEGALSITE"
                  }
                  buttonText={
                    this.state.btnText
                      ? this.state.btnText
                      : popup.buttonText
                      ? popup.buttonText
                      : "ACCEPT"
                  }
                  buttonShape={buttonShape ? buttonShape : popup.buttonShapes}
                  popupSide={popupSide ? popupSide : popup.popupSide}
                  popupPosition={
                    popupPosition ? popupPosition : popup.popupPosition
                  }
                  account={account}
                  accountData={this.props.accountData}
                  refetchData={this.refetchData}
                  popupOverlay={popupOverlay ? popupOverlay : popup.overlay}
                  getGoogleFont={this.getGoogleFont}
                  cookieTitleFont={titleFont ? titleFont : popup.titleFont}
                  cookieBodyFont={bodyFont ? bodyFont : popup.bodyFont}
                  cookieButtonTextFont={
                    footerFont ? footerFont : popup.secondaryButtonFont
                  }
                  cookieButtonFont={buttonFont ? buttonFont : popup.buttonFont}
                  submitCookieSettings={this.submitCookieSettings}
                  dataProtectionOfficer={dataProtectionOfficer}
                  contactBg={
                    legalsite.backgroundColor ? legalsite.backgroundColor : null
                  }
                  legalsite={legalsite}
                  btnBg={
                    legalsite.backgroundButtonsColor
                      ? legalsite.backgroundButtonsColor
                      : null
                  }
                  welcomeBg={
                    legalsite.background1 ? legalsite.background1 : null
                  }
                  bg={legalsite.background2 ? legalsite.background2 : null}
                  titleSiteColor={
                    legalsite.titleColor ? legalsite.titleColor : null
                  }
                  titleSiteSize={
                    legalsite.titleSize ? legalsite.titleSize : null
                  }
                  bodySiteColor={
                    legalsite.bodyColor ? legalsite.bodyColor : null
                  }
                  bodySiteSize={legalsite.bodySize ? legalsite.bodySize : null}
                  subtitleColor={
                    legalsite.subtitleColor ? legalsite.subtitleColor : null
                  }
                  subtitleSize={
                    legalsite.subtitleSize ? legalsite.subtitleSize : null
                  }
                  siteBtnColor={
                    legalsite.buttonColor ? legalsite.buttonColor : null
                  }
                  siteBtnSize={
                    legalsite.buttonSize ? legalsite.buttonSize : null
                  }
                  siteTitleFont={
                    legalsite.titleFont ? legalsite.titleFont : null
                  }
                  siteBodyFont={legalsite.bodyFont ? legalsite.bodyFont : null}
                  siteButtonFont={
                    legalsite.buttonFont ? legalsite.buttonFont : null
                  }
                  siteSubtitleFont={
                    legalsite.subtitleFont ? legalsite.subtitleFont : null
                  }
                  popup={popup}
                  getAligned={this.getAligned}
                  getBold={this.getBold}
                  getItalic={this.getItalic}
                  titleBold={titleBold ? titleBold : popup.titleBold}
                  titleItalic={titleItalic ? titleItalic : popup.titleItalic}
                  titleAlign={
                    titleAlign
                      ? titleAlign
                      : popup.titleAlign
                      ? popup.titleAlign
                      : "left"
                  }
                  emailBodyAlign={
                    emailBodyAlign
                      ? emailBodyAlign
                      : popup.bodyAlign
                      ? popup.bodyAlign
                      : "center"
                  }
                  emailBodyBold={emailBodyBold ? emailBodyBold : popup.bodyBold}
                  emailBodyItalic={
                    emailBodyItalic ? emailBodyItalic : popup.bodyItalic
                  }
                  emailBtnBold={emailBtnBold ? emailBtnBold : popup.buttonBold}
                  emailBtnItalic={
                    emailBtnItalic ? emailBtnItalic : popup.buttonItalic
                  }
                  emailFooterBold={emailFooterBold ? emailFooterBold : null}
                  emailFooterItalic={
                    emailFooterItalic ? emailFooterItalic : null
                  }
                />
              </div>
            ) : null}
            <ToastContainer
              position="bottom-left"
              autoClose={3000}
              newestOnTop={false}
              closeButton={false}
              closeOnClick
              rtl={false}
              draggable
              hideProgressBar
            />
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default compose(
  graphql(COOKIE_QUERY, {
    name: "cookie",
    options: () => ({
      variables: {
        popupCategory: "All pages in one pop-up",
      },
      fetchPolicy: "no-cache",
    }),
  }),
  graphql(CUSTOMIZE_COOKIE, {
    name: "customizeCookie",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(CustomizeCookie);
