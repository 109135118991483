import gql from "graphql-tag";

export const BREACHES_DATA_PROCESSORS_QUERY = gql`
  {
    currentDataProcessors(category: ALL) {
      id
      companyName
      email
      dpaFile
      dataProcessor {
        id
        logo
        description
        email
        companyName
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
  }
`;
