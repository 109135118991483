import gql from "graphql-tag";

export const ADD_DPA_TO_INTEGRATION = gql`
  mutation($id: ID!, $dpa: String!, $clientMutationId: String) {
    addDpaToIntegration(
      input: { clientMutationId: $clientMutationId, id: $id, dpa: $dpa }
    ) {
      errors
      integration {
        dpaFile
        dpaStatus
        id
      }
    }
  }
`;
