import gql from "graphql-tag";

export const INTEGRATIONS_QUERY = gql`
  query($integrationCategory: IntegrationCategoryFilterEnum!, $companyName: String!) {
    publicIntegrations(category: $integrationCategory) {
      companyName
      dateAdded
      description
      email
      id
      logo
    }
    searchPublicIntegrations(companyName: $companyName) {
      companyName
      dateAdded
      description
      email
      id
      logo
    }
    account {
      id
      needToUpgrade
      users {
        id
      }
    }
    searchCurrentIntegrations(companyName: $companyName) {
      id
      dataProcessor {
        id
        logo
        description
        email
        companyName
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
    currentIntegrations(category: $integrationCategory) {
      id
      dataProcessor {
        id
        logo
        description
        email
        companyName
        profile {
          companyName
          id
          logo
          photo1
          photo2
          smallDescription
          text1
          text2
          title1
          title2
        }
      }
    }
  }
`;
