import React, { Component } from "react";
import { USERS_DETAILS_QUERY } from "../../graphql/usersDetailsQuery";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { Alert } from "reactstrap";
import SyncLoader from "react-spinners/SyncLoader";
import SideMenu from "../../components/SideMenu/SideMenu";
import MenuContent from "../../components/MenuContent/MenuContent";
import MdClose from "react-icons/lib/md/close";
import { EDIT_DATA_ENTITY } from "../../graphql/editDataEntity";
import { EDIT_NOTES_DATA_ENTITY } from "../../graphql/editNotesDataEntity";
import { REMOVE_DPA_OF_DATA_ENTITY } from "../../graphql/removeDpaOfDataEntity";
import { ADD_DPA_TO_DATA_ENTITY } from "../../graphql/addDpaToDataEntity";
import { TOGGLE_USER_TYPE } from "../../graphql/toggleUserType";
import { EDIT_PURPOSE_DATA_ENTITY } from "../../graphql/editPurposeDataEntity";
import UpgradeBar from "../../components/UpgradeBar/UpgradeBar";
import Navbar from "../../components/Navbar/Navbar";
import { slide as Menu } from "react-burger-menu";
import { ToastContainer, toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import axios from "axios";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import UserInfo from "./UserInfo";
import UserData from "./UserData";
import PropTypes from "prop-types";

class UserDetails extends Component {
  static propTypes = {
    user: PropTypes.object,
    editDataEntity: PropTypes.func,
    toggleUserType: PropTypes.func,
    addDpa: PropTypes.func,
    removeDpaOfDataEntity: PropTypes.func,
    editPurpose: PropTypes.func,
    editNotes: PropTypes.func,
    match: PropTypes.object,
  };

  state = {
    pageId: null,
    formErrors: {},
    description: null,
    user: "",
    files: [],
    fileRemoved: false,
    userType: "Data Subject",
    isInitialShow: true,
    purpose: null,
    notes: null,
  };

  componentDidMount() {
    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      this.setState({
        pageId: this.props.match.params.id,
      });
    }
  }

  notify = (text) => toast(text);

  onFiles() {
    this.props
      .addDpa({
        variables: {
          id: this.props.user.dataEntity.id,
          entityType:
            this.props.user.dataEntity.__typename === "DataSubject"
              ? "DATA_SUBJECT"
              : "DATA_CONTROLLER",
          dpa:
            this.state.files && this.state.files.length
              ? this.state.files[0].url
              : "",
        },
      })
      .then(() => {
        this.props.user.refetch();
        this.notify("DPA uploaded");

        this.setState({
          fileRemoved: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.notify("DPA couldn't be uploaded");
      });
  }

  removeFile = () => {
    this.props
      .removeDpaOfDataEntity({
        variables: {
          dpaId: this.props.user.dataEntity.dpa.id,
        },
      })
      .then(() => {
        this.notify("DPA removed!");
        this.props.user.refetch();

        this.setState({
          fileRemoved: true,
        });
      })
      .catch((error) => {
        console.log(error);
        this.notify("DPA couldn't be removed");
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const name = formData.get("name"),
      company = formData.get("company"),
      email = formData.get("email"),
      phone = formData.get("phone"),
      ip = formData.get("ip"),
      key = formData.get("key"),
      agent = formData.get("agent"),
      internal = formData.get("internal");

    let hasFormErrors = false;
    let formErrors = {};

    if (!name) {
      hasFormErrors = true;
      formErrors.name = true;
    }

    if (!email) {
      hasFormErrors = true;
      formErrors.email = true;
    }

    this.setState({
      formErrors,
    });

    if (hasFormErrors) {
      return;
    }

    this.props
      .editDataEntity({
        variables: {
          entityType:
            this.props.user.dataEntity.__typename === "DataSubject"
              ? "DATA_SUBJECT"
              : "DATA_CONTROLLER",
          id: this.props.user.dataEntity.id,
          name,
          email,
          phoneNumber: phone,
          avatar: this.props.user.dataEntity.avatar,
          company,
          internalId: internal,
          ipAddress: ip,
          keyValue: key,
          userAgent: agent,
        },
      })
      .then(() => {
        this.props.user.refetch();
        this.notify("User info updated");
      })
      .catch((error) => {
        console.log(error);
        this.notify("User info couldn't be updated");
      });
  };

  toggleType = (id, type) => {
    this.props
      .toggleUserType({
        variables: {
          currentUserType:
            type === "DataSubject" ? "DATA_SUBJECT" : "DATA_CONTROLLER",
          id,
        },
      })
      .then(() => {
        this.props.user.refetch();
        this.notify("User type modified");
        window.location.href = "/users";
      })
      .catch((error) => {
        console.log(error);
        if (
          error &&
          error.graphQLErrors[0].message ===
            "Data subject has requests or a signed dpa already."
        ) {
          this.notify("Data subject has requests or a signed dpa already.");
        } else if (
          error &&
          error.graphQLErrors[0].message ===
            "Data controller has requests or a signed dpa already."
        ) {
          this.notify("Data controller has requests or a signed dpa already.");
        } else {
          this.notify("User type couldn't be modified");
        }
      });
  };

  download = (url, name) => {
    axios({
      url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
    });

    this.notify(this.showDownloadNotifyText());
  };

  showDownloadNotifyText = () => {
    return (
      <span className="d-flex align-items-center">
        <DownloadIcon color="#fff" />
        &nbsp; Downloading…
      </span>
    );
  };

  setPurpose = (purpose) => {
    this.setState({
      purpose,
    });
  };

  editNotes = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const notes = formData.get("notes");

    this.props
      .editNotes({
        variables: {
          notes,
          entityType:
            this.props.user.dataEntity.__typename === "DataSubject"
              ? "DATA_SUBJECT"
              : "DATA_CONTROLLER",
          id: this.props.user.dataEntity.id,
        },
      })
      .then(() => {
        this.notify("Notes added");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Notes couldn't be added");
      });
  };

  submitPurpose = (type, id) => {
    this.props
      .editPurpose({
        variables: {
          entityType:
            type === "DataController" ? "DATA_CONTROLLER" : "DATA_SUBJECT",
          id,
          purpose: this.state.purpose === null ? "OTHER" : this.state.purpose,
        },
      })
      .then(() => {
        this.notify("User purpose modified");
      })
      .catch((error) => {
        console.log(error);
        this.notify("User purpose couldn't modified");
      });
  };

  render() {
    const { error, loading, account } = this.props.user;
    const user = this.props.user.dataEntity;
    const { userType } = this.state;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            top: 30px;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error) {
      if (
        localStorage.getItem("auth-token") === null ||
        (error && error.graphQLErrors[0].message === "User not signed in")
      ) {
        return <Redirect to={{ pathname: "/signin" }} />;
      } else {
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
      }
    }

    return user ? (
      <div className="d-flex position-relative">
        <SideMenu getInitialPage={this.getInitialPage} />
        <div className="dashboard w-100">
          {account && account.needToUpgrade ? <UpgradeBar /> : null}
          <Navbar
            title="Users"
            showSideBarMenu={() =>
              this.setState({ isRighMenuOpen: !this.state.isRighMenuOpen })
            }
          />

          <Menu
            right
            isOpen={this.state.isRighMenuOpen}
            noOverlay
            className="right-menu"
          >
            <span
              className="right-menu__close"
              onClick={() => this.setState({ isRighMenuOpen: false })}
            >
              <MdClose size={20} />
            </span>

            <MenuContent />
          </Menu>

          <div className="search-user">
            <div className="container d-flex justify-content-between align-items-start mt-4">
              <UserInfo
                user={user}
                name={this.state.user.name}
                email={this.state.user.email}
                formErrors={this.state.formErrors}
                handleSubmit={this.handleSubmit}
              />

              <UserData
                userType={userType}
                user={user}
                getUserType={(val, initial) => {
                  this.setState({ userType: val, isInitialShow: initial });
                }}
                isInitialShow={this.state.isInitialShow}
                setPurpose={this.setPurpose}
                purpose={
                  this.state.purpose
                    ? this.state.purpose
                    : this.props.user.dataEntity.purpose
                    ? this.props.user.dataEntity.purpose
                    : null
                }
                toggleType={(id, type) => this.toggleType(id, type)}
                submitPurpose={this.submitPurpose}
                removeFile={this.removeFile}
                fileRemoved={this.state.fileRemoved}
                onFiles={(res) => {
                  this.setState({ files: res.filesUploaded });
                  this.onFiles();
                }}
                files={this.state.files}
                editNotes={this.editNotes}
                setNotes={(val) => this.setState({ notes: val })}
                notes={this.state.notes}
              />
            </div>
          </div>

          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            newestOnTop={false}
            closeButton={false}
            closeOnClick
            rtl={false}
            draggable
            hideProgressBar
          />
        </div>
      </div>
    ) : null;
  }
}

export default compose(
  graphql(USERS_DETAILS_QUERY, {
    options: (props) => ({
      variables: {
        id: props.match.params.id,
        entityType:
          props.location.state.type === "DataSubject" ||
          props.location.state.type === "data_subject"
            ? "DATA_SUBJECT"
            : "DATA_CONTROLLER",
      },
      fetchPolicy: "no-cache",
    }),
    name: "user",
  }),
  graphql(EDIT_DATA_ENTITY, {
    name: "editDataEntity",
  }),
  graphql(TOGGLE_USER_TYPE, {
    name: "toggleUserType",
  }),
  graphql(ADD_DPA_TO_DATA_ENTITY, {
    name: "addDpa",
  }),
  graphql(REMOVE_DPA_OF_DATA_ENTITY, {
    name: "removeDpaOfDataEntity",
  }),
  graphql(EDIT_PURPOSE_DATA_ENTITY, {
    name: "editPurpose",
  }),
  graphql(EDIT_NOTES_DATA_ENTITY, {
    name: "editNotes",
  })
)(UserDetails);
