import React, { Component } from "react";
import Bell from "../../components/Icons/Bell";
import Switch from "react-switch";
import SyncLoader from "react-spinners/SyncLoader";
import {
  Row,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Alert,
} from "reactstrap";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import FaCaret from "react-icons/lib/fa/angle-down";
import { EDIT_EMAIL_INTERVAL } from "../../graphql/editEmailNotificationsInterval";
import { TOGGLE_EMAIL_NOTIFICATIONS } from "../../graphql/toggleEmailNotifications";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import PropTypes from "prop-types";

class NotificationSettings extends Component {
  static propTypes = {
    sectionVisible: PropTypes.string.isRequired,
    showSection: PropTypes.func.isRequired,
    enableEmailNotifications: PropTypes.func.isRequired,
    editEmailInterval: PropTypes.func.isRequired,
    viewer: PropTypes.object.isRequired,
  };

  state = {};

  handleChange = () => {
    this.props
      .enableEmailNotifications()
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  editInterval = (interval) => {
    this.props
      .editEmailInterval({
        variables: {
          interval,
        },
      })
      .then()
      .catch((error) => {
        console.log(error);
      });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line react/no-string-refs
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  render() {
    const { sectionVisible, showSection } = this.props;
    const isActive = sectionVisible === "notifications" ? true : false;
    const { viewer, error, loading } = this.props.viewer;

    if (isActive) {
      if (loading)
        return (
          <SyncLoader
            css={`
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            `}
            sizeUnit={"px"}
            size={10}
            color={"#7450c8"}
            loading={true}
          />
        );

      if (error)
        return (
          <Alert color="danger"> There was an error loading the data!</Alert>
        );
    }

    return (
      <div
        className={`${isActive ? "-is-active" : ""} settings__account`}
        // eslint-disable-next-line react/no-string-refs
        ref="notifications"
        onClick={
          !isActive
            ? () => {
                showSection("notifications");
                this.scrollToSection("notifications");
              }
            : () => showSection("notifications")
        }
      >
        <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
          <Bell />
          &nbsp; Notification Settings
        </h2>
        <p className="settings__text">Configure your notifications.</p>

        {isActive && viewer ? (
          <div>
            <Row>
              <Col
                sm={12}
                md={12}
                lg={6}
                className="flex-row d-flex align-items-center justify-content-between settings__notifications-item"
              >
                <div className="d-flex flex-column">
                  <p className="settings__notifications-label">
                    E-mail notifications
                  </p>

                  <div>
                    <UncontrolledDropdown className="chart__dropdown w-100">
                      <DropdownToggle
                        disabled={!viewer.emailNotifications}
                        className="w-100 d-flex align-items-center chart__toggle pl-0 pt-0 border-0 text-left"
                      >
                        Receive e-mail notifications{" "}
                        {viewer.emailNotificationsInterval === "ONCE_A_WEEK"
                          ? "once a week"
                          : viewer.emailNotificationsInterval === "ONCE_A_MONTH"
                          ? "once a month"
                          : "any time"}
                        .{" "}
                        <FaCaret
                          className="chart__caret"
                          size="20"
                          color="#969696"
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        {viewer.emailNotificationsInterval !== "ONCE_A_WEEK" ? (
                          <DropdownItem
                            onClick={() => this.editInterval("ONCE_A_WEEK")}
                          >
                            Receive e-mail notifications once a week
                          </DropdownItem>
                        ) : null}

                        {viewer.emailNotificationsInterval !==
                        "ONCE_A_MONTH" ? (
                          <DropdownItem
                            onClick={() => this.editInterval("ONCE_A_MONTH")}
                          >
                            Receive e-mail notifications once a month
                          </DropdownItem>
                        ) : null}

                        {viewer.emailNotificationsInterval !== "ANYTIME" ? (
                          <DropdownItem
                            onClick={() => this.editInterval("ANYTIME")}
                          >
                            Receive e-mail notifications any time
                          </DropdownItem>
                        ) : null}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>

                <span className="settings__notifications-toggle">
                  <Switch
                    onColor={"#1DDCC0"}
                    offColor={"#B0BAC9"}
                    height={20}
                    width={36}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onChange={this.handleChange}
                    checked={viewer.emailNotifications}
                  />
                </span>
              </Col>
            </Row>
          </div>
        ) : null}
      </div>
    );
  }
}

export default compose(
  graphql(EDIT_EMAIL_INTERVAL, {
    name: "editEmailInterval",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  }),
  graphql(TOGGLE_EMAIL_NOTIFICATIONS, {
    name: "enableEmailNotifications",
  })
)(NotificationSettings);
