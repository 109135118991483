import gql from "graphql-tag";

export const CONFIGURE_DATA_BUTTON = gql`
  mutation(
    $buttonType: ButtonTypeEnum
    $text: String
    $targetAudience: ButtonTargetAudienceEnum
    $color: String
    $textColor: String
    $textFont: String
    $textSize: Int
    $clientMutationId: String
  ) {
    configureDataButton(
      input: {
        buttonType: $buttonType
        text: $text
        targetAudience: $targetAudience
        color: $color
        textColor: $textColor
        textFont: $textFont
        textSize: $textSize
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
