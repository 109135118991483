import gql from "graphql-tag";

export const DP_ACCOUNT_QUERY = gql`
query($dpId: ID!) {
    dataProcessor(id: $dpId) {
      id
      companyName
      profile {
        id
        companyName
        logo
        smallDescription
        photo1
        photo2
        text1
        text2
        title1
        title2
      }
    }
  }
`;
