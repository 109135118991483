import gql from "graphql-tag";

export const BREACHES_QUERY = gql`
  {
    breachIncidents {
      affectedRecordsKnown
      additionalInformation
      awenessedDatetime
      breachOngoing
      breachedDatetime
      causeDescription
      causes
      endedDatetime
      id
      indentifyingDetailsInIndividuals
      lastUpdated
      mitigatingActionsImplemented
      natureDescription
      natures
      numberOfAffectedRecords
      personalDataCriminals
      potentialConsequences
      risk
      name
      securedBreachedData
      specialCategoriesOfData
      takenMeasures
      vulnerableIndividualsAffectedKnown
      where
      downloadUrl
      breachMails {
        body
        id
        signature
        target
      }
    }
  }
`;
