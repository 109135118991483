import React, { Component, Fragment } from "react";
import Palette from "../../components/Icons/Palette";
import { Form, Button } from "reactstrap";
import CustomizationItem from "./CustomizationItem";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { EDIT_LEGALSITE_SETTINGS } from "../../graphql/editLegalsiteSettings";
import { ToastContainer, toast } from "react-toastify";
import ReactFilestack from "filestack-react";
import Upgrade from "../../components/Modals/Upgrade";
import { VIEWER_QUERY } from "../../graphql/viewerQuery";
import Branding from "../../components/Icons/Branding";
import PropTypes from "prop-types";

class CustomizationSettings extends Component {
  state = {
    docColor: null,
    files: [],
    isUpgradeVisible: false,
    type: null,
    faviconFiles: [],
  };

  onFiles = ({ filesUploaded }) => {
    this.setState({ files: filesUploaded });
  };

  onFaviconUpload = ({ filesUploaded }) => {
    this.setState({ faviconFiles: filesUploaded });
  };

  notify = (text) => toast(text);

  handleSubmit = (e) => {
    e.preventDefault();
    const avatar = this.state.files.length
        ? this.state.files[0].url
        : this.props.legalsiteSettings.companyLogo
        ? this.props.legalsiteSettings.companyLogo
        : null,
      brandColor = this.state.docColor
        ? this.state.docColor
        : this.props.legalsiteSettings.brandColor
        ? this.props.legalsiteSettings.brandColor
        : "#7450C8",
      favicon = this.state.faviconFiles.length
        ? this.state.faviconFiles[0].url
        : this.props.legalsiteSettings.favicon
        ? this.props.legalsiteSettings.favicon
        : null;

    this.props
      .editLegalsiteSettings({
        variables: {
          companyLogo: avatar,
          brandColor,
          favicon: favicon,
        },
      })
      .then(() => {
        this.props.viewer.refetch();
        this.notify("Company settings saved!");
      })
      .catch((error) => {
        console.log(error);
        this.notify("Company settings were not saved!");
      });
  };

  showUpgrade = () => {
    this.setState({
      isUpgradeVisible: !this.state.isUpgradeVisible,
    });
  };

  scrollToSection = (section) => {
    setTimeout(() => {
      // eslint-disable-next-line
      this.refs[section].scrollIntoView({ block: "start", behavior: "smooth" });
    }, 500);
  };

  render() {
    const { sectionVisible, showSection } = this.props;
    const isActive = sectionVisible === "branding" ? true : false;
    const { legalsiteSettings } = this.props;
    const { files, faviconFiles } = this.state;

    return (
      <Fragment>
        <div
          className={`${isActive ? "-is-active" : ""} settings__account`}
          // eslint-disable-next-line
          ref="branding"
          onClick={
            !isActive
              ? () => {
                  showSection("branding");
                  this.scrollToSection("branding");
                }
              : () => showSection("branding")
          }
        >
          <h2 className={`${isActive ? "-is-active" : ""} settings__title`}>
            <Palette color="#7450C8" />
            &nbsp; Customization
          </h2>
          <p className="settings__text">
            Customize your LegalSite, your Cookie Pop-up and your e-mails.
          </p>

          {isActive ? (
            <div className="settings__legal">
              <div className="settings__legal-info d-flex mt-4">
                <div className="d-flex align-items-center settings__legal-logo-container">
                  <div className="position-relative">
                    <p className="settings__legal-color">Company logo</p>
                    {files.length || (files.length && !legalsiteSettings) ? (
                      files.map((file, i) => (
                        <div key={i} className="settings__avatar-container">
                          <img src={file.url} alt="avatar" />
                        </div>
                      ))
                    ) : !files.length &&
                      legalsiteSettings &&
                      legalsiteSettings.companyLogo ? (
                      <div className="settings__avatar-container">
                        <img src={legalsiteSettings.companyLogo} alt="avatar" />
                      </div>
                    ) : (
                      <span className="settings__image-placeholder" />
                    )}

                    <ReactFilestack
                      apikey={"AJNM9qOpGRljTn17sgxrfz"}
                      componentDisplayMode={{
                        type: "link",
                        customText: <span />,
                        customClass: "show-upload",
                      }}
                      actionOptions={{
                        accept: ["image/*"],
                        maxFiles: 1,
                      }}
                      onSuccess={this.onFiles}
                    />
                  </div>
                </div>

                <div className="settings__favicon position-relative">
                  <p className="settings__legal-color">Favicon</p>

                  {faviconFiles.length ||
                  (faviconFiles.length && !legalsiteSettings) ? (
                    faviconFiles.map((file, i) => (
                      <div key={i} className="settings__favicon-container">
                        <img src={file.url} alt="avatar" />
                      </div>
                    ))
                  ) : !faviconFiles.length &&
                    legalsiteSettings &&
                    legalsiteSettings.favicon ? (
                    <div className="settings__favicon-container">
                      <img src={legalsiteSettings.favicon} alt="avatar" />
                    </div>
                  ) : (
                    <span className="settings__image-favicon-placeholder" />
                  )}

                  <ReactFilestack
                    apikey={"AJNM9qOpGRljTn17sgxrfz"}
                    componentDisplayMode={{
                      type: "link",
                      customText: <span />,
                      customClass: "show-upload-favicon",
                    }}
                    actionOptions={{
                      accept: [".png", ".ico"],
                      maxFiles: 1,
                    }}
                    onSuccess={this.onFaviconUpload}
                  />
                </div>
              </div>

              <Form
                onSubmit={this.handleSubmit}
                className="settings__legal-form"
              >
                <Button
                  type="submit"
                  className="settings__user-save btn--primary"
                >
                  Save changes
                </Button>
              </Form>

              <div className="d-flex flex-wrap">
                <div
                  className="settings__branding mt-5"
                  onClick={() => this.props.showCustomization("branding")}
                >
                  <Branding className="settings__branding-icon" />
                  <div className="settings__branding-content">
                    <h4 className="settings__customization-title mb-5">
                      Branding
                    </h4>
                    <p className="settings__customization-text">
                      Customize all your brand assets colors, fonts and logo.
                      The selections you choose on Branding will be applied to:
                    </p>

                    <ul>
                      <li>LegalSite</li>
                      <li>Pop-ups</li>
                      <li>E-mails</li>
                      <li>Don’t sell data Button</li>
                    </ul>
                  </div>
                </div>
                <div className="d-flex flex-wrap flex-column mt-5">
                  <CustomizationItem
                    title="LegalSite"
                    text="Choose colors and fonts for your LegalSite."
                    showCustomization={this.props.showCustomization}
                  />

                  <CustomizationItem
                    title="Pop-ups"
                    text="Define the shape, position and pop-up style."
                    showCustomization={this.props.showCustomization}
                  />

                  <CustomizationItem
                    title="E-mail templates"
                    text="Choose color, fonts and text for your e-mail templates."
                    showCustomization={this.props.showCustomization}
                  />

                  <CustomizationItem
                    title="Don’t sell data Button"
                    text="This button is required by the CCPA only. Choose type, color, and fonts."
                    showCustomization={this.props.showCustomization}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          newestOnTop={false}
          closeButton={false}
          closeOnClick
          rtl={false}
          draggable
          hideProgressBar
        />

        <Upgrade
          showUpgrade={this.showUpgrade}
          isCustomization={true}
          isUpgradeVisible={this.state.isUpgradeVisible}
          type={this.state.type}
          getSample={this.props.getSample}
        />
      </Fragment>
    );
  }
}

export default compose(
  graphql(EDIT_LEGALSITE_SETTINGS, {
    name: "editLegalsiteSettings",
  }),
  graphql(VIEWER_QUERY, {
    name: "viewer",
  })
)(CustomizationSettings);

CustomizationSettings.propTypes = {
  sectionVisible: PropTypes.string,
  showSection: PropTypes.func,
  legalsiteSettings: PropTypes.object,
  editLegalsiteSettings: PropTypes.func,
  viewer: PropTypes.object,
  showCustomization: PropTypes.func,
  getSample: PropTypes.func,
};
