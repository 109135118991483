import React, { Component } from "react";
import { Link } from "react-router-dom";
import { graphql } from "@apollo/client/react/hoc";
import { LEGALSITE_SETTINGS_QUERY } from "../../graphql/legalSiteSettings";
import { Alert } from "reactstrap";
import "./manage-consent.scss";
import ManageConsentItem from "./ManageConsentItem";
import PropTypes from "prop-types";

class ManageConsent extends Component {
  static propTypes = {
    data: PropTypes.object,
  };

  state = {};

  render() {
    const { legalsiteSettings, loading, error } = this.props.data;

    if (loading) return <p>Loading...</p>;

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <div className="manage-consent">
        <div className="legalsite__nav">
          <Link
            to="/legalSites"
            className="d-flex align-items-center text-decoration-none"
          >
            {legalsiteSettings?.companyLogo ? (
              <div className="legalsite__logo-container">
                <img src={legalsiteSettings.companyLogo} alt="logo" />
              </div>
            ) : (
              <span className="legalsite__logo" />
            )}
            <span className="legalsite__brand">
              {legalsiteSettings.companyName}
            </span>
          </Link>

          <span className="legalsite__link">Get your LegalSite</span>
        </div>

        <div className="manage-consent__container">
          <div className="manage-consent__header">
            <div className="container">
              <h1 className="manage-consent__header-title">Manage Consent</h1>
              <p className="manage-consent__header-text">
                Here you can manage what data you agree to share with us.
                <br />
                Don’t worry, you can change them anytime you like.
              </p>
            </div>
          </div>

          <div className="manage-consent__items container">
            <ManageConsentItem
              title="Essential"
              text="We use browser cookies that are necessary for the site to work as intended."
            />

            <ManageConsentItem
              title="Advertising"
              text="To personalize and measure the effectiveness of advertising on our site and other websites."
              hasToggle={true}
            />

            <ManageConsentItem
              title="Analytics"
              text="We use browser cookies that are necessary for the site to work as intended."
              hasToggle={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default graphql(LEGALSITE_SETTINGS_QUERY)(ManageConsent);
