import PropTypes from "prop-types";
import React from "react";

const DashboardIcon = props => (
    <svg
        height={20}
        viewBox='0 0 20 20'
        width={20}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Style-Guide---UI-Desktop" transform="translate(-362.000000, -4408.000000)">
                <g id="Icons" transform="translate(72.000000, 4283.000000)">
                    <g id="Group-9" transform="translate(0.000000, 80.000000)">
                        <g id="apps" transform="translate(288.000000, 43.000000)">
                            <rect id="button-bg" x="0" y="0" width="24" height="24" rx="2"></rect>
                            <path d="M10,13 L3,13 C2.44771525,13 2,13.4477153 2,14 L2,21 C2,21.5522847 2.44771525,22 3,22 L10,22 C10.5522847,22 11,21.5522847 11,21 L11,14 C11,13.4477153 10.5522847,13 10,13 Z M9,20 L4,20 L4,15 L9,15 L9,20 Z M21,2 L14,2 C13.4477153,2 13,2.44771525 13,3 L13,10 C13,10.5522847 13.4477153,11 14,11 L21,11 C21.5522847,11 22,10.5522847 22,10 L22,3 C22,2.44771525 21.5522847,2 21,2 Z M20,9 L15,9 L15,4 L20,4 L20,9 Z M21,13 L14,13 C13.4477153,13 13,13.4477153 13,14 L13,21 C13,21.5522847 13.4477153,22 14,22 L21,22 C21.5522847,22 22,21.5522847 22,21 L22,14 C22,13.4477153 21.5522847,13 21,13 Z M20,20 L15,20 L15,15 L20,15 L20,20 Z M10,2 L3,2 C2.44771525,2 2,2.44771525 2,3 L2,10 C2,10.5522847 2.44771525,11 3,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,3 C11,2.44771525 10.5522847,2 10,2 Z M9,9 L4,9 L4,4 L9,4 L9,9 Z" id="Shape" fill="#fff" fillRule="nonzero"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

DashboardIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default DashboardIcon;