import gql from "graphql-tag";

export const REVIEW_TEMPLATE_CHANGES = gql`
  mutation(
    $pageTemplateId: ID!
    $alertId: ID!
    $approve: Boolean!
    $clientMutationId: String
  ) {
    reviewTemplateChanges(
      input: {
        pageTemplateId: $pageTemplateId
        alertId: $alertId
        approve: $approve
        clientMutationId: $clientMutationId
      }
    ) {
      errors
    }
  }
`;
