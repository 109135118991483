import PropTypes from "prop-types";
import React from "react";

const WarningIcon = props => (
    <svg
        height={20}
        viewBox='0 0 22 20'
        width={22}
        fill='currentColor'
        {...props}
        style={{
            verticalAlign: "middle",
            color: props.color,
            ...props.style
        }}>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="1.1--Dashboard-expanded" transform="translate(-1315.000000, -308.000000)" fill="#F86B67" fillRule="nonzero">
                <g id="Legal-site-box" transform="translate(1063.000000, 130.000000)">
                    <g id="Privacy" transform="translate(12.000000, 159.000000)">
                        <g id="exclamation-triangle" transform="translate(240.000000, 19.000000)">
                            <path d="M11.4285714,14.2857143 C10.902586,14.2857143 10.4761905,14.7121098 10.4761905,15.2380952 C10.4761905,15.7640807 10.902586,16.1904762 11.4285714,16.1904762 C11.9545569,16.1904762 12.3809524,15.7640807 12.3809524,15.2380952 C12.3809524,14.7121098 11.9545569,14.2857143 11.4285714,14.2857143 Z M21.5904762,15.6857143 L13.9238095,2.35238095 C13.4192048,1.44770586 12.4644589,0.887018306 11.4285714,0.887018306 C10.3926839,0.887018306 9.43793804,1.44770586 8.93333333,2.35238095 L1.31428571,15.6857143 C0.792428951,16.5658321 0.781053649,17.6578226 1.28446272,18.5486207 C1.78787179,19.4394188 2.729203,19.9930143 3.75238095,20.0001393 L19.1047619,20.0001393 C20.1364502,20.0101672 21.0934815,19.4633272 21.6085517,18.5693548 C22.1236219,17.6753823 22.1167129,16.5731595 21.5904762,15.6857143 Z M19.9428571,17.5904762 C19.7728384,17.8929716 19.4517513,18.0790562 19.1047619,18.0762231 L3.75238095,18.0762231 C3.40539157,18.0790562 3.08430448,17.8929716 2.91428571,17.5904762 C2.74415924,17.2958085 2.74415924,16.9327629 2.91428571,16.6380952 L10.5333333,3.3047619 C10.6962819,2.98669884 11.0235782,2.78662907 11.3809524,2.78662907 C11.7383266,2.78662907 12.0656229,2.98669884 12.2285714,3.3047619 L19.8952381,16.6380952 C20.0877189,16.9287807 20.1059849,17.3014065 19.9428571,17.6095238 L19.9428571,17.5904762 Z M11.4285714,6.66666667 C10.902586,6.66666667 10.4761905,7.09306214 10.4761905,7.61904762 L10.4761905,11.4285714 C10.4761905,11.9545569 10.902586,12.3809524 11.4285714,12.3809524 C11.9545569,12.3809524 12.3809524,11.9545569 12.3809524,11.4285714 L12.3809524,7.61904762 C12.3809524,7.09306214 11.9545569,6.66666667 11.4285714,6.66666667 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

WarningIcon.propTypes = {
    color: PropTypes.string,
    style: PropTypes.object
};

export default WarningIcon;