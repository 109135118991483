import gql from "graphql-tag";

export const NOTIFICATIONS_QUERY = gql`
  {
    notifications {
      category
      id
      receivedDate
      request {
        id
      }
    }
    account {
      access
      id
    }
  }
`;
