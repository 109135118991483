import gql from "graphql-tag";

export const TOGGLE_USER_TYPE = gql`
  mutation(
    $clientMutationId: String
    $currentUserType: ToggleUserTypeEnum!
    $id: ID!
  ) {
    toggleUserType(
      input: {
        clientMutationId: $clientMutationId
        currentUserType: $currentUserType
        id: $id
      }
    ) {
      errors
    }
  }
`;
