import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class RetentionPeriod extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    period: PropTypes.string,
    getPeriod: PropTypes.func.isRequired,
  };

  state = {
    is6Months:
      this.props.period && this.props.period === "6_MONTHS" ? true : false,
    is1Year: this.props.period && this.props.period === "1_YEAR" ? true : false,
    is3Years:
      this.props.period && this.props.period === "3_YEARS" ? true : false,
    is5Years:
      this.props.period && this.props.period === "5_YEARS" ? true : false,
    isOther:
      this.props.period &&
      this.props.period !== "5_YEARS" &&
      this.props.period !== "6_MONTHS" &&
      this.props.period !== "1_YEAR" &&
      this.props.period !== "3_YEARS"
        ? true
        : false,
    isInitialOption: true,
    other: "",
  };

  render() {
    const { is6Months, is1Year, is3Years, is5Years, isOther, isInitialOption } =
      this.state;
    const { period } = this.props;
    const info =
      this.props.period &&
      !this.props.period.includes("5_YEARS") &&
      !this.props.period.includes("6_MONTHS") &&
      !this.props.period.includes("1_YEAR") &&
      !this.props.period.includes("3_YEARS")
        ? this.props.period
        : "";

    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title">
          Retention period
        </h5>

        <div className="d-flex">
          <div className="d-flex align-items-center modal-edit-privacy-category__content-check-label">
            <span
              className={`${
                (period === "6_MONTHS" && isInitialOption) ||
                (!isInitialOption && is6Months)
                  ? "-is-active"
                  : ""
              } modal-edit-privacy-category__content-check mr-2`}
              onClick={() => {
                this.setState({
                  is6Months: true,
                  is1Year: false,
                  is3Years: false,
                  is5Years: false,
                  isOther: false,
                  isInitialOption: false,
                });
                this.props.getPeriod("6_MONTHS");
              }}
            />
            6 months
          </div>

          <div className="d-flex align-items-center modal-edit-privacy-category__content-check-label">
            <span
              className={`${
                (period === "1_YEAR" && isInitialOption) ||
                (!isInitialOption && is1Year)
                  ? "-is-active"
                  : ""
              } modal-edit-privacy-category__content-check mr-2`}
              onClick={() => {
                this.setState({
                  is1Year: true,
                  is6Months: false,
                  is3Years: false,
                  is5Years: false,
                  isOther: false,
                  isInitialOption: false,
                });
                this.props.getPeriod("1_YEAR");
              }}
            />
            1 year
          </div>
        </div>

        <div className="d-flex">
          <div className="d-flex align-items-center modal-edit-privacy-category__content-check-label">
            <span
              className={`${
                (period === "3_YEARS" && isInitialOption) ||
                (!isInitialOption && is3Years)
                  ? "-is-active"
                  : ""
              } modal-edit-privacy-category__content-check mr-2`}
              onClick={() => {
                this.setState({
                  is3Years: true,
                  is6Months: false,
                  is1Year: false,
                  is5Years: false,
                  isOther: false,
                  isInitialOption: false,
                });
                this.props.getPeriod("3_YEARS");
              }}
            />
            3 years
          </div>

          <div className="d-flex align-items-center modal-edit-privacy-category__content-check-label">
            <span
              className={`${
                (period === "5_YEARS" && isInitialOption) ||
                (!isInitialOption && is5Years)
                  ? "-is-active"
                  : ""
              } modal-edit-privacy-category__content-check mr-2`}
              onClick={() => {
                this.setState({
                  is5Years: true,
                  is6Months: false,
                  is1Year: false,
                  is3Years: false,
                  isOther: false,
                  isInitialOption: false,
                });
                this.props.getPeriod("5_YEARS");
              }}
            />
            5 years
          </div>
        </div>

        <div className="d-flex">
          <div className="d-flex align-items-center modal-edit-privacy-category__content-check-label -is-other">
            <span
              className={`${
                (period === "other" && isInitialOption) ||
                (!isInitialOption && isOther) ||
                info
                  ? "-is-active"
                  : ""
              } modal-edit-privacy-category__content-check mr-2`}
              onClick={() => {
                this.setState({
                  is3Years: false,
                  is6Months: false,
                  is1Year: false,
                  is5Years: false,
                  isOther: true,
                  isInitialOption: false,
                });
                this.props.getPeriod(this.state.other);
              }}
            />
            <Input
              disabled={!isOther}
              type="text"
              defaultValue={info ? info : ""}
              placeholder="Other"
              onChange={(e) =>
                this.setState({ other: e.target.value }, () =>
                  this.props.getPeriod(this.state.other)
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
