import React from "react";
import PropTypes from "prop-types";
import GoogleFontLoader from "react-google-font-loader";

function FontLoader(props) {
  const { font1, font2, font3, font4 } = props;

  return (
    <GoogleFontLoader
      fonts={[
        {
          font: font1,
        },
        {
          font: font2,
        },
        {
          font: font3,
        },
        {
          font: font4,
        },
      ]}
    />
  );
}

export default FontLoader;
FontLoader.propTypes = {
  font1: PropTypes.string.isRequired,
  font2: PropTypes.string.isRequired,
  font3: PropTypes.string.isRequired,
  font4: PropTypes.string.isRequired,
};
