import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import MdClose from "react-icons/lib/md/close";
import MdArrow from "react-icons/lib/ti/arrow-right";
import Publish from "../../images/Settings/Illustration.svg";
import Site from "../../images/Settings/Illustration2.svg";
import Email from "../../images/Settings/email.svg";
import "./modals.scss";

export default class CookieConsentModal extends Component {
  static propTypes = {
    showConsentModal: PropTypes.func.isRequired,
    isConsentModalVisible: PropTypes.bool.isRequired,
    isForLegalSite: PropTypes.bool,
    isForEmail: PropTypes.bool,
  };

  state = {};

  render() {
    const { isForLegalSite, isForEmail } = this.props;
    return (
      <div>
        <Modal
          isOpen={this.props.isConsentModalVisible}
          toggle={this.props.showConsentModal}
          className="modal-consent"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.showConsentModal}
            />
            <div className="modal-assign__image">
              <img
                src={isForLegalSite ? Site : isForEmail ? Email : Publish}
                alt="Publish"
              />
            </div>
            <p className="modal-assign__title">
              {isForLegalSite
                ? "LegalSite published!"
                : isForEmail
                ? "E-mail template saved!"
                : "Cookie consent Pop-up published!"}
            </p>
            <p className="modal-assign__message w-100 mb-4">
              {isForLegalSite
                ? "Your LegalSite was published and updated."
                : isForEmail
                ? "You have updated your e-mail template."
                : "You have updated your website Cookie Pop-up."}
            </p>
            <Link to="/dashboard" className="modal-assign__link">
              Dashboard <MdArrow size={23} />
            </Link>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
