import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, Button } from "reactstrap";
import MdClose from "react-icons/lib/md/close";

export default class DeleteUserModal extends Component {
  static propTypes = {
    openDeleteClientModal: PropTypes.func.isRequired,
    isDeleteClientModalOpen: PropTypes.bool.isRequired,
    deleteClient: PropTypes.func.isRequired,
    isClients: PropTypes.bool,
  };

  state = {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isDeleteClientModalOpen}
          toggle={this.props.openDeleteClientModal}
          className="delete-modal"
        >
          <ModalBody>
            <MdClose
              className="modal-assign__close"
              size={25}
              onClick={this.props.openDeleteClientModal}
            />
            <p className="modal-assign__title mb-3">
              Are you sure you want to delete{" "}
              {this.props.isClients ? "these clients" : "this client"}?
            </p>
            <p className="modal-assign__text mb-0 mt-0">
              This step can not be undone.
            </p>

            <div className="discard-changes__btns">
              <Button
                className="btn--secondary"
                onClick={this.props.openDeleteClientModal}
              >
                Cancel
              </Button>
              <Button
                className="btn--primary"
                onClick={this.props.deleteClient}
              >
                Delete {this.props.isClients ? "Cients" : "Client"}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
