import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import Check from "react-icons/lib/fa/check";
import MdClose from "react-icons/lib/md/close";

export default class Actions extends Component {
  static propTypes = {
    showEditModal: PropTypes.func.isRequired,
    action: PropTypes.array,
    getActions: PropTypes.func.isRequired,
  };

  state = {
    isDelete: this.props.action
      ? this.props.action.indexOf(
          "Delete data where there is no further purpose for retention"
        ) > -1
        ? true
        : false
      : null,
    other: null,
    isOther: this.props.action
      ? this.props.action.filter(
          (item) =>
            item !==
            "Delete data where there is no further purpose for retention"
        ).length
        ? true
        : false
      : false,
  };

  getActions = () => {
    const info = this.props.action
      ? this.props.action.filter(
          (item) =>
            item !==
            "Delete data where there is no further purpose for retention"
        )
      : "";

    this.props.getActions([
      this.state.isDelete === true
        ? "Delete data where there is no further purpose for retention"
        : null,
      this.state.other !== null
        ? this.state.other
        : this.state.isOther
        ? info.toString()
        : null,
    ]);
  };

  render() {
    const info = this.props.action
      ? this.props.action.filter(
          (item) =>
            item !==
            "Delete data where there is no further purpose for retention"
        )
      : "";

    return (
      <div>
        <MdClose
          className="modal-edit-privacy-category__content-close"
          size={25}
          onClick={this.props.showEditModal}
        />
        <h5 className="modal-edit-privacy-category__content-title mb-2">
          Actions required
        </h5>

        <p className="modal-edit-privacy-category__content-subtitle">
          Identify actions that are required to ensure all personal data
          processing operations are GDPR compliant.
        </p>

        <div className="d-flex mb-3">
          <span
            className={`check ${
              this.state.isDelete ? "-checked" : "-unchecked"
            }`}
            onClick={() =>
              this.setState({ isDelete: !this.state.isDelete }, () =>
                this.getActions()
              )
            }
          >
            <Check color={"#fff"} />
          </span>
          <span className="modal-edit-privacy-category__content-text">
            Delete data where there is no further purpose for retention
          </span>
        </div>

        <div className="d-flex flex-column">
          <div className="d-flex">
            <span
              className={`check ${
                this.state.isOther ? "-checked" : "-unchecked"
              }`}
              onClick={() =>
                this.setState({ isOther: !this.state.isOther }, () =>
                  this.getActions()
                )
              }
            >
              <Check color={"#fff"} />
            </span>
            <span className="modal-edit-privacy-category__content-text">
              Other
            </span>
          </div>

          <Input
            disabled={!this.state.isOther}
            type="text"
            className="mt-3 w-75"
            defaultValue={info ? info : ""}
            placeholder="Other"
            onChange={(e) =>
              this.setState({ other: e.target.value }, () => this.getActions())
            }
          />
        </div>
      </div>
    );
  }
}
