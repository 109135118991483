import React from "react";
import { Button } from "reactstrap";
import Image from "../../images/History/Illustration.svg";
import { Link } from "react-router-dom";
import Signed from "../../images/Users/Document.svg";
import { graphql } from "@apollo/client/react/hoc";
import { PAGES_DATA_QUERY } from "../../graphql/pagesDataQuery";
import PropTypes from "prop-types";

function NoData(props) {
  const { pages } = props.data;

  const page = pages
    ? pages.filter((item) => item.title === "Data Processing Agreement")
    : null;
  let DPAIndex;

  if (pages) {
    pages.filter((item, index) => {
      if (item.title === "Data Processing Agreement") {
        return (DPAIndex = index);
      }
    });
  }

  return (
    <div className="d-flex mt-4">
      <div className="compliance-no-data-item">
        <p className="compliance-no-data__title">Manage my own DPA</p>
        <div>
          <img src={Signed} alt="DPA" />
        </div>
        <p className="compliance-no-data__text">
          You don't have any data yet. Set up your Data Processing Agreement so
          you can request companies to sign it
        </p>
        <Link
          to={{
            pathname: "/pages",
            state: {
              isDPA: true,
              page: page ? page[0] : null,
              index: DPAIndex,
            },
          }}
          className="btn--primary d-flex align-items-center justify-content-center"
        >
          Edit DPA
        </Link>
      </div>

      <div className="compliance-no-data-item">
        <p className="compliance-no-data__title">
          Manage Data Processor's DPAs
        </p>
        <div>
          <img src={Image} alt="DPA" />
        </div>
        <p className="compliance-no-data__text">
          You don't have any data yet. Upload a Data Processing Agreement
          received from a Data Processor.
        </p>
        <Button className="btn--primary" onClick={props.redirectToDP}>
          Upload DPA
        </Button>
      </div>
    </div>
  );
}

export default graphql(PAGES_DATA_QUERY)(NoData);
NoData.propTypes = {
  data: PropTypes.object.isRequired,
  redirectToDP: PropTypes.func.isRequired,
};
