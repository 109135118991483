import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import Template1 from "../../images/Settings/Popup.png";
import Template2 from "../../images/Settings/Popup 2.svg";
import Template3 from "../../images/Settings/Popup 3.svg";
import Template4 from "../../images/Settings/Popup4.svg";
import Template5 from "../../images/Settings/Popup4.2.svg";
import GreenCheck from "../../images/Settings/check-circle.png";

function Step2Popup(props) {
  const {
    popup,
    isInitialTemplate,
    templateActive,
    chooseTemplate,
    showSteps,
  } = props;

  return (
    <div className="d-flex align-items-end flex-wrap">
      <div>
        <h3 className="customization-cookie__title">
          2. Choose a Pop-up template
        </h3>

        <div className="d-flex customization-cookie__item">
          <p className="customization-cookie__text">.Small squared popup</p>
          <img
            onClick={() => chooseTemplate(1)}
            className={`${
              (popup.template === "squared popup" && isInitialTemplate) ||
              (!isInitialTemplate && templateActive === 1)
                ? "-is-active"
                : ""
            } customization-cookie__img`}
            src={Template1}
            alt="template"
          />
          {(popup.template === "squared popup" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 1) ? (
            <div className="customization-cookie__check">
              <img src={GreenCheck} alt="check" />
            </div>
          ) : null}
        </div>

        <div className="d-flex customization-cookie__item">
          <p className="customization-cookie__text">.Vertical popup</p>
          <img
            onClick={() => chooseTemplate(2)}
            className={`${
              (popup.template === "vertical popup" && isInitialTemplate) ||
              (!isInitialTemplate && templateActive === 2)
                ? "-is-active"
                : ""
            } customization-cookie__img`}
            src={Template2}
            alt="template"
          />
          {(popup.template === "vertical popup" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 2) ? (
            <div className="customization-cookie__check">
              <img src={GreenCheck} alt="check" />
            </div>
          ) : null}
        </div>

        <div className="d-flex customization-cookie__item">
          <p className="customization-cookie__text">.Overlay popup</p>
          <img
            onClick={() => chooseTemplate(3)}
            className={`${
              (popup.template === "overlay popup" && isInitialTemplate) ||
              (!isInitialTemplate && templateActive === 3)
                ? "-is-active"
                : ""
            } customization-cookie__img`}
            src={Template3}
            alt="template"
          />
          {(popup.template === "overlay popup" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 3) ? (
            <div className="customization-cookie__check">
              <img src={GreenCheck} alt="check" />
            </div>
          ) : null}
        </div>

        <div className="d-flex customization-cookie__item">
          <p className="customization-cookie__text">
            .Overlay popup
            <br />
            with page view
          </p>
          <div className="position-relative">
            <img
              onClick={() => chooseTemplate(4)}
              className={`${
                (popup.template === "page view" && isInitialTemplate) ||
                (!isInitialTemplate && templateActive === 4)
                  ? "-is-active"
                  : ""
              } customization-cookie__img`}
              src={Template4}
              alt="template"
            />
            <img
              src={Template5}
              alt="template"
              className="customization-cookie__template-img"
            />
          </div>
          {(popup.template === "page view" && isInitialTemplate) ||
          (!isInitialTemplate && templateActive === 4) ? (
            <div className="customization-cookie__check">
              <img src={GreenCheck} alt="check" />
            </div>
          ) : null}
        </div>
      </div>

      {templateActive || popup.template ? (
        <Button
          onClick={() => showSteps(3)}
          className="btn--primary customization-cookie__next"
        >
          Next
        </Button>
      ) : null}
    </div>
  );
}

export default Step2Popup;
Step2Popup.propTypes = {
  popup: PropTypes.object.isRequired,
  isInitialTemplate: PropTypes.bool.isRequired,
  templateActive: PropTypes.number,
  chooseTemplate: PropTypes.func.isRequired,
  showSteps: PropTypes.func.isRequired,
};
