import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import DownloadIcon from "../../components/Icons/DownloadIcon";
import Moment from "react-moment";

function BreachRightMenu(props) {
  const { isNotify, isEmail, incidentType } = props;

  return (
    <div className="breach-right-menu">
      <Button
        className="btn--primary mb-2"
        onClick={
          isNotify
            ? props.showReport
            : isEmail
            ? props.sendEmail
            : props.showNotify
        }
      >
        {isNotify ? "See report" : isEmail ? "Send e-mail" : "Next"}
      </Button>
      {!isEmail ? (
        <Button
          className="btn--secondary mb-2"
          onClick={() => {
            props.showNotify(false);
            props.showIncidents();
          }}
        >
          Save as a draft
        </Button>
      ) : null}

      <Button className="btn--gray mb-2" onClick={props.showIncidents}>
        Cancel
      </Button>

      {!isEmail && props.updated && incidentType !== "new" ? (
        <p className="compliance-menu__last-edit">
          Last edit was made <Moment fromNow>{props.updated}</Moment>
        </p>
      ) : null}

      {!isEmail && props.updated ? (
        <div
          className="w-100 compliance-menu__items pl-2"
          onClick={() =>
            props.downloadBreach(
              props.download ? props.download : null,
              props.name ? props.name : "file"
            )
          }
        >
          <p className="compliance-menu__download">
            <DownloadIcon
              className="breach-right-menu__icons"
              color="#7450c8"
            />{" "}
            Download
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default BreachRightMenu;
BreachRightMenu.propTypes = {
  isNotify: PropTypes.bool,
  showIncidents: PropTypes.func.isRequired,
  showBreachSentModal: PropTypes.func.isRequired,
  showNotify: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  showReport: PropTypes.func.isRequired,
  downloadBreach: PropTypes.func.isRequired,
  download: PropTypes.string,
  name: PropTypes.string,
  updated: PropTypes.string,
  incidentType: PropTypes.string,
  isEmail: PropTypes.bool,
};
