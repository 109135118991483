import React, { Component, Fragment } from "react";
import { Alert } from "reactstrap";
import RightSideMenu from "./RightSideMenu";
import SyncLoader from "react-spinners/SyncLoader";
import { graphql } from "@apollo/client/react/hoc";
import { flowRight as compose } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { LEGALSITE_QUERY } from "../../graphql/legalSite";
import { BRAND_LEGALSITE } from "../../graphql/brandLegalsite";
import { BRANDING_QUERY } from "../../graphql/brandingQuery";
import BrandingLegalsite from "./BrandingLegalsite";
import BrandingEmail from "./BrandingEmail";
import BrandingPopup from "./BrandingPopup";
import FontLoader from "./FontLoader";
import PropTypes from "prop-types";

class Branding extends Component {
  static propTypes = {
    legalsite: PropTypes.object,
    brandLegalsite: PropTypes.func,
    getInitialPage: PropTypes.func,
    brandingQuery: PropTypes.object,
  };

  state = {
    logo: null,
    brandingTextColor: null,
    brandingTextSize: null,
    brandingTextFont: null,
    brandingTitleAlign: null,
    brandingTitleBold: null,
    brandingTitleItalic: null,
    brandingSubtitleColor: null,
    brandingSubtitleSize: null,
    brandingSubtitleFont: null,
    brandingSubtitleAlign: null,
    brandingSubtitleBold: null,
    brandingSubtitleItalic: null,
    brandingBodyColor: null,
    brandingBodySize: null,
    brandingBodyFont: null,
    brandingBodyAlign: null,
    brandingBodyBold: null,
    brandingBodyItalic: null,
    brandingButtonColor: null,
    brandingButtonSize: null,
    brandingButtonFont: null,
    brandingButtonAlign: null,
    brandingButtonBold: null,
    brandingButtonItalic: null,
    brandingLinksColor: null,
    brandingLinksSize: null,
    brandingLinksFont: null,
    brandingLinksAlign: null,
    brandingLinksBold: null,
    brandingLinksItalic: null,
    bgColor: null,
    iconsColor: null,
    manageTextColor: null,
    manageTextSize: null,
    manageTextFont: null,
    manageTitleAlign: null,
    manageTitleBold: null,
    manageTitleItalic: null,
    footerBg: null,
    btnBg: null,
    cardsColor: null,
    legalsiteBg: null,
    emailBg: null,
    popupBg: null,
    brandingTextButtonColor: null,
    brandingTextButtonFont: null,
    brandingTextButtonSize: null,
    brandingTextButtonBold: null,
    brandingTextButtonItalic: null,
  };

  notify = (text) => toast(text);

  brand = () => {
    const { legalsite } = this.props.legalsite;
    const { brandStyling } = this.props.legalsite.legalsiteSettings;

    this.props
      .brandLegalsite({
        variables: {
          legalsiteTitleColor: this.state.brandingTextColor
            ? this.state.brandingTextColor
            : legalsite.headerTitleColor
            ? legalsite.headerTitleColor
            : brandStyling && brandStyling.legalsiteTitleColor
            ? brandStyling.legalsiteTitleColor
            : null,
          legalsiteTitleSize: this.state.brandingTextSize
            ? this.state.brandingTextSize
            : legalsite.headerTitleSize
            ? legalsite.headerTitleSize
            : brandStyling && brandStyling.legalsiteTitleSize
            ? brandStyling.legalsiteTitleSize
            : null,
          legalsiteTitleFont: this.state.brandingTextFont
            ? this.state.brandingTextFont
            : legalsite.headerTitleFont
            ? legalsite.headerTitleFont
            : brandStyling && brandStyling.legalsiteTitleFont
            ? brandStyling.legalsiteTitleFont
            : null,
          legalsiteTitleItalic: this.state.brandingTitleItalic
            ? this.state.brandingTitleItalic
            : legalsite.headerTitleItalic
            ? legalsite.headerTitleItalic
            : brandStyling && brandStyling.legalsiteTitleItalic
            ? brandStyling.legalsiteTitleItalic
            : null,
          legalsiteTitleBold: this.state.brandingTitleBold
            ? this.state.brandingTitleBold
            : legalsite.headerTitleBold
            ? legalsite.headerTitleBold
            : brandStyling && brandStyling.legalsiteTitleBold
            ? brandStyling.legalsiteTitleBold
            : null,
          manageInfoTitleColor: this.state.manageTextColor
            ? this.state.manageTextColor
            : legalsite.bodyTitleColor
            ? legalsite.bodyTitleColor
            : brandStyling && brandStyling.manageInfoTitleColor
            ? brandStyling.manageInfoTitleColor
            : null,
          manageInfoTitleSize: this.state.manageTextSize
            ? this.state.manageTextSize
            : legalsite.bodyTitleSize
            ? legalsite.bodyTitleSize
            : brandStyling && brandStyling.manageInfoTitleSize
            ? brandStyling.manageInfoTitleSize
            : null,
          manageInfoTitleFont: this.state.manageTextFont
            ? this.state.manageTextFont
            : legalsite.bodyTitleFont
            ? legalsite.bodyTitleFont
            : brandStyling && brandStyling.manageInfoTitleFont
            ? brandStyling.manageInfoTitleFont
            : null,
          manageInfoTitleItalic: this.state.manageTitleItalic
            ? this.state.manageTitleItalic
            : legalsite.bodyTitleItalic
            ? legalsite.bodyTitleItalic
            : brandStyling && brandStyling.manageInfoTitleItalic
            ? brandStyling.manageInfoTitleItalic
            : null,
          manageInfoTitleBold: this.state.manageTitleBold
            ? this.state.manageTitleBold
            : legalsite.bodyTitleBold
            ? legalsite.bodyTitleBold
            : brandStyling && brandStyling.manageInfoTitleBold
            ? brandStyling.manageInfoTitleBold
            : null,
          subtitleTitleColor: this.state.brandingBodyColor
            ? this.state.brandingBodyColor
            : brandStyling && brandStyling.subtitleTitleColor
            ? brandStyling.subtitleTitleColor
            : "#7450c8",
          subtitleTitleSize: this.state.brandingBodySize
            ? this.state.brandingBodySize
            : brandStyling && brandStyling.subtitleTitleSize
            ? brandStyling.subtitleTitleSize
            : 16,
          subtitleTitleFont: this.state.brandingBodyFont
            ? this.state.brandingBodyFont
            : brandStyling && brandStyling.subtitleTitleFont
            ? brandStyling.subtitleTitleFont
            : "Roboto",
          subtitleTitleItalic: this.state.brandingBodyItalic
            ? this.state.brandingBodyItalic
            : brandStyling && brandStyling.subtitleTitleItalic
            ? brandStyling.subtitleTitleItalic
            : null,
          subtitleTitleBold: this.state.brandingBodyBold
            ? this.state.brandingBodyBold
            : brandStyling && brandStyling.subtitleTitleBold
            ? brandStyling.subtitleTitleBold
            : null,
          bodyTextSize: this.state.brandingSubtitleSize
            ? this.state.brandingSubtitleSize
            : brandStyling && brandStyling.bodyTextSize
            ? brandStyling.bodyTextSize
            : 16,
          bodyTextFont: this.state.brandingSubtitleFont
            ? this.state.brandingSubtitleFont
            : brandStyling && brandStyling.bodyTextFont
            ? brandStyling.bodyTextFont
            : "Roboto",
          bodyTextColor: this.state.brandingSubtitleColor
            ? this.state.brandingSubtitleColor
            : brandStyling && brandStyling.bodyTextColor
            ? brandStyling.bodyTextColor
            : "#969696",
          bodyTextBold: this.state.brandingSubtitleBold
            ? this.state.brandingSubtitleBold
            : brandStyling && brandStyling.bodyTextBold
            ? brandStyling.bodyTextBold
            : null,
          bodyTextItalic: this.state.brandingSubtitleItalic
            ? this.state.brandingSubtitleItalic
            : brandStyling && brandStyling.bodyTextItalic
            ? brandStyling.bodyTextItalic
            : null,
          mainButtonTextSize: this.state.brandingButtonSize
            ? this.state.brandingButtonSize
            : brandStyling && brandStyling.mainButtonTextSize
            ? brandStyling.mainButtonTextSize
            : 16,
          mainButtonTextFont: this.state.brandingButtonFont
            ? this.state.brandingButtonFont
            : brandStyling && brandStyling.mainButtonTextFont
            ? brandStyling.mainButtonTextFont
            : "Roboto",
          mainButtonTextColor: this.state.brandingButtonColor
            ? this.state.brandingButtonColor
            : brandStyling && brandStyling.mainButtonTextColor
            ? brandStyling.mainButtonTextColor
            : "#fff",
          mainButtonTextBold: this.state.brandingButtonBold
            ? this.state.brandingButtonBold
            : brandStyling && brandStyling.mainButtonTextBold
            ? brandStyling.mainButtonTextBold
            : null,
          mainButtonTextItalic: this.state.brandingButtonItalic
            ? this.state.brandingButtonItalic
            : brandStyling && brandStyling.mainButtonTextItalic
            ? brandStyling.mainButtonTextItalic
            : null,
          buttonTextSize: this.state.brandingTextButtonSize
            ? this.state.brandingTextButtonSize
            : brandStyling && brandStyling.buttonTextSize
            ? brandStyling.buttonTextSize
            : 16,
          popupTextButtonAndLinkSize: this.state.brandingTextButtonSize
            ? this.state.brandingTextButtonSize
            : brandStyling && brandStyling.buttonTextSize
            ? brandStyling.buttonTextSize
            : 16,
          popupTextButtonAndLinkFont: this.state.brandingTextButtonFont
            ? this.state.brandingTextButtonFont
            : brandStyling && brandStyling.buttonTextFont
            ? brandStyling.buttonTextFont
            : "Roboto",
          buttonTextFont: this.state.brandingTextButtonFont
            ? this.state.brandingTextButtonFont
            : brandStyling && brandStyling.buttonTextFont
            ? brandStyling.buttonTextFont
            : "Roboto",
          buttonTextColor: this.state.brandingTextButtonColor
            ? this.state.brandingTextButtonColor
            : brandStyling && brandStyling.buttonTextColor
            ? brandStyling.buttonTextColor
            : "#7450c8",
          popupTextButtonAndLinkColor: this.state.brandingTextButtonColor
            ? this.state.brandingTextButtonColor
            : brandStyling && brandStyling.buttonTextColor
            ? brandStyling.buttonTextColor
            : "#7450c8",
          buttonTextBold: this.state.brandingTextButtonBold
            ? this.state.brandingTextButtonBold
            : brandStyling && brandStyling.buttonTextBold
            ? brandStyling.buttonTextBold
            : null,
          popupTextButtonAndLinkBold: this.state.brandingTextButtonBold
            ? this.state.brandingTextButtonBold
            : brandStyling && brandStyling.buttonTextBold
            ? brandStyling.buttonTextBold
            : null,
          popupTextButtonAndLinkItalic: this.state.brandingTextButtonItalic
            ? this.state.brandingTextButtonItalic
            : brandStyling && brandStyling.buttonTextItalic
            ? brandStyling.buttonTextItalic
            : null,
          buttonTextItalic: this.state.brandingTextButtonItalic
            ? this.state.brandingTextButtonItalic
            : brandStyling && brandStyling.buttonTextItalic
            ? brandStyling.buttonTextItalic
            : null,
          linksFooterColor: this.state.brandingLinksColor
            ? this.state.brandingLinksColor
            : brandStyling && brandStyling.linksFooterColor
            ? brandStyling.linksFooterColor
            : "#969696",
          linksFooterSize: this.state.brandingLinksSize
            ? this.state.brandingLinksSize
            : brandStyling && brandStyling.linksFooterSize
            ? brandStyling.linksFooterSize
            : 16,
          linksFooterFont: this.state.brandingLinksFont
            ? this.state.brandingLinksFont
            : brandStyling && brandStyling.linksFooterFont
            ? brandStyling.linksFooterFont
            : "Roboto",
          linksFooterItalic: this.state.brandingLinksItalic
            ? this.state.brandingLinksItalic
            : brandStyling && brandStyling.linksFooterItalic
            ? brandStyling.linksFooterItalic
            : null,
          linksFooterBold: this.state.brandingLinksBold
            ? this.state.brandingLinksBold
            : brandStyling && brandStyling.linksFooterBold
            ? brandStyling.linksFooterBold
            : null,
          buttonsAndIconsColor: this.state.iconsColor
            ? this.state.iconsColor
            : brandStyling && brandStyling.buttonsAndIconsColor
            ? brandStyling.buttonsAndIconsColor
            : "#7450c8",
          headerAndFooterColor: this.state.btnBg
            ? this.state.btnBg
            : brandStyling && brandStyling.headerAndFooterColor
            ? brandStyling.headerAndFooterColor
            : "#b0bac9",
          legalsiteBgColor: this.state.legalsiteBg
            ? this.state.legalsiteBg
            : brandStyling && brandStyling.legalsiteBgColor
            ? brandStyling.legalsiteBgColor
            : legalsite.bodyBg,
          navbarAndCardBgColor: this.state.cardsColor
            ? this.state.cardsColor
            : brandStyling && brandStyling.navbarAndCardBgColor
            ? brandStyling.navbarAndCardBgColor
            : "#fff",
          emailBgColor: this.state.emailBg
            ? this.state.emailBg
            : brandStyling && brandStyling.emailBgColor
            ? brandStyling.emailBgColor
            : "#fff",
          popupBgColor: this.state.popupBg
            ? this.state.popupBg
            : brandStyling && brandStyling.popupBgColor
            ? brandStyling.popupBgColor
            : "#fff",
        },
      })
      .then(() => {
        this.notify("Changes saved");
        this.props.legalsite.refetch();
      })
      .catch((error) => {
        console.log(error);

        this.notify("Changes couldn't be saved");
      });
  };

  changeTextColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeBgColor = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  changeFontSize = (name, size) => {
    this.setState({
      [name]: parseInt(size),
    });
  };

  getGoogleFont = (name, font) => {
    this.setState({
      [name]: font,
    });
  };

  getAligned = (name, align) => {
    this.setState({
      [name]: align,
    });
  };

  getBold = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  changeBg = (name, color) => {
    this.setState({
      [name]: color,
    });
  };

  getItalic = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  refetchData = () => {
    this.props.brandingQuery.refetch();
    this.props.legalsite.refetch();
  };

  render() {
    const {
      logo,
      brandingTextFont,
      brandingTextColor,
      brandingTextSize,
      brandingTitleAlign,
      brandingTitleBold,
      brandingTitleItalic,
      brandingSubtitleColor,
      brandingSubtitleSize,
      brandingSubtitleFont,
      brandingSubtitleAlign,
      brandingSubtitleBold,
      brandingSubtitleItalic,
      brandingBodyColor,
      brandingBodySize,
      brandingBodyFont,
      brandingBodyAlign,
      brandingBodyBold,
      brandingBodyItalic,
      brandingButtonColor,
      brandingButtonSize,
      brandingButtonFont,
      brandingButtonAlign,
      brandingButtonBold,
      brandingButtonItalic,
      brandingLinksColor,
      brandingLinksSize,
      brandingLinksFont,
      brandingLinksAlign,
      brandingLinksBold,
      brandingLinksItalic,
      manageTextColor,
      manageTextSize,
      manageTextFont,
      manageTitleAlign,
      manageTitleBold,
      manageTitleItalic,
      bgColor,
      footerBg,
      iconsColor,
      btnBg,
      legalsiteBg,
      cardsColor,
      emailBg,
      popupBg,
      brandingTextButtonColor,
      brandingTextButtonFont,
      brandingTextButtonSize,
      brandingTextButtonBold,
      brandingTextButtonItalic,
    } = this.state;

    const {
      pages,
      legalsite,
      account,
      legalsiteSettings,
      loading,
      error,
      dontSellMyDataButton,
    } = this.props.legalsite;

    const { emails, popups } = this.props.brandingQuery;

    if (loading)
      return (
        <SyncLoader
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
          `}
          sizeUnit={"px"}
          size={10}
          color={"#7450c8"}
          loading={true}
        />
      );

    if (error)
      return (
        <Alert color="danger"> There was an error loading the data!</Alert>
      );

    return (
      <Fragment>
        <div className="justify-content-between d-flex w-100">
          <FontLoader
            font1={
              brandingTextFont
                ? brandingTextFont
                : legalsite?.headerTitleFont || "Roboto"
            }
            font2={
              brandingSubtitleFont
                ? brandingSubtitleFont
                : legalsite.headerBodyFont
                ? legalsite.headerBodyFont
                : "Roboto"
            }
            font3={
              brandingBodyFont
                ? brandingBodyFont
                : legalsite?.headerTitleFont || "Roboto"
            }
            font4={legalsite.bodyTitleFont ? legalsite.bodyTitleFont : "Roboto"}
          />

          <FontLoader
            font1={brandingButtonFont ? brandingButtonFont : "Roboto"}
            font2={brandingLinksFont ? brandingLinksFont : "Roboto"}
            font3={manageTextFont ? manageTextFont : "Roboto"}
            font4={brandingTextButtonFont ? brandingTextButtonFont : "Roboto"}
          />

          <FontLoader
            font1={
              dontSellMyDataButton && dontSellMyDataButton.textFont
                ? dontSellMyDataButton.textFont
                : "Roboto"
            }
            font2={
              legalsite.footerTextFont ? legalsite.footerTextFont : "Roboto"
            }
            font3={"Roboto"}
            font4={"Roboto"}
          />
          <div className="d-flex justify-content-between w-100">
            <div className="container customization-site__branding-content">
              <p className="customization-site__branding-title">Legalsite</p>

              <BrandingLegalsite
                legalsite={legalsite}
                headerBg={btnBg}
                pages={pages}
                cardsColor={cardsColor}
                legalsiteSettings={legalsiteSettings}
                brandingTextColor={brandingTextColor}
                brandingTextSize={brandingTextSize}
                brandingTextFont={brandingTextFont}
                brandingTitleAlign={brandingTitleAlign}
                brandingTitleBold={brandingTitleBold}
                brandingTitleItalic={brandingTitleItalic}
                brandingSubtitleColor={brandingSubtitleColor}
                brandingSubtitleSize={brandingSubtitleSize}
                brandingSubtitleFont={brandingSubtitleFont}
                brandingSubtitleAlign={brandingSubtitleAlign}
                brandingSubtitleBold={brandingSubtitleBold}
                brandingSubtitleItalic={brandingSubtitleItalic}
                brandingBodyColor={brandingBodyColor}
                brandingBodySize={brandingBodySize}
                brandingBodyFont={brandingBodyFont}
                brandingBodyAlign={brandingBodyAlign}
                brandingBodyBold={brandingBodyBold}
                brandingBodyItalic={brandingBodyItalic}
                brandingButtonColor={brandingButtonColor}
                brandingButtonSize={brandingButtonSize}
                brandingButtonFont={brandingButtonFont}
                brandingButtonAlign={brandingButtonAlign}
                brandingButtonBold={brandingButtonBold}
                brandingButtonItalic={brandingButtonItalic}
                manageTextColor={manageTextColor}
                manageTextSize={manageTextSize}
                manageTextFont={manageTextFont}
                manageTitleAlign={manageTitleAlign}
                manageTitleBold={manageTitleBold}
                manageTitleItalic={manageTitleItalic}
                bgColor={bgColor}
                iconsColor={iconsColor}
                btnBg={iconsColor}
                footerBg={footerBg}
                legalsiteBg={legalsiteBg}
                brandingLinksColor={brandingLinksColor}
                brandingLinksSize={brandingLinksSize}
                brandingLinksBold={brandingLinksBold}
                brandingLinksItalic={brandingLinksItalic}
                brandingLinksFont={brandingLinksFont}
                dontSellMyDataButton={dontSellMyDataButton}
              />

              <div className="d-flex justify-content-around">
                <BrandingEmail
                  account={account}
                  logo={logo}
                  legalsiteSettings={legalsiteSettings}
                  brandingSubtitleColor={brandingSubtitleColor}
                  brandingSubtitleSize={brandingSubtitleSize}
                  brandingSubtitleFont={brandingSubtitleFont}
                  brandingSubtitleAlign={brandingSubtitleAlign}
                  brandingSubtitleBold={brandingSubtitleBold}
                  brandingSubtitleItalic={brandingSubtitleItalic}
                  brandingBodyColor={brandingBodyColor}
                  brandingBodySize={brandingBodySize}
                  brandingBodyFont={brandingBodyFont}
                  brandingBodyAlign={brandingBodyAlign}
                  brandingBodyBold={brandingBodyBold}
                  brandingBodyItalic={brandingBodyItalic}
                  brandingButtonColor={brandingButtonColor}
                  brandingButtonSize={brandingButtonSize}
                  brandingButtonFont={brandingButtonFont}
                  brandingButtonAlign={brandingButtonAlign}
                  brandingButtonBold={brandingButtonBold}
                  brandingButtonItalic={brandingButtonItalic}
                  brandingLinksColor={brandingLinksColor}
                  brandingLinksSize={brandingLinksSize}
                  brandingLinksFont={brandingLinksFont}
                  brandingLinksAlign={brandingLinksAlign}
                  brandingLinksBold={brandingLinksBold}
                  brandingLinksItalic={brandingLinksItalic}
                  bgColor={emailBg}
                  btnBg={iconsColor}
                  footerBg={btnBg}
                />

                <BrandingPopup
                  legalsiteSettings={legalsiteSettings}
                  brandingSubtitleColor={brandingSubtitleColor}
                  brandingSubtitleSize={brandingSubtitleSize}
                  brandingSubtitleFont={brandingSubtitleFont}
                  brandingSubtitleAlign={brandingSubtitleAlign}
                  brandingSubtitleBold={brandingSubtitleBold}
                  brandingSubtitleItalic={brandingSubtitleItalic}
                  brandingBodyColor={brandingBodyColor}
                  brandingBodySize={brandingBodySize}
                  brandingBodyFont={brandingBodyFont}
                  brandingBodyAlign={brandingBodyAlign}
                  brandingBodyBold={brandingBodyBold}
                  brandingBodyItalic={brandingBodyItalic}
                  brandingButtonColor={brandingButtonColor}
                  brandingButtonSize={brandingButtonSize}
                  brandingButtonFont={brandingButtonFont}
                  brandingButtonAlign={brandingButtonAlign}
                  brandingButtonBold={brandingButtonBold}
                  brandingButtonItalic={brandingButtonItalic}
                  brandingLinksColor={brandingLinksColor}
                  brandingLinksSize={brandingLinksSize}
                  brandingLinksFont={brandingLinksFont}
                  brandingLinksAlign={brandingLinksAlign}
                  brandingLinksBold={brandingLinksBold}
                  brandingLinksItalic={brandingLinksItalic}
                  bgColor={popupBg}
                  btnBg={iconsColor}
                  brandingTextButtonColor={brandingTextButtonColor}
                  brandingTextButtonFont={brandingTextButtonFont}
                  brandingTextButtonSize={brandingTextButtonSize}
                  brandingTextButtonBold={brandingTextButtonBold}
                  brandingTextButtonItalic={brandingTextButtonItalic}
                />
              </div>
            </div>
            <RightSideMenu
              isBranding={true}
              isEmailCustomization={false}
              isCookieCustomization={false}
              isSiteCustomizatio={false}
              getInitialPage={this.props.getInitialPage}
              legalsiteSettings={legalsiteSettings}
              changeTextColor={this.changeTextColor}
              changeBgColor={this.changeBgColor}
              changeFontSize={this.changeFontSize}
              getGoogleFont={this.getGoogleFont}
              brandingTextColor={
                brandingTextColor
                  ? brandingTextColor
                  : legalsite.headerTitleColor
              }
              accountData={account}
              brandingTextSize={
                brandingTextSize ? brandingTextSize : legalsite?.headerTitleSize
              }
              brandingTextFont={
                brandingTextFont ? brandingTextFont : legalsite?.headerTitleFont
              }
              manageTextColor={
                manageTextColor ? manageTextColor : legalsite?.bodyTitleColor
              }
              manageTextSize={
                manageTextSize !== null
                  ? manageTextSize
                  : legalsite.bodyTitleSize
              }
              manageTextFont={
                manageTextFont ? manageTextFont : legalsite?.bodyTitleFont
              }
              manageTitleAlign={
                manageTitleAlign ? manageTitleAlign : legalsite?.bodyTitleAlign
              }
              manageTitleBold={
                manageTitleBold !== null
                  ? manageTitleBold
                  : legalsite?.bodyTitleBold
              }
              manageTitleItalic={
                manageTitleItalic !== null
                  ? manageTitleItalic
                  : legalsite?.bodyTitleItalic
              }
              getAligned={this.getAligned}
              getBold={this.getBold}
              getItalic={this.getItalic}
              brandingTitleAlign={
                brandingTitleAlign
                  ? brandingTitleAlign
                  : legalsite?.headerTitleAlign
              }
              emailBranding={
                emails && emails.length ? emails[0].showLegalsiteBranding : null
              }
              popupBranding={
                popups && popups.length ? popups[0].showLegalsiteBranding : null
              }
              brandingTitleBold={
                brandingTitleBold !== null
                  ? brandingTitleBold
                  : legalsite?.headerTitleBold
              }
              brandingTitleItalic={
                brandingTitleItalic !== null
                  ? brandingTitleItalic
                  : legalsite?.headerTitleItalic
              }
              brandingBodyColor={
                brandingBodyColor ? brandingBodyColor : "#7450c8"
              }
              brandingBodySize={brandingBodySize ? brandingBodySize : 16}
              brandingBodyFont={brandingBodyFont ? brandingBodyFont : "Roboto"}
              brandingBodyAlign={brandingBodyAlign ? brandingBodyAlign : null}
              brandingBodyBold={brandingBodyBold ? brandingBodyBold : null}
              brandingSubtitleColor={
                brandingSubtitleColor ? brandingSubtitleColor : "#969696"
              }
              notifyMsg={(msg) => this.notify(msg)}
              brandingBodyItalic={
                brandingBodyItalic ? brandingBodyItalic : null
              }
              refetchData={this.refetchData()}
              brandingTextButtonColor={
                brandingTextButtonColor ? brandingTextButtonColor : "#7450c8"
              }
              brandingSubtitleSize={
                brandingSubtitleSize ? brandingSubtitleSize : 16
              }
              brandingSubtitleFont={
                brandingSubtitleFont ? brandingSubtitleFont : "Roboto"
              }
              brandingSubtitleAlign={
                brandingSubtitleAlign ? brandingSubtitleAlign : null
              }
              brandingSubtitleBold={
                brandingSubtitleBold ? brandingSubtitleBold : null
              }
              brandingTextButtonBold={
                brandingTextButtonBold ? brandingTextButtonBold : null
              }
              brandingSubtitleItalic={
                brandingSubtitleItalic ? brandingSubtitleItalic : null
              }
              brandingButtonColor={
                brandingButtonColor ? brandingButtonColor : "#fff"
              }
              brandingButtonSize={brandingButtonSize ? brandingButtonSize : 16}
              brandingButtonFont={
                brandingButtonFont ? brandingButtonFont : "Roboto"
              }
              brandingTextButtonFont={
                brandingTextButtonFont ? brandingTextButtonFont : "Roboto"
              }
              brandingButtonAlign={
                brandingButtonAlign ? brandingButtonAlign : null
              }
              brandingButtonBold={
                brandingButtonBold ? brandingButtonBold : null
              }
              brandingButtonItalic={
                brandingButtonItalic ? brandingButtonItalic : null
              }
              brandingLinksColor={
                brandingLinksColor ? brandingLinksColor : "#969696"
              }
              brandingLinksSize={brandingLinksSize ? brandingLinksSize : 16}
              brandingLinksFont={
                brandingLinksFont ? brandingLinksFont : "Roboto"
              }
              brandingLinksAlign={
                brandingLinksAlign ? brandingLinksAlign : null
              }
              brandingLinksBold={brandingLinksBold ? brandingLinksBold : null}
              brandingLinksItalic={
                brandingLinksItalic ? brandingLinksItalic : null
              }
              brandingTextButtonItalic={
                brandingTextButtonItalic ? brandingTextButtonItalic : null
              }
              changeBg={this.changeBg}
              bgColor={bgColor}
              iconsColor={iconsColor}
              btnBg={btnBg ? btnBg : "#b0bac9"}
              cardsColor={cardsColor ? cardsColor : "#fff"}
              legalsiteBg={legalsiteBg ? legalsiteBg : legalsite?.bodyBg}
              emailBg={emailBg ? emailBg : "#fff"}
              popupBg={popupBg ? popupBg : "#fff"}
              brandingTextButtonSize={
                brandingTextButtonSize ? brandingTextButtonSize : 16
              }
              brand={this.brand}
            />
          </div>

          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            newestOnTop={false}
            closeButton={false}
            closeOnClick
            rtl={false}
            draggable
            hideProgressBar
          />
        </div>
      </Fragment>
    );
  }
}

export default compose(
  graphql(LEGALSITE_QUERY, {
    name: "legalsite",
  }),
  graphql(BRANDING_QUERY, {
    name: "brandingQuery",
  }),
  graphql(BRAND_LEGALSITE, {
    name: "brandLegalsite",
  })
)(Branding);
